{ log, meta }= Mod= vx= require 'vx/globals/Boot'

{ UIComponent }= UI= require 'vx/UI'

{ button, icon, span }= UI

{ hasFullScreen }= require 'vx/comp/fullScreen/FullScreen'

openIcon=  icon '|fullscreen'
closeIcon= icon '|fullscreen_exit'

#openIcon="open"
#closeIcon="close"

#log "FullScreen icons=",{openIcon,closeIcon}



fullScreenButton= meta class FullScreenButton extends UIComponent
    @displayName: 'FullScreenButton'


    render:(props=@props)->

        return null if !hasFullScreen

        {onClick,icon,isFullScreen}= props

        icon?= if isFullScreen then closeIcon else openIcon

        button '#fullscreen-btn .mapcontrol $marginRight=16px',onClick:(onClick or @doClick),icon

    doClick:->
        @props.app?.toogleFullScreen?()

module.exports= { fullScreenButton }

