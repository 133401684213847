{ log }= vx= require 'vx/globals/Boot'
# Assumes L= leaflet ....


{ asyncMap  }= {} # ? require 'Utils'

{ LineStr }= require 'vx/math/LineStr'

{ round }= Math



time= ->new Date

module.exports= deConvert= (data,done)->
    c=0

    tl0= time()

    toArr= (d)->
        arrs=[]
        idx=0
        while idx<d.length and c<10000
            l=d[idx]+17
            arrs.push d.slice idx+1,l+idx+1
            idx+=l+1
        arrs


    deDelta= (arr,scale=1)->
        rr=(x)-> x/scale
        d=0
        for a in arr
            d+=rr a

    toCat= (arr)->
        codes='TRLNVPF'
        for c in arr
            if c then codes[c-1] else ''


    scale= 1000000
    ur= (x)->x/scale
    cx=0
    cy=0

    deLS= (arr)->
        dx=cx
        dy=cy
        for a,i in arr by 2
            b= arr[i+1]
            x= a
            y= b

            x:ur dx+= x
            y:ur dy+= y


    a1= toArr data
    tl1= time()


    lastArr= a1.pop()
    if typeof lastArr[0] is 'string'
        UUids= lastArr
        NumClubs= a1.pop()
    else
        NumClubs= lastArr
        UUids= false
    NomsClubs= a1.pop()

    log "Got UUids #{!!UUids}}"

    Clubs= {}
    nums= deDelta NumClubs
    for num,i in deDelta NumClubs
        Clubs[num]=
            num:num
            nom:NomsClubs[i]
            idx:i

    NoClub= deDelta a1.pop()

    tl2= time()


    Cinfo= a1.pop()
    cx= (-72*scale)+ Cinfo[0]
    cy=  (47*scale)+ Cinfo[1]

    NoSen= deDelta a1.pop()
    Cats= toCat deDelta a1.pop()

    #Linestrs=(deLS l for l in a1 )

    tl3= time()


    out=[]
    ld2Line= (ld,i)->
        l= deLS ld
        out.push LineStr._ l,
            data:
                cat:Cats[i]
                club: Clubs[NoClub[i]]
                cnum: NoClub[i]
                num:NoSen[i]
                idx:i
                uuid:if UUids then UUids[i] else ''

    if done
        asyncMap a1,
            mapF:ld2Line
            doneF:->
                done out
        return null
    else
        for ls,i in a1
            ld2Line ls,i
        tl4= time()
        log " Deconvert loops= #{tl1-tl0} #{tl2-tl1} #{tl3-tl2} #{tl4-tl3}"
        return out



