{ meta, log, a2map, sList, oAssign, oSet, oDiffs, oShallow, beginingSeconds, Global }= Mod= vx= require 'vx/globals/Boot'
ModuleName= 'SharingUI'
wait= (fn)-> setTimeout fn,500

{ oEq }= oShallow()

UI= require 'vx/UI'

# I18n
{ sayModule }= require 'vx/i18n/I18n'
say= sayModule ModuleName

# UI layout
{ mDialog, p, table, tbody, tr, td, br, button, icon, span, div }= UI
# { textarea }=  require './DOMElements'
{ makeV1URLb:makeV1URL, makeV2URL }= require 'vx/tools/URLencoders'


require 'vx/comp/SharingUI.css'

#vx.ShareURL?= 'http://fcmq.fcmqapi.ca/i'


# CopyEvent= (e)->
#     if (clipboard=e.clipboardData or e.originalEvent.clipboardData) and clipboard.setData
#         #log "we have a setData"
#         ret= clipboard.setData 'text/plain',getStateAsURL()
#         #log "setData = #{ret}"
#         if ret
#             e.preventDefault()
#             (navigator.alert or alert) ( say "message copy automatique" ),null,(say "titre copy aumatique")


copyState= (url,thenDo)->
#    doDefault= false
    switch
        when Global.cordova?.plugins?.clipboard?
            #log "copy using clipboard plugin"
            Global.cordova?.plugins.clipboard.copy url,
                ->  if thenDo
                        thenDo()
                    else (navigator.alert or alert) ( say "message copy automatique" ),null,(say "titre copy automatique")
                -> (navigator.alert or alert) ( say "message erreur copy automatique" ),null,(say "titre erreur copy automatique")

        when navigator.clipboard?.writeText?
            log "copy using navigator.clipboard.writeText"
            navigator.clipboard.writeText url
                .then -> if thenDo
                                thenDo()
                         else (navigator.alert or alert) ( say "message copy automatique" ),null,(say "titre copy automatique")
                .catch (err)->
                    # ok failed try patch
                    log "clipboardwrite failed #{err}",err
                    ret= copyToClipBoard url,thenDo
                    if !ret
                        (navigator.alert or alert) ( say "message erreur copy automatique" ),null,(say "titre erreur copy automatique")
        else
            ret= copyToClipBoard url,thenDo
            if !ret
                ret= (navigator.prompt or prompt) say("message pour copier faire"),url
                log "copyState else thenDo #{!!thenDo} ret=#{ret}"
                thenDo() if thenDo


copyToClipBoard= (txt,thenDo)->
    log "copyToClipBoard"
    ret= copyToClipBoardPatch txt
    if ret
        log "copyToClipBoardPatch worked"
    else
        log "copyToClipBoardPatch failed"
        ret= (navigator.prompt or Global.prompt) say("message pour copier faire")
        ret= 1
        log "copyToClipBoard promt got #{ret}"

    if thenDo and ret
        log "copyToClipBoard then do"
        thenDo()
    ret



copyToClipBoardPatch= (txt)->
    return null if !document.queryCommandSupported? 'copy' # TODO: cant get this to work...

    log "copy using execCommand copy"

#     textarea= document.createElement "textarea"
#     textarea.textContent= url
#     textarea.style.position= "fixed" # Prevent scrolling to bottom of page in MS Edge.
#     document.body.appendChild textarea
#     textarea.focus()
#     textarea.select()

    evtext= document.querySelector '#ev__url'
    log "got evtext=",evtext
    range = document.createRange()
    range.selectNode evtext
    #log "range is",range
    window.getSelection().empty()
    window.getSelection().addRange range

    ret= null
    try
        ret=document.execCommand "copy" # Security exception may be thrown by some browsers.
        log " execCommand copy is #{ret}"
    catch ex
        console.warn "Copy to clipboard failed.", ex

    ret


isAndroid= -> navigator.userAgent.toLowerCase().indexOf("android") > -1

isIOS= ->  navigator.userAgent.toLowerCase().indexOf("iphone") > -1




sendMail= ({addr='',subject='',body='No body'})->
    [name,srvr]= addr.split '@'
    e= encodeURIComponent
    if addr
         addr= "#{e name}@#{e srvr}"
    else addr= ''
    mailURL= "mailto:#{addr}?&subject=#{e subject}&body=#{e body}"


    #log "Doing mailURL= '#{mailURL}'"
    if vx.isAPP
        window.location.href= mailURL
    else # web
        # create new window in case we are in a iframe
        window.open mailURL,'_top'
    #log "window.location.href=#{window.location.href}"
    null

sendSMS= ({addr='',subject='',body=''})->
    e= encodeURIComponent
    mess="#{subject}#{if subject and body then ' ' else ''}#{body}"
    smsURL= "sms:#{e addr}?&body=#{e mess}"
    #log "Doing smsURL= #{smsURL}"
    window.location.href= smsURL
    #log "window.location.href=#{window.location.href}"
    null


confirmP= (mess)->
    new Promise (resolve,reject)->
        try
            resolve (navigator.confirm or Global.confirm) say "bug email"
        catch err
            reject err





class SharingStateManager


    defaultState:
        envoyerDialog: false

    persistantState: {}

    setEnvoyerDialog:(val)-> @setState envoyerDialog: !!val
    getEnvoyerDialog:(val)-> @state.envoyerDialog
    doCloseEnvoyerDialog: -> @setState envoyerDialog: false

    getStateAsURL:-> @getStateAsV2URL()

    getStateAsURL2:-> @getStateAsV2URL()

    getStateAsV1URL:->
        info= @getStateInfo()
        info.url= vx.urls.ShareURL
        makeV1URL info

    getStateAsV2URL:->
        info= @getStateInfo()
        info.url= vx.urls.ShareURL
        makeV2URL info

    doShareMailState: ->
        if isAndroid() # and  vx.isAPP
            # we are in android
            @doShareMailStateBug()
            return null

        url= @getStateAsURL()

        @setEnvoyerDialog false

        wait ->sendMail
            subject: say "titre courriel partager"
            body:    (say "message courriel partager") + url

        null


    doShareMailStateBug: ->

        url= @getStateAsURL()

        if vx.isAPP
            return if !(navigator.confirm or Global.confirm) say "bug email"

            @setEnvoyerDialog false

            wait -> copyState url,->
                sendMail
                    subject: say "titre courriel partager"
                    body:    (say "message courriel partager")
        else

            copyState url,->
                sendMail
                    subject: say "titre courriel partager"
                    body:    (say "bug email2")

            @setEnvoyerDialog false

        null


    doShareMailStateBugOld: ->



        null





    doShareSMSState: ->
        url= @getStateAsURL()

        @setEnvoyerDialog false


        wait -> sendSMS
            #subject: say "titre sms partager"
            subject: ''
            body:    (say "message sms partager") + url

        null


    doShareWebPage: ->

        url= @getStateAsURL()

        @setEnvoyerDialog false
        wait ->
            window.open url, if Global.cordova then '_system' else '_blank'
                .focus()
        null


    doShareCopyURL: ->
        url= @getStateAsURL()

        # fake copy requires event thread
        copyState url

        wait => @setEnvoyerDialog false


    doShareCopyURL2: ->
        url= @getStateAsURL2()

        @setEnvoyerDialog false

        wait -> copyState url


doSel=(elem)->
    #log "doSel elem=",elem
    return if !elem
    range = document.createRange()
    range.selectNode elem
    window.getSelection().empty()
    #log "sel after empty=", window.getSelection()
    window.getSelection().addRange range

shareDialog= (props)->

    { app }= props
    url= app.getStateAsURL()


    mDialog '#envoyerDialog footer=ok ',
        title: say "titre popup partager"
        visible: app.getEnvoyerDialog()
        onClose: app.doCloseEnvoyerDialog
        onBackground:'ok'
        ok:[(icon '|cancel'),say "Annuler"]
        p '',
            say "intro popup partager"
            if vx.isAPP
                span '.vx-show-w-wide',br(),say "intro popup partager cell2"
            else ''

        table '',tbody '',{},
            (if vx.isAPP or !isAndroid()
                    tr '',
                        td '.share__col1 $width=50%',button '$width=100% ',
                                onClick: app.doShareMailState
                                title: say "hover btn partager courriel"
                                (icon '|email'),' ',say "titre btn partager courriel"
                        td '.share__col2 .vx-share-btn-info',say "text btn partager courriel"
            else '')

            if vx.isAPP or isAndroid() or isIOS()
                tr '',
                    td '.share__col1 $width:50%',button '$width:100% ',
                        onClick: app.doShareSMSState
                        title: say "hover btn partager sms"
                        (icon '|sms'),' ',say "titre btn partager sms"
                    td '.share__col2 ',say "text btn partager sms"


#                 tr '',
#                     td '$width:50%',button '$width:100% bkgColor=primary',
#                         onClick: app.doShareCopyURL2
#                         title: say "hover btn partager copy2"
#                         (icon '|attachment'),' ',say "titre btn partager copy2"
#                     td '',say "text btn partager copy2"

            if !vx.isAPP
                tr '',
                    td '.share__col1 $width=50%',button '$width=100% ',
                        onClick: app.doShareWebPage
                        title: say "hover btn partager favori"
                        (icon '|cloud'),' ',say "titre btn partager favori"
                    td '.share__col2 ',say "text btn partager favori"


            tr '',
                td '.share__col1 $width=50%',button '$width=100% ',
                    onClick: app.doShareCopyURL
                    title: say "hover btn partager copy"
                    (icon '|attachment'),' ',say "titre btn partager copy"
                td '.share__col2 ',say "text btn partager copy"

            tr '',td '.share__col1 .share__url colspan=2',div '#ev__url.ev__url',ref:doSel,url





module.exports= { SharingStateManager, shareDialog }

