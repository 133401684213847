{ meta, log, oA, lang, aLast, isArray, oDiffs }= Mod= vx= require 'vx/globals/Boot'

{ floor, ceil, abs, max, min, trunc, abs }= Math

{ parseQS }= require 'vx/tools/URLTools'

{ getV1EncodedState, getV2EncodedState, asV2EncodedState }= require 'vx/tools/URLencoders'

{ Bounds }= require 'vx/math/Bounds'

{ Pt,  simplifySamePixelPts, simplifyInlinePts, closeTo }= require 'vx/graph/Graph5'

{ VItin }= require 'vx/comp/itin/Router'

{ nomConditions2reg }= require 'vx/globals/Conversions'

P= require 'vx/tools/Persistance'

stateFromURL= (url)->
    qs= url.split('?')[1].split('#')[0]
    info = parseQS qs,{},1
    {r,s}= info
    try
        state= switch r
            when '1'
                getV1EncodedState s
            when '2'
                getV2EncodedState s
            else #guess r=2
                if s then getV2EncodedState s
                else {}
    catch err
        state={}

    oA {},state,info



getSegIdsInfo=(segIds,router)->

    segs= ( router.getSegId abs segId for segId in segIds )

    bounds= Bounds.aBNDS ( s.bounds for s in segs)

    dist=0
    for s in segs
        dist+= s.dist

    opts=[]
    last= null
    for sid,idx in segIds
        s= segs[idx]
        pts= s.pts
        if sid<0
            pts=pts[..].reverse()
        if last
            lastPt=aLast last
            if closeTo pts[0],lastPt
                last= last.concat pts[1 ..]
            else if closeTo ( aLast pts),lastPt
                #log "merge reverse"
                last= last.concat pts[..-2].reverse()
            else
                opts.push last
                last= pts
        else last= pts
    opts.push last if last

    { bounds,dist,opts,segs }


#log "$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ conditonmodel v2"

meta class Condition


    @createFrom= (obj,router)->

        #log "Condition.creatFrom '#{obj.Titre}' router=#{!!router} waypoints=#{!!obj.waypoints} segs=#{!!obj.segs} itin=#{ !!(obj.url_condition_final or obj.url_condition_ancien)} wps= #{obj.waypoints?.length}",obj.waypoints

        # first Determine if we have waypoints and type

        switch
            when obj.waypoints?.length
                type= 'sect'
                waypoints= obj.waypoints

            when obj.url_condition_final or obj.url_condition_ancien
                type= 'sect'
                itinURL= obj.url_condition_final or obj.url_condition_ancien

                state= stateFromURL itinURL

                #log "Condition.creatFrom '#{obj.Titre}' got state=",state
                waypoints= ( Pt._ wp[1],wp[0] for wp in state.waypoints or [] when wp and typeof wp isnt 'string' )

                #log "State from waypoints got wpts=",waypoints
                mapBounds= obj.mapBounds or state.mapBounds

            when obj.segIds
                type= 'zone'

            when obj.iconPos
                type= 'sect'
                #log "sect from iconPos=#{obj.iconPos}",obj.iconPos
                waypoints= [ Pt._ obj.iconPos.lng,obj.iconPos.lat ]


        #path cleanup waypoints TODO trouver attraits


        if router and type is 'sect'
            #log "doing a sect"
            #wPts= ( Pt._ x,y for [y,x] in waypoints )
            wPts= waypoints
            switch waypoints.length
                when undefined,0 then
                when 1
                    segInfo= router.graph.findClosestSegmentToPtM waypoints[0]
                    seg=segInfo.seg
                    segIds= if seg then [seg.id] else []
                    #log " WAYPOINTS=1 for #{obj.id} #{obj.Titre} #{waypoints[0]} got segIds=#{segIds}" #,{waypoints,seg,segInfo}
                else #>1
                    #log "make itin #{waypoints.length} waypoints obj=",obj
                    itin= VItin._ wPts,router,{useF:1}
                    #log "OUPS Created itin dist=#{itin.totalDist}",itin if itin.totalDist>1000
                    #dist= itin.totalDist
                    segIds= itin.getSegIds()


            #log "segIds=",segIds

            if !segIds or !segIds.length
                log "Error on #{obj.Titre} #{obj.id} no segs wpta=#{waypoints}"
                return null

            {dist,bounds,opts}= getSegIdsInfo segIds,router

            llWpts= ( {lat:y,lng:x} for {x,y} in wPts )
            encodedState= ( asV2EncodedState waypoints:llWpts,mapBounds:bounds )

            if obj.iconPos
                iconPos= obj.iconPos
            else # Auto amrker

                if wPts.length % 2
                    #odd
                    iconPos= llWpts[floor wPts.length/2]
                else # even find mid point
                    a= wPts[-1+wPts.length/2]
                    b= wPts[   wPts.length/2]

                    c= itin.closestPt2 Pt._ (a.x+b.x)/2,(a.y+b.y)/2
                    iconPos= {lat:c.y,lng:c.x}

            #log "did a sect"

        else if router and type= 'zone'
            # merge seg
            #log "OK got segs "

            segIds= obj.segIds

            { bounds,dist,opts}= getSegIdsInfo segIds,router

            if !obj.iconPos
                cpt= bounds.center()
                iconPos= {lat:cpt.y,lng:cpt.x}


        else
            log "Cant create"

#             ptArr=[]
#             for segId in segs
#                 router.getSeg abs segId
#                 continue if !seg
#                 pts= seg.pts()
            dist=1
            segs=[]
            opts=[]

        if obj.cond is 'Aucun'
            # no points
            log "cond aucun no pimts"
            dist=0
            segs=[]
            opts=[]


        _pts=  ( simplifyInlinePts (simplifySamePixelPts o,14),14 for o in opts )
        _pts12=( simplifyInlinePts (simplifySamePixelPts o,11),11 for o in opts )
        _pts8= ( simplifyInlinePts (simplifySamePixelPts o,8),8   for o in opts )


        #log "Condition.creatFrom '#{obj.Titre}'got encodesState=#{itinQS} = #{JSON.stringify state}"

        #log "obj.description_fr= #{JSON.stringify obj.description_fr}"
        #log "obj.description_en= #{JSON.stringify obj.description_en}"

        { id, membre, Titre, Titre_en, desc, desc_en, desc2, desc2_en, dateMaj, cond }= obj

        condition=
            __proto__: @::
            id:       id
            Titre:    Titre
            Titre_en: Titre_en
            desc:     desc
            desc_en:  desc_en
            desc2:    desc2
            desc2_en: desc2_en
            membre:   membre
            dateMaj:  dateMaj
            cond:     cond
            fcmqurl:  itinURL
            vxurl:    obj.url_fiche_region
            photo:    obj.url_photo
            waypoints: waypoints
            iconPos:    obj.iconPos or iconPos
            icon:      obj.icon or 'none'
            viewBounds:  obj.mapBounds or mapBounds or bounds  #if mb= state.mapBounds then [ mb.x, mb.y, mb.X, mb.Y ] else undefined  #TODO viewBounds vs bounds
            bounds:    bounds
            dist:      dist
            segIds:   segIds
            _pts:   _pts
            _pts12: _pts12
            _pts8:  _pts8
            encodedState: encodedState
            #state:     state
            data: obj.data

        #log "create Condition dist=#{dist}"
        condition



    toJSON: ->
        oA {},@, { _pts:undefined, _pts8:undefined, _pts12:undefined},
            _spts:   @_encode 'pts'
            _spts8:  @_encode 'pts8'
            _spts12: @_encode 'pts12'


    _encode: (name)->
        for pts in ( @[name] or [])
            [
                P.toLStr (p.x for p in pts),1000000,-71.1
                P.toLStr (p.y for p in pts),1000000,46.9
                ]


    _decode: (name)->
            data= @[name]
            return [] if !data?
            @[name]= undefined
            for d in data
                [xs,ys]= d
                xa= P.fromLStr xs,1000000,-71.1
                ya= P.fromLStr ys,1000000,46.9
                ( {x, y:ya[i]} for x,i in xa )


    pts__get:   -> @_pts?=   @_decode '_spts'
    pts8__get:  -> @_pts8?=  @_decode '_spts8'
    pts12__get: -> @_pts12?= @_decode '_spts12'

    i_all__get: -> @data?.i_all
    c_all__get: -> @data?.c_all
    i_atr__get: -> @data?.i_atr

    @fromJSONArr: (conditionsData)->
        #log "Condition.fromJSONArr got #{conditionsData.length} this="
        ret=for c in conditionsData when c
            #log " read #{c.id}"
            oA {__proto__: @::},c,
                Titre_fr: c.Titre
                Titre:    if lang is 'en' then c.Titre_en or '' else c.Titre or ''
                desc_fr:  c.desc
                desc:     if lang is 'en' then c.desc_en or '' else c.desc or ''
        #log "ret =#{ret.length}"
        ret



meta class ConditionSeg extends Condition







conditionsFromJSONArr= Condition.fromJSONArr.bind Condition
conditionsFromJSONArr.vx_name= 'conditionsFromJSONArr' #for db server
conditionsFromJSONSrc= (condInfo)->
    { sections, seg2sect }= condInfo or {}
    log " ********************** conditionsFromJSONSrc GOT SECTIONS=#{sections?.length} seg2sect=#{seg2sect?.length} "
    sects=if sections then conditionsFromJSONArr sections else []
    log "conditionsFromJSONSrc returing sects=#{sects?.length}"
    sects
conditionsFromJSONSrc.vx_name= 'conditionsFromJSONSrc'

#log "loaded CicuiModel conditionsFromJSONArr=#{typeof conditionsFromJSONArr}"


addRefs= (sectId,refs,seg2sect)->
    # CACREFULL seg2sect arrays should be sorted
    # they will not after this function must call seg2sectUpdateSort after all updates
    log "addRefs ",{sectId,refs}
    for ref in refs
        ref= abs trunc ref
        segRefs= seg2sect[ref]
        switch
            when !segRefs?
                seg2sect[ref]= sectId
            when typeof segRefs is 'string'
                if segRefs isnt sectId
                    seg2sect[ref]= [segRefs,sectId]
            else # segRefs is Array
                if 0>segRefs.indexOf sectId
                    # Should add not there
                    if seg2sect.hasOwnProperty ref
                        # already updated just push
                        segRefs.push sectId
                    else
                        seg2sect[ref]=segRefs.concat sectId
                #else null #already there
    null


removeRefs= (sectId,refs,seg2sect)->
    log "removeRefs  seg2sect=#{!!seg2sect} has",{sectId,refs}
    for ref in refs
        ref= abs trunc ref
        segRefs= seg2sect[ref]
        switch
            when segRefs is sectId
                seg2sect[ref]= null
            when segRefs? and 0 <= (pos=segRefs.indexOf sectId)
                # segRefs is Array
                if seg2sect.hasOwnProperty ref
                    # already updated just push
                    seg2sect[ref]= segRefs= segRefs[..]
                segRefs.splice pos,1

                switch segRefs.length
                    when 0 then seg2sect[ref]= null # should never happen
                    when 1 then seg2sect[ref]= segRefs[0]
            #else #nochange
            #    null
    null



touchRefs= (sectId,refs,seg2sect)->
    log "touchRefs  seg2sect=#{!!seg2sect} has",{sectId,refs}
    for ref in refs
        seg2sect[ref]= seg2sect[ref]
    null




segGroups= (a,b)->

    return { ao:[], ab:[], bo:b  } if !a or !a.length
    return { ao:a,  ab:[], bo:[] } if !b or !b.length

    an= (abs trunc x for x in a )
    bn= (abs trunc x for x in b )

    aSet= new Set an
    bSet= new Set bn

    ao= (x for x from a when not bSet.has x)
    bo= (x for x from b when not aSet.has x)

    ab= (x for x from a when bSet.has x)

    { ao, ab, bo }



seg2sectUpdateSort= (seg2sectUpdate,segs)->
    for own idx,arr of seg2sectUpdate when (idx isnt 'length') and isArray arr
        buged=false
        for a in arr when !segs[a]
            log " seg fetch error for #{a}"
            buged= true
        if buged
            log " Bugged for arr=",arr
            arr= seg2sectUpdate[idx]= ( a for a in arr when segs[a]? )
        arr.sort (a,b)-> segs[a].dist-segs[b].dist # low to hi




oDeepEq= (a,b,ops={},cnt=0)->
        return true  if a is b
        return false if !b or !a # because tested a is b
        return false if typeof a isnt 'object' or typeof b isnt 'object'
        return false if a.length isnt b.length

        if cnt >32
            log "oDeepEq looping on ",{a,b}
            throw new Error "oDeepEq looping on ",{a,b,ops,cnt}
            # could also return false ...
        cnt++
        ignores= ops.ignores
        for k,v of a when (!ignores or k not of ignores) and (v isnt vb=b[k])  and not oDeepEq v,vb,ops,cnt
             return false
        return true
#         for k,v of a
#             if ignores or k not of ignores
#                 log "ignored #{k}"
#                 continue
#             if not oDeepEq v,b[k],ops,cnt
#                 return false
#         return true



DetectCondChange= (condInfo,newSectArr,membre)->

    {seg2sect,sections}= condInfo

    log "DetectCondChange for membre #{membre} newSectArr.length=#{newSectArr?.length}  sections.length=#{sections?.length}"
    log "condIfo=#{typeof condInfo} " #,condInfo

    newSectMap= {}
    nc= 0
    for sect in newSectArr when sect and (!membre or sect.membre is membre)# TODO decide when sect.membre is membre
        newSectMap[sect.id]= sect
        nc++

    log "  did newSectMap count=#{nc} or #{newSectArr.length}"


    oldSectMap={}
    oc= 0
    for sect in sections when sect and (!membre or sect.membre is membre)
        oldSectMap[sect.id]= sect
        oc++

    log "  did oldSectMap count=#{oc} of #{sections.length}"

    #noChange={}
    #removedSect={}
    changedSects={}

    log "  do changedSects"

    deepOps= ignores:{encodedState:1}

    ac= mc= dc= 0

    for id,newSect of newSectMap when not oDeepEq newSect,(oldSect=oldSectMap[id]),deepOps
        changedSects[id]= {New:newSect,Old:oldSect or null}
        if oldSect
             mc++
        else ac++

    log "  do deleted if #{!!membre}"
    if membre
        for id,oldSect of oldSectMap when (oldSect.membre is membre) and id not of newSectMap
            changedSects[id]= {New:null,Old:oldSect}
            dc++


    log "  finished changedSects got addd=#{ac} modified=#{mc} deleted=#{dc}"
    log "  newSects= ",Object.keys(newSectMap).sort()
    log "  oldSects= ",Object.keys(oldSectMap).sort()


    seg2sectUpdates= { __proto__:seg2sect }
    sectionsUpdates= { __proto__:sections }

    # Apply changes
    log "  do Apply changeSect" #,changedSects

    for sectId,{ New, Old } of changedSects
        switch
            when Old and New
                #find seg changed
                log "seg change debug"
                log " depEq=#{oDeepEq New,Old}"
                #log   "oDiff=",oDiffs New,Old,deep:1
                { ao , ab, bo }= segGroups Old.segIds,New.segIds
                log "  seg change debug { ao , ab, bo }=",{ ao , ab, bo }
                removeRefs sectId,ao,seg2sectUpdates if ao
                addRefs    sectId,bo,seg2sectUpdates if bo
                # becuase will require a resort change could change dist
                touchRefs  sectId,ab,seg2sectUpdates if ab
                sectionsUpdates[sectId]= New
            when Old
                # delete remove a seg refes
                log "seg delete"
                removeRefs sectId,Old.segIds,seg2sectUpdates
                sectionsUpdates[sectId]= null
            when New
                # add add all seg refes
                log "seg add"
                addRefs sectId,New.segIds,seg2sectUpdates
                sectionsUpdates.push New
            else #Impossible
                throw "Impossible"

    # CACREFULL seg2sect arrays should be sorted
    # they will not after the above must call seg2sectUpdateSort after all updates
    log "  do sort"
    seg2sectUpdateSort seg2sectUpdates,sectionsUpdates


    { changes:{ seg2sect:seg2sectUpdates, sections:sectionsUpdates }, changedSects}


module.exports= { Condition, conditionsFromJSONArr, conditionsFromJSONSrc, DetectCondChange }

