{ Global, meta, log, aLast, oSplit, oAssign, oA, oDiffs, Global }= vx= require 'vx/globals/Boot'

require 'vx/map/leaflet/LeafletWidgets.css'

{ createPortal }= require 'react-dom'

{ HTMLControl, HTMLLayer, NativeGridLayer, ControlPosConvert }= require 'vx/map/leaflet/LeafletExtensions'

{ pow, log:ln}= Math

log "loading leaflet vx.isNODE=#{vx.isNODE} was iwnodw=#{window?}"

# TODO: check this
if window?
    L= ( require 'leaflet') or Global.L
    require 'leaflet/dist/leaflet.css'
    #require 'MapZen/tangram.gd'
else L= {}

{ Bounds }= require 'vx/math/Bounds'

{ render }=    require 'react-dom'

{ latM, latMInv, tile2latMswne }= require 'vx/math/MapGeometry'

{ latLng, LatLng }= L

{ mapPopup }=         require 'vx/map/MapPopup'

{ div, br, svg, g }= UI= require 'vx/UI'
{ UIComponent }= UI



{ cloneElement, Children }= require 'react'
{ map:childrenMap, toArray:childrenToArray }= Children

{ findDOMNode }= require 'react-dom'

if vx.use.vectorTiles
    mgl=  require 'mapbox-gl'
    mgll= require 'mapbox-gl-leaflet'
    mglcss= require 'mapbox-gl/dist/mapbox-gl.css'
    #log "required vectorTiles got ",{mgl,mgll,mglcss}



asLatLng= (ll)-> return ll
        # leaflet is forgiving
#         switch ll.constructor
#             when LatLng then    ll
#             when Object then    latLng ll.lat,ll.lng
#             when Array  then    latLng ll[0],ll[1]
#             else                latLng ll.lat,ll.lng #best guest

asLatLngArr= (latlngArr)->
        return [] if !latlngArr or !latlngArr.length
        if latlngArr[0].x?
            return ( { lat:pt.y, lng:pt.x } for pt in latlngArr )
        latlngArr
        # Leftlet is forgiving
#         return [] if not latlngArr
#         return latlngArr if latlngArr.length is 0
#         switch latlngArr[0].constructor
#             when LatLng then    latlngArr
#             when Object then    asGLatLngArrObj latlngArr
#             when Array  then    ( new LatLng latlng[0],latlng[1] for latlng in latlngArr )
#             when Number then    ( new LatLng lat,latlngArr[i+1]  for lat,i in  latlngArr by 2 )
#             else                asGLatLngArrObj latlngArr #best guest

# asLatLngArrObj= (latlngArrObj)->
#         return [] if not latlngArrObj
#         return latlngArr if latlngArrObj.length is 0
#         ret=switch typeof latlngArrObj[0].lat
#             when 'number'   then  ( new LatLng latlng.lat,  latlng.lng   for latlng in latlngArrObj )
#             when 'function' then  ( new LatLng latlng.lat(),latlng.lng() for latlng in latlngArrObj )
#             when 'undefined' then ( new LatLng latlng.y,    latlng.x   for latlng in latlngArrObj ) #best guess
#             else [] # throw error ?
#         log "asLatLngArrObj got",{latlngArrObj,ret}
#         ret



llEqual= (a,b)->
    if a.equals
        a.equals b
    else if b.equals
        b.equals a
    else a.lat is b.lat and a.lng is b.lng


mapControl= meta class MapControl extends UIComponent
    @displayName: 'MapControl'

    @propTypes =
        id:         @type.string
        position:   @type.string
        index:      @type.number
        leafletMap: @type.object

    # carefull this all works without using proxys cause 'children dont change
    # so we only have attribute re-aligmenents occure, if children changes
    # react will compllain that children 'arent' their any more cause their now in the map divs...
    
    constructor: ->
        #log "map control constructor"
        super ...arguments
        @portal= document.createElement 'div'
        @portal.className= 'leafletControlPortal'

    render: -> createPortal @props.children,@portal

    componentDidMount: ->
        #log "Component did mount"
        @initLMap @portal

    initLMap: (node)->
        { leafletMap }= @props
        #log "#{@name}.initLMap props=",@props

        return if not node #should throw an error
        @ctrl= new HTMLControl node, @props.position

        #return if not map

        if not leafletMap #should throw an error
            log "Missing leafletMap",{L,leafletMap}
            return

        leafletMap.addControl @ctrl

    componentWillUnmount: ->
        @props.leafletMap.remove @ctrl if @props.leafletMap and @ctrl
        @portal= null
        @ctrl= null



meta class GenericLayer extends UIComponent
    @displayName: 'GenericLayer'

    @propTypes =
        visible:    @type.bool
        onDrag:     @type.func
        onDragEnd:  @type.func
        onClick:    @type.func
        leafletMap:    @type.object #mandantory at creation

    layer: null # the leaflet marker

    render: ->
        # update the the layer
        null

    makeLayer:-> @layer= null #implement in descendents

    componentDidMount:->
        @makeLayer()
        @_updateProp_visible @props.visible if @props.visible

    _updateProp_visible:(visible)->
        if !@props.leafletMap
            #log "GenericLayer._updateProp_visible to #{visible} no leafletMap on ",@ if vx.isDEV
            return
        if visible
            #log "GenericLayer._updateProp_visible addLayer for ",{comp:@,@layer} if vx.isDEV
            @props.leafletMap.addLayer @layer
        else
            #log "GenericLayer._updateProp_visible remove layer for ",{comp:@,@layer} if vx.isDEV
            @props.leafletMap.removeLayer @layer


    #WillReceiveProps better than DidUpdate cause we can change state withou generting a render loop
    #componentDidUpdate: ( prevProps,prevState) ->
    componentWillReceiveProps: ( newProps) ->
        @_updateProps (oDiffs newProps,@props),newProps

    _updateProps_ignores:false

    _updateProps: (diffs,newProps)->
        return if not diffs
        ignores= @_updateProps_ignores
        for name,{a,b} of diffs when !ignores or !ignores[name]
            if (updateFnc="_updateProp_#{name}") of @
                    @[updateFnc] a,b,diffs,newProps
            else log "#{@_displayname} componentDidUpdate ignore change for #{name}",{a,b} if @props.debug
        null

    componentWillUnmount: ->
        @props.leafletMap.removeLayer @layer if @props.visible
        @layer= null



marker= meta class Marker extends GenericLayer
    @displayName: 'Marker'


    @propTypes= oAssign {},@propTypes,
        position:     @type.oneOfType [@type.object,@type.array]
        onNewPostion: @type.func
        label:        @type.string



    makeLayer:->
        { leafletMap, position, onClick, onDrag, onDragEnd, onContextMenu, name, value, icon, label, visible=true }= @props


        mops=
            clickable: (!!onClick) or undefined
            draggable: (!!onDrag or !!onDragEnd) or undefined
            title: label

        if icon
            if typeof icon is 'string'
                #class name = file name
                mops.url= icon
                s= aLast icon.split '/'
                mops.icon= L.divIcon
                    iconSize:[20,34]
                    iconAnchor:[10,34]
                    iconUrl:   icon
                    className: "customLeaftletIcon #{(s.split '.')[0] or ''}"
            else
                mops.icon= L.divIcon
                    iconSize:[20,34]
                    iconAnchor:[10,34]
                    iconUrl:   icon.url
                    className: "customLeaftletIcon #{icon.class or ''}"


        @layer= cmarker= new L.Marker asLatLng(position),mops
        debug= @props.debug
        #TODO inverse pt and value in drag events ...
        if onDrag
            cmarker.on 'drag',(e)->
                pos= cmarker.getLatLng()
                alatlng= [ pos.lat, pos.lng ]
                log "drag on #{typeof name} #{name} alatlng=#{alatlng}" if debug
                onDrag value,name,alatlng
        if onDragEnd
            cmarker.on 'dragend',(e)->
                pos= cmarker.getLatLng()
                alatlng= [ pos.lat, pos.lng ]
                log "dragend on #{typeof name} #{name} alatlng=#{alatlng}" if debug
                onDragEnd value,name,alatlng
        if onClick
            cmarker.on 'click',(e)->
                pos= cmarker.getLatLng()
                alatlng= [ pos.lat, pos.lng ]
                log "click on #{typeof name} #{name} alatlng=#{alatlng}" if debug
                onClick value,name,alatlng
        if onContextMenu
            cmarker.on 'contextmenu',(e)->
                pos= cmarker.getLatLng()
                alatlng= [ pos.lat, pos.lng ]
                log "contextmenu on #{typeof name} #{name} alatlng=#{alatlng}" if debug
                onContextMenu value,name,alatlng

    _updateProp_position:(pos)-> @layer.setLatLng asLatLng pos

    _updateProp_icon:(icon)->
        #log "Marker update icon=",icon
        if typeof icon is 'string'
            #class name = file name
            #mops.url= icon
            s= aLast icon.split '/'
            icon= L.divIcon
                iconSize:[20,34]
                iconAnchor:[10,34]
                iconUrl:   icon
                className: "customLeaftletIcon #{(s.split '.')[0] or ''}"
        else
            icon= L.divIcon
                iconSize:[20,34]
                iconAnchor:[10,34]
                iconUrl:   icon.url
                className: "customLeaftletIcon #{icon.class or ''}"

        @layer.setIcon icon

    _updateProp_label:(label)->
        @layer._icon.title= label


polyline= meta class Polyline extends GenericLayer
    @displayName: 'Polyline'


    @propTypes= oAssign {},@propTypes,
        path:       @type.array
        color:      @type.string
        opacity:    @type.number
        weight:     @type.number


    makeLayer:->
        { leafletMap, path, color='#0000FF', opacity=1.0, weight=2, visible=true }= @props

        pathlatlng= asLatLngArr path

        @layer= L.polyline pathlatlng,
            color:color
            opacity:opacity
            weight:weight
            className: 'polyline'

    _updateProp_path:(path)-> @layer.setLatLngs asLatLngArr path

    _update: (diffs,newProps=@props)->
        return if not diffs
        newStyle= null
        for name,{a,b} of diffs
            switch name
                when 'visible' then @_updateProp_visible a,b
                when 'path'    then @_updateProp_path a,b
                when 'color','width','opacity','weight'
                        newStyle ?= {}
                        newStyle[name]=a
                else
                    log "#{@_displayname} componentDidUpdate ignore change for #{name}",{a,b} if @props.debug

        @layer.setStyle newStyle if newStyle

        null


tileLayer= meta class TileLayer extends GenericLayer
    @displayName: 'TileLayer'

    @propTypes= oAssign {},@propTypes,
        getTileUrl: @type.func
        tileURL:    @type.string
        opacity:    @type.number
        minZoom:    @type.number
        maxZoom:    @type.number
        minNativeZoom:    @type.number
        maxNativeZoom:    @type.number
        zoomOffset: @type.number
        name:       @type.string
        alt:        @type.string
        tileSize:   @type.number
        updateWhenZooming: @type.bool
        zIndex:     @type.number
        debug:      @type.bool
        info:       @type.object
        pane:       @type.string



    basicTileUrlFn: ({x,y},zoom)->
        xy= ( if x<10 then x else Math.floor( x/10 % 10)).toString()+(if y<10 then y else Math.floor(y/10 % 10)).toString()
        "#{@props.tileUrl}/z#{17-zoom}/xy#{xy}/map-#{17-zoom}-#{x}-#{y}.png"


    makeLayer: ->

        { tileURL, opacity=1.0, minZoom=0, maxZoom=18, minNativeZoom, maxNativeZoom, getTileUrl,tileSize=256,name,alt,zoomOffset=0, updateWhenZooming=false,zIndex=1, pane }= @props
        #if tileUrlnot getTileUrl
        #    getTileUrl=@basicTileUrlFn.bind @


        #log "TileLayer.makeLayer for props=",@props
        @layer= L.tileLayer tileURL,{maxZoom,minZoom,minNativeZoom, maxNativeZoom,tileSize,zoomOffset,updateWhenZooming,zIndex} #,pane}
        #log "TileLayer.makeLayer dir layer=",{@layer}

        @layer


vectorTileLayer= meta class VectorTileLayer extends GenericLayer
    @displayName: 'VectorTileLayer'

    @propTypes= oAssign {},@propTypes,
        tileURL:    @type.string
        transfrom:  @type.string
        cache:      @type.object
        opacity:    @type.number
        minZoom:    @type.number
        maxZoom:    @type.number
        updateWhenZooming: @type.bool
        zIndex:     @type.number
        debug:      @type.bool
        pane:       @type.string
        accessToken: @type.string


    makeLayer: ->

        { tileURL, minZoom=0, maxZoom=18, updateWhenZooming=false, zIndex=1, accessToken, transform, cache, debug }= @props
        #if tileUrlnot getTileUrl
        #    getTileUrl=@basicTileUrlFn.bind @

        if transform and cache
            throw new Error "Cant have cache and transform for",@

        if cache
            log "VectorTileLayer.makeLayer using cache" if debug
            transformRequest= (url,mode)->
                return if mode isnt 'Tile' 
                log "vector ask for #{url}" if debug
                [z,x,y]= (url.split '/')[-3 ..]
                y= (y.split '.')[0]
                if cache[z]?[x]?[y]
                     log "vector cache hit for #{z} #{x} #{y}" if debug
                     url:url
                else
                    log "vector cache miss for #{z} #{x} #{y}" if debug
                    url:null

#                 if 8< zi= parseInt z
#                     log "hit over 8"
#
#                     s= pow(2,zi-8)
#                     xi= (parseInt x)//s
#                     yi= (parseInt y)//s
#
#                     nurl= (url.split '/')[.. -4].concat([8,xi,"#{yi}.pbf"]).join '/'
#                     log "repace #{url} by #{nurl}"
#                     return url:nurl
#                 #log "vector ask for #{url}"
#                 #url
#                 null

        else if vx.isDEV and Global.location.hostname is 'localhost' # npm start

            origin= Global.location.protocol+'//dev-app.fcmqapi.ca/'

            log "VectorTileLayer.makeLayer using auto-transform origin=#{origin}" if debug
            transformRequest= (url,mode)->
                if url[..7] isnt 'mapCache'
                    log "VectorTileLayer.auto-transform ignore #{mode} for #{url}" if debug
                    return

                newUrl= origin+url
                if mode isnt 'Tile'
                    log "MAPBOXGL fetch #{mode} #{url} origin=#{origin}" if debug
                log "VectorTileLayer.auto-transform using #{newUrl}" if debug
                url:newUrl


        else if vx.isCORDOVA #use auto transform
            switch Global.location.origin[0 .. 3]
                when 'http'
                    origin= Global.location.origin+'/'
                when 'file'
                    origin= "#{Global.cordova.file.applicationDirectory}www/"
                    log "VectorTileLayer.makeLayer using auto-transform file origin=#{origin}" if debug
                else
                    origin= Global.location.origin
                    log "VectorTileLayer.makeLayer using auto-transform unknown origin=#{origin}" if debug


            log "VectorTileLayer.makeLayer using auto-transform origin=#{origin}" if debug
            transformRequest= (url,mode)->
                if url[..7] isnt 'mapCache'
                    log "VectorTileLayer.auto-transform ignore #{mode} for #{url}" if debug
                    return

                newUrl= origin+url
                if mode isnt 'Tile'
                    log "MAPBOXGL fetch #{mode} #{url} origin=#{origin}" if debug
                log "VectorTileLayer.auto-transform using #{newUrl}" if debug
                url:newUrl

        else if transform
            log "VectorTileLayer.makeLayer using transform" if debug
            transformRequest= transform
        else if tileURL[0 .. 7 ] is 'mapCache'
            log "VectorTileLayer.makeLayer using auto http transform" if debug
            origin= Global.location.origin+'/'
            transformRequest= (url,mode)->
                url:origin+url



        @layer= L.mapboxGL vops={
            style: tileURL
            accessToken: accessToken or 'no token'
            maxzoom:maxZoom
            collectResourceTiming: false
            transformRequest
            }

        log "VectorTileLayer.makeLayer cache is #{!!cache} layer=",{@layer,vops} if debug or vx.isDEV
        @layer



    _updateProp_tileURL:(newUrl,oldUrl)->

        log "VectorTileLayer._updateProp_tileUrl setting style url to #{newUrl} was #{oldUrl} @layer is #{!!@layer} getMapBoxMap is #{!!@layer?.getMapboxMap?}" if @props.debug or vx.isDEV
        return if !@layer
        @layer.getMapboxMap?().setStyle newUrl




wmsTileLayer= meta class WMSTileLayer extends TileLayer
    @displayName: 'WMSTileLayer'

    @propTypes: oAssign {},@propTypes,
        wmsOps: @type.object

    makeLayer: ->

        { tileURL, wmsOps, opacity=1.0, minZoom=0, maxZoom=18, minNativeZoom, maxNativeZoom ,tileSize=256,name,updateWhenZooming=false,zIndex=10,pane }= @props
        #if tileUrlnot getTileUrl
        #    getTileUrl=@basicTileUrlFn.bind @

        @layer= L.tileLayer.wms tileURL,
            opacity: opacity
            maxZoom: maxZoom
            minZoom: minZoom
            minNativeZoom: minNativeZoom
            maxNativeZoom: maxNativeZoom
            tileSize: tileSize
            updateWhenZooming: updateWhenZooming
            layers: wmsOps.layers
            style:  wmsOps.style
            format: wmsOps.format
            lang:   wmsOps.lang
            transparent: true
            zIndex: zIndex
            pane: pane or 'tilePane'



dynamicTileLayer= meta class DynamicTileLayer extends TileLayer
    @displayName: 'DynamicTileLayer'


    @propTypes= oAssign {},@propTypes,
        content:    @type.object
        latmContent:@type.bool
        renderTile:   @type.func
        cacheSize:  @type.number
        debug:      @type.bool
        #added
        id:         @type.string # should be in generic layer ?
        name:       @type.string # " "
        marginFixe: @type.number #content access margins
        marginPct:  @type.number
        info:       @type.object
        updateWhenZooming: @type.bool
        keepBuffer: @type.number
        updateWhenIdle: @type.bool
        pane:       @type.string



    componentWillMount:->
        #super()
        log "DynamicTileLayer.componentWillMount ==================== DYNAIC TILE TILELAYER WILL MOUNT props=",{props:@props,self:@} if @props.debug
        if @props.cacheSize
            @cacheLRU= new Array @props.cacheSize
            @cacheMap= new WeakMap


    _updateProps_ignores:
        content:1


    componentDidUpdate:(prevProps,prevState)->
        # super prevProps,prevState there is no super
        #log "DynamicTileLayer.componentDidUpdate update content=#{prevProps.content isnt @props.content} content=#{prevProps.info isnt @props.info}" if @props.debug
        if ( prevProps.content isnt @props.content ) or ( prevProps.info isnt @props.info ) # info considered part of content
            # content update
            @_didUpdateProp_content @props.content,@props.info


        null

    _didUpdateProp_content: (newContent,newInfo)->
        @refresh()

    makeLayer: ->
        { maxZoom=18, minZoom=0,tileSize=256,updateWhenZooming=false,keepBuffer=3,updateWhenIdle=true,zIndex=10,pane }= @props
        #@visibleTiles= {}
        ops= {maxZoom,minZoom,tileSize,updateWhenZooming,keepBuffer,updateWhenIdle,zIndex,pane}
        @layer= new L.GridLayerNoZoom ops
        @layer.createTile= @doCreateTile
        #@layer._zoomAnimated= false
        #@layer.on 'tileunload',@doUnloadingTile
        log "DynamicTileLayer.makeLayer made",@layer if @props.debug
        @layer


    doCreateTile: (coords)->
        {x,y,z:zoom}= coords
        ezoom= @layer.getEffectiveZoom?() or zoom
        tileId="#{x}-#{y}-#{ezoom}"


        if @cacheMap
            if tileNode= @cacheMap[tileId]
                log "cache hit #{tileId}" if @props.debug
                @cacheLRU.unshift()
                @cacheLRU.push tileNode
                #@visibleTiles[tileId]= tileNode
                return tileNode

        #no tileNode
        tileSize= @layer.getTileSize().x
        swne= tile2latMswne x,y,ezoom
        tileOps= @addContent {swne,x,y,zoom,ezoom,tileSize,content:null,tileId,info:@props.info }

        if @props.renderTile
                tileContent= @props.renderTile.call @,tileOps
        else tileContent= @renderTile tileOps

        node = document.createElement 'div'
        #log "Dynamin tile render got tile=",tile
        render tileContent,node
        #tileNode=node.removeChild node.childNodes[0]
        tileNode= node
        #log "#{@name} returning node= ",{tileNode,tile,zoom}

        if @cacheMap
            log "cache add #{tileId} tileNode=",tileNode if @props.debug
            @cacheMap[tileId]= tileNode if @props.debug
            @cacheLRU.unshift()
            @cacheLRU.push tileNode
        #@visibleTiles[tileId]= tileNode
        tileNode


    refresh:->
        log "DynamicTileLayer.refresh" if @props.debug
        #clear cache and reload all visible tiles
        if @cacheMap
            @cacheMap= null
            @cacheLRU= null
        if @props.cacheSize
            @cacheLRU= new Array @props.cacheSize
            @cacheMap= new WeakMap
        #@visibleTiles={}
        @layer.redraw()




    doUnloadingTile: ({tile})->
        if id= tile.dataset.tileid
            tile= @visibleTiles[id]
            log "DynamicTileLayer.doUnloadingTile #{id}",{tile,self:@} if @props.debug

            delete @visibleTiles[id]
        else
            log "DynamicTileLayer.doUnloadingTile failed for #{id}" if @props.debug


    addContent:(ops)->
        if @props.content
            [s,w,n,e]= ops.swne
            bnds= Bounds._ w,s,e,n
            if (mf= @props.marginFixe or 0) or (mp=@props.marginPct or 0)
                {dx,dy}= bnds.size()
                area= bnds.grow (mf+mp*dx),(mf+mp*dy)
            else area= bnds
            if not @props.latmContent
                area.y= latMInv area.y
                area.Y= latMInv area.Y

            ops.content= @props.content.getIntersects area

        ops

    renderTile: ({swne:[s,w,n,e],x,y,zoom,ezoom,tileSize,content,tileId})->
        log "Defualt renderTile got ",{swne:[s,w,n,e],x,y,zoom,ezoom,tileSize,content,tileId} if @props.debug
        r= (x)-> x.toFixed 1
        { className, opacity=1}= @props #tileSize=256,

        div
            className: className
            '-tileid': tileId
            $width: "#{tileSize}px"
            $height:"#{tileSize}px"
            $fontSize:'10px '
            $border:  '1px solid #AAAAAA'
            $opacity: "#{opacity}"
            "tile: #{x},#{y}, #{zoom} #{tileSize}",br()
            "-- #{r latMInv n} --",br()
            "#{r w}--#{r e}",br()
            "-- #{r latMInv s} --",br()



# meta class NativeDynamicTileLayer extends DynamicTileLayer
#     @displayName: 'NativeDynamicTileLayer'
# 
#     makeLayer: ->
#         { maxZoom=18, minZoom=0,maxNativeZoom, minNativeZoom,tileSize=256,updateWhenZooming=false,zIndex=10}= @props
#         @layer= new NativeGridLayer {maxZoom,minZoom,maxNativeZoom, minNativeZoom,tileSize, updateWhenZooming,zIndex}
#         #log " LLLLLLLLLLLLLLLLLLLLLLLLL layer create native d layer=",@layer
#         @layer.createTile= @doCreateTile



cachedZoomTileLayer= meta class CachedZoomTileLayer extends TileLayer
    @displayName: 'CachedZoomTileLayer'

    @propTypes= oAssign {},@propTypes,
        cache: @type.object

    makeLayer: ->

        { tileURL, opacity=1.0, minZoom=0, maxZoom=18, minNativeZoom, maxNativeZoom, tileSize=256,name,alt, cache,zoomOffset,updateWhenZooming=false,keepBuffer=3,updateWhenIdle=true,zIndex=1 }= @props
        #if tileUrlnot getTileUrl
        #    getTileUrl=@basicTileUrlFn.bind @
        ops= {maxZoom,minZoom,minNativeZoom, maxNativeZoom,tileSize,cache,zoomOffset,updateWhenZooming,keepBuffer,updateWhenIdle,zIndex}
        log "@@@@@@@@@@@@@@@@@@@@@@@@@2 creating CachedZoomTileLayer ops=",ops
        @layer= new L.CachedZoomTileLayer tileURL,ops


# meta class MapHTMLDiv extends GenericLayer
#     @displayName: 'MapHTMLDiv'



mapHTML= meta class MapHTML extends GenericLayer
    @displayName: 'MapHTML'

    @propTypes= oAssign {},@propTypes,
        top:    @type.number
        bottom: @type.number
        left:   @type.number
        right:  @type.number
        height: @type.number
        width:  @type.number
        dontPositionCont: @type.bool

        content: @type.object


    makeLayer:->
        cls= 'map-html vx-react-root '+ (@props.className or '')
        #log " &&& mapHTML make HTMLLayer cls=#{cls} dontPositionCont=#{@props.dontPositionCont}"
        @layer= new HTMLLayer
            __proto__: @props
            children: undefined
            content: @props.children
            className: cls

    _update: (diffs)->
        return if not diffs
        newPos= null
        for name,{a,b} of diffs
            switch name
                when 'visible' then @_updateProp_visible a,b
                when 'children' then @_updateProp_children a,b
                when 'top','left','right','bottom','width','height'
                        newPos ?= {}
                        newPos[name]=a
                else log "#{@_displayname} componentDidUpdate ignore change for #{name}",{a,b} if @props.debug

        @layer.setPos newPos if newPos


    _updateProp_children:(content)->
        #log "MapHTML update content=",content
        return null if not @layer
        @layer.options.content= content
        @layer._update_content()

        null



mapSVG= meta class MapSVG extends UIComponent
    @displayName: 'MapSVG'

    @propTypes= oAssign {},@propTypes,
        top:    @type.number
        topl:    @type.number
        bottom: @type.number
        bottoml: @type.number
        left:   @type.number
        right:  @type.number
        height: @type.number
        width:  @type.number
        content: @type.object



    render: (props=@props)->

        {  topl,top=(latM topl), left,right, bottoml,bottom=(latM bottoml), scale=1, gmapMap, className='',id, zIndex, visible= true }= props

        topl?=    latMInv top
        bottoml?= latMInv bottom
        w= scale*left
        e= scale*right
        n= scale*top
        s= scale*bottom

        mapHTML '.maphtmlsvg',(oA {},props,{children:null,bottoml:null,topl:null,dontPositionCont:true,top:topl,bottom:bottoml}),
            svg '.mapsvgct preserveAspectRatio=none',viewBox:"#{w} #{-n} #{e-w} #{n-s}",
                g 'transform=scale(1,-1)',props.children





mapContainer= meta class MapContainer extends UIComponent
    @displayName: 'MapContainer'
    # props types see Widget

    constructor:(props)->
        super props
        vx.MC=@

        #init done by map idle can be delayed unltil gmaps is available....
        @state=
            zoom:   null #props.zoom
            center: null #props.center
            bounds: null #props.bounds
        return @

    #alphabet='abcdefghijklmnopqrstuvwxyz'
    zoomStr='00 01 02 03 04 05 06 07 08 09 10 11 12 13 14 15 16 17 18 19 20 21 22 23 24'

    render:->
        zoom= @state.zoom
        #log "Map container render zoom=#{zoom}"
        if zoom?
            gt=zoomStr[... 3*zoom]
            lt=zoomStr[3*(1+zoom)..]
            z=zoomStr[(3*zoom) .. 1+(3*zoom)]
            pixelSize= (360/256)/2**zoom
        else
            lt=gt=z=''
            pixelSize= 0.1


        div '.map-container .leaflet-map',
            'data-zoom-lt': lt # '00 01 02 03 04 05 06' #
            'data-zoom-gt': gt # '08 09 10 11 12 13 14 15 16 17 18 19 20 21 22 23 24' #
            'data-zoom':    z  # '07' #
            style:
                '--svgpixelsize': "#{pixelSize}px"



    componentDidMount: -> # DOinit
        @node= findDOMNode @
        @initLMap @node
        null

    componentWillUpdate: (newProps)->
        return if not lmap= @leafletMap
        #log "MapContainer Widget componentWillUpdate leaflet is #{!!L} ",{props:@props,state:@state,newProps}

        changeState= false
        newState= {}

        if @props.zoom isnt newProps.zoom and newProps.zoom isnt @state.zoom
            log "setMap zoom to #{newProps.zoom}" if @props.debug
            lmap.setZoom newProps.zoom
            changeState= true
            newState.zoom= newProps.zoom

        if not( llEqual @props.center,newProps.center) and not(llEqual newProps.center,@state.center)
            log "set map center to ",newProps.center if @props.debug
            lmap.setView newProps.center, newProps.zoom
            changeState= true
            newState.center= newProps.center

        if changeState
            @setState newState


    componentWillUnmount: -> #todo remove events


    initLMap: (node)->
        return if not L
        return if not node #should throw an error

        {
            center={lat:47,lng:-71},
            zoom=7,
            onBkgClick,
            onBkgDoubleClick,
            mapOps
        }= @props

        center= asLatLng center

        #now done by triggering an 'idle' event
        #@state.center= center # I Know but its hack...
        #@state.zoom= zoom


        ops= oAssign
            zoomControl: false
            doubleClickZoom: false
            fadeAnimation:  true
            zoomAnimation:  true
            attributionControl: false
            markerZoomAnimation: false
            zoomAnimationThreshold:8
            wheelPxPerZoomLevel:100
            ,mapOps



        @leafletMap= L.map node,ops # new MapDebug div,ops #
        #log "@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@2  map with",{map:@leafletMap,node:node,ops:ops, width:node.clientWidth, height:node.clientHeight, lat:center.lat,lng:center.lng}
        @leafletMap.addControl L.control.zoom
            position: ControlPosConvert['BOTTOM_RIGHT']

        @leafletMap.addControl L.control.scale
            position: ControlPosConvert['BOTTOM_LEFT']

        #state managers
        On= @leafletMap.on.bind @leafletMap

        doBoundsChange= =>
            change={}
            changed= false
            newBounds= @leafletMap.getBounds()
            if !@state.bounds or !(newBounds.equals @state.bounds)
                changed= change.bounds= newBounds

            newCenter= @leafletMap.getCenter()
            if !@state.center or !(newCenter.equals @state.center)
                changed= change.center= newCenter


            newZoom= @leafletMap.getZoom()
            #log "IIIIIIIIIIII Idle current zoom is #{newZoom} stae=#{@state.zoom} a=#{@state.zoom isnt 0 and !@state.zoom} or b#{!(newZoom is @state.zoom)}"
            if (@state.zoom isnt 0 and !@state.zoom) or !(newZoom is @state.zoom)
                changed= change.zoom= newZoom
                #log "zoom changed to #{newZoom}"
                @props.onNewZoom? newZoom,'zoom',@

            if changed
                #log "IIIIIIIIIIIII idle change=",change
                @setState change
                name=@props.name or 'lmap'
                @props.onNewBounds? (Bounds.fromNative change.bounds),"#{name}_bounds",@ if change.bounds?
                @props.onNewCenter? change.center,"#{name}_center",@ if change.center?
                @props.onNewZoom?   change.zoom,  "#{name}_zoom",  @ if change.zoom?

        On 'zoomstart',@doZoomStart
        On 'zoomend',@doZoomEnd
        On 'zoomend',doBoundsChange
        On 'moveend',doBoundsChange
        On 'resize',doBoundsChange
        On 'load',doBoundsChange
        @leafletMap.setView center,zoom

        #@_test= new HTMLMapZone left:-72,top:47,bottom:46.9,right:-71.9
        #@_test.addTo @leafletMap
        #@_marker= L.marker [47,-72]
        #@_marker.addTo @leafletMap

        if onBkgClick
            @_clickListener= On 'click',(e)=>
                #log "LMAPS MAP click e=",e
                @leafletMap._vx_clicktimer= setTimeout (=>
                        #log "click timeout"
                        @leafletMap._vx_clicktimer= null
                        @props.onBkgClick e,@
                        ),50

        # always therre small, avoids updating/checking on props chane, TODO: same for click
        On 'dblclick',(e)=>
            log "leaflet map double click" if @props.debug
            @props.onBkgDoubleClick e,@ if typeof @props.onBkgDoubleClick is 'function'


    doZoomEnd: ->
        @node?.classList.remove 'vx-zooming'
        log "zoomend" if vx.isDEV

    doZoomStart: ->
        @node?.classList.add 'vx-zooming'
        log "zoomstart" if vx.isDEV


mapWidget= meta class MapWidget extends UIComponent
    @displayName: 'MapWidget'

    @propTypes =
        name:   @type.string
        promise: @type.string # ? Cause isn gmaps
        center: @type.object
        zoom:   @type.number
        bounds: @type.object
        onBkgClick: @type.func
        onNewBounds: @type.func
        onNewCenter: @type.func
        onNewZoom:   @type.func
        mapTypeControl: @type.bool
        mapTypeControlOptions: @type.object
        mapOps: @type.object

    constructor:(props)->
        super props
        @state=
            leafletMap:null


    panTo: (pt)->
        @state.leafletMap?.panTo pt

    fitBounds: (bnds)->
        log "leaflet map fitBounds=",bnds
        @state.leafletMap?.fitBounds [[bnds.y,bnds.x],[bnds.Y,bnds.X]]

    render:->

        props= oAssign {ref:@setMap},@props,{children:null}
        div '.map-widget',
            mapContainer props
            if @state.leafletMap and @props.children
                #log " RRRRRRRRRRRRR mapWidget has children ",@props.children
                i=1
                leafletMap= leafletMap:@state.leafletMap

                childrenMap @props.children,(child)->
                    if child
                        cloneElement child,leafletMap
                    else child




        #todo bounds check



    #renderMapChildren:-> @props.children


    setMap: (aMapContainer)=>
        #log "$$$$$$ mapWidget mapContainer #{aMapContainer} leafletMap=#{aMapContainer?.leafletMap} from MapContainer ",aMapContainer if vx.isDEV
        if aMapContainer?.leafletMap
            @setState leafletMap:aMapContainer.leafletMap
            if @props.promise
                Promise.vxGet @props.promise
                    .vxResolve @






module.exports=  { mapWidget, mapContainer, mapHTML, cachedZoomTileLayer, dynamicTileLayer, wmsTileLayer, vectorTileLayer, tileLayer, TileLayer, polyline, mapPopup, marker, mapControl, mapSVG, MapSVG, API:'leaflet' }


