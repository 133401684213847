{ log, oAssign }= vx= require 'vx/globals/Boot'


{ min, max, sqrt } = Math



# **** line point functions line from p0 to p1 relative to pt #############

intersectionXY=(x0,y0, x1,y1, xt,yt )-> # x0,y0 x1,y1 is the line xt,yt is the target point

    dx = x1-x0
    dy = y1-y0

    lSq = dx*dx + dy*dy

    uo= if lSq != 0 then ((xt - x0) * dx + (yt - y0) * dy) / lSq
    else  0  # cause Pt0 == Pt1

    u= max(0,min(1,uo))

    x = x0 + u * dx
    y = y0 + u * dy

    {x,y,u,uo}


distInfoSq2XY=(x0,y0, x1,y1, xt,yt )-> # x0,y0 is the line xt,yt is the target point

    i= intersectionXY(x0,y0,x1,y1,xt,yt)
    i.dSq= (dx = i.x - xt)*dx+(dy = i.y - yt)*dy
    i

distInfo2XY=(x0,y0, x1,y1, xt,yt )->
    d= distInfoSq2XY x0,y0, x1,y1, xt,yt
    d.dist= sqrt d.dSq
    oAssign d,{x0,y0, x1,y1, xt,yt}
    d


intersectionPt=  (p0,p1,pt)-> intersectionXY( p0.x,p0.y, p1.x,p1.y, pt.x,pt.y )
distSq2Pt=(p0,p1,pt)-> distInfoSq2XY(   p0.x,p0.y, p1.x,p1.y, pt.x,pt.y )


distInfo2Pt=  (p0,p1,pt)-> distInfo2XY(     p0.x,p0.y, p1.x,p1.y, pt.x,pt.y )


distanceSq= (x0,y0,x1,y1)-> (dx=x1-x0)*dx + (dy=y1-y0)*dy
distance=   (x0,y0,x1,y1)-> sqrt distanceSq x0,y0,x1,y1



distancePt=   (p0,p1)-> distance( p0.x,p0.y, p1.x,p1.y )

ptAtU= (x0,y0,x1,y1,u)-> { x:x0+(x1-x0)*u, y:y0+(y1-y0)*u }
ptAtUPt= (p0,p1,u)-> ptAtU( p0.x,p0.y, p1.x,p1.y,u )



module.exports= { ptAtUPt, distance, distancePt, distInfo2Pt, distInfo2XY, intersectionXY }
