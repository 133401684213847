{ log, Global, On, $ }= Mod= vx= require 'vx/globals/Boot'

require 'vx/comp/fullScreen/FullScreen.css'

prefixNames=
    'none':  'fullscreenEnabled       fullscreenchange       exitFullscreen       requestFullscreen        fullscreenElement      '.split /\s+/
    'webkit':'webkitFullscreenEnabled webkitfullscreenchange webkitExitFullscreen webkitRequestFullscreen  webkitFullscreenElement'.split /\s+/
    'moz':   'mozFullScreenEnabled    mozfullscreenchange    mozCancelFullScreen  mozRequestFullScreen     mozFullscreenElement   '.split /\s+/
    'ms':    'msFullscreenEnabled     msfullscreenchange     msExitFullscreen     msRequestFullscreen      msFullscreenElement    '.split /\s+/


if Global.document
    for prefix,[fullscreenEnabled] of prefixNames when document[fullscreenEnabled]
        break


if !prefix
    FullScreenStateManager= hasFullScreen= null
else
    hasFullScreen= true
    [fullscreenEnabled,fullscreenchange,exitFullscreen,requestFullscreen,fullscreenElement]= prefixNames[prefix]

    #log "FullScreen names=",{fullscreenEnabled,fullscreenchange,exitFullscreen,requestFullscreen,fullscreenElement,prefixNames}

    class FullScreenStateManager

        #noCircuitsProposed=[]

        defaultState:
            isFullScreen: !!document[fullscreenElement]

        urlAPIVars:
            fullScreen:(val)->
                if val and !document[fullscreenElement]
                    @toogleFullScreen()

        onMountDo: ['watchFullScreen']


        watchFullScreen: ->
            On document,fullscreenchange,@doFSChange

        doFSChange: ->
            newFSState= !!document[fullscreenElement]
            log "Got a full screen change isFullScreen=#{newFSState} " #,{fullscreenElement,elem:document[fullscreenElement]}
            @setState isFullScreen:newFSState

        toogleFullScreen:->
            if !!document[fullscreenElement]
                 document[exitFullscreen]()
            else $('body')[requestFullscreen]() # @appNode?[requestFullscreen]()



module.exports= { FullScreenStateManager, hasFullScreen  }


