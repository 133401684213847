#!/usr/bin/coffee
{ meta, oAssign, log, time, aLast, Global, oAssign, sSet, sList, isNumber, timeStart, timeEnd, aFlaten, isArray, isPrefix, isSuffix }= vx= require 'vx/globals/Boot'

{ Bounds }= require 'vx/math/Bounds'
LZString=     require 'lz-string-gd'
P= require 'vx/tools/Persistance'
{ AttraitsPrefixV2,  AttraitsSuffixV2 }= require 'vx/globals/Conversions'

{ asURL, parseQS, setProtocol }= require 'vx/tools/URLTools'

# asURL=(qs,nosort)->
#     hash= qs.hash or ''
#     url= qs.url or ''
#
#     vars= ( k2 for k2 of qs when k2 isnt 'url' and k2 isnt 'hash' )
#     if !nosort
#         vars.sort()
#     #qs= ( "#{escape k}=#{escape url[k]}" for k in ( k2 for k2 of url when k2 isnt 'url' and k2 isnt 'hash' ).sort()).join '&'
#     #"""#{escape url}#{ if qs then "?#{qs}" else ''}#{ if hash then "##{escape hash}" else ''}"""
#     qs= ( "#{encodeURI k}=#{encodeURI qs[k]}" for k in vars).join '&'
#     """#{url}#{ if qs then "?#{qs}" else ''}#{ if hash then "##{hash}" else ''}"""
#
#
# parseQS= (qs,out={},debug)->
#
#         qs?= (Global.location?.search or '').substr 1
#         log "parseQS qs = #{qs}" if debug
#         for arg in qs.split '&'
#             parts= arg.split '='
#             name= parts.shift()
#             val= switch parts.length
#                 when 0 then true
#                 when 1 then decodeURIComponent parts[0].replace /\+/g, ' '
#                 else decodeURIComponent (parts.join '=').replace /\+/g, ' '
#             log "parseQS got #{name} = #{val}" if debug
#             if val[0] is ':'
#                 val= val.substr 1
#                 if val[0] isnt ':'
#                      val= try
#                             JSON.parse val
#                           catch
#                             log "URLencoders.parseQS failed on #{name}=:#{val}",{name,val,qs}
#                             val= null
#             out[name]= val if val?
#         out
#
#
# setProtocol= (url)->
#     return url if not url
#     urlparts =url.split ':'
#     protocol= urlparts[0]?.trim().toLowerCase()
#     if (protocol is 'http') or (protocol is 'https')
#         return url
#     # ok set protocole to current if its http or https else http
#     cProtocol= location.protocol
#     if not( (cProtocol is 'http:') or (cProtocol is 'https:') )
#         cProtocol= 'http:'
#     return "#{cProtocol}#{urlparts.join ':'}"




#v1 url info r=1 s=
#
#       Scale= 100000
#       Dx=  40
#       Dy= -80
#       VisibleGroupsLookup=
#             Restaurant:          0
#             Essence:             1
#             Hébergement:         2
#             'Hôtel sécuritaire': 3
#             'Location motoneige':4
#             Concesionnaire:      5
#             'Abri ou système sécurité':6
#             Bar:                 7
#             'Bed and Breakfast': 8
#             'Sites et attraits': 9
#             'Mobilité restreinte': 10
#             Stationnement:      11
#             'Réparation mécanique':12
#
#       Meteos= ['aucun','neige','SRPD','SRPE24','SRPE12','Masse-neige']
#
#    rnd (x)-> Math.round Scale*x
#
#    itin:         i = [ [rnd wp.lat-DY,rnd wp.lng-DY] | "wp.objid -attrit."  for wp in itin.waypoints
#    mapBounds:    m = [ rnd x-DX,rnd y-DY, rnd X-x, rnd Y-y] for [x,y,X,Y]= getMapBounds
#    visibleGroups:v = [ VisibleGroupsLookup[name] for own name,val of visibleGroups when val and name of VisibleGroupsLookup
#    eco:          e = 1 if state.eco
#    surfaceuses:  s = 1 if state.surfaceuses
#    meteo:        M = #indexOf meteo layer in Meteos
#
#    just for strings
#                  t = round(new Date()/1000-1483246800).toString 36 # 1483246800=(new Date 'Jan 1 2017')/1000
#
#

VisibleGroupsV1=[
    "Restaurant"
    "Essence"
    "Hébergement"
    "Hôtel sécuritaire"
    "Location motoneige"
    "Concesionnaire"
    "Relais"
    "Bar"
    "Bed and Breakfast"
    "Sites et attraits"
    "Mobilité restreinte"
    "Stationnement"
    "Réparation mécanique"
    "Refuge" # ajouter 2017/8
    "Forfait" # ajouter 2017/8 remis 2020/8
    "Point de vue" # ajouter 2020/8
    "Repos" #  # ajouter 2020/9
    "Autres" # ajouter 2020/9
    "Incitatif" # ajouter 2020/11
    "Bornes évacuation"
    ]

VisibleGroupsLookupV1={}
VisibleGroupsRevLookupV1={}
for t,i in VisibleGroupsV1
    VisibleGroupsLookupV1[t]=i
    VisibleGroupsRevLookupV1[i]=t #?? TODO was I dreaming ... why not VisibleGroupsV1[x] or for use of of operator?

MeteosV1= ['aucun','neige','SRPD','SRPE24','SRPE12','Masse-neige','Neige-radar']
MeteosV2= ['','neige','SRPD','SRPE24','SRPE12','Masse-neige','Neige-radar']


V1=
    DX: 40
    DY: -80
    SCALE: 100000
    ORIGINTIME: (new Date 'Jan 1 2017')/1000

V2=
    DX: 47
    DY: -71
    SCALE: 100000
    ORIGINTIME: (new Date 'Oct 25 2018')/1000

makeV1URL= (info)->

    s= asV1EncodedState info

    asURL {
        url: info.url
        s: s
        r: '1'
        }

makeV1URLb= (info)->

    s= asV1EncodedState info

    asURL {
        url: info.url
        s: s
        r: '1'
        },1 #nosort


makeV2URL= (info)->

    s= asV2EncodedState info

    asURL {
        url: info.url
        s: s
        r: '2'
        },1 #r at start ,1 #nosort


asV1EncodedState=  (info,out={})->

    { DX, DY, SCALE, ORIGINTIME }= V1

#     # V1 encoded state=
#         {
#             i:[([rnd lat-DX,rnd lng-DY]|shortobjid)+] # waypoints or short obj id (-attrait.fcmq.) rnd = round to scale
#             m:[ (rnd x-DX),(rnd y-DY), (rnd X-x), rnd Y-y] # mapBounds if exist
#             v:[ (indexOf viibleGroup in VisibleGroupsV1)* ] # visible groups (attraits)
#             e: 1 if ecosentiers
#             s: 1 if surfaceuses
#             M: idx of meteo Layer in MeteosV1
#             t:  Math.round(new Date()/1000-ORIGINTIME).toString 36 # time link was created 1483246800=(new Date 'Jan 1 2017')/1000
#             d: 1 dont ask to if ok to load
#         }


    rnd= (n)-> Math.round n*SCALE

    if info.waypoints?.length # use itinData instead ?
        out.i= for wp,i in info.waypoints
            if !wp.objid
                [(rnd wp.lat-DX),(rnd wp.lng-DY)]
            else
                wp.objid.split('.')[2...].join '.'
    if info.mapBounds
        {x,y,X,Y}= info.mapBounds
        out.m= [ (rnd x-DX),(rnd y-DY), (rnd X-x), rnd Y-y]

    if info.visibleGroups
        v= ( VisibleGroupsLookupV1[name] for own name,val of info.visibleGroups when val and name of VisibleGroupsLookupV1 )
        out.v= v if v.length


    out.e= 1 if info.ecosentiers
    out.s= 1 if info.surfaceuses

    if info.meteo and -1<i=MeteosV1.indexOf info.meteo
        out.M= i

    out.t= Math.round(new Date()/1000-ORIGINTIME).toString 36 # 1483246800=(new Date 'Jan 1 2017')/1000

    if info.dontAsk
        out.d=1

    #log "v1 as encodedstate before compress = \n#{JSON.stringify out}"

    ret= LZString.compressToEncodedURIComponent JSON.stringify(out)[1 ... -1]

    ret


getV1EncodedState= (s,out={})->

    { DX, DY, SCALE }= V1

    try
        ds= LZString.decompressFromEncodedURIComponent s
        js= JSON.parse "{#{ds}}"
    catch
        log "Error V1 step 1 parsing '#{s}'\n{#{ds}}"
        return out
    #log "getV1EncodedState got js=\n#{JSON.stringify js}"
    for k,v of js
        switch k
            when 'i'
                out.waypoints= for wp in v
                    if typeof wp is 'string'
                         "attrait.FCMQ.#{wp}"
                    else [ wp[0]/SCALE+DX, wp[1]/SCALE+DY ]
            when 'm'
                out.mapBounds= Bounds._ (x=v[0]/SCALE+DX), (y=v[1]/SCALE+DY), x+v[2]/SCALE, y+v[3]/SCALE
            when 'v'
                vb= new ->(@[name]=1 for i in v when name=VisibleGroupsRevLookupV1[i]);@
                #for name of VisibleGroupsRevLookupV1 when name not of vb
                #    vb[name]=0
                out.visibleGroups= vb
            when 'e'
                out.ecosentiers= if v then true else false
            when 's'
                out.surfaceuses= if v then true else false
            when 'M'
                if MeteosV1[v]
                    out.meteo= MeteosV1[v]
            when 't'
                out.time= new Date 1000* (V1.ORIGINTIME + parseInt v,36)
            when 'd'
                out.dontAsk=1 if v
            else
                log "ERROR getV1EncodedState not understood #{k}=#{v}"

    out

ewaypointsarr2pts= (ewaypointsarr)->
    Scale= 100000
    lng=0
    lat=0
    for dx,i in ewaypointsarr by 2
        lng+= ewaypointsarr[i+1]
        lat+= dx

        lng:lng/Scale
        lat:lat/Scale

bndsYXarr2bnds= (barr)->
    [y,x,Y,X]= barr
    Bounds._ x,y,X,Y



asV2EncodedState=  (info,out=[1])->

    { DX, DY, SCALE, ORIGINTIME }= V2

#     # V2 encoded state=
#         {
#             0: version
#             1:  Math.round(new Date()/1000-ORIGINTIME).toString 36 # time link was created 1483246800=(new Date 'Jan 1 2017')/1000
#             2: toLS x,X + toLS y,Y of mapBounds
#             3: tostr 36 sum (2^i for x,i in VisibleGroups when x is visible
#             4: toLStr wpLats
#             5: toLstr wpLngs
#             6: short ids join ','
#             7: 1 dont ask to if ok to load
#             8: idx of meteo Layer in MeteosV1
#             9: 1 if surfaceuses
#             10: 1 if ecosentiers
#         }

    #1
    out.push  Math.round(new Date()/1000-ORIGINTIME) #

    #2
    if info.mapBounds
        {x,y,X,Y}= info.mapBounds
        out.push (P.toLS [x,X],SCALE/20,DX).concat P.toLS [y,Y],SCALE/20,DY
    else out.push 0

    #3
    if info.visibleGroups
        s=0
        for g,visible of info.visibleGroups when visible and (g of VisibleGroupsLookupV1)
            s+=2**VisibleGroupsLookupV1[g]
        out.push s
    else out.push 0

    # 4 5 6
    if info.waypoints?.length # use itinData instead ?
        out.push P.toLS ( wp.lat for wp in info.waypoints ),SCALE,DY
        out.push P.toLS ( wp.lng for wp in info.waypoints ),SCALE,DX

        if ( 1 for wp in info.waypoints when wp.objid).length # si aumoin un id
            ids=[]
            for wp in info.waypoints
                if !id=wp.objid
                    ids.push 0
                else
                    # check for ids
                    prefix=0
                    for p,idx in AttraitsPrefixV2 when p and isPrefix id,p
                        id= id.substr p.length
                        prefix=idx
                        break
                    suffix=0
                    for p,idx in AttraitsSuffixV2 when p and isSuffix id,p
                        id= id.substr 0,id.length-p.length
                        suffix=idx
                        break
                    if (idn=parseInt id).toString()==id
                        id= idn
                    if prefix or suffix
                        ids.push [prefix,id,suffix]
                    else ids.push id
            out.push ids
        else out.push 0
    else
        out.push 0,0,0

    #7
    out.push if info.dontAsk then 1 else 0

    #8
    out.push Math.max 0,MeteosV2.indexOf info.meteo

    #9
    out.push if info.surfaceuses then 1 else 0

    #10
    out.push if info.ecosentiers then 1 else 0


    for i in [ out.length-1 .. 0] when out[i] isnt 0
        break
    out.length= i+1

    outlz= LZString.compressToEncodedURIComponent JSON.stringify(out)[1 ... -1]

    #log "asEncodedState v2=",[{out,info,outlz,outjson:JSON.stringify out}]

    outlz


getV2EncodedState=  (s,info={})->

    try
        ds= LZString.decompressFromEncodedURIComponent s
        inw= JSON.parse "[#{ds}]"
    catch
        log "Error V2 step 1 parsing '#{s}'\n[#{ds}]"
        return info


    { DX, DY, SCALE, ORIGINTIME }= V2

#     # V2 encoded state=
#         {
#             0: version
#             1:  Math.round(new Date()/1000-ORIGINTIME).toString 36 # time link was created 1483246800=(new Date 'Jan 1 2017')/1000
#             2: toLS x,X + toLS y,Y of mapBounds
#             3: tostr 36 sum (2^i for x,i in VisibleGroups when x is visible
#             4: toLStr wpLats
#             5: toLstr wpLngs
#             6: short ids join ','
#             7: 1 dont ask to if ok to load
#             8: idx of meteo Layer in MeteosV1
#             9: 1 if surfaceuses
#             10: 1 if ecosentiers

#         }

    #out.push  Math.round(new Date()/1000-ORIGINTIME) # 1483246800=(new Date 'Jan 1 2017')/1000

    try
        #0 version
        info.version= inw.shift()

        #1
        info.time= new Date 1000* (V2.ORIGINTIME + inw.shift())

        #2 mapBounds
        if mb= inw.shift()
            [x,X,y,Y]= mb
            [x,X]= P.fromLS [x,X],SCALE/20,DX
            [y,Y]= P.fromLS [y,Y],SCALE/20,DY
            info.mapBounds= Bounds._ x,y,X,Y

        # 3 visibleGroups
        info.visibleGroups= vgObj={}
        if vg= inw.shift()
            idx=0
            while vg
                if vg % 2 and VisibleGroupsV1[idx]
                    vgObj[VisibleGroupsV1[idx]]=1
                vg= vg>>1
                idx++

        # 4 5 6 lats lngs ids
        lats= inw.shift()
        lngs= inw.shift()
        ids=  inw.shift()
        if lats or lngs or ids
            #ok some kind of waypoints
            len= Math.max (lats?.length or 0),(lngs?.length or 0),(ids?.length or 0)
            if len
                if !isArray lats then lats=[]
                else lats= P.fromLS lats,SCALE,DY

                if !isArray lngs then lngs=[]
                else lngs= P.fromLS lngs,SCALE,DX

                if !isArray ids  then ids=[]

                info.waypoints= wps= []
                for i in [0 ... len]
                    if (id=ids[i]) and isArray id
                        [idxP,txt,idxS=0]= id
                        prefix= AttraitsPrefixV2[idxP] or ''
                        suffix= AttraitsSuffixV2[idxS] or ''
                        id= prefix+txt.toString()+suffix
                    else id=id and id.toString()
                    lat= lats[i]
                    lng= lngs[i]
                    if id
                        wps.push [lat,lng,id]
                    else if lat? and lng?
                        wps.push [lat,lng]
                    else
                        log "getV2EncodedState waypoints mis on idx=#{i} id=#{ids[i]} lat=#{lat} lng=#{lng}"

        #7 dontask
        if dontAsk=inw.shift()
            info.dontAsk= dontAsk

        #8 meteo
        meteo= inw.shift()
        info.meteo= MeteosV2[meteo] or ''

        #9 Surfaceuses
        if sf=inw.shift()
            info.surfaceuses= sf

        #10
        if eco=inw.shift()
            info.ecosentiers= eco


    catch err
        cinfo= info
        log "getV2EncodedState Decode error info=",{s,inw,cinfo}
        throw err
        info={}

    #log "V2 restore state got info=",info
    info


getAnyEncodedState=  (s,r,info={})->

    rn= (parseInt r) or undefined

    try
        ds= LZString.decompressFromEncodedURIComponent s
        first= ds?.split?(',')[0]?.split?(':',2).length
        autoR= switch first
            when 1 then 2
            when 2 then 1
            else undefined
    if autoR? and autoR isnt rn
        log "getAnyEncodedState.autodetect miss match auto=#{autoR} r=#{r} rn=#{rn} using autoR "

    autoR?= rn

    switch autoR
        when 1 then getV1EncodedState s,info
        when 2 then getV2EncodedState s,info
        else undefined





module.exports= { makeV1URL, makeV1URLb, makeV2URL, asV1EncodedState, asV2EncodedState, ewaypointsarr2pts, parseQS, getV1EncodedState, getV2EncodedState, getAnyEncodedState, setProtocol, bndsYXarr2bnds }
