
{ before }= require 'vx/globals/Boot'

before './DOMElements'
DOM= require './DOMElements'

before './UIComponent'
{ UIComponent }= require './UIComponent'

before './BasicUIApp'
{PersistantApp}=   require './BasicUIApp'


before './RMWCWidgets'
RMWCWidgets= require './RMWCWidgets'

# Migration Patchs
{ topBar:header2, listItem:listItemText, listDivider:listItemDivider, rmwcButton:button }= RMWCWidgets

content= DOM.section

before    './RMWCTypo'
RMWCTypo= require    './RMWCTypo'
#...widgets
ui= { ...DOM, PersistantApp, UIComponent, 
     ...RMWCWidgets, header2, listItemText, listItemDivider, button, content, ...RMWCTypo }

#console.log " MCWUI 3 =",ui

module.exports= ui
