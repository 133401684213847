{ log, Global  }= Mod= vx= require 'vx/globals/Boot'

# makeEvalFnc= (varList)->
#
#     if typeof varList is 'string'
#          varListArr= varList.trim().split /\s+/
#     else valListArr= varList
#
#     evalFn= (template,obj,ops)->
#         fncTxt= """
#             ops= ops || {}
#             var  #{ vars.join ','};
#             #{("#{varName}=obj.#{varName};" for varName in varListArr ).join '\n'}
#             console.log("eval=",{prop:prop,item:item})
#             ret= eval(prop);
#             console.log("ret=",ret)
#             return ret
#         """
#         log "fncTxt=",fncTxt
#         evalProp= new Function 'item','prop',fncTxt
#         log "evalProp=",evalProp
#
#         log "now render"
#
#         ret= ( template.replace(es6TemplateRegex,(m, propTxt)-> evalProp item,propTxt) for item in data).join('')
#         log "render did ",ret
#         ret

# Utility functions for templates
Fn=
    ret2br= (str)-> str?.replace? /\n/g,'<br/>' or str
    
SetProtocol= (url)->
    return url if not url
    urlparts =url.split ':'
    protocol= urlparts[0]?.trim().toLowerCase()
    if (protocol is 'http') or (protocol is 'https')
        return url
    # ok set protocole to current if its http or https else http
    cProtocol= Global.location?.protocol
    if not( (cProtocol is 'http:') or (cProtocol is 'https:') )
        cProtocol= 'http:'
    return "#{cProtocol}#{urlparts.join ':'}"


# Coffee v1
#
#evalFn= (evalTxt,obj,deco,ops,say,lang,fn)->
#    ret=''
#    `with(obj){`
#    try
#        ret= eval evalTxt
#    catch err
#        log "evalFn err #{err} on '#{evalTxt}'",{obj,ops}
#    `}`
#    ret



#coffee v2

evalFn= (evalTxt,o,deco,ops,say,lang,fn)->
    ret=''
    setProtocol= SetProtocol
    try
        ret= eval evalTxt
    catch err
        log "evalFn err #{err} on '#{evalTxt}'",{o,deco,ops,say,lang,setProtocol,fn}
    ret


TemplateCache= new Map()

getTemplateFn= (template,varList)->
    return TemplateCache[template] if template of TemplateCache
    TemplateCache[template]= makeTemplateFn template,varList


makeTemplateFn= (template,varList)->
    ### from https://github.com/regexhq/es6-template-regex ###
    es6TemplateRegex= /\$\{([^\\}]*(?:\\.[^\\}]*)*)\}/g
    tArr= template.split es6TemplateRegex
#     if !varList
#         #lets guess
#         varList= ( x for i in [1...tArr.length] by 2  when  (x=tArr[i].trim().split(/[. (\[]/)[0]) and x not of {ops:1,obj:1,'this':1})

    templateFn= (obj,deco,ops,say,lang='fr',fn=Fn)->
        ret= tArr.slice 0
        for i in [1...tArr.length] by 2
            ret[i]= evalFn ret[i],obj,deco,ops,say,lang,fn
        ret.join ''

module.exports= { makeTemplateFn, getTemplateFn }
