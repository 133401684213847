#!/usr/bin/coffee

# this is only used in node

# decorator used to log function renders
log= console.log.bind console

{ memo}= require 'react'
module.exports= 

    logRender: logRender= (fnc,{name=(fnc.name or '?fnc?'),mess="LogRender"}={})-> 
        (...args)->
            log "#{mess} #{name} start"
            ret= fnc.apply @,args
            log "#{mess} #{name} end"
            ret

    logRenderMemo: (fnc)->
        logRender (memo logRender fnc,{mess:"LogRender memo"})
