{ meta, log, sList, sSet, nr, time:getTime }= Mod= vx= require 'vx/globals/Boot'
{ oDiffs, oAssign, time:getTime, isPrefix, aSum, aPairs, aLast, sSplit, sSplit1 }= Mod

require 'vx/tiles/PMNPancartesDrawer.css'

{ latM, latMInv, pixel2deg }= require 'vx/math/MapGeometry'
{ Bounds }=   require 'vx/math/Bounds'
{ QuadNode }= require 'vx/math/QuadNode'


{ UIComponent }= UI= require 'vx/UI'
{ span, div, br, table, tbody, tr, td, th, ul, li }= UI
{ svg, path, g, line }= UI

{ sayModule }= require 'vx/i18n/I18n'
say= sayModule 'vx/graph/GraphWidgets'


meta class PancartesDrawer extends UIComponent
    #This is not a real Compnent but we want to ssociate CSS to the render method ....

    _className: 'pancarte'

    render2: ({swne,x,y,zoom,tileSize,content})->

        #log "pancartesDrawer got "
        [s,w,n,e]= swne
        #calc offsets

        lngOffset=  w
        lngScale= (e-w)/tileSize
        latMOffset= n
        latMScale= (s-n)/tileSize
        #log "pancartesDrawer got ",{swne,content,x,y,zoom,tileSize,latMScale,latMOffset,lngOffset,lngScale}

        div '.pancarte__tile .mdc-typography--body1',
            $width: "#{tileSize}px"
            $height:"#{tileSize}px"
            'data-nw':"#{n.toFixed 6},#{w.toFixed 6}"
            'data-se':"#{s.toFixed 6},#{e.toFixed 6}"
            for pt in content
                div '.pancarte',
                    'data-cat':   pt.seg.data.cat
                    'data-nosen': pt.seg.data.no_sen
                    'data-pos':   "#{pt.x.toFixed 6},#{pt.y.toFixed 6} #{pt.ym.toFixed 6}"
                    $top: "#{((pt.ym-latMOffset)/latMScale).toFixed 0}px"
                    $left:"#{((pt.x - lngOffset)/ lngScale).toFixed 0}px"


    renderOut: (out=[],{swne,x,y,zoom,tileSize,content,info})->

        #log "pancartesDrawer got "
        [s,w,n,e]= swne
        #calc offsets

        lngOffset=  w
        lngScale= (e-w)/tileSize
        latMOffset= n
        latMScale= (s-n)/tileSize
        #log "pancartesDrawer got ",{swne,content,x,y,zoom,tileSize,latMScale,latMOffset,lngOffset,lngScale}

        mBounds= Bounds._ w,s,e,n

        pancarteRaduisPx= 14
        pancarteRaduisMDeg= pixel2deg pancarteRaduisPx,zoom

        pancarteOverlapRaduisPx= 100
        pancarteOverlapRaduisMDeg= pixel2deg pancarteOverlapRaduisPx,zoom
        qPancartes= QuadNode._ mBounds
        qPancartes.getObjBounds= (obj)->obj

        #minSegLengthPx= pancarteRaduisPx*0.000001

        #minSegLengthMDeg= pixel2deg minSegLengthPx,zoom
        nodes= info.nodes3 or info.nodes


        for seg in content when ((cat=seg.data.cat) is 'T' or cat is 'R' and seg.data.no_sen ) #and ( seg.latMDiagonal > minSegLengthMDeg )
            midPt= seg.middleM()
            {x,ym}= midPt

            continue if ! mBounds.containsPt {x,y:ym}

            pBnds= Bounds._ x-pancarteRaduisMDeg, ( latMInv ym-pancarteRaduisMDeg), x+pancarteRaduisMDeg, ( latMInv ym+pancarteRaduisMDeg )
            hits=nodes.getIntersects pBnds
            continue if hits and hits.length

            oBnds= Bounds._ x-pancarteOverlapRaduisMDeg, ym-pancarteOverlapRaduisMDeg, x+pancarteOverlapRaduisMDeg,  ym+pancarteOverlapRaduisMDeg

            overlaps= qPancartes.getIntersects oBnds
                #log "makePancartes miss on #{seg.data.cat}#{seg.data.no_sen} #{seg.data.uuid} ",{seg,hits,pBnds,midPt}
            continue if overlaps and overlaps.length

            qPancartes.add oBnds

            out.push div '.pancarte',
                'data-cat':   seg.data.cat
                'data-nosen': seg.data.no_sen
                #'data-pos':   "#{x.toFixed 6},#{y.toFixed 6} #{pt.ym.toFixed 6}"
                $top: "#{((ym-latMOffset)/latMScale).toFixed 0}px"
                $left:"#{((x - lngOffset)/ lngScale).toFixed 0}px"

        out


    render: (ops)->
    
        out= @renderOut [],ops
        {tileSize}=ops
        div '.pancarte__tile .mdc-typography--body1',
            $width: "#{tileSize}px"
            $height:"#{tileSize}px"
            #'data-nw':"#{n.toFixed 6},#{w.toFixed 6}"
            #'data-se':"#{s.toFixed 6},#{e.toFixed 6}"
            out


    makePancartes: ({zoom,graph})->

        pancarteRaduisPx= 20
        pancarteRaduisMDeg= pixel2deg pancarteRaduisPx,zoom

        minSegLengthPx= pancarteRaduisPx*1.8
        minSegLengthMDeg= pixel2deg minSegLengthPx,zoom

        nodes= graph.getNodeCache()
        possibleSegments= ( seg for seg in @graph.segments when (cat=seg.data.cat) is 'T' or cat is 'R' and seg.data.no_sen )
        #log "makePancartes got #{possibleSegments.length} possibleSegments"
        goodSegs= ( seg for seg in possibleSegments when seg.latMDiagonal > minSegLengthMDeg )
        #log "makePancartes got #{goodSegs.length} goodSegs"
        # ok for all possible segments
        #  get middle pos
        #  check if hides an intersection


        pQuad= QuadNode._ nodes.bounds, getObjBounds:(o)->o.bounds

        for seg in goodSegs
            midPt= seg.middleM()
            {x,ym}= midPt

            pBnds= Bounds._ x-pancarteRaduisMDeg, ( latMInv ym-pancarteRaduisMDeg), x+pancarteRaduisMDeg, ( latMInv ym+pancarteRaduisMDeg )
            hits=nodes.getIntersects pBnds
            if hits and hits.length
                #log "makePancartes miss on #{seg.data.cat}#{seg.data.no_sen} #{seg.data.uuid} ",{seg,hits,pBnds,midPt}
                continue
            else
                #log "makePancartes hit on #{seg.data.cat}#{seg.data.no_sen} #{seg.data.uuid} ",{seg,hits,pBnds,midPt}
                midPt.seg= seg
                pQuad.add midPt

        pQuad


pancartesDrawer= PancartesDrawer::render.bind PancartesDrawer::
makePancartes=   PancartesDrawer::makePancartes


module.exports= { pancartesDrawer, makePancartes, PancartesDrawer }
