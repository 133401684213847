#!/usr/bin/coffee
{ oAssign, log, time, aLast, isArray, sList, timeStart, timeEnd, oCloneWith }= vx= require 'vx/globals/Boot'

P= require 'vx/tools/Persistance'
{ latM, latMInv }= require 'vx/math/MapGeometry'
{ distInfo2XY,  distInfo2Pt}= require 'vx/math/Geometry'
{ QuadNode }= require 'vx/math/QuadNode'
{ Bounds }= require 'vx/math/Bounds'
{ abs, round, min, max }= Math
{ Heap }=     require 'vx/graph/Heap'

# Scales ....
{ Meter2Degre, MapScale, iMapScale, verySmall, INodeScale }= require './Const'

{ Pt, Node, SplitNode, Segment }= require './Utils'


# close to and sameAs are not the same
# to very close close points may not simplify to the same point ...

closeTo= (a,b,iScale=iMapScale)->
    dx= abs a.x-b.x
    dy= abs a.y-b.y
    return false if dx>iScale or dy>iScale
    true


roundTo= (x,iScale=iMapScale)-> round(x/iScale)*iScale

roundPtTo= (pt,iScale=iMapScale)-> Pt._ roundTo(pt.x/iScale)*iScale, round(pt.y/iScale)*iScale

sameAs= (a,b,iScale=iMapScale)-> closeTo (roundPtTo a),(roundPtTo b),verySmall


class Graph
    #

    iNodeScale: INodeScale

    nodes: [null] #[Node] #we dont want id=0

    segments: [null] #[Segment] #we dont want id=0

    @_= (ops)-> new @ ops

    constructor: (ops)->
        #log "GraphRefrence @=#{@}",@
        #return new Graph ops if @ is Global or !@?
        return @init ops


    init:({@nodes= [null], @segments= [null],@iNodeScale=INodeScale}={})->@


    _addSegment: ({pts,data,oneway=false})->

        #if !pts and !pts.length
        #    return

        #data.s = if typeof data.s == "undefined" then 1 else data.s
        #data.e = if typeof data.e == "undefined" then 1 else data.e
        #data.f = if typeof data.f == "undefined" then 1 else data.f

        startNode= @_nodeFor pts[0]
        endNode=   @_nodeFor (aLast pts)

        @segments.push segment= Segment._ {id:@segments.length, start:startNode.id, end:endNode.id, pts, data, oneway}

        startNode._addStart segment
        endNode._addEnd segment

        @lineCache.add segment if @lineCache

        segment


    _nodeFor: (pt,dontCreate)->

        nodeCache= @getNodeCache()

        bnds= Bounds._ pt.x-@iNodeScale, pt.y-@iNodeScale ,pt.x+@iNodeScale, pt.y+@iNodeScale
        if (hits=nodeCache.getIntersects bnds).length
            if hits.length>1
                log "----------MULTI HIT--------#{JSON.stringify hits}"
            #log "node hit pt=#{pt} node=#{hits[0].pt}"
            return node=hits[0]

        return false if dontCreate
        node= Node._ id:@nodes.length,pt:pt
        @nodes.push node
        nodeCache.add node

        node


    sameAs: sameAs
    closeTo: closeTo

    getLineCache: ->
        return lc if lc=@lineCache

        @lineCache= QuadNode._ Bounds.aBNDS ( seg.bounds for seg in @segments when seg )
            .addAll ( seg for seg in @segments when seg)

        @lineCache


    getNodeCache: ->

        return @nodeCache if @nodeCache

        pts= ( node.pt for node in @nodes when node)
        #log " getNodeCache pts=",pts
        @nodeCache= QuadNode._ Bounds.aPTS pts
        @nodeCache.addAll ( node for node in @nodes when node)

        @nodeCache



    getNodeCache3: ->

        return @nodeCache3 if @nodeCache3

        nodes3 = ( node for node in @nodes when node and 2<( (node.ends?.length or 0)+(node.starts?.length or 0)) )
        log "getNodeCache3 nodes3 = #{nodes3.length}/#{@nodes.length}"
        @nodeCache3= QuadNode._ Bounds.aPTS (node.pt for node in nodes3)
        @nodeCache3.addAll nodes3

        @nodeCache3


    # *** Persistance and constuctors


    @fromJSON= (data)->

        np= Node::
        pp= Pt::
        for n in data.nodes
            if n
                n.__proto__= np
                n.pt = new Pt._ n.pt.x,n.pt.y

        sp= Segment::
        bp= Bounds::
        nodes= data.nodes
        for s in data.segments
            if s
                s.bounds.__proto__= bp if s.bounds
                s._sp= nodes[s.start].pt
                s._ep= nodes[s.end].pt
                s.__proto__= sp


        data.__proto__= @::
        data

    @fromJSONtxt= (txt)->  @fromJSON JSON.parse txt

    @fromSegArr= (segArr,ops)->
        #log " graph fromSegArr ops=#{JSON.stringify ops}"
        timeStart 'graph build'
        graph= new @ ops
        graph.nodeCache= QuadNode._ Bounds._ -180,-90,180,90 #-80,40,-60,60 #cause we know

        for s in segArr when s
            {pts,data,oneway}= s
            if !pts or pts.length<2
                log "addSegemnt error",{pts,data}
                #return new Error  "Segment must have atleast 2 points"

            #Check if pts is a Pt array
            #log "@fromSegArr before pts=",pts
            pts= Pt.array2Pts pts
            #log "@fromSegArr after pts=",pts

            #if pts and pts.length and pts.length>2
            graph._addSegment {pts,data}

        log "----------- Graph build end"
        timeEnd 'graph build'

        graph


    @fromGraph= (refGraph)->

        graph= new @()
        graph.segments= refGraph.segments[..]
        graph.nodes=    refGraph.nodes[..]

        graph


class GraphX extends Graph
    #

    toJSON: ->
        nodes: @nodes
        segments: for seg in @segments
            if seg
                id:    seg.id
                start: seg.start
                end:   seg.end
                oneway:seg.oneway if seg.oneway # make it undefined thus not jsoned
                data:  seg.data
                bounds:seg.bounds
                dist:  seg.dist
                _p:    seg._p or seg.makePtsJSON()
            else seg
        i: @iNodeScale


class GraphX2 extends Graph
    # TODO oneway:seg.oneway if seg.oneway # make it undefined thus not jsoned


    version: 1


    sdefs: """
        data.e:1
        end:1,32
        _p1
        dist:10000
        bounds.y:#{MapScale}:47.5678
        data.cat:TRLNPV
        bounds.X:#{MapScale}:-72.0001
        data.numClub:1:34
        data.no_sen:1,456
        bounds.Y:#{MapScale}:47.7856
        start:1,67
        _p0
        bounds.x:#{MapScale}:-71.3534
        data.f:1
        """


    sdefsi: """
        data.e:1
        end:1,32
        _p1
        dist:10000
        y:#{MapScale}:47.5678
        data.cat:TRLNPV
        X:#{MapScale}:-72.0001
        data.numClub:1:34
        data.no_sen:1,456
        Y:#{MapScale}:47.7856
        start:1,67
        _p0
        x:#{MapScale}:-71.3534
        data.f:1
        """

    ndefs: """
        pt.x:#{MapScale}:-72.3434
        pt.y:#{MapScale}:47.5678
        starts.length:1:214
        ends.length:1:-34
        """
    ndefsi: """
        x:#{MapScale}:-72.3434
        y:#{MapScale}:47.5678
        s:1:214
        e:1:-34
        """


    EncodeObjArr: P.EncodeObjArr2

    DecodeObjArr: P.DecodeObjArr2

    fromLStr: P.fromLStr

    toLStr:   P.toLStr

    segEncoder: -> @EncodeObjArr  @sdefs,{splitScale:MapScale}

    nodeEncoder: ->@EncodeObjArr  @ndefs,{splitScale:MapScale}

    sinit: (ops,id)->
        if ops and ops.start?
            {start,end,x,y,X,Y,data,_p0,_p1,dist}=ops
            Segment.create {id,start,end,data,bounds:(Bounds._ x,y,X,Y),dist,_p:[_p0,_p1]}
        else null

    segDecoder: ->@DecodeObjArr  @sdefsi,{initFnc:@sinit,splitScale:MapScale}

    nodeDecoder: ->
        self= this
        (data,ends,starts)->
            #we need globales(closure) so wrap it all up here
            spos=0
            epos=0
            ninit= (ops,i)->
                if ops and ops.x?
                    {x,y,s,e}= ops
                    new Node {id:i,pt:(Pt._ x,y),ends:ends[ epos ... (epos+=e)],starts:starts[ spos ... (spos+=s)]}
                else null
            (self.DecodeObjArr self.ndefsi,{initFnc:ninit,splitScale:MapScale}) data

    toJSON: ->
        s=[]
        e=[]
        for n in @nodes when n
            e.push.apply e,n.ends
            s.push.apply s,n.starts

        e=[ @version,(@toLStr e,1,17),(@toLStr s,1,14),(@nodeEncoder() @nodes),(@segEncoder() @segments),@clubs,@iNodeScale]
        # decode test

        #@constructor.fromJSON e

        e

    @parseJSON: (e)->
        throw new Error "Unknown format for GraphX2 from json #{e[0]}" if e[0] isnt @::version

        ends=   @::fromLStr e[1],1,17
        starts= @::fromLStr e[2],1,14

        nodes:  @::nodeDecoder() e[3],ends,starts
        segments: @::segDecoder() e[4]
        clubs: e[5]
        iNodeScale: e[6] ? @::iNodeScale

    @fromJSON: (e)->

        { nodes, segments, clubs, iNodeScale }= @parseJSON e

        #fs.writeFile '/home/app/code/toJSONtest.json',(JSON.stringify {nodes,segments},0,1),->log "wrote toJSONtest.json"
        graph= new @ {nodes,segments}
        if clubs? then graph.clubs= clubs
        graph




class GraphX3 extends GraphX2
    # TODO oneway:seg.oneway if seg.oneway # make it undefined thus not jsoned

    version: 2

    sdefs: """
        data.e:1
        end:1,32
        _p1x
        dist:10000
        bounds.y:#{MapScale}:47.5678
        data.cat:TRLNPV
        bounds.X:#{MapScale}:-72.0001
        data.numClub:1:34
        data.no_sen:1,456
        bounds.Y:#{MapScale}:47.7856
        start:1,67
        _p0x
        bounds.x:#{MapScale}:-71.3534
        data.f:1
        """


    sdefsi: """
        data.e:1
        end:1,32
        _p1x
        dist:10000
        y:#{MapScale}:47.5678
        data.cat:TRLNPV
        X:#{MapScale}:-72.0001
        data.numClub:1:34
        data.no_sen:1,456
        Y:#{MapScale}:47.7856
        start:1,67
        _p0x
        x:#{MapScale}:-71.3534
        data.f:1
        """


    EncodeObjArr: P.EncodeObjArr3
    DecodeObjArr: P.DecodeObjArr3
    fromLStr: P.fromLStrB
    toLStr:   P.toLStrB

    sinit: (ops,id)->
        if ops and ops.start?
            {start,end,x,y,X,Y,data,_p0x,_p1x,dist}=ops
            Segment.create {id,start,end,data,bounds:(Bounds._ x,y,X,Y),dist,_px:[_p0x,_p1x]}
        else null


    @fromJSON: (e)->
        switch e[0]
            when 1 # GraphX2
                info= GraphX2.parseJSON e
            when 2
                info= @parseJSON e
            when 5
                info= GraphX5.parseJSON e
            else throw new Error "Unknown format for GraphX3 from json #{e[0]}"

        { nodes, segments, clubs, iNodeScale }= info
        #fs.writeFile '/home/app/code/toJSONtest.json',(JSON.stringify {nodes,segments},0,1),->log "wrote toJSONtest.json"
        graph= new @ {nodes,segments,iNodeScale}
        if clubs? then graph.clubs= clubs
        graph



class GraphX5 extends GraphX3
    # TODO oneway:seg.oneway if seg.oneway # make it undefined thus not jsoned

    version: 5

    sdefs: """
        data.e:1
        end:1,32
        _p1x
        dist:10000
        bounds.y:#{MapScale}:47.5678
        data.cat:TRLNPV
        bounds.X:#{MapScale}:-72.0001
        data.numClub:1:34
        data.no_sen:1,456
        bounds.Y:#{MapScale}:47.7856
        start:1,67
        _p0x
        bounds.x:#{MapScale}:-71.3534
        data.f:1
        data.tags
        """


    sdefsi: """
        data.e:1
        end:1,32
        _p1x
        dist:10000
        y:#{MapScale}:47.5678
        data.cat:TRLNPV
        X:#{MapScale}:-72.0001
        data.numClub:1:34
        data.no_sen:1,456
        Y:#{MapScale}:47.7856
        start:1,67
        _p0x
        x:#{MapScale}:-71.3534
        data.f:1
        data.tags
        """

    @fromJSON: (e)->
        switch e[0]
            when 1 # GraphX2
                info= GraphX2.parseJSON e
            when 2
                info= GraphX3.parseJSON e
            when 5
                info= @parseJSON e
            else throw new Error "Unknown format for GraphX3 from json #{e[0]}"

        { nodes, segments, clubs, iNodeScale }= info
        #fs.writeFile '/home/app/code/toJSONtest.json',(JSON.stringify {nodes,segments},0,1),->log "wrote toJSONtest.json"
        graph= new @ {nodes,segments,iNodeScale}
        if clubs? then graph.clubs= clubs
        graph


class GraphUtils extends GraphX3
    # Graph with utlity function

    constructor: (ops)->
        super ops
        @findsegLRUpt= null #new Array 4
        return @

    findClosestNode2Pt: (pt)-> #( TODO: latm version ?? )
        sz= @iNodeScale*4096 # best guess nodes are sparse
        pt= Pt.normalize pt
        rbnds=Bounds.PTS pt
        hits=[]
        nodeCache= @getNodeCache()
        while !hits.length
            bnds= rbnds.grow 2*sz
            #CAREFULL here we are using a square to get values but we are only sure that we have all
            #element at distance x if the CIRCLE r=x is included in the box thats why we test distance...
            hits= ( {node,dist} for node in nodeCache.getIntersects(bnds) when (dist=pt.distanceTo node.pt )<=sz)
            #if hits.length
            #     log "findClosestNode2Pt got #{hits.length} sz=#{sz} ns=#{@iNodeScale}",hits
            #else log "findClosestNode2Pt hit miss sz=#{sz} bnds=#{bnds}"
            sz= 3*sz
        hits.sort((a,b)-> a.dist-b.dist)
        hits[0]


    findClosestNode2PtV2: (pt,maxRadius=null,ignores)-> #( TODO: latm version ?? )
        if ignores and !isArray ignores
            ignores=[ignores]

        pt= Pt.normalize pt
        pbnds=Bounds.PTS pt
        nodeCache= @getNodeCache()

        step=100 # meters Meter2Degre
        sq2= 2**0.5


        if ignores and !isArray ignores
            ignores= [ ignores]
        pass=0
        while true
            bnds= pbnds.grow Meter2Degre * step
            #CAREFULL here we are using a square to get values but we are only sure that we have all
            #element at distance x if the CIRCLE r=x is included in the box thats why we test distance...
            ihits= nodeCache.getIntersects bnds
            if ignores
                #log "ihits filter #{ihits.length}"
                ihits= ( node for node in ihits when 0>ignores.indexOf node.pt )
                #log "ihits filtered #{ihits.length}"
            minDist=Infinity
            hits= for node in ihits
                dist=1000*pt.distanceTo node.pt
                minDist= min minDist,dist
                {node,dist}

            if hits.length and minDist< step/sq2 # must be in circle not the containig square
                #we have a valid
                if maxRadius and minDist>maxRadius
                    hits=[] # to bad but to far
                break # this is the end of the loop
            else # we may have results but could be more
                if maxRadius and maxRadius<step
                    #miss
                    if hits.length and minDist<maxRadius
                        log "SHOULd NEVER HAPPEN mis on #{hits}"
                    else
                        hits=[]
                    break
                else #try again
                    step= 2*step

        #log "found #{hits.length} hits=#{hits}"
        if hits.length
            hits.sort((a,b)-> a.dist-b.dist)
            hits[0]
        else {node:null,dist:Infinity}



    findClosestSegmentToPtMv1: (pt,minD= Infinity)->
        r= @iNodeScale*100 #search radiuous this will still be very small
        nPt= Pt.normalize pt
        pbnds= Bounds.PTS nPt
        hits=[]
        seen={}

        nearest= false
        while minD> r
            r= if minD<Infinity then minD*1.5 else r*4
            bnds= pbnds.grow 2*r
            #CAREFULL here we are using a square to get values but we are only sure that we have all
            #element at distance x if the CIRCLE r=x is included in the box thats why we test distance...
            hits= ( line for line in @getLineCache().getIntersects(bnds) when line not of seen )
            for near in hits
                seen[near.id]= true
                distInfo= near.closestToM nPt,null,null,minD #TODO test withou null,null, error ?
                continue if not distInfo
                dist= distInfo.dist
                if dist<minD
                    nearest= near
                    nearestInfo= distInfo
                    minD= dist

         { seg:nearest, info:nearestInfo, dist:minD }



    findClosestSegmentToPtM: (pt,minD= Infinity,{ignoreFermer,ignoreSegId}={})->
        #log "start findClosestSegmentToPtM"
        findSegLRU= @findsegLRUpt
        if findSegLRU
            for info,i in findSegLRU when info and info.pt is pt
                args= info.args
                continue if args.minD isnt minD or args.ignoreFermer isnt ignoreFermer
                #log "findClosestSegmentToPtM cahce hit"
                if i #not first in cache
                    findSegLRU.splice i,1
                    findSegLRU.unshift info
                return info.ret

        ret= @findClosestSegmentToPtM_ (Pt.normalize pt),minD,{ignoreFermer,ignoreSegId}
        if findSegLRU
            findSegLRU.pop()
            findSegLRU.unshift { pt, ret, args:{minD,ignoreFermer}}
        #log "end findClosestSegmentToPtM"
        ret



    findClosestSegmentToPtM_: (pt,minD= Infinity,{ignoreFermer,ignoreSegId,rStart=@iNodeScale*100})->
        #log "findClosestSegmentToPtM_ start"
        r= max @iNodeScale,min minD,rStart #search radiuous this will still be very small
        pbnds=Bounds.PTS pt
        hits=[]
        seen={}

        nearest= false
        nearestD= minD

        while nearestD>=r and r<=minD
            r= r*3
            bnds= pbnds.grow r
            #log "r=#{r}"
            #CAREFULL here we are using a square to get values but we are only sure that we have all
            #element at distance x if the CIRCLE r=x is included in the box thats why we test distance...
            hits= ( line for line in @getLineCache().getIntersects(bnds) when (line not of seen) and (!ignoreFermer or !line.data.f ) and (!ignoreSegId or ignoreSegId isnt line.id ) )
            for near in hits
                seen[near.id]= true
                nearInfo= near.closestToM pt,nearestD
                continue if not nearInfo
                dist= nearInfo.dist
                if dist<nearestD
                    nearest= near
                    nearestInfo= nearInfo
                    nearestD= dist
        #info= nearest.distInfo2PtM(pt)
        return false if !nearest
        nearestInfo.seg= nearest
        #{ line:nearest, info:nearestInfo, dist:minD }
        #log "findClosestSegmentToPtM_ start"
        nearestInfo



    findClosestSegmentToPtMold: (npt,minD= Infinity)->

        pt= Pt.normalize npt

        lineCache= @getLineCache()

        #log " segfinder in findClosestSegmentToPtM pt=#{pt}",{pt,npt}

        info= lineCache.findClosest2Pt pt,(seg,pt,better)->
            #ok we have segments test if bounds has a chance of beiing closer
            if !seg.bounds.intersectsPt pt
                #if point is inside bounds we cant us this optimisation
                dist= (dinfo=seg.bounds.distData2PtM(pt))[0].dist
                #log " segfinder in findClosestSegmentToPtM outside test #{dist<=better}  dist=#{dist} better=#{better}",{seg}
                return dist if dist>better   #we cant be beter than this, and its not enough
                #log " segfinder in findClosestSegmentToPtM outside test #{dist<=better}  dist=#{dist} better=#{better}",{seg,dinfo}
            #else log " segfinder intersect ",{seg}

            #ok lets scan
            info= seg.distInfo2PtM pt
            dist= info.dist
            #log " segfinder in findClosestSegmentToPtM inside test #{dist<better} info=",{info,better,dist}
            info.dist

        #log " segfinder in findClosestSegmentToPtM found distM=#{distM} seg=",seg
        seg= info.obj
        info= seg.distInfo2PtM pt
        info.seg= seg
        info


class GraphRouter extends GraphUtils
    #

    _splitAt: (splitTargetPt,{nodes=@nodes,segments=@segments,fromNode,info}={})->
        debug= false

        splitTargetPt= Pt.normalize splitTargetPt

        info?= @findClosestSegmentToPtM splitTargetPt,Infinity,{ignoreFermer:1} #1=ignoreFermer #,dist*1.1 # makesure

        {seg:splitSeg, idx:splitIdx, }= info


        if info.end or info.idx is 0 #start node
            if info.end
                 log "end at   #{splitSeg.end} from seg #{splitSeg.id}" if debug
            else log "strat at #{splitSeg.start} from seg #{splitSeg.id}" if debug
            return {node: nodes[if info.end then splitSeg.end else splitSeg.start], lastSeg:null, lastSegSplitIdx:null, nodes, segments }

        if splitSeg.data?.f
            #patch segment fermer
            return {node: nodes[if 0.5<(info.dist/splitSeg.dist) then splitSeg.end else splitSeg.start], lastSeg:null, lastSegSplitIdx:null, nodes, segments }

        splitPt= splitSeg.pts[splitIdx]

        log "split at #{splitIdx} from seg #{splitSeg.id}" if debug

        # ok create a sub graph

        newNodes=    {__proto__:nodes}    # nodes[..] #oCreate nodes    #
        newSegments= {__proto__:segments} # segments[..] # oCreate segments #

        sn= newNodes[splitSeg.start]
        startNode= sn._clone()
        newNodes[startNode.id]= startNode

        en= newNodes[splitSeg.end]
        endNode= en._clone()
        newNodes[endNode.id]= endNode

        #TODO check if spliting an already split segment !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!11

        #pt= splitSeg.pts[splitIdx]
        newNodes.push splitNode= new SplitNode { id:newNodes.length, pt:splitPt, _splitIdx:splitIdx, _splitSegId:splitSeg.id }

        startPts= splitSeg.pts[.. splitIdx]
        endPts= splitSeg.pts[splitIdx ..]

        newSegments.push startSeg= Segment.create
            id:    newSegments.length
            start: splitSeg.start
            end:   splitNode.id
            #oneway: #TODO
            data:  splitSeg.data
            pts:   startPts
            _splitSegId:    splitSeg.id
            _splitSegStart: 0
            _splitSegEnd:   splitIdx

        splitNode._addEnd   startSeg
        startNode._addStart startSeg

        newSegments.push endSeg= Segment.create
            id:    newSegments.length
            start: splitNode.id
            end:   splitSeg.end
            #oneway: #TODO
            data:  splitSeg.data
            pts:   endPts
            _splitSegId:    splitSeg.id
            _splitSegStart: splitIdx
            _splitSegEnd: -1

        splitNode._addStart endSeg
        endNode._addEnd     endSeg


        ret= {node: splitNode, nodes:newNodes, segments:newSegments }
        log "GraphRouter _split at got/ret ",{segments,nodes,ret,self:@} if debug
        ret
        

    pathFinder: (pt,ops)->
        #log "pathFinder",{pt,ops}
        ret= @_pathFinder pt,ops # @_pathFinder pt,ops #
        ret

    pathFinderFromTo: (from,to,ops)->
        #log "pathFinderFromTo",{from,to,ops}
        sameSeg= false
        if ops.sameOk
            # dont try to resolve alternate path if points on same segment
            # sameOk= distM deg that avoids routing
            #log "pathFinderFromTo doing same seg optimisation"
            fromInfo= @findClosestSegmentToPtM (Pt.normalize from),Infinity,{ignoreFermer:1} #1=ignoreFermer #,dist*1.1 # makesure
            toInfo= @findClosestSegmentToPtM (Pt.normalize to),Infinity,{ignoreFermer:1} #1=ignoreFermer #,dist*1.1 # makesure
            if fromInfo.seg is toInfo.seg
                sameSeg= true

        ret= (@pathFinder from,ops) to
        ret.sameSeg= sameSeg
        ret


    _pathFinder: (fromPt,ops)->

        #log "path finder for ",{fromPt,ops,self:@}
        #fPt= Pt.normalize fromPt

        # find split

        {node:orgFromNode,segments:fromSegments,nodes:fromNodes}= splitInfo= @_splitAt fromPt,{nodes:@nodes,segments:@segments}

        {costFn=(seg)->seg.dist }= ops

        #visited can be cached if cost and estimate and fromPt dont change ...

        #log "_pathFinder using startMarkers=",startMarkers


        self= @

        (toPt)->


            {node:toNode,segments:toSegments,nodes:toNodes}= self._splitAt toPt,{nodes:fromNodes,segments:fromSegments}
            fromNode= toNodes[orgFromNode.id]

            heapBugMarker={totalCost:Infinity}

            startMarkers= [heapBugMarker,{node:fromNode, fromSeg:null,rev:null, totalCost:0 }]
            markers= Heap._ startMarkers,(m)->m.totalCost
            visited= {}

            #log "path finder fromNode._splitSegId = #{fromNode._splitSegId} toNode._splitSegId=#{toNode._splitSegId}",{fromNode,toNode}
            if (sameSegId=toNode._splitSegId) and sameSegId is fromNode._splitSegId
                #OK on same segemnt
                #log "same seg hit"
                startIdx= fromNode._splitIdx
                endIdx=      toNode._splitIdx
                if endIdx<startIdx
                    #we are revesed
                    tmp= startIdx
                    startIdx= endIdx
                    endIdx= tmp
                    isReversed= true
                else isReversed= false
                sameSeg= toSegments[sameSegId]

                spts= sameSeg.pts
                subSeg= Segment.create
                    id:    toSegments.length
                    data:  sameSeg.data
                    pts:   ( spts[i] for i in [ startIdx .. endIdx ] )
                    _splitSegId: sameSegId
                    _splitSegStart:startIdx
                    _splitSegEnd:  if endIdx == sameSeg.pts.length-1 then -1 else endIdx
                cost= costFn subSeg
                #log "same seg subseg=",subSeg

                return { cost, pts:subSeg.pts, connects:[{seg:subSeg,isReversed:false}] }

            #else log "toNode._splitSegId=#{toNode._splitSegId} fromNode._splitSegId=#{fromNode._splitSegId}"

            tPt=toNode.pt
            fPt= fromNode.pt
            #log "pathfiner findPath toNode=#{toNode.id} from #{fromNode.id}",{fromNode,fromPt,fPt,toPt,tPt,toNode}
            self.findPath {fromNode,toNode,costFn,visited,markers,nodes:toNodes,segments:toSegments}




    findPath: ({fromNode,toNode,costFn,visited,markers,nodes,segments})->
        debug= false

        log "GraphRouter.findPath costFn=#{costFn}",{costFn,nodes,segments} if debug

        startTime= performance.now() if debug


        #segments= @segments
        #nodes=    @nodes

        toNodeId= toNode.id

        log "FINDPATH got node #{fromNode.id} and #{toNode.id} visited=#{(1 for x of visited).length}" if debug


        visitMarker= visited[ toNodeId ]
        if not visitMarker
            while visitMarker=markers.pop()
                if visitMarker.totalCost is Infinity
                    visitMarker=null
                    break # no more solutions
                log "poped marker #{visitMarker.node.id} cost=#{visitMarker.totalCost}    has #{markers.length} left "  if debug

                visitNode= visitMarker.node
                visitNodeId= visitNode.id

                if visitNodeId is toNodeId
                    markers.push visitMarker
                    log " found to node"  if debug
                    break

                if visitNodeId of visited #longer solution ignore cost
                    if visited[visitNodeId].totalCost>visitMarker.totalCost
                        log "SCAN ERROR 1 for #{visitNodeId} cost error=#{visited[visitNodeId].totalCost-visitMarker.totalCost} nodes=",{visisted:visited[visitNodeId],visitMarker} if debug
                    #log "      OK skip maker cause node #{visitNodeId} visited cost was #{visited[visitNodeId].totalCost} vs #{visitMarker.toalCost}" if debug
                    log "   -n#{visitMarker.node.id}:{visitMarker.totalCost}>#{visited[visitNodeId].totalCost}" if debug
                    continue
                log "+#{visitMarker.node.id}:#{visitMarker.totalCost}" if debug
                #log "setting visited #{visitNodeId} to marker totalcost is #{visitMarker.totalCost} ",visitMarker  if debug
                visited[visitNodeId]= visitMarker

                lastSeg= visitMarker.fromSeg
                lastSegId= lastSeg?.id or -1

                log "tryning #{visitNode.starts.length} start segs" if debug
                for nextSegId in visitNode.starts
                    if nextSegId is lastSegId # dont goback for noting
                        log "       loopback continue" if debug
                        continue

                    nextSeg=  segments[nextSegId]
                    nextCost= costFn nextSeg
                    log "      ?s#{nextSegId}:s cost:#{nextCost} seg=" if debug


                    #nextSeg=  segments[nextSegId]
                    #nextCost= costFn nextSeg
                    if nextCost >= Infinity or isNaN nextCost
                        log "       Impossible cost=#{nextCost}" if debug
                        continue

                    totalCost= visitMarker.totalCost + nextCost #TODO after defensive if when sure
                    if (nextNodeId=nextSeg.end) of visited
                        # we already visited the end of this segment do we have a better solution this soution is worst
                        # lets check to make sure ...
                        if visited[nextNodeId].totalCost > totalCost
                            # no this(current) path is more expensive
                            log "SCAN ERROR 2 for  between nodes #{visitNodeId} and #{nextNodeId} cost error=#{visited[nextNodeId].totalCost-totalCost} info=",{visitNode,nextNode,nextSeg,nextCost,oldCost:visited[nextNodeId].totalCost,costError:visited[nextNodeId].totalCost-totalCost,nextNodeId}  if debug
                        log "       -#{nextNodeId}:{totalCost}>#{visited[nextNodeId].totalCost}" if debug
                        continue # not a possible solution
                    nextNode= nodes[nextNodeId]
                    markers.push nextMarker={node:nextNode, fromSeg:nextSeg, rev:false, totalCost:totalCost } #, , path:nextPath, estLeft:cEstLeft, total:cost+cEstLeft }
                    log " >#{nextNode.id} tc=#{totalCost}" if debug

                for nextSegId in visitNode.ends
                    nextSeg=  segments[nextSegId]
                    nextCost= costFn nextSeg
                    log "      ?s#{nextSegId}:e cost=#{nextCost}" if debug
                    if nextSegId is lastSegId # dont goback for noting
                        log "       loopback continue" if debug
                        continue

                    #nextSeg=  segments[nextSegId]
                    #nextCost= costFn nextSeg
                    if nextCost >= Infinity or isNaN nextCost
                        log "       Impossible cost=#{nextCost}" if debug
                        continue

                    totalCost= visitMarker.totalCost + nextCost #TODO after defensive if when sure
                    if (nextNodeId=nextSeg.start) of visited
                        # we already visited the end of this segment do we have a better solution this soution is worst
                        # lets check to make sure ... cost
                        if visited[nextNodeId].totalCost > totalCost
                            # no this(current) path is more expensive
                            log "SCAN ERROR 2b for  between nodes #{visitNodeId} and #{nextNodeId} cost error=#{visited[nextNodeId].totalCost-totalCost} info=",{visitNode,nextNode,nextSeg,totalCost,oldCost:visited[nextNodeId].totalCost,costError:visited[nextNodeId].totalCost-totalCost,nextNodeId}  if debug
                        log "       -#{nextNodeId}: tc={totalCost}>#{visited[nextNodeId].totalCost}" if debug
                        continue # not a possible solution
                    nextNode= nodes[nextNodeId]
                    markers.push nextMarker={node:nextNode, fromSeg:nextSeg, rev:true, totalCost:totalCost } #, path:nextPath, estLeft:cEstLeft, total:cost+cEstLeft }
                    log " <#{nextNode.id} #{totalCost}" if debug

        # create solution
        if visitMarker
            connects= []
            currentMarker= visitMarker
            while currentMarker.fromSeg
                connects.push {seg:seg=currentMarker.fromSeg,isReversed:currentMarker.rev}
                currentMarker= visited[ if currentMarker.rev then seg.end else seg.start ]
            connects.reverse()
            pts=[]
            #log "got connects=#{connects}",connects
            for c in connects
                lpts= if c.isReversed then c.seg.pts[..].reverse() else c.seg.pts
                pts= pts.concat lpts

            ret= { cost:visitMarker.totalCost, pts:pts, connects } # path,
        else
            log "No Path"
            ret= { cost:Infinity, pts:[], connects:[] } #  path:[],

        log "GraphRoute findPath took #{(performance.now()-startTime).toFixed 3}ms connects=#{ret.connects.length}  markers=#{markers.length}",{ret,visitMarker,connects,toNode,fromNode,visited,markers} if debug #path, if debug

        log "findpath took #{(performance.now()-startTime).toFixed 3}ms " if debug
        ret


#YUP you can skwint

oAssign Bounds::,

    tlPt:-> new Pt @x, @y # Top Left
    trPt:-> new Pt @x, @Y # Top Right
    blPt:-> new Pt @X, @y # Bottom Left
    brPt:-> new Pt @X, @Y # Bottom Right
    cPt: -> new Pt (@x+@X)/2, (@y+@Y)/2 #center
    cMPt:-> new Pt (@x+@X)/2, latMInv ((latM @y)+(latM @Y))/2 #latM based center
    distData2PtM: (pt)->
        tl= @tlPt(); tr= @trPt(); bl= @blPt(); br= @brPt()
        [
         oAssign {side:'top'},   distInfo2XY(tl.x,tl.ym, tr.x,tr.ym, pt.x,pt.ym)
         oAssign {side:'right'}, distInfo2XY(tr.x,tr.ym, br.x,br.ym, pt.x,pt.ym)
         oAssign {side:'bottom'},distInfo2XY(br.x,br.ym, bl.x,bl.ym, pt.x,pt.ym)
         oAssign {side:'left'},  distInfo2XY(bl.x,bl.ym, tl.x,tl.ym, pt.x,pt.ym)
        ].sort((a,b)->a.dist-b.dist) #only keep first



graphInfo= (graph)->
    log "Graph info"

    nodes= (node for node in graph.nodes when node)
    segments= (seg for seg in graph.segments when seg )

    pcnt= 0
    for seg in graph.segments when seg
        pcnt+= seg.pts.length

    log " Has #{nodes.length} nodes and #{segments.length} segments and #{pcnt} points"
    c1=0
    c2=0
    c3=0
    c4=0

    dataListG= sList 'cat no_sen eco'
    dataListClub= sList 'cat no_sen eco reg numClub'

    dataMatch= (a,b,dataList=dataListG)->
        a= graph.segments[a].data
        b= graph.segments[b].data
        for p in dataList when a[p] isnt b[p]
            return false
        true


    for n in graph.nodes when n and 2 == (n.starts.length + n.ends.length)
        c1++
        if n.starts.length is 1
            c2++

            [a,b]= n.starts.concat n.ends
            if dataMatch a,b,dataListG
                c3++
                if dataMatch a,b,dataListClub
                    c4++

    log " Has #{c1} doubles of wicth #{c2} are pure #{c3} mergable, #{c4} mergable same club"


module.exports=  { Graph, GraphX2, GraphX3, GraphRouter, GraphUtils, graphInfo, closeTo } 

