{ log, oSplit, oAssign, Global }= vx= require 'vx/globals/Boot'
ModuleName= 'GMapExtensions'
# Requires google.maps be loaded

module.exports= Exports=
    ready: false
    gmaps:      null
    asGLatLng:  null
    asGLatLngArr:       null
    CustomGMapMarker:   null
    DynamicTileOverlay: null


require 'vx/tools/NamedPromises'
{ latM, latMInv, tile2latMswne }= require 'vx/math/MapGeometry'
{ div, br }= require 'vx/UI'
{ render, unmountComponentAtNode }= require 'react-dom'
{ Bounds }= require 'vx/math/Bounds'
{ Pt }=     require 'vx/graph/Graph5'

# gmap loader
Global.vxInitGMap=->
    #log "$$$$$$$$$$$$$$$$ vxInitGMap resolving gmap google=",google
    Promise.vxGet('gmaps').vxResolve Global.google.maps

Promise.vxGet('gmaps').then (gmaps)->

    #log " 2$$$$$$$$$$$$$$$$$$$$$$$$ gmpas resolved for GMapExtensions evaluating ModuleName=#{ModuleName}"

    { LatLng, LatLngBounds }= gmaps


    asGLatLng= (latlng)->
            switch latlng.constructor
                when LatLng
                    #log "asGLatLng ll is LatLng no change",{ret,latlng}
                    latlng
                when Object
                    ret= new LatLng latlng.lat,latlng.lng
                    #log "asGLatLng ll isObject did",{ret,latlng}
                    ret
                when Array
                    ret= new LatLng latlng[0],latlng[1]
                    #log "asGLatLng ll is Array did",{ret,latlng}
                    ret
                when Pt
                    ret= new LatLng latlng.y,latlng.y
                else
                    ret= new LatLng latlng.lat,latlng.lng #best guest
                    log "asGLatLng ll Unknowen best guesse Object did",{ret,latlng}
                    ret

    asGLatLng= (latlng)->
            switch latlng.constructor
                when LatLng then    latlng
                when Object then    new LatLng latlng.lat,latlng.lng
                when Array  then    new LatLng latlng[0],latlng[1]
                else new LatLng latlng.lat,latlng.lng #best guest

    asGLatLngArrObj= (latlngArrObj)->
            return [] if not latlngArrObj
            return latlngArrObj if latlngArrObj.length is 0
            ret=switch typeof latlngArrObj[0].lat
                when 'number'   then  ( new LatLng latlng.lat,  latlng.lng   for latlng in latlngArrObj )
                when 'function' then  ( new LatLng latlng.lat(),latlng.lng() for latlng in latlngArrObj )
                when 'undefined' then ( new LatLng latlng.y,    latlng.x   for latlng in latlngArrObj ) #best guess
                else [] # throw error ?
            #log "asGLatLngArrObj got",{latlngArrObj,ret}
            ret

    asGLatLngArr= (latlngArr)->
            return [] if not latlngArr
            return latlngArr if latlngArr.length is 0
            switch latlngArr[0].constructor
                when LatLng then    latlngArr
                when Object then    asGLatLngArrObj latlngArr
                when Array  then    ( new LatLng latlng[0],latlng[1] for latlng in latlngArr )
                when Number then    ( new LatLng lat,latlngArr[i+1]  for lat,i in  latlngArr by 2 )
                else                asGLatLngArrObj latlngArr #best guest


    newLLB= (s,w,n,e)-> new LatLngBounds (new LatLng  s,w),(new LatLng  n,e)

    asGLatLngBounds= (llb)->
            switch llb.constructor
                when LatLngBounds then llb
                when Object       then newLLB  llb.south,llb.west,llb.north,llb.east
                when Array        then newLLB.apply null,llb
                when Bounds       then newLLB llb.y,llb.x,llb.Y,llb.X
                else                   newLLB  llb.south,llb.west,llb.north,llb.east #best guest



    class CustomGMapMarker extends gmaps.OverlayView #implements Marker Interface

        constructor: (ops,@div)->
            super ops
            oAssign @,ops
            #todo
            if @group
                @group.addMarker @,@visible
            

        #ops
        position: null
        map:    null
        group:  null
        classes:''
        visible:true
        title:  null
        group: null

        dragable:false
        clickable:false
        pane: 'overlayLayer'



        onAdd:->
            @getPanes()[@pane].appendChild @div

        onRemove: ->
            if @div
                @div.parentNode.removeChild @div
                @div= null

        setPosition: (pos)->
            @latlng= pos
            if @div
                @draw()

        draw:->
            #log " DDD #{ModuleName}:#{@constructor.name}"
            proj= @getProjection()
            point= proj.fromLatLngToDivPixel @position

            if point
                @div.style.left = point.x + 'px'
                @div.style.top = point.y + 'px'




    class CustomGMapZone extends gmaps.OverlayView #implements Marker Interface

        constructor: (ops,@div)->
            super ops
            oAssign @,ops
            return @

        #ops
        bounds: null # LanLngBounds
        map:    null
        group:  null
        classes:''
        visible:true
        title:  null
        maxZoom: 22

        dragable:false
        clickable:false
        pane: 'overlayMouseTarget' # 'overlayLayer'


        onAdd:->
            @getPanes()[@pane].appendChild @div

        onRemove: ->
            if @div
                @div.parentNode.removeChild @div
                @div= null

        setBounds: (bnds)->
            @bounds= bnds
            if @div
                @draw()

        draw:->
            #log " DDD #{ModuleName}:#{@constructor.name}"
            proj= @getProjection()
            {x:east,y:north}= proj.fromLatLngToDivPixel @bounds.getNorthEast()
            {x:west,y:south}= proj.fromLatLngToDivPixel @bounds.getSouthWest()

            @div.style.left = west  + 'px'
            @div.style.top =  north + 'px'

            #set w/h or bottom right
            @div.style.width=  (east-west)  + 'px'
            @div.style.height= (south-north) + 'px'
            #@div.style.right=  east + 'px'
            #@div.style.bottom= south + 'px'


    class DynamicTileOverlay
            DTOCnt=0

            _map: false
            _visible: false
            id: null
            minZoom: 0
            maxZoom: 22
            opacity: 1
            tileSize: 256
            name:  'overlay'
            className: 'dt-overlay'
            content: null # a quadtree
            latmContent: false # content is latm
            marginFixe: 0 #content access margins
            marginPct: 0
            marginPixel: 0
            debug: false
            info: {} # or null ?
            debug: false


            constructor: (ops)->

                for k,v of ops when v?
                    switch k
                        when 'bounds'
                            @bounds= asGLatLngBounds ops.bounds
                        else @[k]=v
                @id?= "DTO#{DTOCnt++}"
                if typeof @tileSize is 'number'
                    @tileSize= new gmaps.Size @tileSize,@tileSize

                @visibleTiles= {}


            onAdd:(a,b)->
                log "DynamicTileOverlay onAdd"


            releaseTile: (tileNode)->
                if id= tileNode.id
                    delete @visibleTiles[id]
                if tileNode.classList.contains 'dto-tile__rx'
                    ret= unmountComponentAtNode tileNode
                    if !ret
                         log "unmountComponentAtNode #{tileNode.id} failed node=",tileNode
                    else log "unmountComponentAtNode #{tileNode.id} succes" if @debug
                null


            refresh:->
                #clear cache and reload all visible tiles
                oldVisible= oAssign {},@visibleTiles
                #log "refrresh info=",{oldVisible,@visibleTiles,@id}
                for id of oldVisible
                    node= document.getElementById id
                    #log "node #{id} is",node
                    if !node or !node.parentNode
                        #not on map any more
                        log "missed release tile #{id}"
                        delete @visibleTiles[id]
                        continue
                    [name,x,y,zoom]= ( parseInt w for w in id.split '-')
                    #log "refresh updateTile ",{name,x,y,zoom,node}
                    @updateTile node,x,y,zoom,id
                null


            getTile: (tile, zoom, ownerDocument)->
                {x,y}= tile
                tileId= "#{@id}-#{x}-#{y}-#{zoom}"

                tileNode= ownerDocument.createElement 'div'
                tileNode.id= tileId
                if zoom<@minZoom or zoom>@maxZoom
                    tileNode.className= 'dto-tile'
                    return tileNode #dont cache empty tiles

                tileNode.className= 'dto-tile dto-tile__rx'
                #if @visibleTiles[tileId]
                #    log "DynamicTileOverlay.getTile double add on #{tileId}"
                @visibleTiles[tileId]= true

                @updateTile tileNode,x,y,zoom,tileId


            updateTile: (tileNode,x,y,zoom,tileId)->
                #no tileNode
                tileSize= @tileSize.width # CAREFULL assuming square tiles ...
                swne= tile2latMswne x,y,zoom
                ezoom= zoom #ezoom is used by Leaflet
                tileOps= @addContent {swne,x,y,zoom,ezoom,tileSize,content:null,tileId,@info }

                tileContent= @renderTile tileOps

                render tileContent,tileNode
                #log "#{@name} returning node= ",{tileNode,tileId,tileOps}

                tileNode


            addContent:(ops)->
                if @content

                    [s,w,n,e]= ops.swne
                    bnds= Bounds._ w,s,e,n
                    if (mf= @marginFixe ) or (mp=@marginPct) or (mpx=@marginPixel)
                        {dx,dy}= bnds.size()
                        # convert mpx do lng,latm
                        area= bnds.grow (mpx+mf+mp*dx),(mpx+mf+mp*dy)
                    else area= bnds

                    if not @latmContent
                        area.y= latMInv area.y
                        area.Y= latMInv area.Y

                    ops.content= @content.getIntersects area
                    #log "addContent added #{ops.contentlength} info=",{area,latmContent:@latmContent,@content}

                ops


            renderTile: ({swne:[s,w,n,e],x,y,zoom,ezoom,tileSize,content,tileId})->
                r= (x)-> x.toFixed 1

                div
                    className: @className
                    '-tileid': tileId
                    $width: "#{tileSize}px"
                    $height:"#{tileSize}px"
                    $fontSize:'10px '
                    $border:  '1px solid #AAAAAA'
                    $opacity: "#{@opacity}"
                    "tile: #{x},#{y}, #{zoom} #{tileSize}",br()
                    "-- #{r latMInv n} --",br()
                    "#{r w}--#{r e}",br()
                    "-- #{r latMInv s} --",br()




    oAssign Exports,{ ready: false, gmaps, asGLatLng, asGLatLngArr, asGLatLngBounds, CustomGMapMarker, CustomGMapZone, DynamicTileOverlay }

    log " %%%% READY #{ModuleName}"

    Promise.vxGet("#{ModuleName}Ready").vxResolve Exports
    #Promise.vxGet("GMapExtensionsReady").vxResolve Exports


log "Exporting #{ModuleName}"

