{ log,  $ }= vx= require 'vx/globals/Boot'

{ cloneElement, Children }= require 'react'
childrenToArray= Children.toArray

{ latM }= require 'vx/math/MapGeometry'


positionContent= (content,info,name)->
    return undefined if not content
    { latMOffset, lngOffset, lngScale, latMScale }= info

    for child in childrenToArray content when content
        switch
            when not child.props then child
            when child.props.bounds
                [bottom,left,top,right]= child.props.bounds
                latMTop=latM(top)
                cloneElement child,
                    bounds:undefined
                    style:
                        zIndex: child.props?.style?.zIndex or Math.floor 100000*(90-top)
                        position: 'absolute'
                        left:"#{(left-lngOffset)*lngScale}%"
                        top:"#{(latMTop-latMOffset)*latMScale}%"
                        height:"#{((latM bottom)-latMTop)*latMScale}%" # latMScale is negative
                        width:"#{(right-left)*lngScale}%"
            when child.props.position
                {bottom,left,top,right,mbottom,mtop}= child.props.position
                mtop?= latM top if top?
                mbottom?= latM bottom if bottom?
                cloneElement child,
                    position:undefined
                    style:
                        zIndex: child.props?.style?.zIndex or Math.floor 100000*(180-( mbottom ? mtop ))
                        position: 'absolute'
                        top:   "#{(mtop-latMOffset)*latMScale}%" if mtop?
                        left:  "#{(left-lngOffset)*lngScale}%"         if left?
                        right: "#{100-(right-lngOffset)*lngScale}%"    if right?
                        bottom: "#{100-(mbottom-latMOffset)*latMScale}%" if mbottom?
            else

                cloneElement child,children: positionContent child.props.children,info


module.exports= {positionContent}


