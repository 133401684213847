{ meta, log, timeStart, timeEnd, a2map, sList, oAssign, oSet, oDiffs, oIsEmpty, beginingSeconds, isSuffix }= Mod= vx= require 'vx/globals/Boot'

# TODO: remove this not used/required (was for private attraits) 
# TODO: remove Attraits dependecies

OfE= Object.fromEntries

{ Bounds }= require 'vx/math/Bounds'


{Promise}= require 'vx/tools/NamedPromises'
{ meter2deg, latMInv }= require 'vx/math/MapGeometry'

{Pt}= require 'vx/graph/Graph5'

{ acros }= require 'vx/globals/Conversions'

P= require 'vx/tools/Persistance'

P.addQSCompressors AttraitsPatterns=
    H:
        prefix:'attrait.FCMQ.'
        sufix: '.heb'
        compFn: (str)-> P.intToB64u parseInt str
        expdFn: (str)-> (P.b64uToInt str).toString()
    R:
        prefix:'attrait.FCMQ.'
        sufix: '.res'
        compFn: (str)-> P.intToB64u parseInt str
        expdFn: (str)-> (P.b64uToInt str).toString()
    F:
        prefix:'attrait.FCMQ.'



class Attrait # extends ManagedObjectBase

    mapType: 'Attrait'
    membre: ''
    id:     ''
    cls:    'Location'
    title:  ''
    titleEn:''
    description: ''
    descriptionEn: ''
    tel:    ''
    address:''
    numrue: ''
    ville:  ''
    pays:   ''
    cp:     ''
    courriel:''
    pt: {x:NaN,y:NaN,ym:NaN} # Pt lat and lng are props
    href:   ''
    hrefEn: ''
    photo:  ''
    urlsource:''
    type:   ''
    sourceCat: ''
    sourceSousCat: ''
    commentaire: ''
    coeur: 0
    check: 0
    etoile:0
    #forfait:''
    data:{}

    @addProps= (props)-> Object.defineProperties @::,props # from oBject Manager

    @addProps
        latitude:
            get: -> @pt.y
            set: (lat)-> @pt= new Pt @pt.x,lat
        longitude:
            get: -> @pt.x
            set: (lng)-> @pt=  new Pt lng,@pt.y


    nextId=0

    constructor:(values)->
        #super values # only if sub class
        Object.assign @,values
        if !@id
            @id="attrait-#{values?.user or '?'}-#{beginingSeconds().toString 36}"
        return @



class AttraitStateManager

    # **** Attraits  ****

    attraitsLookup:{}

    _attraitsFiltre:''
    _attraitsBrut: []

    defaultState:
        attraitsFiltre:''
        attraits: null # Instance of attrait mamanger
        attraitsGroups:[]
        attraitsVisibleGroups: {}
        attraitsVisible: {}
        attraitsDecorations: {}
        #attraitsLocalChangeSent: false
        #attraitsLocalChange: false
        attraitSelectedId: false
        option_attraitAutoRead: true
        option_attraitAutoReadDelay: 0.5
        option_attraitAutoReadSpeed: 10


    persistantState: a2map ( "option_#{name}" for name in  sList 'attraitAutoRead attraitAutoReadDelay attraitAutoReadSpeed '),{attraitsVisibleGroups:1},2
        #a2map (  "layer_#{name}__visibility" for name in sList 'Municipalités Attraits_et_Culture Hébergements Nature_et_Plein_air Information_touristique Photo Commentaire aucun' )


    findSummaryIds: (id,{heads=['a','attrait'],membres=[''],tails=['']}={})->

        addPt=(v)-> if v then '.' else ''

        # does if id have a
        idParts= id.split '.'
        idHead=  idParts[0]
        idTail=  idParts[idParts.length-1]

        hasHead= false
        for head in heads when idHead is head
            hasHead= head
            break

        hasMembre= false
        if !hasHead
            idMembre= idHead
            for membre in membres when idMembre is membre
                hasMembre= membre
                break

        hasTail= false
        for tail in tails when idTail is tail
            hasTail= tail
            break


        switch
            when hasHead
                testHeads=['']
            when hasMembre
                testHeads= heads
            else #join heads and membres
                testHeads= []
                for head in heads
                    for membre in membres
                        testHeads.push "#{head}#{ addPt head and membre }#{membre}"

        if hasTail
             testTails=['']
        else testTails=tails

        summaryIds=[id]
        for head in testHeads
            for tail in testTails
                summaryIds.push "#{head}#{addPt head}#{id}#{addPt tail}#{tail}"

        summaryIds



    urlAPIVars:

        attraitSelectedId:(val)->
            @setSelectedAttraitId val

        attZoomMode: (zoomMode)->
            app= @
            (Promise.all [(Promise.vxGet 'attraits'),(Promise.vxGet 'main-gmaps-map')]).then ->
                selectedAtt= app.getSelectedAttrait()
                selectedAtt= app.getSelectedAttrait()
                return if !selectedAtt
                switch  zoomMode[0]
                    when 'selected-attrait'
                        dist=zoomMode[1] or '50km'
                        switch
                            when isSuffix dist,'km'
                                 dlng= meter2deg * 1000* parseFloat dist[...-2]
                            else dlng= meter2deg * 50000
                        return if !dlng
                        { x,ym}= selectedAtt.pt
                        zbnds= Bounds._ x-dlng,(latMInv ym-dlng),x+dlng,(latMInv ym+dlng)

                app.fitMapBounds? zbnds if zbnds


    groupAttrib: 'type'

    #@::persistantState.attraitsLocalChangeSent= 1

    #onMountDo: ['initAttraits']

    #initAttraits:->
    #    log "init attraits"
    #    Promise.vxGet('vxData').then  @doLoadedAttraits

    onBeforeMountDo: ['initFiltre']

    initFiltre:->
        #log "!!!!!!!!!!!!!!!!!!! AttraitStateManager.onBeforeMountDo got attraitsFiltre=#{@state.attraitsFiltre}",{self:@,@state}
        if @state.attraitsFiltre
            @setAttraitsFiltre @state.attraitsFiltre

    setAttraitsFiltre: (filtre)->
        @_attraitsFiltre= filtre
        if @_attraitsBrut and @_attraitsBrut.length
            attraitsBrut= @_attraitsBrut
            @setAttraits ( a for a in attraitsBrut when !filtre or a.region is filtre )

    setAttraitsBrut:(attraitsBrut)->
        timeStart 'setAttraitsBrut'
        @_attraitsBrut= attraitsBrut
        filtre= @_attraitsFiltre
        @setAttraits ( a for a in attraitsBrut when !filtre or a.region is filtre )
        timeEnd 'setAttraitsBrut'

    setAttraits: (attraitsData)->
        #ptProto= Pt::
        #for a in attraitsData
        #    a.pt.__proto__= ptProto
        #log "AttraitStateManager.setAttraits recieved ",attraitsData

        @doLoadedAttraits attraitsData

    setAttraitsInfo: (info)-> @setState attraitsFileInfo:info

    doLoadedAttraits: (attraitsData)->

        #log "AttraitStateManager.doLoadedAttraits len=#{attraitsData.length}"
        try

    #         if @state.attraits
    #             # this is an update, but for now we clober everything ....
    #             AttraitManager.updateArr attraitsData

            #attraits= AttraitManager.init attraitsData
            
            attraits= ( new Attrait info for info in attraitsData )
            
            #log "AttraitStateManager.doLoadedAttraits attraits= ",{attraits,attraitsData}
            #setup change persistance

            #AttraitManager.onChange= @doAttraitChange

            @attraitsLookup= attraitsLookup=  OfE ( [a.id,a] for a in attraits )

            attraitsGroups= @makeGroups attraits
            attraitsVisibleGroups= @getVisibleGroups()

            newAttraitsVisibleGroups= OfE ( [grp, if attraitsVisibleGroups[grp] then 1 else 0 ] for grp of attraitsGroups )


            state= @state
            newAttraitsVisible= OfE ( [aId,attraitsLookup[aId]] for aId of state.attraitsVisible when aId of attraitsLookup )

            newAttraitsDecorations= OfE ( [aId,deco] for aId,deco of state.attraitsDecorations when aId of attraitsLookup )

            #log "Doing doLoaded Attraits setState =",{ attraits,attraitsGroups,attraitsVisibleGroups }
            selectedId= @state.attraitSelectedId
            newAttraitSelectedId= false
            if  selectedId
                if selectedId of attraitsLookup
                    newAttraitSelectedId= selectedId
                else #hunt for id
                    ids= @findSummaryIds selectedId,
                        membres: vx.q.am or ['']
                        heads: ['a','attrait']
                        tails:acros
                    for testId in ids when testId of attraitsLookup
                        newAttraitSelectedId= testId
                        break


            newState={
                attraits
                attraitsGroups
                attraitsVisibleGroups: newAttraitsVisibleGroups
                attraitsVisible:       newAttraitsVisible
                attraitsDecorations:   newAttraitsDecorations
                #attraitsLocalChange: AttraitManager.hasUpdates
                attraitSelectedId: newAttraitSelectedId
                }
            oldState={
                attraits: @state.attraits
                attraitsGroups: @state.attraitsGroups
                attraitsVisibleGroups: @state.attraitsVisibleGroups
                attraitsVisible: @state.attraitsVisible
                attraitsDecorations: @state.attraitsDecorations
                #attraitsLocalChange: @state.attraitsLocalChange
                attraitSelectedId: selectedId
                }

            #log "AttraitStateManager.doLoadedAttraits newState==",{newState,oldState}


            @setState {
                attraits
                attraitsGroups
                attraitsVisibleGroups: newAttraitsVisibleGroups
                attraitsVisible:       newAttraitsVisible
                #attraitsLocalChange: AttraitManager.hasUpdates
                attraitSelectedId: newAttraitSelectedId
                },
                ->
                    log "resolving promise 'attraits'"
                    (Promise.vxGet 'attraits').vxResolve true

        catch err
            log "AttraitStateManager.doLoadedAttraits error #{err}"
            console.trace err

        null


    # attraits

    getAttraits: ->@state.attraits

    getAttraitById: (id)-> @attraitsLookup[id]


    # groups

    getGroups: ->  @state.attraitsGroups

    getVisibleGroups:-> @state.attraitsVisibleGroups

    clearVisibleGroups:->
        @setState attraitsVisibleGroups:{}

    setVisibleGroups: (grps)->
        return if !grps or oIsEmpty grps
        changed= false
        newGroupVisibility= oAssign {},@getVisibleGroups()
        for k,v of grps when ( k not of  newGroupVisibility) or (!!v isnt !!newGroupVisibility[k])
            changed= true
            newGroupVisibility[k]= if v then 1 else 0

        @setState attraitsVisibleGroups:newGroupVisibility if changed


    isGroupVisibile: (gname)-> !!@state.attraitsVisibleGroups[gname]

    setGroupVisibility: (val,gname)->
        #@setState (state)->
            newGroupVisibility= oSet @getVisibleGroups(),gname,if val then 1 else 0
            @setState attraitsVisibleGroups:newGroupVisibility if newGroupVisibility isnt null

    makeGroups: (attraits,groupAttrib=@groupAttrib)->
        # 'new ->' creates empty object the loop is the constrcutor ...
        grps= new ->( (@[attrait[groupAttrib]]?= []).push attrait for attrait in attraits or [] ; @)
        #log "AttraitStateManager.makeGroups did ",{grps,attraits,groupAttrib}
        grps



    # attrait visibility

    getVisibleAttraits:-> @state.attraitsVisible

    isAttraitVisible: (attrait)-> !!@state.attraitsVisible[attrait.id]


    setAttraitVisibility: (val,attrait)->
        newAV= oSet @state.attraitsVisible,attrait.id,if val then 1 else 0
        if newAV isnt null
            @setState attraitsVisible:newAV


    setAttraitVisibilityById: (val,attraitId)->
        return if not attraitId of @attraitsLookup
        newAV= oSet @state.attraitsVisible,attraitId,if val then 1 else 0
        if newAV isnt null
            @setState attraitsVisible:newAV


    setAttraitsVisibilitiesByIds: (changes)->
        change= false
        newChanges= {}
        visibles= @state.attraitsVisible
        for id,val of changes when (!visibles[id]) isnt (!val)
            change= true
            newChanges[id]= if val then 1 else 0
        if change
            @setState attraitsVisible: oAssign {},visibles,changes


    # decorations

    getAllAttraitsDecorations:-> @state.attraitsDecorations

    addAttraitIdsDecorations: (decoChanges)->

            newDecos= oAssign {},@getAllAttraitsDecorations()
            changed= false
            for aid,changes4 of decoChanges
                currentDeco= (@getAttraitDecorationsId aid) or {}
                diffs= oDiffs changes4,currentDeco # has change
                #log "addAttraitIdsDecorations for #{aid} diffs=#{diffs}",{diffs,changes4,currentDeco}
                if  diffs # has change
                    newDecos[aid]= oAssign {},currentDeco,changes4
                    changed= true if !changed
            #log "addAttraitIdsDecorations changed=#{changed} ",{newDecos,decoChanges,oldDecos:@state.attraitsDecorations}
            if changed
                #log "addAttraitIdsDecorations did ",{newDecos,decoChanges,oldDecos:@state.attraitsDecorations}
                @setState attraitsDecorations:newDecos
            #else log "addAttraitIdDecorations NOCHANGE ",{decoChanges,oldDecos:@state.attraitsDecorations}
            if changed then newDecos else null

    getAttraitDecorationsId: (attraitId)->@getAllAttraitsDecorations()[attraitId]


    # selected

    getSelectedAttraitId: -> @state.attraitSelectedId or false

    getSelectedAttrait: -> @getAttraitById @getSelectedAttraitId()

    setSelectedAttraitId: (id,state=@state,cb)->
        @closeAllMenus() if id
        newState=
            attraitSelectedId: id
            selectedAttrait: null
        if id
            for attrait in state.attraits or [] when attrait.id is id
                newState.selectedAttrait= attrait
                break
        #log "attrait setSelectedAttraitId id=#{id} state=",{state,attraits:@state.attraits}
        @setState newState,cb


    toggleSelectedAttraitId: (id)->
        if !id or id is @state.attraitSelectedId
            id= false
        @setSelectedAttraitId id



    doToggleSelectedAttraitId: (id)-> @toggleSelectedAttraitId id


module.exports= { Attrait, AttraitStateManager, AttraitsPatterns }

