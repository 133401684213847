{ log, meta, oIsEmpty, oDiffs, oShallow, oAssign, aLast, oAllPropNames, setTimeout2, timeStart,timeLog,timeEnd, $ }= vx= require 'vx/globals/Boot'  # to get a global ref to HelloWorld that survies webpack



{ oEq }= oShallow() # o1 use C.name?= instead
C= {} # Constant namepsace


{ Bounds }=    require 'vx/math/Bounds'
{ pixel2deg, latM, latMInv }= require 'vx/math/MapGeometry'

wait= require 'vx/tools/Wait'

UI= require 'vx/UI'

# I18n
{ sayModule }= require 'vx/i18n/I18n'
say= sayModule 'App' # because of refactoring not sure where message is ... was ModuleName

{ createElement }= require 'react'

# UI layout
{ page, sidePanel, section, content, group, ul, li, form }= UI
{ header2, tbTitle }= UI

# html blocks
{ div, h1, h2, h3, h4, h5, h6, hr, p, a:aTag, mDialog, fab  }= UI # pass through require 'DOMTags'

# html inline
{ br, span, img }= UI

#htmlctrls
{ button, input, range, checkbox, radio, radioGroup, createTag, details, summary, icon, title }= UI

{ polyline:svgPolyline, use:svgUse, path:svgPath }= UI
#log "svgUse= ",svgUse

{ itinMapViewFactory, itinButtons, itinMapButton, itinMapWpInfoPopup, itinXMenu }= require 'vx/comp/itin/ItinWidgets' if vx.use.itin

{ coucheLayer }= require 'vx/comp/Couches' if vx.use.couches

{ templateWidget }= require 'vx/templates/TemplateWidgets'
require 'vx/templates/couche-selector.css'


#{ pmnDrawer }= require 'AppWidgets'# parcoursPath,  ,surfaceDrawer, pancartesDrawer
{ graphPtInfo, pmnDrawer, pancartesDrawer  }= require 'vx/graph/GraphWidgets'

#{  }= require 'vx/comp/attraits/AttraitsUI'
{ attraitMarker, attraitInfo, attraitIcon, attraitsSelector, attraitsSelectorCustom, attraitsLayerFactory, getObjMapBoundsm, makeClusters, makeEtablisments, filter, filterVBnds }= require 'vx/comp/attraits/AttraitsFCMQUI' if vx.use.attraits
#log "!!!!!!!!!!!! AttraitsFCMQUI load got ",{ attraitMarker, attraitInfo, attraitIcon } if vx.use.attraits

#{ fbLogin }= require 'FBUI'

{ shareDialog }= require 'vx/comp/SharingUI' if vx.use.sharing

{ surfToggle, surfToggleV2, surfToggleBrute, surfLayerFactory, surfLegend, surfLegend2, surfLegendBrut, surfPtDetails, surfPtDetailsV2 , ms2hrs }= require 'vx/comp/surf/SurfManager' if vx.use.surf
{ asColor:asSurfColor }= require 'vx/comp/surf/SurfColor'

{ circuitInfo, circuitsSVGLayer, circuitsMarkerLayer }= require 'vx/comp/circuits/CircuitsUI' if vx.use.circuits

{ conditionsSVGLayer, conditionsMarkerLayer, condPtDetails }= require 'vx/comp/cond/ConditionsUI' if vx.use.cond

{ asCondClass, calcCondAfter, asCondClassFromCond }= require 'vx/globals/Conversions'

{ fullScreenButton }= require 'vx/comp/fullScreen/FullScreenUI' if vx.use.fullscreen


require './MapPage.css'

filter= ({content,visibleGroups,visibleAttraits,groupAttrib,selectedId})->
    #log "filter got",{content,visibleGroups,visibleAttraits,groupAttrib}
    ret=( a for a in content or [] when visibleGroups[a[groupAttrib]] or visibleAttraits[a.id] or a.id is selectedId)
    #log "returned",ret
    ret


MeteoInfo=
    'Masse-neige':
        layers: 'GDPS.ETA_I5'
        style:  'SNOWMASS'
    'Neige-sol':
        layers: 'GDPS.ETA_SD'
        style:  'SNOWDEPTH'
    'TempMin24hrs':
        layers: 'REPS.DIAG.24_T7.ERMEAN'
        style:  'REPS_TT'
    'Neige-radar':
        layers: 'RADAR_RDBS'
        style:  'RADARURPREFLECTS'
    SRPE06:
        layers: 'REPS.DIAG.6_SNMM.ERMEAN'
        style:  'REPS_PRMM'
    SRPE12:
        layers: 'REPS.DIAG.12_SNMM.ERMEAN'
        style:  'REPS_PRMM'

    SRPE24:
        layers: 'REPS.DIAG.24_SNMM.ERMEAN'
        style:  'REPS_PRMM'

    SRPE48:
        layers: 'REPS.DIAG.48_SNMM.ERMEAN'
        style:  'REPS_PRMM'
    SRPD:
        layers: 'RDPS.ETA_SN'
        style: 'PRECIPSNOW'

    '':
        layers: ''
        style: ''


dot= (props)->
    div '"$border=1px solid red" $width=0 $height=0 "$margin=-1px 0 0 -1px"',props

{ gpsMarker, gpsTracker }= require 'vx/comp/GPS' if vx.use.gps
#{ recordButton, recordedPath }= require 'vx/comp/traces/Recorder' if vx.use.traces
#{ tracePath }= require 'vx/comp/traces/Traces' if vx.use.traces

{ Pt }= require 'vx/graph/Graph5'

GroupClassName=  "map-html" # hard code cause complicated to make dynamic  MapHTML::_className

#MapWidgets= require 'MapWidgets'

zI=
    ground:  1
    mask:    2
    meteo:   3

    pistes:  5
    surf:    6
    #cond:   7

    circuits: 10
    conditions: 7
    circuitsFocus: 11
    condFocus:     18
    itin:          12

    pancartes: 15
    attraits: 16
    pictoCircuits: 17
    pictoCond: 17

    bkgPopup: 18
    selectedSeg: 20



mapPageFor= (MapWidgets)->
    { API, mapWidget, mapControl, mapSearch, mapPopup, polyline, marker, mapHTML, mapSVG }= MapWidgets
    { tileLayer, TileLayer, vectorTileLayer, wmsTileLayer, dynamicTileLayer, cachedZoomTileLayer, tangramLayer }= MapWidgets

    itinMapView= itinMapViewFactory MapWidgets     if vx.use.itin

    attraitsLayer= attraitsLayerFactory MapWidgets if vx.use.attraits

    surfLayer=     surfLayerFactory     MapWidgets if vx.use.surf

    drawLine= (ops,pts)-> drawLineM ops,({x,y:ym ? latM y} for {x,y,ym} in pts )
    drawLines= (ops,aPts)->
        #log "drawLines got aPts=",aPts
        drawLinesM ops,( ({x,y:ym ? latM y} for {x,ym,y} in pts ) for pts in aPts )


    drawLineA= (ops,apts)-> drawLine ops,({x,y:latM y} for [x,y] in apts )


    drawLineMA= (ops,apts)-> drawLineM ops,({x,y} for [x,y] in apts )

    drawLineM=(ops,pts)->
        { zoom, px=4, key, id, zIndex, start={}, end={},className }=ops
        bnds= Bounds.aPTS pts

        {x,y,X,Y}= bnds.grow pixel2deg 2*px,zoom

        #log " mapSVG pts is ",{top:Y, bottom:y, left:x, right:X}


        mapSVG
            top:Y
            bottom:y
            left:x
            right:X
            key:id
            id:id
            zIndex:zIndex
            className: className
            visible: true
            svgPolyline
                __proto__: ops
                px:    undefined
                zoom:  undefined
                zIndex:undefined
                start: undefined
                end:   undefined
                #$strokeWidth:"calc(#{px}*var(--svgpixelsize))"
                points:( "#{x},#{y}" for {x,y} in pts).join ' '


    drawLinesM=(ops,aPts)->
        #log "drawLinesM got aPts=",aPts
        { zoom, px=4, key, id, zIndex, start={}, end={},className,stroke }=ops
        bnds= Bounds.aPTS [].concat.apply [],aPts

        {x,y,X,Y}= bnds.grow pixel2deg 2*px,zoom

        #log " drawLinesM bounds #{id} ",{top:Y, bottom:y, left:x, right:X}


        mapSVG
            top:Y
            bottom:y
            left:x
            right:X
            key:key or id
            id:id
            zIndex:zIndex
            visible:true
            className: className

            for pts,idx in aPts
                ppts= ( "#{x},#{y}" for {x,y} in pts).join ' '
                #log " drawLinesM line #{id} pts=",ppts
                svgPolyline
                    __proto__: ops
                    key:idx
                    px:    undefined
                    zoom:  undefined
                    zIndex:undefined
                    start: undefined
                    end:   undefined
                    $stroke:stroke
                    #$strokeWidth:"calc(#{px}*var(--svgpixelsize))"
                    points:ppts

    DoBkgClick= undefined

    api: api= API
    mapPage: ({app,id='map',active,HasGPS=false,bkgSourceMode=1,bkgHybrideSplit=13,option_beta=false,bkgMenuVisible})->

        beta= option_beta
        #DoBkgClick?= app.doBkgClick
        DoBkgClick?= (e)->
            #timeStart 'bkgClick'
            #log "start bkgClick"
            app.doBkgClick e
        #log "mapPage app keys=#{Object.keys app}\n allProps= #{oAllPropNames app}\n all keys=#{(k for k,v of app when v?).sort()}"

        maxZoom= if vx.isDEV then 22 else 18
        zoomSplit= 14

        condAfter= calcCondAfter()
        
        noAttraits= !app.getAttraits()?.length
        
        #Test if we should hide Splash
        
        if !vx.bootfinished #and app.state.graph 
            vx.bootfinished=true
            setTimeout2 2000,-> #2000
                $('#vx-splash')?.style.display='none'
                navigator?.splashscreen?.hide?()

        page "##{id} .page--fullscreen",{active},
            content mapWidget "#{id}div",
                ref: C.mapPageRef?= (mapWidget)-> app["#{API}Map"]= app.mapWidget =mapWidget

                promise: "main-#{api}-map"
                mapOps:C.mapOps?= {
                    disableDoubleClickZoom:true
                    maxZoom: maxZoom
                    minZoom: 0
                    fadeAnimation:  false
                    zoomAnimation:  true
                    gestureHandling: vx.mapMode.gestureHandling
                    }
                screenWidth: app.state.vxScreenWidth
                minZoom:0
                maxZoom: maxZoom
                zoom: app.getMapZoom()
                onNewZoom:   app.setMapZoom

                center: app.getMapCenter()
                onNewCenter: app.setMapCenter

                bounds: app.getMapBounds()
                onNewBounds: app.setMapBounds

                mapType: app.getMapType()
                onNewMapType: app.setMapType
                #trackMode: app.getTrackMode()

                onBkgClick:       if vx.mapMode.bkgClick then DoBkgClick

                # ******************** CONTROLS ********************************

                if vx.isAPP
                  [ # leaflet
                    mapControl key:'cAllRight',position:'TOP_RIGHT',div '',

                        if !vx.mapMode.paneauCouches then ' '
                        else
                            button '.mapcontrol #btnPaneauCouches $marginLeft=16px',
                                onClick:app.toggleCouches
                                icon '|location_on'
                                #span '.vx-label__responsive .vx-hide-w-narrow',say "Couches"

                        if !vx.mapMode.paneauAttraits then ' '
                        else
                            button '.mapcontrol #btnPaneauAttraits $marginLeft=16px',
                                disabled: noAttraits
                                onClick:app.toggleAttraits
                                icon '|location_on'
                                #span '.vx-label__responsive .vx-hide-w-narrow',say "Attraits"

                        if !vx.use.fullscreen then ' '
                        else fullScreenButton
                                isFullScreen:app.state.isFullScreen
                                app:app
                                
                    if vx.mapMode.menu or vx.use.sharing
                        mapControl key:'c1',position:'LEFT_TOP',index:0,div '',

                            if vx.mapMode.menu
                                button '.mapcontrol  $marginRight=16px',
                                    onClick:app.toggleMenu
                                    icon '|menu'
                                    span '.vx-label__responsive .vx-show-w-wide',say "Map Menu"

                            if vx.use.sharing
                                button '#btin-carte-partager .mapcontrol  $marginRight=16px',
                                    onClick:app.setEnvoyerDialog
                                    value: true
                                    title: say "hover btn partager"
                                    icon '|share'
                                    span '.vx-label__responsive .vx-hide-w-narrow',say "titre btn partager"

                    if vx.use.itin
                        itin= app.getItin()
                        mapControl key:'c1b',position:'LEFT_TOP',index:0,div '',
                            itinMapButton itin:itin,zoom:app.getMapZoom()
                            itinXMenu {mode:'onmap',app,i18nInfo:app.state.i18nInfo,itin:app.getItin(),beta} if beta and vx.mapMode.itinMenu and itin?.waypoints?.length > 1
                           
                    mapControl 'position:RIGHT_BOTTOM',key:'c13',
                        icon '$marginRight=18px',
                            if app.getNetworkState() is 'online'
                                 'cloud'
                            else 'cloud_off'

                    if vx.isDEV
                        mapControl 'position:RIGHT_BOTTOM',key:'c14',
                            fab '',
                                onClick:(-> app.setState networkState: if app.getNetworkState() is 'online' then 'offline' else 'online')
                                icon if app.getNetworkState() is 'online' then '|cloud_off' else '|cloud'
                                "#{app.getMapZoom()}"

                    ]

                else [ # gmaps
                    if vx.use.fullscreen
                        mapControl key:'c2a',position:'TOP_RIGHT',
                            fullScreenButton
                                isFullScreen:app.state.isFullScreen
                                app:app

                    if vx.mapMode.paneauAttraits
                        mapControl key:'c2b',position:'TOP_RIGHT',
                            button '.mapcontrol #btnPaneauAttraits ',
                                onClick:app.toggleAttraits
                                disabled: noAttraits
                                icon '|location_on'
                                #span '.vx-label__responsive .vx-hide-w-narrow',say "Attraits"
                    if vx.mapMode.paneauCouches
                        mapControl key:'c2c',position:'TOP_RIGHT',
                            button '.mapcontrol #btnPaneauCouches ',
                                onClick:app.toggleCouches
                                icon '|location_on'
                                #span '.vx-label__responsive .vx-hide-w-narrow',say "Couches"
                                
                    if vx.mapMode.boutonApp
                        mapControl key:'c10',position:'TOP_RIGHT',
                            button '#btnInsApp .mapcontrol target=_blank .vx-hide-w-compact',
                                href:  say 'btn install app href'
                                title: say "btn install app hover"
                                icon '|phone_iphone'
                                say "btn install app"

                    if vx.mapMode.menu
                        mapControl key:'c1',position:'TOP_LEFT',index:0,div '',
                            button '#btn-carte-menu .mapcontrol',
                                onClick:app.toggleMenu,
                                icon '|menu'
                                span '.vx-label__responsive .vx-hide-w-narrow',say "Map Menu"

                    if vx.use.circuits and vx.mapMode.paneauListCircuits
                        mapControl key:'c1b',position:'LEFT_TOP',index:0,div '',
                            button '#btn-carte-list-cir .mapcontrol #btn-paneau-listcircuits',
                                onClick:app.toggleCircuitsList
                                say "btn list circuits"
                                icon '|arrow_forward' #+"#{app.state.mapZoom}"


                    if vx.use.sharing
                        mapControl key:'c9',position:'TOP_LEFT',
                            button '#btin-carte-partager .mapcontrol ',
                                onClick:app.setEnvoyerDialog
                                value: true
                                title: say "hover btn partager"
                                icon '|share'
                                span '.vx-label__responsive .vx-hide-w-narrow',say "titre btn partager"

                    if vx.use.itin
                        itin= app.getItin()
                        mapControl key:'c7',position:'LEFT_TOP',span '.vx-buttongroup',
                            itinMapButton itin:itin,zoom:app.getMapZoom(),i18nInfo:app.state.i18nInfo
                            itinXMenu {mode:'onmap',app,i18nInfo:app.state.i18nInfo,itin:itin,beta} if beta and vx.mapMode.itinMenu and itin?.waypoints?.length > 1
                            
                    if vx.mapMode.search
                        mapControl key:'c6',position: 'RIGHT_TOP',
                            mapSearch '#mapsearch1 .mapcontrol name=mapSearch',
                                bounds: app.getMapBounds()
                                onNewValue:app.setMapSearch
                                $width:'20em'
                                $maxWidth:'40vw'
                                acOps: C.acOps?= {
                                    componentRestrictions: country:'ca'  # 'fr'
                                    }
                    ]


                mapControl 'position:BOTTOM_LEFT',key:'c11',
                   aTag '#copyrightctrl $cursor=pointer $backgroundColor=rgba(245,245,245,0.7) $borderRadius=3px $position=absolute $bottom:0 $whiteSpace=nowrap',
                        onClick: C.setCopyright?= -> app.set true,'copyrightDialog'
                        title: say "hover bouton copyright"
                        #icon '$marginTop:-4px |copyright'
                        span '$textDecoration:underline $marginBottom=4px $display=inline-block',say "titre bouton copyright"

                mapControl 'position=LEFT_BOTTOM',key:'c12',
                    div '$marginBottom:0',
                        div '#vx-logoviaexplora .vx-app-logo'
                        div '#vx-logofcmq .vx-app-logo .fcmq-bkg-logoassis'

                if vx.use.surf and vx.mapMode.hasSurf
                    mapControl 'position:LEFT_BOTTOM',key:'c152',
                        surfLegend2 '#surflegendctrl .mdc-elevation--z4  $marginTop=4px ',
                            visible: ( app.getSurfV2MaxTime() and app.getSurfVisibility 'surfaceuses' ) or (
                                    app.getDebugSurf() and app.getSurfBrutMaxTime() and app.getSurfVisibility 'surfaceusesbrute' )
                            maxTime: ((app.getSurfVisibility 'surfaceuses') and app.getSurfV2MaxTime()) or app.getSurfBrutMaxTime()

                mapControl 'position:LEFT_BOTTOM',key:'c8',
                    img '#meteolegendctrl .mdc-elevation--z4 $width=67px "$border=1px solid white"',
                        #hint: !app.getSurfMaxTime() or !app.getSurfVisibility() or (app.getNetworkState() isnt 'online')
                        $display:if !(m=app.get 'meteo') or (app.getNetworkState() isnt 'online') then 'none'
                        src: if m
                            "http://geo.weather.gc.ca/geomet//?LANG=#{app.state.lang}&SERVICE=WMS&VERSION=1.3&REQUEST=GetLegendGraphic&STYLE=#{MeteoInfo[m].style}&LAYER=#{MeteoInfo[m].layers}&format=image/png&SLD_VERSION=1.1.0"
                          else ''


                if vx.use.gps
                    mapControl key:'c5',position:'RIGHT_BOTTOM',
                        gpsTracker
                            className:'mapcontrol'
                            gpsPos:app.getGPSPos()
                            value:app.getTrackMode()
                            onNewValue:app.setTrackMode


                # *************** MAIN MAP LAYERS ****************************************

                if api is 'leaflet'
                    (
                        tileURL= vx.urls.TilePathOffline
                        if bkgSourceMode and app.getNetworkState() is 'online' 
                            if bkgSourceMode==2 or app.getMapZoom()>bkgHybrideSplit
                                tileURL= vx.urls.TilePathOnline
                    
                        vectorTileLayer
                            key: 'mapvectorbackgroundoffline'
                            visible: true
                            #tileURL:"https://api.mapbox.com/styles/v1/mapbox/outdoors-v10/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZ2RyYXBlYXUiLCJhIjoiYmU3NDgwYmYzYmFjMmRhYTY0YWZjZWU4MGQ1OThhMDAifQ.g-B5PBKFOXwks9A1nqD79Q"
                            tileURL: tileURL
                            minZoom: 0
                            maxZoom: maxZoom
                            zIndex:zI.ground
                        )

                if vx.q.maskName and app.getLayerVisibility 'mask' 
                    # ,- 54.50529037731543,  "
                    d=8
                    mapSVG
                        id: 'mask'
                        key: 'mask'
                        # bounds= 51.13286649118823,-76.1493193816871 54.505918103161974,-73.7303911836244  " -->
                        top:    d+54.505918103161974
                        bottom:-d+51.13286649118823
                        left:  -d+ -76.149319381687
                        right:  d+ -73.7303911836244
                        zoom: app.getMapZoom()
                        zIndex: zI.mask
                        svgUse xlinkHref:"/css/svg/#{vx.q.maskName or 'mask'}.svg#path"

                if wmsTileLayer and (meteoName= app.get 'meteo') and (meteoName isnt 'aucun') and (app.getNetworkState() is 'online')
                    wmsTileLayer
                        key: meteoName
                        tileSize: 256
                        visible: meteoName of MeteoInfo
                        opacity: 0.5
                        name: 'meteoLayer'
                        tileURL:"http://geo.weather.gc.ca/geomet/?"
                        wmsOps: oEq 'meteoLayer', #cause lang can change
                            format: "image/png"
                            layers: MeteoInfo[meteoName].layers
                            style:  MeteoInfo[meteoName].style
                            lang: app.state.lang
                        minZoom:5
                        maxZoom:17
                        zIndex: zI.meteo


                if vx.isAPP
                    if app.state.graph #or app.state.graphS
                        dynamicTileLayer
                            key: 'pmnGraph'
                            id:  'pmnGraph'
                            visible: true #true # true # cant remove app.getLayerVisibility 'pmnGraph'
                            opacity: vx.mapMode.pisteOpacity or 0.5
                            name: "pmnGraph"
                            content: app.state.graph?.getLineCache()# or app.state.graphS.getLineCache()
                            info: oEq 'pmnGraph',
                                eco:   app.getLayerVisibility 'ecosentiers'
                                pancartes: true
                                nolines: (app.getSurfVisibility?() or app.getCondVisibility?() )
                                pancartesMinZoom: 7
                                nodes: app.state.graph?.getNodeCache3() # or app.state.graphS.getNodeCache()
                            renderTile: pmnDrawer
                            cacheSize:0
                            minZoom:4 #9
                            maxZoom: maxZoom
                            zIndex: zI.pistes
                            updateWhenZooming: false
                            updateWhenIdle: true
                            pane:'overlayPane'

                else if app.state.graphSimple then  [ # gmaps 
                    tileLayer oEq 'pmnGraphSrv',
                        key: id='pmnGraphSrv-'+(qs="?#{if app.getLayerVisibility 'ecosentiers' then 'eco=1&' else''}d=#{(app.state.graphFileInfo?.LastModified or 0).toString 36}")
                        qs:  qs         # cache busting
                        id:  id
                        visible: !(app.getSurfVisibility?() or app.getCondVisibility?() )#true # true # cant remove app.getLayerVisibility 'pmnGraph'
                        opacity: vx.mapMode.pisteOpacity or 0.5
                        name: "pmnGraphSrv"
                        minZoom:4 #9
                        maxZoom: maxZoom
                        zIndex: zI.pistes
                        updateWhenZooming: false
                        updateWhenIdle: true
                        tileURL: vx.urls.MapURL or vx.q.couche or "/tiles/pmn"
                        tileSize:256
                        zoomOffset:0

#                     dynamicTileLayer  oEq 'pmnGraphSentiers',
#                         key: 'pmnGraphSentiers'
#                         id:  'pmnGraphSentiers'
#                         visible:  !(app.getSurfVisibility?() or app.getCondVisibility?() )# true # cant remove app.getLayerVisibility 'pmnGraph'
#                         opacity:0.8
#                         name: "pmnGraphSentiers"
#                         content: app.state.graphSimple?.getLineCache()# or app.state.graphS.getLineCache()
#                         info: 
#                             eco:   app.getLayerVisibility 'ecosentiers'
#                             pancartes: false
#                             nolines: false
#                             pancartesMinZoom: 0
#                             nodes: app.state.graph?.getNodeCache3() # or app.state.graphS.getNodeCache()
#                         renderTile: pmnDrawer
#                         cacheSize:0
#                         minZoom: 0#9
#                         maxZoom: maxZoom
#                         zIndex: zI.pistes
#                         updateWhenZooming: false
#                         updateWhenIdle: true


                    dynamicTileLayer  oEq 'pmnGraphPancartes',
                        key: 'pmnGraphPancartes'
                        id:  'pmnGraphPancartes'
                        visible: true # true # cant remove app.getLayerVisibility 'pmnGraph'
                        opacity:0.8
                        name: "pmnGraphPancartes"
                        content: app.state.graphSimple?.getLineCache()# or app.state.graphS.getLineCache()
                        info: oEq 'pmnGraphPancartesInfo',
                            #eco:   app.getLayerVisibility 'ecosentiers'
                            pancartes: true
                            nolines: true
                            pancartesMinZoom: 7
                            nodes: app.state.graph?.getNodeCache3() # or app.state.graphS.getNodeCache()
                        renderTile: pmnDrawer
                        cacheSize:0
                        minZoom: 7 #9
                        maxZoom: maxZoom
                        zIndex: zI.pancartes
                        updateWhenZooming: false
                        updateWhenIdle: true

                    ]



                if vx.mapMode.condAlerts
                    # log "Doing conditions layers conditionsSVGLayer=#{typeof conditionsSVGLayer} conditionsMarkerLayer=#{typeof conditionsMarkerLayer} "
                    [
                        conditionsSVGLayer
                            id: 'alertssvglayer'
                            key:'alertssvglayer'
                            lastModified: app.state.conditionsFileInfo?.LastModified or 0
                            MapWidgets: MapWidgets
                            app: app
                            conditions:  app.state.conditionsAlerts
                            decos:     app.state.conditionsDecorations
                            visible:   !app.getCondVisibility?()
                            #graph:     app.state.graph
                            zoomMode:  app.state.conditionSelectedZoomMode
                            zoom: app.getMapZoom()
                            zIndex: zI.conditions
                            after: condAfter

                        conditionsMarkerLayer
                            id: 'alertsmarkerlayer'
                            key:'alertsmarkerlayer'
                            lastModified:app.state.conditionsFileInfo?.LastModified or 0
                            MapWidgets: MapWidgets
                            app: app
                            conditions:  app.state.conditionsAlerts
                            decos:     app.state.conditionsDecorations
                            visible:   !app.getCondVisibility?()
                            #graph:     app.state.graph
                            zoomMode:  app.state.conditionsSelectedZoomMode
                            setHoverId: app.setConditionHoverId
                            onMarkerClick: app.toggleConditionSelectedId
                            zIndex: zI.pictoCond
                            after: condAfter
                    ]


                if app.state.circuits # and app.state.graph and
                    [
                        circuitsSVGLayer
                            key:'circuitsvglayer'
                            id:'circuitsvglayer'
                            MapWidgets: MapWidgets
                            app: app
                            circuits:  app.state.circuits
                            decos:     app.state.circuitsDecorations
                            #graph:     app.state.graph
                            zoomMode:  app.state.circuitSelectedZoomMode
                            zoom: app.getMapZoom()
                            setHoverId: app.setCircuitHoverId   if vx.mapMode.circuitPathHover
                            onPathClick: app.toggleCircuitSelectedId if vx.mapMode.circuitPathClickSelect
                            zIndex: zI.circuits

                        circuitsMarkerLayer
                            key:'circuitmarkerlayer'
                            id:'circuitmarkerlayer'
                            MapWidgets: MapWidgets
                            app: app
                            circuits:  app.state.circuits
                            decos:     app.state.circuitsDecorations
                            markerPos: vx.mapMode.circuitMarkerPos
                            visible:   !vx.mapMode.circuitMarkerHidden and (!vx.mapMode.hasCond or !app.getCondVisibility?())
                            #graph:     app.state.graph
                            zoomMode:  app.state.circuitSelectedZoomMode
                            setHoverId: app.setCircuitHoverId if vx.mapMode.circuitMarkerHover
                            onMarkerClick: app.toggleCircuitSelectedId if vx.mapMode.circuitMarkerClickSelect
                            zIndex: zI.pictoCircuits
                        ]


                if app.state.conditions # and app.state.graph and
                    # log "Doing conditions layers conditionsSVGLayer=#{typeof conditionsSVGLayer} conditionsMarkerLayer=#{typeof conditionsMarkerLayer} "
                    [

                        tileLayer
                            key: id='conditions-tilelayer'#+(app.state.conditionsFileInfo?.LastModified or 0).toString 36  # reload on datechange
                            qs: "?#{if vx.q?.cq then vx.q.cq+'&' else ''}d=#{(app.state.conditionsFileInfo?.LastModified or 0).toString 36}" # cache busting
                            id: id
                            #lastModified: app.state.conditionsFileInfo?.LastModified or 0
                            name: 'conditions'
                            api: api
                            visible: app.getCondVisibility()

                            tileURL: if API is 'leaflet'
                                    "#{vx.urls.Conditions}/{z}/{x}/{y}.png"
                                else vx.urls.Conditions

                            #opacity: 1 #vx.mapMode.condOpacity or (if vx.mapMode.condOld then 0.5 else 1)
                            #getTileUrl: (TileLayer::basicVXCondTileUrlFn) if vx.mapMode.condOld
                            minZoom:5
                            maxZoom: maxZoom
                            zIndex: zI.conditions


                        conditionsMarkerLayer
                            id: 'conditionsmarkerlayer'
                            key:'conditionsmarkerlayer'
                            lastModified:app.state.conditionsFileInfo?.LastModified or 0
                            MapWidgets: MapWidgets
                            app: app
                            conditions:  app.state.conditions
                            decos:     app.state.conditionsDecorations
                            visible:   app.getCondVisibility()
                            #graph:     app.state.graph
                            zoomMode:  app.state.conditionsSelectedZoomMode
                            setHoverId: app.setConditionHoverId
                            onMarkerClick: app.toggleConditionSelectedId
                            zIndex: zI.pictoCond
                            after: condAfter
                    ]


                if vx.use.surf and vx.mapMode.hasSurf
                    surfLayer
                        key: 'surfaceuses'
                        id: 'surfaceuses'
                        name: 'surfaceuses'
                        #api: api
                        maxTime: app.getSurfV2MaxTime()
                        visible: app.getSurfVisibility()
                        minZoom:5
                        maxZoom: maxZoom
                        zIndex: zI.surf


                if app.state.attraits and attraitsLayer
                    attraitsLayer
                        key:  'attraitsLayer'
                        id:   'attraitsLayer'
                        visible: true
                        name: 'attraitsLayer'
                        latmContent: true
                        content: oEq 'data-attraits-zoom-bnds',filterVBnds,
                            bnds:     app.getMapObjBounds() #oEq 'data-attraitsLayer-bnds',app.getMapObjBounds(),undefined,1
                            selected: app.getSelectedAttraitId()
                            content:  oEq 'data-attraits-zoom',makeClusters,
                                zoom:    app.getMapZoom()
                                selectedId:  app.getSelectedAttraitId()
                                visibleAttraits:   app.getVisibleAttraits()
                                content: oEq 'data-attraits',
                                    (-> makeEtablisments {@visibleAttraits,selectedId:app.getSelectedAttraitId(),content:filter @}),
                                    visibleGroups:     app.getVisibleGroups()
                                    visibleAttraits:   app.getVisibleAttraits()
                                    groupAttrib: app.groupAttrib
                                    content: app.getAttraits()
                                    selectedId: app.getSelectedAttraitId()
                        minZoom: 0
                        maxZoom:  maxZoom
                        zoomAnimated:false
                        updateWhenZooming: false
                        info: oEq 'attraitsLayerInfo',
                            app: app
                            selected:    app.getSelectedAttraitId()
                            groupAttrib: app.groupAttrib
                            decorations: app.getAllAttraitsDecorations()
                        zIndex: zI.attraits


                if vx.use.itin and app.hasItin()
                    itinMapView
                        key: 'itin'
                        app: app
                        onNewValue: app.doUpdateItin
                        itin: app.getItin()
                        attraits: app.getAttraits() # changes if attraits changes
                        selectedWP: app.getItinSelectedWP()
                        zIndex: zI.itin

                if vx.mapMode.showSelSeg and (seg=app.state.selectedSeg?.seg) and ( (app.getSurfVisibility? 'surfaceuses') or app.getCondVisibility?()) #(seg=app.state.selectedSeg?.seg) and ( (vx.use.surf and app.getDebugSurf()) or ( vx.mapMode.hasCond and app.getCondVisibility() ))
                    segId= app.state.selectedSeg?.seg.id or 0
                    segLineId=if segId then "#{segId}-sel-svg" else 'nosegid-sel-svg'
                    #log "drawing slected seg line segLineId=#{segLineId}",app.state.selectedSeg
                    switch
                        when app.getCondVisibility?()
                            cls="vx-sel-line condition-path condition-path__cond-#{asCondClass app.getCondTypeSegId segId}"
                        when (app.getSurfVisibility? 'surfaceuses')
                            if (info=app.state.surfSegInfo) and info.seg?.id is segId and info.passage and info.maxTime
                                {maxTime, passage}= info
                                clr= asSurfColor maxTime,passage.entryTime,passage.exitTime
                            cls="vx-sel-line surf-path"
                        else cls='vx-sel-line'
                    drawLines {key:segLineId,stroke:clr,id:segLineId,className:cls,zoom:app.getMapZoom(),zIndex:zI.selectedSeg},[seg.pts]


                if (condId=app.getConditionSelectedId()) and app.getCondVisibility?() and ( cond= app.getConditionId condId) #(seg=app.state.selectedSeg?.seg) and ( (vx.use.surf and app.getDebugSurf()) or ( vx.mapMode.hasCond and app.getCondVisibility() ))
                    condLineId=if condId then "#{condId.replace /\./g,'_'}-sel-svg" else 'nocondid-sel-svg'
                    #log "drawing slected cond line  condLineId=#{condLineId}",app.state.selectedSeg
                    cls="vx-sel-line condition-path condition-path__cond-#{asCondClassFromCond cond}"
                    #log " cls=#{cls} before drawLines"
                    drawLines {key:condLineId,id:condLineId,className:cls,zoom:app.getMapZoom(),zIndex:zI.selectedSeg},cond.pts

                if vx.mapMode.bkgClick
                    mapHTML "#mapanchor0",key:'mapanchor0',top:47.1,bottom:47,left:-70,right:-69.9,visible:true, zIndex:zI.bkgPopup, #top:50,bottom:45,left:-79,right:-64,visible:true,
                        gpsMarker key:'gpsmarker',pos:app.getGPSPos(),onClick:app.doGPSClick if vx.use.gps and app.getGPSPos()
                        createElement bkgPopup,
                            _debug: 0
                            mapPopup: mapPopup
                            pt: pt=app.get 'bkgMenuPosition'
                            position: { top:pt[0],left:pt[1]}
                            app:      app
                            visible:  bkgMenuVisible
                            bkgMenuIsGPS:   app.state.bkgMenuIsGPS
                            surfVisibility: app.getSurfVisibility 'surfaceuses'
                            condVisibility: app.getCondVisibility()
                            bkgMenuVisible:bkgMenuVisible
                        itinMapWpInfoPopup {app,id,mapPopup} if vx.use.itin

                if vx.mapMode.search and (pos=app.getMapSearchPos())
                    marker
                        key:'serchmarker'
                        position:pos
                        title:app.getMapSearchName()


            # End of content Panels only on map 
            
            if vx.use.sharing and app.getEnvoyerDialog()
                shareDialog app:app


            mDialog '#copyrightDialog footer=ok $width=40em',
                title: say "titre popup copyright"
                visible: app.get 'copyrightDialog'
                onClose: C.diagclose?= ->app.set false,'copyrightDialog'
                ok:[(icon '|cancel'),say "Ok"]
                say "html popup copyright"


          

# *************** extern function *********************************

mapInfoPanel=({app,id='servives-info-panel'})->

    selectedAttraitId=  vx.use.attraits and app.getSelectedAttraitId()
    selectedCircuitId=  vx.use.circuits and app.getCircuitSelectedId()

    sidePanel "##{id}  .mdc-elevation--z8 ",
        active: ( selectedAttraitId and selectedAttrait=app.getAttraitById selectedAttraitId ) or selectedCircuitId #or (selectedParcours=app.getParcoursById app.getSelectedParcours())
        switch
            when selectedAttrait
                #log " mapInfoPanel setectedAttrait =",selectedAttrait
                attraitInfo
                    attrait:selectedAttrait
                    app:app
                    onClose:app.toggleSelectedAttraitId
                    itin:app.state.itin
            when selectedCircuitId and app.getCircuitId selectedCircuitId
                circuitInfo
                    circuitId:selectedCircuitId
                    app:app
                    onClose: C.cInfoClose?= (obj)->app.toggleCircuitSelectedId obj.id
                    #itin:app.state.itin
                    deco: app.getCircuitDecorations selectedCircuitId
                    ops: oEq 'screenWidth',{screenWidth:app.state.vxScreenWidth}
            #when  selectedParcours
            #    selectedParcoursId
            else null



mapCouchesPanelCustom= ({app,id='servives-couches-panel'})->
    sidePanel "##{id} right .mdc-elevation--z8  ",
        active: app.state.servicesCouchesVisible

        templateWidget
            componentClassName: if vx.mapMode.multiCouches then 'couche-selector-multi' else 'couche-selector'
            say: say
            obj:oEq 'couchesPanelState',
                meteo: (app.get 'meteo')
                surf: app.getSurfVisibility? 'surfaceuses'
                eco:  app.getLayerVisibility? 'ecosentiers'
                cond: app.getCondVisibility?()
                offLine: (app.getNetworkState() is 'offline')
                hasCond:      vx.mapMode.hasCond
                hasSurf:      vx.mapMode.hasSurf
                hasEco:       vx.mapMode.hasEco

            actions: oEq 'couchesPanelAction',{app},({app})->
                doSetMeteo: (obj,{value}={})->
                    log "setMeteo to #{value}"
                    app.set value,'meteo'  if value?
                doSetSurf:  (obj,{checked})-> app.setSurfVisibility  checked,'surfaceuses'
                doSetCond:  (obj,{checked})-> app.setCondVisibility  checked
                doSetEco:   (obj,{checked})-> app.setLayerVisibility checked,'ecosentiers'
                doSetCouche: (obj,{value})->
                    #log "set couche to #{value}"
                    if value isnt 'surf' and app.getSurfVisibility 'surfaceuses'
                        app.setSurfVisibility  false,'surfaceuses'
                    if value isnt 'ecosentiers' and app.getLayerVisibility 'ecosentiers'
                        app.setLayerVisibility false,'ecosentiers'
                    if value isnt 'cond' and app.getCondVisibility?()
                        app.setCondVisibility  false
                    switch value
                        when 'surf' then app.setSurfVisibility  true,'surfaceuses'
                        when 'ecosentiers'  then app.setLayerVisibility true,'ecosentiers'
                        when 'cond' then app.setCondVisibility  true

                doClose: app.toggleCouches


mapAttraitsPanel= ({app,id='servives-attraits-panel'})->
    sidePanel "##{id} right .mdc-elevation--z8 ",
        active:app.state.servicesAttraitsVisible
        data_hint_:[app.getGroups(),app.getVisibleGroups()]

        if !oIsEmpty app.getGroups()
            attraitsSelectorCustom { app, attraitIcon}
    
    
    
    
# **************** functional components *********************         

bkgPopup= (props)->
    {app,pt,visible,bkgMenuIsGPS,surfVisibility,condVisibility,mapPopup,style,perf={}}= props

    pt_= Pt._ pt[1],pt[0]
    {bkgPopup1,bkgPopup2}= perf
#     if visible
#         #log "end bkgClick"
#         timeEnd  'bkgClick'
#     else
#         log "log bkgClick"
#         timeLog  'bkgClick'
    
    ret= mapPopup '#mapBkgMenu elevation=8',
        style:style
        pos: pt
        active: visible
        app:app
        header2 
            left: tbTitle null,if bkgMenuIsGPS then say "GPS" else say "Titre menu bkg"
            rightShrink: button '',onClick:app.toggleBkgMenu,title:(say "fermer"),(icon '|close')
        div '.mdc-typography--body2 "$padding=4px 8px 8px 16px"',{},...[
            hr() if bkgPopup1 
            if !bkgPopup1 
                graphPtInfo # we asume graph does not change
                    key: 'ptinfo'
                    graph: app.get 'graph'
                    clubs: app.get 'clubs'
                    pt: pt

            if !bkgPopup2 and vx.use.surf and surfVisibility 
                #log "app.getSurfVisibility 'surfaceusesv2' true"
                surfPtDetailsV2
                    key:'surfPtDetailsV2'
                    infoURL: app.getSurfInfoURL 'surfaceusesv2'
                    #TODO: make this pt and not pt_
                    pt: pt_
                    debug: 0 #app.getDebugSurf()
                    graph: app.state.graph
                    setInfo: app.setSurfSegInfo

            if !bkgPopup2 and vx.mapMode.hasCond and condVisibility and !vx.urls.condold and vx.urls.Conditions
                #log "app condPtDetails"

                condPtDetails
                    key:'conddetails'
                    infoURL: app.getCondInfoURL()
                    onlyFermer: false
                    #TODO: make this pt and not pt_
                    pt: pt_


            else if !bkgPopup2
                condPtDetails
                    key:'conddetails2'
                    infoURL: app.getCondInfoURL()
                    onlyFermer: true
                    pt: pt_
                    
            itinButtons {app,wp:(pt),onClick:->app.set false,'bkgMenuVisible'} if !bkgPopup2 and vx.use.itin
            ]
    #log "bkgMenu did ret=",ret
    ret


            
  
module.exports= { mapPageFor, mapCouchesPanelCustom, mapAttraitsPanel, mapInfoPanel }



