{ log, meta, oIsEmpty, oA, Global }= vx= require 'vx/globals/Boot'  # to get a global ref to HelloWorld that survies webpack
ModuleName= 'AppAdminPage'

require './AdminPage.css'

wait= require 'vx/tools/Wait'

vx.fileurls= require 'vx/globals/FileURLS'

# I18n
{ sayModule }= require 'vx/i18n/I18n'
say= sayModule ModuleName

UI= require 'vx/UI'

# UI layout
{ page, header2, tbTitle, content, ul, li }= UI

# html inline
{ p, br, h5 }= UI

#htmlctrls
{ button }= UI



DebugPage= ({menuBtn,app}={})->
    page '#debug key:debug',

        header2 '',
            left:[ (menuBtn ''),tbTitle null,say 'Debug' ]

        content '.page__content-margin',
            br()
            button '',
                onClick: ->
                    try
                        localStorage.clear()
                    app.setState reload:new Date
                "Resset"
            "  "
            button '',
                onClick: ->
                    app.setState reload:new Date
                "Refresh"

            if app.getDeviceReady()

                [
                    h5 '',"Local Storage #{if !vx.isNODE then Global.localStorage?.length else '?'}"
                    if !vx.isNODE and Global.localStorage
                        ul '',
                            for k in ( k2=localStorage.key i for i in [0...localStorage.length]).sort()
                                li ''," #{k} [#{(item=localStorage.getItem(k)).length}] : #{if item.length<200 then item else item[... 200]+'...'}"
                    if !vx.isNODE and Global.localStorage
                        "Total: #{ t=0; (  t+= (localStorage.getItem localStorage.key i).length for i in [0...localStorage.length]);t}"
                ]


module.exports= { DebugPage, debugPage:DebugPage }
