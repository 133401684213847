{ log, oSplit, oAssign, meta, getPropertyText, isPrefix, $ }= vx= require 'vx/globals/Boot'
ModuleName= 'ItinWidgets'


require './ItinWidgets.css'

UI= require 'vx/UI'

{ UIComponent }= UI

{ page, div, h1, h2, h3, h4, h5, h6, headline, caption, span, p, a, i, code, br, img, em, hr, header2, tbIcon, tbTitle, section, Page, menu, menuSurfaceAnchor, listItemText, listItemDivider, list }= UI
{ label, option, button:hbutton, dialog, code, fieldset, title, group, details, summary, layout, cell, ul, li }= UI # select, use UISelect
{ table, tbody, tr, td }= UI # select, use UISelect

#{ button2:button, button:button1, icon, content }=  UI
{ button, valueButton, icon, content }=  UI

{ sayModule, lang}= require 'vx/i18n/I18n'
{ vxIcon }=  require 'vx/ui/vxIcon'
say= sayModule ModuleName


{ latM }= require 'vx/math/MapGeometry'


itinMapViewFactory= (MapWidgets)->

    { marker, polyline, polylineSVG, API }= MapWidgets

    meta class ItinMapView extends UIComponent
        @displayName: 'ItinMapView'

        @propTypes=
            gmapMap:  @type.object
            itin:     @type.object # an itin
            attraits: @type.array # must redraw based on attraits
            onNewValue: @type.func
            app:      @type.object
            selectedWP: @type.object


        constructor:(props)->
            super props

            #init done by map idle can be delayed unltil gmaps is available....
            @state=
                itin:   props.itin


        componentWillReceiveProps: (nextProps)->
            log "ItinMapView.componentWillReceiveProps change itin=#{nextProps.itin isnt @state.itin}" if @props.debug
            if nextProps.itin isnt @state.itin
                @setState itin:nextProps.itin

#         shouldComponentUpdate: (newProps,newState)->
#             log "ItinMapView.shouldComponentUpdate itin change #{newState.itin isnt @state.itin} selectedChange #{newProps.selectedWP isnt @props.selectedWP}" if @props.debug
#             (newState.itin isnt @state.itin) or (newProps.selectedWP isnt @props.selectedWP) or (newProps.attraits isnt @props.attraits)

        getItinWaypoints: -> @state.itin?.waypoints or []

        getItinPaths: ->     @state.itin?.paths or []

        # setLocalItin and setItin are done on drag, so fast they could be trottled
        setLocalItinWaypoint: (value,name,alatlng,cb)->
            self=@
            #log "ItinMapView.setLocalItinWaypoint ",{self,value,name,alatlng,cb}
            @setState  (state,props)->
                #log "ItinMapView.setLocalItinWaypoint -> setState ",{self,value,name,alatlng,cb,state,props}
                state.itin.updateWaypointP value,alatlng
                .then (itin)->
                    #log "ItinMapView.setLocalItinWaypoint -> setState.then ",{self,value,name,alatlng,cb,state,props,itin}
                    self.setState {itin},cb


        setItinWaypoint: (value,name,alatlng)->
            #log "setItinWaypoint",{self,value,name,alatlng}
            @setLocalItinWaypoint value,name,alatlng,(@doOnNewValue if @props.onNewValue)

        doRemoveItinWaypoint: (value,name)->
            #log "ItinMapView remove waypoint ",{self:@,wp:value} ##{typeof pos} #{pos}"
            @state.itin.removeWaypointP value
            .then (itin)=>
                @setState {itin},@doOnNewValue if @props.onNewValue

        doOnNewValue:->
            #log "ItinMapView doOnNewValue...",@state.itin
            @props.onNewValue? @state.itin,@props.name

        doClick:(wp,name)->
            #log "ItinMapView Marker click",{self:@,wp,name}
            @props.app.setItinSelectedWP wp


        render:(props=@props) ->
            

            self=@
            itin=@state.itin
            log "ItinMapView.render",{self,itin}  if @props.debug
            div {},
                for wp in @getItinWaypoints() when !wp.objid or !(props.app?.getAttraitById? wp.objid)
                    #if wp.objid
                        #log "ItinMapView.render got wpobj at #{wp.pos} id=#{wp.objid} hit=#{props.app?.getAttraitById? wp.objid}"
                    m=marker
                        key:  wp.uid  #"""itin-wpw-#{wp.id or "#{r6 wp.lat}-#{r6 wp.lng}"}"""
                        draggable: true
                        onDrag: @setLocalItinWaypoint if !itin.isSlow
                        onDragEnd: @setItinWaypoint
                        onClick: @doClick
                        visible: wp isnt props.selectedWP
                        onContextMenu: @doRemoveItinWaypoint
                            #(wp,pos)->
                            #log "on contect menu",{wp,pos}
                            #self.doRemoveItinWaypoint wp,pos
                        position:   wp
                        name:       wp.id
                        value:      wp
                        gmapMap: if API is 'gmaps'   then props.gmapMap
                        leafletMap: if API is 'leaflet' then props.leafletMap
                        label: if !wp.isEnd and wp.pos isnt 0 then wp.pos.toString() else ''
                        debug:false
                        icon: switch
                            when wp.pos is 0
                                 '/css/icon/ongletvert.png'
                            when wp.isEnd
                                 '/css/icon/ongletrouge.png'
                            else '/css/icon/ongletjaune.png'
                    #log "wp pos=#{wp.pos} isEnd=#{wp.isEnd} m=",m
                    m


                 for path in @getItinPaths()
                    #log 'return path=',path
                    polyline
                        #zIndex:10
                        key:  path.uid
                        path: path.pts
                        gmapMap: if API is 'gmaps'   then props.gmapMap
                        leafletMap: if API is 'leaflet' then props.leafletMap
                        #gmap polyline
                        #path.lineOps
                        color:   path.lineOps.color
                        opacity: path.lineOps.opacity
                        weight:  path.lineOps.weight
                        visible: true

#TODO add logs cause to manu of these ...

itinMapButton= (props={})->
    {itin,zoom,i18nInfo}= props
    #log "itinMapButton props=",props
    return null if not itin
    ret=button '#btn-carte-itin-info .mapcontrol $textTransform=none',
        #onClick:(->log "itin b click")
        #i18nInfo:i18nInfo
        href:'#itin'
        icon '|directions'
        say 'itinButton',
            wpcnt:itin.waypoints.length
            dist:itin.totalDist
    #log "itinMapButton ret=",ret
    ret


itinMapWpInfoPopup= (props)->
    { id, app,mapPopup,onClick }= props
    wp=app.get 'itinSelectedWP'
    return  '' if not app.state.itin or !wp
    { isStart, isEnd, noAssign, noAssignTitre, before, done, todo, total, nom, nom2, next }= app.state.itin.getDstInfoForPos wp.pos
    log " itinMapWpInfoPopup done=#{done}"
    mapPopup "##{id}mapWPinfo elevation=8",
        position: { top:(pos=app.get 'itinSelectedWPPos')[0],left:pos[1]}
        pos: pos
        active: !!wp
        app:app
        header2 'flat  compact',
            left: tbTitle [
                    say ""
                    " "
                    switch
                        when isStart then say "Début"
                        when isEnd   then say "Arrivé"
                        else [(say "Étape"),' ',wp.pos]
                    ]
            rightShrink: button 'flat  icon=cancel',onClick:->app.clearItinSelectedWP()
        div '$padding="4px 8px 8px 24px"',
            ul '.mdc-typography--body1 $paddingLeft=0px $marginTop=0px',[
                li key:1, (say "Prochaine étape", {dist:next})  if not isEnd
                li key:2, (say "Cumul parcouru",  {dist:done})  if done>0
                li key:3, (say "Distance arrivée",{dist:todo})  if not isEnd
                ]
            button '',
                title:say "titre bouton enlever itin"
                onClick: (e)=>
                    #log "click remove wp"
                    app.clearItinSelectedWP()
                    app.removeItinWaypoint wp
                    #onClick e if onClick
                say "bouton enlever itin"




itinStepListItem= meta class ItinStepListItem extends UIComponent
    @displayName: 'ItinStepListItem'

    @propTypes=
        wp:     @type.object
        path:   @type.object
        itin:   @type.object
        removeWP: @type.func


    render: (props=@props)-> # 2
        { app, itin, removeWP, wp }= props
        #log "ItinStepListItem.render got =", { app, itin, removeWP, wp }
        pos= wp.pos
        path= itin.getPath wp

        if wp.objid
            wpobj= app.getAttraitById wp.objid

        { isStart, isEnd, noAssign, noAssignTitre, before, done, todo, total, nom, nom2, next }= itin.getDstInfoForPos pos
        #log "ItinStepListItem.render got #{JSON.stringify { pathDst, totalDst, cumulDst, totalWPs, pos }}"
        div '.itin-step-list-item .mdc-typography--body1 $marginTop=4px',_isEnd:isEnd,
            details '',
                summary '',
                    if removeWP
                        valueButton '$float=right $margin=4px icon=delete_forever flat .itin-step-erase-btn--id',onNewValue:removeWP,value:pos
                            #icon '|delete_forever'
                            #span '.text',say "Enlever cette étape"
                    h5 '',
                        switch
                            when wpobj then [
                                vxIcon 'key:icon',
                                    onClick: app.doToggleClickedAttraitId
                                    'data-id':    wpobj.id
                                    'data-objid': wpobj.id
                                    'data-region':wpobj.membre if wpobj.membre
                                    'data-nom':   wpobj.title
                                    'aria-label': wpobj.title
                                    wpobj.sourceSousCat
                                wpobj.title
                                ]
                            when isStart then say "Départ"
                            when isEnd   then say "Arrivée"
                            else              (say "Étape")+" #{pos}"

                    (layout '',
                        cell 'span=1 phone=0 | '
                        cell 'span=11 tablet=7 ',(say "Prochaine étape",{dist:next})
                        ) if !isEnd

                    (layout '',
                            cell 'span=1 phone=0 | '
                            cell 'span=11 tablet=7 ',(say "Cumul parcouru",{dist:done})
                        ) if done>0

                    (layout '',
                        cell 'span=1 phone=0 | '
                        cell 'span=11 tablet=7',(say "Distance arrivée",{dist:todo})
                        ) if !isEnd


                if !isEnd
                    if !path.dirHTML
                        div '',
                            layout '',
                                cell 'span=1 phone=0 | '
                                cell 'span=1 phone=1',em '',(span '.spacer',''),say "à (km)"
                                cell 'span=1 phone=1',em '',say "prendre"
                                cell 'span=1 phone=1',em '',say "pour (km)"
                                cell 'span=1 phone=1',em '',say "cumul (km)"

                            (
                                cumul2=0;
                                for sect,idx in path.dir
                                    layout '.line',key:idx,
                                        cell 'span=1 phone=0 | '
                                        cell 'span=1 phone=1',span '',(span '.spacer',"#{idx+1}"), "#{(say 'asDist',done+cumul2).toFixed 1}"
                                        cell 'span=1 phone=1',say 'nomPiste',sect
                                        cell 'span=1 phone=1',"#{(say 'asDist',sect.dst).toFixed 1}"
                                        cell 'span=1 phone=1',"#{(say 'asDist',cumul2+=sect.dst).toFixed 1}"
                            )
                    else
                        div dangerouslySetInnerHTML:__html:path.dirHTML




infoAttraitItin= ({attrait,app,onClick}={})->
        if not(wp=app.state.itin?.attraitsIds[attrait.id])
            itinButtons {wp:attrait,app,onClick}
        else
            { isStart, isEnd, noAssign, noAssignTitre, before, done, todo, total, nom, nom2, next }= app.state.itin.getDstInfoForPos wp.pos
            [
                hr key:'1'
                h5 '.itinButtons.title key:2',
                    say "Titre Itinéraire"
                    " "
                    switch
                        when isStart then say "Début"
                        when isEnd   then say "Arrivé"
                        else [(say "Étape"),' ',wp.pos]
                ul '.mdc-typography--body1 key:list  $margin="4px 0"',[
                    li key:1, (say "Prochaine étape", {dist:next})  if not isEnd
                    li key:2, (say "Cumul parcouru",  {dist:done})  if done>0
                    li key:3, (say "Distance arrivée",{dist:todo})  if not isEnd
                    ]

                button 'key:but',
                    title:say "titre bouton enlever itin"
                    onClick: (e)=>
                        app.removeItinWaypoint attrait
                        onClick e if onClick
                    (icon '|delete_forever'),say "bouton enlever itin"
            ]


itinButtons=  ({app,wp,onClick})->
        if true or app.hasItin() then [
            hr 'key:1'
            h5 'key:2 .itinButtons--title .mdc-typography--caption',say "Insérer dans l’itinéraire:"
            group 'key:3 horizontal',onClick:onClick,
                button '#itin-btns-debut raised $marginRight=4px $marginBottom=4px -itinbtn=Début',
                    title: say "Insérer ce point au début de l’itinéraire"
                    onClick: (e)=>
                        app.addItinWaypoint wp,0
                        onClick e if onClick
                        #app.set false,'bkgMenuVisible'
                    say "Début"

                button '#itin-btns-etape raised $marginRight=4px $marginBottom=4px -itinbtn=Étape',
                    title: say "Insérer ce point entre 2 marqueurs pour que la distance soit la plus courte"
                    onClick: (e)=>
                        app.addItinWaypoint wp,'?'
                        onClick e if onClick
                        #app.set false,'bkgMenuVisible'
                    say "Étape"

                button '#itin-btns-fin raised $marginRight=4px $marginBottom=4px -itinbtn=Fin',
                    title: say "Insérer ce point à la fin de l’itinéraire"
                    onClick: (e)=>
                        app.addItinWaypoint wp,-1
                        onClick e if onClick
                        #app.set false,'bkgMenuVisible'
                    say "Fin"
                ]


itinListContent= ({app,itin,i18nInfo})->
        itinWaypoints= app.getItinWaypoints()
        content '.page__content-margin',
             switch itinWaypoints.length
                when 0,undefined,null
                    div key:'emptycontent',name:'emptycontent',say 'mess no wp'
                else
                    div key:'content',name:'content',
                        (for wp,idx in app.getItinWaypoints()
                            #log "Creating itin itme for wp #{idx}"
                            [
                                itinStepListItem
                                    key:    wp.uid
                                    wp:     wp
                                    itin:   itin
                                    app:    app
                                    i18nInfo: i18nInfo
                                    removeWP: app.doRemoveItinWaypoint
                                hr key:"hr-#{idx}"
                            ]
                        ).flat 1

rbIcon= (checked)->  
    #log "doing rbIcon #{checked} span=",span
    span '.material-icons .what $paddingRight=10px',if checked then 'radio_button_checked' else 'radio_button_unchecked'




itinXMenu= meta class ItinXMenu extends UIComponent
    @displayName: 'ItinXMenu'

    constructor: ->
        super()
        @state=
            showItinMenu: false

    render: (props=@props,state=@state)->
        {app,i18nInfo={},mode='normal',itin,beta}= props

        hasNavigate= itin and itin.waypoints?.length and itin.router.canNavigate

        return null if !itin

        mn= menu { onClick:@doMenuClick,open:@state.showItinMenu,onClose:@doMenuClose},
                (listItemText '.itinpage-menu-effacer data-action=clearItin   name=clearItin   value=clearItin   data-name=clearItin   data-value=clearItin',  ( say 'clear itin')) if itin.waypoints?.length
                (listItemText '#itinpage-menu-reverse data-action=reverseItin name=reverseItin value=reverseItin data-name=reverseItin data-value=reverseItin',( say 'reverse itin')) if beta and itin.waypoints?.length>1
                listItemDivider '.waht-v1' if itin.waypoints?.length
                listItemText '.itinpage-menu-km .waht-v1 data-action=doSetKm name=distunit" value=km data-name=distunit" data-value=km',
                    rbIcon i18nInfo.distUnit=='km'
                    say 'kilometres'
                listItemText '.itinpage-menu-mile data-action=doSetMi name=distunit" value=mi data-name=distunit" data-value=mi',
                    rbIcon i18nInfo.distUnit=='mi'
                    say 'miles'
                listItemDivider() if hasNavigate 
                ((listItemText '.itin-menu-export  data-action=doStartItinNavigate',(say"navaigation google")) if  hasNavigate  )
                listItemDivider() if vx.mapMode.gpxItin
                ((listItemText '.itin-menu-export  data-action=exportItinGPX',(say 'export as GPX')) if vx.mapMode.gpxItin  )

        switch mode
            when 'onmap'
                menuSurfaceAnchor '.itin-menu .itin-menu--onmap',
                    button '#btn-carte-itin-info .mapcontrol ',onClick:@doToggleItinMenu,icon '|more_vert'
                    mn
            else #normal
                menuSurfaceAnchor '.itin-menu .itin-menu--normal',
                    button onClick:@doToggleItinMenu,icon '|more_vert'
                    mn

                # (listItem 'data-action=reverseItin','reverse'),

    doToggleItinMenu: (e)->
        #log "toogle itin menu"
        @setState showItinMenu:!@state.showItinMenu
        
    doMenuClose: (e)->
         @setState showItinMenu:false
        

    doMenuClick: (e)->
        { target, type }= e
        #log "doMenuClick target=",{target}
        app= @props.app
        @setState showItinMenu:false
            
        actionSelector= "[data-#{type}-action],[data-action]"
        
        if target.matches actionSelector
            actionTarget= target
        else
            if target.tagName is 'LI'
                actionTarget= $ actionSelector,target
            if !actionTarget
                actionTarget= target.closest("[data-#{type}-action],[data-action]")
                
        if actionTarget
            actionTargetDS= actionTarget.dataset
            actionName= actionTargetDS["#{type}Action"] or actionTargetDS.action
            if isPrefix actionName,'--'
                # use var Name lets use set action through css rules
                actionName= getPropertyText actionTarget,actionName

        log "got action Name=#{actionName}"
        if actionName and app and typeof app[actionName] is 'function'
            log "doing app.#{actionName}"
            app[actionName]()
        else
            log "action #{actionName} fail actionName #{!!actionName} and app #{!!app} and typeof app[actionName] is 'function' #{app and typeof app[actionName] is 'function'}"





itinPage= meta class ItinPage extends UIComponent
    @displayName: 'ItinPage'


    render: (props=@props)->

        { id,useMenu, menuBtn, itin,title, textRetour, app, active,i18nInfo={},option_beta }= props
        #log "render itinPage @state.showItinMenu=#{@state.showItinMenu} props=",props
        beta= option_beta
        page "##{id} key:#{id}",{active},
            header2 '',
                left: [
                    if useMenu then menuBtn {key:'menu'} else ''
                    tbTitle {key:'title'},title
                    if vx.mapMode.itinMenu then tbTitle {key:'dotmenu',className:'was-menu'},itinXMenu {app,i18nInfo,itin,beta} else ''
                    ]
                    
                right: tbTitle null,button '#itinpage-btn-carte href=#map',(span '.vx-hide-phone',textRetour),(icon '|arrow_forward')

            itinListContent {app,itin,i18nInfo}


    doToggleItinMenu: (e)->
        #log "toogle itin menu"
        @setState showItinMenu:!@state.showItinMenu

    doMenuClick: (e)->
        { target, type }= e
        #log "doMenuClick target=",{target}
        app= @props.app
        @setState showItinMenu:false

        if actionTarget= target.closest("[data-#{type}-action],[data-action]")
            actionTargetDS= actionTarget.dataset
            actionName= actionTargetDS["#{type}Action"] or actionTargetDS.action
            if isPrefix actionName,'--'
                # use var Name lets use set action through css rules
                actionName= getPropertyText actionTarget,actionName

        log "got action Name=#{actionName}"
        if actionName and app and typeof app[actionName] is 'function'
            log "doing app.#{actionName}"
            app[actionName]()
        else
            log "action #{actionName} fail actionName #{!!actionName} and app #{!!app} and typeof app[actionName] is 'function' #{app and typeof app[actionName] is 'function'}"



module.exports= { infoAttraitItin, itinButtons, itinMapButton, itinMapViewFactory, itinListContent, itinMapWpInfoPopup, itinPage, itinXMenu }

