{ log, meta, oSplit, oAssign, oDiffs, asJSON, Global }= vx= require 'vx/globals/Boot'
ModuleName= 'GMapWidgets'


require 'vx/map/gmaps/GMapWidgets.css'

require 'vx/tools/NamedPromises'

{ latM, latMInv }= require 'vx/math/MapGeometry'
{ Bounds }=        require 'vx/math/Bounds'
{ QuadNode }=      require 'vx/math/QuadNode'

{ ready:GMapsReady, gmaps, asGLatLng, asGLatLngArr, asGLatLngBounds, CustomGMapMarker, CustomGMapZone, DynamicTileOverlay  }= require 'vx/map/gmaps/GMapExtensions'
log "!!! required GMapExtensions gmaps is #{!!gmaps}"
Promise.vxGet('GMapExtensionsReady').then (module)->
    { ready:GMapsReady, gmaps, asGLatLng, asGLatLngArr, asGLatLngBounds, CustomGMapMarker, CustomGMapZone, DynamicTileOverlay }=module
    log "!!! Got a Promise GMapExtensionsReady ModuleName=#{ModuleName} gmaps is #{!!gmaps}"
    Promise.vxGet("#{ModuleName}Ready").vxResolve true

{ mapPopup }=         require 'vx/map/MapPopup'
{ positionContent } = require 'vx/map/positionContent'

{ UIComponent, div }= UI= require 'vx/UI'
{ input:htmlInput, svg, g, polyline:svgPolyline }= UI 

{ cloneElement, Children }= require 'react'
{ map:childrenMap, toArray:childrenToArray }= Children

{ render, findDOMNode, unmountComponentAtNode }= require 'react-dom'

{ sayModule }= require 'vx/i18n/I18n'
say= sayModule 'GMapWidgets' # cause webp clobbers ModuleName

llEqual= (a,b)->
    return true if a is b
    return false if !a or !b
    asGLatLng(a).equals asGLatLng b

    #if a.equals
    #    a.equals b
    #else if b.equals
    #    b.equals a
    #else a.lat is b.lat and a.lng is b.lng

bndsEqual= (a,b)->
    return true if a is b
    return false if !a or !b
    asGLatLngBounds(a).equals asGLatLngBounds b


mapControl= meta class MapControl extends UIComponent
    @displayName: 'MapControl'

    @propTypes =
        id:         @type.string
        position:   @type.string
        enabled:    @type.bool
        visible:    @type.bool
        index:      @type.number
        gmapMap:    @type.object


    render: -> return null # @props.children

    componentDidMount: ->
        #log "!!! MapControl.componentDidMount   Dynammic Component did mount"
        @initGMap()

    initGMap: ()->
        #log "!!! MapControl.componentDidMount   Dynammic Component did mount"
        { gmapMap }= @props
        #log "#{@name}.initGMap props=",@props

        { position, index, map }= @props
        position?= 'LEFT_TOP'

        #return if not map

        if not (gmaps and gmapMap) #should throw an error
            log "Missing gmaps or gmapMap",{gmaps,gmapMap}
            return

        @node= node= document.createElement 'div'
        @renderToNode()

        #log "Dynammic Control init ",{node,position,gmapMap,gmaps}

        if index?
            gmapMap.controls[gmaps.ControlPosition[position]].insertAt index,node
        else
            gmapMap.controls[gmaps.ControlPosition[position]].push node




    componentDidUpdate: (oldProps,oldState)-> # could be willUpdate
        #log "Dynammic Control componentDidUpdate ",{diffs:(oDiffs oldProps,@props),cDiffs:(oDiffs oldProps.children,@props.children),@props,@state,oldProps,oldState}
        @renderToNode()
        #@patchResizeMap()

    patchResizeMap: ->
        # do this to force reposioning of controls
        return if !@props.gmapMap or !gmaps
        #log "patchResizeMap"
        #gmaps.event.trigger @props.gmapMap,'resize'


    renderToNode:->
        #log "!!! MapControl.renderToNode  "
        childs= childrenToArray @props.children
        switch childs.length
            when 0
                return
            when 1
                render childs[0],@node
            else #>1
                render (div '',childs),@node


    componentWillUnmount: ->
        unmountComponentAtNode @node if @node




marker= meta class Marker extends UIComponent
        @displayName: 'Marker'

        @propTypes =
            id:         @type.string
            position:   @type.oneOfType [@type.object,@type.array]
            pos:        @type.array
            onNewPostion: @type.func
            clickable:  @type.bool
            visible:    @type.bool
            draggable:  @type.bool
            onDrag:     @type.func
            onDragend:   @type.func
            onClick:    @type.func
            onContextMenu: @type.func
            gmapMap:    @type.object
            name:       @type.oneOfType [@type.string,@type.number]
            value:      @type.object
            icon:       @type.string
            label:      @type.string
            title:      @type.string
            labelPosition: @type.object

        marker: null # the gmap marker

        render: ->
            # update the marker
            return null

        componentDidMount:->
            { gmapMap, position, draggable, label, title, icon, visible }= @props

            @marker= new gmaps.Marker
                map: gmapMap
                position:asGLatLng position
                draggable: draggable
                title:  title
                visible: visible ? true
            if icon
                @marker.setIcon
                    url:icon
                    labelOrigin: {x:10,y:12}
            if label
                @marker.setLabel
                    text: label

            #log "GMapsWidget:Marker create ops=",{label,title,icon,@marker}
            { onDrag, onDragEnd, onClick, onContextMenu, name, value }= @props

            if onDrag
                @marker.addListener 'drag',(e)=>
                    onDrag value,name,[e.latLng.lat(),e.latLng.lng()]
            if onDragEnd
                @marker.addListener 'dragend',(e)=>
                    alatlng= [e.latLng.lat(),e.latLng.lng()]
                    #log "dragend on #{typeof name} #{name} alatlng=#{alatlng}"
                    onDragEnd value,name,alatlng
            if onClick
                @marker.addListener 'click',(e)->
                    #log "click"
                    onClick value,name

            if onContextMenu
                @marker.addListener 'rightclick',(e)->
                    #log "rightclick",{self:@,value,name,onContextMenu}
                    onContextMenu value,name


        componentDidUpdate: ( prevProps,prevState) ->

            for key,{a,b} of oDiffs prevProps,@props
                #log "GMapsWidget:Marker componentDidUpdate #{key} changed from #{a} to #{b}"
                switch key
                    when 'position'
                        @marker.setPosition asGLatLng b
                    when 'icon'
                        @marker.setIcon
                            url:b
                            labelOrigin: {x:10,y:12}
                    when 'label'
                        @marker.setLabel (if b then {text:b} else '')
                    when 'title'
                        @marker.setTitle b or ''
                    when 'visible'
                        @marker.setVisible b
                    #else null # nop

        componentWillUnmount: -> @marker.setMap null



class NativePolyline extends UIComponent
    @displayName: 'NativePolyline'

    @propTypes =
        id:         @type.string


    polyline:null # the gmap polyline

    render: ->
        # update the marker
        null

    componentDidMount:->
        { gmapMap, path, color='#0000FF', opacity=1.0, weight=2, visible=true }= @props

        pathlatlng= asGLatLngArr path


        #log "create polyline with pts=",{pathlatlng,color,opacity,weight,visible}
        @polyline= new gmaps.Polyline
            map:gmapMap
            path:pathlatlng
            strokeColor:color
            strokeOpacity:opacity
            strokeWeight:weight
            visible:visible

    componentDidUpdate: ( prevProps,prevState) ->
        if diffs= oDiffs @props,prevProps
            #log "polyline did update wirh diffs=",diffs
            for name,{a,b} of diffs
                switch name
                    when 'path'
                        #log "poly path change =",{a,b,eq:a is b}
                        @polyline.setPath asGLatLngArr a
                    when 'visible'
                        @polyline.setVisible a
                    #else
                        #log "#{@_displayname} componentDidUpdate ignore change for #{name}",{a,b}
                        #null
        null


        #todo
    componentWillUnmount: -> @polyline.setMap null


polyline= meta class Polyline extends NativePolyline
    @displayName: 'Polyline'



tileLayer= meta class TileLayer extends UIComponent
    @displayName: 'TileLayer'

    constructor: (props)->
        super props
        @state= onMap:false
        return @


    @propTypes =
        id:         @type.string
        getTileUrl: @type.func
        tileURL:    @type.string
        opacity:    @type.number
        minZoom:    @type.number
        maxZoom:    @type.number
        name:       @type.string
        alt:        @type.string
        tileSize:   @type.number
        visible:    @type.bool
        gmapMap:    @type.object
        info:       @type.object # info passed to drawer
        qs:         @type.string #querystring for cache busting
        debug:      @type.bool
        zIndex:     @type.number

    layer:null # the gmap polyline

    basicVXTileUrlFn: ({x,y},zoom)->
        xy= ( if x<10 then x else Math.floor( x/10 % 10)).toString()+(if y<10 then y else Math.floor(y/10 % 10)).toString()
        "#{@props.tileURL}/z#{17-zoom}/xy#{xy}/map-#{17-zoom}-#{x}-#{y}.png"

    basicLeafletTileUrlFn: ({x,y},zoom)->
        url= "#{@props.tileURL}/#{zoom}/#{x}/#{y}.png#{@props.qs or ''}"
        #log "gmaps get tileurl for ",{x,y,zoom,url,tileURL:@props.tileURL,self:@}
        url

    basicVXCondTileUrlFn: ({x,y},zoom)-> "#{@props.tileURL}?type=png&x=#{x}&y=#{y}&zoom=#{17-zoom}#{@props.qs or ''}"

    basicTileUrlFn: @::basicLeafletTileUrlFn

    render: -> null

    reloadLayer: ->
        { gmapMap, visible }= @props

        layer= @layer

        if !layer or !gmapMap or !visible
            log "TileLayer.reloadLayer fail gmapMap=#{!!gmapMap} visible=#{!!visible} layer=#{!!layer}"
            return
        pos= gmapMap.overlayMapTypes.getArray().indexOf layer
        if pos <0
            log " TileLayer.reloadLayer layer not found"
            return
        #log " TileLayer.reloadLayer reloading pos=#{pos}"
        gmapMap.overlayMapTypes.removeAt pos
        gmapMap.overlayMapTypes.insertAt pos,layer
        #setTimeout (-> gmapMap.overlayMapTypes.insertAt pos,layer),10



    componentDidUpdate:(oldProps)->
        #log "TileLayer.componentDidUpdate ",{oldProps,@props}

        if @layer and @props.visible isnt @state.onMap
            #Ok visibility miss match
            if @props.visible
                if @props.zIndex?
                    #inser based on zIndex
                    for l,i in @props.gmapMap.overlayMapTypes.getArray() when l.vxzIndex? and l.vxzIndex > @props.zIndex
                        break
                    #log "TileLayer.componentDidUpdate @props.zIndex? true =#{@props.zIndex} i=#{i}, len=#{@props.gmapMap.overlayMapTypes.getArray().length} lzI=#{l?.vxzIndex}",{l,layers:@props.gmapMap.overlayMapTypes.getArray()}
                    @props.gmapMap.overlayMapTypes.insertAt i,@layer
                else #no zIndex dont care insert at end
                    @props.gmapMap.overlayMapTypes.push @layer
                @setState onMap:true # could avoid it but ...
            else
                pos=  @props.gmapMap.overlayMapTypes.getArray().indexOf @layer
                if pos>=0
                    @props.gmapMap.overlayMapTypes.removeAt pos
                    @setState onMap:false # could avoid it but ...

        else
            #dont check when visibility change
            if oldProps.qs isnt @props.qs
                #log "RELOAD LAYER qs changed from #{oldProps.qs} to #{@props.qs}"
                @reloadLayer()

        null


    componentDidMount:->
        @makeLayer()
        #log " MMMMMMMMM #{@props.name} created layer=",@layer if @props.debug

        if @props.visible
            if @props.zIndex?
                #inser based on zIndex
                for l,i in @props.gmapMap.overlayMapTypes.getArray() when l.vxzIndex? and l.vxzIndex > @props.zIndex
                    break
                #log "TileLayer.componentDidMount @props.zIndex? true =#{@props.zIndex} i=#{i},  len=#{@props.gmapMap.overlayMapTypes.getArray().length}, lzI=#{l?.vxzIndex}",{l,layers:@props.gmapMap.overlayMapTypes.getArray()}
                @props.gmapMap.overlayMapTypes.insertAt i,@layer
            else #no zIndex dont care insert at end
                @props.gmapMap.overlayMapTypes.push @layer
            @setState onMap:true # could avoid it but ...
        null


    makeLayer: ->
        { gmapMap, urlFn, opacity=1.0, minZoom=0, maxZoom=18, getTileUrl,tileSize=256,name,alt,debug }= @props
        if getTileUrl
             getTileUrl=getTileUrl.bind @
        else getTileUrl=@basicTileUrlFn.bind @

        @layer= new gmaps.ImageMapType { getTileUrl, minZoom, maxZoom, opacity, name, alt, tileSize:new gmaps.Size tileSize,tileSize,debug }
        @layer.vxzIndex= @props.zIndex
        @layer


    componentWillUnmount: ->

        if @state.onMap
            pos=  @props.gmapMap.overlayMapTypes.getArray().indexOf @layer
            if pos>=0
                @props.gmapMap.overlayMapTypes.removeAt pos

        null


###
    Document   : wms.js
    Created on : Feb 16, 2011, 3:25:27 PM
    Author     : "Gavin Jackson <Gavin.Jackson@csiro.au>"

    Refactored code from http://lyceum.massgis.state.ma.us/wiki/doku.php?id=googlemapsv3:home
###

#google= false # TODO

bound= (value, opt_min, opt_max)->
    value= Math.max value,opt_min if opt_min?
    value= Math.min value,opt_max if opt_max?
    value

degreesToRadians= (deg) -> deg * (Math.PI / 180)

radiansToDegrees= (rad) -> rad / (Math.PI / 180)



class MercatorProjection

    MERCATOR_RANGE = 256

    pixelOrigin_:        x:MERCATOR_RANGE/2,y:MERCATOR_RANGE/2
    pixelsPerLonDegree_: MERCATOR_RANGE / 360
    pixelsPerLonRadian_: MERCATOR_RANGE / (2 * Math.PI)


    fromLatLngToPoint: (latLng, opt_point)->

        point = opt_point or new Global.google.maps.Point 0,0

        origin = @pixelOrigin_

        point.x = origin.x + latLng.lng() * @pixelsPerLonDegree_

        # NOTE(appleton): Truncating to 0.9999 effectively limits latitude to
        # 89.189.  This is about a third of a tile past the edge of the world tile.
        siny = bound ( Math.sin degreesToRadians latLng.lat()), -0.9999, 0.9999
        point.y = origin.y + 0.5 * Math.log((1 + siny) / (1 - siny)) * -@pixelsPerLonRadian_

        point


    fromDivPixelToLatLng: (pixel,zoom)->

        origin= @pixelOrigin_

        scale= Math.pow 2,zoom

        lng=         ( pixel.x / scale  - origin.x) /  @pixelsPerLonDegree_
        latRadians = ( pixel.y / scale  - origin.y) / -@pixelsPerLonRadian_

        lat= radiansToDegrees( 2*Math.atan(Math.exp latRadians) - Math.PI/2 )

        asGLatLng { lat,lng }


    fromDivPixelToSphericalMercator: (pixel,zoom)->

        coord= @fromDivPixelToLatLng pixel,zoom
        r= 6378137.0
        x= r*degreesToRadians coord.lng()
        latRad= degreesToRadians coord.lat()
        y = (r/2) * Math.log((1+Math.sin(latRad))/ (1-Math.sin(latRad)))

        {x,y}




wmsTileLayer= meta class WMSTileLayer extends TileLayer
    @displayName: 'WMSTileLayer'

    @propTypes:
        wmsOps:         @type.array

    basicTileUrlFn: ({x,y},zoom)->

        lULP= { x: x   *256, y:(y+1)*256 }
        lLRP= { x:(x+1)*256, y: y   *256 }

        projectionMap = new MercatorProjection()

        lULg= projectionMap.fromDivPixelToSphericalMercator lULP,zoom
        lLRg= projectionMap.fromDivPixelToSphericalMercator lLRP,zoom

        lUL_Latitude=  lULg.y
        lUL_Longitude= lULg.x
        lLR_Latitude=  lLRg.y
        lLR_Longitude= lLRg.x

        #GJ: there is a bug when crossing the -180 longitude border (tile does not render) - this check seems to fix it
        if  lLR_Longitude < lUL_Longitude
            lLR_Longitude= Math.abs lLR_Longitude

        url="#{@props.tileURL}#{@_wmsStr}&bbox=#{lUL_Longitude},#{lUL_Latitude},#{lLR_Longitude},#{lLR_Latitude}"
        if @props.debug
            log "WMSTileLayer.basicTileUrlFn x=#{x},y=#{y},zoom=#{zoom} -> #{url}"
        url


    updateWMSStr: (props=@props)->
        wmsOps=
            REQUEST: 'GetMap'
            SERVICE: 'WMS'
            VERSION: '1.3',
            BGCOLOR: '0xFFFFFF'
            TRANSPARENT: 'TRUE'
            CRS:     'EPSG:900913' # ? 3395?
            WIDTH:   '512'
            HEIGHT:  '512'

        for k,v of props.wmsOps or {}
            wmsOps[k.toUpperCase()]=v

        @_wmsStr= ("#{k}=#{wmsOps[k]}" for k in Object.keys(wmsOps).sort()).join '&'

    componentDidUpdate: (prevProps, prevState)->
        oldWMSStr= @_wmsStr
        @updateWMSStr()
        log "GMAPS WMS layer did update got onMap=#{@state.onMap} same#{oldWMSStr isnt  @_wmsStr} wms=\n before=#{oldWMSStr}\nafter= #{@_wmsStr} "
        if @state.onMap and oldWMSStr isnt  @_wmsStr
            @layer.refresh()
        super prevProps,prevState

    componentDidMount:->
        @updateWMSStr()
        super()




dynamicTileLayer= meta class DynamicTileLayer extends TileLayer
    @displayName: 'DynamicTileLayer'

    @propTypes =
        content:    @type.object
        latmContent:@type.bool
        renderTile: @type.func
        cacheSize:  @type.number
        marginFixe: @type.number #content access margins
        marginPct:  @type.number


    makeLayer: ->

        { tileClass, opacity=1.0, minZoom=0, maxZoom=18,tileSize=256,name,alt,content, latmContent, renderTile=@renderTile, cacheSize=0, marginFixe, marginPct, debug, info, id }= @props
        #log " !!!!!!!!!!!!!!!!!!!!!!!! Dynamic tile layer #{@props.id}  got drawTile=",{renderTile,props:@props} if @props.debug

        if content and !(content instanceof QuadNode)
            content= @asQuadNode content

        @layer= new DynamicTileOverlay { minZoom, maxZoom, opacity, name, alt, content, latmContent, renderTile, tileSize, cacheSize, marginFixe, marginPct, debug, info, id }
        @layer.vxzIndex= @props.zIndex
        #@_didUpdateProp_content content # set content with a fake content change this allows filtering in one place
        @layer

    componentDidUpdate:(prevProps,prevState)->
        super prevProps,prevState
        @_didUpdateProps (oDiffs prevProps,@props),prevProps
        null

    componentWillUnmount:->
        log "unmounting tile overlay #{@name}"

    _didUpdateProps: (diffs,oldProps)->
        ignores= @_didUpdateProps_ignores
        for name,{a,b} of diffs when !ignores or name not of ignores
            if @["_didUpdateProp_#{name}"]
                 @["_didUpdateProp_#{name}"] b,a,diffs,oldProps
            else
                #log "DynamicTileLayer._didUpdateProps unexpected update for #{name} from #{a} to #{b}",{name,a,b}
                null

    asQuadNode: (content)-> #Should be redifened as required
        log "DynamicTileLayer.asQuadNode got content=",content if @props.debug
        content

    _didUpdateProps_ignores:
        visible: 1 #managed in super
        gmapMap: 1 #managed in super


    _didUpdateProp_content: (newContent)->
        log "DynamicTileLayer._didUpdateProp_content ",newContent if @props.debug
        if newContent and !(newContent instanceof QuadNode)
            newContent= @asQuadNode newContent
        @layer.content= newContent
        @layer.refresh()

    _didUpdateProp_info: (newInfo,oldInfo,diffs)->
        log "DynamicTileLayer._didUpdateProp_info will refresh #{'content' not of diffs}",newInfo if @props.debug
        @layer.info= newInfo
        @layer.refresh() if 'content' not of diffs


mapHTML= meta class MapHTML extends UIComponent
    @displayName: 'MapHTML'

    render: -> null


    componentDidMount:->
        { top,left,right,bottom, gmapMap, className='',zIndex, visible=true,dontPositionCont }= @props
        bounds= asGLatLngBounds [ bottom,left,top,right ] # swne

        @options=
            latMOffset: lMO= latM top
            lngOffset:  left
            lngScale:   100/(right-left)
            latMScale: -100/(lMO - latM bottom)
            dontPositionCont: dontPositionCont


        @node= node= document.createElement 'div'
        node.className= "map-html #{className}"
        node.style.display= if visible then 'block' else 'none'
        if zIndex?
            node.style.zIndex= zIndex
        self= @
        #node.addEventListener 'click',(e)->
            #log "MAPHTML native click  #{self.props.id} self=#{e.target is node} contains=#{node.contains e.target}",e
            #null
            #if (node.contains e.target) and e.target isnt node
            #    e.stopPropagation()
            #    e.preventDefault()
        #node.addEventListener 'click',((e)->
            #log "MAPHTML native capture click #{self.props.id} self=#{e.target is node} contains=#{node.contains e.target}",e
            #null
            #),true
        @renderToNode()
        @zone= new CustomGMapZone { map:gmapMap, bounds:bounds },@node
        @zone.setMap gmapMap


    renderToNode:(props=@props)->
        childs= if @options.dontPositionCont then props.children else @_positionContent  props.children,@options
        #log "MapHTML.renderToNode"
        #log " --------- MapHTML2 rendeToNode Childs=",{childs,ops:@options}
        # OUPS childs could disapear return if !childs or !childs.length
        render (div '.vx-react-root',onClickCapture:@doClearMapClick,onClick:@doClick,childs),@node #onClick:@doClick,


    doClearMapClick: (e)->
        #log "MAPHTML react click capture #{@props.id} e=",e
        #log "Do clear map click props=",@props
        if @props.gmapMap._vx_clicktimer
            #log "MAPHTML clear timer"
            clearTimeout @props.gmapMap._vx_clicktimer
            @props.gmapMap._vx_clicktimer= null
        else # ok lets setup a block
            if @_vx_clicktimer
                clearTimeout @_vx_clicktimer
            @props.gmapMap._vx_blockclick= true
            #log "set click block"
            @_vx_clicktimer= setTimeout (@doClearBlock),200
        null

    doClearBlock:->
        @_vx_clicktimer= null
        #log "reset click block"
        @props.gmapMap._vx_blockclick= false

#    doClick: (e)->
#        log "MAPHTML react click  e=",e
#
#     doClickCapture: (e)->
#         log "MAPHTML react click capture e=",e
#

    componentDidUpdate:(prevProps,prevState)->
        for key,{a,b} of oDiffs prevProps,@props
            switch key
                when 'top','left','bottom','right'
                    viewChanged= true
                when 'className'
                    @node.setAttribute 'class',"map-html #{b or ''}"
                when 'zIndex'
                    @node.style.zIndex= b
                when 'visible'
                    @node.style.display= if b then 'block' else 'none'
        @renderToNode()

    componentWillUnmount: ->
        #log "maphtml control #{@props.id} was node #{!!@node} "
        unmountComponentAtNode @node if @node

    _positionContent: positionContent



mapSVG= meta class MapSVG extends UIComponent
    @displayName: 'MapSVG'


    render: -> null


    componentDidMount:->
        { topl,top=(latM topl), left,right, bottoml,bottom=(latM bottoml), scale=1,gmapMap, className='',id, zIndex, visible= true }= @props

        #log " mapsvg componentDidMount got top=#{top} bottom=#{bottom}", { top,left,right,bottom, gmapMap, className,id, zIndex }
        bounds= asGLatLngBounds [ (latMInv bottom),left,(latMInv top),right ] # swne

        w= scale*left
        e= scale*right
        n= scale*top
        s= scale*bottom

        @node= node= document.createElementNS "http://www.w3.org/2000/svg", "svg"
        node.setAttribute 'class',"map-svg #{className or ''}"
        node.id= id if id?
        node.style.display= if visible then 'block' else 'none'
        node.style.zIndex= zIndex ? 10000*top
        #node.style.position='absolute'
        #log " mapsvg componentDidMount n=#{n} s=#{s} n-s=#{n-s}"
        node.setAttribute 'viewBox',"#{w} #{-n} #{e-w} #{n-s}"
        node.setAttribute 'preserveAspectRatio','none'


        self= @
        #node.addEventListener 'click',(e)->
            #log "MAPHTML native click  #{self.props.id} self=#{e.target is node} contains=#{node.contains e.target}",e
            #null
            #if (node.contains e.target) and e.target isnt node
            #    e.stopPropagation()
            #    e.preventDefault()
        #node.addEventListener 'click',((e)->
            #log "MAPHTML native capture click #{self.props.id} self=#{e.target is node} contains=#{node.contains e.target}",e
            #null
            #),true
        @renderToNode()
        @zone= new CustomGMapZone { map:gmapMap, bounds:bounds },@node
        @zone.setMap gmapMap


    renderToNode:(props=@props)->
        childs= props.children
        #log "MapSVG.renderToNode"
        #log " --------- MapSVG rendeToNode Childs=",{childs,ops:@options}
        # OUPS childs could disapear return if !childs or !childs.length
        render ( g 'transform=scale(1,-1)', onClickCapture:@doClearMapClick, onClick:@doClick, childs ), @node #onClick:@doClick, TODO: WHY


    doClearMapClick: (e)->
        #log "MAPHTML react click capture #{@props.id} e=",e
        #log "Do clear map click props=",@props
        if @props.gmapMap._vx_clicktimer
            log "MAPSVG clear timer"
            clearTimeout @props.gmapMap._vx_clicktimer
            @props.gmapMap._vx_clicktimer= null
        else # ok lets setup a block
            if @_vx_clicktimer
                clearTimeout @_vx_clicktimer
            @props.gmapMap._vx_blockclick= true
            log "MAPSVG set click block"
            @_vx_clicktimer= setTimeout (@doClearBlock),200
        null

    doClearBlock:->
        @_vx_clicktimer= null
        #log "reset click block"
        @props.gmapMap._vx_blockclick= false

#    doClick: (e)->
#        log "MAPHTML react click  e=",e
#
#     doClickCapture: (e)->
#         log "MAPHTML react click capture e=",e
#

    componentDidUpdate:(prevProps,prevState)->
        # TODO check node atribute changes
        for key,{a,b} of oDiffs prevProps,@props
            switch key
                when 'top','left','bottom','right'
                    viewChanged= true
                when 'className'
                    @node.setAttribute 'class',"map-svg #{b or ''}"
                when 'zIndex'
                    @node.style.zIndex= b
                when 'visible'
                    @node.style.display= if b then 'block' else 'none'
#                 when 'zoom'
#                     @node.style.setProperty '--svgpixelsize',pixelSize= (360/256)/2**b
#                     #for x in [ 1 .. 16 ]
#                     #    @node.style.setProperty "--svg#{x}px","#{x*pixelSize}px"
        if viewChanged
            {top,left,right,bottom}= @props
            bounds= asGLatLngBounds [ (latMInv bottom),left,(latMInv top),right ] # swne

            w= left
            e= right
            n= top
            s= bottom
            @node.setAttribute 'viewBox',"#{w} #{-n} #{e-w} #{n-s}"
            @zone.setBounds bounds

        @renderToNode()


    componentWillUnmount: ->
        #log "maphtml control #{@props.id} was node #{!!@node} "
        unmountComponentAtNode @node if @node


polylineSVG= (props)->
    return null if !props.path
    { color, opacity, weight, visible, label, path, key, gmapMap, zIndex} = props
    #log "polylineSVG weight=#{weight} path=",path
    polyProps=
        __proto__:props
        zIndex:     undefined
        key:        undefined
        path:       undefined
        color:      undefined
        opacity:    undefined
        weight:     undefined
        clickable:  undefined
        visible:    undefined
        label:      undefined
        gmapMap:    undefined

        points:    (pt.toStrCoordM() for pt in props.path).join ' '
        $stroke:    color
        $strokeWidth: "#{weight}px" if weight?
        $opacity:  opacity
        title: label
        visibility: 'hidden' if visible? and !visible

    {x,y,X,Y}= Bounds.aPTS props.path

    mapSVG key:key, gmapMap:gmapMap, zIndex:zIndex, left:x,right:X,top:(latM Y),bottom:(latM y),
        svgPolyline polyProps



mapContainer= meta class MapContainer extends UIComponent
    @displayName: 'MapContainer'
    # props types see Widget


    constructor:(props)->
        super props
        vx.MC=@

        #init done by map idle can be delayed unltil gmaps is available....
        @state=
            zoom:   null #props.zoom
            center: null #props.center
            bounds: null #props.bounds
            mapType: props.mapType

        #log "!!! MapContainer.constructor !!!"

        return @

    #alphabet='abcdefghijklmnopqrstuvwxyz'
    zoomStr='00 01 02 03 04 05 06 07 08 09 10 11 12 13 14 15 16 17 18 19 20 21 22 23 24'

    render:->
        zoom= @state.zoom
        #log "!!! MapContainer.render zoom=#{zoom}"
        lines={}
        if zoom?
            gt=zoomStr[... 3*zoom]
            lt=zoomStr[3*(1+zoom)..]
            z=zoomStr[(3*zoom) .. 1+(3*zoom)]

            #Lines
            pixelSize= (360/256)/2**zoom
            #for x in [ 1 .. 16 ]
            #    lines["--svg#{x}px"]="#{x*pixelSize}px"
        else
            lt=gt=z=''
            pixelSize=0.1

        div ('.map-container'),
            'data-zoom-lt': lt # '00 01 02 03 04 05 06' # lt
            'data-zoom-gt': gt # '08 09 10 11 12 13 14 15 16 17 18 19 20 21 22 23 24' # gt
            'data-zoom':    z  # '07' # z
            'data-maptype': @state.mapType
            style:
                '--svgpixelsize':"#{pixelSize}px"

    componentDidMount: -> # DOinit
        @node= findDOMNode @
        @initGMap @node
        null

    componentWillUpdate: (newProps)->
        return if not gmap= @gmapMap
        #log "MapContainer Widget componentDidUpdate gmap is #{!!gmap}",{props:@props,state:@state,newProps}

        changeState= false
        newState= {}

        if @props.zoom isnt newProps.zoom and newProps.zoom isnt @state.zoom
            #log "setMap zoom to #{newProps.zoom}"
            gmap.setZoom newProps.zoom
            changeState= true
            newState.zoom= newProps.zoom

        if not( llEqual @props.center,newProps.center) and not(llEqual newProps.center,@state.center)
            #log "set map center to ",newProps.center
            gmap.panTo newProps.center
            changeState= true
            newState.center= newProps.center

#         if not(newState.center) and newProps.bounds and not( bndsEqual @props.bounds,newProps.bounds) and not(bndsEqual newProps.bounds,@state.bounds)
#             log "set map bounds to ",newProps.bounds
#             gmap.fitBounds asGLatLngBounds newProps.bounds
#             changeState= true
#             newState.bounds= asGLatLngBounds newProps.bounds

        if newProps.mapType isnt @state.mapType
            #log "set map mapType to ",newProps.mapType
            gmap.setMapTypeId newProps.mapType
            changeState= true
            newState.mapType= newProps.mapType


        if changeState
            @setState newState


    componentWillUnmount: -> #todo remove events

    forceMapResize:->
        if !@node or !@gmapMap
            log "forceMapResize ignored @node=#{!!@node} @gmapMap=#{!!@gmapMap}"
            return
        if @doingForceMapResize
            log "forceMapResize ignored already doingForceMapResize"
            return

        self= @

        log "forceMapResize start"

        FProp='paddingBottom'
        FVal='1px'

        self.doingForceMapResize= true
        requestAnimationFrame ->
            #log "forceMapResize smaller #{FProp}=#{self.node.style[FProp]}  h=#{self.node.clientHeight}",self.node.style
            self.node.style[FProp]=FVal
            #log "forceMapResize smaller #{FProp}=#{self.node.style[FProp]}  h=#{self.node.clientHeight}",self.node.style
            Global.google.maps.event.trigger self.gmapMap,"resize"
            self.doingForceMapResize= true

            requestAnimationFrame ->
                #log "forceMapResize smaller #{FProp}=#{self.node.style[FProp]}  h=#{self.node.clientHeight}",self.node.style
                self.node.style[FProp]=0
                #log "forceMapResize smaller #{FProp}=#{self.node.style[FProp]}  h=#{self.node.clientHeight}",self.node.style
                Global.google.maps.event.trigger self.gmapMap,"resize"
                self.doingForceMapResize= false



    initGMap: (node)->
        #log "!!! MapContainer.initGMap gmaps is #{!!gmaps} node is #{!!node}"
        return if not gmaps
        return if not node #should throw an error


        {
            center={lat:47,lng:-71},
            zoom=7,
            onBkgClick,

            mapTypeControl=true,
            mapTypeControlOptions= {
                position: if vx.mapMode.menu then 'LEFT_TOP' else 'TOP_LEFT'
                mapTypeControl: true,
                style: if @props.screenWidth isnt 'wide'
                     gmaps.MapTypeControlStyle.DROPDOWN_MENU
                else gmaps.MapTypeControlStyle.HORIZONTAL_BAR
                },

            mapType=gmaps.MapTypeId.TERRAIN,
            zoomControl=true,
            zoomControlOptions=      { position: 'RIGHT_BOTTOM' },

            streetViewControl=true,
            streetViewControlOptions={ position: 'RIGHT_BOTTOM' },

            scaleControl=true,
            scaleControlOptions, #=     { position: 'LEFT_TOP' }

            fullscreenControl= false,
            fullscreenControlOptions=    { position: 'TOP_RIGHT' },

            gestureHandling= 'greedy',
            disableDefaultUI=true,
            mapOps
        }= @props

        center= asGLatLng center

        #now done by triggering an 'idle' event
        #@state.center= center # I Know but its hack...
        #@state.zoom= zoom

        if typeof mapTypeControlOptions?.position is 'string'
            mapTypeControlOptions= oAssign {},mapTypeControlOptions,position:gmaps.ControlPosition[mapTypeControlOptions.position]


        if typeof zoomControlOptions?.position is 'string'
            zoomControlOptions= oAssign {},zoomControlOptions,position:gmaps.ControlPosition[zoomControlOptions.position]

        if typeof streetViewControlOptions?.position is 'string'
            streetViewControlOptions= oAssign {},streetViewControlOptions,position:gmaps.ControlPosition[streetViewControlOptions.position]

        if typeof scaleControlOptions?.position is 'string'
            scaleControlOptions= oAssign {},scaleControlOptions,position:gmaps.ControlPosition[scaleControlOptions.position]

        if typeof fullscreenControlOptions?.position is 'string'
            fullscreenControlOptions= oAssign {},fullscreenControlOptions,position:gmaps.ControlPosition[fullscreenControlOptions.position]



        ops= oAssign { center, zoom,\
                         mapTypeControl,   mapTypeControlOptions, mapTypeId:mapType,\
                           scaleControl,     scaleControlOptions,\
                            zoomControl,      zoomControlOptions,\
                      streetViewControl,streetViewControlOptions,\
                      fullscreenControl,fullscreenControlOptions,\
                      disableDefaultUI, gestureHandling },mapOps
        log "Creating map with",{node:node,ops:ops, width:node.clientWidth, height:node.clientHeight, lat:center.lat(),lng:center.lng()}

        @gmapMap= new gmaps.Map node,ops # new MapDebug div,ops #

        self= @
        #log "!!! MapContainer.initGMap  promise for 'deviceready main-gmaps-map' "
        Promise.vxGetAll 'deviceready main-gmaps-map' # TODO make map promise name a prop ...
            .then ->
                #log "!!! MapContainer.initGMap resolved promise for 'deviceready main-gmaps-map' "
                # No more vars in CSS, mapStyle= (mapCS=getComputedStyle(node)).getPropertyValue '--map-style'
                mapStyle= vx.q?.mapStyle
                log "mapStyle=",{mapStyle}
                if mapStyle and mapStyle isnt 'none'
                    try
                        mapStyle= JSON.parse mapStyle # was JSON.parse JSON.parse cause css strings are double quoated ....
                    catch e
                        mapStyle= null
                else mapStyle=null
                #log "did mapStyle=",mapStyle
                if mapStyle
                    self.gmapMap.setOptions styles:mapStyle
                #log "JUST DID deviceready main-gmaps-map force map resize"
                self.forceMapResize()


        #state managers

        Global.google.maps.event.addDomListener window, "resize",=>
            { offsetWidth,offsetHeight}= node
            center=  @props.center # @state.center or
            #log " resize set Center node size is #{offsetWidth},#{offsetHeight} state.center is #{asJSON @state.center,0} props.center  is  #{asJSON @props.center,0}"
            return if !offsetWidth or !offsetHeight or !center
            #log "triggering @gmapMap,resize"
            Global.google.maps.event.trigger @gmapMap,"resize"
            @gmapMap.setCenter center if center

        On= gmaps.event.addListener.bind gmaps.event,@gmapMap

        On 'idle',=>
            { offsetWidth,offsetHeight}= node
            #log " GMAPS IDLE node size is #{offsetWidth},#{offsetHeight}"
            return if !offsetWidth or !offsetHeight
            change={}
            changed= false
            newBounds= @gmapMap.getBounds()
            if newBounds and ( !@state.bounds or !(bndsEqual newBounds,@state.bounds) )
                changed= change.bounds= newBounds
                @props.onNewBounds? (Bounds.fromNative newBounds),"#{@props.name or 'gmap'}_bounds",@

            newCenter= @gmapMap.getCenter()
            if !@state.center or !(llEqual newCenter,@state.center)
                changed= change.center= newCenter
                @props.onNewCenter? newCenter,'center',@

            newZoom= @gmapMap.getZoom()
            #log "IIIIIIIIIIII Idle current zoom is #{newZoom} stae=#{@state.zoom} a=#{@state.zoom isnt 0 and !@state.zoom} or b#{!(newZoom is @state.zoom)}"
            if (@state.zoom isnt 0 and !@state.zoom) or !(newZoom is @state.zoom)
                changed= change.zoom= newZoom
                #log "zoom changed to #{newZoom}"
                @props.onNewZoom? newZoom,'zoom',@

            if changed
                #log "IIIIIIIIIIIII idle change=",change
                @setState change

        On 'maptypeid_changed',=>
            newType= @gmapMap.getMapTypeId()
            #log "New map type=#{newType}"
            if @state.mapType isnt newType
                @setState mapType:newType
                @props.onNewMapType? newType,'mapType',@

        gmaps.event.trigger @gmapMap,'idle' # Initialize,carefull this could create a render loop

        if onBkgClick
            @_clickListener= On 'click',(e)=>
                #log "GMAPS MAP click e=",e
                if @gmapMap._vx_blockclick
                    #log "click blocked"
                    @gmapMap._vx_blockclick= false
                    return null
                if @props.onBkgClick and not e.placeId
                    #log "set click timer 200"
                    @gmapMap._vx_clicktimer= setTimeout (=>
                        #log "click timeout"
                        @gmapMap._vx_clicktimer= null
                        @props.onBkgClick e,@
                        ),200
                else null
#                 if e.placeId
#                     log "clicked place = #{e.placeId}"
#                     e.stop()


#         # find the page and watch for beforeshow
#         page= node.closest '[data-role="page"]'
#         pageContainer= page.closest '.ui-mobile'
#
#         $(pageContainer).on 'pagecontainershow',(e,{toPage})=>
#             return if not toPage or toPage[0] isnt page
#             log " %%%%%%%%%%%% pagecontainershow trigger a resize"
#             gmaps.event.trigger @gmapMap,'resize'





mapWidget= meta class MapWidget extends UIComponent
    @displayName: 'MapWidget'


    @propTypes =

        name:    @type.string
        promise: @type.string

        center: @type.object
        zoom:   @type.number
        bounds: @type.object

        onBkgClick: @type.func
        onNewBounds: @type.func
        onNewCenter: @type.func
        onNewZoom:   @type.func

        onNewMapType: @type.func
        mapTypeControl: @type.bool
        mapTypeControlOptions: @type.object
        mapType: @type.string

        zoomControl: @type.bool
        zoomControlOptions: @type.object

        scaleControl: @type.bool
        scaleControlOptions: @type.object

        streetViewControl: @type.bool
        streetViewControlOptions: @type.object

        fullscreenControl: @type.bool

        info: @type.object


    constructor:(props)->
        super props
        @state=
            gmapMap:null
            gmapsReady: !!gmaps

        if not gmaps
            Promise.vxGet("#{ModuleName}Ready").then => @setState gmapsReady:true


    render:->

        if @state.gmapsReady
            props= oAssign {ref:@setMap},@props,{children:null,promise:null}
            div ('.map-widget .gmap-map'),
                mapContainer props
                if @state.gmapMap and @props.children
                    i=1
                    gmapMap= gmapMap:@state.gmapMap

                    childrenMap @props.children,(child)->
                        if child
                            cloneElement child,gmapMap
                        else child


        else
            div "|Waitng for google maps api"



        #todo bounds check



    #renderMapChildren:-> @props.children


    setMap: (aMapContainer)=>
        #log "$$$$$$ mapWidget mapContainer #{aMapContainer} gmapMap=#{aMapContainer?.gmapMap} from MapContainer ",aMapContainer if vx.isDEV
        if aMapContainer?.gmapMap
            @setState gmapMap:aMapContainer.gmapMap
            if @props.promise
                Promise.vxGet @props.promise
                    .vxResolve @

    includeBounds: (bnds)->
        if @state.gmapMap
            @state.gmapMap.panToBounds asGLatLngBounds bnds

    fitBounds: (bnds)->
        gbnds= asGLatLngBounds bnds
        #log "fit bnds gmap bnds=#{gbnds}",{gmap:@state.gmapMap,gbnds,bnds}
        if @state.gmapMap

            @state.gmapMap.fitBounds gbnds #asGLatLngBounds gbnds

#     panTo: (pt,zoom)->
#         if !@state.gmapMap
#             log "ignore panTo #{pt} z=#{zoom} no map"
#             return
#         if !zoom?



mapSearch= meta class MapSearch extends UIComponent
    @displayName: 'MapSearch'

    autoComplete: false

    render: (props=@props)->
        props=
            __proto__: props
            placeholder: props.placeHolder or say "Recherche Google"
            bounds: undefined
            onNewValue: undefined
            acOps: undefined
        htmlInput 'type=text',props



    componentDidMount: ->

        @initGMap findDOMNode @


    initGMap: (node)->
        throw new Error "Missing Node" if not node
        throw new Error "Missing GMaps" if not gmaps
        ops= oAssign {},@props.acOps
        ops.bounds= @props.bounds if @props.bounds

        #log " @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ Autocomplet info got",{ops,node}
        @autoComplete= new gmaps.places.Autocomplete node,ops
        #log " @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ Autocomplet did got",{ops,node,ac:@autoComplete}
        gmaps.event.addListener @autoComplete,'place_changed',@doChange

    doChange: ->
        @props.onNewValue @autoComplete.getPlace(),@props.name


    componentWillUpdate: (newProps)->
        return if not @autoComplete
        oldBounds= @props.bounds
        newBounds= newProps.bounds
        if (newBounds isnt oldBounds) and ( (!newBounds or !oldBounds ) or !( bndsEqual newBounds,oldBounds ) )
            @autoComplete.setBounds asGLatLngBounds newBounds




module.exports=  { mapPopup, mapControl, marker, polyline, tileLayer, TileLayer, wmsTileLayer, dynamicTileLayer, mapHTML, mapSVG, polylineSVG, mapWidget, mapSearch, API:'gmaps' }
