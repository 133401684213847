{ oAssign }= vx= require 'vx/globals/Boot'

# ********** BaseClass *********** /

alloc= -> @apply((o= Object.create @::),arguments) ? o

log= console.log.bind console

clsAlloc= ->
    #log "-------------------- clsAlloc called @=#{@name} typeof @=#{typeof @}"
    args= Array.prototype.slice.call arguments
    args.unshift null
    f= @.bind.apply @,args
    #log 'before new'
    ret= new f()
    #log "after new ret=#{ret}"
    ret

simpleClsAlloc= (a,b)-> new @ a,b # Typially (val,ops) or just (ops)

class BaseClass

    @meta= ->
        @addNewless()
        @_

    @addNewless= ->
        if @_
            if @_.__unbound
                 unbound= @_.__unbound
            else unbound= @_
        else
            unbound= simpleClsAlloc
            console.log "CCCCCCCCCCCCCCCCCCCCCC created alloc for SHOULD NEVER HAPPEN?",@.name
        @_= unbound.bind @
        @_.__unbound= unbound


    #@_= clsAlloc


    @get= (name,get)->
        (@::_getters ? @::_getters=[]).push name
        Object.defineProperty @::, name, { get, configurable: yes}

    @getters= (getmap)->
        for name,get of getmap then @get name,get
        @


    @set= (name,set)->
        console.log " ADDING SETTER FOR #{name} fnc=",set
        Object.defineProperty @::, name, { set, configurable: yes}

    @setters= (setmap)->
        for name,set of setmap then @set name,set
        @

    @_= simpleClsAlloc

    constructor:(ops)-> return oAssign @,ops

    toString:()-> "<#{@constructor.name}>"

    _touchGetters: ()->
        return if !@_getters
        for name in @_getters
            x=@[name]
        null

    @_bind= (name)->
        if typeof @[name] is 'function'
             @[name].bind @
        else null

    _bind: @_bind

module.exports= { BaseClass }
