#!/usr/bin/coffee
{ oAssign, oA, log, aLast, sList, before }= vx= require 'vx/globals/Boot'


{ Meter2Degre,  SIScale, verySmall  }= require './Const'
{ Pt } = require './Utils'
{ GraphUtils, graphInfo }= require './Graph'
{ mCalcDist }=          require 'vx/math/MapGeometry'
{ intersectionXY }=     require 'vx/math/Geometry'
{ sqrt, floor }= Math

aRemoveValue= (arr,val)->
        if (pos= arr.indexOf val)>-1
             newArr=arr[..]
             newArr.splice pos,1
             newArr
        else arr



simplifySamePixelPts= null #defined in class
simplifyInlinePts= null


class MutableGraph extends GraphUtils
    # TODO: getNodeCache (and probaly getLineCache) should use -180,-90,180,90
    #  becuase surface could grow, unless we are only simplifing


    mergeSegments: (node)->
        #log "start mergeSegements for node #{node?.id}"
        if node?.starts?.length isnt 1 and node?.ends?.length isnt 1
            throw new Error 'Un mergable node #{node?.ID}',node

        endSeg=   @segments[node.ends[0]]
        startSeg= @segments[node.starts[0]]

        if endSeg is startSeg
            log "Hum we have a loop Ignore"
            return


        if endSeg.oneway isnt startSeg.oneway
            throw new Error "Segment merge oneway mis match node=#{node}",node

        data={}
        startData= startSeg.data
        endData=   endSeg.data
        for k,ev of endData
            sv= startData[k]
            if sv is ev
                 data[k]= ev
            else data[k]= "#{ev},#{sv ? '!'}"
        for k,sv of startData when k not of endData
            data[k]= "#{sv},!"

        pts= endSeg.pts.concat startSeg.pts[1...]
        @_removeSegment startSeg
        @_removeSegment endSeg

        @_addSegment { pts, data, oneway:endSeg.oneway }



    splitSegmentAtIdx: (seg,idx,{startData,endData,nodeData}={})->

        pts= seg.pts

        if idx is 0 or idx is pts.length-1
            log "!!!!!!!!!!!!!!!!!!!!!!!! invalid split idx=#{idx} len=#{pts.length}"
            throw new Error "invalid split on ",seg

        startPts= pts[..idx]
        endPts=   pts[idx..]

        startData= oAssign {},seg.data,startData
        endData=   oAssign {},seg.data,endData

        @_removeSegment seg

        startSeg= @_addSegment pts:startPts, data:startData
        endSeg=   @_addSegment pts:endPts,   data:endData

        if startSeg.end isnt endSeg.start
            log "!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! Start end mismatch"

        node= @nodes[startSeg.end]
        if nodeData
            node= @nodes[startSeg.end]
            @_updateNode node,nodeData

        { node, startSeg, endSeg }


    reverseSegment:(seg)->

        #log "start revsersegment for seg #{seg?id}"
        if seg.oneway
            throw new Error 'Unrevseable Oneway segment #{seg}',seg

        pts= seg.pts[..].reverse()
        data= seg.data

        @_removeSegment seg

        newSeg= @_addSegment {pts,data}

        newSeg




    _removeSegment:(seg)->

        #TODO: Detect floating nodes and remove

        @segments[seg.id]=null
        if @lineCache
            @lineCache.remove seg

        startNode= @nodes[seg.start]
        @_updateNode startNode, starts: aRemoveValue startNode.starts,seg.id

        endNode= @nodes[seg.end]
        @_updateNode endNode, ends: aRemoveValue endNode.ends,seg.id

        null


    _updateSegment: (seg,updates)->
        newSegment= seg._clone updates
        @segments[newSegment.id]= newSegment

        if @lineCache
            @lineCache.remove seg
            @lineCache.add newSegment if newSegment

        newSegment


    _updateNode: (node,updates)->
        newNode= node._clone updates
        if !newNode.starts.length and !newNode.ends.length
            # no connecctions does not exists
            # instead remove
            newNode= null

        if @nodeCache
            @nodeCache.remove node
            @nodeCache.add newNode if newNode

        @nodes[node.id]= newNode #this should replace node, newNode maybe null

        newNode



class GraphSimplify extends MutableGraph
    #

    #constructor: (ops)->
        #log "Graph @=#{@}",@
        #return new GraphSimplify ops if @ is Global or !@?
        #return @init ops


    DataList= sList 'cat no_sen e f reg numClub'

    splitTs: (radius=0,linkData={link:1,cat:'V'})->
        #find single endings
        # nodes may grow during the loop
        log "start splitTs"
        idx=-1
        #maxRadius= max ( radius * Meter2Degre ), 101 * @iNodeScale #101*@iNodeScale= we know find starts at 100
        dRadius= radius * Meter2Degre

        while idx<@nodes.length
            idx++
            #log "#{idx}"
            node= @nodes[idx]
            continue if !node
            #check if a start or an end
            continue if ( node.starts.length + node.ends.length ) isnt 1
            # of we have a an End...
            log "#{idx}"

            endSeg= @segments[node.starts[0] or node.ends[0]]

            #log "before tsegInfo"
            tSegInfo= @findClosestSegmentToPtM node.pt,dRadius,{ignoreSegId:endSeg.id}

            if tSegInfo
                log "tsegInfo.dist=",tSegInfo.dist
            else log "tsegInfo=",tSegInfo
            continue if not tSegInfo
            # we got a hit lets calc real distance
            dist= 1000* mCalcDist node.pt.y,node.pt.x,tSegInfo.y,tSegInfo.x
            tseg= tSegInfo.seg
            log "Deadend node #{node.id} start of #{node.starts[0]},#{node.starts.length} ends #{node.ends[0]}/#{node.ends.length}"
            log "  connect at #{dist} is T #{dist <= radius} for seg #{tseg.id} from node #{tseg.start} to #{tseg.end}"
            if dist <= radius
                # we are at radius distance
                # check if pt is a node

                log "T hits for seg #{endSeg.id} on segment #{tSegInfo.seg.id} at #{dist.toFixed 3} meters"

                nextNode= @_nodeFor Pt._ tSegInfo.x,tSegInfo.y,1 #dont create

                if !nextNode  or nextNode is node #ok no node lets create one
                    # ok slit found segment
                    #log "Splitiing seg #{tSegInfo.seg.id} at #{tSegInfo.idx} new node #{!(nextNode is node)}"
                    if tSegInfo.idx is 0 or tSegInfo.idx is tSegInfo.seg.pts.length-1
                        log "split at begining or end 'should never happen' Ignore"
                        continue
                    log "split segment #{tSegInfo.seg.id} at #{tSegInfo.idx}/#{tSegInfo.seg.pts.length} idxf=#{tSegInfo.idxf} idx2=#{tSegInfo.idx2} "
                    {node:nextNode}= @splitSegmentAtIdx tSegInfo.seg,tSegInfo.idx
                #else
                #    log "found node #{nextNode.id}"

                if nextNode is node
                    #AHH should never happen probaly iNodeScale to large we are looping here warn and continue
                    #log "no new node current node conect coount should be 3 is #{node.starts.length+node.ends.length}"
                    continue
                # OK in business just add a segment between the 2 nodes
                if node.starts.length
                    # add nextNode pt to start
                    pts= [nextNode.pt,node.pt]
                else
                    pts= [node.pt,nextNode.pt]
                newSeg= @_addSegment {pts,data:oA {},linkData}
                #log "added link segment #{newSeg.id} = #{pts}"


    connectEnds: (radius=0,linkData={link:1,cat:'V'})->
        #find single endings
        # nodes may grow during the loop
        nfCnt=0
        log "start connectEnds"
        #maxRadius= max ( radius * Meter2Degre ), 101 * @iNodeScale #101*@iNodeScale= we know find starts at 100
        addCnt=0
        idx=0
        single=0
        nextNode= null
        dist= Infinity
        for node in @nodes
            idx++
            node= @nodes[idx]
            continue if !node
            #check if a start or an end
            continue if ( node.starts.length + node.ends.length ) isnt 1
            # of we have a an End...
            ++single
            endSeg= @segments[ node.starts[0] or node.ends[0] ]
            iPts=[ @nodes[endSeg.start].pt, @nodes[endSeg.end].pt ]
            #log "serech node=#{node.id} pt=#{node.pt} ipts=#{iPts}"
            {node:nextNode,dist}= @findClosestNode2PtV2 node.pt,radius,iPts # ignore segment nodes
            if !nextNode
                nfCnt++
                if nfCnt<30 or !(nfCnt % 100)
                    log "not found #{nfCnt} #{node.id}"
                continue
            mdist= 1000* mCalcDist node.pt.y,node.pt.x,nextNode.pt.y,nextNode.pt.x

            #log "connect ends got node #{nextNode?.id} for node #{node?.id} at #{dist} at #{mdist} hit = #{dist<radius} nodePt=#{node.pt}"
            #log " enseg dist=#{endSeg?.dist} ipts= #{iPts}"
            continue if !nextNode or dist>=radius

            ++addCnt
            #OK add a link segment
            if node.starts.length
                # add nextNode pt to start
                 pts= [nextNode.pt,node.pt]
            else pts= [node.pt,nextNode.pt]
            newSeg= @_addSegment {pts,data:oA {},linkData}

        log "connectEnds add #{addCnt} segments saw #{idx} nodes #{single} where singles "



    simplify: ({dataList=DataList,zoom=16,width=2,keepList,debug=0,noData=0,mergable}={})->

        if debug
            log " SIMPLIFY BEFORE"
            graphInfo @


        @simplifyMS dataList,mergable
        if debug
            log "AFTER simplifyMS"
            graphInfo @

        @simplifySamePixel zoom
        if debug
            log "AFTER simplifySamePixel"
            graphInfo @

        @simplifyInline zoom,width
        if debug
            log "AFTER simplifyInline"
            graphInfo @

        if keepList
            @simplifyData keepList
            if debug
                log "AFTER simplifyData"
                graphInfo @



    simplifyMS:(sameDataList,mergable)->

        mergedCnt= 0

        #for node in @nodes when node and (((node.starts.length is 2) and !node.ends.length) or (!node.starts.length  and (node.ends.length and 2)))
        #    log "simplifyMS double #{if node.starts.length then 'start' else 'end'}"

        try
            log "nodes length is #{@nodes.length}"
            idx=-1
            # nodes may grow during the loop
            while idx<@nodes.length
                idx++
                node= @nodes[idx]
                if not ( node and (nStarts=node.starts) and (nEnds=node.ends) and (((nStarts.length is 1) and (nEnds.length is 1)) or ((nStarts.length is 2) and !nEnds.length) or (!nStarts.length  and (nEnds.length and 2))) )
                    continue

                if nStarts.length is 2
                    continue if @segments[nStarts[0]].oneway or  @segments[nStarts[1]].oneway
                    #log "simplify double start fixe"
                    @reverseSegment @segments[nStarts[1]]
                else if nEnds.length is 2
                    continue if @segments[nEnds[0]].oneway or  @segments[nEnds[1]].oneway
                    #log "simplify double end fixe"
                    @reverseSegment @segments[nEnds[0]]

                if node isnt @nodes[idx] # Node has changed
                    #log "node was changed #{idx}"
                    node= @nodes[idx]
                if not ( node and (nStarts=node.starts) and (nEnds=node.ends) and (nStarts.length is 1) and (nEnds.length is 1) )
                    #log "simplifyMS double start/end mismatch ????"
                    continue

                startSeg= @segments[nStarts[0]]
                endSeg=   @segments[nEnds[0]]
                if startSeg.oneway is endSeg.oneway and @_dataMatch startSeg,endSeg,sameDataList
                    if !mergable or mergable startSeg,endSeg
                        @mergeSegments node
                        mergedCnt++
        catch err
            log "simplifyMS 2 error "
            console.trace err
            throw err

        log "nodes length is #{@nodes.length} idx is #{idx}"
        log "Did #{mergedCnt} merges"


    simplifyData:(nameList)->
        for seg in @segments when seg and sData=seg.data
            data={}
            for k in nameList
                data[k]=sData[k]

            @_updateSegment seg,data:data

        @


    simplifyInline: (zoom=16,width=2)->


        for seg,i in @segments when seg

            pts= seg.pts

            outPts= @simplifyInlinePts pts

            if outPts.length< pts.length
                #log "Saved pts #{pts.length-outPts.length} of #{pts.length}"
                @_updateSegment seg,pts:outPts



    simplifyInlinePts: simplifyInlinePts= (pts, zoom=16,width=2)->

        pixelSize= 2**(8+zoom)
        maxDist=width*360/pixelSize
        #log "maxDist=#{maxDist}"

        #doLog= !(i % 100)
        doLog=0

        outPts= [ pts[0]]
        lastPtX= pts[0].x
        lastPtYm= pts[0].ym

        defered= [
            x:pts[1].x
            ym:pts[1].ym
            pt: pts[1]
            ]

        log " seg  maxDist=#{maxDist}",{ptslen:pts.length,outPts,lastPtX,lastPtYm,defered} if doLog


        for pt,j in pts[2...]

            current=
                x:currentX= pt.x
                ym:currentYm= pt.ym
                pt: pt
            stop= false
            if doLog and j<10
                log " #{j}  dlen=#{defered.length}"

            for {x,ym,pt},k in defered when !stop
                if doLog and j<10
                    log "#{k} #{j}  xy=#{x},#{ym} pt=#{pt}"
                rep= intersectionXY lastPtX,lastPtYm,currentX,currentYm,x,ym

                log "uo=#{rep.uo} pti=#{rep.x},#{rep.y}" if  doLog and j<10
                { x:xti, y:yti ,uo }=rep
                d= sqrt (dx=x-xti)*dx+(dy=ym-yti)*dy
                if uo<=0 or uo>=1
                    # defered pt does not intersect segemnt htis is a fail
                    stop=true
                    break
                else
                    #d= sqrt (dx=x-xt)*dx+(dy=y-yt)*dy
                    if d > maxDist
                        # to far from segemnt fail
                        stop=true
                        break
            if stop
                #we failed last defered point must be kept
                {x:lastPtX,ym:lastPtYm,pt:last}= aLast defered
                outPts.push last
                defered= [ current ]
            else # we passed
                defered.push current

        outPts.push (aLast defered).pt

        outPts



    simplifySamePixel: (zoom=16)->
        for seg in @segments when seg and seg.pts isnt (pts=@simplifySamePixelPts seg.pts,zoom)
            @_updateSegment seg,pts:pts


    simplifySamePixelPts: simplifySamePixelPts= (pts,zoom=16)->
        pixelSize= 2**(8+zoom)
        pixelWidth=360/pixelSize

        asPixel= (v)-> (floor (180+v)/pixelWidth) % pixelSize

        asPixelPt= (pt)-> x:(asPixel pt.x ),y:(asPixel pt.ym)

        outPts=[pts[0]]
        lastPixel= asPixelPt pts[0]

        for pt in pts[1 ... -1]
            pixel= asPixelPt pt
            if pixel.x isnt lastPixel.x or pixel.y isnt lastPixel.y
                outPts.push pt
                lastPixel= pixel

        outPts.push aLast pts

        if outPts.length is pts.length
            return pts

        #log "Saved #{pts.length-outPts.length} of #{pts.length} points"

        outPts


    _dataMatch: (startSeg,endSeg,dataList)->
        a= startSeg.data
        b= endSeg.data
        for p in dataList when a[p] isnt b[p]
            return false
        true



module.exports=  { GraphSimplify, simplifySamePixelPts, simplifyInlinePts } 

