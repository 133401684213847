{ log, meta, ModuleName, oIsEmpty }= vx= require 'vx/globals/Boot'  # to get a global ref to HelloWorld that survies webpack

{ memo }= require 'react'

{ logRender }= require 'vx/tools/logRender'

require './AdminEditSheet.css'

wait= require 'vx/tools/Wait'

vx.fileurls= require 'vx/globals/FileURLS'

# I18n
{ sayModule }= require 'vx/i18n/I18n'
say= sayModule 'AdminPage' # was ModuleName

UI= require 'vx/UI'

# UI layout
{ page, sidePanel, header2, tbTitle, section, content, group, ul, li, form, iframe }= UI

# html blocks
{ div, h1, h2, h3, h4, h5, h6 }= UI # pass through require 'DOMTags'

# html inline
{ p, br, span, img }= UI

#htmlctrls
{ button }= UI

{ list, listItem }= UI

{ updateForm, updateFormJ }= require 'vx/tools/Form'

{ reg2nomFr, reg2nomEn }= require 'vx/globals/Conversions'



sul= (props,...items)->
    itemProps=props.itemProps or ''
    ul {props...,itemProps:undefined},...( li itemProps,item for item in items.flat 2 when item )

getName= (membre='',lang='fr')->
    lookup= if lang is 'en' then reg2nomEn else reg2nomFr
    lookup[ membre.toUpperCase() ] or membre

doNavigate= (e)->
    { href,id}= e.target
    log "doNavigate for #id} to #{href}",e.target
    window.open href,id



doClickAction= (e)->
    log "clicked #{e.target.id}"
    
    
adminEditSheet= ({app,id='admin',key,menuBtn,active})->
            membre= vx.q?.srvr
            sName= getName membre,app.state.lang
            
            sheetId= vx.q.sheetId
            action=  vx.q.action
            update=  vx.q.update or ''
            
            if membre and 0>update.indexOf membre
                update="#{update} #{membre}"
            
            headerVisible= app.state.editSheetHeaderVisible
            
            readyState=app.getAdminState()
            adminMess= app.getAdminMessage()
            # log "render adminPage readyState=#{readyState}"
            
            isReady= readyState is 'ready'
            
            page {active,id,key},

                header2 '.appadmineditsheet__header',
                    left: [
                            (menuBtn '')
                            tbTitle say "Edition #{update}"
                            ]
                    right:[
                        
                            button '#admin-sheet-toogleheader',
                                disabled:!isReady
                                onClick:-> app.setState editSheetHeaderVisible:!headerVisible
                                say "#{headerVisible and 'Cacher' or 'Afficher'} entete"
#                             button '#admin-sheet-showhide',
#                                 disabled:true or !isReady
#                                 onClick:doClickAction
#                                 say "cacher/voir aide"
                            button '#admin-sheet-update',
                                disabled:!isReady
                                onClick:makeUpdate {app}
                                'data-url':"https://docs.google.com/spreadsheets/d/#{sheetId}"
                                'data-action':action
                                'data-membre':membre
                                'data-update':update
                                say "MAJ Carte"             
                            img '.vx-user-pict',src:vx.q.pict if vx.q.pict
                            ]

                content '.page__content-margin', switch readyState
                    when 'ready'
                        if sheetId
                            iframe '.sheetframe',
                                src:"https://docs.google.com/spreadsheets/d/#{sheetId}/#{!headerVisible and 'edit?rm=minimal&gid=0&single=true&widget=true&headers=false#gid=0' or 'edit?embed=true'}"
                        else '?'
                    when 'updating' then [
                        h5 '',say "Mise à jour encours ..."
                        p '',adminMess
                        ]
                    when 'succes' then [
                        h5 '',
                            button
                                onClick: wait ->app.setAdminState 'ready',''
                                say "OK"
                            say "Mise à jour réussie!"
                        p '',adminMess
                        ]
                    when 'error304' then [
                        h5 '',
                            button
                                onClick: wait ->window.location.reload true
                                say "OK"
                            say " ATTENTION! Pas de changement"
                        p '',adminMess
                        ]
                    when 'error403' then [
                        h5 '',
                            button
                                onClick: wait ->window.location.reload true
                                say "OK"
                            say " ATTENTION! Erreur d'authetification"
                        p '',adminMess
                        ]
                    else
                        log "render AppAdminPage unkown state #{readyState}"
                        [
                            h5 '',
                                button
                                    onClick: wait ->app.setAdminState 'ready',''
                                    say "OK"
                                say " État incounnu #{readyState}"
                            p '',adminMess
                            ]


makeUpdate= ({app})->
    doUpdate= (e)->
        #log "doUpdate e=",{e,target:e.target,ds:e.target?.dataset}
        actionTarget= e.target.closest("[data-click-action],[data-action]")
        {url,action,membre,update}=actionTarget.dataset
        curl= "#{url}/export?format=csv"
        #log "START MAJ #{action}",{url:curl,action,membre}
        app.setAdminState 'updating'," Maj #{update} en cours"
        try
            [response,status,url]= await postData action,{url:curl,membre}
            switch status
                when 200
                    app.setAdminState 'succes',"Maj #{update} réussie."
                when 403
                    app.setAdminState 'error403',"Authentifiez-vous à nouveau. Contactez la FCMQ si vous éprouvez des problèmes persistants."
                    log "got 403 error ", url
                when 304
                    app.setAdminState 'error304',"Aucun changement détecté. Modifez le chiffrier avant de faire la mise à jour."
                    log "got 304 error ", url                
                else
                    log "ERROR END maj #{action} got status #{status}"
                    app.setAdminState 'error',"Maj Erreur #{update}, erreur imprévue ##{status}, contactez la FCMQ."
                    errMess= await response.text()
                    app.setAdminState '',...["Maj Erreur #{update}, erreur imprévue ##{status}, contactez la FCMQ.",br(),errMess]
        catch err
            log "ERROR END maj #{action} got error #{err}",err
            app.setAdminState 'error'," Maj Erreur #{update}, erreur imprévue ##{status}, contactez la FCMQ."
            errMess= await response.text()
            app.setAdminState '',...[" Maj Erreur #{update}, erreur imprévue ##{status}, contactez la FCMQ.",br(),errMess]
        
    

postData= (url = '', data = {})->
    response= await fetch url,
        method: 'POST'
        mode: 'same-origin' # no-cors, *cors, same-origin
        cache: 'no-cache'   # *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin' # include, *same-origin, omit
        headers: 
            'Content-Type': 'application/json'
            #// 'Content-Type': 'application/x-www-form-urlencoded',
        #redirect: 'follow', // manual, *follow, error
        #referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify data # body data type must match "Content-Type" header
    text= await response.text()
    [text,response.status,response.url]
                
module.exports= { adminEditSheet, AdminEditSheet:  adminEditSheet }



