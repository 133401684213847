{ log, meta, oAssign, oShallow, isArray, $, Global, Mb }= vx= require 'vx/globals/Boot'  # to get a global ref to HelloWorld that survies webpack

{ createElement:cE}= require 'react'

require './AppIndex.css'

{ oEq }= oShallow() # o1 use Constant name space C  C.name?= instead

{ AppState }= require './AppState'

UI= require 'vx/UI'

# I18n
{ sayModule, getLang }= require 'vx/i18n/I18n'
say= sayModule 'App' # cheat use app for index and appIndex ModuleName

# UI layout
{ appWidget }= UI

{ div, tbIcon, button }= UI

{ MainSnackbarQueue }= UI

{ itinPage }= require 'vx/comp/itin/ItinWidgets' if vx.use.itin


{ mapPageFor }=     require './pages/MapPage'

{ adminPage }=      require './pages/AdminPage'      if vx.use.admin

{ adminEditSheet }= require './pages/AdminEditSheet' if vx.use.admin

{ paramPage }=      require './pages/ParamPage'      if vx.use.parametres

{ debugPage }=      require './pages/DebugPage'      if vx.use.debug

{ appData }=        require './AppData'

{ appHelp }=        require './AppHelp'



GMapWidgets=    require 'vx/map/gmaps/GMapWidgets'      if vx.mapMode.api is 'gmaps'
{ mapPage }=    mapPageFor GMapWidgets      if vx.mapMode.api is 'gmaps'

LeafletWidgets= require 'vx/map/leaflet/LeafletWidgets' if vx.mapMode.api is 'leaflet'
{ mapPage }=    mapPageFor LeafletWidgets   if vx.mapMode.api is 'leaflet'

{ appPannels }= require './AppPannels'

log "Loading App vx.isAPP=#{vx.isAPP}  vx.isAPI=#{vx.isAPI} vx.isBROWSER=#{vx.isBROWSER} isDEV=#{vx.isDEV}"

# iconito client in http patch 
localStorage= undefined
try
    localStorage= Global.localStorage
    lslen= localStorage.length
catch err
    localStorage= undefined
    


poolTime=   10*60 #  #seconds
snackDelay= 10 #4.5*60 # seconds # max is now 10s

r1000= (n)-> Math.round n/1000

Delais=
 graph:0.05
 attraits: 0.2
 message:5
 cond:2
 circuits:1
 

if vx.appBoot
    vx.logx "appFirstBoot"
    D=0.1
    D2=1
    Delais=
        graph:D
        attraits: D+1*D2
        message:  D+15*D2
        cond:     D+4*D2
        circuits: D+4*D2
    #log " !!!!!!!!!!!!!!!! appFirstBoot delais=",Delais
    
    
else if vx.isAPP
    Delais=
        graph:0.1
        attraits: 1
        message: 15
        cond:4
    #log " !!!!!!!!!!!!!!!! using app timming delais=",Delais


app=meta class App  extends AppState
    @displayName: 'App'
    # for kate

    C={} # Comstant name space


    constructor:(props)->
        log "App start of constructor"
        ret=super props
        #log "App end of constructor" #,{ret,self:@}
        return ret

    doToggleClickedAttraitId: (e)=>
        #log "click attrait icon capture #{e.target.id}",e
        e.preventDefault()
        @toggleSelectedAttraitId e.target.dataset.id
        null

    doToggleClickedParcoursId: (e)=>
        #log "click parcours  capture #{e.target.id}",e
        @toggleSelectedParcoursId e.target.dataset.id
        null

#     doBlockBackButton:->
#         document.addEventLister 'backbutton',(e)->
#             log "BackButton pressed"
#             e.preventDefault()



    snackbarMessage: (mInfo={})->
        #log "snackbar message snckbar=#{!!@snackbar}",mInfo
        {
            actionHandler #ignore
            actionText= say "OK"
            timeout= 1000*snackDelay  # en ms pooltime- 2min
            dismissesOnAction= true
            message=''
            actions
        }= mInfo
        
        return if !message?.trim()
        
        notify={
            title:message
            dismissesOnAction
            timeout:timeout
            }
        
        if actions
            notify.actions= actions
        else
            notify.actions= [ { title:actionText} ]
            
        MainSnackbarQueue.notify notify

    


    componentWillMount: ->
        #log "Index.componentWillMount"
        super()



    majMess: ->
        {graph,attraits}= @state
        
        g=!!graph?.nodes?.length
        a=attraits?.length
        #log "Maj mess #{g} #{a} graphFileInfo=",@state.graphFileInfo
        if !g or !a
             @snackbarMessage {message:@bootmess+" (#{++@mcnt})",actionHandler:null,actionText:'',timeout:3000}
             setTimeout @majMess,3000
        else
            @snackbarMessage {message:say "Terminé",actionHandler:null,actionText:'',timeout:1000}
            

    componentDidMount: ->
        #log "Index.componentWillMount"
        super()
        return if !vx.isAPP
        if vx.appBoot
             @bootmess= say "init encours" #"Un moment initialisation encours peu prendre un moment "
        else @bootmess= say "lecture encours" #"Un moment lecture encours peu prendre un moment "
        @mcnt=0
        @majMess= @majMess.bind @
        @majMess()


    doSetAppNode: (node)->
        #log "set AppNode to ",node
        @appNode= node

    menuBtn:undefined
        
    render: ->

        { loglist, counter }= @state or {}
        internet= rec= false
        meteo= @MeteoLayers
        app= @
        r6= (x)->Math.round x*1000000
        n6= (x)-> x.toFixed 6

        menuBtn= @menuBtn?= -> tbIcon 'icon=menu',onClick:app.toggleMenu

        log "  ****************************  App Got a render  for activePage=#{@getPage()} this=" if @state.debug
        #log " gpsPos=",@getGPSPos()

        { bkgSourceMode, bkgHybrideSplit, option_beta }= app?.state
        appWidget '.mdc-typography .mdc-typography--body1',pageId:@getPage(),className:@state.className, #tagRef:@doSetAppNode,


            appData # load non-visual data(ajax) compnents that 'update' app
                app: app
                conditionsUsed: app.state.conditionsUsed
                graphFileInfo:  app.state.graphFileInfo
                Delais: Delais
                poolTime:poolTime
                
            appHelp
                app: app
                deviceReady: @getDeviceReady()
                circuits: @state.circuits
                circuitsFilter: @state.circuitsFilter
                bkgMenuPosition: app.get 'bkgMenuPosition'
                graph:    @state.graph

            appPannels 
                key:'apppannels'
                app:  app
                menuVisible: @state.menuVisible
                message:     @state.message
                admin:       @state.admin
                lang:        getLang()
                AdminPage:   !!adminPage
                circuits:    @state.circuits
                circuitsDecorations: @state.circuitsDecorations
                circuitsListVisible: @state.circuitsListVisible
                circuitsFilter:      @state.circuitsFilter
                circuitsFiltreVisible: @state.circuitsFiltreVisible
                allListCircuits:     @state.classes.allListCircuits
                vxScreenWidth:       @state.vxScreenWidth
                graph:               @state.graph
                messageVisible: app.getMessageDialogVisible()
                termsOk:             @state.termsOk

            switch vx.mapMode.api
                    when 'gmaps'   then mapPage { app, id:'map', option_beta, HasGPS:false     , bkgMenuVisible:app.get('bkgMenuVisible') }
                    when 'leaflet' then mapPage { app, id:'map', option_beta, HasGPS:vx.use.gps, bkgMenuVisible:app.get('bkgMenuVisible'),bkgSourceMode, bkgHybrideSplit, }

            if vx.use.debug
                debugPage 
                    key:'debugpage'
                    reload: @state.reload
                    app:app
                    menuBtn:@menuBtn
                    

            adminPage {key:'adminpage',app,id:'admin',menuBtn} if adminPage? and vx.uiMode.admin and app.state.admin
            
            adminEditSheet {key:'admineditsheet',app,id:'admin-edit-sheet',menuBtn} if adminEditSheet? and vx.uiMode.admin and app.state.admin

            itinPage { key:'itinpage',id:'itin',app, itin:app.getItin(),i18nInfo:@state.i18nInfo, useMenu:vx.mapMode.menu, menuBtn, title:( say "titre page itin" ),textRetour:(say 'btn retour carte'),option_beta} if itinPage and vx.use.itin


            if vx.use.parametres
                paramPage 
                    id: 'parametres'
                    key: 'parametres'
                    zoom:          app.getMapZoom()
                    graphFileInfo: app.state.graphFileInfo
                    attraitsFileInfo: app.state.attraitsFileInfo
                    circuitsFileInfo: app.state.circuitsFileInfo
                    deviceReady:   app.getDeviceReady()
                    width:         app.state.screenWidth
                    height:        app.state.screenHeight
                    networkState:  app.state.networkState
                    bkgMode:       app.state.bkgSourceMode
                    beta:          app.state.option_beta
                    refresh:       (app.state.activePage is 'parametres') and (lt isnt lt=(new Date()) % 5000)
                    menuBtn: menuBtn
                    app:app



module.exports= { App, app}


