{ log, setTimeout2, isNODE }= vx= require 'vx/globals/Boot'

#log "Loading Gservices for gDirsP isNODE=#{isNODE}"

require 'vx/tools/NamedPromises' # for google router

if isNODE
    googleMapsServer= require '@google/maps'


rate=4
rateDelayMS= 1000/rate
rateMultiplier= 2

LastQuery= 0

if isNODE
    googleMapsClient= googleMapsServer.createClient
        key: 'AIzaSyAHcjacP8vCidNxIgx2dKULLLr-p5fNDx8' # serverG was inf fcmq3.server 'AIzaSyApo7Do2gytQOCoNLuPgvPHAAYf8Y8ZynI'
        Promise: Promise
    gDirsP= (request)->
        googleMapsClient.directions request
        .asPromise()
        .then (resp)->resp.json


else # Browser
    GDirsP= Promise.vxGet('gmaps').then (gmaps)-> new gmaps.DirectionsService()
        #log " GoogleRouter created dirs service=",gdirs
    makeGDirsP= (request,gdirsService,rateMS=rateDelayMS)->
                new Promise (resolve,reject)->
                    NextQueryOk= LastQuery+rateMS
                    wait= Math.max 0,NextQueryOk-now= (new Date).getTime()
                    LastQuery= now + wait
                    log "makeGDirsP waiting #{wait}ms" if wait>0
                    setTimeout2 wait,->
                        gdirsService.route request, (response, status)->
                            if status == 'OK'
                                resolve response
                            else
                                log "google server got ",{response,status}
                                reject {status,response} if status isnt 'OVER_QUERY_LIMIT'
                                log "OVER_QUERY_LIMIT at rate #{rateMS}ms"
                                resolve makeGDirsP request,gdirsService,rateMS*rateMultiplier


    gDirsP= (request)->GDirsP.then (gdirsService)-> makeGDirsP request,gdirsService

#gDirsP= (req)->GDirsP.then(fnc)-> fnc req

#log " ------------------------------------- gDirsP=",gDirsP

module.exports= { gDirsP }
