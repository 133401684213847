{ meta, log, a2map, sList, oAssign, oSet, oDiffs, oShallow, beginingSeconds }= Mod= vx= require 'vx/globals/Boot'
ModuleName= 'MessageUI'
wait= (fn)-> setTimeout fn,500

{ oEq }= oShallow()

UI= require 'vx/UI'

# I18n
{ sayModule, getLang, autoHTML }= require 'vx/i18n/I18n'
say= sayModule ModuleName

# UI layout
{ mDialog, p, table, tbody, tr, td, br, button, icon, span, div, a, checkbox }= UI



class MessageStateManager


    defaultState:
        messageDialogVisible: false
        messageLastOkId: 0
        messageInfo: null
        message:''
        messageMode:1
        termsOk: 0

    persistantState: { messageLastOkId:1, termsOk:1 }


    setMessage: (message)->
        #log "Setting message to #{message}",message
        @setState message:message


    setMessageInfo: (messageInfo)->
        #log "Setting messageInfo to #{messageInfo}",messageInfo

        @setState
            messageInfo: messageInfo
            messageDialogVisible: messageInfo.LastModified isnt @state.messageLastOkId


    setTermsOk: (val)->
        log "set terms to #{val}"
        @setState termsOk:val

    setMessageDialogVisible: (val)->
        #log "setMessageDialogVisible tp #{val}"
        if !val
            @setState
                messageDialogVisible: false
                messageLastOkId:@state.messageInfo?.LastModified or @state.messageLastOkId
        else
            @setState  messageDialogVisible:false

    doMessageDialogPersistentClose: ->
        log "doing doMessageDialogPersistentClose #{@state.messageInfo?.LastModified}"
        @setState messageLastOkId:@state.messageInfo?.LastModified

    doMessageDialogClose: -> 
        log "doing doMessageDialogClose #{@state.messageInfo?.LastModified}"
        @setState messageDialogVisible: false

    getMessageDialogVisible:      -> !@state.termsOk or @state.messageDialogVisible


    doShowMessage: ->
        @setState messageDialogVisible:true 



messageDialog= (props)->

    { app,message,visible,termsOk }= props
    return null if !message

    isDisabled= !termsOk

    if  getLang() is 'fr'
         { titre_fr:titre='', text_fr:text='', btn_ok_fr:btn_ok="OK", btn_annuler_fr:btn_annuler='',url_legal_fr:url_legal='https://fcmq.qc.ca/download_file/1658/'}= message or {}
    else { titre_en:titre='', text_en:text='', btn_ok_en:btn_ok="OK", btn_annuler_en:btn_annuler='',url_legal_en:url_legal='https://fcmq.qc.ca/download_file/1657/'}= message or {}
    mDialog '#messageDialog',
        #debug:1
        disabled: isDisabled
        debug:true
        title: [titre,div '',
            checkbox '',onNewValue:app.setTermsOk,value:1,offValue:0,checked:!isDisabled,
                span '.vx-label__responsive .mdc-typography--subtitle1 ',say "text accept conditions d'utilisation debut"
            a '.vx-label__responsive .mdc-typography--subtitle1 target=fcmqterms',{href:url_legal},say "lien conditions d'utilisation"
            #span '.vx-label__responsive .mdc-typography--subtitle1 ',say "text accept conditions d'utilisation fin"
            ]
        visible: visible
        onOk: app.doMessageDialogPersistentClose
        onClose: app.doMessageDialogClose
        #onValue:  app.setMessageDialogVisible
        preventOutsideDismiss: !!isDisabled
        cancel: btn_ok or "OK" if btn_ok or !btn_annuler
        ok: btn_annuler?.trim() if btn_annuler
        p '',dangerouslySetInnerHTML: __html: autoHTML text
        





module.exports= { MessageStateManager, messageDialog }

