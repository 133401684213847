{ meta, log, sList, sSet, oA, nr, time:getTime }= Mod= vx= require 'vx/globals/Boot'
{ oDiffs, oAssign, time:getTime, isPrefix, aSum, aPairs, aLast, sSplit, sSplit1 }= Mod

require './ConditionsUI.css'

{ shortDate2 }= require 'vx/tools/DateUtils'

wait= require 'vx/tools/Wait'

PR= require 'vx/tools/Persistance'

{ getV2EncodedState, getV1EncodedState }= require 'vx/tools/URLencoders'

{ UIComponent, frag }= UI= require 'vx/UI'

{ table, tbody, th, tr, td, span, form, button, group, div, a, strong, p, img, h5, h6, br, hr, i:italic, b:bold }= UI

{ menu, listItem, detailStart, detailText }= UI
{ path, svg, use }= UI

{ mapPopup }= require 'vx/map/MapPopup' # to avoid dependecies on MapWidgets so gmaps & leaflet can live together

{ templateWidget } =require 'vx/templates/TemplateWidgets'
#require 'vx/templates/condition-info.css'
require 'vx/templates/condition-hover.css'


# I18n
{ sayModule, say, getLang, autoHTML }= require 'vx/i18n/I18n'
appSay=  sayModule 'App' #dont refactor all transaltions



{ LineStr }= require 'vx/math/LineStr'
{ Bounds }= require 'vx/math/Bounds'

{ asCondClass, condFiltre, condIsAlert, asCondClassFromCond, calcCondAfter, reg2nomFr, reg2nomEn }= require 'vx/globals/Conversions'

{ abs }= Math

C={} # Constant name space



if vx.isAPP
    svgIcon= (props)->
        svg '.svgIcon',props, use href:"css/alerts/icons.svg##{props.icon}",xlinkHref:"css/alerts/icons.svg##{props.icon}"
else
    svgIcon= (props)->
        svg '.svgIcon',props, use href:"css/alerts/icons.svg##{props.icon}",xlinkHref:"css/alerts/icons.svg##{props.icon}"

divIcon= (props)->
    #svg '.svgIcon',props, use href:"css/alerts/icons.svg##{props.icon}",xlinkHref:"css/alerts/icons.svg##{props.icon}"
    div '.svgIcon',props


conditionPathSVG= meta class ConditionPathSVG extends UIComponent

    #props
    #  condition: {Condition}
    #  className: string
    #  map: {Map}
    #  graph: {Graph5}

#     getLineStr: -> @_lineStr?= @makeLineStr() # TODO regen if this changes
#         #@makeLineStr()


    getSVGLatMPts: (zoom)->

        switch
            when zoom<10
                 @_linePts8?= ( (LineStr._ p,@props.lineStrOps).asSVGLatMPts() for p in @props.condition.pts8 )
            when zoom<13
                 @_linePts12?=( (LineStr._ p,@props.lineStrOps).asSVGLatMPts() for p in @props.condition.pts12 )
            else @_linePts?=  ( (LineStr._ p,@props.lineStrOps).asSVGLatMPts() for p in @props.condition.pts )


    render: (props=@props)->

        { deco,condition,zoom }= props #graph
        #return null if !graph

        cName=['condition-path',"condition-path__cond-#{asCondClass condition.cond}"]
        cName.push @props.ClassName if @props.ClassName


        hasDeco= false
        for decoName,v of deco or {} when v
            hasDeco= true
            cName.push decoName

        className= cName.join ' '
        #log "rendering #{condition.id} className=#{className}"
        path
            className:className
            id:"pl_#{@props.condition.id.replace /\./g,'_'}"
            #'.selected':deco?.selected
            d: ( 'M '+svgpts for svgpts in @getSVGLatMPts zoom).join ' ' #if hasDeco then @getSVGLatMPts() #?.asSVGLatMPts() else ''


    zoomCondition:(zoomMode)->
        bnds= Bounds.BNDS @props.condition.bounds
        @props.app?.fitMapBounds bnds

    componentWillMount: ->
            if @props.zoomMode # Wicked, it here becuase we know we have to zoom before the map is loaded ... TODO setWantedBounds on map ....
                @zoomCondition @props.zoomMode




conditionsSVGLayer= meta class ConditionsSVGLayer extends UIComponent


    render:(props=@props)->

        {conditions,decos,zoom,graph,zoomMode,app,MapWidgets,gmapMap,leafletMap,id='conditionssvg',zIndex,visible,after}= props

        { mapSVG, mapHTML }= MapWidgets

        #log "ConditionsSVGLayer.render mapSVG=#{typeof mapSVG} mapHTML=#{typeof mapHTML}"

        if after
            col= conditions.length
            conditions= condFiltre conditions,after
            # log "ConditionsSVGLayer filter from #{col} to #{conditions.length}"


        d=0

        mapSVG '.conditionssvglayer',
            gmapMap: gmapMap
            leafletMap: leafletMap
            id: id
            # bounds= 51.13286649118823,-76.1493193816871 54.505918103161974,-73.7303911836244  " -->
            topl:    d+  50.5
            bottoml:-d+  45
            left:   -d+ -79.9
            right:   d+ -62.8

            zIndex: zIndex
            visible: visible
            zoom: zoom
            for c in conditions #when (cdeco= decos[c.id]) and (cdeco.visible or cdeco.selected)
                cdeco= decos[c.id]
                conditionPathSVG
                    key: c.id
                    condition: c
                    deco: cdeco
                    #graph: graph
                    app:app # its a constante ...
                    zoomMode: zoomMode if cdeco?.selected
                    zoom: zoom



conditionsMarkerLayer= meta class ConditionsMarkerLayer extends UIComponent


    render:(props=@props)->

        {conditions,decos,MapWidgets,gmapMap,leafletMap,id='1',setHoverId,onMarkerClick,zIndex,visible,after}= props

        { mapSVG, mapHTML }= MapWidgets

        # log "ConditionsMarkerLayer.render mapSVG=#{typeof mapSVG} mapHTML=#{typeof mapHTML}"

        if after
            col= conditions.length
            conditions= condFiltre conditions,after
            #log "ConditionsMarkerLayer filter from #{col} to #{conditions.length}"

        d=0
        # log "ConditionsMarkerLayer render setHoverId is #{!!setHoverId}"
        mapHTML '.conditionsmarkerlayer .leaflet-zoom-hide',
            gmapMap: gmapMap
            leafletMap: leafletMap
            id:"conditions-icons#{id}"
            visible: visible
            top:47.1,bottom:47,left:-70,right:-55,
            zIndex: zIndex
            debug: false
            for c in conditions when c.iconPos and c.icon isnt 'aucun' #when (cdeco= decos[c.id]) and (cdeco.visible or cdeco.selected)
                conditionMarker
                    condition: c
                    decos:   decos
                    onMouseEnter: @doStartHover if setHoverId
                    onMouseLeave: @doEndHover   if setHoverId
                    onClick:      @doClick      if onMarkerClick



    doStartHover: (e)->
        id= e.target?.dataset.objid
        #log "ConditionsMarkerLayer doStartHover id=#{id} setHoverId=#{typeof @props.setHoverId}"
        return if !id or !@props.setHoverId
        @props.setHoverId id,1

    doEndHover: (e)->
        id= e.target?.dataset.objid
        #log "ConditionsMarkerLayer doEndHover id=#{id} setHoverId=#{typeof @props.setHoverId}",f:@props.setHoverId
        return if !id or !@props.setHoverId
        @props.setHoverId id,0

    doClick: (e)->
        id= e.target?.dataset.objid
        if !id and e.target
            id= e.target.closest('[data-objid]')?.dataset.objid
        #log "conditionsMarkerLayer got a click id=#{id}",{e,closest:e.target.closest('[data-objid]')}
        return if !id or !(typeof @props.onMarkerClick is 'function')
        @props.onMarkerClick id



conditionMarker=(props)->
    { condition, decos={},onMouseEnter, onMouseLeave, onClick,ops }= props
    {id,Titre,membre}= condition
    cdeco= decos[id]
    selected= cdeco?.selected
    pos= condition.iconPos
    cName= ['condition-icon','mdc-elevation--z4',"condition-icon__cond-#{asCondClass condition.cond}","condition-icon__type-#{(condition.icon or 'none').toLowerCase()}"]
    for decoName,v of cdeco or {} when v
        cName.push decoName

    if !selected
        marker= divIcon
            key:  "#{id}-icon"
            id:   "#{id}-icon"
            icon: (condition.icon or 'none').toLowerCase() #condition.icon or 'none'
            className: cName.join ' '
            position:
                bottom: pos.lat
                left:   pos.lng
            'data-objid': id
            'data-nom': Titre
            'data-region':membre
            onMouseEnter: onMouseEnter if onMouseEnter
            onMouseLeave: onMouseLeave if onMouseLeave
            onClick:      onClick      if onClick


        return marker if !(cdeco?.hover == 1)

        lang= getLang()
        desc=[]
        if condition?
            if lang is 'en'
                {desc_en:d,desc2_en:d2,dateMaj}= condition
            else
                {desc:d,desc2:d2,dateMaj}= condition

            #log "d=#{d} et d2=#{d2}"
            if d or d2
                cdeco= oA {},cdeco,hasDesc:1


        ret= [ marker ,
        templateWidget '.condition-icon__hover',
            componentClassName: 'condition-hover'
            key: "#{id}-hover"
            id:  "#{id}-hover"
            obj: condition
            #debug: 1
            deco: cdeco
            ops: ops
            say: props.say or say
            $zIndex: 99999999
            position:
                bottom: pos.lat
                left:   pos.lng
        ]
        #log "marker hover=",ret
        ret
    else
        mapPopup 'elevation=8',
            key: "#{id}-popup"
            id:  "#{id}-popup"
            onClick: onClick # should be a toggle
            active: true
            _setClass: 'condition-icon__popup'
            'data-objid': condition.id
            position: {top:pos.lat,left:pos.lng }
            $zIndex: 99999997
            div '$padding:10px',
                condPtHTML
                    condition:condition
                    status:'ok'
                    mode:'alone'



condPtDetails= meta class CondPtDetails extends UIComponent

    @propTypes:
        pt:      @type.object
        maxTime: @type.number
        infoURL: @type.string
        graph:   @type.object
        debug:   @type.bool
        onlyFermer: @type.bool


    constructor: (props)->
        super props
        @state=
            time: null
            veh: null
            status:'idle'
        #log "SurfManager.condPtDetails.constructor"
        #condPtDetails
        return @


    componentWillMount:->

        #log "SurfManager.condPtDetails.constructor this=",@
        {pt}= @props
        @fetchPtInfo pt


    componentWillReceiveProps: (newProps)->

        #check for pt change
        pt= @props.pt
        npt= newProps.pt
        return if (pt is npt) or ( pt and npt and pt.x==npt.x and pt.y==npt.y)


        #ok changing
        #clear info
        @setState
            statuts: if npt then 'waiting' else 'idle'
            time: null
            veh:  ''


    componentDidUpdate: (oldProps)->

        #check for pt change
        pt= @props.pt
        opt= oldProps.pt
        # return if no change
        return if (pt is opt) or ( pt and opt and pt.x==opt.x and pt.y==opt.y)
        return if !pt #status should be idle
        #ok got a new pt
        #clear info
        @fetchPtInfo pt


    fetchPtInfo: (pt,props=@props)->
        self= @
        { infoURL }= props
        url="""#{infoURL}&x=#{pt.x}#{if pt.y? then "&y=#{pt.y}" else ''}#{if pt.ym? then "&ym=#{pt.ym}" else ''}#{if pt.z? then "&#{pt.z}" else ''}"""
        #log "SurfManager.condPtDetails.fetchPtInfo url=#{url}"
        fetch url
            .then (response)-> response.json().then (data)->
                # test still requires
                #log "log SurfManager.condPtDetails.fetchPtInfo.fetch.then pt=#{pt} self.props.pt=#{self.props.pt} not same is #{pt isnt self.props.pt} data=",{data,pt,cpt:self.props.pt}
                #return if pt isnt self.props.pt
                # do we have a race?
                self.setState
                    status:'done'
                    data: data


    render:(props=@props,state=@state)->
        {data,status}= state
        if props.onlyFermer and (!condIsAlert(data?.extra) and !data?.seg?.data?.f)
            #log "onlyFermer ignore data=",data
            return ''
        frag '',hr(),
            condPtHTML { mode:props.mode,condition:data?.extra,status,data}




condPtHTML= meta class CondPtHTML extends UIComponent

    @propTypes:
        status:    @type.string
        condition: @type.object
        data:      @type.object
        debug:     @type.bool


    render:(props=@props)->
        {condition,status,mode,data}= props
        #log "CondUIManager.condPtDetails.render condition=#{!!condition} status=#{status}"
        return div '','' if !status or status is 'idle'
        return div '',appSay "mess attent serveur contitions" if status is 'waiting'
        return div '',appSay "mess pas info conditions" if !condition
        #log "CondPtHTML.render conditionb=",condition if condition
        lang= getLang()
        if data?.seg?.data?.f
            # Seg is fermer
            return div '.condpt',
                div '.condpt__text',say "mess fermer par fcmq"
        if !condition
            return div '.condpt',
                div '.condpt__text',say "mess pas info conditions"

        desc=[]
        if condition?
            if lang is 'en'
                {desc_en:d,desc2_en:d2,dateMaj}= condition
            else
                {desc:d,desc2:d2,dateMaj}= condition
            desc.push d2   if d2
            desc.push '<hr />' if  d2 and d
            desc.push d  if d
            desc.push " (#{say 'asDate',new Date dateMaj*1000})" if dateMaj

        condCls= asCondClassFromCond condition

        log " condPtHTML desc=",{desc,condition,minDate:calcCondAfter(),condCls}

        div '.condpt',className:(if mode then "condpt--#{mode}"),
            button '.condpt__close-btn flat icon=cancel'
            h5 '.condpt__title .mdc-typography--subheading2',
                div ".condition-icon .condition-icon--inline .condition-icon__cond-#{condCls} .condition-icon__type-#{condition.icon or 'none'}"
                "#{if lang is 'en' then condition?.Titre_en else condition.Titre}"
            if condition.cond and condCls isnt 'aucun'
                h6 '.condpt__sub-title .typo .mdc-typography--subheading2',
                    """#{appSay 'Conditions:'} #{appSay "cond-#{if condition.dateMaj and condition.dateMaj < calcCondAfter() then 'Non disponible' else condition.cond}"}"""
            div '.condpt__text',dangerouslySetInnerHTML: __html: autoHTML desc.join ' '
            if condition.encodedState
                a '.condpt__itin target=_blank', href:"//test.fcmq.fcmqapi.ca/i?s=#{condition.encodedState}&r=2","Itinéraire de la section"
            if condition.membre
                span '.mdc-typography--subheading',appSay "cond source",condition.membre




meta class ConditionInfo extends UIComponent


    render: (props=@props)->
        { onClose:doClose, conditionId, app, deco, ops }= props
        obj= app.getConditionId conditionId
        templateWidget
            componentClassName: 'condition-info'
            obj: obj
            deco: deco
            ops: ops
            container: @
            say: props.say or say

    doClose: (e)-> @props.onClose e if @props.onClose

    doNavigate: (e)->
        log " got a cicuit info navigate ",e

    doZoomToCondition: ->
        {app,conditionId}= @props
        condition= app.getConditionId conditionId
        if app.state.vxScreenWidth isnt 'wide'
            app.setConditionSelectedId ''
            app.toggleConditionsList() if app.state.conditionsListVisible
            setTimeout (->app.zoomConditions [condition]),400 # wait for windows to close
        else
            app.zoomConditions [condition]

    editCondition: (obj,ops)->
        ops.e?.preventDefault()
        log "edit condition doin preventDefault ",{obj,ops}
        window.location= window.location.origin+window.location.pathname+"?r=1&s=#{obj.encodedState}"



module.exports= { conditionsSVGLayer, conditionsMarkerLayer, condPtDetails }
