{ log, Mb, $, Global }= vx= require 'vx/globals/Boot'  # to get a global ref to HelloWorld that survies webpack

{ dateAsVersionNumber }= require '../Ajax'

# I18n
{ sayModule }= require 'vx/i18n/I18n'
say= sayModule 'App' # refactored

UI= require 'vx/UI'
# UI layout
{ page, header2, tbTitle, content, ul, li }= UI

# html inline
{ span }= UI

#htmlctrls
{ button, checkbox, radio, radioGroup, details, summary, icon }= UI


C={}

ParamPage= (props={})->
    {active,id,zoom,deviceReady,graphFileInfo,networkState,attraitsFileInfo,circuitsFileInfo,bkgMode,beta,menuBtn,app}= props

    page '#parametres key:parametres',{active},
        header2 '',
            left: [ (menuBtn {key:'menu'}),tbTitle {key:'title'},say "titre page parametres" ]
            right: tbTitle null,button '#paremetres-btn-carte href=#map',(span '.vx-hide-phone',(say 'btn retour carte')),(icon '|arrow_forward')


        content '.page__content-margin .mdc-typography--body1',
            if deviceReady
                if  graphFileInfo
                    {LastModified,responseTime}= graphFileInfo
                    ageG= if responseTime? then (new Date)-responseTime
                    versionG= dateAsVersionNumber LastModified
                if  attraitsFileInfo
                    {LastModified,responseTime}= attraitsFileInfo
                    ageA= if responseTime? then (new Date)-responseTime
                    versionA= dateAsVersionNumber LastModified
                if  circuitsFileInfo
                    {LastModified,responseTime}= circuitsFileInfo
                    ageC= if responseTime? then (new Date)-responseTime
                    versionC= dateAsVersionNumber LastModified
                ul '.mdc-typography--body1',
                    li '',"Version: ",app.state.version
                    li '',"Zoom: #{zoom}"
                    li '',"#{say 'Version des sentiers:'}#{versionG} #{say 'verifier temps',{temps:ageG}}" if  graphFileInfo
                    li '',"#{say 'Version des attraits:'}#{versionA} #{say 'verifier temps',{temps:ageA}}" if  attraitsFileInfo
                    li '',"#{say 'Version des circuits:'}#{versionC} #{say 'verifier temps',{temps:ageC}}" if  circuitsFileInfo
                    li '',"#{say 'État reseau:'}#{ say networkState}"
                    if vx.isAPP
                        li details 'open',
                            summary '',(say "Mode fond ecran"),say "bkgMode#{bkgMode}"
                            radioGroup {className:'group--vert',value:bkgMode,onNewValue:C.bkgModeFn?= (mode)->app.setState {bkgSourceMode:parseInt mode}},
                                radio {value:0},say "bkgMode0"
                                radio {value:1},say "bkgMode1"
                                radio {value:2},say "bkgMode2"
                    li details '',
                        summary '',(say "Options")
                        checkbox '',onNewValue:app.setOption,value:1,offValue:0,checked:beta,name:'beta',
                            span '',say "Mode beta, à vos risques!"
                    li details '',
                        summary '',say "Détails techniques:"
                        ul '.mdc-typography--body1',
                            li '',"Agent: #{navigator?.userAgent or 'node?'}"
                            li '',"Platform: #{navigator?.platform or 'node?'}"
                            li '',"GMaps: #{Global.google?.maps?.version}"
                            li '',"Classes: #{($ 'html').className}"

                            li '',"Data url: #{vx.urls.DataURL}"
                            li '',"Tile url: #{vx.urls.TileURL}" if vx.urls.TileURL
                            li '',"Surf url: #{vx.urls.SurfServerV2}"
                            li '',"Share url:#{vx.urls.ShareURL}"
                            (li '',button 'flat href=#debug', say "show ls info" ) if vx.isDEV
            else "deviceready?"

module.exports= { ParamPage, paramPage:ParamPage }
