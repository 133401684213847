{ meta, log, a2map, sList, oAssign }= Mod= vx= require 'vx/globals/Boot'

{ Attrait, AttraitStateManager:SuperAttraitStateManager }= require './Attraits'

OrderedGroups= [
 'Hôtel sécuritaire'
 'Bed and Breakfast'
 'Hébergement'
 'Restaurant'

 'Forfait'

 
 'Concesionnaire'
 'Essence'
 'Location motoneige'
 'Relais'
 'Refuge'

 'Réparation mécanique'
 'Stationnement'

 'Repos'
 'Bar'
 'Incitatif'

 'Sites et attraits'
 'Point de vue'


 'Mobilité restreinte'
 'Club'

 'Halte'
 'Information'
 'Ravitaillement'
 'Autres'
 'Bornes évacuation'
 ]

class AttraitStateManager extends SuperAttraitStateManager

    groupAttrib: 'sourceCat'

    defaultState: oAssign {},@::defaultState,
        layer_pmnGraph__visibility:  true
        visibleLayers:['pmnGraph']


    persistantState:
            a2map ( "layer_#{name}__visibility" for name in ['pmnGraph'] ),oAssign {},@::persistantState

module.exports= { Attrait, AttraitStateManager, OrderedGroups }
