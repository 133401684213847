{ log,  $ }= vx= require 'vx/globals/Boot'


{ createElement:cE }= require 'react'


require 'vx/map/MapPopup.css'

{ Bounds }= require 'vx/math/Bounds'
{ latM }= require 'vx/math/MapGeometry'

{ ElementFactory:EF, useEffect2 }= require 'vx/ui/ReactElemSS'



arrow= EF ({side,...props})->
    props.className= "arrow arrow--#{side or 'top'} #{props.className or ''}"
    cE 'div',props

### CSS:
.arrow {
    box-sizing: content-box;
    width: 0;
    height: 0;
    border: 5px solid transparent; 
    font-size: 0;
    line-height: 0;
    z-index:1 ;
    }
.arrow--top    { border-top-color:   white }
.arrow--right  { border-right-color: white }
.arrow--bottom { border-bottom-color:white }
.arrow--left   { border-left-color:  white }
###



mapPopup= EF ({active,children,flat,elevaton,...props})->

    {app,pos,id,'data-id':dataid}= props

    useEffect2 [active,pos,id,app],([active,pos,id,app])->
        return if !active
        if !id or !pos or !app
            log "mapPopup effect missing required",{id,pos,app}
            return
        node= $ "##{id}"
        if !node
            log "mapPopup effect no node"
            return

        style= getComputedStyle $ '.map-popup__inner',node
        width=  parseFloat style.width
        height= parseFloat style.height

        if app.leafletMap
            #leaflet margin patch out of time
            width+=  32
            height+= 56

        bnds= Bounds.PTS {x:pos[1],y:pos[0]}
        app.includeMapPopupBounds id,bnds,[height,width/2,0]
        undefined # effects return noting or undo effect

    props.className= "map-popup map-popup--#{if active then 'active' else 'not-active'} #{props.className}"
    cE 'div',props,
        arrow '.map-popup__arrow'
        cE 'div',{className:'map-popup__center'},
            cE 'div',
                className:"map-popup__inner mdc-elevation--z#{elevation ? ( if flat then 0 else 4)}"
                children:children

### CSS:

.map-popup {
    position:absolute;
    width:0;
    height:0;
    }
    
.map-popup.map-popup--not-active { display: none }

.map-popup__center {
    position: absolute;
    bottom: 5px; 
    pointer-events: none;
    }

.debugcss  .map-popup__inner {  border: 1px solid red ;}


.map-popup__inner {
    background-color:white ;
    margin-top:   0 ;
    margin-right:   50% ;
    margin-bottom: 0 ;
    margin-left:   -50% ;
    pointer-events: auto;
    }

.map-popup__inner {
    min-width: 20em;
    }


.map-popup__inner header.mdc-toolbar  a {
    color: var(--mdc-theme-text-primary-on-primary, white)
    }

.map-popup .map-popup__inner .material-icons { 
    margin:   4px;
    position: static;
    }

.map-popup  .mdc-toolbar .mdc-toolbar__section--align-end {
    flex: 0 0 auto;
    }
    

.gmap-map .map-html .map-popup .mdc-toolbar__row .material-icons,
.leaflet-map .map-html .map-popup .mdc-toolbar__row .material-icons
{
    margin-left:4px;
    margin-top:4px;
    position:static;
    }


    
###

module.exports= {mapPopup}


