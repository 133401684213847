### eslint-disable ###
ModuleName= 'Boot'


log= console.log.bind console #not working on Safari

log "Start loading Boot.js"

Global= switch
    when globalThis? then globalThis
    when window? then window
    when global? then global
    else throw new Error "No Global Name Space"

vx= Global.vx?= {}

#TODO all globals in vx -> isXXXX everywhere


Global.isBROWSER= vx.isBROWSER?=  ( process?.env.APP_ENV is 'browser' or Global.window? )
Global.isNODE=    vx.isNODE?= !vx.isBROWSER
Global.isAPP= vx.isAPP?= false
Global.isAPI= vx.isAPI?= false

if !vx.isNODE
    if Global.module
         log "DANGER Global.module exists"
    else Global.module= false
    log "============= did window setup document=" #,document
else #Node setup
    Global.window?= Global # some wierd module thinks its in browser on node
    Global.document= {}
    log "============= did node stup document=" #,document

if Global.process?
    #node
    Global.isDEV= vx.isDEV= process.env.NODE_ENV isnt 'production'
else
    #browser
    loc= Global.location
    
    isDEV=  (loc.hostname is 'localhost') or (loc.hostname[0..3] is 'dev-')
    log " boot isDEV= #{isDEV} browser mode #{loc.hostname}"
    Global.isDEV= vx.isDEV= isDEV

Global.isDESKTOP= vx.isDESKTOP= true
Global.isMOBILE=  vx.isMOBILE=  false # true # phone or tablet



round = Math.round # was: {round}=

if Object.assign
     oAssign= Object.assign
else oAssign= (target)->
    for i in [ 1 ... arguments.length ]
        for own k,v of (arg=arguments[i]) when arg
            target[k]= v
    target

#document?.currentScript?= {}

#console.time 'boot' 


oAssign vx,
    # *************** Utilitaires     ******************
    #Just o not require it everywhere
    Global: Global

    log: log
    before: (str)-> str # log "Before #{str}"; str
    logx: -> # NOOP
    timeStart: -> #if vx.isDEV then console.time.bind    console else ->
    timeLog:   -> #  if isDev then console.timeLog.bind console else ->
    timeEnd:   -> # if isDev then console.timeEnd.bind console else ->
    
    #log: ->console.log.apply console,arguments
    time: time= (date) -> date?=new Date(); date.getTime()
    beginingTime: bt= (new Date(Date.UTC(2010,0))).getTime()
    beginingSeconds: -> Math.round (time()-bt)/1000
    asJSON: (o,n)-> n?=2;JSON.stringify o,0,n

    setTimeout2:  (time,fnc)-> setTimeout  fnc,time
    setInterval2: (time,fnc)-> setInterval fnc,time

    #minimal Dom so as not to require DOMUtils2 to often
    $: $= (sel,target)-> target?=document; target.querySelector sel
    $$:(sel,target)->    target?=document; target.querySelectorAll sel
    On: (elem,name,ops,cb)-> cb?=ops; (if typeof elem is 'string' then $(elem) else elem ).addEventListener name,cb,( ops if ops isnt cb )

    getProperty: getProperty= (node_style,proprtyName)->
        if typeof node_style is 'string'
            node_style= $ node_style

        if node_style?.nodeName
            node_style= getComputedStyle? node_style

        if !node_style?.getPropertyValue?
            return ''

        ret= node_style.getPropertyValue? proprtyName

        #log "getProperty  #{proprtyName} got #{ret} from style=",{node_style,proprtyName}

        ret
        
    cssStrParse: cssStrParse= (txt)->
        return '' if !txt or txt is 'none'
        txt2= txt.trim()
        if txt2[0] is "'"
            # eh firefox?
            txt3= '"'+txt2.replace(/\\/g,'').replace(/\n/g,'\\n').replace(/"/g,'\\"')[1...-1]+'"'
        else txt3= txt2
        #vx.txt= txt3
        #log "js parse txt=#{txt3}",{txt2,txt,txt3}
        try ret= JSON.parse txt3
        catch e
            log "Error parsing cssStr txt=#{txt} txt3=#{txt3}",{e,txt,txt3,txt2}
        #log "JSON parse got '#{ret[0..20]}'"
        ret

    getPropertyText: getPropertyText= (node_style,proprtyName)-> cssStrParse getProperty node_style,proprtyName


    meta: meta= (cls,module)->
        if cls.meta
            log "calling cls.meta=",{cls,meta:cls.meta,module} if typeof cls.meta isnt 'function'
            cls.meta module
        else defMeta cls # was else alloc.bind cls

    defMeta: defMeta= (cls)->
        # coffee v2
        for name in ( oOwnPropNames cls::) when typeof (val=cls::[name]) is 'function'
        #for own name,val of cls::
            switch
                when isSuffix name,'__get'
                    Object.defineProperty cls::, name[...-5], {get:val, configurable: yes}
                    delete cls::[name]
                when isSuffix name,'__set'
                    Object.defineProperty cls::, name[...-5], {set:val, configurable: yes}
                    delete cls::[name]
        cls



    type : type= {}.toString
    isObject: (object)-> type.call(object) is "[object Object]"
    isArray: isArray= Array.isArray or (object)-> type.call(object) is "[object Array]"
    isObj: (obj)-> typeof obj is 'object' and !isArray obj
    isNumber: (num)-> ! isNaN parseFloat num

    # **************** Array functions ***************************


    arrSlice: arrSlice= Array::slice
    aSlice: (idx,arrLike)-> arrSlice.call arrLike,idx
    aLast: aLast= (arr)-> arr[arr.length-1]
    arr: -> arrSlice.call arguments,0 # return arguments as Array tempting to just return arguments but not sure of side effects

    aPairs: (arr)-> ( [arr[i],arr[i+1]] for i in [0 .. arr.length-2] by 1 )

    a2map: a2map= (arr,map,i)->
        map?= {}
        i?= 0
        for k in arr
            map[k]=++i
        map

    aFlaten: (arr,i)->
        i?= 0
        while i<arr.length
            if isArray arr[i]
                arr= [].concat.apply [],arr
            else i++

        arr

    aSum: (arr)-> arr.reduce ((a,b)->a+b),0

    # *********** Object Functions *********************

    oKeys:   Object.keys
    oKeysSort: (o,s)-> Object.keys(o).sort s
    oNew: (fn,newO)->
        newO?= {}
        if typeof fn is 'functon'
            fn.call newO
        else
            newO[pair[0]]=pair[1] for pair in fn when pair[1]?
        newO

    oSet: (obj,k,v)->
        return null if obj[k] is v
        out= oAssign {},obj
        if v is undefined
            delete out[k]
        else
            out[k]=v
        out

    oAssign: oAssign
    oA: oAssign
    oAssignAll: (o)->
        for i in [1 ... arguments.length] by 1 when arg=arguments[i]
            for k,v of arg when v isnt undefined
                o[k]= v
        o
    oCopy: (o)-> oAssign {},o
    oCreate: oCreate= Object.create
    oCreateWith: (proto,source,change,other)->oAssign (oCreate proto),                       source,change,other
    oClone:      (source,change,other)->      oAssign (oCreate Object.getPrototypeOf source),source,change,other #source is copied
    oCloneWith:  (son,source,change,other)->  oAssign (oCreate Object.getPrototypeOf son),   source,change,other # son NOT copied

    oUpdate: (o,updates)->
        newO= null
        for k,v of updates when o[k] isnt v
            #(updatedO ?= oClone o)[k]=v
            null
        newO

    oIsEmpty: oIsEmpty= (o)->
        for k of o
            return false
        true


    oIsOwnEmpty:  (o)->
        for own k of o
            return false
        true

    oPop: (o,key,ret)->
        if key of o
            ret=o[key]
            delete o[key]
        ret

    # copy of source (for none  nullvalues) wthout keys of defs, update defs
    oSplit: (source,defs,out)->
        out?= {}
        for k,v of source when v isnt null
            if k of defs
                defs[k]= v
            else out[k]= v
        out

    oEquals: oEquals=(a,b)-> # shalow equal a and b, same keys
        return true  if a is b
        return false if !b or !a
        for k,v of a when v isnt b[k]
            return false
        true



    oShallow: ->
        _shallow_cache={}

        oEq: oEq= (name,ifn,ivals)->
            if typeof ifn isnt 'function'
                 vals= ifn;   fn= ivals
            else vals= ivals; fn= ifn
            if oEquals vals, cache= _shallow_cache[name]
                return _shallow_cache["#{name}_fn"] if fn
                return cache
            _shallow_cache[name]= vals
            return vals if not fn
            return _shallow_cache["#{name}_fn"]= fn.call vals,vals

        oEqDbg: oEq= (name,ifn,ivals,debug=1)->
            if typeof ifn isnt 'function'
                 vals= ifn;   fn= ivals
            else vals= ivals; fn= ifn
            if oEquals vals, cache= _shallow_cache[name]
                log " !! oEq cahce HIT for #{name}" if debug
                return _shallow_cache["#{name}_fn"] if fn
                return cache
            if debug
                diffs=oDiffs vals, (cache or {})
                log " !! oEq cahce MISS for #{name} diffs= #{Object.keys diffs} fn=#{ifn?.name} ",diffs if debug
            _shallow_cache[name]= vals
            return vals if not fn
            return _shallow_cache["#{name}_fn"]= fn.call vals,vals if !debug
            #debug
            start= Date.now()
            ret=_shallow_cache["#{name}_fn"]= fn.call vals,vals
            log " !!           MISS for #{name}  fn=#{ifn?.name} took #{Date.now()-start}ms"
            ret

        oEqD: (name,ifn,ivals)->
            if typeof ifn isnt 'function'
                 vals= ifn;   fn= ivals
            else vals= ivals; fn= ifn
            if oEquals vals, cache= _shallow_cache[name]
                if fn
                    log "oEqD fn cache hit for #{name}",{name,fn,vals,cache,fncache:_shallow_cache["#{name}_fn"]}
                    return _shallow_cache["#{name}_fn"]
                log "oEqD cache hit for #{name}",{name,fn,vals,cache}
                return cache
            diffs= oDiffs vals,cache if vals and cache
            log "oEqD cache miss for #{name}",{vals,cache,fn,diffs}
            _shallow_cache[name]= vals
            return vals if not fn
            _shallow_cache["#{name}_fn"]= fn.call vals,vals


        o1: (name,fn,vals)-> _shallow_cache[name]?= oEq name,fn,vals


    oEqualsKeys: (a,b)-> throw 'TODO'

    oDiffs: oDiffs= (a,b,ops)->

        return false  if a is b

        # todo length check on arrays if not checkKeys

        # TODO: own loops for checkproto is true

        return oDiffsOps a,b,ops if ops

        diffs={}

        for k,va of a when (va isnt vb=b[k]) # (k not of b ) or .. we like deleted=
            diffs[k]= { a:va, b:vb }

        return false if oIsEmpty diffs

        diffs


    oDiffsOps: oDiffsOps= (a,b,ops)->

        diffs= ops.diffs
        diffs?= {}
        checkKeys= ops.checkKeys
        deep= ops.deep
        keys= ops.keys
        key= ops.key

        # is __protos__ === then use own a worthly optimisation?


        if !deep and !key

            if keys
                for k of keys when (va=a[k]) isnt (vb=b[k]) # (k not of b ) or .. we like deleted=
                    diffs[k]= { a:va, b:vb }
            else
                for k,va of a when (va isnt vb=b[k]) # (k not of b ) or .. we like deleted=
                    diffs[k]= { a:va, b:vb }
                if checkKeys #todo check if this is faster than 2 Object.keys ...
                    for k,vb of b when k not of diffs and (vb isnt va=a[k])
                        diffs[k]= { a:va, b:vb }
        else
            key+='.' if key
            if keys
                for k of keys when (va=a[k]) isnt vb=b[k] # (k not of b ) or .. we like deleted=
                    if !deep # or k not of deep)
                        diffs[key+k]= { a:va, b:vb }
                    else
                        if deepOps= deep[k]
                            oDiffs va,vb,
                                __proto__:deepOps
                                diffs:diffs
                                key:key+k
                        else oDiffs va,vb,
                                diffs:diffs
                                key:key+k

            else
                for k,va of a when (va isnt vb=b[k]) # (k not of b ) or .. we like deleted=
                    if !deep or (typeof va isnt 'object') #(k not of deep)
                        diffs[key+k]= { a:va, b:vb }
                    else
                        if deepOps= deep[k]
                            oDiffs va,vb,
                                __proto__:deepOps
                                diffs:diffs
                                key:key+k
                        else oDiffs va,vb,
                                diffs:diffs
                                key:key+k
                if checkKeys #todo check if this is faster than 2 Object.keys ...
                    for k,vb of b when k not of diffs and (vb isnt va=a[k])
                        if (k not of deep)
                            diffs[key+k]= { a:va, b:vb }
                        else
                            if deepOps= deep[k]
                                oDiffs va,vb,
                                    __proto__:deepOps
                                    diffs:diffs
                                    key:key+k
                            else oDiffs va,vb,
                                    diffs:diffs

                            key:key+k

        return false if oIsEmpty diffs

        diffs



    oOwnPropNames: oOwnPropNames= (obj)-> Object.getOwnPropertyNames obj

    oAllPropNames: (obj)->
        propNames=[]
        seen={}
        #log "all prop names for #{obj}",obj
        while obj
            #log "Object.getOwnPropertyNames obj =#{Object.getOwnPropertyNames obj}"
            for n in Object.getOwnPropertyNames obj when n not of seen
                propNames.push n
                seen[n]=1
            obj= obj.__proto__
        propNames



    # ***************** Number functions ******************

    nr: (d,n)->
        if arguments.length <2 then n=d; d=2
        round n/Math.pow(10,d)


    # ***************** String functions ******************

    isPrefix: isPrefix= (s,prefix)-> !prefix or s.substr(0,prefix.length) is prefix
    isSuffix: isSuffix= (s,suffix)-> !suffix or s.substr(-suffix.length)  is suffix

    sList: sList= (s,sep)->
        sep?= /[\s|,]+/
        return s if typeof s isnt 'string'
        ( w for w in s.split sep when w) # slow but sList is just for quick and dirty

    snList: sList= (s,sep)->
        sep?= /[\s|,]+/
        return s if typeof s isnt 'string'
        ( (if isNaN n=parseFloat w then w else n) for w in s.split sep when w) # slow but sList is just for quick and dirty



    sSet:  (s,sep)-> a2map sList s,sep

    sReplace: (s,old,nw)-> s.split(old).join(nw)

    sSplit: (s,rx)-> rx?=' '; (s or '').split rx

    sSplit1: (s,sep,rx)->
        sep?= ' '
        rx?= sep
        a=(s or '').split rx
        [a.shift(),(a.join sep)]

    sCamel:  (str)-> str.replace /-([a-z])/g, (match,g1)-> g1.toUpperCase()
    s1Camel: (str)-> str.replace /(^|-)([a-z])/g, (match,g1,g2)-> g2.toUpperCase()

    sUncamel:(str)->str.replace /([a-z0-9]|^)([A-Z])/g, (match,g1,g2)-> if g1 then "#{g1}-#{g2.toLowerCase()}" else g2.toLowerCase()

    sCap:  (str)-> str[0].toUpperCase()+ str[1 ..]
    sUncap:(str)-> str[0].toLowerCase()+ str[1 ..]
    sLower:(str)-> str.toLowerCase()
    sUpper:(str)-> str.toUpperCase()

    sDeaccent: (str)-> str.normalize('NFD').replace /[\u0300-\u036f]/g, ""
    
    Mb: (num)-> "#{Math.round num/(1024*1024)}Mb"

    # Other ....


    btoa: Global.btoa ? Buffer? and ( (s)->new Buffer.from(s,'binary').toString 'base64' ) or -> throw "no btoa and no Buffer"

    atob: Global.atob ? Buffer? and ( (b64)->Buffer.from(b64,'base64').toString 'binary' )  or -> throw "no atob and no Buffer"


    asJS: (val,sep)->
        sep?= '|'
        if typeof val is 'string'
            if val[0] is sep then sep+val else val
        else if val is undefined
            sep+'undefined'
        else
            sep+JSON.stringify val

    fromJS:(js,sep)->
        sep?= '|'
        return js if !js? or (typeof js isnt 'string') or js[0] isnt sep
        js1= js[1 ..]
        js1c= js1[0]
        return js1 if js1c is sep

        return ( vt for v in js1.split ',' when vt=v.trim() )if js1c is ','
        return undefined if js1 is 'undefined'
        try
            jsv= JSON.parse js1
        catch
            jsv= js1
        jsv

# create Global Name space and bacl Ppointer



# loader



                                            

lv= (n)->"#{n}=#{Global[n]}"

log "Boot loaded did "+( lv n for n in 'isNODE vx.isDEV isDESKTOP isMOBILE'.split(' ')).join ' '



module?.exports= vx

