{ log, meta, oAssign }= vx= require 'vx/globals/Boot'

{ BaseClass }= require 'vx/tools/BaseClass'

{ distInfo2Pt }= require 'vx/math/Geometry'

{ min, max, random } = Math


aBounds= meta class Bounds extends BaseClass

    x:0 # min X
    y:0 # min Y
    X:0 # max X
    Y:0 # max Y

	#init(@x,@y,@X,@Y)-> @ # ;-)

    @_= (x,y,X,Y)-> new @ x,y,X,Y

    constructor: (@x,@y,@X,@Y)->
        super()
        return @


    toString:-> "Bounds(#{@x},#{@y},#{@X},#{@Y})"

    # Alternate constructors
    #create top,left Width and Height
    @_a= (arr)-> @_.apply @,arr

    @WH= (x,y,width,height)-> @_( x, y, x+width, y+height )

    #create Center Width and Height
    @CWH= (x,y,width,height)-> @_( x-(w2=width/2), y-(h2=height/2), x+w2, y+h2 )

    @aPTS= (apts)->
        a= apts[0]
        b= @_( a.x, a.y, a.x, a.y )
        b.addPt pt for pt in apts
        b

    @PTS= ->@aPTS arguments

    @aBNDS= (abnds)->
        a= abnds[0]
        b= @_( a.x, a.y, a.X, a.Y )
        b.add bnd for bnd in abnds
        b

    @BNDS= -> @aBNDS arguments

    @fromJSON= (j)-> new @ j.x,j.y,j.X,j.Y

    @fromNative= (nativeBounds)->
        return new Bounds if not nativeBounds
        if nativeBounds instanceof @
            return new @ nativeBounds.x, nativeBounds.y, nativeBounds.X, nativeBounds.Y
        sw= nativeBounds.getSouthWest()
        ne= nativeBounds.getNorthEast()
        if typeof sw.lng is 'function'
             @_ sw.lng(),sw.lat(),ne.lng(),ne.lat()
        else @_ sw.lng  ,sw.lat  ,ne.lng  ,ne.lat


    size:-> { dx:@X-@x, dy:@Y-@y }

    width:-> @X-@x

    height:-> @Y-@y

    center:-> { x:(@x+@X)/2, y:(@y+@Y)/2 }

    tl:-> { x:@x, y:@y } # Top Left
    tr:-> { x:@x, y:@Y } # Top Right
    bl:-> { x:@X, y:@y } # Bottom Left
    br:-> { x:@X, y:@Y } # Bottom Right


    intersects: (b)-> switch
        when @X  <= b.x then false
        when b.X <= @x  then false
        when @Y  <= b.y then false
        when b.Y <= @y  then false
        else true

    intersectsPt: (b)-> switch
        when @X  <= b.x then false
        when b.x <= @x  then false
        when @Y  <= b.y then false
        when b.y <= @y  then false
        else true

    contains: (b)-> @x<=b.x and @X>=b.X and @y<=b.y and @Y>=b.Y

    containsPt: (b)-> @x<=b.x and @X>=b.x and @y<=b.y and @Y>=b.y


    union:   (b)-> @constructor._( min(@x,b.x), min(@y,b.y), max(@X,b.X), max(@Y,b.Y) )
    unionPt: (b)-> @constructor._( min(@x,b.x), min(@y,b.y), max(@X,b.x), max(@Y,b.y) )

    add:   (b)-> @x= min(@x,b.x); @y= min(@y,b.y); @X= max(@X,b.X); @Y= max(@Y,b.Y); @
    addPt: (b)-> @x= min(@x,b.x); @y= min(@y,b.y); @X= max(@X,b.x); @Y= max(@Y,b.y); @


    moveBy:({dx,dy})-> @constructor._( @x+dx, @y+dy, @X+dx, @Y+dy )

    moveSpeed: (speed,time)->
        {dx,dy}= speed
        @moveBy({dx:dx*time,dy:dy*time})

    grow: (szx,szy=szx)-> @constructor.CWH( (c=@center()).x, c.y, @width()+szx, @height()+szy )

    displacment:(speed,time)-> @union(@moveSpeed(speed,time))

    randomPt:()-> { x:@x+random()*@width(), y:@y+random()*@height() }

    distance2Pt: (pt)-> @distInfo2Pt(pt).dist

    distInfo2Pt: (pt)->
        return dist:0 if @intersectsPt(pt)
        @distData2Pt(pt)

    distData2Pt: (pt)->
        tl= @tl(); tr= @tr(); bl= @bl(); br= @br()
        res= [
         [ distInfo2Pt(tl,tr,pt), { idx:0, side:'top'} ]
         [ distInfo2Pt(tr,br,pt), { idx:1, side:'right'} ]
         [ distInfo2Pt(br,bl,pt), { idx:2, side:'bottom'} ]
         [ distInfo2Pt(bl,tl,pt), { idx:3, side:'left'} ]
        ].sort((a,b)->a[0].dist-b[0].dist)[0] #only keep first
        oAssign res[0],res[1]
        res[0]

    asArray:     ->[@x,@y,@X,@Y]
    asSWNEArray: ->[ @y,@x,@Y,@X ]

    overlap: (b)-> [ b.x-@x, b.y-@y, b.X-@X, b.Y-@Y]

module.exports= { Bounds }
