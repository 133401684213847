{ meta, log, arr, a2map, oAssign }= vx= require 'vx/globals/Boot'

require './AttraitsFCMQUI.css'

{ Attrait, OrderedGroups }= require './AttraitsFCMQ'
{ setProtocol }= require 'vx/tools/URLencoders'
{ templateWidget, TemplateWidget, templateListWidget }= require 'vx/templates/TemplateWidgets'
require 'vx/templates/attrait-info.css'
require 'vx/templates/attrait-selector.css'

MapGroups= a2map OrderedGroups

wait= require 'vx/tools/Wait'

{ sayModule, lang }= require 'vx/i18n/I18n'
say= sayModule 'FCMQ'

UI= require 'vx/UI'

{ UIComponent }= UI

{ div, h5, group }= UI
{ checkbox }= UI
{ details, summary }= UI

{ AttraitInfo, AttraitMarker, attraitsLayerFactory, getObjCenterBoundsm, getObjMapBoundsm,  Cluster, Etablisment, makeEtablisments, makeClusters, filter, filterVBnds }= require './AttraitsUI' # , attraitsSelector

{ infoAttraitItin }= require 'vx/comp/itin/ItinWidgets'

{ vxIcon:fcmqIcon, vxIconClassName:IconClassName }=  require 'vx/ui/vxIcon'




GroupClassName=  "map-html" # hard code cause complicated to make dynamic  MapHTML::_className

iconTag= fcmqIcon

attraitIcon= (name)-> fcmqIcon 'elevation=1',name

attraitMarker= meta class AttraitFCMQMarker extends AttraitMarker
    @displayName: 'AttraitFCMQMarker'

    _className: 'attrait-marker'

    iconNameVar: 'sourceSousCat'

    iconTag: iconTag




attraitInfo= meta class AttraitInfoFCMQ extends AttraitInfo
    @displayName: 'AttraitInfoFCMQ'

    _className: 'attrait-info'


    render: (props=@props)->
        { onClose:doClose, attrait:obj, app,  }= props
        #if true
        div '.attrait-info__container ',
            templateWidget
                componentClassName: 'attrait-info'
                obj: obj
                doClose: doClose
                doNavigate: @doNaviagte
            div '.attrait-info__itin-info',
                infoAttraitItin
                    attrait:obj
                    app:    app
                    onClick:doClose
                    
#         <div class="attrait-info__container">
#             <templateWidget 
#                 componentClassName="attrait-info"
#                 obj={obj}
#                 doClose={doClose}
#                 doNavigate={@doNaviagte} />
#             <div class="attrait-info__itin-info" >
#                 <infoAttraitItin
#                     attrait={obj}
#                     app={app}
#                     onClick={doClose} />
#             </div>
#         </div>


    doNavigate: (e)->
        log " got a attrait info navigate ",e


attraitsSelector= ({app,attraitIcon=attraitIcon})->

        # make orred list
        appGroups= app.getGroups()

        gList=[]
        for g in OrderedGroups
            gList.push g #if appGroups.indexOf g isnt -1

        for g of appGroups when g not of MapGroups
            gList.push g
        #log "gList=",gList
        details 'open $margin:"11px 4px 0px 11px"',
            summary h5 '',(say "type attraits")

            group '',

                for gname in gList
                    gnameId= gname.replace /\s/g,'_'
                    checkbox
                        id:id="chbk-#{gnameId}"
                        key:id
                        name: gname
                        checked: app.isGroupVisibile gname
                        value:1
                        offValue:0
                        onNewValue: wait app.setGroupVisibility
                        ( attraitIcon gname )
                        """ #{say gname} #{if (items=appGroups[gname]) and items.length then "(#{items.length})" else ''} """ # titre



toggleLayer= false

attraitsSelectorCustom= ({app,attraitIcon=attraitIcon})->

        if app and !toggleLayer #app never changes
            do (app)->
                toggleLayer= wait (obj,{objid,checked})->
                    app.setGroupVisibility checked,objid

        # make orred list
        appGroups= app.getGroups()



        gList=[]
        for g in OrderedGroups
            gList.push g #if appGroups.indexOf g isnt -1

        for g of appGroups when g not of MapGroups
            gList.push g
        #log "gList=",gList


        templateListWidget
            componentClassName:'attrait-selector'
            toggleLayer: toggleLayer
            doClose: app.toggleAttraits
            list: for gname in gList  #when  (count=appGroups[gname]?.length) or 1
                id:gname
                iconName:gname.toLowerCase().replace /\s+/g,'-'
                titre: say gname
                checked: app.isGroupVisibile gname
                count: appGroups[gname]?.length or 0
                info: if (infoTxt=say 'info-'+gname) and infoTxt and infoTxt isnt 'info-'+gname then infoTxt else ''




attraitsFCMQLayerFactory= (MapWidgets,layerAttraitMarker=attraitMarker,layerAttraitInfo=attraitInfo)->
    attraitsLayerFactory MapWidgets,layerAttraitMarker,layerAttraitInfo




module.exports= { attraitInfo, attraitIcon, attraitMarker, attraitsSelector, attraitsSelectorCustom, getObjCenterBoundsm, getObjMapBoundsm, Cluster, Etablisment, \
        attraitsLayerFactory: attraitsFCMQLayerFactory, makeEtablisments, makeClusters, filter, filterVBnds
        }


