{ log, $$ }= Mod= vx= require 'vx/globals/Boot'



updateForm= (form,add={})->
    #log "updateForm got ",{form,add}
    data= new FormData()
    for k,v of add
        #log " append #{k},#{v} from add"
        data.append k,v
    for ctrl in $$('input[name]',form) when ctrl.name
        #log " append ctrl#{ctrl.name},#{ctrl.value} from form"
        data.append ctrl.name, ctrl.files?[0] ? ctrl.value

    #log "updateform data=",(x for x from data.entries())
    fetch form.action,
        method: 'post'
        credentials: 'include'
        body: data
        #headers: {'Content-Type': form.enctype}


updateFormJ= (form,add={})->
    log "updateForm got ",{form,add}
    data= {}
    for k,v of add
        log " append #{k},#{v} from add"
        data[k]=v
    for ctrl in $$('input[name]',form) when ctrl.name
        log " append ctrl#{ctrl.name},#{ctrl.value} from form"
        data[ctrl.name]= ctrl.files?[0] ? ctrl.value

    log "updateform data=",data
    jsdata= JSON.stringify data
    log "updateform jsdata=",jsdata
    fetch form.action,
        method: 'post'
        credentials: 'include'
        body: jsdata
        headers: {
            'Content-Type': 'application/json'
            # 'Content-Type': 'application/x-www-form-urlencoded',
            }




module.exports= {  updateForm, updateFormJ  }
