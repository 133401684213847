{ Global, log, oSplit, oAssign, oAssignAll, oDiffs }= vx= require 'vx/globals/Boot'

log "loading leaflet  vx.isNODE=#{vx.isNODE} was window=#{window?}"
if window?
    L= ( require 'leaflet') or Global.L
    require 'leaflet/dist/leaflet.css'
else L= {}


{ render, Children,createElement:cE  }= require 'react'
childrenToArray= Children.toArray
{ render }= require 'react-dom'


{ latM, latMInv }= require 'vx/math/MapGeometry'
{ div, br }= require 'vx/UI'
{ positionContent } = require 'vx/map/positionContent'

ControlPosConvert=
    'TOP_LEFT': 'topleft'
    'LEFT_TOP': 'topleft'
    'TOP_RIGHT':'topright'
    'RIGHT_TOP':'topright'

    'BOTTOM_LEFT': 'bottomleft'
    'LEFT_BOTTOM': 'bottomleft'
    'BOTTOM_RIGHT':'bottomright'
    'RIGHT_BOTTOM':'bottomright'

    'TOP_CENTER':  'topleft'


CFather= L?.Control or Object

class HTMLControl extends CFather

    #was
    #constructor: (@node,@gposition='TOP_LEFT')->
    #   super position:@position= ControlPosConvert[@gposition] or 'topleft'


    constructor: (node,gposition='TOP_LEFT')->
        position= ControlPosConvert[gposition] or 'topleft'
        super {position}
        @node= node
        @position= position
        @gposition= gposition
        return @

    onAdd:->
        L.DomEvent.disableClickPropagation @node
        @node

    onRemove:-> log "removing map Control should never happen (rarely) pls check"

#     addTo: (map)->
#         ret=super map
#         cpos= @options.ctrlPos
#         switch
#             when cpos is 'TOP_CENTER'
#                 $(@div_).detach().appendTo($(map.getContainer()).find '.vx-leaflet-topcenter-container')
#             when cpos is 'TOP_LEFT'
#                 $(@div_).detach().appendTo($(map.getContainer()).find '.vx-leaflet-topleft-container')
#             when cpos is 'TOP_RIGHT'
#                 $(@div_).detach().appendTo($(map.getContainer()).find '.vx-leaflet-topright-container')



LFather= L?.Layer or Object

class  HTMLLayer extends  LFather

    constructor: (ops)->
        super ops
        @options= oAssignAll {},@options,{nonBubblingEvents: []},ops
        #log "@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@2 Created map Zone=",{self:@,ops}

        #log " &&& HTMLLayer constructor @options.dontPositionCont =#{@options.dontPositionCont}"

        @_update_pos= @_update_pos.bind @

        (@_animateZoom= @_animateZoom.bind @) if @options.zoomAnimated

        return @


    options:
        attribution:    null
        nonBubblingEvents: null # [] allocated at constructor
        pane: 'markerPane'
        zoomAnimated: false
        className:''

    onAdd: (map)->
        @_map= map
        pane = map.getPane @options.pane
        @_container= ediv= document.createElement 'div'
        ediv.className= @options.className+if @options.zoomAnimated then ' leaflet-zoom-animated' else ''
        ediv.id= @options.id if @options.id
        oAssign ediv.style,@options.style if @options.style
        pane.appendChild ediv
        @_update_pos()
        @_update_content()

        # Calculate initial position of container with `L.Map.latLngToLayerPoint()`, `getPixelOrigin()` and/or `getPixelBounds()`
        # Add and position children elements if needed

        map.on 'zoomend viewreset',@_update_pos
        (map.on 'zoomanim',@_animateZoom) if @options.zoomAnimated

        #@_container.addEventListener 'click',@doNativeClick
        #@_container.addEventListener 'click',@doNativeClickCapture,true




    onRemove: (map)->
        @_map= null
        L.DomUtil.remove @_container
        #@_container.removeEventListener 'click',@doNativeClick
        #@_container.removeEventListener 'click',@doNativeClickCapture,true

        @_container= null
        map.off 'zoomend viewreset',@_update_pos
        (map.off 'zoomanim', @_animateZoom) if @options.zoomAnimated




#     doNativeClick:(e)=>
#         log "LHTMLLayer native click e=",e
#
#
#     doNativeClickCapture:(e)=>
#
#         log "LHTMLLayer native click capture e=",e



    _update_content:->
        return if not @_container or not (content= @options.content)
        content= @options.content
        if !@options.dontPositionCont
            switch
                when content instanceof Array
                    content= div '',
                        #onClick:@doClick
                        onClickCapture:@doClickCapture
                        @_positionContent content,@options,@options.className
                when content.props
                    content= cE content.type,{
                        ...content.props
                        #onClick:@doClick
                        onClickCapture:@doClickCapture
                        children: @_positionContent content.props.children,@options,@options.className
                        }
        #else
        #    log " &&& skipped position content"

        render content,@_container


#     doClick:(e)=>
#         log "LHTMLLayer react click e=",e

    doClickCapture:(e)=>
        #log "LHTMLLayer react click capture e=",e
        if @_map?._vx_clicktimer
            #log "MAPHTML clear timer"
            clearTimeout  @_map._vx_clicktimer
            @_map._vx_clicktimer= null




    setPos: (pos)->
        oAssign @options,pos
        @_update_pos()

    _latLngToLayerPoint:  (latlng)->
        projectedPoint= @_map.project L.latLng latlng
        projectedPoint._subtract @_map.getPixelOrigin()

    _update_pos: ->
        # log "UPDATE POS for ",{self:@,ops:@options}
        # Recalculate position of container
        return if !@_map or !@_container
        { top, left, right, bottom, width, height }= @options
        if !right? and width? then right= left+width
        else if !left? and width? then left= right-width

        if !bottom? and height? then bottom= top+height
        else if !top? and height? then top= bottom-height

        @options.topLatM= latM top ? 0

        { x:pxLeft, y:pxTop }=    @_latLngToLayerPoint [ top ? 0, left ? 0]
        { x:pxRight,y:pxBottom }= @_latLngToLayerPoint [ bottom ? 0, right ? 0 ]

        @_container.style.top=  "#{pxTop}px"
        @_container.style.left= "#{pxLeft}px"

        if right?
            @_container.style.width="#{pxRight-pxLeft}px"
            @options.lngScale= 100/(right-left)
        if bottom?
            @_container.style.height="#{pxBottom-pxTop}px"
            @options.latMScale= -100/(@options.topLatM - latM bottom)
        #log "END UPDATE POS for ",{self:@,ops:@options}
        null



    _xxxanimateZoom: (opt)->
        # Recalculate position of container

        return if !@_map or !@_container
        { top, left, right, bottom, width, height }= @options
        if !right? and width? then right= left+width
        else if !left? and width? then left= right-width

        if !bottom? and height? then bottom= top+height
        else if !top? and height? then top= bottom-height

        { x:pxLeft, y:pxTop }=    @_map._latLngToNewLayerPoint( [ top ? 0, left ? 0 ]    , opt.zoom, opt.center).round()
        { x:pxRight,y:pxBottom }= @_map._latLngToNewLayerPoint( [ bottom ? 0, right ? 0 ], opt.zoom, opt.center).round()

        @_container.style.top=  "#{pxTop}px"
        @_container.style.left= "#{pxLeft}px"

        log "HTMLLayer _animateZoom top=#{pxTop} left=#{pxLeft} right=#{right} bottom=#{bottom}"

        if right?
            @_container.style.width="#{pxRight-pxLeft}px"

        if bottom?
            @_container.style.height="#{pxBottom-pxTop}px"



    _positionContent: (content)->
        return undefined if not content
        { topLatM:latMOffset, left:lngOffset, lngScale, latMScale }= @options
        positionContent.call @, content,{ latMOffset, lngOffset, lngScale, latMScale }



GFather= L?.GridLayer or Object

L.NativeGridLayer= class NativeGridLayer extends GFather

    getTileSize: ->
        map= @_map
        tileSize= super()
        zoom= @_tileZoom # + @options.zoomOffset
        minNativeZoom= @options.minNativeZoom
        maxNativeZoom= @options.maxNativeZoom

        #log " LL getTiles size zoom=#{zoom} _tileZoom=#{@_tileZoom} tileSize=#{tileSize} minNative=#{minNativeZoom}"

        # decrease tile size when scaling below minNativeZoom
        if minNativeZoom? and zoom < minNativeZoom
            pt= tileSize.divideBy(map.getZoomScale(minNativeZoom, zoom)).round()
            #log " LLLLLLLLLL over min native zoom hit zoom=#{zoom} minNativeZoom=#{minNativeZoom} size=#{pt}"
            return pt

        # increase tile size when scaling above maxNativeZoom
        if maxNativeZoom? and zoom > maxNativeZoom
            return tileSize.divideBy(map.getZoomScale(maxNativeZoom, zoom)).round()

        return tileSize


    getEffectiveZoom:->
        zoom= @_tileZoom # + @options.zoomOffset
        minNativeZoom= @options.minNativeZoom
        maxNativeZoom= @options.maxNativeZoom

        # decrease tile size when scaling below minNativeZoom
        if minNativeZoom? and zoom < minNativeZoom
            return minNativeZoom

        # increase tile size when scaling above maxNativeZoom
        if maxNativeZoom? and zoom > maxNativeZoom
            return maxNativeZoom

        return zoom


TFather= L?.TileLayer or Object

L.CachedZoomTileLayer=  class CachedZoomTileLayer extends TFather


        ###
        createTileSuper: (coords, done)->
            tile= document.createElement 'img'

            L.DomEvent.once tile,'load', L.bind @_tileOnLoad, @,done,tile # was on to avoid a double load TODO:check if required
            L.DomEvent.on   tile,'error',L.bind @_tileOnError,@,done,tile

            tile.crossOrigin= '' if @options.crossOrigin

            # Alt tag is set to empty string to keep screen readers from reading URL and for compliance reasons
            # http://www.w3.org/TR/WCAG20-TECHS/H67

            tile.alt= ''


            # Set role="presentation" to force screen readers to ignore this
            # https://www.w3.org/TR/wai-aria/roles#textalternativecomputation

            tile.setAttribute 'role','presentation'

            tile.src= @getTileUrl coords

            tile
        ###

        getTileUrl:  (coords)->
            data =
                r: if L.Browser.retina then '@2x' else ''
                s: @_getSubdomain coords
                x: coords.x
                y: coords.y
                z: @_getZoomForUrl coords.z # GD was ()

            if @_map and !@_map.options.crs.infinite
                invertedY = @_globalTileRange.max.y - coords.y
                if @options.tms
                    data['y'] = invertedY
                data['-y'] = invertedY

            L.Util.template @_url, L.extend data,@options


        _getZoomForUrl:(zoom=@_tileZoom)->
            maxZoom =     @options.maxZoom
            zoomReverse = @options.zoomReverse
            zoomOffset =  @options.zoomOffset
            minNativeZoom = @options.minNativeZoom
            maxNativeZoom = @options.maxNativeZoom

            if zoomReverse
                zoom = maxZoom - zoom;

            zoom += zoomOffset

            if minNativeZoom? and zoom < minNativeZoom
                return minNativeZoom

            if maxNativeZoom? and zoom > maxNativeZoom
                return maxNativeZoom

            zoom



        createTile: (tilePoint,done)->

            {z,x,y}= tilePoint

            cache= @options.cache

            #log "CachedZoomTileLayer.createTile cache=",cache

            info= cache[z+(@options.zoomOffset or 0)]?[x]?[y]


            return super tilePoint,done if info # tile exists act noramly


            #console.log "zoom cache miss for #{z} #{x} #{y}"
            #ok find first cache hit going up
            pow= Math.pow

            dz=0; dx=0; dy=0

            while z>0 and not cache[z+(@options.zoomOffset or 0)]?[x]?[y]
                z2= pow 2,dz
                dx+= z2 * ( x % 2 )
                dy+= z2 * ( y % 2 )
                dz++
                z--
                x= x//2
                y= y//2

            #console.log "cache zoom hit for #{z} #{x} #{y}, #{dx} #{dy} #{dz}"

            #todo do a better job here we this will fait cause not in cache
            #return super tilePoint,done if not z
                #cache miss

            zoomedTilePoint= {z,x,y,dx,dy,dz}

            tile=super zoomedTilePoint,done #
            tile.zoomedTilePoint= zoomedTilePoint
            tile



        _tileOnLoad:  (done, tile)->
            return super done,tile if not tile.zoomedTilePoint?
            zoomedTilePoint= tile.zoomedTilePoint
            return if not zoomedTilePoint # second load
            tile.zoomedTilePoint= false

            # ok we got a zoomed tile lets adjust it

            tile.style.backgroundImage= "url(#{tile.src})"
            tile.src='css/images/empty.png'
            {dz,dx,dy}= zoomedTilePoint
            {x:tx,y:ty}= @getTileSize()
            bkgSize= "#{sx=tx*Math.pow 2,dz}px #{sy=tx*Math.pow 2,dz}px"
            bkgPos= "#{dx * -tx}px #{dy * -ty}px"
            tile.style.backgroundSize= bkgSize
            tile.style.backgroundPosition= bkgPos

            super done,tile


L.GridLayerNoZoom=  class GridLayerNoZoom extends GFather


    _updateLevels: `function () {

        var zoom = this._tileZoom,
        maxZoom = this.options.maxZoom;

        if (zoom === undefined) { return undefined; }

        for (var z in this._levels) {
            if (this._levels[z].el.children.length || z === zoom) {
                this._levels[z].el.style.zIndex = maxZoom - Math.abs(zoom - z);
                this._onUpdateLevel(z);
                } else {
                    L.DomUtil.remove(this._levels[z].el);
                    this._removeTilesAtZoom(z);
                    this._onRemoveLevel(z);
                    delete this._levels[z];
                    }
                }

                var level = this._levels[zoom],
                map = this._map;

                if (!level) {
                    level = this._levels[zoom] = {};

                    level.el = L.DomUtil.create('div', 'leaflet-tile-container leaflet-zoom-animated', this._container);
                    level.el.style.zIndex = maxZoom;

                    level.origin = map.project(map.unproject(map.getPixelOrigin()), zoom).round();
                    level.zoom = zoom;

                    this._setZoomTransform(level, map.getCenter(), map.getZoom());

                    // force the browser to consider the newly added element for transition
                    L.Util.falseFn(level.el.offsetWidth);

                    this._onCreateLevel(level);
                    }

                this._level = level;

                return level;
            }`





module.exports= { HTMLControl, HTMLLayer, NativeGridLayer, CachedZoomTileLayer, ControlPosConvert }
