#!/usr/bin/coffee
{ oAssign, log, isNumber, oCreateWith, oCloneWith }= vx= require 'vx/globals/Boot'

P= require 'vx/tools/Persistance'
{ latM, latMInv, mCalcDist }=          require 'vx/math/MapGeometry'
{ distance, distInfo2XY, distInfo2Pt}= require 'vx/math/Geometry'
{ Bounds }=                            require 'vx/math/Bounds'
{ abs, floor, round, ceil, min, max }= Math

# Scales ....

{ MapDigits, MapScale, SIScale }= require './Const'
{ HasProps }= require './ObjectProperties'

nearZero= 0.0000001


class Pt #extends HasProps
    #     @props
    #         ym: get: -> @_ym?= latM @y
    #         bounds: get: -> Bounds._ @x,@y, @x,@y
    #         mBounds:get: -> Bounds._ @x,@ym,@x,@ym

    @_=  (x,y,_ym)->new @ x,y,_ym

    constructor: (x,y,_ym)->
        #TODO rethink this cause it does not work in a setter
        #return new Pt x,y,_ym if @ is Global or !@? # cant use newlless constructor in es6
        return @init x,y,_ym

    @fromNative= (nativePt)->
        if typeof nativePt.lat is 'function'
             new @ nativePt.lng(), nativePt.lat()
        else new @ nativePt.lng,   nativePt.lat



    init: (@x,@y,_ym)->
        @ym= _ym ? latM @y
        #@bounds=  Bounds._ @x,@y, @x,@y
        #@mBounds= Bounds._ @x,@ym,@x,@ym
        @

    bounds:  -> Bounds._ @x,@y, @x,@y
    mBounds: -> Bounds._ @x,@ym,@x,@ym
    getBounds: ->@bounds()

    distanceMto: (pt)-> distance @x,@ym,pt.x,pt.ym

    distanceTo: (pt)-> mCalcDist @y,@x,pt.y,pt.x

    toString:(digits=MapDigits)-> "Pt({x:#{@x?.toFixed? digits},y:#{@y?.toFixed? digits})"

    toStrCoord:(digits=MapDigits)-> "#{@x.toFixed digits},#{@y.toFixed digits}"
    toStrCoordM:(digits=MapDigits)-> "#{@x.toFixed digits},#{@ym.toFixed digits}"

    toJSON: -> {@x,@y,@t}

    equals: (pt)-> (nearZero>abs @x-pt.x) and (nearZero > abs @y-pt.y)

    @array2Pts: (pts)->
        return pts if !pts.length or (p=pts[0]) instanceof @
        # A bit of duck typing ....
        ( @normalize pt for pt in pts )

    @array2PtsSI: (pts)->
        return pts if !pts.length # or (p=pts[0]) instanceof @
        # A bit of duck typing ....
        ( @normalize [x/SIScale,y/SIScale] for [x,y] in pts )


    @arrDist: (apts)->
        return 0 if not apts.length
        apts= @array2Pts apts
        last= apts[0]
        dist=0
        for i in [ 1 ... apts.length] by 1
            dist+= last.distanceTo c=apts[i]
            last= c
        dist

    @normalize: (p)->
        switch
            when p.__proto__ is @::
                p
            when (p instanceof Array) and (isNumber p[0]) and (isNumber p[1])
                #log "array2Pts convert array"
                new @ p[0],p[1]
            when p.yl?
                new @ p.x,p.yl,p.y
            when p.x? and p.y?
                new @ p.x,p.y
            when (isNumber p.lat) and isNumber p.lng
                new @ p.lng,p.lat
            when (typeof p.lat is 'function' ) and  (typeof p.lng is 'function')
                new @ p.lng(),p.lat()
            else throw new Error "Cant convert #{p} to a point,@normalize failed"


#YUP you can skwint

class Node
    #

    id: -1
    pt: null #pt

    starts: []
    ends: []


    @_=(ops)->new @ ops

    constructor: (ops)->
        #return new Node ops if @ is Global or !@?
        return @init ops

    init: ({ @id, @pt, @starts=[], @ends=[] }={})->
        #@pt= roundPtTo pt
        @

    _clone:(updates,other)-> oCloneWith @,
        {@id,@pt,starts:@starts[..],ends:@ends[..]}
        updates
        other

    getBounds:-> @pt.bounds()

    _addStart:(segment)-> @starts.push segment.id
    _addEnd:  (segment)-> @ends.push   segment.id

    hasSegId: (segId)-> (-1<@starts.indexOf segId) or (-1<@ends.indexOf segId)



class SplitNode extends Node
    #

    _splitIdx: null # int idx
    _splitSegId: null # original seg TODO: use segid?



    #constructor: (ops)->
        #return super ops
        #return new SplitNode ops if @ is Global or !@?
        #return @init ops

    init: (ops={})->
        #@pt= roundPtTo pt
        ret= super ops
        { _splitIdx, _splitSegId }= ops
        ret._splitIdx= _splitIdx if _splitIdx?
        ret._splitSegId= _splitSegId if _splitSegId?
        ret


    _clone:(updates,other)-> oCloneWith @,
        {@id,@pt,starts:@starts[..],ends:@ends[..],@_splitIdx,@_splitSegId}
        updates
        other




class Segment extends HasProps
    #

    id: -1
    start: null # Node
    end:   null # Node
    oneway: false #
    data: {}
    #pts: []  #redefined as prop in persitance
    bounds: null #redefined as prop in persitance
    dist:   null
    _splitSegId:    null
    _splitSegStart: null
    _splitSegEnd:   null


    @create= (ops)-> oCreateWith @::,ops


    @props

        pts:
            get: ->@_pts ?= @decodePtsStr() # fnc ref not a inline fnc ...
            set:(pts)->
                @_p= null
                @_px= null
                @_sp= null
                @_ep= null
                bnds= Bounds.aPTS ( pt for pt in pts )
                @bounds= new Bounds floor(bnds.x*MapScale)/MapScale,
                                floor(bnds.y*MapScale)/MapScale,
                                ceil(bnds.X*MapScale)/MapScale,
                                ceil(bnds.Y*MapScale)/MapScale
                @_pts= pts
                @dist= @calcDist()
                #log "segment #{@id} got #{@pts.length} dist=#{@dist}"
                @_pts

        #dist: cache: ->

        distM: cache: -> @calcDistM()


        SVGPts:     cache: -> ("#{x.toFixed MapDigits} #{y.toFixed MapDigits}" for {x,y} in @pts ).join ','

        SVGLine:    cache: -> ("#{x.toFixed MapDigits} #{y.toFixed MapDigits}" for {x,y} in [@startPt,@endPt] ).join ','

        SVGLatMPts: cache: -> ("#{x.toFixed MapDigits} #{ym.toFixed MapDigits}" for {x,ym} in @pts ).join ','

        #latMPts:    cache: -> ( [x,ym] for {x,ym} in @pts )

        latMLine:   get: ->  [@startPt,@endPt]

        SVGLatMLine:cache: ->  ("#{x.toFixed MapDigits} #{ym.toFixed MapDigits}" for{x,ym} in [@startPt,@endPt] ).join ','

        startPt: get: -> @_sp?= @pts[0]

        endPt:   get: -> @_ep?= @pts[@pts.length-1]

        latMDiagonal: cache: ->
            {x,y,X,Y}= @bounds
            (Pt._ x,y).distanceMto Pt._ X,Y
            #@startPt.distanceMto @endPt

    middleM: ->
        cpt=  @bounds.cMPt()
        info= @closestToM cpt
        Pt._ info.x,info.y


    @_= (ops)-> new @ ops

    constructor: (ops)->
        super()
        return @init ops



    calcDistM: (start=0,end=@pts.length-1)->
            if end < 0 then end= @pts.length-end
            end= min end,@pts.length-1
            distM=0
            pts= @pts
            last= pts[start]
            for i in [(start+1) .. end ] by 1
                pt= pts[i]
                distM+= last.distanceMto pt
                last= pt
            distM

    calcDist: (start=0,end=@pts.length-1)->
            if end < 0 then end= @pts.length-end
            end= min end,@pts.length-1
            dist=0
            pts= @pts
            last= pts[start]
            for i in [(start+1) .. end ] by 1
                pt= pts[i]
                dist+= last.distanceTo pt
                last= pt
            dist


    SVGLatMPtsRes: (res)->

            pts= @latMPtsRes res

            #log "res save #{(100*(1-(splits.length+2)/@pts.length)).toFixed 1}"

            ("#{x.toFixed MapDigits} #{ym.toFixed MapDigits}" for {x,ym} in pts  ).join ','

    latMPtsRes: (res)->

            if @_last_latMPtsRes_res is res
                #log "SAVED latMPtsRes #{@pts.length} / #{@_last_latMPtsRes.length}"
                return @_last_latMPtsRes

            splits= @subSegsIdxsM res

            @_last_latMPtsRes_res= res
            @_last_latMPtsRes= [@startPt].concat (@pts[i] for i in splits),[@endPt]


    subSegsIdxsM: (stepM,offset=0,start=0,end=@pts.length-1)->

            #Caching
            if @_lastSplitStepM is stepM and @_lastSplitOffset is offset
                #log "subSegsIdxsM cache hit",{seg:@,stepM,offset,splits:@_lastSplits}
                log "SAVED subSegsIdxsM #{@pts.length} / #{@_lastSplits.length}"
                return @_lastSplits

            @_lastSplitStepM= stepM
            @_lastSplitOffset= offset
            @_lastSplits= splits= []

            pos= offset
            pts= @pts
            last= pts[start]

            segIdx=0

            for idx in [(start+1) .. end ] by 1
                pt= pts[idx]
                pos+= last.distanceMto pt
                if segIdx < (nextIdx=pos//stepM)
                    splits.push idx
                    segIdx= nextIdx
                last= pt

            #log "subSegsIdxsM exec #{splits.length} splits cnt=#{@distM/stepM} distm=#{@distM} stepM=#{stepM}",{seg:@,stepM,offset,splits}

            splits


    subSegsIdxs: (step,offset=0,start=0,end=@pts.length-1)->

            pos= offset
            pts= @pts
            last= pts[start]
            splits= []
            segIdx=0

            for idx in [(start+1) .. end ] by 1
                pt= pts[idx]
                pos+= last.distanceTo pt
                if segIdx < (nextIdx=pos//step)
                    splits.push idx
                    segIdx= nextIdx
                last= pt

            #log "subSegsIdxsM exec #{splits.length} splits cnt=#{@distM/stepM} distm=#{@distM} stepM=#{stepM}",{seg:@,stepM,offset,splits}

            splits




    init: ({ @id, @start, @end, pts, @data={}, oneway, _splitSegId, _splitSegStart, _splitSegEnd }={})->
        throw new Error "Can init Segment without points" if !pts or pts.length < 2

        if _splitSegId?
            @_splitSegId=    _splitSegId
            @_splitSegStart= _splitSegStart
            @_splitSegEnd=   _splitSegEnd

        @oneway= oneway if oneway
        @pts= pts
        # @bounds= Bounds.aPTS ( {x:pt[0],y:pt[1]}for pt in pts ) done in pts setter

        @

    _clone: (updates,other)->
        # we asume pts and thus dist and bounds are static ....
        oCloneWith @,{@id,@start,@end,@dist,@bounds,@_pts,data:oAssign {},@data},updates,other


    getBounds: ->@bounds


    closestToM: (pt,better)->
        info= @distInfo2PtM pt,undefined,undefined,better
        return false if not info
        idx2= info.idx + ( if (info.u > 0.5) and ( idx < (@pts.length-1)) then 1 else 0 )
        idxf=info.idx+info.u
        idx= Math.min @pts.length-1,round idxf
        { pt:@pts[idx], idx, idxf, idx2, x:info.x, y:info.y, dist:info.dist, info:info, isEnd:(!idx or idx==@pts.length-1) }



    distInfo2PtM: (pt,start=0,stop=@pts.length,better)->

        if better? and !@bounds.intersectsPt pt
            # ok we must beat beter check if its posible
            return false if better < (@bounds.distData2PtM pt).dist

        len= @pts.length
        stop= if stop < 0 then max 0,len-stop else min len,stop
        if stop ==  len
            if pt.x == (endpt=@pts[len-1]).x and pt.ym==endpt.ym
                #log "end point guest hit"
                return {pt,u:0,uo:0,uf:0,end:true,dist:0,idx:len-1}
        dist= Infinity
        pts= @pts
        a= pts[start]
        for i in [start+1 ... stop] by 1\
            when (next= distInfo2XY(a.x,a.ym,(a=pts[i]).x,a.ym,pt.x,pt.ym)).dist < dist
                if next.u < 1
                     next.idx= i-1; next.end= false; next.uf= next.u
                else next.idx= i  ; next.end=true  ; next.uf= 0
                dist= next.dist
                info= next
                break if dist == 0 #cant do better than that
        return false if better? and dist > better
        info.ym=info.y
        info.y= latMInv info.ym
        info


    # TODO not sure here distInfo2Pt are distances ok?
    distInfo2Pt: (pt,pts=@pts,start=0,stop=pts.length)->
        len= pts.length
        stop= if stop <= 0 then len-stop else min(len,stop)
        if stop ==  len
            if pt.x == (endpt=pts[len-1]).x and pt.y==endpt.y
                #log "end point guest hit"
                return {x:pt.x,y:pt.y,u:0,uo:0,uf:0,end:true,dist:0,idx:len-1}
        dist= Infinity
        pts= pts
        a= pts[start]
        for i in [start+1 ... stop] by 1\
            when (next= distInfo2Pt(a,(a=pts[i]),pt)).dist < dist
                if next.u < 1
                     next.idx= i-1; next.end= false; next.uf= next.u
                else next.idx= i  ; next.end=true  ; next.uf= 0
                dist= next.dist
                info= next
                break if dist == 0 #cant do better than that
        info

    #Persistance

    _p: null # ['',''] ptStr encoded Pts
    _px: null


    makePtsJSON:-> [ @_p0(), @_p1() ]

    _p0: -> P.toLStr ( a.x for a in @pts ),MapScale,@bounds.x
    _p1: -> P.toLStr ( a.y for a in @pts ),MapScale,@bounds.y

    _p0x: -> P.toLStrB ( a.x for a in @pts ),MapScale,@bounds.x
    _p1x: -> P.toLStrB ( a.y for a in @pts ),MapScale,@bounds.y

    decodePtsStr:->
        #log "Decode ptstr"
        #throw new Error "stop at deco"
        return [] if !@_p and !@_px

        switch
            when @_p
                apts= P.fromLStr @_p[0],MapScale,@bounds.x
                bpts= P.fromLStr @_p[1],MapScale,@bounds.y
                @_p= null
            when @_px
                apts= P.fromLStrB @_px[0],MapScale,@bounds.x
                bpts= P.fromLStrB @_px[1],MapScale,@bounds.y
                @_px= null

        for a,i in apts
            Pt._ a,bpts[i]


    toJSON: ->
        id:    @id
        start: @start
        end:   @end
        data:   @data
        oneway: @oneway if @oneway
        bounds: @bounds
        dist:   @dist
        _p: @_p or @makePtsJSON()
        _splitSegId:    @_splitSegId    if @_splitSegId
        _splitSegStart: @_splitSegStart if @_splitSegId
        _splitSegEnd:  @_splitSegEnd   if @_splitSegId




module.exports=  { Pt, Node, SplitNode,  Segment } 

