{ log }= vx= require 'vx/globals/Boot'  # to get a global ref to HelloWorld that survies webpack

{ dateAsVersionNumber }= require '../Ajax'

# I18n
{ sayModule }= require 'vx/i18n/I18n'
say= sayModule 'App' # refactored

UI= require 'vx/UI'

# UI layout
{ sidePanel, header2, tbTitle, tbIcon, content }= UI

# html blocks
{ p, h5 }= UI # pass through require 'DOMTags'

# html inline
{ span }= UI

#htmlctrls
{ button, details, summary, group, icon, link }= UI


module.exports= MenuPanel:({app,menuVisible,message,lang,admin,adminPage})->

    sidePanel '#menu  .mdc-elevation--z8',active:menuVisible,
        header2
            onClick: app.toggleMenu
            left:        tbTitle null,say 'Menu'
            rightShrink: tbIcon  'icon=cancel'

        content '', #
            group '.mdc-elevation--z4 ',onClick:app.toggleMenuWait,
                link 'href=#map',       button 'flat', say "menu item mapgoogle"     if vx.isBROWSER
                link 'href=#circuits',  button 'flat', say "menu item list circuits" if vx.isAPI
                link 'href=#itin',      button 'flat', say "menu item itin"          if vx.use.itin
                link 'href=#parametres',button 'flat', say "menu item parametres"    if vx.use.parametres
                button {flat:true,onClick:app.doShowMessage},
                                                       say "menu item message"       if vx.use.message and message and !vx.uiMode.admin
                #button 'flat href=#aide',   say "menu item aide"            if vx.use.aide
                link "href=#{if lang is 'fr' then vx.urls.AideFR else vx.urls.AideEN } target=aidefcmq",
                                        button 'flat', say "menu item aide"          if vx.use.aide
                link 'href=#admin',button '#admin-btn flat',say "menu item admin"    if admin and adminPage?

            details '#menu-urgence .mdc-elevation--z4 .urgence open',
                summary h5 '.vx-center',say "titre page urgence"
                p '.vx-center',say "urgence txt"
                

