{ meta, log, fromJS  }= Mod= vx= require 'vx/globals/Boot'


{ say }= require 'vx/i18n/I18n'




class CouchesStateManager


    defaultState:
        couches:[]


    urlAPIVars:

        ecosentiers:(val)->
            val=fromJS val
            log "CondManager urlAPI got cond= #{typeof val} #{val}"
            if val
                @setLayerVisibility true,'ecosentiers'


    _parseCouches: (couches)->
        if typeof couches is 'string'
            couches= couches.split ','
        if couches.length and typeof couches[0] is 'string'
            couches= for couche in couches when typeof couche is 'string'
                sCouche= couche.split '-'
                if !isNaN op=parseInt sCouche.pop()
                    [(sCouche.join '-'),op]
                else [couche]
        couches


    setCouches: (couches)->
        couches= @_parseCouches couches
        #TODO check for changes and valid structure
        @setState {couches}


    getCouches: -> @state.couches

    onBeforeMountDo: ['initCouches']

    initCouches:->
        #log "!!!!!!!!!!!!!!!!!!! CouchesStateManager.onBeforeMountDo got couchhes=#{@state.couches}",{self:@,@state}
        if @state.couches
            @state.couches= @_parseCouches @state.couches


coucheLayerFactory= (MapWidgets)->

    { TileLayer }= MapWidgets

coucheLayer= ({couche,op,macros,online,TileLayer,tileLayer})->

    { url,minZoom=5,maxZoom=17,tileSize=256,zoomOffset=0,zIndex=6,opacity=100,isOnline=true,type='',fn=false}= macros[couche]
    opacity= op if op?
    switch type
        when 'vx' then fn= TileLayer::basicVXTileUrlFn

    ops=
        key: "couche-#{couche}"
        visible:   !isOnline or online
        tileURL: url
        getTileUrl: fn
        minZoom: minZoom
        maxZoom: maxZoom
        tileSize: tileSize
        zoomOffset: zoomOffset
        updateWhenZooming: false
        updateWhenIdle: false
        opacity:opacity/100
        zIndex: zIndex

    log "coucheLayer got ops=",ops

    tileLayer ops


module.exports= { CouchesStateManager, coucheLayer }
