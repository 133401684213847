{ meta, log, oA, lang, sList }= Mod= vx= require 'vx/globals/Boot'



{ parseQS }= require 'vx/tools/URLTools'

{ getV1EncodedState, getV2EncodedState, getAnyEncodedState, asV2EncodedState }= require 'vx/tools/URLencoders'

{ Bounds }= require 'vx/math/Bounds'

{ Pt,  simplifySamePixelPts, simplifyInlinePts }= require 'vx/graph/Graph5'

{ VItin }= require 'vx/comp/itin/Router'

{ nomCircuits2reg }= require 'vx/globals/Conversions'

P= require 'vx/tools/Persistance'


stateFromQS= (itinQS)->

    {s,r} = parseQS itinQS,{},1
    log "stateFromQS r=#{r}"

    state= getAnyEncodedState s,r
    if not state
        state= {waypoints:[[-71,47],[-72,48]]}

    state



meta class Circuit

    @stateFromObj= (obj)->
        #log "Circuit.creatFrom '#{obj.nom_circuit_fr}'"

        itinURL= obj.url_circuit_final or obj.url_circuit_ancien

        return {} if !itinURL

        log "itinURL=#{typeof itinURL} #{itinURL}"
        itinQS= itinURL.split('?')[1]?.split('#')[0]

        return {} if !itinQS

        #log "Circuit.creatFrom '#{obj.nom_circuit_fr}' got itinQS=#{itinQS}"

        state= stateFromQS itinQS



        #log "Circuit.creatFrom '#{obj.nom_circuit_fr}' got r=#{r} s=#{s}"

        #log "Circuit.creatFrom '#{obj.nom_circuit_fr}' got state=\n#{JSON.stringify state,0,2}"


        #path cleanup waypoints TODO trouver attraits
        state.waypoints= ( wp for wp in state.waypoints when wp and typeof wp isnt 'string' )

        state.wPts= ( Pt._ x,y for [y,x] in state.waypoints )

        state.itinURL= itinURL
        state.itinQS= itinQS


        state

    @createFromP= (obj,router)->

        try
            if not router
                throw new Error "No router"

            { waypoints,wPts }= state= @stateFromObj obj

            if not waypoints or not waypoints.length
                throw new Error "No waypoint"
        catch err
            return Promise.reject err


        cls= @
        VItin.P wPts,router
        .then (itin)->  cls.makeParcours obj,state,itin
        .catch (err)->
            log "CircuitModel.Circuit.createFromP got error #{err}",err
            null


    @createFrom= (obj,router)->

        { waypoints, wPts }= state= @stateFromObj obj

        i={}

        if router and waypoints?.length
            itin= VItin._ wPts,router
            #log "Created itin dist=#{itin.totalDist}"

        @makeParcours obj,state,itin




    @makeParcours= (obj,state,itin)->

        if itin
            dist= itin.totalDist
            segs= ( path.segs for path in itin.paths )
            opts= itin.getPts()

            ops2= ( p for p in opts when p)
            if opts.length isnt ops2.length
                log " Error************************************ missing pt ******"
                opts= ops2

            if opts.length
                _pts=   simplifyInlinePts (simplifySamePixelPts opts,14),14
                _pts12= simplifyInlinePts (simplifySamePixelPts opts,11),11
                _pts8=  simplifyInlinePts (simplifySamePixelPts opts,8),8

        data= oA {},obj,
            allurl: (obj.url_fiche_alliance if obj.url_fiche_alliance)
            allurl_en: (obj.url_fiche_alliance_en if obj.url_fiche_alliance_en)
            ville_depart:  obj.ville_depart or ""
            i_all: if obj.incontournable_alliance?.trim() then 1 else undefined
            c_all: if obj.circuit_alliance?.trim()  then 1 else undefined
            i_atr: if obj.incontournable_atr?.trim() then 1 else undefined
            href_atr:    obj.url_fiche_region?.trim() or undefined
            href_atr_en: obj.url_fiche_region_en?.trim() or undefined

        for k in sList 'url_circuit_final url_circuit_ancien nom_circuit_fr nom_circuit_en description_fr description_fr description_en id_interne_vx url_fiche_region url_fiche_region_en url_photo url_fiche_alliance url_fiche_alliance_en incontournable_alliance circuit_alliance incontournable_atr'
            delete data[k]


        eState= stateFromQS state.itinQS
        eState.waypoints= ( {lat,lng} for [lat,lng] in eState.waypoints )
        eStateStr= asV2EncodedState eState


        parcours=
            __proto__: @::
            Titre:    obj.nom_circuit_fr
            Titre_en: obj.nom_circuit_en
            desc:     obj.description_fr #JSON.parse("\"#{obj.description_fr}\"").replace /''/g,'"'
            desc_en:  obj.description_en #JSON.parse("\"#{obj.description_en}\"").replace /''/g,'"'
            membre:   nomCircuits2reg[obj.region] or '?'
            id:       obj.id_interne_vx
            fcmqurl:  state.itinURL
            vxurl:    obj.url_fiche_region
            photo:    obj.url_photo
            waypoints: ( {lat,lng} for [lat,lng] in state.waypoints ) #just start
            viewBounds:  state.mapBounds  #if mb= state.mapBounds then [ mb.x, mb.y, mb.X, mb.Y ] else undefined  #TODO viewBounds vs bounds
            bounds:    itin?.getBounds()
            dist:      dist
            #segs: segs
            _pts:   _pts
            _pts12: _pts12
            _pts8:  _pts8
            encodedState: eStateStr
            #state:     state
            data: data
#                 allurl: (obj.url_fiche_alliance if obj.url_fiche_alliance)
#                 allurl_en: (obj.url_fiche_alliance_en if obj.url_fiche_alliance_en)
#                 no_sentier:    obj.no_sentier
#                 genre_circuit: obj.genre_circuit
#                 ville_depart:  obj.ville_depart or ""
#                 i_all: if obj.incontournable_alliance?.trim() then 1 else undefined
#                 c_all: if obj.circuit_alliance?.trim()  then 1 else undefined
#                 i_atr: if obj.incontournable_atr?.trim() then 1 else undefined
#                 href_atr:    obj.url_fiche_region?.trim() or undefined
#                 href_atr_en: obj.url_fiche_region_en?.trim() or undefined




    toJSON: ->
        oA {},@, { _pts:undefined, _pts8:undefined, _pts12:undefined},(if @pts and @pts.length
            _spts:  @_encode @pts
            _spts8: @_encode @pts8 if @pts
            _spts12:@_encode @pts12 if @pts
            )


    _encode: (pts)->[
            P.toLStr (p.x for p in pts),1000000,-71.1
            P.toLStr (p.y for p in pts),1000000,46.9
            ]


    _decode: (name)->
            spts= @[name]
            return [] if !spts?.length
            [xs,ys]=spts
            @[name]= undefined
            xa= P.fromLStr xs,1000000,-71.1
            ya= P.fromLStr ys,1000000,46.9
            ( {x, y:ya[i]} for x,i in xa )


    pts__get:   -> @_pts?=  @_decode '_spts'
    pts8__get:  -> @_pts8?= @_decode '_spts8'
    pts12__get: -> @_pts12?=@_decode '_spts12'

    i_all__get: -> @data?.i_all
    c_all__get: -> @data?.c_all
    i_atr__get: -> @data?.i_atr

    @fromJSONArr: (circuitsData)->
        #log "Circuit.fromJSONArr got #{circuitsData.length} this="
        ret=for c in circuitsData when c
            if !c._spts
                log "SKIPPING #{c.Titre} in #{c.membre} no _spts"
                continue

            #log " read #{c.id}"
            oA {__proto__: @::},c,
                Titre_fr: c.Titre
                Titre:    if lang is 'en' then c.Titre_en or c.Titre_fr else c.Titre or c.Titre_en
                desc_fr:  c.desc
                desc:     if lang is 'en' then c.desc_en or '' else c.desc or ''
                bounds:      if c.bounds     then Bounds.fromJSON c.bounds
                viewBounds:  if c.viewBounds then Bounds.fromJSON c.viewBounds
        #log "ret =#{ret.length}"
        ret


circuitsFromJSONArr= Circuit.fromJSONArr.bind Circuit
circuitsFromJSONArr.vx_name= 'circuitsFromJSONArr' #for db server
#log "loaded CicuiModel circuitsFromJSONArr=#{typeof circuitsFromJSONArr}"

module.exports= { Circuit, circuitsFromJSONArr }


# TODO: keep this ? useful?


# # Parcours v2.1 obj
#
#     "Titre":
#     "membre":
#     "dist":
#     "id": "parcours.CDQ.Appalaches",
#     "desc": "",
#     "waypoints":
#     "bounds":
#     "fcmqurl": "http://gd.fcmqapi.ca?s=:(*(;CRRU:g1:COdS:mV)(:ssQr:Zp;BDv;Ebq;EY9;BTH:CFQ:BWu:CgI:E1u)(;tVwW:Dpw:B7y:N9;DnN;ETN:BLp;Cn6:N9:DVT):::::*)~",
#     "encodedState": "(*(;CRRU:g1:COdS:mV)(:ssQr:Zp;BDv;Ebq;EY9;BTH:CFQ:BWu:CgI:E1u)(;tVwW:Dpw:B7y:N9;DnN;ETN:BLp;Cn6:N9:DVT):::::*)~",
#     "vxurl": "https://www.tourismecentreduquebec.com/fr/itineraires-motoneige/aux-contreforts-des-appalaches.aspx"
#
#     #pour planifier facilement
#     "Centre-du-Québec":
#     "Charlevoix":
#     "Chaudière-Appalaches":
#     "Gaspésie":
#     "Lanaudière":
#     "Mauricie":
#     "Québec":
#     "Saguenay-Lac St-Jean":
#     "Peu habités":
#     "Près des villes":
#     "Pêche sur glace":
#     "Spa":
#     "Glissades":
#     "Tables du terroir":
#     "Boisé":
#     "Avec dénivelé":
#     "Points de vue":
#     "Nos coups de cœur":
#
#
#
#
# # Parcours V2.2 csv  ? = optionnel
#
#
#     Region
#     Nom_du_circuit_FR
#     Nom_du_circuit_ANG
#     id
#     URL_Itin
#     URL_Alliance ? si vide pas dans site alliance
#     URL_Region   ?
#     id_VX_v1     ?
#     Description_FR
#     Description_ANG
#     photo
#     photo2 ?
#     photo3 ?
#     prop autres colones non _
#
#
#
#
# # Parcours v2.2 obj
#
#     "titre": # etatit Titre
#     "titre_en":
#     "membre":
#     "zone":
#     "type":
#     "dist":
#     "id":
#     "desc":
#     "desc_en":
#     "fcmqurl": #itin
#     "encodedState":
#     "itin":
#         "waypoints":
#         "router":
#         "fcmqurl": #itin
#         "encodedState":
#     "bounds":
#     "siteWeb": #url ATR
#     "photo":
#     prop:
#         allurl
#         vxurl
#         idv1
