{ log, isArray }= vx= require 'vx/globals/Boot'  # to get a global ref to HelloWorld that survies webpack

{ dateAsVersionNumber }= require '../Ajax'

# I18n
{ sayModule }= require 'vx/i18n/I18n'
say= sayModule 'App' # refactored

UI= require 'vx/UI'

# UI layout
{ sidePanel }= UI


{ circuitsListCustom, circuitsFiltreUI }= require 'vx/comp/circuits/CircuitsUI'  if vx.use.circuits # circuitsList, circuitsList3,


module.exports=

    CircuitsListPanel:({app,circuits,circuitsDecorations,circuitsListVisible,vxScreenWidth,allListCircuits,circuitsFilter}={})->
        sidePanel '#circuitslist .mdc-elevation--z8 ',
            active:( circuitsListVisible or ( vxScreenWidth=='wide' and allListCircuits)),
            circuitsListCustom '',
                app: app
                circuits: circuits
                autoZoom: 'asked'
                decos:    circuitsDecorations
                onClose:  app.toggleCircuitsList
                filtered: circuitsFilter
                membre:   if (reg=circuitsFilter?.membre) then (if isArray reg then reg[0] else reg) else undefined
                setHoverId: app.setCircuitHoverId
                ops: app.state.i18nInfo # considered static

    CircuitsFiltrePanel: ({app,circuitsFiltreVisible,circuits,circuitsFilter}={})->
        sidePanel '#circuitsFiltre .mdc-elevation--z8 ',
            active: circuitsFiltreVisible
            circuitsFiltreUI '',
                ops: app.state.i18nInfo # considered static
                app: app
                circuits:  circuits
                #onClose: app.toggleCircuitsFiltreVisibility
                filter:  circuitsFilter
