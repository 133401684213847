{ log, $, meta, oA, oDiffs, sUncamel, sLower, sUncap, sList, lang, defMeta, oAllPropNames }= vx= require 'vx/globals/Boot'


React= require 'react'
{ PureComponent }= React

PropTypes= require 'prop-types'

{ ElementFactory:EF }= require './ReactElemSS'


meta class ReactClass extends PureComponent

    @type: PropTypes or {}

    @meta: (info)->
        # OK add This new class to the FncMap
        # also create and add a construtor to tags
        #if !@hasOwnProperty 'displayName'
        #    @displayName= @::getOwnProperty('name') or @name
        #log "meta ReactClass for #{@name} #{@hasOwnProperty('displayName') and @displayName}"

        #if @hasOwnProperty('displayName') and @.displayName != @name
            #log "meta OUPS #{@name} isnt #{@.displayName}"


        defMeta @

        # coffee v1
        #name= @name
        # coffe v2 ?????
        if @hasOwnProperty 'displayName'
            name= @displayName
        else
             @displayName= name= @name

        if ! @::hasOwnProperty '_className'
            @::_className= sLower sUncamel name

        EF @



    ownProps: (props)-> props # chance to filter out unwanted props


meta class UIComponent extends ReactClass
    @displayName: 'UIComponent'


    @meta: (info)-> # @ is cls
        defMeta @

        # coffee v2
        #autoBinds=( name for name,func of ( @:: ) when name.match /^(do|set|toggle)[A-Z]/ )
        autoBinds=( name for name in (oAllPropNames  @::) when ((typeof name is 'string') and  name.match /^(do|set|toggle)[A-Z]/ ) and (typeof @::[name] is 'function') )
        
        #log "AUTOBINDS=#{autoBinds}"
        if autoBinds.length
             @::_autoBinds= autoBinds
        else @::_autoBinds= false
        super info


    constructor: (props)->
        super props
        if @_autoBinds
            for fname in @_autoBinds
                @[fname]= @[fname].bind @
        return @





module.exports= { UIComponent }

