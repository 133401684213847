{ meta, log, timeStart, timeEnd, oAssign, sSet, sList, sCamel, Global, isSuffix, isPrefix, isArray, aLast, oIsEmpty, oA, $ }= vx= require 'vx/globals/Boot' #, oAllPropNames
ModuleName= 'AppState'

Use= require 'vx/app/IndexUse'

wait= require 'vx/tools/Wait'

PR= require 'vx/tools/Persistance'

UI= require 'vx/UI'
{ max, min, abs }= Math

{  PersistantApp }= UI # pass through require 'DOMTags'
{ parseQS, getV1EncodedState, getV2EncodedState }= require 'vx/tools/URLencoders'

{ sayModule, setLang, I18nStateManager }= require 'vx/i18n/I18n'
say= sayModule ModuleName

{Promise}= require 'vx/tools/NamedPromises'

{ latM, latMInv, pixel2deg, meter2deg }= require 'vx/math/MapGeometry'

{ Bounds }= require 'vx/math/Bounds'     # surface Ways
{ QuadNode }= require 'vx/math/QuadNode' # surface Ways

{ LineStr }= require 'vx/math/LineStr'

LZString=     require 'lz-string-gd'

{ I1toI2 }= require 'vx/app/I1toI2'

#{ doFBStatusResponse, doClearFBState }= require 'FBsdk'

{ GraphSimplify, GraphRouter:Graph, Pt }= require 'vx/graph/Graph5'


{ ItinPStateManager:ItinStateManager }= require 'vx/comp/itin/ItinP'  if vx.use.itin

#{ AttraitStateManager }= require 'Attraits'
{ AttraitStateManager }= require 'vx/comp/attraits/AttraitsFCMQ'     if vx.use.attraits

{ SharingStateManager }= require 'vx/comp/SharingUI'        if vx.use.sharing

{ SurfStateManager }= require 'vx/comp/surf/SurfManager'         if vx.use.surf

{ ConditionsStateManager }= require 'vx/comp/cond/ConditionsManager'  if vx.use.cond

{ GPSStateManager }= require 'vx/comp/GPS'                  if vx.use.gps

#{ RecorderStateManager }= require 'vx/comp/traces/Recorder' if vx.use.recorder

{ CouchesStateManager }= require 'vx/comp/Couches'          if vx.use.couches

{ CircuitsStateManager }= require 'vx/comp/circuits/CircuitsManager' if vx.use.circuits

{ MessageStateManager }= require 'vx/comp/MessageUI'        if vx.use.message

{ FullScreenStateManager, hasFullScreen }= require 'vx/comp/fullScreen/FullScreen'    if vx.use.fullscreen


meta class AppState  extends PersistantApp
    @displayName: 'AppState'
    #
    
    toast:(message,actionText='')->
        log "TOAST: #{message} #{actionText}"
        @snackbarMessage? {message,actionText}

    setStateDebug: (ops,a,b)->
        if typeof ops is 'object'
            keys=Object.keys ops
        else keys= typeof ops
        log "setState  keys=#{keys}",ops
        super ops,a,b

    browserLang= if navigator?.language?.slice(0,2) is 'fr' then 'fr' else 'en'

    log "firstHash=#{vx.q?.firstHash}"

    defaultState: oA { #todo remove should trust globals
        version: '8.44'
        activePage: vx.q?.firstHash or 'map'
        counter:0
        loglist:[]
        classes: {}
        parcours:[]
        traces:[]
        layer_pmn__visibility:  true
        layer_SRPD__visibility: true
        visibleLayers:[]
        bkgMenuPosition: [0,0]
        bkgMenuVisible: false
        mapCenter: {lat:47,lng:-71} #{lat:48.2,lng:-3.16}
        mapZoom:   7
        mapType: 'terrain'
        bkgSourceMode:   1 # 0:local 1: hybride 2: network
        bkgHybrideSplit: 14 # max zoom for local when hybride
        mainMenuVisible: false
        deviceready: undefined
        trackMode:'idle'
        surfaceWays: null
        option_dontSendRecEnd: false
        option_dontSendSave:   false
        user:''
        admin: (window?.location.pathname[0..5] is '/admin') or ( -1 < window?.location.host.indexOf 'admin') or !!vx?.admin
        adminState: 'ready'
        adminMessage: ''
        lang: vx?.lang or browserLang
        meteo:''
        couches:[]
        graph:  null
        debug:0
        option_beta:0
        },vx.defaultState


    persistantState: oA (
        pl= sList 'mapZoom mapCenter mapType user FBuser bkgSourceMode' # meteo
        #for name in sList 'ecosentiers'# 'pmnGraph'
        #    pl.push "layer_#{name}__visibility"
        for name in sList 'dontSendRecEnd dontSendSave modeOdo beta'
            pl.push "option_#{name}"
        sSet pl
        ),vx.persistantState


    urlAPIVars: oA
    
        debug: -> @setState debug:1
        className: (cls)->
            classO={}
            for k in cls.split /\s+/ when kt=k.trim()
                classO[sCamel kt]=1
            @setState
                className:cls
                classes:classO
#         css: (cssList)->
#             for src in  (sList cssList,'|') or []
#                 @addCSS src

        mask:->
            @setLayerVisibility 1,'mask'

        meteo:(meteo)->
            @set meteo,'meteo'

        unit:(unit)->
            log "urlAPIVars got unit=#{unit}"
            @setDistUnit unit,1 # 1=force

        lang:(lang)->
            log "urlAPIVars got lang=#{lang}"
            setLang lang


        centre: (zoomMode)->
            log "urlAPIVars centre=#{zoomMode}"
            app= @
            if typeof zoomMode is 'string'
                zoomMode=zoomMode.split ','

            #log "zoomMode= #{typeof zoomMode} #{zoomMode}"
            [lat,lng,zoom]= zoomMode or []
            lat= parseFloat lat
            lng= parseFloat lng
            #log "lat=#{lat} isNaN lat=#{isNaN lat} lng=#{lng} isNaN lng=#{isNaN lng}"
            if isNaN(lat) or isNaN(lng)
                log "centre lat,lng = #{lat},#{lng} invalide ignore"
                return

            #log "seting map center to #{lat},#{lng}"
            @setMapCenter {lat,lng}

            scale=null
            if zoom?
                if typeof zoom is 'string' and ( aLast(zoom) is 'm' or aLast(zoom) is 'i' )
                    scale= switch
                        when isSuffix zoom,'km' then 1000
                        when isSuffix zoom,'m'  then 1
                        when isSuffix zoom,'mi' then 1609
                else if !isNaN parseInt zoom
                         @setMapZoom parseInt zoom
                else log "centre zoom=#{zoom} ignored"

            return if !scale?
            # we have a scale so we are going to do a fit bounds

            mRadius= scale*parseFloat zoom
            dlng= meter2deg * mRadius
            latm= latM lat
            zbnds= Bounds._ lng-dlng,(latMInv latm-dlng),lng+dlng,(latMInv latm+dlng)
            app= @
            ((Promise.vxGet 'main-gmaps-map').then ->app.fitMapBounds? zbnds) if zbnds

        doClick: (id)->
            log "doClick id=#{id}"
            setTimeout (->
                form= $("##{id}")
                log "doClick elem",form
                form?.click?()
                ),2000

        # Carefull add overides
        vx.urlAPIVars


#     addCSS: (src)->
#         sheet=  document.createElement 'link'
#         self= @
#         oA sheet,
#             rel:  'stylesheet';
#             #type: 'text/css';
#             #media:'screen';
#             href:  src
#             onerror: (err)-> log "Error loading css #{src} err=#{err} ",err
#             onload: ->
#                 log "loaded #{src}"
#                 breakPoints=self.adjustCSSBreakPoints()
#                 self.setState breakPoints
#         $('head').appendChild sheet

    log "class AppState adding state managers vx.use= #{(k for k,v of vx.use when v)}"  if vx.isDEV

    @addStateManager I18nStateManager::

    (@addStateManager AttraitStateManager::)  if vx.use.attraits

    (@addStateManager ItinStateManager::)     if vx.use.itin

    (@addStateManager SharingStateManager::)  if vx.use.sharing

    (@addStateManager SurfStateManager::)     if vx.use.surf

    (@addStateManager ConditionsStateManager::) if vx.use.cond

    (@addStateManager CircuitsStateManager::) if vx.use.circuits

    (@addStateManager CouchesStateManager::)  if vx.use.couches

    (@addStateManager GPSStateManager::)      if vx.use.gps

    (@addStateManager RecorderStateManager::) if vx.use.recorder

    (@addStateManager FullScreenStateManager::)    if vx.use.fullscreen and hasFullScreen

    (@addStateManager MessageStateManager::)     if vx.use.message


    # TODO wrap meteo in a statte manager

    log "AppState added stateManagers" if vx.isDEV # @::=keys=#{Object.keys @::}\n allProps= #{oAllPropNames @::}\n all keys=#{(k for k,v of (@::) when v?).sort()}"

    MeteoLayers: false


    getStateInfo: ->
        log "getState info"

        waypoints: @getItinWaypoints()
        mapBounds: Bounds.fromNative @getMapBounds()
        visibleGroups: @getVisibleGroups()
        ecosentiers: @getLayerVisibility 'ecosentiers'
        surfaceuses: @getSurfVisibility() if vx.use.surf
        meteo:       @get 'meteo'


    _wpArr2wps: (wpArr)->
        ids= []
        wps= []
        for wp in wpArr
            if wp.length>2
                wpId= wp[2]
                #find position or skip
                if !attrait= @getAttraitById wpId
                    if wp[0]? and wp[1]?
                        log "Replaced attrait id=#{wpId} does not exist using old position"
                        ids.push 0
                        wps.push [wp[0],wp[1]]
                    else
                        log "Removed attrait id=#{wpId} does not exist and no position"
                        continue
                else
                    ids.push wpId
                    wps.push [attrait.pt.y,attrait.pt.x]
            else
                ids.push 0
                wps.push wp
        log "_wpArr2wps  did",{wps,ids,wpArr}
        {wps,ids}


    clearBeforeSetStateInfo:(cb)->
        #reset all vars that are part of  state info
        newState=
            meteo: ''
            visibleLayers: []
        for layer in ['ecosentiers','surfaceuses']
            newState["layer_#{layer}__visibility"]= false

        @setState newState

        @clearVisibleGroups()

        @clearItin cb


    newStateInfo:(info)->
        #we must sync changes
        log "newStateInfo info=",info
        self=@
        @clearBeforeSetStateInfo ->
            log "newStateInfo now info=",info
            self.setStateInfo info


    setStateInfo: (info)->
        log "AppState.setStateInfo got",{info,itin:@state.itin,wps:@state.itin?.waypoints}
        for k,v of info
            switch k
                when 'meteo' then @set v,'meteo'
                when 'ecosentiers'
                    @setLayerVisibility v,k
                when 'surfaceuses'
                    @setSurfVisibility v if vx.use.surf
                when 'waypoints'
                    if v.constructor isnt Array
                        {wps,ids}= v
                    else
                        #ok formmat check if contains an id
                        break for w in v when hasStr=w.length>2
                        if hasStr and (Promise.vxGet 'attraits').vxState is 'pending'
                            #do wait stuf
                            log "going to wait on attraits"
                            (Promise.vxGet 'attraits').then do(v,self=@)->->
                                #log "wait on attraits ended att=",self.getAttraits()
                                {wps,ids}= self._wpArr2wps v
                                if self.state.itin
                                    log "Delayed UPDATE ITIN we got",{wps,ids}
                                    self.setItinData {wps,ids,i:1}
                                else
                                    log "Delayed clober initData",{wps,ids}
                                    self.forceItinData {wps,ids,i:1}
                            continue
                        #Ok we can continue if we encounter an id
                        # its ok to handle it
                        #create wps and ids
                        {wps,ids}= @_wpArr2wps v

                    #wps and ids are set
                    if @state.itin
                        #log "UPDATE ITIN we got",{wps,ids,itin:@state.itin}
                        @setItinData {wps,ids,i:1}
                    else
                        #log "OK clober initData",{wps,ids,itin:@state.itin}
                        @forceItinData {wps,ids,i:1}


                when 'mapBounds'
                    log "setStateInfo mapBounds=",v
                    if ! (v instanceof Bounds)
                        if isArray v
                            [x,y,X,Y]= v
                        else
                            {x,y,X,Y}= v
                        v= Bounds._ x,y,X,Y
                    Promise.vxGet 'main-gmaps-map'
                        .then do(v)->
                            #log "adding mapVounds promise for main-gmaps-map v=",v
                            (mapWidget)->
                                #log "doing mapVounds promise for main-gmaps-map v=",v
                                mapWidget.fitBounds v
                    Promise.vxGet 'main-leaflet-map'
                        .then do(v)->
                            #log "adding mapVounds promise for main-leaflet-map v=",v
                            (mapWidget)->
                                #log "doing mapVounds promise for main-leaflet-map v=",v
                                mapWidget.fitBounds v
                when 'visibleGroups' then @setVisibleGroups v
                when 'attraitsFiltre' then @setAttraitsFiltre v
                #when 'circuitsProposedIds' then @setCircuitsProposedIds v
                when 'couches'
                    #log "AppState.setStateInfo doing couches=",oldv=v
                    @setCouches v
                else
                    log "AppState.setState ignored #{k}=#{v}"



    stateRestore: (stateName,props,defaultState)->
        #log "App.stateRestore domain=#{vx.mapMode.domain}"
        ret= super stateName,props,defaultState
        @initVisibleLayers()
        setLang @state.lang
        #log "App.stateRestore @state=",@state if vx.isDEV
        @state


    initVisibleLayers:->
        prefix= 'layer_'
        suffix=  '__visibility'
        visibleLayers=[]
        for layerName of @state when (isPrefix layerName,prefix) and isSuffix layerName,suffix
            visibleLayers.push layerName[prefix.length ... -suffix.length]
        @state.visibleLayers= visibleLayers
        visibleLayers



    checkEncodedState:(qs=parseQS())->

        sInfo= @getEncodedState qs

        if oIsEmpty sInfo
            log "AppState.checkEncodedState version  state got nothing"
        else
            #log "AppState.checkEncodedState  state got ",sInfo
            if (sInfo.time? and ((new Date)-sInfo.time)>10000) and !sInfo.dontAsk
                # ask before doing unpadte
                if ( (navigator.confirm or Global.confirm) say "message changer config carte" )
                    @newStateInfo sInfo
            else
                @newStateInfo sInfo



    getEncodedState:(qs)->
        { s:urlState,r} = qs
        log "AppState.checkEncodedState r=#{r} s= #{typeof urlState}:#{urlState?.length}",qs

        if !r? and (typeof urlState is 'string') # and urlState not of @state.macros
            # try to auto detect r
                try
                    S= LZString.decompressFromEncodedURIComponent urlState
                    first= S.split(',',1)[0]
                    switch
                        when first.indexOf(':')>-1 #we have and object
                            r='1'
                        when not isNaN (firstI= parseInt first)
                            r=(firstI+1).toString()
                        else r=null
                catch err
                    r= null


        switch
            when (typeof urlState is 'object') and urlState[0] is 1
                #version 2 of urlState
                v=2
                sInfo= getV2EncodedState urlState
                #log "did getV2EncodedState got=",{urlState,sInfo}
#             when ( typeof urlState is 'string') # and urlState of @state.macros
#                 v= 'macro'
#                 sInfo= @state.macros?[urlState]
            when ( typeof urlState is 'string') and  ( r is '2' )
                v=2
                sInfo= getV2EncodedState urlState
                #log "did getV1EncodedState got=",{urlState,sInfo}
            when ( typeof urlState is 'string') and  ( r is '1'  )
                v=1
                sInfo= getV1EncodedState urlState
                sInfo= I1toI2 sInfo if sInfo
                #log "did getV1EncodedState got=",{urlState,sInfo}
            else
                v= say 'unknown format'
                sInfo= null
                #log "AppState.checkEncodedState format not recocnnized update page/app state=",urlState

        sInfo


    constructor:(props)->

        super props

        @state.deviceready= Promise.vxState @,'deviceready'

        #log "out App constructor " # state=#{JSON.stringify @state}"

        #if vx.isDEV or vx.setMyApp
        vx.MyApp= @
        return @

    popCnt=1


    componentDidMount: ->
        super()
        # all inits done to check url for state change


        @checkEncodedState()

        self= @
        (Promise.vxGet 'deviceready').then ->

            #log "start Appstate.didmount & deviice ready history.length is #{Global.history.length}"
            self.historyPos= Global.history.length

            d= document # Cordova events
            #d.addEventListener 'pause',      self.doPause
            #d.addEventListener 'resume',    self.doResume
            d.addEventListener 'backbutton', self.doBackButton
            #d.addEventListener 'backbutton', self.doBackButtonCapture,true

            # standard html5 events
            w= window
            #w.addEventListener 'beforeunload',self.doBeforeUnload
            #w.addEventListener 'unload',      self.doUnload
            w.addEventListener 'popstate',    self.doPopState


            if !Global.universalLinks?
                log "universalLinks not present"
            else
                #log "universalLinks installed=#{!!universalLinks}",universalLinks
#                 universalLinks.subscribe 'didLaunchAppFromLink',(data)->
#                     #log "universalLinks Got didLaunchAppFromLink data=",data
#                     url= data.url
#                     qs= (url.split '?')[1]?.trim()
#                     log "Got unversal link qs=#{qs}"
#                     self.checkEncodedState parseQS qs
                Global.universalLinks.subscribe 'setinfo',self.doSetState

            self.lastPos=1
            if Global.history?
                #log "do set popCnt to #{popCnt}"
                Global.history?.replaceState {popCnt:self.lastPos},null,null
                #log "state now is #{Global.history.state}",Global.history
            #else
            #    log "no history???",Global.history

    doSetState: (data)->
        log "universalLinks Got setInfo data=",data
        url= data.url
        qs= (url.split '?')[1]?.trim()
        log "Got unversal link qs=#{qs}"
        @checkEncodedState parseQS qs

    doPause: (e)-> log "AppState.init Pause event e=",e
    doBackButton: (e)-> log "AppState.init BackButton  history.length=#{Global.history.length}  event e=",e
    doBackButtonCapture: (e)-> log "AppState.init BackButtonCapture event e=",e
    doUnload: (e)-> log "AppState.init Unload event e=",e
    doBeforeUnload: (e)-> log "AppState.init BeforeUnload event e=",e

    doPopState: (e)->
        #log "AppState.init PopState history.length=#{Global.history.length} event e=",e
        if e.state is null
            #log "setting popCnt to #{@lastPos+1}"
            Global.history.replaceState {popCnt:++@lastPos},null,null
        else
            #log "current popCnt is #{e.state.popCnt}"
            @lastPos= e.state.popCnt

    doExit: ->
        log "do exit"
        if navigator?.app?.exitApp?
            navigator.app.exitApp()
        else if Global.history?
            log "doing navigator history go -#{Global.history?.state?.popCnt}"
            if Global.history?.state?.popCnt?
                Global.history.go -Global.history.state.popCnt
    # ***** Options *****

    getOption: (name)-> @state["option_#{name}"]
    setOption: (val,name)-> @setState "option_#{name}":val

    getUser: -> @state.user or @state.FBuser
    setUser: (user)-> @setState {user}

    getCounter: -> @state.counter #??
    setCounter: (val)-> @setState 'counter':val
    incCounter: ->
        #log " --------------- In inc counter"
        @setState (state)-> 'counter':1+(state.counter or 0)

    getNetworkState: ->
        return 'offline' if !@getDeviceReady()
        @state.networkState

    # Layers

    getLayerVisibility: (name)->
        name= name.replace /\s/g,'_'
        @state["layer_#{name}__visibility"] or false


    setLayerVisibility: (val,name)->
        name= name.replace /\s/g,'_'
        #log "!!!!!!!!!!!!!!!!!! setLayerVisibility #{name} to #{val}"
        layerName= "layer_#{name}__visibility"
        if val
            if not @state[layerName]
                @setState
                    "layer_#{name}__visibility":val
                    visibleLayers:@state.visibleLayers.concat name
        else
            if @state[layerName]
                vls= [].concat @state.visibleLayers
                vls.splice (vls.indexOf name),1
                @setState
                    "layer_#{name}__visibility":val
                    visibleLayers:vls


    getVisibleLayers: -> @state.visibleLayers



    # **** Parcours

    getParcours: ->@state.parcours
    setParcours: (parcours)->
        #log " AAAAAAAAAAAAAAAAa setParcours got",parcours
        @setState {parcours}

    getSelectedParcoursId: -> @state.selectedParcoursId or false

    setSelectedParcoursId: (id)->
        @closeAllMenus() if id
        @setState selectedParcoursId: id or false

    toggleSelectedParcoursId: (id)->
        if !id or id is @state.selectedParcoursId
            id= false
        @setSelectedParcoursId id

    setEncodedParcours: (data)->
        SCALE= 100000
        fromEpts= (epts)->
                ( PR.DecodeObjArr {lat:SCALE,lng:SCALE} ) {lat:(l for l in epts by 2 ), lng:( l for l in epts[1 ..] by 2 )}

        for par in data
            par.pts= fromEpts par.epointsarr
            par.epointsarr= null

        @setParcours data


    # **** Map ops *************************

    setMapCenter: (mapCenter,name,ctrl,dontCheckTrack)->
        if mapCenter.lat and typeof mapCenter.lat is 'function'
            mapCenter= {lat:mapCenter.lat(),lng:mapCenter.lng()}
        #log " ------------------------ Setting mapcenter from #{@state.mapCenter} to #{mapCenter}"
        @setState { mapCenter }
        # see gps
        if !dontCheckTrack and @state.trackMode is 'track' and mapCenter and @state.gpsPos and !@close2 @state.gpsPos,mapCenter
            @setTrackMode 'idle'
    getMapCenter: -> @state.mapCenter
    getMapCenterLat: ->
        if (c=@state.mapCenter) and c.lat?
             #if typeof c.lat is 'function' then c.lat() else c.lat
             c.lat
        else null

    getMapCenterLng: ->
        if (c=@state.mapCenter) and c.lng?
             #if typeof c.lng is 'function' then c.lng() else c.lng
             c.lng
        else null

    setMapBounds: (bounds,name,ref)->
        # carefull this updates the state IT DOES NOT move the map
        #log "AppState.setMapBounds from #{name} =",bounds
        if !bounds.x?
            throw new Error 'invalide bounds'
        {x,y,X,Y}= bounds
        mBounds= Bounds._ x,(latM y),X,latM Y
        objMBounds= mBounds.grow mBounds.width(),mBounds.height()
        {x,y,X,Y}= objMBounds
        objBounds= Bounds._ x,(latMInv y),X,latMInv Y

        @setState
            mapBounds:    bounds # for now we dont update the map bounds
            mapObjMBounds:objMBounds
            mapMBounds:   mBounds
            mapObjBounds: objBounds

    getMapBounds: ->    @state.mapBounds
    getMapMBounds: ->   @state.mapMBounds
    getMapObjMBounds:-> @state.mapObjMBounds
    getMapObjBounds:-> @state.mapObjBounds


    setMapSearch: (place)->
        loc= place?.geometry?.location
        if !loc and place?.name
            [lat,lng] = ( parseFloat n for n in (place.name or '').split ',')
            if lat and lng
                place={geometry:{location:{lat,lng}},name:place.name}
            else if place.name[0] is 's'
                sid= parseInt place.name[1..]
                log "setMapSearch seg=#{place.name} sid=#{sid} ",seg=@state.graph?.segments?[sid]
                if !isNaN(sid) and seg=@state.graph?.segments?[sid]
                    pt= seg.middleM?()
                    if pt
                        place={geometry:{location:{lat:pt.y,lng:pt.x}},name:"seg #{place.name[1..]}"}
        @setState mapSearch:place
        if pos= place?.geometry?.location
            @includeMapBounds (Pt.fromNative pos).bounds(),[32,12,0],1 #can zoom

    getMapSearch: -> @state.mapSearch
    getMapSearchPos: ->@state.mapSearch?.geometry?.location

    getMapSearchName: -> @state.mapSearch?.name

    setMapZoom: (zoom)->
        #log "setMapZoom to #{zoom}"
        @setState { mapZoom:zoom }
    getMapZoom: -> @state.mapZoom


    getMapType: -> @state.mapType
    setMapType: (mapType)-> @setState {mapType}

    _addMargins2Bnds: (bnds,margins=[])->

        [top=0,right=top,bottom=top,left=right]= margins
        #log "AppState.includeMapBoundsconvert #{margins} got #{[top,right,bottom,left]}"
        if margins.length
            zoom= @getMapZoom()
            [top,right,bottom,left]= ( pixel2deg n,zoom for n in [top,right,bottom,left] )
            {x,y,X,Y}= bnds
            Bounds._ (x-left),(latMInv -bottom+latM y),(X+right),latMInv top+latM Y
        else bnds

    includeMapPopupBounds: (id,bnds,margins,canZoom)->
        #log "includeMapPopupBounds",{id,bnds,margins,canZoom}
        return if id is @lastIncludeMapPopupBounds
        @lastIncludeMapPopupBounds= id
        return if !bnds
        @includeMapBounds bnds,margins,canZoom

    includeMapBounds: (bnds,margins,canZoom)->
        #log "AppState.includeMapBounds"
        bnds= @_addMargins2Bnds bnds,margins if margins

        mapBnds= @getMapBounds()

        if mapBnds.contains bnds
            #log "AppState.includeMapBounds alreay included",{mapBnds,bnds,bnds,margins}
            return

        if canZoom
            #log "canzoom"
            if @gmapsMap
                 @gmapsMap.fitBounds mapBnds.union bnds
            else if @leafletMap
                @leafletMap.fitBounds mapBnds.union bnds
            return

        #log "no zoom"

        if @gmapsMap
            #log "gmaps iclude bounds"
            @gmapsMap.includeBounds bnds
        else if @leafletMap
            # no iclude in leafet use panTo
            # prefer top left so we can close
            [dx,dy,dX,dY]= mapBnds.overlap bnds

            #dx= min dx,0
            #dy= min dy,0
            #dX= max dX,0
            #dY= max dY,0

            if dX>=0
                 cx= dX
            else if dx<0
                 cx= max dx,dX #dont hide right side
            else cx= 0

            if dY>=0
                 cy= dY
            else if dy<0
                 cy= max dy,dY #dont hide top side
            else cy= 0


            {x,y}= mapBnds.center()
            pt= {lng:x+cx,lat:y+cy}
            #log "leaflet panTo #{pt}",pt
            @leafletMap.panTo pt


        #@setMapBounds newMapBounds



    fitMapBounds: (bnds,margins)->

        bnds= @_addMargins2Bnds bnds,margins if margins

        #log "fit bounds ",{bnds,@gmapsMap,@leafletMap,@mapWidget}
        if @mapWidget
            @mapWidget.fitBounds bnds
        else #map not ready yet

            log " fitMapBounds using delayed promise"
            Promise.vxGet 'main-gmaps-map'
                .then (mapWidget)->
                        log "doing mapVounds promise for main-gmaps-map v=",bnds
                        mapWidget.fitBounds bnds
            Promise.vxGet 'main-leaflet-map'
                .then (mapWidget)->
                        #log "doing mapVounds promise for main-leaflet-map v=",bnds
                        mapWidget.fitBounds bnds


    # Actions *********************************

    doBkgClick: (e)->

        @closeAllMenus()
        @setConditionSelectedId? null
        @setConditionHoverId? null
        @setState
            bkgMenuPosition: if e.latlng then [e.latlng.lat,e.latlng.lng] else [e.latLng.lat(),e.latLng.lng()] # Leaftlet or gmaps
            bkgMenuVisible: true
            bkgMenuIsGPS: false

    #doGPSClick from GPSStateManager


    closeAllMenus: ->
        #log "close all menus"
        @lastIncludeMapPopupBounds= null
        @setState
            bkgMenuVisible:  false
            #bkgMenuPosition:null
            bkgMenuIsGPS: null

            menuVisible:     false
            servicesVisible: false
            servicesCouchesVisible: false
            servicesAttraitsVisible: false
            attraitSelectedId: false
            selectedParcoursId:false
            selectedSeg: null

        @clearItinSelectedWP() if vx.use.itin



    toggleBkgMenu: ->
        log "toggleBkgMenu is #{!!@state.bkgMenuVisible}"
        @closeAllMenus() #if !@state.bkgMenuVisible
        @setState bkgMenuVisible: !@state.bkgMenuVisible

    toggleMenu: toggleMenu= ->
        #log "setting menu to #{!@state.menuVisible}"
        @setState menuVisible:!@state.menuVisible

    toggleMenuWait: wait 0.8,toggleMenu

    toggleServices:->  @setState servicesVisible:!@state.servicesVisible
    toggleCouches:->
        #log "toggle couche self=",@
        ret= @setState servicesCouchesVisible:!@state.servicesCouchesVisible
        #log "after set state ret=",ret
        ret

    toggleAttraits:->  @setState servicesAttraitsVisible:!@state.servicesAttraitsVisible

    toggleSayClick: -> null #log "appstate click",@


    setEnvoyerDialog:(val)-> @setState envoyerDialog: !!val
    getEnvoyerDialog:(val)-> @state.envoyerDialog


    close2: (a,b,dd=50,zoom=@state.mapZoom)->
        return true if !a? or !b? or !zoom?
        z2= 2**zoom*(360/256) # convert to pixels ...
        dlat= z2 * abs (latM a.lat)-latM b.lat
        dlng= z2 * abs a.lng-b.lng

        #log "dlatlng= #{dlat},#{dlng} close2=#{dlat<dd and dlng<dd}"

        dlat<dd and dlng<dd




    # *** Graph layers

    GCnt=0
    debug= false
    setGraph: (graphData,name,info)->
        return if !graphData or !graphData.length
        timeStart 'setGraph'
        graph= Graph.fromJSON graphData if graphData
        timeEnd 'setGraph'
        graph.id= ++GCnt
        if true
            graphSimple= graph
        else
            graphs= GraphSimplify.fromGraph graph
            graphs.simplify zoom:17,dataList:(sList 'cat no_sen numClub e f'),debug:0,mergable:(a,b)->
                if !a.data?.s and !b.data?.s #keepList:(sList 'cat no_sen numClub e f'),
                    true
                else
                    log " merge s skipped on ",{a,b}
                    false
            graphSimple= graphs #GraphX3.fromSegArr graphs.segments



        log "Loaded graph initData=",@state.itinData if debug
        if graph.clubs?
            @setState
                graph: graph
                graphSimple: graphSimple
                clubs: graph.clubs
        else
            @setState
                graph: graph
                graphSimple: graphSimple
        
        log "graph ------------------------- graphbuild id=#{graph.id}"
        #@setItinGraphAsync graph
        @initItinFromGraph graph if vx.use.itin

    setGraphInfo: (info)-> @setState graphFileInfo:info

    getSegId: (id)-> @state.graph?.segments[id]


    setGraphS: (graphData)->
        graph= Graph.fromJSON graphData
        #log 'graph ------------------------- graphsbuild'
        @setState graphS:graph

    setSelectedSeg: (selectedSeg)->
        #log "slected seg is ",selectedSeg
        @setState {selectedSeg}

    # ****** Admin management *****************

    getAdminState: -> @state.adminState
    setAdminState: (state,message)->
        return if !state and !message # ???
        if state
            @setState
                adminState: state
                adminMessage: message or ''
        else @setState adminMessage:message

    getAdminMessage: -> @state.adminMessage or ''


    # Macros

    setMacros: (macros)-> @setState macros
    getMacros: -> @state.macros

    # Couches Manager


    # **** Cordova stuf *************


    getDeviceReady: ->@state.deviceready


    # Surface ways


    doInitLSWays: (lsways)->
        #log "In doInitLSWays = ",lsways
        arrLS=
            for id,way of lsways when way.nodes.length > 0
                ops=
                    highway: way.highway
                    surface: way.surface
                    cycle:   way.cycle
                pts= ( {x:node.lng,y:latM node.lat} for node in way.nodes)
                #log "Doing ls with ",{pts,ops}
                LineStr._ pts,ops


        qdWays= QuadNode._ Bounds.aBNDS( ls.getBounds() for ls in arrLS )
        qdWays.addAll arrLS
        #log " array ls = ",{arrLS,qdWays}
        @setSurfaceWays qdWays

    setSurfaceWays: (sways)-> @setState surfaceWays:sways
    getSurfaceWays: -> @state.surfaceWays





module.exports= { AppState }
