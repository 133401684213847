{ oAssign, log }= vx= require 'vx/globals/Boot'


class HasProps

    @props= (props)->

        hasCached= false

        for name,descr of props


            #if typeof propInfo is 'function'
            #    propInfo= init:propInfo

            descr= oAssign {},descr # make it writable

            if typeof descr.init is 'function'
                @inits= oAssign {},@inits,"#{name}":descr.init
                descr.writable= true
                descr.enumerable?= true

            if descr.noenum or descr.noEnum
                descr.enumerable= false

            if typeof descr.cache is 'function'
                hasCached= true
                cacheFnc= descr.cache
                descr.get= do (name,cacheFnc)-> ->(@__cache or @initCache())[name]?= cacheFnc.call @


            Object.defineProperty @::,name,descr

        if hasCached
            # hmm could always redefine instead of inheriting
            if @hasCached
                log " cls #{@name} already has __cache"
            else
                #adding cached mecanics
                #log " adding __cache to #{@name} ",{cls:@,props,proto:@::,hasCached:@hasCached}
                @hasCached= true
                Object.defineProperties @::,
                    __cache:
                        value:      null
                        writable:   true
                        enumerable: false
        @


    initProps:->
        return if !@constructor.inits
        for name,cls of @constructor.inits when !Object.hasOwnPropery @,name
            @[name]= new cls

    setNoEnum: (name,value)->
        Object.defineProperty @,name,value:value
        value

    initCache: (ret={})->
        Object.defineProperty @,'__cache',value:ret
        ret


opds= (obj)->
    ret={}
    for k in Object.getOwnPropertyNames obj
        ret[k]= Object.getOwnPropertyDescriptor obj,k
    for k in Object.getOwnPropertySymbols obj
        ret[k]= Object.getOwnPropertyDescriptor obj,k
    ret

module.exports= { HasProps, opds  }




