{ log, oA }=vx= require 'vx/globals/Boot'


log 'i18n load start'

tradFr= require 'vx/i18n/TradFR'
tradEn= require 'vx/i18n/TradEN'

log 'i18n loaded  tradFr and tradEn'

if vx?.lang is 'en'
     trad= tradEn
     lang= 'en'
else
     trad= tradFr
     lang= 'fr'



class I18nStateManager

    units= {km:1, mi:0.621371}


    defaultState:
        i18nLang: 'fr' # not used yet
        i18nDistUnit: 'km' # km or mi
        i18nDistScale: 1 # 1 or 0.621371


    persistantState: { i18nDistScale:1, i18nDistUnit:1  }

    stateLoad_i18nDistUnit: (key,stateName)->
        @stateLoad key,stateName
        I18nInfo.distUnit= @state[key]


    stateLoad_i18nDistScale: (key,stateName)->
        @stateLoad key,stateName
        I18nInfo.distScale= @state[key]


    setDistUnit: (Unit,force)->
        #log "I18nStateManager setDistUnit to #{Unit} forc is #{!!force}"
        unit= Unit?.toLowerCase?()
        if unit not of units
            #log "I18nStateManager No unit for #{Unit} ignored"
            return

        return if !force and unit is @state.i18nDistUnit

        scale= units[unit]
        I18nInfo.distUnit= unit
        I18nInfo.distScale= scale


        @setState
            i18nDistUnit: unit
            i18nDistScale: scale
            i18nInfo: oA {},I18nInfo
            ->
                #log "I18nStateManager Doing a force update"
                @forceUpdate() # cause i8n every where ans we are not using context yet

    doSetMi: -> @setDistUnit 'mi'
    doSetKm: -> @setDistUnit 'km'

    onMountDo: ['initI18nInfo']

    initI18nInfo: ->
        #log "I18nStateManager.onMountDo setState to",i18nInfo:oA {},I18nInfo
        @setState i18nInfo:oA {},I18nInfo


I18nInfo=
    trad: trad
    lang: lang
    distUnit: 'km'
    distScale: 1



autoHTML= (str)->
    ret=str.replace /(\n)|(\s((https?\:)|(HTTPS?\:))\S+)/g,(hit)->
        if hit is '\n'
            '<br \>'
        else if hit[1..4].toLowerCase() is 'http'
            """ <a href="#{hit[1..]}" target="_blank">#{hit[1..]}</a>"""
        else hit
    ret



module.exports=
    getTrad: -> trad
    getLang: -> lang

    autoHTML: autoHTML

    I18nStateManager:I18nStateManager

    setLang: (lang)->
        newMess= {} if vx.isDEV
        switch lang
            when 'fr'
                trad= tradFr
                I18nInfo.trad= tradFr
                I18nInfo.lang= 'fr'
            when 'en'
                trad= tradEn
                I18nInfo.trad= tradEn
                I18nInfo.lang= 'en'
            else
                trad= tradFr
                I18nInfo.trad= tradFr
                I18nInfo.lang= 'fr'

    newMess: newMess= {}

    sayModule: sayModule= (mName='')-> say= (mess,ops) ->
        if typeof mess is 'string'
            if (mName and m=trad["#{mName}:#{mess}"]) or (m=trad[mess])
                mess= m if m isnt '!'
            else
                if vx.isDEV
                    if mName
                        m= "#{mName}:#{mess}"
                    else m= mess
                    newMess[m]?= 1
        if typeof mess is 'function'
            mess= mess.call ops,say,I18nInfo

        if mess is '_' then mess=''
        mess

    say: sayModule()
    
log "i18n load end"
module.exports
