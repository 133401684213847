{ meta, log, arr, Global, meta, oAssign, $ }= Mod= vx= require 'vx/globals/Boot'
ModuleName= 'AttraitsUI'

require './AttraitsUI.css'

Use= Global.vx?.Use or { attraits:1 }

{ Attrait }=         require './Attraits'
{ latM, pixel2deg, latMInv }= require 'vx/math/MapGeometry'
{ QuadNode }=        require 'vx/math/QuadNode'
{ Bounds }=          require 'vx/math/Bounds'
{ Pt }=              require 'vx/graph/Graph5'

wait= require 'vx/tools/Wait'

{ sayModule, lang }= require 'vx/i18n/I18n'
say= sayModule ModuleName

UI= require 'vx/UI'

{ UIComponent }= UI

{ header2, content, icon, div, a }= UI
{ img, br, h6, h5, p, span, group }= UI
{ form, button, checkbox }= UI
{ details, summary }= UI
{ tbTitle, tbIcon }= UI

{ vxIcon, vxIconClassName }=    require 'vx/ui/vxIcon'


GroupClassName=  "map-html" # hard code cause complicated to make dynamic  MapHTML::_className


IconClassName= vxIconClassName
iconTag= vxIcon

attraitIcon= (name)-> vxIcon 'elevation=1',name

class Cluster

        mapType:'Cluster'

        constructor: (@attraits,ops)->
            @bounds= Bounds.aBNDS ( att.pt.mBounds() for att in @attraits)
            @pt=   Pt._ @bounds.center().x, latMInv @bounds.center().y
            size=0
            ( size+= att.size ? 1 for att in @attraits )
            @size= size
            oAssign @,ops
            return @


clusterMarker= meta class ClusterMarker extends UIComponent
        @displayName: 'ClusterMarker'

        @propTypes:
            cluster:  @type.object

        render: (props=@props)->

            div '.cluster-marker',
                __proto__:props
                cluster: undefined
                app:     undefined
                'data-size':   props.cluster.size
                #'data-names': ( a.title for a in props.cluster.attraits).join('\n')[..80]
                onClick: @props.onClick or @doZoom2Cluster
                #onMouseOver: -> log "hover cluster props=",props
                

        doZoom2Cluster: ->
            app= @props.app

            {x,y,X,Y}= @props.cluster.bounds

            { x:mx, y:my, X:mX, Y:mY }= app.getMapMBounds()


            scale= Math.min (mX-mx)/(X-x),(mY-my)/(Y-y)
            dz= Math.max 1,-1+Math.min 5,Math.round Math.log(scale)/Math.log 2
            zoom= app.getMapZoom()
            log "zoom2 scale=#{scale} dz=#{dz} "
            app.setMapCenter {lng:((X+x)/2),lat: latMInv (Y+y)/2}
            setTimeout (->app.setMapZoom zoom+dz),600


class Etablisment

        mapType:'Etablisment'

        constructor: (@attraits,ops)->
            a= @attraits[0]
            #@bounds= Bounds.aBNDS ( a.pt.mBounds for a in @attraits)
            @pt=   a.pt
            @id= "etab-#{a.id}"
            @size= @attraits.length
            @title= a.tittre
            oAssign @,ops
            return @



etablismentMarker= meta class EtablismentMarker extends UIComponent
        @displayName: 'EtablismentMarker'

        @propTypes:
            attraits:  @type.array

        render: (props=@props)->
            { info, attraitInfo, attraitMarker, attraits }= props
            { itin, decorations, selected, app }= info
            {doToggleClickedAttraitId,doToggleClickedAttraitId}= app
            div '.etablisment-marker',
                __proto__:props
                attraits: undefined
                info:  undefined
                attraitInfo:   undefined
                attraitMarker: undefined
                'aria-label': attraits[0].title
                'data-col':col=switch props.attraits.length
                    when 2,4 then 2
                    when  10,11,12 then 4
                    else 3
                '$--col':col
                for attrait in attraits
                    {x,y}= attrait.pt
                    ops=
                        key:    attrait.id
                        attrait: attrait
                        #position: {top:attrait.pt.y,left:attrait.pt.x}
                        pos:[y,x]
                        selected: attrait.id is selected
                        onClick: doToggleClickedAttraitId
                        onClose: doToggleClickedAttraitId
                        app: app
                        infoAttrait: attraitInfo
                    if decos=decorations[attrait.id]
                        for name,val of decos
                            ops["data-#{name}"]=val
                    attraitMarker '$display:inline-block',ops



attraitMarker=meta class AttraitMarker extends UIComponent
        @displayName: 'AttraitMarker'

        @propTypes=
            attrait:    @type.object
            selected:   @type.bool
            onClick:    @type.func
            infoAttrait: @type.func
            app:        @type.object

        EtablismentMarkerClass= 'etablisment-marker'

        iconNameVar: 'type'

        iconTag: iconTag

        render: (props=@props) ->

            attrait=props.attrait

            @iconTag
                __proto__: props
                id: "am-#{attrait.id?.replace /\./g,'-'}"

                onClose: undefined
                app:     undefined
                infoAttrait: undefined
                pos:     undefined

                _setClass: 'attrait-marker attrait-marker__icon attrait-marker--clickable'
                'data-id':    attrait.id
                'data-objid': attrait.id
                'data-region':attrait.membre if attrait.membre
                'data-nom':   attrait.title
                'aria-label': attrait.title
                #position: props.position ? [ attrait.latitude, attrait.longitude ]
                #deleted
                attrait: undefined
                selected:undefined
                # childs
                '.selected': props.selected
                '.icon-flag':attrait.etoile or attrait.coeur or attrait.check
                '.flag-etoile':attrait.etoile
                '.flag-coeur':attrait.coeur
                '.flag-check':attrait.check
                props.attrait[@iconNameVar]

        doPhotoZoom: =>
            ($ '#popupzoom-photo').css 'background-image':"url('#{@props.attrait.photo}')"
            ( $ '#popupzoom' ).popup 'open'


#NOT USED OVriden in attraitFMcqui
attraitInfo= meta class AttraitInfo extends UIComponent
    @displayName: 'AttraitInfo'


    spacer=-> span '$width=0.5em'

    iconNameVar: 'type'

    @propTypes =
        attrait: @type.object
        onClose: @type.func
        app:     @type.object


    render: (props=@props)->
        attrait= props.attrait
        #log "info attrait attrait=",attrait
        div '.attrait-info',className:props.className,
            header2 'flat compact',onClick:props.onClose,
                left:[
                    tbIcon iconTag '',attrait[@iconNameVar]
                    tbTitle attrait.title or ''
                    ]
                rightShrink: tbIcon button 'flat icon=cancel',onClick:props.onClose

            content '.attrait-info__content',{},
                if attrait.photo
                    a onClick: @doShowPhoto,
                        img '.attrait-info__photo', src:attrait.photo
                group 'horizontal alignEnd',

                    button 'name=edit noRipple  href=#attrait-edit-page',
                        icon '|edit'
                        #say "Éditer"
                    button 'name=comment  noRipple  href=#attrait-edit-page',
                        color:if attrait.comment then 'accent' else 'primary'
                        icon '|comment'
                    button 'name=speak  noRipple ',
                        onClick: @doSpeakAttrait
                        color:  if attrait.comment then 'accent' else 'primary'
                        icon '|hearing'


                    button 'icon=favorite name=coeur noRipple',
                        color: if attrait.coeur then 'red' else 'primary'
                        #$backgroundColor: if attrait.coeur then 'white' else undefined
                        #$borderRadius:'50%'
                        onClick: @doToggleFlag
                    button 'icon=star name=etoile noRipple',
                        color: if attrait.etoile then 'rgb(255,255,0)' else 'primary'
                        #$backgroundColor: if attrait.etoile then 'white' else undefined
                        #$borderRadius:'50%'
                        onClick: @doToggleFlag
                    button 'icon=check name=check noRipple',
                        color: if attrait.check then 'green' else 'primary'
                        #$backgroundColor: if attrait.check then 'white' else undefined
                        #$borderRadius:'50%'
                        onClick: @doToggleFlag
                div '.attrait-info__adresse',
                    p 'noTopMargin',
                        [attrait.numrue,br()] if attrait.numrue
                        [attrait.cp,br()]     if attrait.cp
                        [attrait.ville,br()]  if attrait.ville
                    (button '',href:"tel:#{attrait.tel}", (icon '|phone'),attrait.tel)  if attrait.tel
                    (button 'target=_blank',href:attrait.urlsource, (icon '|info'),say "Site web")  if attrait.urlsource
                p '.attrait-info__desc',attrait.description
            #(comp.props.itin?getInfoUIAttrait? attrait,comp) or infoAttraitItin attrait,comp


attraitsSelector= ({app,attraitIcon=attraitIcon})->
        details 'open',
            summary h5 '',(say "type attraits"),

            group '',

                for gname of app.getGroups()
                    checkbox
                        id:id="chbk-#{gname}"
                        key:id
                        name:gname
                        checked: app.isGroupVisibile gname
                        onNewValue: wait app.setGroupVisibility
                        value:1
                        offValue:0
                        ( attraitIcon gname )
                        ' '
                        say gname # "titre #{gname}"



getObjCenterBoundsm= (obj)->switch obj.mapType
        when 'Cluster' then obj.pt.bounds()
        when 'Attrait','Etablisment' then obj.pt.mBounds()
        else obj.pt?.mBounds() or obj.bounds


getObjMapBoundsm= (obj,zoom,iconSize=24,clusterSize=40)->

    baseBnds= getObjCenterBoundsm obj
    pixelWidth= pixel2deg 1,zoom
    switch obj.mapType
        when 'Cluster' then baseBnds.grow clusterSize*pixelWidth
        when 'Attrait' then baseBnds.grow iconSize*pixelWidth
        when 'Etablisment'   then baseBnds.grow (Math.min(3,obj.attraits.length)*iconSize*pixelWidth), iconSize*pixelWidth
        else obj.pt?.mBounds or obj.bounds



attraitsLayerFactory= (MapWidgets,layerAttraitMarker=attraitMarker,layerAttraitInfo=attraitInfo)->

    { mapHTML }= MapWidgets

    #log "attraitsLayerFactory got ",{ DynamicTileLayer, mapHTMLDiv ,MapWidgets,layerAttraitMarker,layerAttraitInfo}

    meta class AttraitsLayer extends UIComponent
        @displayName: 'AttraitsLayer'

        id:         @type.string
        content:    @type.array
        latmContent:@type.bool
        opacity:    @type.number

        visible:    @type.bool
        debug:      @type.bool
        info:       @type.object
        gmapMap:    @type.object
        app:        @type.object


        render: (props=@props)->
            { content,info,visible,id,name,opacity,gmapMap,leafletMap,zIndex }= props
            { itin, decorations, selected, app }= info
            { doToggleClickedAttraitId, doToggleClickedAttraitId }= app
            #log "attraits layer render got props=",{props,info,content,visible}
            mapHTML '.attraits-layer .leaflet-zoom-hide',
                gmapMap: gmapMap
                leafletMap: leafletMap
                id: id or "AttraitsLayer2-#{name or''}"
                key: id or "AttraitsLayer2-#{name or''}"
                visible: true
                debug:   false
                #top:50,bottom:45,left:-79,right:-61.5
                zIndex: zIndex
                top:47.1,bottom:47,left:-70,right:-69.9
                for obj in content or []
                    switch obj.mapType
                      when 'Attrait'
                        attrait= obj
                        {x,y}= attrait.pt
                        ops=
                            key:     attrait.id
                            attrait: attrait
                            position: {top:y,left:x}
                            pos: [y,x]
                            selected: attrait.id is selected
                            onClick: doToggleClickedAttraitId
                            onClose: doToggleClickedAttraitId
                            app: app
                            infoAttrait: layerAttraitInfo
                        if decos= decorations[attrait.id]
                            for name,val of decos
                                ops["data-#{name}"]=val
                        layerAttraitMarker ops

                      when 'Cluster'
                        {x,y}= obj.pt
                        #y= latMInv y
                        #Y= latMInv Y
                        clusterMarker
                            cluster: obj
                            id:"cluster-#{obj.id}"
                            key:"cluster-#{obj.id}"
                            position:{ top:y,left:x}
                            app: info.app
                      when 'Etablisment'
                        {x,y}= obj.pt
                        #Y= latMInv Y
                        etablismentMarker
                            attraits: obj.attraits
                            id:"et-#{obj.id}"
                            key:"et-#{obj.id}"
                            position:{ top:y,left:x}
                            info: info
                            attraitMarker: layerAttraitMarker
                            attraitInfo: layerAttraitInfo
                      else continue

makeDebug= false

makeEtablisments=({content,selectedId,visibleAttraits})->

        log "makeEtablisments got",{content,visibleAttraits} if makeDebug
        start= Date.now()
        return [] if !content or !content.length

        pos={}

        for a in content
            {x,ym}= a.pt
            coord="#{x.toFixed 6}-#{ym.toFixed 6}"
            (pos[coord] ? pos[coord]?=[]).push a

        ret= for k,attraits of pos
            if attraits.length is 1
                attraits[0]
            else # group
                isVisible= false
                for a in attraits when visibleAttraits[a.id] or a.id is selectedId
                    isVisible= true
                    break
                new Etablisment attraits,{isVisible}

        log "makeEtablisments filter #{content.length} -> #{ret.length} took #{Date.now()-start}ms" if makeDebug
        ret




makeClusters=({zoom,content,visibleAttraits,selectedId,pixelMarge=-4, bnds=getObjMapBoundsm} )->
        log "makeClusters got",{zoom,content,visibleAttraits,pixelMarge, bnds} if makeDebug

        if !content or !content.length
            log "makeClusters no content" if makeDebug
            return []

        start=Date.now()
        iconSize=24

        if zoom>16
            return content

        if pixelMarge
             marge= pixel2deg pixelMarge,zoom
             cbnds= (o)-> o._cbnds?= (bnds o,zoom).grow marge
        else cbnds= (o)-> o._cbnds?= bnds o,zoom

        bounds= Bounds.aBNDS (  (o._clusterId= o._cbnds= null;  cbnds o,zoom ) for o in content )
        #log "Make cluster bounds= #{bounds}"
        cQuad= QuadNode._ bounds, getObjBounds:cbnds

        nccnt=0
        clusters={}
        getParentId= (id)->
            while pid= clusters[id].parent
                id= pid
            id
            
        flatenCluster= (c,ret=[])->
            for i in c
                if i.isCluster
                     #log "parent container mismatch on #{c.id} and #{i.id}",{c,i,ret} if c.id isnt i.parent
                     flatenCluster i,ret
                else ret.push i
            ret
        
        cnt=0
        
        itime=0
        htime=0
        atime=0
        
        for a in content
            continue if a.isVisible or visibleAttraits[a.id] or a.id is selectedId
        
            now= performance.now()
            hits= cQuad.getIntersects abnds=cbnds a
            itime+= performance.now()-now
            
            
            if hits.length #we have a cluster
                #log "makeClusters got #{hits.length} on #{a.id} now=#{Date.now()-start}ms" #,{a,hits,abnds}
                now= performance.now()

                seenClusterIds= new Set
                loners=[a]
                
                for aHit in hits
                    if cid= aHit._clusterId
                        seenClusterIds.add cid
                    else loners.push aHit
                    
                parentClusterIds= new Set
                for cid from seenClusterIds
                    parentClusterIds.add getParentId cid
                    
                if parentClusterIds.size is 1
                    # grow cluster
                    cluster= clusters[clusterId=parentClusterIds.values().next().value]
                    #log " grow cluster #{cid}"
                    for l in loners
                        cluster.push l
                        l._clusterId= clusterId
                else # new cluster
                
                    newCluster= loners
                    newCluster.isCluster= 1
                    newCluster.id= clusterId= ++nccnt
                    clusters[clusterId]= newCluster
                    for l in loners
                        l._clusterId= clusterId
                    # add cluster
                    for cid from parentClusterIds
                        cluster= clusters[cid]
                        #throw new Error "Cant change parent",{cluster,a,parentClusterIds} if cluster.parent
                        cluster.parent= clusterId
                        newCluster.push cluster

                htime+= performance.now()-now

                
            now= performance.now()
            cQuad.add a
            atime+= performance.now()-now

        #ok now build the real qNode

        # get not clustered
        log "makeClusters  made cQuad cnt=#{cnt} ncnt=#{nccnt} itime=#{itime} htime=#{htime} atime=#{atime} #{Date.now()-start}ms",cQuad if makeDebug
        ret=(a for a in content when not a._clusterId )
        log "makeClusters got #{ret.length} no hist took #{Date.now()-start}ms",{nohits:ret[..]} if makeDebug

        # add clusters
        addedClusters=for id,c of clusters when not c.parent # get heads
            c= flatenCluster c
            new Cluster c,id:id

        log "makeClusters Adding #{addedClusters.length} took #{Date.now()-start}ms  =",{addedClusters,clusters} if makeDebug
        for c in  addedClusters
            ret.push c

        log "makeClusters filter #{content.length} -> #{ret.length}  took #{Date.now()-start}ms"   if makeDebug
        ret


filter= ({content,visibleGroups,visibleAttraits,groupAttrib})->
    log "filter got",{content,visibleGroups,visibleAttraits,groupAttrib} if makeDebug
    ret=( a for a in content or [] when visibleGroups[a[groupAttrib]] or visibleAttraits[a.id] )
    log "filter #{content.length} -> #{ret.length}" if makeDebug
    ret

filterVBnds= ({content,bnds,selected})->
    return [] if !bnds or !content

    ret= (obj for obj in content when (bnds.containsPt obj.pt) or (selected? and obj.id? and obj.id is selected))
    #log "filterVBnds #{content.length} -> #{ret.length}"
    ret

module.exports=  { attraitIcon, attraitsSelector, attraitsLayerFactory, AttraitInfo, AttraitMarker, Cluster, Etablisment, getObjCenterBoundsm, getObjMapBoundsm, makeEtablisments, makeClusters, filter, filterVBnds }


