module.exports= (delay,fnc)->
    if typeof delay isnt 'number'
        fnc= delay
        delay=0.5
    ->
        args= arguments # cause arguments always redefined in ->
        self= @
        setTimeout (-> fnc.apply self,args ),delay*1000


