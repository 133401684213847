# from Boot
oA= Object.assign
sList= (s,sep)->
    sep?= /[\s|,]+/
    return s if typeof s isnt 'string'
    ( w for w in s.split sep when w) # slow but sList is just for quick and dirty
a2map= (arr,map,i)->
    map?= {}
    i?= 0
    for k in arr
        map[k]=++i
    map
sSet=  (s,sep)-> a2map sList s,sep
log= console.log.bind console


Regroupements=
    DM: sList 'DM,SL,QC,CV'
    QM: sList 'QM,GS,BSL,CN'
    PM: sList 'PM,LN,MA'
    CACDQ: sList 'CACDQ,CA,CDQ'



reg2nomCircuits=
    BJ: '1.EeyouIstche Baie-James'
    CV: '2.Charlevoix'
    SL: '2.SaguenayLacStJean'
    QC: '2.Québec'
    BSL: '3.Bas-St-Laurent'
    CN: '3.CôteNord'
    GS: '3.Gaspésie'
    AT: '4.AbitibiTemiscamingue'
    OUT: '5.Outaouais'
    LR: '6.Laurentides'
    LN: '7.Lanaudière'
    MA: '7.Mauricie'
    CDQ: '8.CentreDuQuebec'
    CA: '8.ChaudiereAppalaches'
    DM: 'Destination motoneige (Québec-Charlevoix-Saguenay)'
    QM: 'Maritime (BasStLaurent-Gaspésie-Cote-Nord)'
    PM: 'PaysMotoneige(Lanaudière-Mauricie)'
    CACDQ: 'TCA-TCDQ'
    CE: 'CE'
    MT: 'MT'



reg2nomFr=
    BJ:  'Eeyou Istchee Baie-James'
    CV:  'Charlevoix'
    SL:  'Saguenay-Lac St-Jean'
    QC:  'Québec'
    BSL: 'Bas-St-Laurent'
    CN:  'Côte-Nord'
    GS:  'Gaspésie'
    AT:  'Abitibi-Témiscamingue'
    OUT: 'Outaouais'
    LR:  'Laurentides'
    LN:  'Lanaudière'
    MA:  'Mauricie'
    CDQ: 'Centre-du-Quebec'
    CA:  'Chaudière-Appalaches'
    DM:  'Charlevoix - Saguenay-Lac St-Jean - Québec'
    QM:  'Bas-St-Laurent - Gaspésie - Côte-Nord'
    PM:  'Lanaudière - Mauricie'
    CACDQ:'Centre-du-Québec - Chaudière-Appalaches'
    Tous: 'Toutes les régions'
    CE:  'Canton-de-l\'Est'
    MT:  'Montérégie'
    #FCMQ: 'Fédération des Clubs de Motoneige du Québec'
    FCMQ: 'FCMQ'


nomCircuits2reg=
    'undefined':'?'

for reg,nom of reg2nomFr
    nomCircuits2reg[nom]=reg

for reg,nom of reg2nomCircuits
    nomCircuits2reg[nom]=reg


reg2nomEn=
    BJ:  'Eeyou Istchee Baie-James'
    CV:  'Charlevoix'
    SL:  'Saguenay-Lac St-Jean'
    QC:  'Québec'
    BSL: 'Bas-St-Laurent'
    CN:  'Côte-Nord'
    GS:  'Gaspésie'
    AT:  'Abitibi-Témiscamingue'
    OUT: 'Outaouais'
    LR:  'The Laurentians'
    LN:  'Lanaudière'
    MA:  'Mauricie'
    CDQ: 'Centre-du-Quebec'
    CA:  'Chaudière-Appalaches'
    DM:  'Charlevoix - Saguenay-Lac St-Jean - Québec city'
    QM:  'Bas-St-Laurent - Gaspésie - Côte-Nord'
    PM:  'Lanaudière - Mauricie'
    CACDQ:'Centre-du-Québec - Chaudière-Appalaches'
    Tous: 'All Regions'
    CE:  'Canton-de-l\'Est'
    MT:  'Montérégie'
    #FCMQ: 'Fédération des Clubs de Motoneige du Québec'
    FCMQ: 'FCMQ'

reg2FCMQreg=
    BJ:  'EEYOU ISTCHEE BAIE-JAMES'
    CV:  'CHARLEVOIX'
    SL:  'SAGUENAY–LAC SAINT-JEAN'
    QC:  'QUÉBEC'
    BSL: 'BAS-SAINT-LAURENT'
    CN:  'CÔTE-NORD'
    GS:  'GASPÉSIE'
    AT:  'ABITIBI-TÉMISCAMINGUE'
    OUT: 'Outaouais'
    LR:  'LAURENTIDES'
    LN:  'LANAUDIÈRE'
    MA:  'MAURICIE'
    CDQ: 'CENTRE-DU-QUÉBEC'
    CA:  'Chaudière-Appalaches'
    DM:  'DM'
    QM:  'QM'
    PM:  'PM'
    CACDQ:  'CACDQ'
    CE:  'CANTONS-DE-L\'EST'
    MT:  'MONTÉRÉGIE'

AttraitsPrefixV2= [''].concat ("a.#{p}." for p of reg2nomFr ), ("attrait.#{p}." for p of reg2nomFr )



FCMQreg2reg= {'undefined':'?'}
for k,v of reg2FCMQreg
    FCMQreg2reg[v]= k
    FCMQreg2reg[v.toLowerCase()]= k
    FCMQreg2reg[v.toUpperCase()]= k


FCMQnum2reg_=
    '01':'GS' # et Iles de la madelaine
    '02':'BSL'
    '03':'CA'
    '04':'CN'
    '05':'SL'
    '06':'QC' #+ CV	2 ATR
    '07':'MA' #+ CD 2 2ATR
    '08':'LN'
    '09':'AT' # +BJ
    '10':'CT'
    '11':'MT'
    '12':'MR'
    '13':'OUT'

clubsCDQ= sSet '403	406	407	413	417	422'
clubsBJ= sSet  ''
clubsCV= sSet  ''

FCMQNum2Reg= (num,clubNum)->
    reg= FCMQnum2reg_[num]
    switch reg
        when 'MA'
            reg= 'CDQ' if clubNum of clubsCDQ
        when 'AT'
            reg= 'BJ'  if clubNum of clubsBJ
        when 'QC'
            reg= 'CV'  if clubNum of clubsCV
    reg




regV1toV2=
    'CentreDuQuebec':       'CDQ'
    'ChaudiereAppalaches':  'CA'
    'AbitibiTemiscamingue': 'AT'
    'Lanaudiere':           'LN'
    'Mauricie':             'MR'



scat2acro=
    'Stationnement':           'sta'
    'Incitatif':               'inc'
    'Restaurant':              'res'
    'Hébergement':             'heb'
    'Bar':                     'bar'
    'Essence':                 'gaz'
    'Relais':                  'rel'
    'Mobilité restreinte':     'mob'
    'Bed and Breakfast':       'bnb'
    'autres':                  'aut'
    'Autres':                  'aut'
    'Sites et attraits':       'att'
    'Réparation mécanique':    'mec'
    'Refuge':                  'ref'
    'Hôtel sécuritaire':       'hbs'
    'Location motoneige':      'loc'
    'Polaris':                 'pol'
    'Ski-Doo':                 'ski'
    'Yamaha':                  'yam'
    'Arctic Cat':              'cat'
    'Casino Charlevoix':       'ccv'
    'Forfait':                 'for'
    'Point de vue':            'pdv'
    'Club':                    'clb'
    'Halte':                   'hlt'
    'Information':             'inf'
    'Ravitaillement':          'rav'
    'Cantine':                 'can'
    'Repos':                   'rep'
    'Bornes évacuation':        'bor'


acros= ( acro for scat,acro of scat2acro )

AttraitsSuffixV2= [''].concat ( ".#{n}" for n in acros )

typeV1toV2=
    'Hébergements': 'Hébergement'
    'Restauration': 'Restaurant'
    '(autres)'    : 'autres'
    ''            : 'autres'
    'Information touristique':'Information'
    'Crèmerie et cantine': 'Cantine'
    'Forfaits'   : 'Forfait'
    'Point de vue':'Point de vue'
    'Repos':       'Repos'
    'Autres':      'Autres'



icon2iconId=
    'Club':          'club'
    'Aucune alerte': 'aucun'
    'Aucune icône':  'aucun'
    'Aucun icône':   'aucun'
    'Attention':     'attention'
    'Sentier fermé': 'fermer'
    'Eau gelée':     'eau-ouvert'
    'Eau incertaine':'eau-limit'
    'Eau dégelée':   'eau-fermer'
    'Auto':          'auto'
    'Motoneige':     'motoneige'

AllATRs= sList 'at bj bsl ca cdq ce cn cv  gs lr mt out pm qc sl fcmq'.trim().split /\s+/ # fcmq


NoChangeInDataErr= "No change in Data"


# *** Conditions et Expirations conditions *****



condClass=
    'très bon':'tresbon'
    'fermé':'fermer'
    'cfermé':'fermer'
    'ouvert':'bon'
    'limité':'limit'
    'limitéj':'limitj'
    'non disponible':'noinfo'
    'transparent':'aucun'
    '': 'noinfo'


condIsAlert= (c,minDate=calcCondAfter())->
    c and ((c.membre is 'FCMQ') or ((c.cond is 'Fermé')  and (!c.dateMaj or c.dateMaj>=minDate)))


asCondClass= (cond)->
    cond ?= ''
    cond= cond.toLowerCase()
    cond= condClass[cond] or cond or 'noinfo'

asCondClassFromCond= (cond)->
    c= if (cond.cond isnt 'transparent') and cond.dateMaj and (cond.dateMaj < calcCondAfter())
        'Non disponible'
    else cond.cond
    asCondClass c


CCF= # Couleur conditions FCMQ
    Bonne:      Bonne='#44a468' # was #83bc5c 131 188 92  '#00b050' #
    Bon:        Bonne
    Acceptable: Acceptable= '#e6cc14' # 230 205 20 #'#ffff66' #'#ffd966' # 255 217 102  '#ffff00' #
    Fermer:     '#ff6d70' # 255 109 1222   '#ff0000' #
    'cfermé':   '#ff6d70' # 255 109 1222   '#ff0000' # pas sure si requis
    NonDispo:   NonDispo= '#b2b2b2' # 3x178 '#a6a6a6' # 166 166 166
    Limit:      Acceptable
    NoInfo:     NonDispo
    Inconue:    NonDispo


CondAfterDelta= (7*24*60*60*1000)/1000


calcCondAfter= ->
        now= new Date()
        now.setMinutes 0
        now.setSeconds 0
        now.setMilliseconds 0
        (now.getTime()/1000)-CondAfterDelta

ClubIcon= 'club'
MotoneigeIcon= 'motoneige'

condFiltre= (conditions,after)->

    for cond in (conditions or []) when !cond.dateMaj or cond.dateMaj>after or cond.icon is ClubIcon
        if  cond.icon is ClubIcon and cond.dateMaj < after
            cond2= oA {__proto__:cond.__proto__},cond
            cond2.cond= 'Non disponible'
            cond2
        else cond



sheetId= (sheetUrl='')->

    parts1= sheetUrl.split '//'
    parts1.shift()
    id= parts1.join('//').split('docs.google.com/spreadsheets/d/')[1]?.split('/')[0] or ''
    id
    



module.exports= { reg2nomCircuits, nomCircuits2reg, reg2nomFr, reg2nomEn, reg2FCMQreg, FCMQreg2reg, Regroupements, scat2acro, acros,
        AttraitsPrefixV2,  AttraitsSuffixV2, regV1toV2, typeV1toV2, FCMQNum2Reg, asCondClass, CCF, icon2iconId, AllATRs,
        NoChangeInDataErr, CondAfterDelta,  calcCondAfter, ClubIcon, MotoneigeIcon, condIsAlert, condFiltre, asCondClassFromCond, sheetId }
