Global= switch
    #when globalThis? then globalThis
    when window? then window
    when global? then global
    else throw new Error "No Global Name Space"

oA=  Object.assign
log= console.log.bind console

#log "Global.vx is #{Global.vx} vx.q=#{Global.vx.q}"

vx= Global.vx?= {lang:'en'}

#check if we have a language set or not
if !vx.lang
    vx.lang= if navigator? and navigator.language && navigator.language.slice(0,2)=='fr' then 'fr' else 'en'


if !vx.q
    vx.q=
        "pageType":"api2"
        "circuitsListVisible":0
        "circuitUpdateHash":0
        "zoomMode":"selected-circuits"
        "GTM":"GTM-PLLJNH"
        "GMK":"AIzaSyD4irdg9KJ2KMo44miPH-Id8jIJoFkSA3E"
        "pancartes":1
        "className":"has-itin has-multicond cond"
        "cond":0
        "lang":"en"
        "title":" FCMQ: 2020-21 Snowmobile Map"
        "rootClass":"srvr-fcmq projet-root page-indexen"
        "srvr":"fcmq"




# Contants **************************************

oA vx,ModeApp=
    ModeApp: 'fcmq' # api,app,srvr

    #bool par rapport a modeApp
    isAPP:  false
    isFCMQ: true #
    isAPI:  false
    isSRVR: false

oA vx,ModeAppCntxt=
    switch vx.ModeApp
        when 'fcmq','api'
            isBROWSER: true
            isNODE:    false
            isCORDOVA: false
            isMOBILE:  false
            isDESKTOP: true
        when 'app'
            isBROWSER: true
            isNODE:    false
            isCORDOVA: true
            isMOBILE:  true
            isDESKTOP: false
        when 'srvr'
            isBROWSER: false
            isNODE:    true
            isCORDOVA: false
            isMOBILE:  false
            isDESKTOP: false
        else
            log "No Boolean Mode vars for ModeApp=#{vx.ModeApp}"


# oA vx,ModeDev=
#     # ModeDev
#     ModeDev: 'dev' # dev,prod
#     isDEV:    true

#vx.isDEV= isDEV ? false



oA vx,
    # State mamangement BasicUIApp.PersistantApp
    persistantStateName:'basic'
    persistantState: ps={}
    urlAPIVars:    {}
    refAPIVars:    {}
    hashAPIVars:   {}

    defaultState:{}


vx.use= Use=
    #stateManagers
    attraits:1
    aide:1
    graph:   1
    itin:    1 # requires graph not for moto
    sharing: 1 #requires itin
    circuits:0
    gps: 0
    recorder: 0
    traces:   0
    couches: 1
    # PMN
    pmn:     1
    pancartes: 1
    surf: 1
    cond: 1
    # Autres
    polyfill:1
    watcher: 1
    circuitUpdateHash:0
    fullscreen: 1
    parametres: 1
    debug: 1
    admin: 1
    message: 1


vx.mapMode=
    api: 'gmaps'
    paneauListCircuits: 0
    paneauCouches : 1
    paneauAttraits: 1
    boutonApp: 1
    infoMode:       'paneau'
    menu: 1
    bkgClick: 1
    search: 1
    hasSurf: 1
    hasCond: 1 # usecond must be true
    hasEco:  1
    multiCouches: 1
    condOld: 0
    #condTransparent: 0
    condOpacity: 1.0
    condAlerts: 1
    pisteOpacity: 1.0
    gestureHandling:'greedy'
    showSelSeg: 1  # requires grapj, itin
    domain:'mn'
    router:''
    itinMenu: 1
    gpxItin: 0
    gpxCircuit: 0
    #circuitMarkerPos:'start' #  start,end,mid,center
    #circuitMarkerHover: 1
    #circuitMarkerClickSelect: 1
    #circuitPathrHover: 0
    #circuitPathClickSelect: 0

vx.uiMode=
    admin:         0 #carefull vx.use.admin must be true ...

    adminMn:       1
    adminGraph:    1
    adminAttraits: 1
    adminParcours: 0
    adminMessage:  1
    adminCondATR:  1
    adminCondMaster:0
    adminSurfFCMQ: 0



vx.templates= {}


vx.urls=
    SurfServerV2:   ''
    Conditions:     '/tiles/pmncondATR'
    TileServer:     'https://fcmqmaps2.fcmqapi.ca/'
    ShareURL:       'http://fcmq.fcmqapi.ca/i'
    HomePath:        "/"
    DataURL:       '/db/' #relative to page for dev but hardcoded for prod ? no
    GraphURL:      '/db/graphSentiersSS.json'
    AttraitsURL:   '/db/attraitsFCMQ2.json'
    ParcoursURL:   '/db/parcoursInfo.json'
    #CondURL:       '/db/condSectionsATR.json'
    MessageURL:    '/db/message.json'
    MapURL:        '/tiles/pmn'
    AideFR:        'https://fcmq.qc.ca/files/1515/9856/1411/Guide_dutilisation_Carte_interactive_iMotoneige_V3.pdf'
    AideEN:        'https://fcmq.qc.ca/files/1915/9856/1427/Guide_dutilisation_Carte_interactive_iMotoneige_EN_V3.pdf'

#PlanifierFrom= 'https://gd17.typeform.com/to/YdeTC5' #planif v3

if Global.location.hostname is 'localhost'
    log "localhost q patch"
    oA vx.urls,
        DataURL:       '//dev-fcmq.fcmqapi.ca/db/' #relative to page for dev but hardcoded for prod ? no
        GraphURL:      '//dev-fcmq.fcmqapi.ca/db/graphSentiersSS.json'
        AttraitsURL:   '//dev-fcmq.fcmqapi.ca/db/attraitsFCMQ2.json'
        ParcoursURL:   '//dev-fcmq.fcmqapi.ca/db/parcoursInfo.json'
        #CondURL:       '//dev-fcmq.fcmqapi.ca/db/condSectionsATR.json'
        Conditions:    '//dev-fcmq.fcmqapi.ca/tiles/pmncondATR'
        MessageURL:    '//dev-fcmq.fcmqapi.ca/db/message.json'
        MapURL:        '//dev-fcmq.fcmqapi.ca/tiles/pmn'

    log "vx.q=",vx.q
    log "overiding GMK"
    vx.q.GMK=  'AIzaSyCh9zm4GkcUlmAexlLvGD9_FBWg7MUIxX8' #  vxall: 'AIzaSyBqLlktRuxAlJaHz2diZ6d56CuEabreTYc' # VXgen was 'AIzaSyCh9zm4GkcUlmAexlLvGD9_FBWg7MUIxX8'
    # hBreakPoints= #default web
#     compact: 36  # small phone portait
#     narrow:  48  # phone portait
#     medium:  56  # phone lanscape/tablet portait
#     wide:    999999 # tablet lanscape/ screen
vx.hBreakPoints= #App
     compact: 24  # small phone portait
     narrow:  32   # phone portait
     medium:  40   # phone lanscape/tablet portait
     wide:    999999 # tablet lanscape/ screen


console.log "Loaded GlobalNewIndex:"

module?.exports= { ModeApp, ModeAppCntxt, Use }

