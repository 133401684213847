
#{ say } require 'vx/i18n/I18n'

#mois=  sList say "Jan Fév Mars Avril Mai Juin Jui Aout Sep Oct Nov Dec"
mois=  "Jan Fév Mars Avril Mai Juin Jui Aout Sep Oct Nov Dec".split ' '
#jours= sList say "Dimanche Lundi Mardi Mercredi Jeudi Vendredi Samedi"
jours= "Dimanche Lundi Mardi Mercredi Jeudi Vendredi Samedi".split ' '

{ floor, round }= Math.round

zpad= (v,l=2)->"000#{v}".substr -l

module.exports=
    formatTimeM: (ms)->
        t= round ms/(60*1000)
        z= if t<10*60 then '0' else ''
        "#{z}#{ floor t/60 }:#{@zpad t % 60}min"

    formatTimeS: (ms)->
        t= round ms/1000
        z=if t<10*60*60 then '0' else ''
        "#{z}#{floor t/3600 }:#{zpad floor((t % 3600)/60)}:#{zpad t % 60}"
    shortDateHre: (d)-> parseDate d,->"#{@Y2}/#{@M}/#{@D} #{@h}:#{@m}"
    shortDate:    (d)-> parseDate d,->"#{@Y2}/#{@M}/#{@D}"
    shortDate2:   (d)-> parseDate d,->"#{@JF}, #{@D1} #{@M3}"
    parseDate: parseDate= (d,fStr)->
        try
            if typeof d is 'number' then d= new Date(d)
            get=(v,l)->zpad (d["get#{v}"]()),l

            fStr.call
                Y2: get 'FullYear'
                Y4: get 'FullYear',4
                M:  zpad d.getMonth()+1
                D:  get 'Date'
                D1: d.getDate()
                h:  get 'Hours'
                m:  get 'Minutes'
                J:  d.getDay()
                M3: mois[d.getMonth()]
                JF: jours[d.getDay()]
        catch error
            "Eroor #{error}"
