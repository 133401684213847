{ log, meta }= vx= require 'vx/globals/Boot'

{ BaseClass }= require 'vx/tools/BaseClass'

{ min, max, sqrt, random } = Math


meta class Heap extends BaseClass

    ###
    from: http://eloquentjavascript.net/appendix2.html
    ###

    constructor: (data,fn)->
        super()
        if fn?
            @fn= fn
        @content= []
        if data
            for x in data then @.push x
        return @

    length:0

    fn: (a)-> a

    push: (e) ->
        @bubbleUp((@length= @content.push(e))-1) #push returns length
        @length

    pop: ()->
        result= @content[0]
        end=    @content.pop()
        if @length > 0
            @length--
            @content[0]= end
            @sinkDown(0)

        result


    remove: (node)-> #TODO: should apply fn to loop nodes by value. or use indexOf
        for cn,i in @content when cn == node
            # When it is found, the process seen in 'pop' is repeated
            # to fill up the hole.
            end= @content.pop()
            @length= @content.length
            #log "remove i=#{i} x=#{cn} end=#{end}"
            # If the element we popped was the one we needed to remove,
            # we're done.
            if i == @length then return i
            # Otherwise, we replace the removed element with the popped
            # one, and allow it to float up or sink down as appropriate.
            @content[i]= end
            @bubbleUp(i)
            @sinkDown(i)
            return i
        null

    bubbleUp: (n)->
        # Fetch the element that has to be moved.
        element= @content[n]
        score=   @fn(element)
        # When at 0, an element can not go up any further.
        while n > 0
            #Compute the parent element's index, and fetch it.
            parentN= Math.floor((n + 1) / 2) - 1
            parent= @content[parentN]
            # If the parent has a lesser score, things are in order and we
            # are done.
            break if score >= @fn(parent)

            # Otherwise, swap the parent with the current element and
            # continue.
            @content[parentN]= element
            @content[n]= parent
            n= parentN
        null

    sinkDown:(n)->
        # Look up the target element and its score.
        length= @content.length
        element= @content[n]
        elemScore = @fn(element)

        loop
            # Compute the indices of the child elements.
            child2N= (n + 1) * 2
            child1N= child2N - 1
            # This is used to store the new position of the element,
            # if any.
            swap= null
            # If the first child exists (is inside the array)...
            if child1N < length
                # Look it up and compute its score.
                child1= @content[child1N]
                child1Score= @fn(child1)
                #If the score is less than our element's, we need to swap.
                if child1Score < elemScore
                    swap= child1N

            # Do the same checks for the other child.
            if child2N < length
                child2= @content[child2N]
                child2Score= @fn(child2)
                if child2Score < (if swap == null then elemScore else child1Score)
                    swap = child2N


            # No need to swap further, we are done.
            break if (swap == null)

            # Otherwise, swap and continue.
            @content[n]= @content[swap]
            @content[swap]= element
            n = swap
        null

#log "Heap loaded"

module.exports= { Heap }
