
{ log, $, Global }= vx= require 'vx/globals/Boot'

{ app }= require './AppIndex' #  './AppIndexRouted' # 

{Promise}= require 'vx/tools/NamedPromises'


C={}
addTag=(target,tag,ops)-> $(target).appendChild Object.assign document.createElement(tag),ops

module.exports= bootApp: ->
    { GMK, lang, title, css:css_, js:js_ }= vx?.q

    addTag 'head','title',{innerText:title}


    if css_
        log "add ing css tag"
        addTag 'head','style',{id:'inlinecss2',type:'text/css',innerText:css_}
        
    if js_
        (new Function(js_))()


    if vx.mapMode.api is 'gmaps'
        gmk2= new URLSearchParams(Global.location.search).get('_GMK')
    
        addTag 'body','script',
            type: 'text/javascript'
            id:   'gmapload'
            async: ''
            src:  "https://maps.googleapis.com/maps/api/js?v=3.exp&language=#{lang}-ca&libraries=places&key=#{gmk2 or GMK}&callback=vxInitGMap"

    #await Promise.vxGet 'config2'
    #log "Promise all MDC and config2"
    await Promise.vxGet 'deviceready'
    #ReactDOM.render React.createElement(App),document.getElementById('root')
    vx.bootReact app,{},'#root'
    # we assume GMaps code was loaded

