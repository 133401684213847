{ log }= vx= require 'vx/globals/Boot' # to get a global ref to HelloWorld that survies webpack

{ createElement, Fragment }= require 'react'

{ ajax, ajaxCached }= require './Ajax' # TODO: why is this here

# I18n
{ sayModule, getLang }= require 'vx/i18n/I18n'
say= sayModule 'App' # cheat use app for index and appIndex ModuleName


# iconito client in http patch 

C={}

AppData= ({app,conditionsUsed,graphFileInfo,Delais,poolTime}={})->
    createElement Fragment,null, #data laoders # Fetch not working locally in cordova for now see patch in compWillMount
        ajaxCached
            URL: """#{vx.urls.AttraitsURL or "#{vx.urls.DataURL}attraitsFCMQ2.json"}#{if vx.q?.aq then '?'+vx.q.aq else ''}"""
            backupURL: ( if !vx.isDEV then 'file:data/attraitsFCMQ2.json' else "data/attraitsFCMQ2.json" ) if vx.isAPP
            online: app.getNetworkState() is 'online'

            cacheName: if vx.isAPP then 'cache-attraits' else undefined
            cacheMode: if vx.isAPP then 'ls' else undefined

            name: 'attraits'
            noData: C.emptyArr?= []
            errData: C.emptyArr?= []
            onNewValue: app.setAttraitsBrut
            onNewHeader:app.setAttraitsInfo
            #fetchOps: o1 'fetchOpsInclude', #CAREFULL used twice
            #    credentials:'include'
            poolTime:poolTime # @getOption 'attraitAutoReadSpeed'
            progress:0
            poolMessage: say "Les attraits ont été mise à jour"
            wait:Delais.attraits # if vx.isAPP then 3 else 0.2 #@getOption 'attraitAutoReadDelay'
            app: app
            debug: false #true #vx.isAPP

        if vx.use.message and vx.urls.MessageURL and !vx.uiMode.admin
            ajaxCached
                URL: vx.urls.MessageURL
                online: app.getNetworkState() is 'online'

                cacheName: 'cache-message'
                cacheMode: 'ls'

                name: 'message'
                onNewValue: app.setMessage
                onNewHeader:app.setMessageInfo
                poolTime:   60*60 # @getOption 'attraitAutoReadSpeed'
                poolMessage: ''
                wait: Delais.message #@getOption 'attraitAutoReadDelay'
                app: app
                debug: false #vx.isAPP


        if vx.use.graph
            #log "App Render use graph got graphURL=#{vx.urls.GraphURL}"
            ajaxCached
                #URL: 'http://192.168.86.3:8080/MN18/data/graphSentiers.json'
                URL: vx.urls.GraphURL or "#{vx.urls.DataURL}graphSentiersSS.json"
                backupURL: ( if !vx.isDEV then 'file:data/graphSentiersSS.json' else 'data/graphSentiersSS.json' ) if vx.isAPP

                online: app.getNetworkState() is 'online'

                cacheName:if vx.isAPP then 'cache-graph' else undefined
                cacheMode: 'ls'

                name: 'graph'
                progress:0
                noData: C.emptyArr?= []
                errData: C.emptyArr?= []
                onNewValue:  app.setGraph
                onNewHeader: app.setGraphInfo
                #fetchOps: o1 'fectOpsInclude', # CAREFULL used twice
                #        credentials:'include'
                poolTime: poolTime # if vx.isDEV then 2 else 300 #poolTime # @getOption 'attraitAutoReadSpeed'
                poolMessage: say "La carte des sentiers a été mise à jour"
                wait: Delais.graph #if vx.isAPP then 0.1 else 0.1 # @getOption 'attraitAutoReadDelay'
                app: app
                debug: false # vx.isAPP


        else # false fetch just to get header
            if vx.urls.GraphURL
                log "App Render dont use graph got graphURL=#{vx.urls.GraphURL}"
                ajaxCached
                    #URL: 'http://192.168.86.3:8080/MN18/data/graphSentiers.json'
                    URL: vx.urls.GraphURL #or "#{vx.urls.DataURL}graphSentiersSS.json"
                    #backupURL: ( if !isDEV then 'file:data/graphSentiers.json' else 'data/graphSentiers.json' ) if vx.isAPP

                    online: app.getNetworkState() is 'online'

                    cacheName: 'cache-graph'
                    cacheMode: 'ls-head'
                    fetchOps: C.methodHead?= {
                        method:'HEAD'
                        }
                    errData: C.emptyArray?= []

                    name: 'graph'
                    onNewValue:  C.doNothing?= (data)->
                    onNewHeader: app.setGraphInfo

                    #fetchOps: o1 'fectOpsInclude', # CAREFULL used twice
                    #        credentials:'include'
                    poolTime: poolTime # if vx.isDEV then 2 else 300 #poolTime # @getOption 'attraitAutoReadSpeed'
                    poolMessage: say "La carte des sentiers a été mise à jour"
                    wait: Delais.graph # @getOption 'attraitAutoReadDelay'
                    app: app
                    debug: false # vx.isAPP
            else # dont use graph and no check header
                if vx.use.itin and !C.initItin
                    log "App Render no graph do app.initItinNoGraph"
                    C.initItin= setTimeout (=>app.initItinNoGraph()),500 # wait after render
                null

        if vx.use.circuits
            #log "Timer using circuits"
            ajaxCached
                URL: """#{vx.urls.ParcoursURL or "#{vx.urls.DataURL}parcoursInfo.json"}#{if vx.q?.pq then '?'+vx.q?.pq else ''}"""
                #URL: "db/parcoursInfo.json"
                online: app.getNetworkState() is 'online'
                name: 'circuits'

                cacheName:if vx.isAPP then 'cache-circuits' else undefined
                cacheMode:'ls'

                noData: C.emptyArr?= []
                errData: C.emptyArr?= []
                onNewValue: app.setCircuits
                onNewHeader: app.setCircuitsInfo
                poolTime: poolTime # if vx.isDEV then 2 else 300 #poolTime # @getOption 'attraitAutoReadSpeed'
                poolMessage: say "Les circuits ont été mise à jour"
                wait: Delais.circuits # @getOption 'attraitAutoReadDelay'
                app: app
                debug: 0 # and vx.isDEV


        if graphFileInfo and vx.use.cond and ( (vx.mapMode.hasCond and conditionsUsed ) or vx.mapMode.condAlerts )
            ajaxCached
                ref: app.ajaxCondRef
                URL: """#{vx.urls.CondURL or "#{vx.urls.DataURL}condSectionsATR.json"}#{if vx.q?.cq then '?'+vx.q?.cq else ''}"""
                #URL: "db/parcoursInfo.json"
                online: app.getNetworkState() is 'online'
                name: 'conditionsData'

                #cacheName:if vx.isAPP then 'cache-circuits' else undefined
                #cacheMode:'ls'

                noData: C.emptyArr?= []
                onNewValue:  app.setConditions
                onNewHeader: app.setConditionsInfo
                poolTime: poolTime # if vx.isDEV then 2 else 300 #poolTime # @getOption 'attraitAutoReadSpeed'
                poolMessage: say "Les conditions ont été mise à jour"
                wait: Delais.cond #if vx.isAPP then 4 else 0.3 # @getOption 'attraitAutoReadDelay'
                app: app
                debug: false #vx.isDEV



module.exports= { AppData, appData:AppData }

