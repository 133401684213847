{ log, timeStart, timeLog, timeEnd, snList, sSet, nr, time:getTime, lang }= Mod= vx= require 'vx/globals/Boot'
{ oDiffs, oAssign, oA, time:getTime, isPrefix, aSum, aPairs, aLast, isArray, sSplit, sSplit1, fromJS }= Mod

React= require 'react'


{ shortDate2 }= require 'vx/tools/DateUtils'

{Promise}= require 'vx/tools/NamedPromises'

{ say }= require 'vx/i18n/I18n'

{ mCalcDist }= require 'vx/math/MapGeometry'

{ Bounds }= require 'vx/math/Bounds'

{ topEval, sendHash }= require 'vx/globals/TopControlClient'

{ Condition }= require './ConditionModel'

{ CCF, condIsAlert, calcCondAfter, condFiltre }= require 'vx/globals/Conversions'

PR= require 'vx/tools/Persistance'




class ConditionsStateManager

    noConditions=[]
    #noConditionsProposed=[]

    defaultState:
        conditions: noConditions
        conditionsAlerts: noConditions
        conditionsFileInfo:{}
        conditionsDecorations: {}
        conditionSelectedId: ''
        conditionHoverId: ''
        conditionSelectedZoomMode: null #??
        conditionsUsed: false # false un itl first use
        _conditionsLookup:{}

    onBeforeMountDo: ['initCondRef']

    initCondRef: ->
        @ajaxCondRef= React.createRef()

        # From https://css-tricks.com/updating-a-css-variable-with-javascript/
        # Set cond css vars
        root = document.documentElement

        for name,color of CCF
            root.style.setProperty "--cond-#{name.toLowerCase()}",color


    urlAPIVars:


        cond:(val)->
            val= fromJS val
            log "CondManager urlAPI got cond= #{typeof val} #{val}"
            @setCondVisibility val

        conditionSelectedId:(val)->@setConditionSelectedId val

        conditionHoverId:(val)->@setConditionHoverId val,2


    # ******** cond Layer info*****************

    getCondVisibility: (name='conditions')->  !!( (@getNetworkState() is 'online') and @getLayerVisibility name )

    setCondVisibility: (val,name='conditions')->
        #log "setCondVisibility val=#{val}"
        if val
            if @state.conditionsUsed
                #log "setCondVisibility conditionsUsed is true @ajaxCondRef?.current is #{!!@ajaxCondRef?.current} #{ typeof @ajaxCondRef?.current?.doPoolData}"
                if @ajaxCondRef?.current
                     @ajaxCondRef.current?.doPoolData?()
            else
                #log "setCondVisibility setting conditionsUsed=true"
                @setState conditionsUsed:true
        @setLayerVisibility val,name


    getCondInfoURL: (name='conditions')->
        if vx.urls.Conditions and !vx.mapMode.condOld
            """#{vx.urls.Conditions}/closest?#{if vx.q?.cq then "#{vx.q.cq}" else ''}"""


    setConditionsInfo: (info)->
        #log "ConditionsStateManager.setConditionsInfo info=",info
        @setState conditionsFileInfo:info

    setConditions: (conditionsData)->
        oldConditions= null
        timeStart 'setConditions'
        if !conditionsData?.length?
            conditions= noConditions
        else
            conditions= Condition.fromJSONArr conditionsData
            condSegInfo={}
            if conditions?.length
                # recalc seginfo
                cond2= conditions[..] #.sort (a,b)-> (a?.dist or 0)-(b?.dist or 0)
                for cond in cond2 when cond.segIds
                    if isArray cond.segIds
                        for id in cond.segIds
                            (condSegInfo[id]?=[]).push cond
                    else condSegInfo[cond.segIds]=cond
        timeLog 'setConditions'
        @setState ((state)->
            timeStart 'setConditions setState'
            oldConditions= state.conditions
            #log " Setting conditions *****************************"
            minDate= calcCondAfter()
            conditionsAlerts= ( c for c in conditions when condIsAlert c)

            ret=
                conditions: conditions
                conditionsAlerts: conditionsAlerts
                condSegInfo: condSegInfo
                _conditionsLookup: _conditionsLookup= new -> (@[condition.id]=condition for condition in conditions; @ )
                conditionsDecorations: new -> (@[conditionId]=deco for conditionId,deco of state.conditionsDecorations when conditionId of _conditionsLookup ; @ )
                #_conditionsProposedIds: _conditionsProposedIds= ( id for id in state.conditionsProposedIds when id of _conditionsLookup  )
                #_conditionsProposed: ( _conditionsLookup[id] for id in _conditionsProposedIds )
                conditionSelectedId: if state.conditionSelectedId of _conditionsLookup then state.conditionSelectedId else ''
            timeEnd 'setConditions setState'
            ret
            ),->
                #log "setConditions.doUpdate !!!!!!!!!!!!!  old= #{oldConditions?.length} new= #{conditions?.length}",{oldConditions,conditions}
                if !(oldConditions?.length) and (conditions?.length)
                    #log "Cicuits went from no data to data=#{conditions.length}"
                    (Promise.vxGet 'conditions-data').vxResolve conditions.length
                #log "Did condition update *********************************8 "
        timeEnd 'setConditions'

    getConditions:  (state=@state)-> state.conditions or []
    getConditionId: (id,state=@state)-> state._conditionsLookup[id]
    getCondSegId:   (segId)-> @state.condSegInfo?[segId][0]
    getCondTypeSegId: (segId)->
        #log " condtype for seg #{segId} is #{@state.condSegInfo?[segId]?[0].cond}"
        # @state.condSegInfo?[segId]?[0].cond or 'noinfo'
        cond= condFiltre @state.condSegInfo?[segId],calcCondAfter()
        c= cond?[0]?.cond or 'noinfo'
        if (seg=@getSegId(segId)) and seg.data.f
            c='fermer'
            #log "getCondTypeSegId #{segId} seg=",seg
        #log " condtype for seg #{segId} is #{c}"

        c


    setConditionDecoration: (decoName,conditionId,value)-> @setConditionDecorations conditionId,"#{decoName}":value

    setConditionDecorations:(conditionId,decos)->@setState (state)->
        newDecos= oA {},state.conditionsDecorations, {"#{conditionId}":oA {},state.conditionsDecorations[conditionId],decos}
        #log "seting condition decos to",newDecos
        conditionsDecorations: newDecos

    setConditionsDecorations:(decos,state=@state,trans)->
        newDecos=oA {},state.conditionsDecorations,trans?.conditionsDecorations
        for conditionId,cDecos of decos
            newDecos[conditionId]= oA {},newDecos[conditionId],cDecos
        if trans
            #log "setConditionsDecorations delayed setStae trans=",trans
            oA {},trans,conditionsDecorations: newDecos
        else
            @setState conditionsDecorations: newDecos

    getConditionDecorations: (conditionId,state=@state)-> state.conditionsDecorations[conditionId]

    getConditionDecoration: (decoName,conditionId,state=@state)-> @getConditionDecorations(conditionId,state)?[decoName]

    getConditionSelectedId:(state=@state)->   state.conditionSelectedId

    setConditionSelectedId: (newIdIn,zoomMode,state=@state,cb)->
        oldId=@getConditionSelectedId state
        if !newIdIn
             newId= ''
        else newId= newIdIn

        return if ( !oldId and !newId ) or ( newId is oldId ) # no change, zoomMode only good on change
        #log "seting selected cond for #{oldId} to #{newId} zoomMode=#{zoomMode} "

        if newId and @getSelectedAttraitId state
            @setSelectedAttraitId null,state

        change= conditionSelectedId: newId

        if zoomMode? and ( zoomMode isnt state.conditionSelectedZoomMode)
            change.conditionSelectedZoomMode= zoomMode

        decoChanges= {}
        decoChanges[oldId]= { selected:undefined } if oldId
        decoChanges[newId]= { selected:1         } if newId

        changes= @setConditionsDecorations decoChanges,state,change

        self= @
        @setState changes,->
            #log "setConditionSelectedId Finised slectId newId=#{newId} oldId=#{oldId}"
            state= self.state
            if !newId and oldId
                self.setConditionHoverId oldId,2,state #(if self.state.vxScreenWidth is 'wide' then 2 else 1)
            cb() if cb


    getConditionHoverId:(state=@state)->   state.conditionHoverId

    setConditionHoverId: (newId,mode,state)->
        oldId= @getConditionHoverId state
        #log "Conditions setConditionHoverId mode=#{!!mode} newId=#{newId} oldId=#{oldId}"

        if !mode
            mode= undefined
        if oldId is newId
            oldId=null

        #log "seting hover cicuit for #{oldId} to #{newId}"

        change= conditionHoverId: (if mode then newId else '')

        decoChanges= {}

        (decoChanges[oldId]= { hover:undefined }) if oldId and oldId isnt newId
        (decoChanges[newId]= { hover:mode      }) if newId

        changes= @setConditionsDecorations decoChanges,state,change

        #log "doing changes=",changes
        @setState changes


    toggleConditionDecoration: (decoName,conditionId,state=@state)-> @setConditionDecoration decoName,conditionId,( if @getConditionDecoration decoName,conditionId then undefined else 1)

    toggleConditionVisibility: (conditionId)-> @toggleConditionDecoration 'visible',conditionId

    toggleConditionSelectedId: (newId,state=@state)->
        @closeAllMenus()
        log "toggleConditionSelectedId newId=#{newId} oldId=#{@getConditionSelectedId state}"
        if newId is @getConditionSelectedId state
             @setConditionSelectedId '',null,state
        else @setConditionSelectedId newId,null,state


    zoomConditions:(conditions,state=@state)->
        zoomMode= state.zooMode
        bnds= Bounds.aBNDS (condition.bounds for condition in conditions)
        #log "ConditionPathSVG.zoomCondition got #{zoomMode}",{zoomMode,@props,bnds}
        @fitMapBounds? bnds


    zoomCondition: (zoomMode)->
        bnds= Bounds.BNDS @props.condition.bounds
        @props.app?.fitMapBounds bnds



module.exports= { ConditionsStateManager }
