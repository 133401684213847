{ sList }= vx= require 'vx/globals/Boot'


# TODO: merge with DateUtils ?


n6= (x)-> x?.toFixed 6
n5= (x)-> x?.toFixed 5

n01= (x)->
    if x>9.5
         x?.toFixed 0
    else x?.toFixed 1


mod= (x,bases=[1000,60,60,24,Infinity])-> bases.map (div)-> r=x % div; x=x//div;r


msAsTimeStrFr= (ms)->

    return '?' if isNaN ms= parseFloat ms

    times= mod ms

    times.reverse()

#    t=for l,i in sList 'jour heure minute seconde' when v=times[i]
#        "#{v} #{l}#{if v>1 then 's' else ''}"

    t=for l,i in sList 'jr hr min sec' when v=times[i]
        "#{v} #{l}#{if v>1 then 's' else ''}"

    if !t.length
        t= ['0 sec']
    t.join ' '


msAsTimeStrEn= (ms)->

    return '?' if isNaN ms= parseFloat ms

    times= mod ms

    times.reverse()

#    t=for l,i in sList 'jour heure minute seconde' when v=times[i]
#        "#{v} #{l}#{if v>1 then 's' else ''}"

    t=for l,i in sList 'days hrs mins secs' when v=times[i]
        "#{v} #{l}"

    if !t.length
        t= ['0 sec']
    t.join ' '



asDateEn= (time,s)->
    d= new Date(time)
    an=   d.getFullYear()
    heure=d.getHours()
    mins= "0#{d.getMinutes()}".substr -2
    secs= "0#{d.getSeconds()}".substr -2 if s
    mois= "January February March April May June July August September October November December".split(' ')[d.getMonth()]
    jour= "Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(' ')[d.getDay()]
    date= d.getDate()

    datesuffix= switch date
        when 1 then 'st'
        when 2 then 'nd'
        when 3 then 'rd'
        else 'th'

    "#{mois} #{date}#{datesuffix} at  #{asTimeEn time,s}"


asDateEn2= (time,s)->
    d= new Date(time)
    an=   d.getFullYear()
    heure=d.getHours()
    mins= "0#{d.getMinutes()}".substr -2
    secs= "0#{d.getSeconds()}".substr -2 if s
    mois= d.getMonth()
    date= d.getDate()

    datesuffix= switch date
        when 1 then 'st'
        when 2 then 'nd'
        when 3 then 'rd'
        else 'th'

    "#{1+mois}/#{date}#{datesuffix} at  #{asTimeEn time,s}"



asTimeEn= (time,s)->
    d= new Date Math.round time
    heure=d.getHours()
    mins= "0#{d.getMinutes()}".substr -2
    secs= "0#{d.getSeconds()}".substr -2 if s

    """#{heure}h#{mins}#{if s then ":#{secs}" else ''}"""


asDateFr= (time,s)->
    d= new Date Math.round time
    an=   d.getFullYear()
    heure=d.getHours()
    mins= "0#{d.getMinutes()}".substr -2
    secs= "0#{d.getSeconds()}".substr -2 if s
    mois= "janvier février mars avril mai juin juillet août septembre octobre novembre decembre".split(' ')[d.getMonth()]
    jour= "dimanche lundi mardi mercredi jeudi vendredi samdi".split(' ')[d.getDay()]
    date= d.getDate()

    datesuffix= switch date
        when 1 then 'st'
        when 2 then 'nd'
        when 3 then 'rd'
        else 'th'

    "#{date} #{mois} à #{asTimeFr time,s}"


asDateFr2= (time,s)->
    d= new Date Math.round time
    an=   d.getFullYear()
    heure=d.getHours()
    mins= "0#{d.getMinutes()}".substr -2
    secs= "0#{d.getSeconds()}".substr -2 if s
    mois= d.getMonth()
    jour= "dimanche lundi mardi mercredi jeudi vendredi samdi".split(' ')[d.getDay()]
    date= d.getDate()

    "#{1+mois}/#{date} à #{asTimeFr time,s}"



asTimeFr= (time,s)->
    d= new Date Math.round time
    heure=d.getHours()
    mins= "0#{d.getMinutes()}".substr -2
    secs= "0#{d.getSeconds()}".substr -2 if s

    """#{heure}h#{mins}#{if s then ":#{secs}" else ''}"""


module.exports= { n01, n5, n6, asDateFr, asDateFr2, asDateEn, asDateEn2, asTimeFr, asTimeEn, msAsTimeStrFr, msAsTimeStrEn }
