{ meta, log, a2map, sList, oAssign, oSet, oDiffs, oShallow, beginingSeconds, isArray, fromJS, $ }= Mod= vx= require 'vx/globals/Boot'
ModuleName= 'SurfManager'

require './SurfManager.css'

{ oEq }= oShallow()

{ max, min, round }= Math

{ asDateFr:asDate, asDateFr2:asDate2, asTimeFr:asTime, n01 }= require 'vx/tools/Formating'
{ latMInv }= require 'vx/math/MapGeometry'

UI= require 'vx/UI'


{ checkbox, canvas, div, UIComponent, h5, h6, span, br, hr, details, summary, b:bold  }= UI

# TODO get colors from SurfColorV1 and V2
{ getAge, getAgeP, asColorAge, OldColor, NoneColor }= require './SurfColor'

# I18n
{ sayModule, getLang }= require 'vx/i18n/I18n'
say= sayModule ModuleName

appSay= sayModule 'App' #dont refactor all transaltions


class SurfStateManager

    defaultState:
        layer_conditions__visibility:   false
        layer_surfaceuses__visibility:   false
        #layer_surfaceusesv2__visibility: false
        layer_surfaceusesbrute__visibility: false
        debugSurf: vx.DebugSurf
        surfMaxTime: 0
        surfBrutMaxTime: 0
        #surfV2MaxTime: 0
        surfSegInfo: null

    persistantState: {} # layer_surfaceuses__visibility:1 } #, layer_surfaceusesbrute__visibility:1, layer_surfaceusesv2__visibility:1, debugSurf:1 }

    urlAPIVars:

        surf:(val)->
            val= fromJS val
            log "SurfManager urlAPI got surf= #{typeof val} #{val}"
            @setSurfVisibility val


    getSurfVisibility: (name='surfaceuses')-> !!( (@getNetworkState() is 'online') and @getLayerVisibility name )

    getSurfInfoURL: (name='surfaceuses')-> switch name
        when 'surfaceuses',''  then "#{vx.urls.SurfServerV2}/tiles/surfv2/closest" #"#{vx.urls.SurfServer}/getsubinfo"
        when 'surfaceusesv2'   then "#{vx.urls.SurfServerV2}/tiles/surfv2/closest"
        when 'surfaceusesbrute' then "#{vx.urls.SurfServerV2}/tiles/surfbrut/closest"
        else ''

    setSurfVisibility: (val,name='surfaceuses')->
        #log """setSurfVisibility name=#{name} val=#{val} state=#{@state["layer_#{name}__visibility"]} ignore=#{!val is !@state["layer_#{name}__visibility"]}"""
        #return if !val is !@state["layer_surfaceuses_#{name}__visibility"] # no change
        if val
            switch (name or '')
                when '','surfaceuses'   then @checkSurfV2MaxTime() #@checkSurfMaxTime()
                when 'surfaceusesbrute' then @checkSurfBrutMaxTime()
                #when 'surfaceusesv2'    then @checkSurfV2MaxTime()
            #showing do a get time

        #@doResetControlsPatch() # force map to resize so controls reposition
        @setLayerVisibility val,name

    setSurfSegInfo: (info)->
        @setState surfSegInfo: info

    # debug surf

    getDebugSurf: -> @state.debugSurf
    setDebugSurf: (v)-> @setState debugSurf:!!v

    onMountDo: ['shouldCheckSurfMaxTime']

    shouldCheckSurfMaxTime:->
        #@checkSurfMaxTime()     if @state.layer_surfaceuses__visibility
        @checkSurfBrutMaxTime() if @state.layer_surfaceusesbrute__visibility
        @checkSurfV2MaxTime()   if @state.layer_surfaceuses__visibility #@state.layer_surfaceusesv2__visibility

    doResetControlsPatch:->
        return# cant get this to work
        #not working
        #return if !window?
        self= @
        log "in doResetControlsPatch",{self}
        setTimeout (->
            log " touch controls"
            # ok force control repostioning
            if false and map= self.gmapsMap?.state?.gmapMap
                map.controls.push  map.controls.pop()
            ),100

    getSurfMaxTime:->      @state.surfV2MaxTime or 0 #@state.surfMaxTime or 0
    getSurfBrutMaxTime:->  @state.surfBrutMaxTime or 0
    getSurfV2MaxTime:->    @state.surfV2MaxTime or 0

    #cause knowone usesit
    doOnline: ->
        log "SurfStateManager going online"
        @setState networkState: 'online'
        if @surfWaitingForOnline
            @surfWaitingForOnline= false
            @checkSurfMaxTime()
        if @surfBrutWaitingForOnline
            @surfBrutWaitingForOnline= false
            @checkSurfBrutMaxTime()
        if @surfV2WaitingForOnline
            @surfV2WaitingForOnline= false
            @checkSurfV2MaxTime()


    checkSurfMaxTime:->
        log "SurfStateManager.checkMaxTime networkstate is #{@getNetworkState()}"
        #return @setState surfMaxTime:1511546400000
        #dont check if not visible

        if (@getNetworkState() isnt 'online')
            @surfWaitingForOnline=true
            return
        @surfWaitingForOnline=false

        if @checkingSurfMaxTime
            #log "SurfStateManager.checkMaxTime already waiting ignore"
            return
        self= @
        @checkingSurfMaxTime= fetch "#{vx.urls.SurfServerV2}/getmaxtime"
            .then (response)-> response.text() #coud be a promise
            .then (data)->
                self.checkingSurfMaxTime=null
                mt= parseFloat data
                if !isNaN mt
                    #log "SurfStateManager.checkMaxTime set maxTime to #{mt}"
                    self.setState surfMaxTime:mt
                #else
                #    log "SurfStateManager.checkMaxTime #{data} not a number nochange"

            .catch (err)->
                self.checkingSurfMaxTime= null
                #log "checkingSurfMaxTime got error #{err}"

    checkSurfBrutMaxTime:->
        #log "SurfStateManager.checkSurfBrutMaxTime networkstate is #{@getNetworkState()}"
        #return @setState surfMaxTime:1511546400000
        #dont check if not visible

        if (@getNetworkState() isnt 'online')
            @surfBrutWaitingForOnline=true
            return
        @surfBrutWaitingForOnline=false

        if @checkingSurfBrutMaxTime
            #log "SurfStateManager.checkSurfBrutMaxTime already waiting ignore"
            return
        self= @
        @checkingSurfBrutMaxTime= fetch "#{vx.urls.SurfServerV2}/tiles/surfbrut/get?maxTime"
            .then (response)-> response.text() #coud be a promise
            .then (data)->
                #log "SurfStateManager.checkSurfBrutMaxTime got data=#{data}"
                self.checkingSurfBrutMaxTime=null
                {maxTime:mt}= JSON.parse data
                if !isNaN mt
                    #log "SurfStateManager.checkMaxTime set maxTime to #{mt}"
                    self.setState surfBrutMaxTime:mt
                #else
                #    log "SurfStateManager.checkMaxTime #{data} not a number nochange"

            .catch (err)->
                self.checkingSurfBrutMaxTime=null
                #log "checkingSurfMaxTime got error #{err}"

    checkSurfV2MaxTime:->
        #log "SurfStateManager.checkSurfV2MaxTime networkstate is #{@getNetworkState()}"
        #return @setState surfMaxTime:1511546400000
        #dont check if not visible

        if (@getNetworkState() isnt 'online')
            @surfV2WaitingForOnline=true
            return
        @surfV2WaitingForOnline=false

        if @checkingSurfV2MaxTime
            #log "SurfStateManager.checkSurfV2MaxTime already waiting ignore"
            return
        self= @
        @checkingSurfV2MaxTime= fetch "#{vx.urls.SurfServerV2}/tiles/surfv2/get?maxTime"
            .then (response)-> response.text() #coud be a promise
            .then (data)->
                #log "SurfStateManager.checkSurfV2MaxTime got data=#{data}"
                self.checkingSurfV2MaxTime=null
                {maxTime:mt}= JSON.parse data
                if !isNaN mt
                    #log "SurfStateManager.checkMaxTime set maxTime to #{mt}"
                    self.setState surfV2MaxTime:mt
                #else
                #    log "SurfStateManager.checkMaxTime #{data} not a number nochange"

            .catch (err)->
                self.checkingSurfV2MaxTime=null
                #log "checkingSurfMaxTime got error #{err}"


    getSubInfo4Pt: (pt,r=2000,cb)->

            #server mode
            #assum online for now use resuest if not ...
            $.ajax
                url: @surfPath+'/getsubinfo'
                type: 'GET'
                datatype:'jsonp'
                data:pt
                cache:false
                crossDomain:true
                success:(subinfo)->
                    #log "async getClosest got=",subinfo
                    if cb
                        cb null,subinfo
                error:(e)->
                    log "async subinfo failed e=",e
                    if cb then cb e
            'async'




surfToggle= ({app,name='surfaceuses',id="cb-#{name}",disabled,key=id})->
    checkbox
        name: name
        key:  key
        id:   id
        disabled: disabled
        checked:    app.getSurfVisibility name
        onNewValue: app.setSurfVisibility
        title: appSay "titre couche #{name}"
        appSay "bouton couche #{name}"


surfToggleV2= ({app,name='surfaceusesv2',id="cb-#{name}",disabled,key=id})->
    checkbox
        name: name
        key:  key
        id:   id
        disabled: disabled
        checked:    app.getSurfVisibility name
        onNewValue: app.setSurfVisibility
        title: appSay "titre couche #{name}"
        appSay "bouton couche #{name}"


surfToggleBrute= ({app,name='surfaceusesbrute',id="cb-#{name}",disabled,key=id})->
    checkbox
        name: name
        key:  key
        id:   id
        disabled: disabled
        checked:    app.getSurfVisibility name
        onNewValue: app.setSurfVisibility
        title: appSay "titre couche #{name}"
        appSay "bouton couche #{name}"

surfLayer= null

ms2hrs= 60*60*1000


surfPtDetails= meta class SurfPtDetails extends UIComponent

    @propTypes:
        pt:      @type.object
        maxTime: @type.number
        infoURL: @type.string
        graph:   @type.object
        debug:   @type.bool
        setInfo: @type.func


    constructor: (props)->
        super props
        @state=
            time: null
            veh: null
            status:'idle'


    componentWillMount:->
        {pt}= @props
        @fetchPtInfo pt

    componentWillReceiveProps: (newProps)->

        #check for pt change
        pt= @props.pt
        npt= newProps.pt
        return if (pt is npt) or ( pt and npt and pt.x==npt.x and pt.y==npt.y)


        #ok changing
        #clear info
        @setState
            statuts: if npt then 'waiting' else 'idle'
            time: null
            veh:  ''

    componentDidUpdate: (oldProps)->

        #check for pt change
        pt= @props.pt
        opt= oldProps.pt
        # return if no change
        return if (pt is opt) or ( pt and opt and pt.x==opt.x and pt.y==opt.y)
        return if !pt #status should be idle
        #ok got a new pt
        #clear info
        @fetchPtInfo pt


    fetchPtInfo: (pt,props=@props)->
        self= @
        { infoURL }= props
        url="#{infoURL}?x=#{pt.x}&y=#{pt.ym}"
        #log "surfPtDetails.componentWillMount.fetch url=#{url}"
        fetch url
            .then (response)-> response.json()
            .then (data)->
                # test still requires
                return if pt isnt self.props.pt
                [info]= data
                #log "SurfManager.surfPtDetail.fetchPtInfo got data=",data
                if self.props.setInfo
                    #log "SurfPtDetails.fetchPtInfo.then doing setInfo"
                    self.props.setInfo info
                self.setState
                    status:'done'
                    time: info.time
                    veh:  info.lastVeh

        #response.text().then (txt)->log "doGotPtInfo txt=",txt
        #response.data().then (data)->log "doGotPtInfo data=",data


    render:(props=@props,state=@state)->
        {time,veh,status}= state
        #log "surfPtDetails.render time=#{time}, veh=#{veh}, status=#{status}"
        return div '','' if !status or status is 'idle'
        return div '',appSay "mess attent serveur surfacage" if status is 'waiting'
        return div '',appSay "mess pas info surfacage" if !time
        maxTime= props.maxTime
        age= (maxTime-time)/ms2hrs
        appSay "mess segment surfacer",{date:time,majDate:maxTime,age,veh}



displayMPt= dMPt= (pt,graph,maxTime)->

        segTxt=''
#         if graph
#             segInfo= graph.findClosestSegmentToPtM pt
#             if segInfo.seg
#                  segTxt=" [#{segInfo.seg.id}]"
        if pt.segid
            segTxt=" [#{pt.segid}]"
        if maxTime
             age= " (#{getAge maxTime,pt.t})"
        else age= ''
        [ span '',"#{(latMInv pt.y).toFixed 6},#{pt.x.toFixed 6} #{asDate2 pt.t,1}#{segTxt}#{age}", br() ]

samePt= (p0,p1)-> p0? and p1? and p0.x==p1.x and p0.y==p1.y



displayPassage= dPass=(passage,maxTime,refSeg,graph)->
        {entryTime,entryPt,exitTime,exitPt,vehId,innerPts,segId,isReversed,uturn,spd,isBest,isBad}= passage
        #log "dpass passage=",{passage,seg:graph?.segments?[segId]}
        #dist= graph.segments[segId].dist
        hrs= (exitTime-entryTime)/(60*60*1000)
        if refSeg and refSeg isnt segId
            log "wrong seg on ",{passage,refSeg}
            return span '',"Erreur #{refSeg} isnt #{segId}"

        type=''

        type+= '*' if isBest
        type+= '?' if isBad
        type+= 'I' if isReversed
        type+= 'U' if uturn
        type+= 'D'if samePt innerPts?[0],entryPt
        type+= 'F'if samePt innerPts?[innerPts.length-1],exitPt
        type=' '+type if type

        #log "displayPassage = ",{passage,maxTime,refSeg,hrs,spd}
        div '$width=25em',
            bold '',"#{vehId} #{asDate2 entryTime}>#{asTime exitTime} (#{getAge maxTime,entryTime,exitTime}) #{n01 spd}km/h#{type}"
            br()
            span '',"Entrée estimée=#{asDate entryTime,1} (#{getAge maxTime,entryTime})",br()
            dMPt entryPt,graph,maxTime
            span '',"Sortie estimée=#{asDate exitTime,1} (#{getAge maxTime,exitTime})",br()
            dMPt exitPt,graph,maxTime
            if innerPts? and innerPts.length
                details '.__innerpts',
                    summary '',"#{innerPts.length} points sur le segment"
                    div '',
                        ( dMPt pt,null,maxTime for pt in innerPts)



surfPtDetailsV2= meta class SurfPtDetailsV2 extends SurfPtDetails

    fetchPtInfo: (pt,props=@props)->
        self= @
        { infoURL }= props
        url="""#{infoURL}?x=#{pt.x}#{if pt.y? then "&y=#{pt.y}" else ''}#{if pt.ym? then "&ym=#{pt.ym}" else ''}#{if pt.z? then "&#{pt.z}" else ''}"""
        log "SurfManager.surfPtDetailsV2.fetchPtInfo url=#{url}" if vx.isDEV
        fetch url
            .then (response)-> response.json()
            .then (data)->
                # test still requires
                #log "log SurfManager.surfPtDetailsV2.fetchPtInfo.fetch.then pt=#{pt} self.props.pt=#{self.props.pt} not same is #{pt isnt self.props.pt} data=",{data,pt,cpt:self.props.pt}
                #return if pt isnt self.props.pt
                # do we have a race?
                log "SurfManager.surfPtDetailV2.fetchPtInfo got data=",data if vx.isDEV
                if self.props.setInfo
                    #log "SurfPtDetails.fetchPtInfo.then doing setInfo"
                    self.props.setInfo data
                self.setState
                    status:'done'
                    data: data


    render:(props=@props,state=@state)->
        {data,status}= state
        #log "SurfManager.surfPtDetailsV2.render data=#{!!data} status=#{status}"
        return div '','' if !status or status is 'idle'
        return div '',appSay "mess attent serveur surfacage" if status is 'waiting'
        return div '',appSay "mess pas info surfacage" if !data
        log "SurfManager.surfPtDetailsV2.render data=",data if vx.isDEV

        passage= data?.passage
        if passage and !isArray passage
             passages= [passage]
        else passages= passage or []

        passage= null
        for psg in passages when psg.isBest
            passage=psg
            break

        switch
            when passages and props.debug
                { maxTime }= data

                div '.surf-pt-details-v2',
                    if passage
                        {entryTime,exitTime,segId,isReversed}= passage
                        [
                          bold '',"""Segment: #{segId} #{if props.graph then "#{n01 props.graph.segments[segId].dist}km " else ''} age: #{getAge maxTime,entryTime,exitTime}"""
                          dPass passage,maxTime,segId,props.graph
                        ]
                    else
                        #log "data=",data
                        bold '',"Segment: #{data.closestSegId} pas de passage vaild"
                    br()
                    if passages and (passages.length>1 or !passage)
                        details '.surf-pt-details-v2__passages',
                            summary '',"#{passages.length} passages"
                            div '',( dPass psg,maxTime,passage?.segId,props.graph for psg in passages )
                    if passage
                         appSay "mess segment surfacer v2",{passage,maxTime}
                    else appSay "mess pas info surfacage"
            when passage
                { maxTime }= data
                age= getAgeP maxTime,passage
                return appSay "mess segment surfacer v2 vieux",{passage,maxTime,heures:72} if age>72
                appSay "mess segment surfacer v2",{passage,maxTime}
            else
                div '.surf-pt-details-v2',
                    appSay "mess pas info surfacage"


surfLegend2= meta class SurfLegend2opardg extends UIComponent

        @propTypes:
            maxTime:  @type.number
            
        render: (props=@props)->
            div '.surflegend',
                $display: if !props.visible then 'none'
                span '.surflegend__maxtime',appSay "mess legend surfaceuses",date:new Date props.maxTime


surfLegend= meta class SurfLegend extends UIComponent

        @propTypes:
            maxTime:  @type.number
            drawOps:  @type.object

        render: (props=@props)->
            canvas 'width:250 height:36',
                __proto__: props
                ref: @setCanvas
                maxTime: undefined
                drawOps: undefined
                visible: undefined
                $display: if !props.visible then 'none'
                $height:  if !props.visible then '0px' else '36px'
                $width:  '250px'
                $backgroundColor: 'rgba(255,255,255,0.5)'

        setCanvas: (node)-> @canvas= node

        componentDidMount: ->
            #log "SurfLegend.componentDidMount doing drawlegend canvas=",{@canvas}
            @initColors()
            @drawLegend()

        componentDidUpdate:->
            #log "SurfLegend.componentDidUpdate doing drawlegend canvas=",{@canvas}
            @drawLegend()

        # **************************
        # Color management and drawing *
        # **************************

        step: 60*60*1000 #hours
        base: 10
        maxSteps: 6*24 # 4 days

        asAge: (time)-> (@props.maxTime-time)//@step

        initColors:->
            @colors= []
            for age in [0..@maxSteps]
                @colors[age]= @asColorAgeV2 age

        asColor: (time)-> @colors[ min @maxSteps,@asAge time ]

        red=    r:255,g:0,b:0
        orange= r:255,g:180,b:0
        green=  r:0,g:255,b:0
        yellow= r:255,g:255,b:0

        grey= r:128,g:128,b:128
        grey2=  r:160,g:160,b:160
        white= r:255,g:255,b:255


        makeGrad: (a,b,pcnt)->
            c={}
            for k,v of a
                c[k]= v+(b[k]-v)*pcnt
            c

        asColorStr: (c)->
            asHex=(x)->"0#{max(0,min(255,round(x))).toString(16)}".substr -2
            '#'+( asHex c[k] for k in 'rgb').join('')


        asColorAgeV2: (age)->
            @asColorStr switch
                when 0<= age <= 18 then @makeGrad green,yellow, age/18
                when 18< age <= 36 then @makeGrad yellow,orange, (age-18)/18
                when 36< age <= 72 then grey
                else white


        defOps:
            index:0
            width:    'auto'
            lheight:  'auto'
            fontName: "roboto,arial"
            fontHeight: 11
            stops:false
            fontColor:'#000000'
            tic: 2
            #lcolors:[ '#ffffff','#b0b0b0','#808080','#404040','#000000']
            stops:
                0:'0hr'
                13:'12hrs'
                25:'24hrs'
                37:'36hrs'
                49:'72hrs'
                58:appSay 'et +'
                70:appSay 'non dispo.'
                #60:'fermé'
            tics:
                0:1
                13:1
                25:1
                37:1
                49:1
                


        drawLegend: ()->

            #log "draw legend canvas=",{@canvas}
            return if not $canvas=@canvas

            { width, lheight, fontName, fontHeight, fontColor, stops, tic, tics, lcolors }= ops= oAssign {},@defOps,@props.drawOps
            if !lcolors
                colors= @colors
                lcolors= [].concat colors[0..36],( colors[c] for c in [37 .. 72] by 3 ) ,( OldColor for c in colors[-11 .. -1]), (NoneColor for c in colors[-11 .. -1] )

            #log "********* in draw legend",{$canvas,ops,maxTime: @props.maxTime}

            $canvas= @canvas
            maxTime= @props.maxTime


            if width is 'auto'
                width= $canvas.width or 250
                #log "auto width is #{width}"
            if lheight is 'auto'
                height= $canvas.height
                lheight= Math.max 5,(height-(1+tic+2.1*fontHeight))
                #log "auto lheight is #{lheight}"
            #else
                #$canvas.height= height= lheight+tic+2*fontHeight

            #$canvas.width= width
            #canvas.height= height

            #log "legende surfaceuse draw w=#{width} h=#{height}"

            ctx= $canvas.getContext '2d'

            ctx.clearRect 0,0,width,height

            cnt= lcolors.length

            font= if fontName and fontHeight
                "#{fontHeight}px #{fontName}"
            else ''

            ctx.save()
            #ctx.setTransform 1,0,0,1,0,0

            ox=0
            oX=0
            if stops and ( stops[0] or stops[cnt] or stops[cnt-1])
                ctx.font = font if font
                if stops[0]
                    ox= ctx.measureText("#{stops[0]}").width/2
                if stops[cnt] or stops[cnt-1]
                    oX= Math.max(
                        ctx.measureText("#{stops[cnt] or ''}").width/2
                        ctx.measureText("#{stops[cnt-1] or ''}").width/2
                        )
            bw= (width-(ox+oX))/cnt

            #log "init legend w=#{width} h=#{height} lh=#{lheight} ox=#{ox} cnt=#{cnt} bw=#{bw}"

            ctx.strokeWidth= 1
            for i in [0 ... cnt]
                ctx.fillStyle= lcolors[i]
                ctx.fillRect ox+(i*bw),0,bw,lheight
                ctx.strokeStyle= lcolors[i]
                ctx.strokeRect ox+(i*bw),0,bw,lheight

            ctx.strokeStyle= fontColor if fontColor
            ctx.strokeRect ox,0,(cnt*bw),lheight

            ctx.font= font if font
            ctx.fillStyle= fontColor if fontColor
            
            if tic and tics
                for i in [0 .. cnt] when tics[i]
                    #tSz= ctx.measureText("#{stop}")
                    if tic
                        ctx.beginPath()
                        ctx.moveTo ox+(i*bw),1
                        ctx.lineTo ox+(i*bw),lheight+tic
                        ctx.stroke()

            if stops
                for i in [0 .. cnt] when stop=stops[i]
                    tSz= ctx.measureText("#{stop}")
                    if tic
                        ctx.beginPath()
                        ctx.moveTo ox+(i*bw),1
                        ctx.lineTo ox+(i*bw),lheight+tic
                        ctx.stroke()
                    ctx.fillText "#{stop}",ox+(i*bw)-(tSz.width/2),lheight+tic+fontHeight

            #log "Legend date is #{date}"

            dateTxt= @getLegendDateTxt maxTime

            if dateTxt
                #log "dateTxt is #{dateTxt}"
                txtSize= fontHeight
                ctx.font= "bold #{txtSize}px #{fontName}"
                while ctx.measureText(dateTxt).width > width
                    txtSize= txtSize-0.5
                    ctx.font= "bold #{txtSize}px #{fontName}"

                ctx.fillText dateTxt,0,lheight+tic+2*fontHeight

            ctx.restore()


        getLegendDateTxt: (maxTime)->
            if maxTime
                appSay "mess legend surfaceuses",date:new Date maxTime
            else ''



surfLegendOld2= meta class SurfLegendOld2 extends SurfLegend


        asColorAgeV2: (age)-> asColorAge age

        initColors:->
            super()
            #log "surfLegend2.initColors got colors=",{@colors}
            @colors

        getLegendDateTxt: (maxTime)->
            if maxTime
                appSay "mess legend surfaceuses",date:new Date maxTime
            else ''



surfLegendBrut= meta class SurfLegendBrut extends SurfLegend


        asColorAgeV2: (age)-> asColorAge age

        initColors:->
            super()
            log "surfLegend2.initColors got colors=",{@colors}
            @colors


        getLegendDateTxt: (maxTime)->
            "Brute: "+if maxTime
                appSay "mess legend surfaceuses",date:new Date maxTime
            else ''


surfLayerFactory= ({tileLayer,API})->
    ({key,visible,minZoom=5,maxZoom=18,maxTime,zIndex=4,id,name})->
        maxTimeTag=Math.round((maxTime or 0)/1000).toString 36
        ret=tileLayer
            key: skey= key or 'surflayer'
            id: id ? skey
            name: name ? skey
            visible: !!( maxTime and visible)
            #tileURL:"https://api.mapbox.com/styles/v1/mapbox/outdoors-v10/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZ2RyYXBlYXUiLCJhIjoiYmU3NDgwYmYzYmFjMmRhYTY0YWZjZWU4MGQ1OThhMDAifQ.g-B5PBKFOXwks9A1nqD79Q"
            tileURL: if API is 'leaflet'
                     "#{vx.urls.SurfServerV2}/tiles/surfv2/{z}/{x}/{y}.png"
                else "#{vx.urls.SurfServerV2}/tiles/surfv2"
            minZoom:minZoom
            maxZoom:maxZoom
            tileSize:256
            zoomOffset:0
            updateWhenZooming: false
            updateWhenIdle: false
            zIndex:zIndex
        #if visible
            #log "Surf layer=",{ret,maxTimeTag,maxTime}
        ret


module.exports= { SurfStateManager, surfToggle, surfToggleV2, surfToggleBrute, surfLayerFactory, surfLegend, surfLegend2, surfLegendBrut, surfPtDetails, surfPtDetailsV2, ms2hrs  }

