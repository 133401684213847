
# Adds Style String to reactElem ...
log= console.log.bind log

React= require 'react'
createElement= React.createElement

{ isProps, mergeProps, makeElementFactories }= require './ReactElem'


# TODO this can be better ...
StringParser= r= ///         #0
    (?=\S)
    (                         #1
          (?:") ([^"]*) (?:") #2
       |  (?:') ([^']*) (?:') #3
       | [^\s]+ 
    )  ///g

STOP={}

        
        
parseStr= (str,cntxt={},parser)->
    keys={}
    last=null
    
    parser?= ///         #0
        (?=\S)
        (                       #1
            (?:") ([^"]*) (?:") #2
        |   (?:') ([^']*) (?:') #3
        |   [^\s]+ 
        )  ///g
    
    while match= parser.exec str
        #log "match=",match
        word= match[3] or match[2] or match[1]
        [name,vals]= word.split /=|:/,2
        
        if word[0] is '|' 
            keys.children= str[(1+match.index) ...]
            break
        
        if vals?
            val= word[(1+name.length)..]
            if word[name.length] is ':'
                try (Function '_',"return #{val}").call cntxt,cntxt
        else val=true
        
        keys[name]=val
    
    keys

    

    


PropInfo=
    '.':addCls=(op,className,value,out)->
        return if !value
        #log "addClass _ for #{className} ",{op,className,value,out} if op is '_'
        #console.trace()
        
        if typeof value is 'string'
            #className="#{className}=#{value}"
            className=value
        if ocls= out.className
            out.className="#{ocls} #{className}"
        else 
            out.className= className
            
    '_': 
            addCls
            
    # CAREFULL only first char is used so this never matchs see '$', sx used by MAterial-UI
    '$$':sx=(op,styleName,value,out)->
        ostyle= out.sx?=  {}
        ostyle[styleName]= value
        
    '$':(op,styleName,value,out)->
        #if styleName[0] is '$'
        #    return sx '$$',styleName[1..],value,out,props
        ostyle= out.style?= {}
        ostyle[styleName]= value
        
    '#':(op,id,value,out)-> out.id= id
    
    '-': (op,dataName,value,out)-> out['data-'+dataName]=value
        
    'className':(op,name,val,out)->
        if out.className
             out.className="#{val} #{out.className}"
        else out.className= val
        
    


parseProps= (props,childs,info=PropInfo)->
    out={}
    for name,value of props when value?
        n0= name[0]
        if fn= info[n0] or info[name]
             fn n0,name[1..],value,out
        else out[name]= value if value?
    out
    

Cache={} # Map maybe ....
parsePropsStr= (pstr)-> Cache[pstr]?= parseProps parseStr pstr
#     if !Cache[pstr]
#          Cache[pstr]= p= parseProps parseStr pstr
#          log " Parse '#{pstr}' got ",p
#     Cache[pstr]
    
    
    
createElementSS= (elem,props,...childs)-> # (elem,[str-props],[{props}],*childs)->

        if typeof props is 'string'
            sprops= parsePropsStr props
            if isProps childs[0]
                #merge static and dynamic props
                props= mergeProps sprops,(parseProps childs.shift()),childs
                createElement elem,props
            else
                if sprops.children
                     createElement elem,{...sprops,children:undefined},sprops.children,...childs
                else createElement elem,sprops,...childs
        else if isProps props
             # no static props just dynamic ones
             createElement elem,(parseProps props),...childs
        else # no props at all 'props' is a child
            createElement elem,undefined,props,...childs


ElementFactorySS= (elem,defProps,ename)->

        if !elem
            log "ReactElemSS.ElementFactorySS elem is falsy",{elem,defProps,ename}
            return ->''

        if typeof defProps is 'string'
            defProps= parsePropsStr defProps

        # factory inheritance
        if factoryInfo= elem?.isElementFactory
            defProps= mergeProps factoryInfo.props,defProps if factoryInfo.defProps
            elem= factoryInfo.elem

        if !defProps
            factory= createElementSS.bind null,elem
        else
            factory=
                (props,...childs)-> # (elem,[str-props],[{props}],*childs)->
                    # a prop string ALSO means to scan props for special props that start with $ _ . - like in string'
                    if typeof props is 'string'
                        sprops= mergeProps defProps,parsePropsStr props
                        if isProps childs[0]
                            #merge static a dynamic props
                            props= mergeProps sprops,parseProps childs[0]
                            childs.shift() # remove ops
                        else props=sprops
                        createElement elem,props,...childs
                    else 
                        if isProps props
                            props= mergeProps defProps,parseProps props
                            createElement elem,props,...childs
                        else #push back props
                            createElement elem,defProps,props,...childs
        factory.isElementFactory= { elem, props:defProps, ss:true }
        factory.displayName= ename if ename
        factory 


ElementFactoriesSS= makeElementFactories elementFactory:ElementFactorySS

useEffect2= (cond,fnc)-> React.useEffect (-> fnc cond),cond


module.exports= { ElementFactories:ElementFactoriesSS, ElementFactory:ElementFactorySS, createElementSS, isProps, mergeProps, parseProps, parseStr, useEffect2 }
                
