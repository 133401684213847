### eslint-disable ###
# TODO: Imporatnt check global Graph ?????

{ meta, log, oAssign, oPop, oClone, oSet, oDiffs, oShallow, aPairs, oA, aLast, sReplace, isPrefix, $ }= vx= require 'vx/globals/Boot'

{ BaseClass }= require 'vx/tools/BaseClass'

#{ Graph }=                 require 'Graph2c'

{ readVxlJson, readAJson, LineStr, linesFromGraph5, linesFromGraph5Async }= require 'vx/math/LineStr'
{ distInfo2Pt }=           require 'vx/math/Geometry'
{ mCalcDist, mCalcDistPt, decodeGEPolyline}= require 'vx/math/MapGeometry'
{ Bounds }= require 'vx/math/Bounds'
{ Pt }= require 'vx/graph/Graph5'

require 'vx/tools/NamedPromises' # for google router

{ max, min, abs }= Math

#UI= require 'vx/UI'
# 18n
{ say }= require 'vx/i18n/I18n'

{ o1 }= oShallow()

{ gDirsP }= require './GServices'

#log "Router ***************************** { gDirsP }= require 'GServices' =",gDirsP


#{ but, butj, bgroup, argJoin, fkm, popupCloseBut  }= Require 'UIutils'

#{ ListPage } = Require 'ListPage'

meta class Router extends BaseClass

    isSlow: false # used to avoid live updates ...

    lineOps:
        stroke:true
        weight:9
        color: '#ff00ff'
        opacity:0.4
        simplify:3
        className: 'itin'

    constructor: (data,ops)->
        ops?= {}
        lops= oPop ops,'lineOps'
        super ops
        @data= data
        if lops then @lineOps= oAssign @lineOps,lops
        return @

    getPath: (start,end,ops)->

    getPathP: (start,end,ops)->
        ret= @getPath start,end,ops
        #log "Router.getPathP generic did ",{start,end,ops,ret}
        Promise.resolve ret

    #getPaths: (start,endsArr)-> ( @getPath(start,end) for end in endsArr when end )

    getLineAt: (pt)->false



meta class AucunRouter extends Router

    getPath: (start,end,ops)->
        path=DirectPath._(oA {start:start,end:end,lineOps:@lineOps},ops)
        path.dir= [ nom:'direct',dst:mCalcDistPt(start,end) ]
        path

    getPathP: (start,end,ops)->
        #log "AucunRouter.getPathP for",{start,end,ops}
        Promise.resolve @getPath start,end,ops




meta class GoogleRouter extends Router
    #Router that uses a graph for resolution
    #create a directions service

    isSlow: true
    canNavigate: true

    graphOps:{}

    constructor: (data,ops={})->
        gops= oPop ops,'graphOps'
        super ops,data
        if gops then @graphOps= oAssign {},@graphOps,gops
        return @

    getPathP: (start,end,ops)->
        req= oA {},@graphOps,ops,
            origin:     { lat:start.y, lng:start.x }
            destination:{ lat:end.y,   lng:end.x   }

        #log "GoogleRouter.getPathP for",{start,end,ops,req}

        dirP= gDirsP req

        dirP.then (resp)=>
            #log "GoogleRouter.getPathP dirs response",resp.routes?[0]
            if !resp.routes?.length
                #log "GoogleRouter.getPathP dirs response no routes resp=",resp
                path=DirectPath._(oA {start:start,end:end,lineOps:@lineOps},ops)
                path.dir= [ nom:'direct',dst:mCalcDistPt(start,end) ]
                return path

            route= resp.routes[0]

            ins=[]
            for leg in route.legs
                for step in leg.steps
                    ins.push step.instructions

            pts= route.overview_path ? decodeGEPolyline route.overview_polyline.points
            lpath=LPath._  pts:( Pt.fromNative p for p in pts),lineOps:@lineOps
            #log "GoogleRouter.getPathP lpath=",lpath
            lpath.dirHTML= ins.join '<br />'
            lpath


meta class GraphRouter extends Router
    #Router that uses a graph for resolution

    graphOps:{}

    constructor: (data,ops={})->
        gops= oPop ops,'graphOps'
        super null,ops
        if gops then @graphOps= oAssign {},@graphOps,gops
        @graph= Graph._ data,@graphOps if data
        return @

    getPath: (start,end,ops)->
        path= @graph.pathFinder(start,ops)(end)
        LPath._  pts:path.pts,lineOps:@lineOps

    getLineAt: (pt)-> @graph.closestLine2Pt pt

    getSegId: (segId)-> @graph.segments[ segId ]



meta class GraphRouter2 extends GraphRouter
    #Router that uses a graph for resolution


    getPath: (start,end,ops)->

        if vx.FermerManager and vx.isUUIDclosed and vx.FermerManager.layerVisible
            # IS THSI STILL USED??????
            closed= vx.isUUIDclosed
            path= @graph.pathFinder(start,
                oA
                    estimate:(linestr)->
                        if linestr.data?.uuid of closed
                            -1 # ignore
                        else linestr.getDist()
                    ops
                )(end)
        else
            path= @graph.pathFinder(start,oA {estimate:(linestr)->linestr.getDist()},ops)(end)

        getNom=(c)->
            d= c.lineStr.data
            "#{d.cat or ''}#{d.num or ''}"

        dir=[]
        if path.connects.length
            dir= [head= nom:getNom(path.connects[0]),dst:0]
            for c in path.connects
                cNom= getNom c
                cDst= c.lineStr.getDist()
                if head.nom == cNom
                    head.dst+= cDst
                else
                    dir.push head= nom:cNom,dst:cDst
        LPath._(pts:path.pts,lineOps:@lineOps,dir:dir)



meta class GraphRouter2g extends GraphRouter2
    #Router that uses a graph for resolution

    constructor: (graph,ops={})->
        super null,ops
        #log "GraphRouter2g got graphOps not used =",gops if gops
        @graph= graph
        #log "GraphRouter2g constructor init got ",{graph,self:@}
        return @


    getPath: (start,end,ops={})->
        #log "GraphRouter2g.getPath useF" if ops.useF
        if ops?.useF
             path= @graph.pathFinder(start,oA {costFn:(seg)->seg.dist},ops)(end)
        else path= @graph.pathFinder(start,oA {costFn:(seg)->if seg.data?.f then Infinity else seg.dist},ops)(end)

        getNom=(c)->
            d= c.seg.data
            "#{d.cat or ''}#{d.no_sen or ''}"

        dir=[]
        #log "Router connects=",path.connects.slice 0

        if path.connects.length
            connects= path.connects
            dir= [head= nom:getNom(path.connects[0]),dst:0]
            for c in path.connects
                cNom= getNom c
                cDst= c.seg.dist
                if head.nom == cNom
                    head.dst+= cDst
                else
                    dir.push head= nom:cNom,dst:cDst
            # adjust start and end
            if startId=(startC=connects[0]).seg._splitSegId
                # start with a segsplit (nearly always true)
                startSeg= @graph.segments[startId]
                if !startSeg
                    log "ERRRRRRRORRRRRRRRRRR no startseg #{startId}"
                    throw new Error "Missing seg #{startSeg}"

                if startC.isReversed
                     startIdx= startC.seg._splitSegEnd
                else startIdx= startC.seg._splitSegStart
                connects[0]= {isReversed:startC.isReversed, seg:startSeg, startIdx}
                #log "changed connects 0 to ",connects[0]

                if connects.length is 1
                    #start and end
                    if startC.isReversed
                         endIdx= startC.seg._splitSegStart
                    else endIdx= startC.seg._splitSegEnd

                    connects[0].endIdx= endIdx


            if (connects.length isnt 1) and  endId=(endC=aLast connects).seg._splitSegId

                endSeg= @graph.segments[endId]
                if !endSeg
                    log "ERRRRRRRORRRRRRRRRRR no end seg #{endId}"
                    throw new Error "Missing seg #{endSeg}"

                if endC.isReversed
                     endIdx= endC.seg._splitSegStart
                else endIdx= endC.seg._splitSegEnd
                connects[connects.length-1]= {isReversed:endC.isReversed, seg:endSeg, endIdx}


            #log "Router adjusted connects=",path.connects.slice 0

            connectIds= ( (if c.isReversed then -c.seg.id else c.seg.id) for c in path.connects )


        LPathSeg._(pts:path.pts,lineOps:@lineOps,dir:dir,segs:{connectIds,startIdx,endIdx})



meta class Path extends BaseClass

    distance:Infinity #Infinity means no path else in meters

    constructor: (info)->
        super info
        return @

    getLine:()->
        return @_line if @_line?
        @_line= @_getLine()

    _getLine:()->

    distance2Pt:(pt)-> #pt is in x,y format
        Infinity # TOBE defiend in implementation set LineStr and Geomertry



meta class DirectPath extends Path

    constructor: (info)->
        ret= super info
        ret.pts= [ret.start,ret.end]
        return ret

    _getLine:()-> vx.map.createPolyline ({lat:pt.y,lng:pt.x} for pt in [@start,@end]),@lineOps


    distance2Pt:(pt)-> distInfo2Pt(@start,@end,pt).dist
    getDist: -> mCalcDist @start.y,@start.x,@end.y,@end.x

    getBounds:-> @_bounds?= Bounds.aPTS [@start,@ned]

meta class LPath extends Path

    pts:[] #set at init

    _getLine:()-> vx.map.createPolyline  ({lat:pt.y,lng:pt.x} for pt in @pts),@lineOps

    distance2Pt:(pt)-> LineStr._(@pts).distance2Pt pt #this is in degres

    closestPt2:(pt)->
        lpt= LineStr._(@pts)
        {idx}= lpt.distance2PtInfo pt
        lpt.pts[idx]


    #Copied from LineStr
    _dist:   LineStr::_dist
    getDist: LineStr::getDist
    evalDist:LineStr::evalDist

    getBounds:-> ( @_bounds?= Bounds.aPTS @pts ) if @pts and @pts.length



meta class LPathSeg extends Path

    pts:[] #set at init

    _dist:null

    _getLine:()-> vx.map.createPolyline  ({lat:pt.y,lng:pt.x} for pt in @pts),@lineOps

    distance2Pt:(pt)-> LineStr._(@pts).distance2Pt pt #this is in degres

    calcDist: (start=0,end=@pts.length-1)->
            dist=0
            pts= @pts
            last= pts[start]
            for i in [(start+1) .. end ] by 1
                pt= pts[i]
                dist+= last.distanceTo pt
                last= pt
            dist

    getDist:-> @_dist?= @calcDist()

    getBounds:-> ( @_bounds?= Bounds.aPTS @pts ) if @pts and @pts.length




meta class VItin extends BaseClass

    waypoints:[]
    paths:[] #derived from way points length= waypoints.length-1 max 0
    attraitsIds:{}
    router: null #Instanceof Router
    totalDist: 0
    lineOps: {}
    graphOps: {}
    ops:{}  # set in constructor ops for router


    @_= (waypoints,router,ops)-> new @ waypoints,router,ops

    constructor: (@waypoints,@router,@ops)->
        super()
        @paths= ( @router.getPath start,end,@ops for [start,end] in aPairs @waypoints )
        #log "Create VItin paths= ",@paths
        d=0
        #log "got #{@paths.length}"
        for path in @paths
            pd= path.getDist()
            d+= pd
            #log "path d=#{pd} dt=#{d} path=",path
        @totalDist= d
        @

    @P= (waypoints,router,ops)->
        # assync loop
        paths=[]
        retP= Promise.resolve true
        for [start,end] in aPairs waypoints
            do (start,end)-> retP= retP.then  (path)->
                paths.push path
                #log "router=",router
                router.getPathP start,end,ops

        cls= @
        retP.then (path)->
            paths.push path
            paths.shift()
            # calc dist
            d=0
            #log "got #{paths.length}"
            for path in paths
                pd= path.getDist()
                d+= pd

            oA (Object.create cls::),{waypoints,router,ops,paths,totalDist:d}




    getBounds: -> Bounds.aBNDS ( bnds for path in @paths  when bnds=path.getBounds() )

    getPts: ->
        if @paths.length
            [].concat.apply [@paths[0].pts[0]],( path.pts[1..] for path in @paths )
        else []


    closestPt2:(pt)->
        lpt= LineStr._ @getPts()
        info= lpt.distInfo2Pt pt
        #log "VItin closestPt2 for #{pt} got info=",info
        lpt.pts[info.idx]


    getSegIdsOld: ->

        segs= ( path.segs for path in @paths )

        segIdLookup={}
        segIds=[]
        for segInfo in segs
            #TODO check start and end if significant
            for segId in segInfo.connectIds when (abs segId) not of segIdLookup
                segIds.push abs segId
                segIdLookup[abs segId]=1
        segIds


    getSegIds: ->

        #log "getSegIds path=",@paths

        segs= ( path.segs for path in @paths )
        #log "getSegIds segs=",segs
        segIdLookup={}
        segIds=[]
        for segInfo,idx in segs
            #TODO check start and end if significant
            #log "segInfo #{idx} =",segInfo
            continue if not segInfo.connectIds
            lastIdx= segInfo.connectIds.length-1
            if !lastIdx
                #log "single segInfo=",segInfo
                if abs(segId=segInfo.connectIds[0]) not of segIdLookup
                    segIdLookup[abs segId]= 1
                    segIds.push segId
                continue
            #log "multiple segInfo="
            for segId,idx in segInfo.connectIds when (abs segId) not of segIdLookup
                if !idx or idx is lastIdx
                    seg= @router.getSegId abs segId
                    #log "    start or end @router.getSegId abs segId=#{segId} got seg=",seg
                    l= seg.pts.length
                    # check if start or end is significant
                    if !idx #start
                        pCnt=segInfo.startIdx
                        if segId>0 then pCnt=l-pCnt
                    else #end
                        pCnt=segInfo.endIdx
                        if segId<0 then pCnt=l-pCnt
                    if l/3>pCnt
                        # to short ignore this segment
                        #log "....to short ignore"
                        continue
                segIds.push segId
                segIdLookup[abs segId]=1

        # to small patch
        if segs.length and !segIds.length and segs[0]?.connectIds?.length
            log " To small patch find first seg",{wps:@waypoints,segs,segIds,ci:segs[0]?.connectIds}
            segIds.push abs segs[0].connectIds?[0]

        #log "getSegIds got=",{segIds,segIdLookup}
        segIds


module.exports= { Router, AucunRouter, GraphRouter, GraphRouter2, GraphRouter2g, GoogleRouter, VItin } # Itineraire, ItinStateManager,

