#!/usr/bin/coffee
{ isArray, log, oA  }= vx= require 'vx/globals/Boot'

{Scale,OldIds}= require 'vx/app/OldIds'

module.exports= I1toI2:(sInfo)->

    return sInfo if !sInfo.waypoints?

    newi= []
    changed= false

    for wp,idx in sInfo.waypoints
        if typeof wp isnt 'string'
            newi.push wp
            continue

        [ a, b ,id, type ]= wp.split '.'

        changed= true
        if id not of OldIds
            log "not found #{id} id = #{a} #{b} #{id} #{type}"
            continue

        [lat,lng,nid]= OldIds[id]
        newWp= [lat/Scale,lng/Scale]
        if type is 'ccv'
            type='att'
        else if type is 'for'
            type= 'heb'
        if nid?
            newWp.push "a.#{nid}.#{type}"
        newi.push newWp


    if changed
         oA {},sInfo,waypoints:newi
    else sInfo


