{ meta, log, sList, sSet, nr, time:getTime, Global }= Mod= vx= require 'vx/globals/Boot'


# TODO: remove this completly from Ajax then retire it ...

debug= false #isDEV


module.exports=
    readFileP: (fileName,rmode='text')-> 
        
        log "readFileP for file=#{fileName} " if debug
        Promise.vxGet('deviceready').then -> new Promise (resolve,reject)->
            if !window.resolveLocalFileSystemURL
                log "readFileP no resolveLocalFileSystemURL Error (reject) file=#{fileName} " if debug
                return reject {err:'no resolveLocalFileSystemURL',fileName}
            pathToFile= Global.cordova.file.dataDirectory + fileName
            log "readFileP got devicready for file=#{fileName} is #{pathToFile}" if debug
            window.resolveLocalFileSystemURL pathToFile,
                (fileEntry)->
                    log "readFileP resolveLocalFileSystemURL success file=#{fileName} is #{pathToFile}" if debug
                    fileEntry.file(
                        (file)->
                            reader = new FileReader()
                            reader.onloadend= (e)-> 
                                log "readFileP reader.onloadend success (do resolve) file=#{fileName} is #{pathToFile}",{self:@,result:@result} if debug
                                #vx.LastRead= @result if debug
                                resolve @result
                            log "readFileP fileEntry.file success file=#{fileName} is #{pathToFile}" if debug
                            switch rmode
                                when 'text' then reader.readAsText file
                                when 'utf8' then reader.readAsText file,'UTF-8'
                                when 'utf16' then reader.readAsText file,'UTF-16'
                                when 'blob' then reader.readAsByteArray file
                        (err)->
                            log "readFileP fileEntry.file ERROR (do reject ) file=#{fileName} is #{pathToFile}",{self:@,err} if debug
                            reject {err,fileName,pathToFile}
                        )
                (err)->
                    log "readFileP resolveLocalFileSystemURL ERROR (do reject ) file=#{fileName} is #{pathToFile}",{self:@,err} if debug
                    reject {err,fileName}
    
    

    writeFileP: (fileName,data)-> 
        log "writeFileP for file=#{fileName} " if debug
        Promise.vxGet('deviceready').then -> new Promise (resolve,reject)->
            if !window.resolveLocalFileSystemURL
                log "writeFileP no resolveLocalFileSystemURL Error (reject) file=#{fileName} " if debug
                return reject {err:'no resolveLocalFileSystemURL',fileName}
            pathToFile= Global.cordova.file.dataDirectory + fileName
            log "writeFileP got devicready for file=#{fileName} is #{pathToFile}" if debug
            window.resolveLocalFileSystemURL Global.cordova.file.dataDirectory,
                (directoryEntry) ->
                    log "writeFileP resolveLocalFileSystemURL succes for file=#{fileName} is #{pathToFile}" if debug
                    directoryEntry.getFile fileName, { create: true }, 
                        (fileEntry)->
                            log "writeFileP directoryEntry.getFile succes for file=#{fileName} is #{pathToFile}" if debug
                            fileEntry.createWriter(
                                (fileWriter)->
                                    log "writeFileP fileEntry.createWriter succes for file=#{fileName} is #{pathToFile}" if debug
                                    fileWriter.onwriteend= (e)->
                                        log "writeFileP fileWriter.write succes for file=#{fileName} is #{pathToFile}" if debug
                                        resolve data
                                    fileWriter.onerror= (err)->
                                        log "writeFileP fileWriter.write Error for file=#{fileName} is #{pathToFile}",{err,self:@,fileName} if debug
                                        reject {err,fileName}
                                    blob= new Blob [data],type:'application/json'
                                    
                                    #fr= new FileReader()
                                    #fr.onloaadend= ->
                                    #    log "write blob size from read is #{@result.length}"
                                    #fr.readAsText blob
                                        
                                    #log "Before write blob size is #{blob.size} data size is #{data.length}" if debug
                                    #vx.LastWrite= data if debug
                                    log "writeFileP do fileWriter.write for file=#{fileName} is #{pathToFile}" if debug
                                    fileWriter.write blob
                                (err)->
                                    log "writeFileP fileEntry.createWriter Error for file=#{fileName} is #{pathToFile}",{err,self:@,fileName} if debug
                                    reject {err,fileName}
                                )
                        (err)->
                            log "writeFileP fileEntry.createWriter ERROR for file=#{fileName} is #{pathToFile}",{err,self:@,fileName} if debug
                            reject {err,fileName}
                (err)->
                    log "writeFileP resolveLocalFileSystemURL ERROR for file=#{fileName} is #{pathToFile}",{err,self:@,fileName} if debug
                    reject {err,fileName}
