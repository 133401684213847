

module.exports=
    nts: 'https://web-api.ntswireless.ca/'

    atcond:  'https://docs.google.com/spreadsheets/d/11pPfYOSDBApGQEd64be2MDrM6RjOymeUwy0xx4p_tZ4/export?format=csv'
    atcondtest: 'https://docs.google.com/spreadsheets/d/1ah_m9nsFBHD1u2Amfyx3C6awiRH5_8EQW4h7IJMPaEY/export?format=csv'

    bjcond:     'https://docs.google.com/spreadsheets/d/1_7EAA1PMeWjxmYZAwquNiytDSmRvoA5iDSVA3Ks5Z0k/export?format=csv'
    bjcondtest: 'https://docs.google.com/spreadsheets/d/1bey6tTgaJWr9gFbKoY4aKQZkEQ9xb8nib3IeRRVDQFY/export?format=csv'

    bslcond:    'https://docs.google.com/spreadsheets/d/1uS1DwV8nW8N2Z3njYAltGv5TKhd1t2zZnBMUaTpFfUA/export?format=csv'

    cacond:     'https://docs.google.com/spreadsheets/d/1ha-XSrgSIarIa-F2ufoMEGbpQhewhW1-NgoAwKErZ4A/export?format=csv'
    cacondtest: 'https://docs.google.com/spreadsheets/d/1of5YPW3aYt_EYan3wo502ef5klzAEcOkLMF4Q8p3BS0/export?format=csv'

    cdqcond:     'https://docs.google.com/spreadsheets/d/1G4MnVCSWHw45e3zrkredRmYBNcDdTNpTVJoSoIpCwM8/export?format=csv'
    cdqcondtest: 'https://docs.google.com/spreadsheets/d/1JwHy6nVVaTCWPjrcqKs-qrhAuHNc7HJsW2hTlwN43c8/export?format=csv'

    cecond:      'https://docs.google.com/spreadsheets/d/1qxHtW_-QuyJ2kpGwxztCKEtKc75UXqlruWZpI8aw0WY/export?format=csv'
    cecondtest:  'https://docs.google.com/spreadsheets/d/1OYsk1aVHu2NvgzU3djWzOtG7pU-588Z4s2SpBooPIeA/export?format=csv'

    cncond:      'https://docs.google.com/spreadsheets/d/14yoW6rQi045FoPOwuK8tTBkifWNEI8ENj5suc-SRIxE/export?format=csv'

    cvcond:      'https://docs.google.com/spreadsheets/d/1YhMK8rUmxNnCPy5_TsDK-68fUpBTx-E7sFmLTDr1U28/export?format=csv'
    cvcondtest:  'https://docs.google.com/spreadsheets/d/1SPUKKGv5mxhhSQMHLiP32oM_H3PJBYwzHm7yrlCTitA/export?format=csv'

    gscond:       'https://docs.google.com/spreadsheets/d/1OjQweunhfFSSZZ5D_FcCn0jN5L8kyn6i3WM94Oz3VC8/export?format=csv'

    lrcond:      'https://docs.google.com/spreadsheets/d/10JIAcCFmlQSxaYOy5jFwC6B_Ace5kCclpdBySEghAXM/export?format=csv'

    mtcond: 'https://docs.google.com/spreadsheets/d/1ZkwVzh8CTHQ6ZXN9HII94Mi8K1pfgvozOaTM-WQqWCI/export?format=csv'
    mtcondtest: 'https://docs.google.com/spreadsheets/d/12cykffH0LxjqnKP8IhFR-cnT2BjW8gfjGyWWItrmMnw/export?format=csv'

    outcond: 'https://docs.google.com/spreadsheets/d/14L7W5tLSGnuqELMvNoO-1V0sdg9BzyhXTMNz7luEfw4/export?format=csv'

    pmcond:    'https://docs.google.com/spreadsheets/d/1Rc5PrkXjz4iyeWCTTAQ10EEjiO_ZuTnSSc0tKE6O-is/export?format=csv'
    pmcontest: 'https://docs.google.com/spreadsheets/d/1LkhqiLE7KUIiKs8knGu-EmsUfndYIheWn_h1U9l0W1E/export?format=csv'

    qccond: 'https://docs.google.com/spreadsheets/d/1DjxmJjgRQagGuDSHDnrupQ3OQhfcP3lCyn_Wvd212AA/export?format=csv'

    slcond: 'https://docs.google.com/spreadsheets/d/1eJNgFyJ3sda0pw0KsNS0cTCaodkvRxEAYYNaK6P9H_I/export?format=csv'

    fcmqcond:     'https://docs.google.com/spreadsheets/d/1U8O2sHp0pWgUkAiTbnMBrETwLEgYJYbtN1lOSc1zzN8/export?format=csv'
    fcmqcondtest: 'https://docs.google.com/spreadsheets/d/1rDrJ1q5vJeD6-KvQcwfte3dGaTMcZaY3D-bc8HUNHRQ/export?format=csv'


    fcmqparcoursvrai: 'https://docs.google.com/spreadsheets/d/1t1Lc9HGjQbGeIWeaPfiE38NDkNGr1NNEZhVRTSvTVNQ/export?format=csv'
    fcmqparcourstest: 'https://docs.google.com/spreadsheets/d/1KuxAkXGP4zenBYzEk_I5AcEni1Z7mcuYPqJHtGJW7A4/export?format=csv'
    fcmqparcours:     'https://docs.google.com/spreadsheets/d/1t1Lc9HGjQbGeIWeaPfiE38NDkNGr1NNEZhVRTSvTVNQ/export?format=csv'


    fcmqmesstest2:    'https://docs.google.com/spreadsheets/d/1YnJqU_JLrqkqFyRP00-igR3KMRMrRC049HVf3Tq5iNY/export?format=csv'
    fcmqmesstest:     'https://docs.google.com/spreadsheets/d/1t3fhsPEPlfSKLF8MNthWA-S6UhlcmHYNvO2nl6fVLZw/export?format=csv'
    fcmqmessbon:      'https://docs.google.com/spreadsheets/d/1rE385NvcO2TS2C-80extkWb2RrOx2MDccW61CiQPrOU/export?format=csv'
    fcmqmess:         'https://docs.google.com/spreadsheets/d/1rE385NvcO2TS2C-80extkWb2RrOx2MDccW61CiQPrOU/export?format=csv'

    fcmqattraitsvrai: 'https://docs.google.com/spreadsheets/d/1oZOl0hZBzzz-LBzefukhkb4822neMO6SmeMkLvS_ljg/export?format=csv'
    fcmqattraitstest: 'https://docs.google.com/spreadsheets/d/1mIBHWpK1YShU7L8c_6AJr6KTnSHtB3sJJ3gfH5OPo9E/export?format=csv'
    fcmqattraits:     'https://docs.google.com/spreadsheets/d/1oZOl0hZBzzz-LBzefukhkb4822neMO6SmeMkLvS_ljg/export?format=csv' #TODO add test button to test



# AT


# CA


