{ log }= vx= require 'vx/globals/Boot'  # to get a global ref to HelloWorld that survies webpack

{ createElement, Fragment }= require 'react'

{ mapCouchesPanelCustom, mapAttraitsPanel, mapInfoPanel }=     require './pages/MapPage'

{ MenuPanel }=      require './panels/MenuPanel'

{ CircuitsListPanel, CircuitsFiltrePanel }= require './panels/CircuitsPanels'

{ messageDialog } = require 'vx/comp/MessageUI' if vx.use.message

#{ snackbar }= require 'vx/UI'
{ mainSnackbar }= require 'vx/UI'

# I18n
{ sayModule, getLang }= require 'vx/i18n/I18n'
say= sayModule 'App' # cheat use app for index and appIndex ModuleName


# iconito client in http patch 

C={}

AppPannels= ({ app,
    menuVisible,message,admin,lang,AdminPage,
    circuits,circuitsDecorations,circuitsListVisible,circuitsFilter,circuitsFiltreVisible,allListCircuits,
    messageVisible,termsOk,
    vxScreenWidth, graph })->

    createElement Fragment,null,

        if vx.mapMode.menu
            MenuPanel
                key:'MenuPanel'
                app:         app
                menuVisible: menuVisible
                message:     message
                lang:        lang
                admin:       admin
                adminPage:   AdminPage


        ...((if vx.isAPI and vx.use.circuits and vx.mapMode.paneauListCircuits
            [
                CircuitsListPanel
                    key:'CircuitsListPanel'
                    app:app
                    circuits:            circuits
                    circuitsDecorations: circuitsDecorations
                    circuitsListVisible: circuitsListVisible
                    vxScreenWidth:       vxScreenWidth
                    allListCircuits:     allListCircuits
                    circuitsFilter:      circuitsFilter
                    
                CircuitsFiltrePanel
                    key:'CircuitsFiltrePanel'
                    app:app
                    circuits:              circuits
                    circuitsFiltreVisible: circuitsFiltreVisible
                    circuitsFilter:        circuitsFilter
            ]) or [])


        messageDialog         { key:'messD',  app, message, visible:messageVisible, termsOk } if graph and vx.use.message and !vx.uiMode.admin
        mapCouchesPanelCustom { key:'mapCPC', app }  if vx.mapMode.paneauCouches
        mapAttraitsPanel      { key:'mapAP',  app }  if vx.mapMode.paneauAttraits
        mapInfoPanel          { key:'mapIP',  app }  if vx.mapMode.infoMode is 'paneau'
        
        mainSnackbar key:'appSnack', #ref:(snackbar)-> #not require I think
            #vx.snackbar= snackbar
            #app.snackbar= snackbar
            

module.exports= { AppPannels, appPannels:AppPannels }
