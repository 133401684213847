{ meta, log, oCreate, oAssign, aLast }= vx= require 'vx/globals/Boot'


window?.addEventListener 'message',(e)->
    return if  (e.source isnt window.parent) or !e.data.verb
    window.dispatchEvent (new CustomEvent "top_message_#{e.data.verb.toLowerCase()}",detail:e.data)

#window.addEventListener 'top_message_sethash',(e)->
#    log " window got as sethash hash=#{e.detail?.hash}",e

module.exports=
    topEval: (cmd)-> window?.parent.postMessage({verb:'eval',cmd:cmd},'*')
    sendHash: ->     window?.parent.postMessage({verb:'sendHash'},'*')
