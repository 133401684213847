{ log }= vx= require 'vx/globals/Boot'

{ round, min, max, abs }= Math



step= 60*60*1000 #hours
maxSteps= 4*24 # 4 days

asAge= (maxTime,timea,timeb=timea)->
    if abs(timea-timeb)> 6*step
        timeb= max timea,timeb
        timea= timeb-2*step
    age= (maxTime-((timea+timeb)/2)) // step
    #log "SurfColorV1 asAge maxTime=#{maxTime} timea=#{timea} timeb=#{timeb} age=#{age}"
    age





asColor= (maxTime,timea,timeb)-> colors[ min maxSteps,asAge maxTime,timea,timeb ]

white= r:255,g:255,b:255
red=    r:255,g:0,  b:0

blanc=  white
noir=  r:0,g:0,b:0

h00= r:141,g: 66,b:198
h12= r:112,g: 48,b:160
h24= r: 46,g:117,b:182
h36= r:  0,g:176,b:240
h72= r:172,g:185,b:202
NoneColorRGB= r:127,g:127,b:127



makeGrad= (a,b,pcnt)->
    c={}
    for k,v of a
        c[k]= v+(b[k]-v)*pcnt
    c

asColorStr= (c)->
    asHex=(x)->"0#{max(0,min(255,round(x))).toString(16)}".substr -2
    '#'+( asHex c[k] for k in 'rgb').join('')


NoneColor= asColorStr NoneColorRGB

OldColor= asColorStr h72

FermerColor= asColorStr red

oneWeek= 7*24


asColorAgeV2= (age)->
    color=asColorStr switch
        when 0<= age <= 12 then makeGrad h00,h12, age/12
        when 12< age <= 24 then makeGrad h12,h24, (age-12)/12
        when 24< age <= 36 then makeGrad h24,h36, (age-24)/12
        when 36< age <= 72 then makeGrad h36,h72, (age-36)/36
        else h72
    #log "asColor Age #{age} = #{color}"
    color

asColorAge= asColorAgeV2
getAge= asAge

colors= new Array maxSteps+1
initColors= ->
    for age in [0 .. maxSteps]
        colors[age]= asColorAgeV2 age
initColors()


module.exports= { asColorAge, asColor, getAge, NoneColor, FermerColor, OldColor }
