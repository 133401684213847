{ log, oAssign, Global, isNODE }= vx= require 'vx/globals/Boot'


#added Named/Global promises

document= Global.document or {} # if in node won explode

if !isNODE
    # currentScript path for IE
    if 'currentScript' not of document
        document.currentScript= document.vxLastScript= {}
#log "Loading named promises"
oAssign Promise,

    vxEmpty: empty= (name)->
        resolveMe= null
        rejectMe= null
        p= new Promise (resolve,reject)->
            resolveMe= (val)->
                p.vxState='resolved'
                p.vxVal= val
                #log ">>>> resolving #{name} to a #{typeof val}" #,val
                resolve val
            rejectMe=  (error)->
                p.vxState='error'
                p.vxError= error
                log ">>>> rejecting #{name} to ",error
                reject error
        p.vxName= name
        p.vxResolve= resolveMe
        p.vxReject=  rejectMe
        p.vxState= 'pending'
        p.vxVal= undefined
        p.vxError= undefined
        p.catch (err)-> log "ERROR Got error on named promise #{name}",{err,promise:@}
        p


    vxNamedPromises: namedPromises= {}

    vxGet: get=  (name)-> namedPromises[name] ?= empty name

    vxGetAll: getAll= (all,name='')->
        if typeof all is 'string'
            all= all.split /\s+/
        p= Promise.all (get pname for pname in all)
        #log "Creating aggreagte promise #{name} from #{all}"
        if name
            ( throw new Error "Name already used" ) if name of namedPromises
            namedPromises[name]= p
        #log "aggreagte is ",p
        p


    vxResolveEvent: resolveEvent= (event)->
        #log "*** resolveEvent #{event.type}",{self:this,event}
        get( event.type ).vxResolve true
        null



    vxState: (target,promiseName,stateName=promiseName)->
        promise= get promiseName
        if promise.vxState is 'pending'
            #log " >>>>> Promise.vxState  #{promiseName} is pending"
            promise.then (val)->
                #log " >>>>> Promise.vxState setting #{stateName} to #{val} ",{target,val,stateName,promiseName}
                target.setState "#{stateName}":val
        promise.vxVal



if window?.addEventListener?

    window.addEventListener 'DOMContentLoaded',resolveEvent
    window.addEventListener 'load',resolveEvent

    get('load').then ->
        document.addEventListener 'deviceready',resolveEvent

module.exports= { Promise }
