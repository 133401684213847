
React= require 'react'
createElement= React.createElement


#RES= Symbol.for 'react.element'
Oproto= Object.prototype

isArray= Array.isArray

log= console.log.bind console

# Determine if props are actually props
# to be 'props' it must a be a simple object {} and NOT a React element
isProps= (props)-> props and (typeof props is 'object') and  ('$$typeof' not of props) and not isArray props



MergeInfo=
    #TODO test for undfined and nulls and []
    style:(a,b)->{a...,b...}
    #sx:(a,b)->{a...,b...} #used by material-UI
    className: (a,b)-> "#{a} #{b}"
    children:  (a,b)->
        ret= [].concat a,b
        log "merged children",{a,b,ret}
        ret
    
mergeProps= (a,b,childs,info=MergeInfo)->
    if !b
        return a if !childs or !childs.length
        return {a...,children: [].concat a.children,childs}
    if a.__proto__ isnt Oproto
        log "mergeProps a??????",a
    if b.__proto__ isnt Oproto
        out={a...}
        (out[k]=v for k,v of b when v?) # has a proto so enum ...
        log "mergeProps b??????",{a,b,out}
    else
        out= {a...,b...}
    for k,fn of info when (k of a) and (k of b)
        v= fn a[k],b[k],out,a,b
        out[k]= v if v?
    #log "merged props",{a,b,out}
    if childs and childs.length
        if out.children
            out.children= [].concat out.children,childs
        else
            if childs.length is 1
                 out.children= childs[0]
            else out.children= childs
    out    
    
# S is for string, no strings here but kept to be distinguish with the real react.createElement ... 
# see ReactElemSS for Styled String support ...
createElementS= (elem,props,...childs)-> # (elem,[{props}],*childs)->
        if isProps props
             createElement elem,props,...childs
        else createElement elem,null,props,...childs


ElementFactory= (elem,defProps,ename)->

        if !elem
            log "ReactElem.ElementFactory elem is falsy",{elem,defProps,ename}
        
        # factory inheritance
        if factoryInfo= elem.isElementFactory
            defProps= mergeProps factoryInfo.props,defProps if factoryInfo.props
            elem= factoryInfo.elem

        if !defProps
            factory= createElementS.bind null,elem
        else
            factory=
                (props,...childs)-> # (elem,[str-props],[{props}],*childs)->
                    if isProps props
                        props= mergeProps defProps,props,childs
                        createElement elem,props
                    else #push back props
                        if defProps.children
                            #ugly
                             createElement elem,{...defProps,children:undefined},defProps.children,props,...childs
                        else createElement elem,defProps,props,...childs
        factory.isElementFactory= { elem , props:defProps }
        factory.displayName= ename if ename
        factory 


makeElementFactories= ({
        elementFactory=ElementFactory
        canBeComponent= (comp)-> typeof comp is 'function' # of  typeof comp is 'string' 
        isCap=   (name)-> name and 'A'<= name[0] <= 'Z'
        isLower= (name)-> name and 'a'<=name[0]<='z'
        unCap= (name)-> name and name[0].toLowerCase()+name[1..]
        cap=   (name)-> name and name[0].toUpperCase()+name[1..]
        }={})->


    ElementFactories= (comps={},def,ename)->
    
            handler=
                get: (target,prop,reciever)->
                    if (prop not of target) and ((uProp=cap prop) of target)
                        elementFactory target[uProp],def,prop
                    else if prop is 'efDefault' and 'default' of target
                        elementFactory target.default,def,'efDefault'
                    else target[prop]

            if isProps comps #TODO: or isModule... works for now
                new Proxy comps,handler
            else
                #log "ReactElem.ElementFactories not object got #{typeof comps}:#{Object.keys comps}"
                elementFactory comps,def,ename


ElementFactories= makeElementFactories()

getDefault=(m)-> m.default
getEFDefault= (m)-> ElementFactory getDefault m

module.exports= { ElementFactories, EF:ElementFactories, ElementFactory, makeElementFactories, createElementS, isProps, mergeProps, createElement, getDefault, getEFDefault }
                
