{ log, oAssign, oA }= Mod= vx= require 'vx/globals/Boot'

{ latMInv }= require 'vx/math/MapGeometry'
{ Bounds }= require 'vx/math/Bounds'

LineStyles=
    LineColors:
        '.cat-T': 'rgba(   0,  77, 168, 1.0 )' # 'rgba(   0,  96, 169, 1.0 )'
        '.cat-R': 'rgba(  112, 173,  71, 1.0 )' # 'rgba(   0, 147, 112, 1.0 )'
        '.cat-L': 'rgba( 255, 153,   0, 1.0 )' # 'rgba( 244, 102,  27, 1.0 )'
        '.cat-N': 'rgba( 255, 153,   0, 1.0 )' #'rgba( 244, 102,  27, 1.0 )' # Non Subvetionner
        '.cat-P': 'rgba( 255, 242,   0, 1.0 )' # strok-width: 0px; stroke-dasharray: 6 4 } /* Privée */
        '.cat-V': 'rgba( 255,   0, 255, 1.0 )' # strok-width: 0px; stroke-dasharray: 10 4 6 4  } /* Traversiers */
        '.cat-a': 'rgba(   0,   0,   0, 1.0 )' # /* Autres */

        '.eco':   'rgba(   0, 255,   0, 1.0 )'

        '.pmnfermer': 'rgba( 255,   0,   0, 1.0 )'

    LineDashes:
        '.cat-N': [ 6, 4 ] # /* Non Subvetionner */
        #'.cat-P': [ 2, 6 ] # /* Privée */
        '.cat-V': [ 10, 4, 6, 4, ] # /* Traversiers */

    LineWidths:
        '.za.cat-T': 1
        '.za.cat-V': 1

        '.za.cat-T.eco': 2.5
        '.za.cat-V.eco': 2.5

        '.za.cat-T.pmnfermer': 1.5
        '.za.cat-V.pmnfermer': 1.5


        '.zb.cat-T': 1.5
        '.zb.cat-R': 1
        '.zb.cat-V': 1

        '.zb.cat-T.eco ': 3
        '.zb.cat-V.eco': 2.5
        '.zb.cat-R.eco': 2.5

        '.zb.cat-T.pmnfermer': 2
        '.zb.cat-V.pmnfermer': 1.5
        '.zb.cat-R.pmnfermer': 1.5


        '.zc.cat-T': 2   #/ *  was 1.5 * /
        '.zc.cat-R': 1.5 #/ * was 1 * /
        '.zc.cat-L': 1
        '.zc.cat-N': 1
        '.zc.cat-P': 1
        '.zc.cat-V': 2

        '.zc.eco': 3.5

        '.zc.pmnfermer': 2.5


        '.zd.cat-T': 3
        '.zd.cat-R': 2.5
        '.zd.cat-L': 2
        '.zd.cat-N': 2
        '.zd.cat-P': 2
        '.zd.cat-V': 2

        '.zd.cat-T.eco': 4.5
        '.zd.eco': 4

        '.zd.cat-T.pmnfermer': 3.5
        '.zd.pmnfermer': 3


        '.ze.cat-T': 4
        '.ze.cat-R': 3
        '.ze.cat-L': 2.5
        '.ze.cat-N': 2.5
        '.ze.cat-P': 2.5
        '.ze.cat-V': 2.5

        '.ze.cat-T.eco': 6
        '.ze.eco': 5

        '.ze.cat-T.pmnfermer': 5
        '.ze.pmnfermer': 4




Zoom2ZoomCls=(zoom)->
        drawCats='VNLRT'   #'aVPNLRT'
        switch zoom
            when 0,1,2,3
                zoomCls= 'za'
                drawCats= 'VT'
            when 4,5
                zoomCls= 'zb'
                drawCats= 'VRT'
            when 6
                zoomCls= 'zc'
                #drawCats= 'aPNLVRT'
            when 7,8
                zoomCls= 'zd'
                #drawCats= 'aPNLVRT'
            else
                zoomCls= 'ze'

        {drawCats,zoomCls}



doRender2Ctx= (ctx,{swne:[s,w,n,e],x,y,zoom,tileSize,content,ops,req,info}=@props,clear)->

        { LineColors, LineDashes, LineWidths }= ops?.lineStyles ? LineStyles


        if clear
            ctx.setTransform(1, 0, 0, 1, 0, 0)
            ctx.clearRect 0,0,tileSize,tileSize

        pW= 360/(tileSize*(2**zoom))

        oAssign ctx,
            strokeStyle:'#FF0000'
            fillStyle: '#0000FF'
            lineWidth: 1
            lineCap:  'round'
            lineJoin: 'round'

        #ctx.fillText "z:#{zoom} s:#{s},w:#{w},n:#{n},e:#{e}",64,128
        #ctx.fillText "pw:#{pW} scale:#{1/pW}",64,148

        ctx.scale (1/pW),(-1/pW)
        ctx.translate -w,-n
        ctx.lineWidth= 2*pW

        #log "doRender2Ctx props=",{@props}
        #eco= !!info?.eco
        if req
            eco= 'eco' of req.query if req
        else if info
            eco= info.eco
        #log "eco hit" if eco

        cats={
            T:[]
            R:[]
            L:[]
            N:[]
            P:[]
            V:[]
            a:[]
            }
        for item in content
            if cat= cats[item.data?.cat ]
                cat.push item
            else cats.a.push item


        {drawCats,zoomCls}= Zoom2ZoomCls zoom


        minpx= 6 * pW
        minpxX2= minpx * 2

        minpxdst= 4 * pW
        minpxdstX2= minpxdst * 2

        #log "CanvasPMN.render2ctx doing draw pW=#{pW} zoom=#{zoom} content=#{content?.length}"
        lineCnt=0
        for dcat in drawCats
            #log "drawing #{dcat} ##{cats[dcat].length}"
            widthLookup= ".#{zoomCls}.cat-#{dcat}"
            width=       pW * ( LineWidths[widthLookup] or 0 )
            widthFermer= pW * ( LineWidths["#{widthLookup}.pmnfermer"] or LineWidths[".#{zoomCls}.pmnfermer"] or 0 )
            widthEco=    pW * ( LineWidths["#{widthLookup}.eco"]       or LineWidths[".#{zoomCls}.eco"] or 0 )

            color=       LineColors[".cat-#{dcat}"]
            colorFermer= LineColors['.pmnfermer']
            colorEco=    LineColors['.eco']

            #dash=        LineDashes[".cat-#{dcat}"]

            ctx.beginPath()
            ctx.strokeStyle= color
            ctx.lineWidth=   width

            #if dash
            #     ctx.setLineDash ( x*pW for x in dash)
            #else ctx.setLineDash []


            for item in cats[dcat]
                #if item is @info
                #    log " segfinder item hit",item
                {f,e}= item.data
                e= eco and e
                if f or e
                    ctx.stroke()
                    ctx.beginPath()
                    if f
                        ctx.strokeStyle= colorFermer
                        ctx.lineWidth=   widthFermer
                    else
                        ctx.strokeStyle= colorEco
                        ctx.lineWidth=   widthEco
                        
                s=0
                switch
                    when zoom<9
                        pts= item.latMLine
                    when zoom<13 and item.latMDiagonal<= minpxX2
                        # we have a small segment
                        pts= item.latMLine
                    when zoom<13
                        pts= item.latMPtsRes minpxdstX2
                    when zoom<16 and item.latMDiagonal<=minpx
                        # we have a small segment
                        pts= item.latMLine
                    when zoom<16
                         pts= item.latMPtsRes minpxdst
                    else
                         pts= item.pts

                #log "Drawing line width= #{ctx.lineWidth} color=#{ctx.strokeStyle} cat=#{dcat} pts=#{pts.length}",pts
                if !( pts?.length)
                    log "oups zoom=#{zoom}",{pts,item}
                p0= pts[0]
                ctx.moveTo p0.x,p0.ym
                if pts.length is 2
                    p1= pts[1]
                    ctx.lineTo p1.x,p1.ym
                    lineCnt++
                else
                    for i in [1 ... pts.length] by 1
                        pi= pts[i]
                        ctx.lineTo pi.x,pi.ym
                        lineCnt++

                if f or e
                    ctx.stroke()
                    ctx.beginPath()
                    ctx.strokeStyle= color
                    ctx.lineWidth=   width

            ctx.stroke()

        if false and info.nodes
            ctx.fillStyle= '#ff0000'
            rw= 4*pW
            pi2= Math.PI*2
            bnds= Bounds._ (w-rw),(latMInv s-rw),(e+rw),(latMInv n+rw)

            for node in info.nodes.getIntersects bnds
                {x,ym}= node.pt
                ctx.fillStyle= '#ff0000'
                ctx.beginPath()
                ctx.moveTo x,ym
                ctx.arc x,ym,rw, 50, 0, pi2
                ctx.fill()
                #ctx.fillStyle= '#000000'
                #log "mtext = #{m=ctx.measureText node.id}",m
                #ctx.fillText "node.id",x,ym
                #ctx.fill()
        #ctx.fillText "pw:#{pW} scale:#{1/pW}",64,148
        lineCnt

LineStylesGrey= oA {},LineStyles
LineStylesGrey.LineColors=
        '.cat-T': 'rgba( 128, 128, 128, 1.0 )'
        '.cat-R': 'rgba( 128, 128, 128, 1.0 )'
        '.cat-L': 'rgba( 128, 128, 128, 1.0 )'
        '.cat-N': 'rgba( 128, 128, 128, 1.0 )' # Non Subvetionner
        '.cat-P': 'rgba( 128, 128, 128, 1.0 )' # strok-width: 0px; stroke-dasharray: 6 4 } /* Privée */
        '.cat-V': 'rgba( 128, 128, 128, 1.0 )' # strok-width: 0px; stroke-dasharray: 10 4 6 4  } /* Traversiers */
        '.cat-a': 'rgba( 128, 128, 128, 1.0 )' # /* Autres */

        '.eco':   'rgba(   0, 255,   0, 1.0 )'

        '.pmnfermer': 'rgba( 255,   0,   0, 0 )'


module.exports= { doRender2Ctx, Zoom2ZoomCls, LineStyles, LineStylesGrey }
