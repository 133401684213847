{ meta, log, oAssign, oPop, oClone, oSet, oDiffs, oShallow, aPairs, oA, aLast, sReplace, isPrefix, $ }= vx= require 'vx/globals/Boot'


{ AucunRouter, GoogleRouter, GraphRouter2, GraphRouter2g }= require './Router'

{ BaseClass }= require 'vx/tools/BaseClass'

{ readVxlJson, readAJson, LineStr, linesFromGraph5, linesFromGraph5Async }= require 'vx/math/LineStr'
deConvert=                 require 'vx/comp/itin/Deconvert'
{ distInfo2Pt }=           require 'vx/math/Geometry'
{ mCalcDist }= require 'vx/math/MapGeometry'
{ Bounds }= require 'vx/math/Bounds'

{ max, min, abs }= Math

#UI= require 'vx/UI'
# 18n
{ say, getLang }= require 'vx/i18n/I18n'
Lang= getLang()

{ o1 }= oShallow()

meta class ItinSpec extends BaseClass

    # Methods, attrubuts used by ItinStateManager

    attraitsIds: {} # id:Attrait
    waypoints: [] # of Pts
    paths:  [] # of LineStr or Lseg ?

    totalDist: 0 # from ItinWidgets


    getDist: ->

    # Found only in ItinWidgets

    getPath: (wp)->

    getDstInfoForPos: (wp)->


    # Modifiers


    @_= ({routerType,routerData,lineOps,initData})->

    addWaypoint: (wp,idx)-> # new Itin

    addWaypoints: (wpArr=[])-> # new Itin

    #removeWaypoints:(rWaypoints=[])-> # new Itin
    reset:-> # new Itin

    removeWaypoint: (idx)-> # new Itin

    updateWaypoint: (pos,alatlng)-> # new Itin



ItinCnt=0

meta class ItineraireP extends BaseClass

    id:0
    waypoints:[]
    paths:[] #derived from way points length= waypoints.length-1 max 0
    attraitsIds:{}
    router: null # Instanceof Router
    #map: null    # contrary to router Itineraire knows about its map and adds and removes layers as required
    totalDist: 0
    lineOps: {}
    graphOps: {}
    initData: null

    constructor:(info)->
        super info
        @id= -1*(++ItinCnt)
        @initRouter()
        @waypoints= []
        @paths= []
        @attraitsIds={}



    @P= (info)->
        itin=new @ info
        itin.initStartItinP()



    fnRefresh: ->
        #log "fnRefresh"
        # Functional refresh for react
        #CAREFULL we manage attraitsIds cause  this will redraw everty thing on a change
        oClone @,
            id: ++ItinCnt
            waypoints: @waypoints[..]
            paths:     @paths[..]
            attraitsIds: oAssign {},@attraitsIds



    initStartItinP:->
        if @initData
            { wps=[], ids=[] }= @initData
            @_setWaypointsP ( {lat,lng,objid: ids[i] or undefined } for [lat,lng],i in wps )
            .then (ret)->
                ret.initData= {}
                ret
        else Promise.resolve @



    _setWaypointsP: (waypoints)->

        return Promise.resolve @ if !waypoints or !waypoints.length

        p= Promise.resolve true
        self= @
        for wp in waypoints
            do (wp)->
                p= p.then -> self._addWaypointP wp,-1 # at end

        p.then ->
            self.refresh 0
            self


    initRouter:->
        if !@routerType
            throw 'No router'

        data=@routerData
        @routerData= null #free data aftercreation cause its big ...
        @router=
            switch @routerType
                when 'aucun'      then AucunRouter._   null,             {@lineOps,@graphOps}
                when 'googleAuto' then GoogleRouter._  null,             {@lineOps,graphOps: oA {},@graphOps,{travelMode:'DRIVING'}}
                when 'graph'      then GraphRouter2._  readVxlJson(data),{@lineOps,@graphOps}
                when 'graphb'      then GraphRouter2._ readAJson(data),  {@lineOps,@graphOps}
                when 'graphc'
                    s=new Date
                    @lines= deConvert data
                    #log "got lines=",@lines
                    #$('#mapItinBtn')?.show()
                    GraphRouter2._ @lines, {@lineOps,@graphOps}
                when 'graphd'
                    s=new Date
                    @lines= ( line for line in linesFromGraph5 data when !line.data.f )
                    #log "graphd got #{@lines.length} lines"
                    #$('#mapItinBtn')?.show()
                    GraphRouter2._ @lines, {@lineOps,@graphOps}
                when 'graphe'
                    s=new Date
                    #log "graphd got #{@lines.length} lines"
                    #$('#mapItinBtn')?.show()
                    @lines= (line for line in @lines when !line.data.f )
                    GraphRouter2._ @lines, {@lineOps,@graphOps}
                when 'graphg'
                    s=new Date
                    #log "graphd got #{@lines.length} lines"
                    #$('#mapItinBtn')?.show()
                    GraphRouter2g._ data, {@lineOps,@graphOps}
                else false
        #log "Loaded router=",@router if vx.isDEV

        @isSlow= true if @router.isSlow

        @router



    wpIdCnt= 0 #counter used for unique waypoint ids
    lastMode= null

    _addWaypointP: (waypoint,pos=-1)->
        # is waypoint a 'point' or an object
        wp= oAssign {uid:"wp-#{(++wpIdCnt).toString 36}"},waypoint

        #log "Itineraire._addWaypoint got ",{waypoint,pos,wp}

        if  wp.objid
            newAIds= oSet @attraitsIds,wp.objid,wp
            @attraitsIds= newAIds if newAIds

        #log "Itineraire.addWaypoint idx=#{pos}"
         #inputs are always imputable (as much as possible)
        #pos = idx of place to add way point, waypoist (and following at that position are shifted forward
        #pos <0 form end -1 = atend = waypoints.length
        orgpos= pos
        ipos= pos
        imode= mode
        mode= switch pos
            when  0 then 'start'
            when -1 then 'end'
            when '?'
                switch
                    when @waypoints.length==0
                        pos=0
                        'start'
                    when @waypoints.length==1 and lastMode is 'end'
                        pos= 0
                        'start'
                    when @waypoints.length==1
                        pos= -1
                        'end'
                    else
                        pos= '?'
                        '?'
            else
                if pos<@waypoints.length
                    '?' # pos no change
                else
                    pos= -1 # end
                    'end'

        #log "Mode management ipos=#{ipos} pos=#{pos} imode=#{imode} mode=#{mode} lastMode=#{lastMode} len=#{@waypoints.length}"
        lastMode= mode



        if pos is '?' then pos= @findInsertFor wp

        if pos < 0   then pos= pos+1+@waypoints.length

#         if not wp.obj #no object find it or create it
#             if wp.id and isPrefix wp.id,'attrait.'
#
#                 wp.obj= vx.Attraits.getObjById wp.id
#                 #ok we got na object
#                 if !wp.lat or !wp.lng
#                     wp.lat= wp.obj.latitude
#                     wp.lng= wp.obj.longitude
#                 #TODO Force marker on map


        wpCnt= @waypoints.length

        wp.pos= pos
        #wp.itin= @ #mostly for display fonctions that need distance info

        switch
            when pos < 0 or pos > wpCnt
                throw "Index #{pos} out of bounds"
            when pos is 0 and wpCnt is 0
                #Speciacl case first waypoint ....
                #log "Itineraire._addWaypoint first waypoint"
                @waypoints.push wp
                Promise.resolve pos
            when pos is 0 #add tobegging
                #log "Itineraire._addWaypoint insert start unshift"
                @waypoints.unshift wp
                if @waypoints.length>1
                    @paths.unshift null
                    @_addPathP 0
            when pos is wpCnt #add to end
                @waypoints.push wp
                @paths.push null
                @_addPathP pos-1
            else # 0 < pos < wpCnt this is an insert
                @removePath pos-1
                @waypoints.splice pos,0,wp
                @paths.splice   pos,0,null
                @_addPathP pos-1
                .then => @_addPathP pos


#         if elem= @getWPContent wp
#             elem.addClass "vx-wp #{addcls}"
#             elem.attr 'data-wpidx',pos




#     addPath: (pos)->
#         #log "addPath #{pos}"
#         #add a path on the map between waypoint pos and pos+1
#         w1= @waypoints[pos]
#         w2= @waypoints[pos+1]
#         @paths[pos]= path= @router.getPath {x:w1.lng,y:w1.lat},{x:w2.lng,y:w2.lat}
#         path.uid= "p-#{w1.uid}-#{w2.uid}"
#         path


    _addPathP: (pos)->
        #log "addPath #{pos}"
        #add a path on the map between waypoint pos and pos+1
        w1= @waypoints[pos]
        w2= @waypoints[pos+1]
        @router.getPathP {x:w1.lng,y:w1.lat},{x:w2.lng,y:w2.lat}
        .then (path)=>
            @paths[pos]= path
            path.uid= "p-#{w1.uid}-#{w2.uid}"
            pos



    addWaypointP: (waypoint,pos=-1)->
        #log "ItinéraireP addWaypointP got ",{waypoint,pos,argLen:arguments.length}
        ret= @fnRefresh() # clone self
        ret._addWaypointP waypoint,pos
        .then (pos)->
            ret.refresh pos-1
            ret



    addWaypointsP: (waypoints,pos=-1)->

        return Promise.resolve @ if !waypoints or !waypoints.length
        ret= @fnRefresh() # clone self
        ret._setWaypointsP waypoints



    updateWaypointP: (pos,alatlng,nochange=false)-> #nochange avoids change vents during a drag ....
    
        opos= pos
        
        if typeof pos isnt 'number'
            pos= @waypoints.indexOf pos

        return Promise.resolve @ if pos <0 or pos>@waypoints.length-1

        wp= @waypoints[pos]
        wp.lat= alatlng[0]
        wp.lng= alatlng[1]

        ret= @fnRefresh()


        (if pos>0
            ret.removePath pos-1
            ret._addPathP pos-1
        else Promise.resolve true
        )
        .then ->
            if pos<ret.waypoints.length-1
                ret.removePath pos
                ret._addPathP pos
        .then ->
            ret.refresh pos-1
            ret



    _removeWaypointP: (pos)->
        if typeof pos isnt 'number'
            pos= @waypoints.indexOf pos

        return Promise.resolve null if pos <0 or pos>=@waypoints.length

        # #inital point mode patch see end of addWaypoint
        lastMode= null

        oldWp= @waypoints[pos]

        #wp.itin= null #just remove a ciculare ref that we know of

        if oldWp.objid
            newAIds= oSet @attraitsIds,oldWp.objid,undefined
            @attraitsIds= newAIds if newAIds

        @waypoints.splice pos,1

        switch
            when pos is 0
                if @paths.length
                    @removePath 0
                    @paths.shift()
            when pos is @waypoints.length # -1 done by the previuos splice
                @removePath pos-1
                @paths.pop()
            else #in between
                @removePath pos
                @removePath pos-1
                @paths.splice pos,1
                return (
                    @_addPathP pos-1
                    .then -> pos
                    )
        Promise.resolve pos


    removeWaypointP: (pos)->

        ret= @fnRefresh()

        ret._removeWaypointP pos
        .then (rpos)->
            if rpos?
                ret.refresh rpos-1
            ret


    _reset: ->
        @totalDist= 0
        @waypoints= []
        @paths= [] #derived from way points length= waypoints.length-1 max 0
        @attraitsIds= {}


    reset:->
        ret= @fnRefresh()
        ret._reset()
        ret



    fnRefresh: ->
        #log "fnRefresh"
        # Functional refresh for react
        #CAREFULL we manage attraitsIds cause  this will redraw everty thing on a change
        oClone @,
            id: ++ItinCnt
            waypoints: @waypoints[..]
            paths:     @paths[..]
            attraitsIds: oAssign {},@attraitsIds



    removePath:(pos)->
        #log "removePath #{pos}"
        @paths[pos]= null


    refresh:(startPos)-> # Actually more of a refresh recalc distances to
        #log "refresh #{startPos}"
        startPos= max 0,startPos
        #log "refresh #{startPos}"
        if startPath=@paths[startPos-1]
             cumul= (startPath.itin_cumul or 0)+startPath.getDist()
        else cumul= 0


        top= max 0,@waypoints.length-1
        startPos= min (max 0,startPos ),top


        objIdChange= false

        for pos in [startPos..top] by 1
            wp= @waypoints[pos]
            continue if !wp

            if wp.objid and !objIdChange
                objIdChange= true



            wp.pos= pos
            wp.isEnd= pos == top
            if pos isnt top
                path= @paths[pos]
                path.itin_cumul= cumul
                path.itin_startWP= wp
                path.itin_endWP= @waypoints[pos+1] # we know pos<top
                cumul+= path.getDist()

        @totalDist= cumul



#     getWPContent:(wp)->
#         marker= vx.Attraits.getMarkerById wp.id
#         content= marker.getDiv() if marker
#         if not content
#             # may be not on map
#             if wp.obj?._marker
#                 #we are lucky
#                 wp.obj._marker.setMap @map
#                 #let try again
#                 content= wp.obj._marker.getDiv()
#
#         $ content if content


    findInsertFor:(wp)->
        pt= x:wp.lng, y:wp.lat
        #log "ItineraireP findInsertFor ",{pt,@paths}
        res1= for path,idx in @paths
            #log "  path=",{path,idx}
            [idx+1,path.distance2Pt pt]
        res= res1.sort (a,b)->a[1]-b[1] #sort by distance to point
        if res.length
             res[0][0]
        else 0

    _dist: null

    getDist:->@totalDist#if @_dist isnt null then @_dist else @evalDist()

#     evalDist:->
#         dst= 0
#         for path in @paths then dst+= path.getDist()
#         @_dist= dst


    getNom: (pos,obj=true)->

        wp= @waypoints[ pos ]
        (obj and wp.obj?.title) or switch pos
            when 0 then say 'itin titre depart'
            when @paths.length then say 'itin titre arrivee'
            else "#{say 'itin titre etape'} #{pos}"


    getDstInfoForPos: (pos)->

        isEnd= pos >= @paths.length
        done=0
        done+= p.getDist() for p in @paths[ 0 ... pos ] by 1

        next= 0
        next= @paths[pos].getDist() if not isEnd

        pos: pos
        isStart: pos ==0
        isEnd:   isEnd
        noAssign: if isEnd then 0 else @paths[pos].getDist()
        noAssignTitre: if isEnd then '' else @getNom pos+1
        before: if pos then @paths[pos-1].getDist() else 0
        done: done
        todo: @getDist()-done
        next: next
        total: @getDist()
        nom:  @getNom pos  # nom obj si presnt
        nom2: @getNom pos,false


#     isObjWP: (obj)->
#
#         for wp,pos in @waypoints when wp.obj is obj
#             return pos
#         false



#     initFromState: (state)->
#
#         wps= @waypoints[..]
#         for wp,i in wps by -1
#             @removeWaypoint i
#         #@waypoints= [] # for clarity
#         #log "INIT PATH STATE"
#         for wp in state
#             @addWaypoint wp
#         @


#     reRoute:->
#         wps= @waypoints[..]
#
#         for wp,i in wps by -1
#             @removeWaypoint i
#
#         for wp in wps
#             @addWaypoint wp


    getState:->

        wps: for wp in @waypoints
                [wp.lat,wp.lng]
        ids: for wp in @waypoints
                wp.objid or 0

    getPath: (wp)->
        if @waypoints[wp.pos] isnt wp
            return null
        @paths[wp.pos]


    getLineAt: (pt)->
        resp= @router.getLineAt x:pt.lng, y:pt.lat
        return resp if not resp
        { line, info  }= resp
        dist= mCalcDist pt.lat, pt.lng, info.y, info.x
        { line, dist }

    getPts:->
        [].concat.apply [],@paths.map (path,idx)->
            if idx
                 path.pts[1...]
            else path.pts



saveAsFile= (filename, contentType='application/octet-stream',content)->

        a= document.createElement 'a'
        blob= new Blob [content],type:contentType
        a.href = window.URL.createObjectURL blob
        a.download= filename
        a.click()



EmptyArray= []
EmptyMap= {}



getGoogleNavURL= (itin,ops)->
    return if !itin

    travelMode= (ops?.travelMode or itin.router?.graphOps?.travelMode or 'DRIVING').toLowerCase() # Bug google

    waypointsArr= ("#{lat.toFixed 6},#{lng.toFixed 6}" for {lat,lng} in itin.waypoints[..-1])
    end= waypointsArr.pop()
    waypoints= waypointsArr.join '|'

    newLoc= "https://www.google.com/maps/dir/?api=1&origin=current+location&destination=#{end}&travelmode=#{travelMode}&hl=#{Lang}&waypoints=#{waypoints}" #&avoid=h
    #log "doNavGoogle circuit doin preventDefault ",{newLoc,circuit,ops,@props}



class ItinPStateManager

    debug= false

    # **** Attraits  ****

    defaultState:
        itin: null
        itinData: null
        itinSelectedWPPos: o1 'a00',[0,0]
        itinSelectedWP: null

    persistantState: {itin:1,itinData:1} # carefull we want the changes from itin but save them in initData

    initItinNoGraph: ->
        #log "initItinNoGraph  @state.itin=#{!! @state.itin}  @state.itinData=#{!!@state.itinData} ",{itinData:@state.itinData,itin:@state.itin}
        @setItinGraphP null,@state.itinData

    initItinFromGraph: (data)->
        #log "initItinFromGraph  @state.itin=#{!! @state.itin}  @state.itinData=#{!!@state.itinData} ",{itinData:@state.itinData,itin:@state.itin}
        if @state.itin
            oldItinData= @state.itinData
            self= @
            @clearItin ->self.setItinGraphP data,oldItinData
        else @setItinGraphP data
        null


    setItinGraphP: (data,initData=@state.itinData)->
        #log "create itin with setItinGraph",{initData} if vx.isDEV
        #@setState itin:Itineraire._ routerType:'graphc',routerData:data,lineOps:lineOps,initData:@state.itinData
        # itin will change on do what we have done to attraits
        decoChanges={}
        visibilityChanges={}

        #log "set itin graph"

        itinp= ItineraireP.P routerType:(vx.mapMode.router or 'graphg'),routerData:data,lineOps:lineOps,initData:initData
        .then (itin)=>
            #log "new itin is setState" if debug
            @setState itin:itin

            for aid,wp of itin.attraitsIds
                decoChanges[aid]= {itinpos:wp.pos,itinend:wp.isEnd or undefined}
                visibilityChanges[aid]= true

            #log "ItinStateManager.setItinGraph new itin",{itin,visibilityChanges,decoChanges} if debug

            for aid,state of visibilityChanges
                @setAttraitVisibilityById state,aid

            @addAttraitIdsDecorations decoChanges

            null



    clearItin: (cb)->
        if @state.itin and @state.itin.waypoints?.length
            @clearItinSelectedWP()
            #log "clear itin removing waypoints"
            @setItinWaypoints [] #this does a set state

        # ok let things settle down
        if cb
            @setState (newState)->
                setTimeout cb,1

    reverseItin: (cb)->
        if @state.itin and @state.itin.waypoints?.length > 1
            newWPts= @getItinWaypoints()[..].reverse()
            self= @
            @clearItin ->
                self.setItinWaypoints newWPts
                if cb
                    self.setState (newState)->setTimeout cb,1
        else if cb
                @setState (newState)->setTimeout cb,1


    exportItinGPX: ->
        return if !@state.itin or !vx.mapMode.gpxItin

        saveAsFile (fname="#{say 'Itinéraire'}.gpx"),'application/gpx+xml',"""
<?xml version="1.0" encoding="UTF-8"?>
<gpx xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:gpxdata="http://www.cluetrust.com/XML/GPXDATA/1/0" xmlns="http://www.topografix.com/GPX/1/0" xsi:schemaLocation="http://www.topografix.com/GPX/1/0 http://www.topografix.com/GPX/1/0/gpx.xsd http://www.cluetrust.com/XML/GPXDATA/1/0 http://www.cluetrust.com/Schemas/gpxdata10.xsd" version="1.0" creator="http://ridewithgps.com/">
  <author>FCMQ inc</author>
  <url>#{document.referrer or window.location}</url>
  <time>#{new Date}</time>
  <trk>
    <name>#{say "Itinéraire"}</name>
    <trkseg>
      #{ ("<trkpt lat=\"#{y.toFixed 6}\" lon=\"#{x.toFixed 6}\"></trkpt>" for {x,y} in @state.itin.getPts() ).join '\n      '}
    </trkseg>
  </trk>
</gpx>
"""

        mess=say "GPX downloaded",{fname}
        #log "app=#{!!@props.app} app.snackbarMessage=#{!!@props.app?.snackbarMessage} mess=#{mess}"
        @snackbarMessage message: mess


    doStartItinNavigate: ->
        itin= @state.itin
        #log "doStartItinNavigate itin=",itin
        if itin
            url= getGoogleNavURL itin
            #log "doNavigate URL=#{url}"
            window.open url,'_blank' if url


    forceItinData: (newItinData)->
        return if @_itinDataSameAs newItinData,@state.itinData
        #if @state.itinData and !@state.itinData.i
        #    # not a preloaded
        #
        #yup prety ugly
        @stateStore 'itinData',newItinData
        @state.itinData= newItinData


    setItinData: (newItinData)->
        { wps=[], ids=[] }= newItinData
        @setItinWaypoints ( {lat,lng,objid: ids[i] or undefined } for [lat,lng],i in wps )
        #itin exists but was reset
        #create wpArr



    _itinDataSameAs: (a,b)->
        ret= @_itinDataSameAsBis a,b
        #log "ItinStateManager._itinDataSameAs is #{ret} for ",{a,b}
        ret

    _itinDataSameAsBis: (a,b)->
        return true  if a is b
        return false if !a or !b
        return false if a.wps.length != b.wps.length
        bids= b.ids
        for id,idx in a.ids when id isnt bids[idx]
            return false
        bwps= b.wps
        for pa,idx in a.wps
            pb= bwps[idx]
            continue if !pa and !pb
            return false if pa[0] isnt pb[0] or pa[1] isnt pb[1]
        true

    stateStore_itin: (name,val)->
        #@stateStore name,val
        if val instanceof ItineraireP
              val=val.getState()
        else  val= null

        return if @_itinDataSameAs val,@state.itinData

        @state.itinData= val # yup cause we dont want a rerendr etc
        @stateStore 'itinData',val # or val

    stateLoad_itin: (name,stateName)->
        #should never of happened ignore


    addItinWaypoint: (ref,idx=null)->
        #log "ItinStateManager.addItinWaypoint got ",{ref,idx}
        if ref.pt
            if ref.id
                 wp=  { lng:ref.pt.x, lat:ref.pt.y, objid:ref.id, uid:"wp-#{ref.id}" }
            else wp=  { lng:ref.pt.x, lat:ref.pt.y }
        else wp= {lat:ref[0],lng:ref[1]}

        return if !@state.itin
        @state.itin.addWaypointP wp,idx
        .then (itin)=>

            #Adjust visibility
            if ref.id
                #log "addItinWaypoint.setAttraitVisibilityById #{ref.id}"
                @setAttraitVisibilityById true,ref.id

            #Adjust decorations
            # test if they have changed
            newDecos= new ->( @[aId]={itinpos:wp.pos,itinend:if wp.isEnd then 1} for aId,wp of itin.attraitsIds ; @)
            #decos= oNew ( [aID,{itinpos:wp.pos,itinend:if wp.isEnd then 1}] for aId,wp of itin.attraitsIds
            @addAttraitIdsDecorations newDecos

            @setState  {itin}



    setItinWaypoints: (wpArr)->
        log "setItinWaypoints wppts=",wpArr if debug
        # we can batch clear
        itin= @state.itin

        if not itin
            # no itin yet ... check for itin data
            if @state.itinData
                #Ok now we cheat we know itin init has not occured yet
                # So we just wack initData could not be worst ....
                # TODO: make sure this is tested
                log "Error ItinStateManager.setItinWaypoints lost!"
            @state.itinData.wps= wpArr
            return

        decoChanges={}
        visibilityChanges={}
        changes= false

        if itin.waypoints.length #remove decorations and exisiting waypoints
            log "setItinWaypoints removing waypoints",{wp:itin.waypoints.slice 0} if debug
            for wp in itin.waypoints when wp.objid
                decoChanges[wp.objid]= {itinpos:undefined,itinend:undefined}
                visibilityChanges[wp.objid]= 0
                changes= true

            itin= itin.reset()

            if !wpArr or !wpArr.length
                log "itin clear changes=#{changes}",{decoChanges,visibilityChanges} if debug
                if changes
                    @addAttraitIdsDecorations decoChanges
                    @setAttraitsVisibilitiesByIds visibilityChanges
                @setState {itin}
                return

        log "setItinWaypoints adding wppts=",wpArr if debug
        # simple itin is empty fill here up
        itinP= itin.addWaypointsP wpArr
        #ok now batch decorate
        .then (itin)=>
            log "setItinWaypoints decorate" if debug
            for aId,wp of itin.attraitsIds
                decoChanges[aId]= {itinpos:wp.pos,itinend:if wp.isEnd then 1}
                visibilityChanges[aId]= 1

            @addAttraitIdsDecorations decoChanges
            @setAttraitsVisibilitiesByIds visibilityChanges

            @setState {itin}


    removeItinWaypoint: (pos)->
        return if !@state.itin
        ipos= pos
        if pos.id and pos.id of @state.itin.attraitsIds
            wp= @state.itin.attraitsIds[ pos.id]
            xpos= wp.pos
        else if pos.pos?
            xpos= pos.pos
        else
            xpos= pos

        #oldWayPts= @state.itin?.waypoints[..]
        oldWp= @state.itin?.waypoints[xpos]
        itinP= @state.itin?.removeWaypointP xpos
        .then (itin)=>

            newDecos= new ->( @[aId]={itinpos:wp.pos,itinend:if wp.isEnd then 1} for aId,wp of itin.attraitsIds ; @)
            if oldid= oldWp.objid
                @setAttraitVisibilityById false,oldid
                newDecos[oldid]= {itinpos:null,itinend:null}
            #decos= oNew ( [aID,{itinpos:wp.pos,itinend:if wp.isEnd then 1}] for aId,wp of itin.attraitsIds
            @addAttraitIdsDecorations newDecos

            @setState  {itin}


    #removeItin: ->


    doRemoveItinWaypoint: (pos)->
        #log "ItinPStateManager.doRemoveItinWaypoint pos=#{typeof pos} #{pos}",pos
        @removeItinWaypoint parseInt pos # bound version

    updateItinWaypoint: (alatlng,pos)->
        return if !@state.itin
        itinP= @state.itin.updateWaypointP pos,alatlng
        .then (itin)=> @setState  {itin}

    doUpdateItin: (newItin)->
        @setState  {itin:newItin}

    lineOps=
        stroke:true
        weight:9
        color: '#ff00ff'
        opacity:0.4
        simplify:3
        className: 'itin'


    hasItin: -> !!@state.itin
    getItin: -> @state.itin

    getItinWaypoints: -> @state.itin?.waypoints or EmptyArray
    getItinPaths: ->     @state.itin?.paths or EmptyArray
    getItinAttraitsIds: ->  @state.itin?.attraitsIds or EmptyMap

    getItinTotalDst: ->
        @state.itin?.getDist() or 0 # null/undefined ?


    getItinSelectedWP: ->@state.itinSelectedWP

    setItinSelectedWP: (wp)->
        #log "ItinStateManager setItinSelectedWP=#{wp}=",wp

        if wp
            @closeAllMenus()
            @setState
                itinSelectedWPPos:[wp.lat,wp.lng]
                itinSelectedWP: wp
        else
            @setState
                itinSelectedWPPos: o1 'a00',[0,0]
                itinSelectedWP: null


    clearItinSelectedWP:->
        if @state.itinSelectedWP
            @setState
                itinSelectedWPPos: o1 'a00',[0,0]
                itinSelectedWP: null







module.exports= { ItineraireP, ItinPStateManager } #, VItin }

###
meta class VItin extends BaseClass

    waypoints:[]
    paths:[] #derived from way points length= waypoints.length-1 max 0
    attraitsIds:{}
    router: null #Instanceof Router
    totalDist: 0
    lineOps: {}
    graphOps: {}
    ops:{}  # set in constructor ops for router

    constructor: (@waypoints,@router,@ops)->
        super()
        @paths= ( @router.getPath start,end,@ops for [start,end] in aPairs @waypoints )
        #log "Create VItin paths= ",@paths
        d=0
        #log "got #{@paths.length}"
        for path in @paths
            pd= path.getDist()
            d+= pd
            #log "path d=#{pd} dt=#{d} path=",path
        @totalDist= d
        return @

    getBounds: -> Bounds.aBNDS ( bnds for path in @paths  when bnds=path.getBounds() )

    getPts: ->
        if @paths.length
            [].concat.apply [@paths[0].pts[0]],( path.pts[1..] for path in @paths )
        else []


    closestPt2:(pt)->
        lpt= LineStr._ @getPts()
        info= lpt.distInfo2Pt pt
        #log "VItin closestPt2 for #{pt} got info=",info
        lpt.pts[info.idx]


    getSegIdsOld: ->

        segs= ( path.segs for path in @paths )

        segIdLookup={}
        segIds=[]
        for segInfo in segs
            #TODO check start and end if significant
            for segId in segInfo.connectIds when (abs segId) not of segIdLookup
                segIds.push abs segId
                segIdLookup[abs segId]=1
        segIds


    getSegIds: ->

        #log "getSegIds path=",@paths

        segs= ( path.segs for path in @paths )
        #log "getSegIds segs=",segs
        segIdLookup={}
        segIds=[]
        for segInfo in segs
            #TODO check start and end if significant
            #log "segInfo=",segInfo
            continue if not segInfo.connectIds
            lastIdx= segInfo.connectIds.length-1
            if !lastIdx
                #log "single segInfo=",segInfo
                if abs(segId=segInfo.connectIds[0]) not of segIdLookup
                    segIdLookup[abs segId]= 1
                    segIds.push segId
                continue
            for segId,idx in segInfo.connectIds when (abs segId) not of segIdLookup
                if !idx or idx is lastIdx
                    seg= @router.getSegId abs segId
                    l= seg.pts.length
                    # check if start or end is significant
                    if !idx #start
                        pCnt=segInfo.startIdx
                        if segId>0 then pCnt=l-pCnt
                    else #end
                        pCnt=segInfo.endIdx
                        if segId<0 then pCnt=l-pCnt
                    if l/3>pCnt
                        # to short ignore this segment
                        continue
                segIds.push segId
                segIdLookup[abs segId]=1
        segIds
###
