{ meta, log, sList, sSet, nr, time:getTime }= Mod= vx= require 'vx/globals/Boot'
ModuleName= 'GraphWidgets'

{ oDiffs, oAssign, time:getTime, isPrefix, aSum, aPairs, aLast, sSplit, sSplit1 }= Mod

{ Pt }= require 'vx/graph/Graph5'

{ pmnDrawer }= require 'vx/tiles/PMNDrawerCanvas' # , pmnLegend

{ UIComponent }= UI= require 'vx/UI'


{ span, div, br, table, tbody, tr, td, th, ul, li }= UI
{ svg, path, g, line, hr }= UI

{ sayModule }= require 'vx/i18n/I18n'
say= sayModule ModuleName



graphPtInfo= meta class GraphPtInfo extends UIComponent

    constructor:(props)->
        super props
        @state= {data:null}
        return @


    render: ->

        {graph,pt,clubs}= @props
        
        return hr() if !pt
        
        if pt not instanceof Pt
            pt=  Pt._ pt[1],pt[0],1
        
        {data}= @state

        if data
            ops=
                'data-numclub':data.numClub
                'data-nomclub':clubs?[data.numClub]
                'data-numsentier':"#{data.cat or ''}#{data.no_sen or ''}"
                'data-pos':"#{pt?.y?.toFixed(6)}#{pt and ','}#{pt?.x?.toFixed(6)}"
                'data-region':clubs?[data.numClub]
        else ops={}


        ul '$paddingLeft=16px "$margin=4px 0 8px 0" $listStyleType=none .mdc-typography--body2 .graphptinfo',ops, ...[
            li '.graphptinfo--pos',say 'position clicker',{pt}
            li '.graphptinfo--sentier',
                if data and graph
                     say 'sentier info',data
                else say "Recherche encours ..."
            li '.graphptinfo--club',
                if data and graph
                     say 'club info',data
                else say "Recherche encours ..."
            li '.graphptinfo--region',
                if data and graph
                     say 'region info',data
                else say "Recherche encours ..."
            ]



#     getDerivedStateFromProps: (nextProps, prevState)->
#         {graph,clubs,pt}= @props
#             
#         if graph is nextProps.graph and pt is nextProps.pt and clubs is nextProps.clubs
#              #no change
#              null
#         else {data:null}


    componentDidUpdate: (prev={})->
    
        return if prev is @props
        {graph,clubs,pt}= @props

        return if graph is prev.graph and pt is prev.pt and clubs is prev.clubs
        self= @
        requestAnimationFrame -> 
            {graph,clubs,pt}= self.props
            if pt not instanceof Pt
                pt=  Pt._ pt[1],pt[0]

            if graph
                {seg}= graph.findClosestSegmentToPtM pt
                distInfo= seg.closestToM pt
                dist= pt.distanceTo distInfo

                {node,dist:nodeDist}= graph.findClosestNode2Pt pt
            #log "GraphPtInfo.render segInfo= ",{seg,graph,pt,dist}


            data=
                __proto__: seg?.data
                dist:  dist
                clubs: clubs
                pt: pt
                id:seg?.id
                
            self.setState {data}


    componentDidMount: -> @componentDidUpdate {}


segFinder= meta class SegFinder extends UIComponent

    @propTypes =
        pt:         @type.array
        graph:      @type.object
        onNewValue: @type.func
        name:       @type.string # a prop passed to onNewValue
        debug:      @type.bool


    constructor:(props)->
        super props
        @state= seg:null
        return @


    componentWillMount:-> @update @props

    componentWillReceiveProps: (newProps)->
        # we only care if pt, or graph changes
        # like shouldUpdate be we change state
        return if @props.pt is newProps.pt and @props.graph is newProps.graph #no change
        @update newProps

    update: (newProps)->

        #log "segFinder update  newProps=",newProps
        if newProps.pt and newProps.graph
            pt= newProps.pt
            if pt instanceof Array # TODO Clean this mess up ...
                pt= [pt[1],pt[0]]
            newSeg= newProps.graph.findClosestSegmentToPtM pt
            #log " segfFinder search #{newProps.pt} got ",{pt:newProps.pt,newSeg} #if newProps.debug
        else newSeg= null
        if newSeg isnt @state.seg
            @setState seg:newSeg

    shouldCompnentUpdate: (newProps,newState)->
        # we only update on seg change
        newState.seg isnt @state.seg

    render: -> null

    componentDidUpdate: (prevProps, prevState)->
        # we could also do the search here ... Better if we have a shouldUpdate
        if @props.onNewValue and @state.seg isnt prevState.seg
            #seg Change trigger
            log " segfinder seg change from to ",{to:@state.seg,from:prevState.seg} if @props.debug
            @props.onNewValue @state.seg,@props.name or ''




module.exports= { graphPtInfo, segFinder, pmnDrawer } # , pmnLegend
