{ log, sList, Global }= vx= require 'vx/globals/Boot'

log "TradEn isnode=#{Global.vx.isNODE}"

{ span, br, p, b, ul, li, em, a, div }= require 'vx/UI'

if Global.vx.isNODE
    span= br= p= b= ul= li= em= a= div= ->''

{ n01, n5, n6, asDateEn:asDate, asTimeEn:asTime, msAsTimeStrEn:msAsTimeStr }= require 'vx/tools/Formating'


{ getAge }= require 'vx/comp/surf/SurfColor'

{ reg2nomEn:Reg2Name }= require 'vx/globals/Conversions'


module.exports=

    'reg2name': -> Reg2Name[@] or @
    'asDate':   -> asDate @
    'asDist':   (say,{distScale})-> @*distScale
    'asDistStr1':   (say,{distUnit,distScale})-> "#{(@*distScale).toFixed 1} #{distUnit}"
    'asDistStr0':   (say,{distUnit,distScale})-> "#{(@*distScale).toFixed 0} #{distUnit}"
    'distUnit':     (say,{distUnit})-> distUnit

    "Friendly Expedition": '!'
    "Curious Exploration": '!'
    "Marathon Adventure":  '!'
    'titre btn editer':"Modify this circuit or send it to the iMotoneige App"


    #partout
    'OK': '!'
    'Ok': "OK"

    #Plusieurs Modules
    #'App:Titre bouton retour carte': "Map"

    'AppState:message changer config carte': "You are about to view an itinerary. This action will change the current itinerary and your map (eg zoom, position). Do you want to continue?"


    'App:titre carte':  "FCMQ Map 2023-24"
    'App:titre app':    "iMotoneige 2024"

    'App:# Usager FB': "FB user #"
    'App:Administration': "Administration"
    #'App:Ajouter à OdoVéloApp:Après arrêt': '!'
    #'App:Après premier enregistrer': '!'
    #'App:Automatiquement lire les attraits': '!'
    #'App:Créer Attrait': '!'
    'App:Debug': "Debug"
    #'App:Delais avant lecture': '!'
    #'App:Jamais': '!'
    'App:La carte des sentiers a été mise à jour':  "The trail map was updated "
    'App:Les attraits ont été mise à jour': "Services were updated "
    'App:Les circuits ont été mise à jour': "Trails were updated "
    'App:Map Menu': "Menu"

    'App:Options': "Options"
    #'App:Reseau Motoneige': '!'
    'App:Services': "Services"
    'App:Titre menu bkg': "Clicked Position:"
    'App:titre page itin':"Itinerary"
    'App:Toujours': '!'
    'App:Transmettre commentaires au serveur': '!'
    'App:Vitesse de lecture': '!'
    'App:menu item admin': "Administration"
    'App:menu item parametres':"Parameters"
    'App:menu item mapgoogle':  "Map 2024"
    'App:btn retour carte': "Back to Map"
    'App:menu item mapleaflet': "Offline map"
    'App:menu item list circuits': "Proposed itineraries"
    'App:menu item itin': "Itinerary"
    'App:menu item itin hover': "Click to view itinerary"
    'App:titre list circuits':  "Proposed itineraries"
    'App:btn list circuits':[(span '.vx-hide-w-compact',"Trail List"),(span '.vx-show-w-compact',"Trails")]
    'App:titre bouton planifier': " Easy planning"
    'App:tests': '!'
    'App:titre group couches': "Layers"

    'App:btn install app': [( span '.vx-show-w-wide',"Download the App" ),( span '.vx-hide-w-wide .vx-hide-w-narrow',"App" )]

    'App:btn install app hover': "Download the app to your cellphone"
    'App:btn install app href': 'https://fcmq.qc.ca/en/snowmobilers/imotoneige/'
    #'App:Ajouter à OdoVélo:': '!'
    #'App:Après arrêt': '!'


    'App:titre page urgence': "EMERGENCY ?"
    'App:urgence txt': [
        span '.vx-smalltxtx',"Dial"
        br()
        span '.vx-vbigtxt',"9-1-1"
        br()
        span '.vx-smalltxtx',"or"
        br()
        span '.vx-bigtxt',"310-4141"
        br()
        span '.vx-smalltxtx',"Cellular"
        br()
        span '.vx-bigtxt',"*4141"
        ]

    'App:titre bouton copyright': "© Legal Notice"
    'App:hover bouton copyright': "Click for copyrights and legal notice"


    'App:bouton couche sentiers': "Trails"
    'App:titre couche sentiers': "_"

    'App:bouton couche aucunecondition': "No trail conditions"
    'App:titre couche aucunecondition': "_"

    'App:bouton couche surfaceuses': "Grooming"
    'App:titre couche surfaceuses':  "Grooming"
    'App:mess legend surfaceuses':-> "As of #{asDate @date}"

    'App:bouton couche conditions': "Trail Conditions"
    'App:titre couche conditions': "_"
    'App:mess legend conditions':-> "As of ##{asDate @date}"

#    'App:titre btn editer':"Modify this circuit or send it to iMotoneige"

    'App:et +':-> "and +"
    'App:mess segment surfacer':->
        div '',
            b '',"Segment groomed on #{asDate @date}"
            b ''," by vehicle #{@veh.replace '-NTS',''}" if @veh
            br()
            "The segment was groomed #{n01 @age} hours before the last update that occured on #{asDate @majDate}."
            br()
    'App:mess segment surfacer v2':->
        {entryTime,exitTime,vehId}= @passage or {}
        entryText= (asDate entryTime).replace 'at','between'
        if (new Date entryTime).getDate() isnt (new Date exitTime).getDate()
             exitText= asDate exitTime
        else exitText= asTime exitTime
        div '',
            b '',"Segment groomed #{(entryText).replace 'at','between'} and #{exitText}"
            b ''," by vehicle #{vehId.replace '-NTS',''}" if vehId
            br()
            "The segment was groomed #{n01 getAge @maxTime,entryTime,exitTime} hours before the last update that occured on #{asDate @maxTime}."
            br()
    'App:mess segment surfacer v2 vieux':->
        {vehId}= @passage or {}
        div '',
            b '',"Segment groomed more than #{@heures} hours ago"
            b ''," by vehicle #{vehId.replace '-NTS',''}" if vehId
            br()

    'App:mess attent serveur surfacage': "Waiting for a response from the grooming server"
    'App:mess pas info surfacage': "No grooming information available"

    'App:bouton couche surfaceusesbrute': "Grooming GPS track"
    'App:titre couche surfaceusesbrute': "All grooming GPS points of last 7 days"

    # Conditions

    'App:mess attent serveur conditions': "Waiting for a response from the 'conditions' server"
    'App:mess pas info conditions': "No trail condition information available for this segment"

    'Cliquer pour détails': "Click for more information"

    'Conditions:':"Conditions: "

    'App:cond-Excellent': "Excellent"
    'App:cond-Très bon': "Very Good"
    'App:cond-Bon': "Good"
    'App:cond-Ouvert':"Opened"
    'App:cond-Acceptable':"Acceptable"
    'App:cond-Limit':"Limit"
    'App:cond-Limitj':"Limit"
    'App:cond-Limité':"Limited"
    'App:cond-Limitéj':"Limited"
    'App:cond-Fermé':"Closed"
    'App:cond-cfermé':"Closed"
    'App:cond-Non disponible':"Information unavailable"
    'App:cond-':              "Information unavailable"
    'App:cond-Inconue':       "Information unavailable"
    'App:cond-inconue':       "Information unavailable"

    'Excellent': "Excellent"
    'Très bon': "Very Good"
    'Bon': "Good"
    'Ouvert':"Opened"
    'Acceptable':"Acceptable"
    'Limit':  "Limit"
    'Limitj': "Limit"
    'Limité': "Limited"
    'Limitéj':"Limited"
    'Fermé': "Closed"
    'cfermé':"Closed"
    'Non disponible':"Information unavailable"
    'Inconue':       "Information unavailable"
    'inconue':       "Information unavailable"

    'mess fermer par fcmq': "This trail segment has been closed."
        
        
    "App:cond source": ->
        name= Reg2Name[@] or @
        switch ''+@
            when 'FCMQ'    then "Source: FCMQ"
            when 'CE','MT' then "Source: FCMQ"
            when 'AT'      then "Source: #{name} clubs"
            else                "Source: ATR #{name}"

    # Fin Conditions

    'App:bouton couche ecosentiers': "écoSentier"
    'App:titre couche ecosentiers': "_"

    'App:Couche météo:': "Weather "

    'App:court couche ':  "none"
    'App:bouton couche ': "No weather layer"
    'App:titre couche ':  "_"

    'App:court couche SRPD':  "6 hour forecast"
    'App:bouton couche SRPD': "6 hour forecast: snow (Average)"
    'App:titre couche SRPD':  "Regional Deterministic Prediction System (RDPS) at 10 km resolution over Canada updated at 00UTC, 06UTC, 12UTC and 18UTC"

    'App:court couche SRPE24':  "24 hour forecast"
    'App:bouton couche SRPE24': "24 hour forecast: snow (Average)"
    'App:titre couche SRPE24':  "Regional Ensemble Prediction System (REPS) at 10 km resolution at 24 hour intervals, Snow (QPF : Quantitative Precipitation Forecasts): mean"

    'App:bouton couche Neige-radar': "Radar snow"
    'App:titre couche Neige-radar': "_"

    'App:court couche SRPE12':  "12 hour forecast"
    'App:bouton couche SRPE12': "12 hour forecast: snow (Average)"
    'App:titre couche SRPE12':  "Regional Ensemble Prediction System (REPS) at 10 km resolution over Canada updated at 00UTC and 12UTC, Snow (QPF : Quantitative Precipitation Forecasts): mean"

    'App:court couche Masse-neige': "Snow Accumulation"
    'App:bouton couche Masse-neige':"Snow mass on ground"
    'App:titre couche Masse-neige': "Global Deterministic Prediction System (GDPS) at 25 km resolution updated at 00 UTC and 12 UTC"

    'App:court couche Neige-sol': "Snow Accumulation"
    'App:bouton couche Neige-sol':"Snow Accumulation"
    'App:titre couche Neige-sol': "Global Deterministic Prediction System (GDPS) at 25 km resolution updated at 00 UTC and 12 UTC"


    "App:titre btn partager": "Share"
    "App:hover btn partager": "Share my itinerary and current map"

    'App:Menu': "!"

    'App:titre popup copyright':"Legal Notice"
    'App:html popup copyright': ->
        ul '',
            li "|Park boundaries: © Government of Québec"
            li "|Map data: © OpenStreetMap contributors, ",a 'href=http://www.openstreetmap.org/copyright target=_blank',"License"
            (li "|Map data and style: © MapTiler , ",a 'href=https://www.maptiler.com/copyright/ target=_blank',"Licence") if vx.isAPP
            li "|Snowmobile trails: © ",a 'href=http://fcmq.qc.ca/ target=_blank',"Federation of Snowmobile Clubs of Quebec"
            (li "|Using ",a 'href=http://leafletjs.com target=_blank',"Leaflet") if vx.isAPP
            (li "|Using  ",a 'href=http://MapBox.com target=_blank',"MapBox-gl-js , ",a 'href=https://github.com/mapbox/mapbox-gl-js/blob/master/LICENSE.txt target=_blank',"Licence") if vx.isAPP
            li "|Weather : © Environment Canada"
            li "|HTML, CSS, Javascript and maps (image): © ",a 'href=http://www.fcmq.qc.ca target=_blank',"FCMQ Inc."


    'App:titre page parametres': "Parameters"

    'App:Version des sentiers:': "Trail map version: "
    'App:Version des attraits:': "Services verson: "
    'App:verifier temps': ->" (verified #{msAsTimeStr @temps} ago)"
    'App:État reseau:': "Network: "
    'App:online':  'on line'
    'App:offline': 'off line'
    'App:Beta mode, à vos risques!':"Beta mode: At your own risk!"
    'App:Détails techniques:': 'Technical details: '
        
    "App:Mode fond ecran": "Source of map background: "

    'App:bkgMode0': 'Cellphone / Tablet'
    'App:bkgMode1': 'Hybride'
    'App:bkgMode2': 'Internet'
       
    # Départ

    'App:init encours':   "Please wait: Currently initializing. This process can take a certain time."
    'App:lecture encours':"Please wait: Currently loading. This process can take a certain time."
    'App:Terminé' : "Initialisationand loading finished" 



    'Ajax:graph':    'trails'
    'Ajax:attraits': 'services'

    'Ajax:Un moment lecture des': "One moment, loading"
    'Ajax:Un moment telechargement': "One moment, downloading"
    'Ajax:"Lecture des': "Loading"
    'Ajax:terminée': "finished"
        
    # AppAdminPage

    'AppAdminPage:Mise à jour encours ...': "Currently updating ..."
    'AppAdminPage:Nouveau ShapeFile': "New shape file"
    'AppAdminPage:Nouveau fichier d\'attraits': 'New attraction file'
    'AppAdminPage:Mise à jour réussie!': " Succesfull update!"
    'AppAdminPage:ATTENTION! Erreur de mise à jour:': "  WARNING! Error occured during update"


    # Partager

    # #mapShareBtn


    'SharingUI:Annuler': "Cancel"

    'SharingUI:titre btn partager': "Share itinerary and map"
    'SharingUI:hover btn paratger': "Share my itinerary and current map"

    'SharingUI:titre popup partager': "Share itinerary and map"
    'SharingUI:intro popup partager': "Send your itinerary and your map settings (zoom, position, selected icons) by one of the following ways."
    'SharingUI:intro popup partager cell2': "Tip: To send your position, place an itinerary marker on your position marker."

    'SharingUI:titre btn partager courriel': [(span '.vx-hide-w-narrow',"Send by "),"email"]
    'SharingUI:hover btn partager courriel': "Send by email"
    'SharingUI:text btn partager courriel':  span '.vx-hide-w-medium',"Send by email"

    'SharingUI:titre btn partager sms':  [(span '.vx-hide-w-narrow',"Send by "),"SMS"]
    'SharingUI:hover btn partager sms': "Send by SMS"
    'SharingUI:text btn partager sms':   span '.vx-hide-w-medium',"Send by SMS"

    'SharingUI:titre btn partager favori': "Duplicate with full URL"
    'SharingUI:hover btn partager favori': "_"
    'SharingUI:text btn partager favori': "Open a web page with the full URL. Useful to save in a bookmark!"

    'SharingUI:titre btn partager copy': "Copy URL"
    'SharingUI:hover btn partager copy': "_"
    'SharingUI:text btn partager copy':"Copy the URL in the clipboard to paste it elsewhere."


    'SharingUI:message pour copier faire': "To copy the URL to the clipboard, click simultaneously the keys: < Ctrl >< c >"

    'SharingUI:titre copy automatique': "Copy"
    'SharingUI:message copy automatique': "The link to your itinerary / map has been copied to the clipboard. Just paste it where you wish."

    'SharingUI:titre erreur copy automatique':"Error"
    'SharingUI:message erreur copy automatique': "Error during copy"


    'SharingUI:message changer config carte': "You are opening an itinerary. Ths action will override the current itinerary and your map (zoom, position). Do you wish to continue?"


    # sharing actions

    'SharingUI:titre courriel partager':  (-> if vx.mapMode.domain is 'mn' then "Itinerary and iMotoneige map" else "Itinerary and map" )
    'SharingUI:message courriel partager': "Click the flowing link to see the itinerary and map:\n"

    'SharingUI:titre sms partager': "_"
    'SharingUI:message sms partager': (-> if vx.mapMode.domain is 'mn' then "iMotoneige: " else "Itinerary and map: ")

    'SharingUI:titre copie automatique': "_"
    'SharingUI:message copie automatique': (-> if vx.mapMode.domain is 'mn' then "iMotoneige: " else "Itinerary and map: ")

    'SharingUI:bug email': "By clicking OK, the route is copied to the clipboard and an email is created. Just insert the link by doing the 'paste' operation (keyboard shortcut: 'CTRL' and 'V'). This procedure is not completely automatic due to a problem beyond our control. "
    'SharingUI:bug email2': "Click the flowing link to see the itinerary and map:\nATTENTION!: Long press here to insert the itinerary. (the itinerary was automaticaly copied to the clipboard)."


    'AppAdminPage:Administration': "!"
    'AppAdminPage:bouton import attraits':  "Import Attractions"
    'AppAdminPage:bouton import shapefile': "Import Shapefile"
    'AppAdminPage:bouton import alerts':    "Import Alerts"
    'AppAdminPage:bouton import circuits':  "Import Itineraries"


    'FCMQ:Abri ou système sécurité':"Secure Location"
    'FCMQ:Bar': "Bar / Cocktail"
    'FCMQ:Bed and Breakfast': "Bed & Breakfast"
    'FCMQ:Concesionnaire': "Snowmobile Dealer"
    'FCMQ:Courriel': "Email"
    'FCMQ:Essence':  "Service Station"
    'FCMQ:Forfait':  "Package"
    'FCMQ:Point de vue':"View"
    'FCMQ:Repos': 'Shack / Shelter'
    'FCMQ:Autres':'Others'
    "FCMQ:Bornes évacuation" : "Evacuation point"
    'FCMQ:Relais' : 'Snowmobile club lodging'
    'FCMQ:Refuge' : 'Snowmobile club shelter'
    'FCMQ:Hébergement': "Lodging"
    'FCMQ:Hôtel sécuritaire': "Secure Hotel"
    'FCMQ:Location motoneige': "Snowmobile Rental"
    'FCMQ:Mobilité restreinte': "Mobility adapted"
    'FCMQ:Relais Détente': "Spa / Body care"
    'FCMQ:Restaurant': "Restaurant"
    'FCMQ:Réparation mécanique': "Repair Services"
    'FCMQ:Site web': "Web site"
    'FCMQ:Sites et attraits': "Site and Attraction"
    'FCMQ:Stationnement': "Parking"
    'FCMQ:Incitatif': "Incentive Parking"
    'FCMQ:Téléphone': "Phone"
    'FCMQ:type attraits': "Types of Attractions"

    'FCMQ:info-Hôtel sécuritaire':"A secure hotel has a security system for snowmobiles. For examples, the hotel must have a garage or a closed enclosure or provide a chain free of charge. It may also have a camera system or a security guard service."
    'FCMQ:info-Incitatif':"Place to park your vehicle before going on the trail. Fees may apply."
    'FCMQ:info-Repos': "<b>Shack</b>: Shelter with possibility of heating, no service, managed by the snowmobile club.<br/><b>Shelter</b>: Heated shelter, offers one or more services, managed by the snowmobile club.<br/><b>IMPORTANT</b>: Please check with your local club if open before leaving."
    'FCMQ:info-Relais': 'Heated snowmobile lodge, offers one or more services. Check with the club to see if it is open before you leave.'
    'FCMQ:info-Refuge': 'Shelter with possibility of supplemental heat, no services. Check with the club to see it it is open before you leave.'
    'FCMQ:info-Bornes évacuation': 'Provided by Quebec Public Security (911)'

    'GMapWidgets:Recherche Google': "Google Search"


    "Itinéraire": "Itinerary"

    'ItinWidgets:Titre Itinéraire': "Itinerary : "

    'ItinWidgets:itinButton':(say,{distUnit,distScale})->
            #console.log " say ItinWidgets:itinButton",@
            switch @wpcnt
                when 0
                    [
                        span '.vx-label__responsive .vx-show-w-wide',"Itinerary:"
                        span '.vx-show-w-narrow',"Click map"
                        span '.vx-hide-w-narrow',"Click map to start"
                    ]
                when 1
                    [
                        span '.vx-label__responsive .vx-show-w-wide',"Itinerary:"
                        span '.vx-label__responsive',"Add destination"
                        ]
                when 2
                    [
                        span '.vx-label__responsive .vx-show-w-wide',"Itinerary:"
                        span '.vx-label__responsive',"#{(@dist*distScale).toFixed 0} #{distUnit}"
                        ]
                else
                    [
                        span '.vx-label__responsive .vx-show-w-wide'," Itinerary:"
                        span '.vx-label__responsive .vx-hide-w-compact'," #{@wpcnt-1} waypoints, #{(@dist*distScale).toFixed 0} #{distUnit}"
                        span '.vx-label__responsive .vx-show-w-compact',"#{(@dist*distScale).toFixed 0} #{distUnit}"
                        ]

    'ItinWidgets:mess no wp':->
        div '.mdc-typography--body1',
            p '',
                em '',"No Itinerary is defined."
                br()
                "To create an Itinerary, display the map and add waypoints to the Itinerary by clicking on the map. "
                br()
                "For details, see the ",(a "target=aidefcmq href=#{vx.urls.AideEN}","user guide")," (Internet required)."

    'ItinWidgets:bouton enlever itin': "Remove waypoint"
    'ItinWidgets:titre bouton enlever itin': "Remove this waypoint from the itinerary"

    'ItinWidgets:Début': "Start"
    'ItinWidgets:Fin': "End"

    'ItinWidgets:Insérer ce point au début de l’itinéraire': "Insert this point at the beginning of the itinerary"
    'ItinWidgets:Insérer ce point entre 2 marqueurs pour que la distance soit la plus courte': "Insert this point to the itinerary minimizing distance between the 2 closest waypoints"
    'ItinWidgets:Insérer ce point à la fin de l’itinéraire': "Insert this point at the end of the itinerary"
    'ItinWidgets:Insérer dans l’itinéraire:': "Add to itinerary as:"
    'ItinWidgets:Étape': "Waypoint"


    'ItinWidgets:Arrivée': "End"
    'ItinWidgets:Arrivé':  "End"
    'ItinWidgets:Départ':  "Start"
    'ItinWidgets:Étape':   "Waypoint"

    'ItinWidgets:Cumul parcouru':  (say,{distUnit,distScale})->" Traveled : #{(@dist*distScale).toFixed 1} #{distUnit}"
    'ItinWidgets:Prochaine étape': (say,{distUnit,distScale})-> "Next waypoint : #{(@dist*distScale).toFixed 1} #{distUnit}"
    'ItinWidgets:Distance arrivée':(say,{distUnit,distScale})-> "Remaining :  #{(@dist*distScale).toFixed 1} #{distUnit}"

    'ItinWidgets:cumul (km)':(say,{distUnit})-> "total (#{distUnit})"
    'ItinWidgets:pour (km)': (say,{distUnit})-> "for (#{distUnit})"
    'ItinWidgets:prendre':   "take"
    'ItinWidgets:à (km)':    (say,{distUnit})-> "at (#{distUnit})"

    'itin titre arrivee': "End"
    'itin titre depart':  "Start"
    'itin titre etape':   "Waypoint"

    'ItinWidgets:nomPiste':->@nom

    'ItinWidgets:clear itin': "Delete Itinerary"
    'ItinWidgets:export as GPX': "Export Itinerary as GPX"

    'ItinWidgets:kilometres': "Kilometers"
    'ItinWidgets:miles':      "Miles"

    'ItinWidgets:reverse itin': "Reverse Direction"

# Circuit UI - Circuit Info Template

    "GPX downloaded":  ->"GPX downloaded to #{@fname}"
    "download GPX":  "Download GPX file for circuit"
    "navaigation google": "Google Navigation"
# fin


# MessageUI
    'MessageUI:text accept conditions d\'utilisation debut':"I accept"
    'MessageUI:lien conditions d\'utilisation': "FCMQ's terms of use."
    'MessageUI:text accept conditions d\'utilisation fin': ' '
# fin


# Couche Selector template

    'titre legend conditions':"Conditions"
    'legend cond bonne':      "Good"
    'legend cond acceptable': "Acceptable"
    'legend cond ferme':      "Closed"
    'legend cond non dispo':  "Unavailable"




    'titre legend sentiers': "Trail types"
    'type sentier T': "Trans-Québec"
    'type sentier R': "Regional"
    'type sentier L': "Local"
    'type sentier N': ['Local ns ',span '$fontSize:66%',"(Non Subsidized)"]
    'type sentier N2':'Local ns <span style="font-size:66%">(Non Subsidized)</span>'
    'type sentier P': "Private"
    'type sentier V': "Water ways and others"
    'type sentier fermer': "Closed"
    'type sentier eco': "ÉcoSentier"


    'titre legend surf': "Grooming Legend"
    'legend surf 1':     "New colors"
    'legend surf 2':     "Legend at the bottom left"

    'mess cond': "Trail conditions are provided by Regional Tourist Associations in collaboration with FCMQ member clubs. It’s possible to know the last updates date and time by clicking on the trail."
    'mess surf': "GPS data from FCMQ member club groomers.This data is transmitted twice a day."

    'Internet requis': "Internet required"

# fin



    'GraphWidgets:position clicker': ->"Position: #{n6 @pt.y},#{n6 @pt.x}"
    'GraphWidgets:sentier info':-> """Trail: #{@cat}#{@no_sen or ''} #{ if @dist and @dist>0.05 then " at #{@dist.toFixed 1}km" else '' } (#{@id})"""
    'GraphWidgets:club info':->
        if @numClub
            if @clubs and c=@clubs[@numClub]
                if c[..3].toLowerCase() is 'club'
                     "#{c} (#{@numClub})"
                else "Club: #{c} (#{@numClub})"
            else "Club number #{@numClub}"
    'GraphWidgets:region info':->
        switch @numClub
                when 201, 202, 204, 206, 207, 215, 229, 233, 236, 246, 251
                    "Region: Saguenay-Lac-Saint-Jean"
                when 300, 322, 338, 342, 351, 319, 342, 356, 371
                    "Region: Québec/Charlevoix/Portneuf"
                when 601, 608, 627, 628, 629, 642, 655, 656, 657, 659, 662, 663, 666, 669, 677, 684, 696, 698
                    "Region: Lanaudière"
                when 400, 404, 408, 409, 410, 412, 854
                    "Region: Mauricie/Centre-du-Québec"
                when 760, 766, 771, 780, 786, 791, 794
                    "Region: Outaouais"
                when 603, 610, 611, 615, 617, 632, 633, 637, 645, 648, 649, 650, 672, 680, 683, 690, 693, 618
                    "Region: Montérégie"
                when 500, 501, 507, 508, 514, 518, 522, 524, 532, 535, 537, 538, 686, 692
                    "Region: Cantons-de-l'Est"
                when 612, 614, 619, 622, 624, 639, 646, 654, 658, 668, 681, 687, 699, 750, 751, 768, 772, 776, 792, 620, 601
                    "Region: Laurentides"
                when 101, 102, 103, 104, 105, 107, 108, 109, 110, 111, 116, 118, 120, 124, 131, 133, 150
                    "Region: Gaspésie/Îles-de-la-Madeleine"  
                when 950, 951, 952, 953, 955, 956, 957, 958, 959, 960, 961, 962, 967, 970
                    "Region: Côte-Nord"
                when 313, 359
                    "Region: Québec/Charlevoix/Portneuf"
                when 310, 328, 353, 403, 406, 407, 413, 417, 422
                    "Region: Mauricie/Centre-du-Québec"  
                when 301, 303, 306, 309, 312, 315, 317, 325, 327, 334, 337, 341, 344, 354, 355, 362, 366, 367, 379, 381, 385, 388, 112
                    "Region: Chaudière-Appalaches"  
                when 106, 302, 305, 307, 308, 314, 320, 336, 340, 346, 352, 333, 391
                    "Region: Bas-Saint-Laurent"
                when 209, 210
                    "Region: Saguenay-Lac-Saint-Jean"
                when 850, 851, 852, 855, 856, 859, 860, 862, 873, 890, 897, 880
                    "Region: Abitibi-Témiscamigue"
                else ""

    'App:menu item fermer app': "Exit application"

    'App:menu item message': "Display message"

    'App:menu item aide': "Help"
    'App:titre aide': "Help"
    'App:aide-html':"""
    <P >Sections specific to the mobile application or website are grouped at the end.</P>

    <H1 >Create an itinerary</H1>
    <UL>
        <LI>Each time the map is clicked, a new itinerary marker can be added. Just choose the type of insertion ( "At start", "Waypoint", "At end");</LI>
        <LI>Each marker is linked to the others by a line representing the shortest itinerary between these markers.</LI>
        <LI>IMPORTANT! </LI>
            <UL>
            <LI>Inserting a marker "At start" or "At end" implies that you want to control of the order in which the markers are added. </LI>
            <LI>Inserting a marker "Waypoint" implies that you want the itineray to go through this point but you don't mind how to get there as long as the itinarary is as short as possible. The application will choose where to connect this new marker in order to get the shortest distance.</LI>
            <LI>Choosing "Waypoint" may change the shape of the itinerary. To avoid changing it, use "At end". </LI>
            </UL>
        <LI>The distance of the itinerary and the number of steps appear on the map (top left).</LI>
        <LI> If an icon displayed on the map (e.g. a lodging) should be incorporated into the itinerary, just click it and then choose the type of insertion. The name of the icon will become the name of the waypoint in the itinerary instructions (see below). </LI>
    </UL>
    <P >To modify the itinerary, there are 3 possibilities:</P>
    <UL>
        <LI>to add a waypoint, click the map where the marker should be inserted and then choose the type of insertion.</LI>
        <LI>to move a waypoint, simply drag the marker (i.e. press and move the mouse by holding down the button until reaching the desired location).</LI>
        <LI>to remove a waypoint:</LI>
            <UL>
            <LI>click the right mouse button on the marker;  </LI>
            <LI>or click "Remove this step" on the popup appearing when you click the marker with the left mouse button;</LI>
            <LI>or click "Remove this step" in the itinerary interface.</LI>
            </UL>
    </UL>

    <H1 >Display itinerary instructions</H1>
    <UL>
        <LI>Once the itinerary is created, display the driving instructions by clicking the "Itinerary" button on the map (top left).</LI>
        <LI>For each waypoint, the following distances are specified: "next step", "traveled" and "remaining".</LI>
        <LI>To display detailed directions, click the arrow to the left of the name of the waypoint. The snowmobile trails to follow and the associated distances are listed.</LI>
    </UL>

    <H1 >Display the grooming delay (Internet required)</H1>
    <UL>
        <LI>Click on the "Services" button on the map (top right) and then check "grooming delay".</LI>
        <LI>The trails will be colored according to the grooming legend (bottom left).</LI>
        <LI>The delay is calculated from the grooming time to the update time identified in the grooming legend.</LI>
        <LI>To get the grooming delay for a specific trail section, click it on the map.</LI>
    </UL>

    <H1 >Display the weather (Internet required)</H1>

    Weather data provided by Environment Canada are available :
    <OL>
        <LI>6 hour forecast: snow (Average)  - Regional Deterministic Prediction System (RDPS) at 10 km resolution over Canada updated at 00UTC, 06UTC, 12UTC and 18UTC.</LI>
        <LI>12 hour forecast: snow (Average) - Regional Ensemble Prediction System (REPS) at 10 km resolution over Canada updated at 00UTC and 12UTC. Snow (QPF : Quantitative Precipitation Forecasts): mean </LI>
        <LI>24 hour forecast: snow (Average) - Regional Ensemble Prediction System (REPS) at 10 km resolution at 24 hour intervals. Snow (QPF : Quantitative Precipitation Forecasts): mean</LI>
        <LI>Snow Accumulation - Global Deterministic Prediction System (GDPS) at 25 km resolution updated at 00 UTC and 12 UTC</LI>
    </OL>

    <H1 >Print (specific to website)</H1>
    <UL>
        <LI>The printing of the map and itinerary instructions is done with the standard print menu of your browser.</LI>
        <LI>When the preview window appears, if the map is misplaced, cancel the printing and move the map then print.</LI>
        <LI>To print the markers and icons, you may need to select an option such as "Colors and background images."</LI>
    </UL>


    <H1>GPS position marker (specific to mobile application)</H1>
    <UL>
        <LI>The position is updated continuously.</LI>
        <LI>Position accuracy:</LI>
        <UL>
            <LI>The position will be more accurate if the GPS is open since the position is obtained by satellites.</LI>
            <LI>If the GPS is closed or absent on the device, the position is estimated using triangulation with the nearest cellular antennas or may not be available.</LI>
        </UL>
    </UL>


    <H1>Centering the map (specific to mobile application) </H1>
    <UL>
        <LI>To adjust the centering of the map, click the position icon appearing over the zoom buttons (bottom right).</LI>
        <LI>When the position icon is blue, the map is automatically centered on your position.</LI>
        <LI>When it is black, the map is NOT automatically centered on your location. To automatically center the map on your location, click again on the icon and it will turn blue. As soon as you manually move the map, automatic centering is deactivated and the icon becomes black. </LI>
    </UL>


      <H1>Map (specific to mobile application)</H1>
      <UL>
        <LI>The application installation includes a global map. Thus, even without Internet access (via WiFi or a data plan), you can locate the cities, towns and main streets.</LI>
        <LI>Detailed maps are available if the device has Internet access. A cloud image is displayed above the centering icon (right bottom) when the Internet connection is used. If the displayed map comes from the global map stored in the application, a circle with a line is displayed.</LI>
        <LI>To prevent iMotoneige from using the Internet, simply close the Internet access.</LI>
      </UL>


    <H1>Problem, question, comment </H1>
    <UL>
      <LI>Do not hesitate to contact us.

         <!-- <A TARGET="_blank" HREF="mail:info@fcmq.qc.ca" >info@fcmq.qc.ca</A>. -->
        Thank you !</LI>
        </UL>

    <H1 >For more information</H1>
      <P ><A TARGET="_blank"  HREF="http://fcmq.fcmqapi.ca/docs/Help_interactive_map.pdf">http://fcmq.fcmqapi.ca/docs/Help_interactive_map.pdf</A>

      </P>
"""


#         'Map Menu': "Menu"
#         'menu item mapgoogle': "Carte Google"
#         'menu item mapleaflet':"Carte Leaflet"
#         'menu item traces':"Liste des sorties"
#         'menu item edit trace':"Modifier sortie"
#         'menu item options':"Options"
#         'titre parcours':"Parcours"
#         'titre traces':"Sorties"
#         'titre page traces':"Liste des sorties"
#         'titre edit trace':"Modifier sortie"
#         'edit title':"Titre"
#         'edit desc':"Description"
#         'label id':"Identifiant"
#         'label dist':"Distance"
#         'label deniveler':"Dénivelé"

