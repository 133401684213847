{ log, Global, isPrefix, isSuffix, atob, btoa, sSet, aLast, oAllPropNames, oAssign }= vx= require 'vx/globals/Boot'

#log "Persistance got atob=",atob
#log "Persistance got btoa=",btoa


{ min, max, sqrt, floor, abs } = Math
#fs= require 'fs'

#CRA was false
if false
    readline= require 'readline'
    fs= require 'fs'


Plus=  ':'
Minus= ';'

Float= '.'
ePlus='['
eMinus=']'


ArrayStart='('
ArrayEnd=  ')'

#ObjStart= '{' is array start
ObjEnd=   '!'

Str= '/'
Ref= '*'
CompressedString= Float


actionsF=
    ":": (w)-> if w[1] is Float then  b64uToNum w.substr 2 else  b64uToInt w.substr 1
    ";":(w)-> if w[1] is Float then -b64uToNum w.substr 2 else -b64uToInt w.substr 1
    "/":  (w,lookup)->
        str= fromJSONuString w.substr 1
        lookup.set (Ref+ intToB64u lookup.seen++),str
        str

actionsR=
    "(":(stack,ret)-> stack.push ret; ret.push newArr=[]; newArr
    ")":  (stack)-> stack.pop()
    #"#{ObjStart}":  (stack,ret)-> stack.push ret; ret.push newArr=[]; newArr
    "!":    (stack,ret)->
        obj={}
        for k,i in ret by 2
            obj[k]=ret[i+1]
        next= stack.pop()
        next.pop()
        next.push obj
        next




Null='~'

B64= "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_-"

B64rx= "A-Za-z0-9_\\-"

RegxEsc= {':','[',']','@','-','(',')','.'}
asRegexChars= (lst)->( (if c of RegxEsc then '\\'+c else c) for c in lst).join ''


Starters= [Plus,Minus,ArrayStart,Str,Ref,Null]
Spliters= Starters.concat [ArrayEnd,ObjEnd]

NumParts= [Float,ePlus,eMinus] # also Compressed string

regTxt="""([#{asRegexChars Spliters }][#{B64rx + asRegexChars NumParts}]*)"""
#log "Regexp text=#{regTxt}"

RegSplit= new RegExp regTxt

KnownObjectsArr= [ [0,Plus],[ 1,Ref],[-1,Minus],['',Str],[null,Null],[undefined,Null]].concat (
        [obj,Ref+B64[i]] for obj,i in [false,true,NaN,Infinity, -Infinity ] )

KnownObjectIdx= new Map KnownObjectsArr
KnownObjectLookup=new Map ( [v,k] for [k,v] in KnownObjectsArr )
KnownObjectIdx.seen=KnownObjectLookup.seen= 26 # startat ax64

makeIdxLookup= ->
    lookup= new Map KnownObjectIdx
    lookup.seen= KnownObjectIdx.seen
    lookup

makeLookup= ->
    lookup= new Map KnownObjectLookup
    lookup.seen= KnownObjectLookup.seen
    lookup

JSONStart= sSet '{ [ - 0 1 2 3 4 5 6 7 8 9  t f n "'
JSONEnd=   sSet '} ] . 0 1 2 3 4 5 6 7 8 9  e l "'


QSCompressors={}

B64lookup= new ->(@[c]=i for c,i in B64);@

# actionsF=
#     "#{Plus}": (w)-> if w[1] is Float then  b64uToNum w.substr 2 else  b64uToInt w.substr 1
#     "#{Minus}":(w)-> if w[1] is Float then -b64uToNum w.substr 2 else -b64uToInt w.substr 1
#     "#{Str}":  (w,lookup)->
#         str= fromJSONuString w.substr 1
#         lookup.set (Ref+ intToB64u lookup.seen++),str
#         str
# 
# actionsR=
#     "#{ArrayStart}":(stack,ret)-> stack.push ret; ret.push newArr=[]; newArr
#     "#{ArrayEnd}":  (stack)-> stack.pop()
#     #"#{ObjStart}":  (stack,ret)-> stack.push ret; ret.push newArr=[]; newArr
#     "#{ObjEnd}":    (stack,ret)->
#         obj={}
#         for k,i in ret by 2
#             obj[k]=ret[i+1]
#         next= stack.pop()
#         next.pop()
#         next.push obj
#         next



Persistance= #a singelton


    isReaderCache: isReaderCache={}

    JSONisReader:(name,obj)->
        #log "revive #{obj}",obj
        return obj if !obj or !(key=obj.__is)
        if hit=isReaderCache[key]
            if hit.fromJSON
                 return hit.fromJSON obj
            else return hit obj
        #OK first time for key
        if hit is null
            log "miss on key=#{key}"
            return obj
        else log "Looking up #{key}"

        parts= key.split '.'

        try
            start=parts.shift()
            if start
                cons= Global.vx[start]
            else cons= Global
            for part in parts
                cons=cons[part]
        catch
            cons= false

        if cons and (cons.fromJSON and typeof cons.fromJSON is 'function') or typeof cons is 'function'
            #OK
            log "OK found ",cons
            isReaderCache[key]= cons
            if cons.fromJSON
                    return cons.fromJSON obj
            else return cons obj

        log "No match for #{key}"
        isReaderCache[key]= null
        obj


    toLSold: (arr,scale=10000,cx=0)->
        rnd= Math.round
        r= (v)->rnd v*scale
        dx= cx
        for x in arr then -dx+(dx=r x)

    toLS: (numArr,scale=10000,dx=0)->
        rnd= Math.round
        r= (v)->rnd v*scale
        dx= r dx
        for x in numArr
            if x?
                 t=dx
                 (dx=r x)-t
            else null

    toIdxArr: (arr,lookup)-> ( lookup.indexOf x for x in arr )

    toIdxArrStr: (arr,lookup,dx)-> @toNumStr @toLS (@toIdxArr arr,lookup),1,dx

    toIdxArrStrB: (arr,lookup,dx)-> @toNumStrB @toLS (@toIdxArr arr,lookup),1,dx

    toNumStr: (numArr)->
        ret=for num in numArr
            switch
                when num is null then '~'
                when num is 1    then '|'
                when num>0       then '+'+num.toString(36)
                when num<0       then     num.toString(36) # -
                when num is 0 or num is -0 \
                                 then '='
                when isNaN num   then '?'
                else throw "unknown parse for #{num}"
        ret.join('')

    toNumStrB: (numArr)->
        ret=for num in numArr
            switch
                when num is null then 'D'
                when num>9       then 'I'+num.toString(36)
                when num>=0      then 'HFCJQOMRSW'[num]
                when num<-9      then 'B'+(-num).toString(36) # -
                when num<=0      then 'HGAKNPLTUV'[-num]
                when isNaN num   then 'E'
                else throw "unknown parse for #{num}"
        ret.join('')




    # TODO add Infinity ?
    toNumStr2: (numArr,lastNum=null)->
        ret=for num in numArr
            r= switch
                when num is null then '~'
                when num is 1 then '|'
                when num > 0
                    sn= num.toString(36)
                    if lastNum?
                        if lastNum == num
                            '!'
                        else
                            dn= (num-lastNum)
                            sdn= (abs dn).toString 36
                            if sdn.length < sn.length
                                if dn > 0
                                    '#'+sdn
                                else '_'+sdn
                    else '+'+sn
                when num<0
                    sn= (-num).toString(36)
                    if lastNum?
                        if lastNum == num
                            '!'
                        else
                            dn= (num-lastNum)
                            sdn= (abs dn).toString 36
                            if sdn.length < sn.length
                                if dn > 0
                                    '#'+sdn
                                else '_'+sdn
                    else '-'+sn
                when num is 0 or num is -0 then '='
                when isNaN num then '?'
                else throw "unknown parse for #{num}"
            if num? and !isNaN(num)
                 lastNum= num
            else lastNum= null
            r
        ret.join('')


    toLStr: (numArr,scale=10000,dx=0)->@toNumStr @toLS numArr,scale,dx

    toLStrB: (numArr,scale=10000,dx=0)->@toNumStrB @toLS numArr,scale,dx

    toLStr3: (numArr,scale=10000,dx=0)->@toNumStr @toLS (@toLS numArr,scale,dx),1,0

    toLStr2: (numArr,scale=10000,dx=0)=>@toNumStr2 @toLS numArr,scale,dx


    toDup: (arr,repeat=0)->
        last= new Object()
        for x in arr
            if x is last then repeat else last= x

    fromLS: (arr,scale=10000,x=0)->
        x= Math.round scale*x
        for dx in arr
            if dx?
                 x+= dx
                 x/scale
            else null

    fromIdxArr: (arr,lookup)-> (lookup[x] for x in arr)

    fromIdxArrStr: (sarr,lookup,dx)-> @fromIdxArr (@fromLS (@fromNumStr sarr),1,dx),lookup

    fromIdxArrStrB: (sarr,lookup,dx)-> @fromIdxArr (@fromLS (@fromNumStrB sarr),1,dx),lookup

    fromNumStr: (numStr)->
        for w in numStr.split /([\+\-\=\~\|][a-z0-9]*)/ when w
            switch w[0]
                when '+','-' then parseInt w,36
                when '=' then 0
                when '~' then null
                when '?' then NaN
                when '|' then 1
                else throw "parse error at on '#{w}' in '#{numStr}'"

    fromNumStrB: (numStr)->
        for w in numStr.split /([A-W][a-z0-9]*)/ when w
            switch w.charCodeAt 0
                when 73  then parseInt    w[1..],36 #A I
                when 66  then -1*parseInt w[1..],36 #B
                when 72 then 0 #H
                when 68 then null #D
                when 69 then NaN #E
                when 70 then  1 #F
                when 71 then -1 #G
                when 67 then  2 #C
                when 65 then -2 #I A
                when 74 then  3 #J
                when 75 then -3 #K
                when 81 then  4 #L Q
                when 78 then -4 #M N
                when 79 then  5 #N O
                when 80 then -5 #O P
                when 77 then  6 #P M
                when 76 then -6 #Q L
                when 82 then  7 #R
                when 84 then -7 #S T
                when 83 then  8 #T S
                when 85 then -8 #U
                when 87 then  9 #V W
                when 86 then -9 #W V
                else throw "parse error at on '#{w}' in '#{numStr}'"


    fromNumStr2: (numStr,last=0)->
        for w in numStr.split /([\+\-\=\~\|\#\%\!][a-z0-9]*)/ when w
            switch w[0]
                when '+','-' then last= parseInt w,36
                when '#' then  last+= parseInt w,36
                when '_' then  last-= parseInt w,36
                when '!' then  last
                when '=' then last= 0
                when '~' then null
                when '?' then NaN
                when '|' then last= 1
                else throw "parse error in '#{numStr}'"

    fromLStr: (arr,scale=10000,x=0)-> @fromLS @fromNumStr(arr),scale,x

    fromLStrB: (arr,scale=10000,x=0)-> @fromLS @fromNumStrB(arr),scale,x

    fromLStr3: (arr,scale=10000,x=0)-> @fromLS (@fromLS @fromNumStr(arr),1,0),scale,x


    fromLStr2: (arr,scale=10000,x=0)-> @fromLS @fromNumStr2(arr),scale,x


    fromDup: (arr,repeat=0)->
        last=null
        for x in arr
            if x is repeat then last else last=x

    parseDefs: (defs)->
        defs=switch
            when typeof defs is 'string'
                for term in defs.split /\s+/
                    parts= term.split ','
                    if parts.length>1
                        parts[1]=parseFloat parts[1]
                    else parts.push false
                    parts
            when typeof defs is 'object' and !defs.length
                ( [k,v] for k,v of defs )
            else defs
        #defs.sort (a,b)-> if a[0]>b[0] then 1 else if a[0]<b[0] then -1 else 0
        defs

    parseDefs2: (defsArr)->
        defsArr= [defsArr] if !Array.isArray defsArr
        pdefs=[]
        for defs in defsArr
            pdefs=pdefs.concat switch
                when typeof defs is 'string'
                    for term in defs.split /\s+/
                        parts= term.split ':'
                        for i in [1 ... parts.length]
                            parts[i]=if isNaN pn=parseFloat p=parts[i] then p else pn
                        parts
                when typeof defs is 'object' and !defs.length
                    ( [k].concat v for k,v of defs )
                else defs
            #defs.sort (a,b)-> if a[0]>b[0] then 1 else if a[0]<b[0] then -1 else 0
        pdefs

    EncodeObjArr3: (defs,{toLSFnc=@toLStrB,toDupFnc=@toDup,toIdxFnc=@toIdxArrStrB,splitScale,toLSFncSplit=@toLStrB}={})->
        #log "using EncodeObjArr3 toLSFnc= #{toLSFnc.name}"
        p.EncodeObjArr2 defs,{toLSFnc,toDupFnc,toIdxFnc,splitScale,toLSFncSplit}

    EncodeObjArr2: (defs,{toLSFnc=@toLStr,toDupFnc=@toDup,toIdxFnc=@toIdxArrStr,splitScale,toLSFncSplit=@toLStr}={})->
        defs= @parseDefs2 defs
        #log "EncodeObjArr2 got defs=",defs
        self= @
        #log "using EncodeObjArr2 toLSFnc= #{toLSFnc.name}"
        (objArr)->
            for [prop,scale=null,dx=null] in defs
                arr= switch typeof prop
                    when 'string'
                        for o in objArr
                            props= prop.split '.'
                            for p in props
                                o= if typeof o?[p] is 'function' then o[p]() else o?[p]
                            o
                    when 'function' then ( prop o for o in objArr )
                ts= typeof scale
                switch
                    when scale and ts is 'number' and scale>=splitScale and splitScale?
                        toLSFncSplit arr,scale,dx
                    when scale and ts is 'number' and scale>0
                        toLSFnc arr,scale,dx
                    when scale is 0
                        toDupFnc arr,dx
                    when ts is 'string' or Array.isArray scale
                        #log "calling otIdxFbx arr.length=#{arr.length} scale=#{scale} ar[0 5]=#{arr[0 .. 5]}"
                        toIdxFnc arr,scale,dx
                    when ts is 'function'
                        #log "encoding with function"
                        scale arr,'encode',dx
                    when !scale?
                        arr
                    else throw "unkown scale #{ts}:#{scale}"


    EncodeObjArr: (defs,toLSFnc=@toLS,toDupFnc=@toDup)->
        defs= @parseDefs defs
        self= @

        (objArr)->
            encObjArr={}
            for [prop,scale] in defs
                arr= ( o[prop] for o in objArr )
                ts= typeof scale
                encObjArr[prop]= switch
                    when scale and ts is 'number'
                        toLSFnc arr,scale
                    when scale is 0
                        toDupFnc arr,scale
                    when ts is 'function'
                        #log "encoding with function"
                        scale arr,'encode'
                    when scale is false
                        arr
                    else throw "unkowen scale"
            encObjArr

    DecodeObjArr: (defs,initFnc,proto,fromLSFnc=@fromLS,fromDupFnc=@fromDup)->
        defs= @parseDefs defs

        (encObjArr)->
            props=[]
            for [prop,scale] in defs when (eoaProp=encObjArr[prop])
                props.push prop
                ts= typeof scale
                encObjArr[prop]= switch
                    when ts is 'number' and scale
                        fromLSFnc  eoaProp,scale
                    when scale is 0
                        fromDupFnc eoaProp,scale
                    when ts is 'function'
                        scale eoaProp,'decode'
                    when scale is false
                        eoaProp
                    else throw "unkowen scale"

            objArr= []
            for prop in props when len=encObjArr[prop].length
                break
            return objArr if not len
            for i in [0...len]
                if proto
                     obj= Object.create proto
                else obj= {}
                for prop in props when (v=encObjArr[prop][i]) isnt null
                    obj[prop]= v
                if initFnc then obj= initFnc obj
                objArr.push obj
            objArr


    DecodeObjArr3: (defs,{initFnc,proto,fromLSFnc=@fromLStrB,fromDupFnc=@fromDup,fromIdxFnc=@fromIdxArrStrB,splitScale,fromLSFncSplit=@fromLStrB}={})->
        p.DecodeObjArr2 defs,{initFnc,proto,fromLSFnc,fromDupFnc,fromIdxFnc,splitScale,fromLSFncSplit}

    DecodeObjArr2: (defs,{initFnc,proto,fromLSFnc=@fromLStr,fromDupFnc=@fromDup,fromIdxFnc=@fromIdxArrStr,splitScale,fromLSFncSplit=@fromLStr}={})->
        defs= @parseDefs2 defs

        (encObjArr)->
            tempObjArr= for eoaProp,i in encObjArr
                [prop,scale=null,dx=null]= defs[i]
                ts= typeof scale
                switch
                    when ts is 'number' and scale>=splitScale and splitScale?
                        fromLSFncSplit  eoaProp,scale,dx
                    when ts is 'number' and scale>0
                        fromLSFnc  eoaProp,scale,dx
                    when scale is 0
                        fromDupFnc eoaProp,scale,dx
                    when ts is 'string' or Array.isArray scale
                        #log "calling otIdxFbx eoaProp.length=#{eoaProp.length} scale=#{scale} ar[0 5]=#{eoaProp[0 .. 5]}"
                        fromIdxFnc eoaProp,scale,dx
                    when ts is 'function'
                        scale eoaProp,'decode',dx
                    when !scale?
                        eoaProp
                    else throw "unkowen scale"

            objArr= []
            for encArr in tempObjArr when len=encArr.length
                break
            return objArr if not len
            for i in [0 ... len]
                if proto
                     obj= Object.create proto
                else obj= {}

                for [prop],j in defs when (name=if typeof prop is 'function' then prop.name else prop) and (v=tempObjArr[j][i])?

                    switch typeof prop
                        when 'string'
                                props= prop.split '.'
                                top=props.length-1
                                o= obj
                                for p,k in props
                                    if typeof o[p] is 'function'
                                        o[p] v
                                    else if top is k
                                        o[p]= v
                                    else o= o[p]?= {}
                        when 'function' then p obj,v
                if initFnc then obj= initFnc obj,i,tempObjArr
                objArr.push obj
            objArr


    SEncodeObjArr: (defs)-> @EncodeObjArr defs, @toLStr.bind(@)
    SDecodeObjArr: (defs,initFnc,proto)-> @DecodeObjArr defs,initFnc,proto,@fromLStr.bind(@)

    strCompress: (str,patterns,none='_')->

        for key,{prefix='',suffix='',rgx,compFn} of patterns
            log "strCompress for '#{str}' got prefix=#{prefix} #{isPrefix str,prefix} suffix= #{suffix} #{isSuffix str,suffix}"
            switch
                when rgx?.test str
                    return key+compFn str,key,rgx
                when (prefix or suffix) and (isPrefix str,prefix) and (isSuffix str,suffix)
                    str= str.slice prefix.length,(-suffix.length or str.length)
                    str= compFn str,key,prefix,suffix
                    return key+str
        if none
             none+@btoauUtf str
        else null


    strExpand: strExpand= (str,patterns,none='_')->
        if (key=str[0]) of patterns
            {prefix='',suffix='',rgx,expdFn} =patterns[key]
            str= str.slice key.length
            str= expdFn str,key,prefix,suffix,rgx   if expdFn
            prefix+str+suffix
        else if vx.isNODE and key is none
            @atobuUtf str.slice none.length
        else str



    parseFromJSON: (json,clss=Global.vx or Global)->
        JSON.parse json,(k,v)->
            if v and (cls=v.__cls) and cls of clss
                #log "parseFromJSON hit on #{cls}"
                clss[cls] v
            else v


    multiJSON:
        if false and vx.isNODE
            (path,{chunk,end,parser=JSON.parse,err=(err)->throw err}={})->
                lines=[]
                ## Example: Read File Stream Line-by-Line
                rl= readline.createInterface
                    input: fs.createReadStream path
                    historySize: 0
                rl.on 'line',(line)->
                    return if not line and not lines.length #Ignore nothing todo
                    if line
                        lines.push line
                    else
                        # We have a chunk
                        ret= parser lines.join('\n')
                        lines.length= 0
                        chunk ret if chunk
                    null
                rl.on 'close',->
                    if lines.length
                        ret= parser lines.join('\n')
                        lines.length= 0
                        chunk ret if chunk
                    end() if end
                rl.on 'error',err

        else
            (path,{chunk,end,parser=JSON.parse,err=(err)->throw err}={})->
                fs.readFile path,(error,data)->
                    return err error if error
                    mjsons= data.toString().split '\n\n'
                    for mjson in mjsons when mjson
                        ret= parser mjson
                        chunk ret if chunk
                    end() if end




    isJSONRegex: /^[\{\[\-0-9tfn\"]*[\}\]\.0-9el\"]$/ # Object Array number true false null string //

    isJSON: (str)-> (str[0] of JSONStart ) and ( (aLast str) of JSONEnd ) # good guest

    isJSONuRegex: new RegExp startRegex= "^[#{asRegexChars Starters}]"
    #log "Startregec= #{startRegex}"

    asJSONu: (obj,lookup=makeIdxLookup(),out=[])->
        log "as jSONu for\n#{JSON.stringify obj}"
        @asJSONuArr obj,out,lookup
        out.push Null
        out.join ''

    asJSONuArr: (obj,out=[],lookup=makeIdxLookup())->
        if lookup.has obj
            out.push lookup.get obj
        else switch typeof obj
            when 'number'  then out.push @asJSONuNumber obj
            when 'string'  then out.push @asJSONuString obj,lookup
            when 'object' then switch
                when Array.isArray obj
                    out.push ArrayStart
                    for o in obj
                        @asJSONuArr o,out,lookup
                    out.push ArrayEnd
                else #object
                    out.push ArrayStart #ObjStart
                    for own k,v of obj when v isnt undefined and typeof v isnt 'function' #JSON ignores undefineds in object values
                        @asJSONuArr k,out,lookup
                        @asJSONuArr v,out,lookup
                    out.push ObjEnd
            when 'function' then out.push null
            else log "asJSONuArr unknown type #{typeof obj} for obj=",obj
        out


    asJSONuNumber: (num)->
        # Asume not 0,1,-1,NaN,+I and -I d
        if num>=0
             c=Plus
        else
            c=Minus
            num=-1*num

        if !Number.isInteger num
            c+=Float
        c+@numToB64u num



    addQSCompressors: (compressors)->
        for k,info of compressors
            if (B64.indexOf k)<0
                #log "Persisntance.addQSCompressors ignore #{k} not a valide key not in B64"
                continue

            if k of QSCompressors
                #log "Persisntance.addQSCompressors ignore #{k} alreay used",{k,current:QSCompressors[k],ignored:info}
                continue
            #log "Persisntance.addQSCompressors added key #{k}"
            QSCompressors[k]=info

    asJSONuString: (str,lookup,compressors=QSCompressors)->
        return lookup.get str if lookup.has str

        if compressors and comp= @strCompress str,compressors,false
             estr= CompressedString+comp
        else estr= @btoauUtf str

        lookup.set str, Ref+ @intToB64u lookup.seen++
        Str+estr


    btoauUtf: (str)-> @upatch @btoaUtf str

    upatch: (str)-> str.replace(/\+/g,'_').replace /\//g,'-'

    fromJSONuString: fromJSONuString= (str,compressors=QSCompressors)->
        return str if !str
        if compressors and str[0] is CompressedString
             strExpand (str.slice 1),compressors,false
        else atobuUtf str

    atobuUtf: atobuUtf= (str)-> atobUtf deupatch str

    deupatch: deupatch= (str)-> str.replace(/\_/g,'+').replace /\-/g,'/'

    numToB64u: (number,digits=B64)->
        return @intToB64u number if Number.isInteger number
        parts= number.toString().split /([.e])/
        for n,i in parts by 2
            if n[0] is '-'
                n=n.substr 1
                parts[i-1]=')'
            else if n[0] is '+'
                parts[i-1]='('
            parts[i]= @intToB64u parseInt n
        parts.join ''

    intToB64u: intToB64u= (number,digits=B64)->
            base= digits.length
            residual = Math.round number
            result= []
            while residual isnt 0
                rixit = residual % base
                result.unshift digits.charAt rixit
                residual = Math.floor residual/base
            result.join ''


    b64uToNum: b64uToNum= (str,base=64,digits=B64lookup)->
        parts= str.split /([.\(\)])/ #TODO make this var sensitive
        return b64uToInt parts[0] if parts.length is 1
        for n,i in parts by 2
            parts[i]= b64uToInt n
            x= parts[i-1]
            switch x
                when '(' then parts[i-1]='e+'
                when ')' then parts[i-1]='e-'
        parseFloat parts.join ''

    b64uToInt: b64uToInt= (str,base=64,digits=B64lookup)->
        result=0
        for c in str
            result=(result*base)+digits[c]
        result

    ###
    from: https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
    ###

    btoaUtf: (str)-> (btoa unescape encodeURIComponent str).replace /=+$/,''

    atobUtf:  atobUtf= (str)-> decodeURIComponent escape atob str

    fromJSONu: (str,lookup=makeLookup(),ret=[])->
        last=
        if str.length>1 and str.slice -1 is Null
            str= str.slice 0,-1

        @fromJSONuArr (str.split RegSplit),ret,lookup



    fromJSONuArr: (arr,ret=[],lookup=makeLookup())->

        stack=[]
        # log "fromJSONuArr=",arr
        for item in arr when item
            if lookup.has item
                ret.push lookup.get item
            else
                c= item[0]
                switch
                    when c of actionsF then ret.push actionsF[c] item,lookup
                    when c of actionsR then ret= actionsR[c]  stack,ret
                    else log "not action for #{item}"
        if ret.length>1
             ret
        else ret[0]




p= Persistance

#for prop,fnc of p when typeof fnc is 'function'
for prop in (oAllPropNames p) when prop isnt '__super__' and prop isnt 'constructor' and (typeof fnc=p[prop]) is 'function'
    p[prop]=fnc.bind p

module.exports= Persistance
###

QS OK
    ! -> ! # bool
    _ -> _ # used in base64u instead of +

    ~ -> ~ # null
    ( -> (
    ) -> )if (!module.exports) {
  log("assigning {} to exports");
  module.exports = {};
}

oAssign(module.exports, p);

    * -> * #infinity as part of a number
    , -> , #+number
    - -> - #-number
    / -> / # useby base64
    ^ -> ^ # for Base64u floats
    . -> . for Base64u floats
    | -> | # 1
    @ -> @ # 0
    ; -> ; #NaN


    : -> :


    [ -> [ # array
    \ -> \ # not used cause escape in js
    ] -> ] # array


    ` -> ` #string
    { -> { #object

    } -> } #object




 !()*,-.:;@[]\^_`{}|~

 test:  !()*,-.:;@[]\^_`{}|~/\éà+ '✓'"

 finally not escaped is !()*,-.:;/_~ and [] most of the time ...

 new mapping

 -_ used in b64

 array  ()
 object (!

number :->+ ;->+  .->decimal in float [->e+ ]->e-
empty number is 0
float has leading dot is :.x.y('['|']')z

string ,

null ~

* refs->

    wellknown refs
    empty 1
    A ( 0 b64 ) false
    B ( 1 ) true
    C NaN
    D +Infinity
    E -Infinity
    F undefined (not used)
    a+ (25) viewed strings
    )




true
false

0
1
+number
-number
numberfraction
number exponent
nan
Infinity

null

[] array
{} object

string


###
#btoa(encodeURIComponent('✓ à la mode'))
