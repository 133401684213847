{ log, sList, sSet, oA, nr, time:getTime, setTimeout2, meta, Global }= Mod= vx= require 'vx/globals/Boot'
{ oDiffs, oAssign, time:getTime, isPrefix, aSum, aPairs, aLast, sSplit, sSplit1 }= Mod

require './CircuitsUI.css'

{ shortDate2 }= require 'vx/tools/DateUtils'

wait= require 'vx/tools/Wait'

PR= require 'vx/tools/Persistance'

{ getV2EncodedState, getV1EncodedState }= require 'vx/tools/URLencoders'

{ UIComponent }= UI= require 'vx/UI'

{ table, tbody, th, tr, td, span, form, button, group, div, a, strong, p, img, h3 }= UI

{ menu, listItem, detailStart, detailText }= UI
{ polyline  }= UI

{ templateListWidget, templateWidget } =require 'vx/templates/TemplateWidgets'
require 'vx/templates/circuit-hover.css'
require 'vx/templates/circuit-info.css'
require 'vx/templates/circuit-list.css'
require 'vx/templates/filtre-selector.css'


{ say, getLang }= require 'vx/i18n/I18n'
Lang= getLang()


{ LineStr }= require 'vx/math/LineStr'
{ Bounds }= require 'vx/math/Bounds'


{ abs }= Math

C={} # Constant name space

CircuitsSVGScale= Scale= 1e5
Digits=5

circuitPathSVG= meta class CircuitPathSVG extends UIComponent
    @displayName: 'CircuitPathSVG'

    #props
    #  circuit: {Circuit}
    #  className: string
    #  map: {Map}
    #  graph: {Graph5}


    getLineStr: -> @_lineStr?= @makeLineStr() # TODO regen if this changes
        #@makeLineStr()


    getSVGLatMPts: (zoom)->
        switch
            when zoom<10
                 @_linePts8?= (LineStr._ @props.circuit.pts8,@props.lineStrOps).asSVGLatMPts Scale,Digits
            when zoom<13
                 @_linePts12?=(LineStr._ @props.circuit.pts12,@props.lineStrOps).asSVGLatMPts Scale,Digits
            else @_linePts?=  (LineStr._ @props.circuit.pts,  @props.lineStrOps).asSVGLatMPts Scale,Digits



    render: (props=@props)->

        { deco,circuit,zoom,onMouseEnter,onMouseLeave,onClick }= props #graph
        {id,Titre,membre}= circuit
        #return null if !graph

        cName=["circuit-path-svg circuit-path-svg-#{circuit.data?.genre_circuit?.toLowerCase()?.replace(/\s+/,'_') or ''}"]
        cName.push @props.ClassName if @props.ClassName
        cName.push "circuit-path-svg--usemouse" if onMouseEnter or onMouseLeave or onClick


        hasDeco= false
        for decoName,v of deco or {} when v
            hasDeco= true
            cName.push decoName

        className= cName.join ' '
        #log "rendering #{circuit.id} className=#{className}"
        polyline
            className:className
            id:"pl_#{@props.circuit.id.replace /\./g,'_'}"
            #'.selected':deco?.selected
            points: @getSVGLatMPts zoom #if hasDeco then @getSVGLatMPts() #?.asSVGLatMPts() else ''
            onMouseEnter: onMouseEnter
            onMouseLeave: onMouseLeave
            onClickCapture:->log "mapsvg polyline clickcapture"
            onClick:(e)->
                log "mapsvg polyline click",{onClick,e,target:e.target}
                onClick e
            'data-objid': id
            'data-nom': Titre
            'data-region':membre

    zoomCircuit:(zoomMode)->
        bnds= Bounds.BNDS @props.circuit.bounds
        @props.app?.fitMapBounds bnds

    componentWillMount: ->
            if @props.zoomMode # Wicked, it here becuase we know we have to zoom before the map is loaded ... TODO setWantedBounds on map ....
                @zoomCircuit @props.zoomMode




circuitsSVGLayer= meta class CircuitsSVGLayer extends UIComponent
    @displayName: 'CircuitsSVGLayer'

    render:(props=@props)->


        {circuits,decos,zoom,graph,zoomMode,app,MapWidgets,gmapMap,leafletMap,id='1',zIndex,setHoverId,onPathClick}= props

        { mapSVG, mapHTML }= MapWidgets

        d=0

        mapSVG '.circuitssvglayer',
            gmapMap: gmapMap
            leafletMap: leafletMap
            id: "circuits-svg#{id}"
            # bounds= 51.13286649118823,-76.1493193816871 54.505918103161974,-73.7303911836244  " -->
            topl:    d+  50.5
            bottoml:-d+  45
            left:  -d+ -79.9
            right:  d+ -62.8
            scale: Scale
            zIndex: zIndex
            zoom: zoom
            for c in circuits #when (cdeco= decos[c.id]) and (cdeco.visible or cdeco.selected)
                cdeco= decos[c.id]
                circuitPathSVG
                    key: c.id
                    circuit: c
                    deco: cdeco
                    #graph: graph
                    app:app # its a constante ...
                    zoomMode: zoomMode if cdeco?.selected
                    zoom: zoom
                    onMouseEnter: @doStartHover if setHoverId
                    onMouseLeave: @doEndHover   if setHoverId
                    onClick:      @doClick      if onPathClick



    doStartHover: (e)->
        id= e.target?.dataset.objid
        #log "circuitsSVGLayer doStartHover id=#{id} setHoverId=#{typeof @props.setHoverId}"
        return if !id or !@props.setHoverId
        @props.setHoverId id,1

    doEndHover: (e)->
        id= e.target?.dataset.objid
        #log "circuitsSVGLayer doEndHover id=#{id} setHoverId=#{typeof @props.setHoverId}",f:@props.setHoverId
        return if !id or !@props.setHoverId
        @props.setHoverId id,0

    doClick: (e)->
        #log "got a click"
        id= e.target?.dataset.objid
        return if !id or !(typeof @props.onPathClick is 'function')
        @props.onPathClick id








circuitsMarkerLayer= meta class CircuitsMarkerLayer extends UIComponent
    @displayName: 'CircuitsMarkerLayer'

    render:(props=@props)->


        {circuits,decos,MapWidgets,gmapMap,leafletMap,id='1',setHoverId,onMarkerClick,visible=true,zIndex,markerPos}= props

        { mapSVG, mapHTML }= MapWidgets

        d=0

        mapHTML '.circuitsmarkerlayer',
            gmapMap: gmapMap
            leafletMap: leafletMap
            id:"circuits-icons#{id}"
            visible: visible
            top:47.1,bottom:47,left:-70,right:-55,
            zIndex: zIndex
            for c in circuits #when (cdeco= decos[c.id]) and (cdeco.visible or cdeco.selected)
                circuitMarker
                    circuit: c
                    decos:   decos
                    markerPos: markerPos
                    onMouseEnter: @doStartHover if setHoverId
                    onMouseLeave: @doEndHover   if setHoverId
                    onClick:      @doClick      if onMarkerClick



    doStartHover: (e)->
        id= e.target?.dataset.objid
        #log "circuitsSVGLayer doStartHover id=#{id} setHoverId=#{typeof @props.setHoverId}"
        return if !id or !@props.setHoverId
        @props.setHoverId id,1

    doEndHover: (e)->
        id= e.target?.dataset.objid
        #log "circuitsSVGLayer doEndHover id=#{id} setHoverId=#{typeof @props.setHoverId}",f:@props.setHoverId
        return if !id or !@props.setHoverId
        @props.setHoverId id,0

    doClick: (e)->
        #log "got a click"
        id= e.target?.dataset.objid
        return if !id or !(typeof @props.onMarkerClick is 'function')
        @props.onMarkerClick id


circuitMarker= (props)->
    { circuit, decos,onMouseEnter, onMouseLeave, onClick,ops,markerPos='start' }= props
    {id,Titre,membre}= circuit
    cdeco= decos[id]
    markerPt= switch markerPos
        when 'start'  then circuit.pts[0]
        when 'end'    then circuit.pts[circuit.pts.length-1]
        when 'mid'    then circuit.pts[Math.round circuit.pts.length/2]
        when 'center' then circuit.bounds.center()
        else circuit.waypoints[0]

    #log "markerPos=",{circuit,markerPt,markerPos,len:circuit.pts.length,mid:Math.round circuit.pts.length/2}
    cName= ['circuit-start-icon']
    for decoName,v of cdeco or {} when v
        cName.push decoName

    marker= div
        className: cName.join ' '
        position:
            bottom: markerPt.y
            left:   markerPt.x
        key: id
        'data-objid': id
        'data-nom': Titre
        'data-region':membre
        onMouseEnter: onMouseEnter if onMouseEnter
        onMouseLeave: onMouseLeave if onMouseLeave
        onClick:  (e)->
            #log "maphtml circuit marker click",{e,onClick,target:e.target}
            onClick e


    return marker if !(cdeco?.hover == 1)

    [ marker ,
        templateWidget '.circuit-start-icon__hover',
            componentClassName: 'circuit-hover'
            obj: circuit
            deco: cdeco
            ops: ops
            say: props.say or say
            key: "#{id}-hover"
            $zIndex: 99999999
            position:
                bottom: markerPt.y
                left:   markerPt.x
    ]



circuitsListCustom= meta class CircuitsListCustom extends UIComponent
    @displayName: 'CircuitsListCustom'

    render:(props=@props)->

        {circuits=[],isOpened=false,app,decos,filtered,membre,setHoverId}= props

        templateListWidget
            componentClassName: 'circuit-list'
            doUpdateFiltre: @doUpdateFiltre
            list:  circuits
            decos: decos
            toggleCircuitVisibility: @doToggleCircuitVisibility
            doSelect: @doSelect
            doOpenFiltre: @doOpenFiltre
            doHover: @doHover if setHoverId
            doClose: @doClose
            #doZoomToSelection: app.doCircuitZoomToSelection
            itemClassName:(obj,deco,ops)->
                #log "CircuitsListCustom.itemClassName id=#{obj.id} notvisible=#{ ops?.filtered and !deco?.filtered}",{obj,deco,ops}
                if ops?.filtered and !deco?.filtered
                    "circuit-list__item--notvisible"
            ops: {filtered,region:membre}
            say: props.say or say


    doHover: (obj,mode)->
        #log "circuitlistcustome setDecoration hover on #{obj?.id}  to #{mode} "
        return if !obj?.id or !@props.setHoverId
        #log "circuitlistcustome setDecoration hover on #{obj.id}  to #{mode}"
        @props.setHoverId obj.id,if mode then 2 else undefined


    doOpenFiltre: ->
        #log "Open filtre"
        @props.app?.toggleCircuitsFiltreVisibility()


    doClose: (e)-> @props.app?.toggleCircuitsList()

    doToggleCircuitVisibility: (circuit,{objid}={})->
        if objid
            @props.app.toggleCircuitVisibility objid


    doSelect: (circuit,{objid}={})->

        #log "CircuitsList3.doCardClick circuit=",circuit

        return if not circuit

        return if not app=@props.app

        #app.fitMapBounds Bounds.BNDS circuit.bounds
        app.setCircuitSelectedId circuit.id,1


    doUpdateFiltre: (obj,info)->
        { name,value}=info
        #log "CiruitCustomList doUpdateFiltre name=#{name} value=#{value}"
        return if !name? # no name value pair

        #log "Update filtre name=#{name} value=#{value}  obj=",{obj,info}
        @props.app.setCircuitsFiltreValue name,value



saveAsFile= (filename, contentType='application/octet-stream',content)->

        a= document.createElement 'a'
        blob= new Blob [content],type:contentType
        a.href = window.URL.createObjectURL blob
        a.download= filename
        a.click()



circuitInfo= meta class CircuitInfo extends UIComponent
    @displayName: 'CircuitInfo'

    render: (props=@props)->
        { onClose:doClose, circuitId, app, deco, ops }= props
        obj= app.getCircuitId circuitId
        templateWidget
            componentClassName: 'circuit-info'
            obj: obj
            deco: deco
            ops: ops
            container: @
            say: props.say or say

    doClose: (obj)-> @props.onClose obj if @props.onClose

    doNavigate: (obj,ops)->
        #log " got a cicuit info navigate ",{obj,ops}
        ops.e?.preventDefault()
        newLoc= Global.location.origin+Global.location.pathname+"?r=2&s=#{obj.encodedState}"
        #log "edit circuit doin preventDefault ",{newLoc,obj,ops}
        window.location= newLoc


    doNavGoogle: (circuit,ops)->
        return if !circuit

        waypointsArr= ("#{lat.toFixed 6},#{lng.toFixed 6}" for {lat,lng} in circuit.waypoints[..-1])
        end= waypointsArr.pop()
        waypoints= waypointsArr.join '|'

        newLoc= "https://www.google.com/maps/dir/?api=1&origin=current+location&destination=#{end}&travelmode=driving&hl=#{Lang}&waypoints=#{waypoints}" #&avoid=h
        #log "doNavGoogle circuit doin preventDefault ",{newLoc,circuit,ops,@props}
        window.open newLoc,'_blank'


    doZoomToCircuit: ->
        {app,circuitId}= @props
        circuit= app.getCircuitId circuitId
        if app.state.vxScreenWidth isnt 'wide'
            app.setCircuitSelectedId ''
            app.toggleCircuitsList() if app.state.circuitsListVisible
            setTimeout (->app.zoomCircuits [circuit]),400 # wait for windows to close
        else
            app.zoomCircuits [circuit]

    doExportGPX: (circuit)->
        return if !circuit

        saveAsFile (fname="#{circuit.Titre.replace /\s+/g,'_'}.gpx"),'application/gpx+xml',"""
<?xml version="1.0" encoding="UTF-8"?>
<gpx xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:gpxdata="http://www.cluetrust.com/XML/GPXDATA/1/0" xmlns="http://www.topografix.com/GPX/1/0" xsi:schemaLocation="http://www.topografix.com/GPX/1/0 http://www.topografix.com/GPX/1/0/gpx.xsd http://www.cluetrust.com/XML/GPXDATA/1/0 http://www.cluetrust.com/Schemas/gpxdata10.xsd" version="1.0" creator="http://ridewithgps.com/">
  <author>FCMQ inc</author>
  <url>#{document.referrer or window.location}</url>
  <time>#{new Date}</time>
  <trk>
    <name>#{circuit.Titre}</name>
    <trkseg>
      #{ ("<trkpt lat=\"#{y.toFixed 6}\" lon=\"#{x.toFixed 6}\"></trkpt>" for {x,y} in circuit.pts ).join '\n      '}
    </trkseg>
  </trk>
</gpx>
"""

        mess=say "GPX downloaded",{fname}
        #log "app=#{!!@props.app} app.snackbarMessage=#{!!@props.app?.snackbarMessage} mess=#{mess}"
        @props.app?.snackbarMessage message: mess



    editCircuit: (obj,ops)->
        ops.e?.preventDefault()
        newLoc= Global.location.origin+Global.location.pathname+"?r=2&s=#{obj.encodedState}"
        #log "edit circuit doin preventDefault ",{newLoc,obj,ops}
        window.location= newLoc


circuitsFiltreUI= meta class CircuitsFiltreUI extends UIComponent
    @displayName: 'CircuitsFiltreUI'

    render: (props=@props)->
        { circuitId, app, filter, ops }= props
        templateWidget
            componentClassName: 'filtre-selector'
            obj: filter or {}
            deco: {}
            ops: ops
            container: @


    doSetUnits: (obj,info)->
        #log "CircuitsFiltreUI.doUpdateFiltre @props.onChange #{!!@props.onChange} info=#{info} obj=#{obj} name=#{info?.name} value=#{info?.value}"
        return if !info?.value # no name value pair
        @props.app.setDistUnit  info?.value


    doClose: (e)->
        #log "CircuitsFiltreUI.doClose e=",e
        if @props.onClose
             @props.onClose e
        else @props.app?.toggleCircuitsFiltreVisibility()

    doUpdateFiltre: (obj,info)->
        #log "CircuitsFiltreUI.doUpdateFiltre @props.onChange #{!!@props.onChange} info=#{info} obj=#{obj} name=#{info?.name}"
        if @props.onChange
            return @props.onChange obj,info
        { name,value}=info
        return if !name? # no name value pair
        if obj[name] is value
            #log " no change for #{name} = #{value}"
            return

        if value is 'All'
            value=undefined

        #log "Update filtre name=#{name} value=#{value}  obj=",{obj,info}
        @props.app.doSetCircuitsFiltreValue name,value



    doUpdateFiltre2: (obj,info)->
        #log "Update filtre 2"
        @doUpdateFiltre obj,info



circuitsDecorator= meta class CircuitsDecorator extends UIComponent
    @displayName: 'CircuitsDecorator'

    render: ->
        #log "CircuitsDecorator.render"
        null

    componentDidMount: ->
        @componentDidUpdate {},{} # @props,@state Not sure ...

    componentDidUpdate: (prevProps,prevState)-> #? still the case in r16
        props=@props
         # TODO allow decos as property without recursive loop
        { list, fnc, app, decos, decoName='circuitsDecorations', idProp='id', filter }= props
        return null if !list or !filter
        if !decos?
            decos= app.state[decoName] # we dont want decos as a prop or this will loop since it changes decos ...

        changes=null
        changeCnt= 0
        for item in list
            itemDecos=decos[itemId=item[idProp]]
            if vals= fnc item,filter,itemDecos,props #carefull cant depend on decos cause not a prop wont reevalute if it changes ...
                itemChanges= null
                for valName,val of vals when val isnt itemDecos?[valName]
                    itemChanges?= {}
                    itemChanges[valName]= val
                if itemChanges
                    changeCnt++
                    changes?= {}
                    changes[itemId]= oA {},itemDecos,itemChanges
        if changes
            log "CircuitDecorator got #{changeCnt} changes" if vx.isDEV
            newDecos= oA {},decos,changes
            app.setState "#{decoName}":newDecos
        else
             log "CircuitDecorator gotno changes" if vx.isDEV


module.exports= { circuitsSVGLayer, circuitsMarkerLayer, circuitsListCustom, circuitInfo, circuitsFiltreUI, circuitsDecorator, CircuitsSVGScale }
