{ meta, log, sList, sSet, nr, time:getTime }= Mod= vx= require 'vx/globals/Boot'
{ oDiffs, oAssign, time:getTime, isPrefix, aSum, aPairs, aLast, sSplit, sSplit1 }= Mod

require 'vx/tiles/PMNDrawerCanvas.css'

{ PancartesDrawer }= require 'vx/tiles/PMNPancartesDrawer'

{ UIComponent }= UI= require 'vx/UI'


{ span, div, br, table, tbody, tr, td, th, ul, li }= UI
{ svg, path, g, line, canvas }= UI

{ sayModule }= require 'vx/i18n/I18n'
say= sayModule 'vx/graph/GraphWidgets'

{ Zoom2ZoomCls, doRender2Ctx, LineStyles }= require 'vx/tiles/PMNDrawerCanvasCtx'
#{ LineColors, LinesWidths, LineDashes }= LineStyles

meta class PmnDrawer extends UIComponent
    #This is not a real Compnent but we want to associate CSS to the render method ....
    # Css also used by pmnlegend

    _className: 'rpmntile3'

    # TODO build from LineStyles

    render: (ops)->

        {tileSize,zoom,content,tileId,info}= ops

        if content?.length and info?.pancartes and ( !info.pancartesMinZoom? or info.pancartesMinZoom<=zoom)
            pancartes= PancartesDrawer::renderOut [],ops

        tileCanvas= canvasPMN ops if content?.length and ( !info.nolines? or !info.nolines)

        ret= div '.rpmntile3__tile .mdc-typography--body1',$width:"#{tileSize}px",$height:"#{tileSize}px",'data-tileid':tileId,
            tileCanvas
            pancartes

        ret



pmnDrawer= PmnDrawer::render.bind PmnDrawer::



canvasPMN= meta class CanvasPMN extends UIComponent


    doGotCanvas: (canvasNode)->
        #log "doGotCanvas canvas=",canvasNode
        @canvas= canvasNode
        return if not canvasNode
        @frameWaiting= requestAnimationFrame @doRender2Canvas
        canvasNode

    doRender2CanvasClear:(timeStamp,canvasNode=@canvas,props=@props)->
        @doRender2Canvas timeStamp,canvasNode,props,true # clear

    doRender2Canvas:(timeStamp,canvasNode=@canvas,props=@props,clear)->
      @frameWaiting= false
      #log "doRender2Canvas  clear=#{!!clear} canvas=",{canvasNode,x,y,zoom,self:@}
      return if not canvasNode

      try #cause in async fnc
        ctx= canvasNode.getContext '2d'
        @doRender2Ctx ctx,props,clear
      catch err
        console.trace()
        console.error err


    doRender2Ctx: doRender2Ctx



    render: (ops=@props)->
        if @frameWaiting
            cancelAnimationFrame @frameWaiting
            @frameWaiting= null
        {tileSize,tileId}= @props
        canvas '$position=absolute',
            ref:@doGotCanvas
            'data-tileid':tileId
            $width: "#{tileSize}px"
            $height:"#{tileSize}px"
            height:tileSize
            width:tileSize

    componentDidUpdate:(oldProps)->
        if oldProps.content isnt @props.content
            #this iwll alwsy be true cause content is a query TODO ???
            if !@frameWaiting and @canvas
                #log "ok a content update redraw",{oc:oldProps.content,nc:@props.content}
                @frameWaiting= requestAnimationFrame @doRender2CanvasClear




module.exports= {pmnDrawer,  CanvasPMN:CanvasPMN:: } # pmnLegend,
