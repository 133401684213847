
require './vxIcon.css'

{ ElementFactory  }= require './ReactElemSS'
{ i }= require './DOMElements'

s2lcname= (str)->str.toLowerCase().replace /\s+/g,'-'

vxIcon= ElementFactory (props)-> i '.vx-icon',
        __proto__: props
        _iconsCls: "vx-icon-#{s2lcname props.children or 'empty'}"
        _clickableCls: ( 'vx-icon-clickable' if props.onClick)

        bkgColor: undefined
        $backgroundColor: "var(--mdc-theme-#{props.bkgColor})" if props.bkgColor

        elevation: undefined
        flat: undefined

        _elevation:"mdc-elevation--z#{props.elevation ? ( if props.flat then 0 else 4)}"

        children: undefined

module.exports= { vxIcon, vxIconClassName:'vx-icon' }

