{ log, isArray }= vx= require 'vx/globals/Boot' # to get a global ref to HelloWorld that survies webpack

{ createElement, Fragment }= require 'react'

{ gpsManager }= require 'vx/comp/GPS' if vx.use.gps
{ circuitsDecorator }= require 'vx/comp/circuits/CircuitsUI'  if vx.use.circuits # circuitsList, circuitsList3,
{ segFinder}= require 'vx/graph/GraphWidgets' if vx.use.itin
# I18n
{ sayModule, getLang }= require 'vx/i18n/I18n'
say= sayModule 'App' # cheat use app for index and appIndex ModuleName


# iconito client in http patch 

C={}

AppHelp= ({app,deviceReady,circuits,circuitsFilter,bkgMenuPosition,graph})->

    createElement Fragment,null,

        if vx.use.gps
            gpsManager
                key:'gpsmanager'
                ref:  app.setGPSManager
                enabled: true
                onNewValue: app.setGPSPos
                deviceReady: deviceReady
                updateInterval: 10
                
        if vx.use.circuits
            circuitsDecorator
                key:'circuitsDecorator'
                app:app
                decosName: 'circuitsDecorations' # dont depend on this
                list:   circuits
                filter: circuitsFilter
                fnc: C.cfFnc?= (circuit,filtre)->
                    for k,v of filtre when v?
                        switch k
                            when 'dist'
                                if circuit.dist < v[0] or circuit.dist > v[1]
                                    #log " circuit filtre #{circuit.id} miss on #{k} min=#{v[0]} max=#{v[1]} dist=#{circuit.dist}"
                                    return filtered:undefined
                            else
                                if k[0] is '.'
                                        kv= circuit.data?[k.substr 1]
                                else kv= circuit[k]
                                if isArray v
                                    if (i=v.indexOf kv) is -1
                                        #log " circuit filtre  #{circuit.id} miss on #{k}=#{JSON.stringify kv} isnt in array #{JSON.stringify v}"
                                        return filtered:undefined
                                else
                                    if v isnt kv
                                        #log " circuit filtre  #{circuit.id} miss on #{k}=#{kv} isnt #{v}"
                                        return filtered:undefined

                    #log " circuit filtre  hit on #{circuit.id} "
                    filtered:1


        if vx.use.itin
            segFinder
                key:'segFinder'
                onNewValue: app.setSelectedSeg
                pt:     bkgMenuPosition
                graph:  graph
                name:   'clickedseg'


module.exports= { AppHelp, appHelp:AppHelp }

# RECORDER STUFF
#
# { recorder }= require 'vx/comp/traces/Recorder' if vx.use.recorder
# { traceManager, tracesTable,  tracesTable3, tracesTable4,  traceEditForm }= require 'vx/comp/traces/Traces' if vx.use.traces
# After gpsManager # never used for motoneige
#
#             if vx.use.recorder
#                 recorder
#                     pos:@getGPSPos()
#                     recording: @getRecording()
#                     onNewValue:@setRecordedTrack
#                     onStart:  @doCreateRecTrace
#                     onUpdate: @doUpdateRecTrace
#                     updateInterval: 30
#                     onStop:   @doEndRecTrace
# 
#             if vx.use.traces
#                 traceManager
#                     onNewValue:@setTraces
#                     onUpdate:  @doCheckTrace
#                     ref:       @setTraceManager
#                     user:      @getUser()

