{ log, meta, Global, asJSON, asJS, arr, isArray, isObj, isPrefix, oAssign, oA, oEquals, fromJS, sSet, sList, $, oAllPropNames }= I= vx= require 'vx/globals/Boot'


require './BasicUIApp.css'

{ parseQS, parseUri }= require 'vx/tools/URLTools'


#{ shallowEqual }= require 'vx/react/ReactBase'
shallowEqual= oEquals

{ UIComponent }= require './UIComponent'


{Promise}= require 'vx/tools/NamedPromises'

{ main }= require './DOMElements'

# getGloabls functions





hints=
    'Android':'android'
    'android':'addriod'
    'iPhone': 'ios'
    'iPad':   'ios'
    'iphone': 'ios'
    'ipad':   'ios'
    'Windos': 'win'
    'OS X':   'osx'
    'Linux':  'linux'
    'linux':  'linux'

if !vx.isNODE
    { render, findDOMNode, unmountComponentAtNode }= require 'react-dom'
    vx.bootReact= (widget,props,sel='#react-container')->
        #log "start Boot React vx.q?.rootClass=#{vx.q?.rootClass}",{widget,props,sel}
        # Ok do real init stuf here
        #document.onDomReady->
        if document? and doc=($ 'html')
            os='unknownplatform'
            if ua=navigator?.userAgent
                for hint,cls of hints when 0 <= ua.indexOf hint
                    os= cls
                    break
            doc.classList.add os
            doc.classList.add (if vx.isAPP then 'app' else 'web')
            doc.classList.add "vx-lang-#{lang}" if lang?
            if vx.q?.rootClass
                for cn in sList vx.q?.rootClass
                    doc.classList.add cn

        requestAnimationFrame -> #firefox iframe bug classes not set on :root
            $sel= $ sel
            wp= widget props
            render wp,$sel
            



localStorage= undefined
try
    localStorage= Global.localStorage
    lslen= localStorage.length
catch err
    localStorage= undefined
    
    
if !localStorage
    localStorage= # NOP
        setItem:->
        getItem:->null
        removeItem:->
        clear:->




meta class UIComponentWithState extends UIComponent


    constructor: (props)->
        super props
        @state?= {}


    set: (value,name,extra)->
        if typeof @[fname="set#{name[0].toUpperCase()}#{name[1..]}"] is 'function'
             @[fname] value,name,extra
        else
            (newState={})[name]=value
            @setState newState


    get: (name='')->
        if typeof @[fname="get#{name[0].toUpperCase()}#{name[1..]}"] is 'function'
             @[fname] name
        else @state[name]


    doSet: @::set

    doEventSet: (event)-> @set (fromJS event.target.value),event.target.name,event





meta class OrientedApp extends UIComponentWithState


    @displayName: 'OrientedApp'


    constructor:(props)->

        ret= (super props) ? @
        ret.getGlobals()
        return ret


    # **** Routing *****

    componentWillMount: ->
        rootStyle= getComputedStyle? document.documentElement
        breakPoints= @adjustCSSBreakPoints()

        breakPoints.networkState= 'unknown' # force onknowen offline until deviceready cause may have fule:refs if Global.navigator?.onLine then

        @setState breakPoints


    adjustCSSBreakPoints:(resp={})->
        rootStyle= getComputedStyle? $ ':root'
        oA resp,
            rootFontSize: (parseFloat rootStyle?.fontSize) or 16
            horizBreakPoints: vx.hBreakPoints=
                compact: (parseFloat rootStyle?.getPropertyValue '--vx-w-compact') or 480  # small phone portait
                narrow:  (parseFloat rootStyle?.getPropertyValue '--vx-w-narrow')  or 640  # phone portait
                medium:  (parseFloat rootStyle?.getPropertyValue '--vx-w-medium')  or 800  # phone lanscape/tablet portait
                wide:    (parseFloat rootStyle?.getPropertyValue '--vx-w-wide')  or 99999  # tablet lanscape/ screen

        resp


    getGlobals: ->
        # cant enumrate cutome proprties for now so instead loop over globals testing existance of proprty
        #log "getGlobals getComputedStyle=#{getComputedStyle?}"
        return if !getComputedStyle?
        root= $ ':root'
        return if !root
        #styler= getComputedStyle root
        style= getComputedStyle root,'::before'
        if !style
            log "getGlobals no style found for :root::before",{root}
            return
        

        # notch detection
        if true
            isIOS= root.classList.contains 'ios'
            # TODO we are lookinf at root::before this seams to work but would it be better just root ?
            notches= ( style.getPropertyValue "padding-#{n}" for n in sList 'top right bottom left' )

            rotation= window.orientation ?Global.screen?.rotation?

            log "notch detection screen resize rotation is #{rotation}"
            switch rotation
                when 90
                    [ nRight, nBottom, nLeft, nTop ]= notches
                    if isIOS
                        nBottom= nLeft
                        nLeft= 0
                when -90,270
                    [ nLeft, nTop, nRight, nBottom ]= notches
                    if isIOS
                        nBottom= nRight
                        nnRight= 0
                else
                    [ nTop, nRight, nBottom, nLeft ]= notches



            log "Notches nTop, nRight, nBottom, nLeft =",[ nTop, nRight, nBottom, nLeft ]

            root.style.setProperty n,v for n,v of {
                '--notch-top':    '47px'
                '--notch-right':  nRight
                '--notch-bottom': '0px'
                '--notch-left':   nLeft
                }

        null



    componentDidMount: ->
        window.addEventListener    'resize',@doResize
        window.addEventListener    'orientationchange',@doRotation

        #OK apply urlState changes here

        (Promise.vxGet 'deviceready').then =>

            # connection
            if (nettype= navigator.connection?.type)?
                 #ok cordovae network presnet
                 log "BasicUIAapp deviceready cordova network prensent #{nettype} =",navigator.connection
                 newState= if nettype is Global.Connection?.NONE then 'offline' else 'online'
            else newState= if navigator.onLine then 'online' else 'offline'
            log "BasicUIAapp deviceready doint networkState=#{newState} was #{@state.networkState}"
            # this is a mess should use standard prop change functions
            if newState isnt @state.networkState
                log "------------ change networkState to #{newState}"
                switch newState
                    when 'online'  then @doOnline()
                    when 'offline' then @doOffline()
            document.addEventListener  'online',@doOnline
            document.addEventListener  'offline',@doOffline

            #deive and platform

            if Global.device
                htmlTag= $ 'html'
                if platform= Global.device.platform?.toLowerCase().trim().replace /\s+/g,'_'
                    htmlTag.classList.add platform
                if model= Global.device.model?.toLowerCase().split(',')[0].trim().replace /\s+/g,'_'
                    htmlTag.classList.add model
                    if model isnt mainModel= (model.split /\d/)[0].trim().replace /\s+/g,'_'
                        htmlTag.classList.add mainModel

        @doResize()

    componentWillUnmount: ->
        window.removeEventListener 'hashchange',@doHashChange
        window.removeEventListener 'resize',@doResize
        window.removeEventListener 'orientationchange',@doRotation
        document.removeEventListener  'online',@doOnline
        document.removeEventListener  'offline',@doOffline      
        

    # TODO: lets do a better transition than a reload .....

    doOnline: ->
        log "BasicUIApp going online from #{@state.networkState}"
        return if @state.networkState is 'online'
        
        @setState networkState: 'online'


    doOffline: ->
        log "BasicUIApp going offline from #{@state.networkState}"
        return if @state.networkState is 'offline'
        @setState networkState: 'offline'


    doResize:->
        {innerWidth:width,innerHeight:height}= window

        if width isnt @state.screenWidth or height isnt @state.screenHeight

            page= $ ':root'

            orientation= if width>height then 'landscape' else 'portrait'

            if orientation is 'landscape'
                page.classList.remove 'vx-portrait'
                page.classList.add    'vx-landscape'
            else
                page.classList.remove 'vx-landscape'
                page.classList.add    'vx-portrait'

            # TODO this should be in mds somewhere
            mdcScreenWidth= switch
                when width <= 480 then 'phone'
                when width <= 840 then 'tablet'
                else 'desktop'

            @doRotation()

            rWidth= width

            hBreaks= ( {name,val} for name,val of @state.horizBreakPoints ).sort (a,b)-> a.val-b.val

            for {name,val} in hBreaks when  rWidth <= val
                    vxScreenWidth= name
                    break
                # tablet lanscape/ screen

            #log "doing a resize to #{width} x #{height} @state.rootFontSize=#{@state.rootFontSize} mscScreenWidth is #{mdcScreenWidth} vx ScreenWidth is #{vxScreenWidth} rwidth=#{rWidth}",{hBreaks,sh:@state.horizBreakPoints}

            if @state.mdcScreenWidth isnt mdcScreenWidth
                page.classList.remove 'vx-mdc-phone','vx-mdc-tablet','vx-mdc-desktop'
                page.classList.add "vx-mdc-#{mdcScreenWidth}"

            if @state.vxScreenWidth isnt vxScreenWidth
                page.classList.remove.apply  page.classList,("vx-w-#{name}" for {name} in hBreaks)
                page.classList.add "vx-w-#{vxScreenWidth}"



            @setState
                screenWidth:  width
                rWidth:       rWidth
                screenHeight: height
                mdcScreenWidth:   mdcScreenWidth
                vxScreenWidth: vxScreenWidth
                orientation: orientation




    doRotation: ->

        page= $ ':root'

        rotation= window.orientation ? Global.screen?.orientation?.angle

        if rotation?
            #log "screen resize rotation is #{rotation} " #page=",page
            switch rotation
                when 90
                    page.classList.remove 'vx-leftside'
                    page.classList.add    'vx-rightside'
                when -90,270
                    page.classList.remove 'vx-rightside'
                    page.classList.add    'vx-leftside'
                else
                    page.classList.remove 'vx-rightside'
                    page.classList.remove 'vx-leftside'




meta class RoutedApp extends OrientedApp

    @displayName: 'RoutedApp'
        

    componentDidMount: ->
        super()
        @setPage Global.location?.hash[1..] or @props.defaultPage or @state.activePage or ''
        window.addEventListener    'hashchange',@doHashChange
        
    componentWillUnmount: ->
        super()
        window.removeEventListener 'hashchange',@doHashChange
 

    doHashChange:->
        pageId= Global.location.hash[1..]
        log "Hash change hash is #{Global.location.hash[1..]} page=#{pageId}"
        if pageId isnt @getPage()
            @setPage pageId
        else
            #log "RoutedApp.doHashChange Ignore  no change"
            null


    setPage: (page)->
        log "set page to #{page}"
        if Global.location.hash[1..] isnt page
            Global.location.hash= page
        @setState activePage:page

    doSetPage: @::setPage

    doSetPageEvent: (e)-> @setPage fromJS e.target.value

    doBack: -> Global.history?.back()

    getPage: -> @state.activePage



meta class PersistantApp extends RoutedApp # OrientedApp
    @displayName: 'PersistantApp'

    persistantStateName: vx.persistantStateName

    persistantState: vx.persistantState

    urlAPIVars:    vx.urlAPIVars

    refAPIVars:    vx.refAPIVars

    hashStateVars:   vx.hashStateVars

    defaultState:    vx.hashStateVars


    onMountDo: []

    onBeforeMountDo:[]


    constructor:(props)->

        ret= (super props) ? @
        ret.stateRestore()
        #log "end of PersistantApp constructor"
        return ret


    doStateClear: ->

        #log "DOING state clear"

        localStorage.clear()


    stateRestore: (stateName=@persistantStateName,props=@props,defaultState=@defaultState)->

        # No props or url management for now
        @state= oA {},@state, defaultState # @state should be undefined

        # TODO reviste stateLoad/Store function should recieve a stat obj instead of accessing @state
        for key of @persistantState
            #TODO do we need @stateLoad_#{key} or is onBeforeMount Ok?
            if @["stateLoad_#{key}"]
                 @["stateLoad_#{key}"] key,stateName
            else @stateLoad key,stateName
        #change state from urlAPI
        #oAssign @state,@persistantStateFromURL()  # @stateFromRefer(),@persistantStateFromURL(),@stateFromHash()
        @state


    persistantStateFromURL: ->
        qs= parseQS()
        urlState={}

        for k,v of qs when k of @persistantState or (k[0]=='!' and k=k.substr 1)
            urlState[k]= v
        #log "PersistantApp.persistantStateFromURL got ",{urlState,qs}

        oAssign @state,urlState


    stateFromFromRefer: -> null

    stateFromHash: ->


    componentDidUpdate:(prevProps, prevState)->
        for key of @persistantState when (val=@state[key]) isnt oldVal=prevState[key]
            # post state change, this is better than overiding @setState cause:
            # in theorie state chage will be agregated, also this should occure post rendering thus
            # wont slow repainting ...
            # also state changes passed as  functions (rare in my case) ave been evaluated here,
            # managing in a setState would be very complex ...
            # other option is cWillUpdate, but then we would slow the rendering loop ...

            #lets try a shallow equal
            continue if typeof val is 'object' and shallowEqual val,oldVal

            if @["stateStore_#{key}"]
                 @["stateStore_#{key}"] key,val
            else @stateStore key,val

        null

    stateLoad: (key,stateName=@persistantStateName)->
        sval= localStorage.getItem "state-#{stateName}-#{key}"
        #log " stateLoad for state-#{stateName}-#{key} got '#{sval}'"
        return null if not sval
        try
            pval= JSON.parse sval
            if pval?
                @state[key]= pval

    stateStore: (key,val,stateName=@persistantStateName)->
        lname= "state-#{stateName}-#{key}"
        #log " SSSSSSSSSSSSSSSSSS stateStore for #{lname} got '#{JSON.stringify val}'"
        if !val?
             localStorage.removeItem lname
        else localStorage.setItem    lname, JSON.stringify val
        val


    @addStateManager= (obj)->
        #log "adding a state manger #{obj?.constructor?.name} obj=#{obj} propNames= #{oAllPropNames obj}"
        self= @::
        #for prop,val of obj when prop isnt '__super__' and prop isnt 'constructor'
        for prop in (oAllPropNames obj) when prop isnt '__super__' and prop isnt 'constructor' and val=obj[prop]
            #log "   got prop=#{prop}"
            switch prop
                when 'defaultState'
                    oAssign (self.defaultState?={}),val
                when 'persistantState','urlAPIVars','refAPIVars','hashStateVars'
                    if typeof val is 'string'
                        val= obj[val]
                    #log "BasicUIApp@addStateManager adding #{prop} to",{sp:self[prop],val}
                    oAssign (self[prop]?={}),val
                when 'onBeforeMountDo'
                    self.onBeforeMountDo= self.onBeforeMountDo.concat val
                when 'onMountDo'
                    self.onMountDo=       self.onMountDo.concat val

                else
                    self[prop]= val
        null



    componentWillMount: ->
        #log "PersistantApp.componentWillMount"
        super()
        for method in @onBeforeMountDo
            @[method]?()

        #OK apply URLSate
        @applyURLAPI()

    componentDidMount: ->
        super()
        for method in @onMountDo
            @[method]?()


    applyURLAPI: ->
        vx.qo= vx.q
        urlQS= vx.q= oA {},vx.q,parseQS()
        if document?
            refQS= parseQS (parseUri document.referrer).query
            #log "Building refQS referrer=#{document.referrer}",refQS
        else #actually it exists in express TODO:
            refQS={}
            log "No ref ",refQS
        #referQS= parseURLQS
        @applyAPI urlQS,@urlAPIVars,{urlQS,refQS}



    applyAPI: (stateVars,lookupVars,params)->
        # check url for state changes
        #log "PersistantApp.applyState for ",{stateVars,lookupVars}
        for prop,val of stateVars when lookupVars[prop]?
            action= lookupVars[prop]
            tact= typeof action
            #log "apply api for prop=#{prop} typeof acton=#{tact}  action=#{action}" if vx.isDEV
            switch
                when tact is 'function'
                     action.call @,val,params
                when tact is 'string' and typeof @[action] is 'function'
                    @[action] @,val,params
                when tact is 'string' and action of @state
                     @setState "#{action}":val
                else @setState "#{prop}":val





module.exports= { PersistantApp }

