{ log, sList, Global }= vx= require 'vx/globals/Boot'

log "TradFr isnode=#{Global.vx.isNODE}"

{ span, br, p, b, ul, li, em, a, div }= require 'vx/UI'

log "TradFr isnode=#{Global.vx.isNODE}"

if Global.vx.isNODE
    span= br= p= b= ul= li= em= a= div= ->''

{ n01, n5, n6, asDateFr:asDate, asTimeFr:asTime, msAsTimeStrFr:msAsTimeStr }= require 'vx/tools/Formating'


{ getAge }= require 'vx/comp/surf/SurfColor'

{ reg2nomFr }= require 'vx/globals/Conversions'
Reg2Name= reg2nomFr

module.exports=

    'reg2name': -> Reg2Name[@] or @
    'asDate':   -> asDate @
    'asDist':   (say,{distScale})-> @*distScale
    'asDistStr1':   (say,{distUnit,distScale})-> "#{(@*distScale).toFixed 1} #{distUnit}"
    'asDistStr0':   (say,{distUnit,distScale})-> "#{(@*distScale).toFixed 0} #{distUnit}"
    'distUnit':     (say,{distUnit})-> distUnit

    "Friendly Expedition": "Circuit découverte"
    "Curious Exploration": "Circuit audacieux"
    "Marathon Adventure":  "Circuit aventure"
    'titre btn editer':"Modier ce circuit ou l'envoyer à l'application iMotoneige"


    #partout
    'OK': '!'
    'Ok': "OK"

    #Plusieurs Modules
    #'App:Titre bouton retour carte': "Carte"

    'AppState:message changer config carte': "Vous êtes sur le point d'ouvrir un itinéraire. Cette action va changer l'itinéraire actuel et votre carte (ex. zoom, position). Voulez-vous continuer?"


    'App:titre carte':  "Carte FCMQ 2024-24"
    'App:titre app':    "iMotoneige 2024"

    'App:# Usager FB': '!'
    'App:Administration': '!'
    #'App:Ajouter à OdoVéloApp:Après arrêt': '!'
    #'App:Après premier enregistrer': '!'
    #'App:Automatiquement lire les attraits': '!'
    #'App:Créer Attrait': '!'
    'App:Debug': '!'
    #'App:Delais avant lecture': '!'
    #'App:Jamais': '!'
    'App:La carte des sentiers a été mise à jour': 'La carte des sentiers a été mise à jour '
    'App:Les attraits ont été mise à jour': "Les services ont été mis à jour "
    'App:Les circuits ont été mise à jour': "Les circuits ont été mis à jour "
    'App:Map Menu': "Menu"

    'App:Options': '!'
    #'App:Reseau Motoneige': '!'
    'App:Services': '!'
    'App:Titre menu bkg': "Position cliquée:"
    'App:titre page itin':"Itinéraire"
    'App:Toujours': '!'
    'App:Transmettre commentaires au serveur': '!'
    'App:Vitesse de lecture': '!'
    'App:menu item admin': "Administration"
    'App:menu item parametres':"Paramètres"
    'App:menu item mapgoogle':  "Carte 2024"
    'App:btn retour carte': "Retour à la carte"
    'App:menu item mapleaflet': "Carte hors connexion"
    'App:menu item list circuits':"Circuits proposés"
    'App:menu item itin': "Itinéraire"
    'App:menu item itin hover': "Cliqué pour voir itinéraire"
    'App:titre list circuits':  "Circuits proposés"
    'App:btn list circuits': [(span '.vx-hide-w-narrow',"Liste des circuits"),(span '.vx-show-w-narrow',"Circuits")]
    'App:titre bouton planifier': " Planifier facilement"
    'App:tests': '!'
    'App:titre group couches': '!'

    'App:btn install app': [( span '.vx-show-w-wide',"Installez l'application" ),( span '.vx-hide-w-wide .vx-hide-w-narrow',"Application" )]

    'App:btn install app hover': "Installez l'application sur votre cellulaire"
    'App:btn install app href': 'https://fcmq.qc.ca/fr/motoneigistes/imotoneige/'
    #'App:Ajouter à OdoVélo:': '!'
    #'App:Après arrêt': '!'


    'App:titre page urgence':"URGENCE ?"
    'App:urgence txt': [
        span '.vx-smalltxtx',"Composez"
        br()
        span '.vx-vbigtxt',"9-1-1"
        br()
        span '.vx-smalltxtx',"ou"
        br()
        span '.vx-bigtxt',"310-4141"
        br()
        span '.vx-smalltxtx',"cellulaire"
        br()
        span '.vx-bigtxt',"*4141"
        ]

    'App:titre bouton copyright': "© Mentions légales"
    'App:hover bouton copyright': "Cliquez pour copyrights et mentions légales"


    'App:bouton couche sentiers': "Sentiers"
    'App:titre couche sentiers': "_"

    'App:bouton couche aucunecondition': "Aucune couche de condition"
    'App:titre couche aucunecondition': "_"

    'App:bouton couche surfaceuses': "Délai de surfaçage"
    'App:titre couche surfaceuses': "_"
    'App:mess legend surfaceuses':-> "En date du #{asDate @date}"

    'App:bouton couche conditions': "Conditions des sentiers"
    'App:titre couche conditions': "_"
    'App:mess legend conditions':-> "En date du #{asDate @date}"

#    'App:titre btn editer':"Modify this circuit or send it to iMotoneige"

    'App:et +':-> "et +"
    'App:mess segment surfacer':->
        div '',
            b '',"Segment surfacé le #{asDate @date}"
            b ''," par le véhicule #{@veh.replace '-NTS',''}" if @veh
            br()
            "Le surfaçage datait donc de #{n01 @age} heures au moment de la mise à jour faite le #{asDate @majDate}."
            br()
    'App:mess segment surfacer v2':->
        {entryTime,exitTime,vehId}= @passage or {}
        entryText= (asDate entryTime).replace 'à','entre'
        if (new Date entryTime).getDate() isnt (new Date exitTime).getDate()
             exitText= 'le '+ asDate exitTime
        else exitText= asTime exitTime
        div '',
            b '',"Segment surfacé le #{entryText} et #{exitText}"
            b ''," par le véhicule #{vehId.replace '-NTS',''}" if vehId
            br()
            "Le surfaçage datait donc de #{n01 getAge @maxTime,entryTime,exitTime} heures au moment de la mise à jour faite le #{asDate @maxTime}."
            br()
    'App:mess segment surfacer v2 vieux':->
        {vehId}= @passage or {}
        div '',
            b '',"Segment surfacé il y a plus de #{@heures} heures"
            b ''," par le véhicule #{vehId.replace '-NTS',''}" if vehId
            br()

    'App:mess attent serveur surfacage': "En attente d'une réponse du serveur de surfaçage"
    'App:mess pas info surfacage': "Pas d'information de surfaçage disponible"

    'App:bouton couche surfaceusesbrute': "Trace GPS de surfaçage"
    'App:titre couche surfaceusesbrute': "Tous les points GPS de surfaceuses des derniers 7 jours"

    # Conditions

    'App:mess attent serveur contitions': "En attente d'une réponse du serveur de conditions"
    'App:mess pas info conditions': "Pas d'information de conditions disponible"

    'Cliquer pour détails': "Cliquer pour détails"

    'Conditions:':"Conditions: "

    'App:cond-Excellent': "Excellent"
    'App:cond-Très bon': "Très bon"
    'App:cond-Bon': "Bon"
    'App:cond-Ouvert':"Ouvert"
    'App:cond-Acceptable':"Acceptable"
    'App:cond-Limit':"Limit"
    'App:cond-Limitj':"Limit"
    'App:cond-Limité':"Limité"
    'App:cond-Limitéj':"Limité"
    'App:cond-Fermé':"Fermé"
    'App:cond-cfermé':"Fermé"
    'App:cond-Non disponible':"Non disponible"
    'App:cond-':              "Non disponible"
    'App:cond-Inconue':       "Non disponible"
    'App:cond-inconue':       "Non disponible"

    'Excellent': "Excellent"
    'Très bon': "Très bon"
    'Bon': "Bon"
    'Ouvert':"Ouvert"
    'Acceptable':"Acceptable"
    'Limit':  "Limit"
    'Limitj': "Limit"
    'Limité': "Limité"
    'Limitéj':"Limité"
    'Fermé': "Fermé"
    'cfermé':"Fermé"
    'Non disponible':"Non disponible"
    'Inconue':       "Non disponible"
    'inconue':       "Non disponible"

    'mess fermer par fcmq': "Ce tronçon de sentier est fermé."
        
        
    "App:cond source": ->
        name= Reg2Name[@] or @
        switch ''+@
            when 'FCMQ'    then "Source: FCMQ"
            when 'CE','MT' then "Source: FCMQ"
            when 'AT'      then "Source: clubs #{name}"
            else                "Source: ATR #{name}"
            
    # Fin Conditions

    'App:bouton couche ecosentiers': "écoSentier"
    'App:titre couche ecosentiers': "_"

    'App:Couche météo:': "Météo "

    'App:court couche ':  "Aucune couche"
    'App:bouton couche ': "Aucune couche météo"
    'App:titre couche ':  "_"

    'App:court couche SRPD':  "Prévision 6hrs"
    'App:bouton couche SRPD': "Prévision 6 heures : neige (moyenne)"
    'App:titre couche SRPD':  "Système Régional de Prévision Déterministe (SRPD) à 10 km de résolution mis-à-jour à 00UTC, 06UTC, 12UTC et 18UTC."

    'App:court couche SRPE24':  "Prévision 24hrs"
    'App:bouton couche SRPE24': "Prévision 24 heures : neige (moyenne)"
    'App:titre couche SRPE24':  "Système régional de prévision Ensembliste (SRPE) à 10 km de résolution mis à jour à intervalle de 24 heures."

    'App:bouton couche Neige-radar': "Radar neige"
    'App:titre couche Neige-radar': "_"

    'App:court couche SRPE12':  "Prévision 12hrs"
    'App:bouton couche SRPE12': "Prévision 12 heures : neige (moyenne)"
    'App:titre couche SRPE12':  "Système Régional de Prévision Ensembliste (SRPE) à 10 km de résolution mis-à-jour à 00UTC et 12UTC (intervalle de 12 heures)."

    'App:court couche Masse-neige': "Neige au sol"
    'App:bouton couche Masse-neige':"Masse de la neige au sol"
    'App:titre couche Masse-neige': "Système Global de Prévision Déterministe (SGPD) à 25 km de résolution mis-à-jour à 00UTC et 12UTC"

    'App:court couche Neige-sol': "Neige au sol"
    'App:bouton couche Neige-sol':"Neige au sol"
    'App:titre couche Neige-sol': "Système Global de Prévision Déterministe (SGPD) à 25 km de résolution mis-à-jour à 00UTC et 12UTC"


    "App:titre btn partager": "Envoyer"
    "App:hover btn partager": "Envoyer mon itinéraire et la carte actuelle (zoom, position, etc.)"

    'App:Menu': "!"

    'App:titre popup copyright':"Mentions légales"
    'App:html popup copyright':
        ul '',
            li "|Limites des parcs : © Gouvernement du Québec"
            li "|Donnéss cartographiques: © OpenStreetMap contributors, ",a 'href=http://www.openstreetmap.org/copyright  target=_blank',"licence"
            (li "|Donnéss et style cartographiques: © MapTiler , ",a 'href=https://www.maptiler.com/copyright/ target=_blank',"licence") if vx.isAPP
            li "|Sentiers de motoneige: © ",a 'href=http://fcmq.qc.ca/ target=_blank',"Fédération des Clubs de Motoneigistes du Québec"
            (li "|Utilisation de ",a 'href=http://leafletjs.com target=_blank',"Leaflet") if vx.isAPP
            (li "|Utilisation de ",a 'href=http://MapBox.com target=_blank',"MapBox-gl-js , ",a 'href=https://github.com/mapbox/mapbox-gl-js/blob/master/LICENSE.txt target=_blank',"licence") if vx.isAPP
            li "|Météo : © Environnement Canada"
            li "|HTML,CSS, Javascript et cartes (image): © ",a 'href=http://www.fcmq.qc.ca target=_blank',"FCMQ inc."


    'App:titre page parametres': "Paramètres"

    'App:Version des sentiers:': "Version des sentiers: "
    'App:Version des attraits:': "Version des points de services: "
    'App:verifier temps': ->" (vérifié il y a #{msAsTimeStr @temps})"
    'App:État reseau:': "Réseau: "
    'App:online':  'Connecté'
    'App:offline': 'Sans connection'
    'App:Mode beta, à vos risques!':'!'
    'App:Détails techniques:': '!'
    
    "App:Mode fond ecran": "Source du fond de carte: "

    'App:bkgMode0': 'Cellulaire / Tablette'
    'App:bkgMode1': 'Hybride'
    'App:bkgMode2': 'Internet'
        
    # Départ
    
    'App:init encours':   "Svp attendre: initialisation en cours. Ce processus peut nécessiter un certain temps."
    'App:lecture encours':"Svp attendre: lecture en cours. Ce processus peut nécessiter un certain temps."
    'App:Terminé' : "Initialisation et lecture terminés"



    'Ajax:graph':    'sentiers'
    'Ajax:attraits': 'services'

    'Ajax:Un moment lecture des': "Un moment, lecture des"
    'Ajax:Un moment telechargement': "Un moment, téléchargement des"
    'Ajax:"Lecture des': "Lecture des"
    'Ajax:terminée': "terminée"
        
    # AppAdminPage

    'AppAdminPage:Mise à jour encours ...': " Mise à jour en cours ..."
    'AppAdminPage:Nouveau ShapeFile': '!'
    'AppAdminPage:Nouveau fichier d\'attraits': '!'
    'AppAdminPage:Mise à jour réussie!': " Mise à jour réussie!"
    'AppAdminPage:ATTENTION! Erreur de mise à jour:': "  ATTENTION! Erreur de mise à jour:"


    # Partager

    # #mapShareBtn


    'SharingUI:Annuler': "!"

    'SharingUI:titre btn partager': "Envoyer l'itinéraire et la carte"
    'SharingUI:hover btn partager': "Envoyer mon itinéraire et la carte actuelle (zoom, position, etc.)"

    'SharingUI:titre popup partager': "Envoyer l'itinéraire et la carte"
    'SharingUI:intro popup partager': "Envoyer votre itinéraire et les ajustements de votre carte (zoom, position, pictogrammes choisis) d’une des façons ci-dessous."
    'SharingUI:intro popup partager cell2': "Truc : Pour transmettre votre position, placer un marqueur de l’itinéraire sur votre marqueur de position."

    'SharingUI:titre btn partager courriel': [(span '.vx-show-w-wide',"Envoyer par "),"courriel"]
    'SharingUI:hover btn partager courriel': "Envoyer par courriel"
    'SharingUI:text btn partager courriel':  span '.vx-hide-w-wide',"Envoyer par courriel"

    'SharingUI:titre btn partager sms':  [(span '.vx-show-w-wide',"Envoyer par "),"SMS"]
    'SharingUI:hover btn partager sms': "Envoyer par SMS"
    'SharingUI:text btn partager sms':   span '.vx-hide-w-wide',"Envoyer par SMS"

    'SharingUI:titre btn partager favori': "Dupliquer URL complet"
    'SharingUI:hover btn partager favori': "_"
    'SharingUI:text btn partager favori': "Ouvrir une page avec le URL complet. Pratique pour sauvegarder dans un favori!"

    'SharingUI:titre btn partager copy': "Copier URL"
    'SharingUI:hover btn partager copy': "_"
    'SharingUI:text btn partager copy':"Copier le URL dans le presse-papiers afin de le coller ailleurs."


    'SharingUI:message pour copier faire': "Pour copier le URL dans le presse-papiers, peser simultanément sur les touches: < Ctrl >< c >"

    'SharingUI:titre copy automatique': "Copie"
    'SharingUI:message copy automatique': "Le lien de votre itinéraire/carte a été copié dans votre presse-papiers. Il vous suffit de le coller à l’endroit désiré."

    'SharingUI:titre erreur copy automatique':"Erreur"
    'SharingUI:message erreur copy automatique': "Erreur lors de la copie"


    'SharingUI:message changer config carte': "Vous êtes sur le point d'ouvrir un itinéraire. Cette action va changer l'itinéraire actuel et votre carte (ex. zoom, position). Voulez-vous continuer?"


    # sharing actions

    'SharingUI:titre courriel partager':  (-> if vx.mapMode.domain is 'mn' then "Itinéraire et carte iMotoneige" else "Itinéraire et carte" )
    'SharingUI:message courriel partager': "Cliquer le lien suivant pour voir l'itinéraire et la carte:\n"

    'SharingUI:titre sms partager': "_"
    'SharingUI:message sms partager': (-> if vx.mapMode.domain is 'mn' then "iMotoneige: " else "Itinéraire et carte: " )

    'SharingUI:titre copie automatique': "_"
    'SharingUI:message copie automatique': (-> if vx.mapMode.domain is 'mn' then "iMotoneige: " else "Itinéraire et carte: " )

    'SharingUI:bug email': "En cliquant sur OK, l'itinéraire est copié dans le presse-papier et un courriel est créé. Il suffit d'y insérer le lien en faisant l'opération 'coller'  (raccourci clavier:  'CTRL' et 'V'). Cette procédure n'est pas complètement automatique en raison d'un problème hors de notre contrôle."
    'SharingUI:bug email2': "Cliquer le lien suivant pour voir l'itinéraire et la carte:\nATTENTION!: Tapez ici longtemps pour insérer l'itinéraire (l'itinéraire a été automatiquement copier dans le press-papier)."


    'AppAdminPage:Administration': "!"
    'AppAdminPage:bouton import attraits':  "Import des attraits"
    'AppAdminPage:bouton import shapefile': "Import du shapefile"
    'AppAdminPage:bouton import alerts':    "Import des alertes"
    'AppAdminPage:bouton import circuits':  "Import des circuits"


    'FCMQ:Abri ou système sécurité': "Abri / Système sécurité"
    'FCMQ:Bar': "Bar / Cocktail"
    'FCMQ:Bed and Breakfast': '!'
    'FCMQ:Concesionnaire': 'Concessionnaire'
    'FCMQ:Courriel': '!'
    'FCMQ:Essence': 'Station-Service'
    'FCMQ:Forfait': '!'
    'FCMQ:Point de vue': '!'
    'FCMQ:Repos': 'Relais / Refuge'
    'FCMQ:Autres':'Autres'
    "FCMQ:Bornes évacuation" : "Bornes d'évacuation"
    'FCMQ:Relais' : 'Relais des clubs de motoneigistes'
    'FCMQ:Refuge' : 'Refuge des clubs de motoneigistes'
    'FCMQ:Hébergement': '!'
    'FCMQ:Hôtel sécuritaire': '!'
    'FCMQ:Location motoneige': '!'
    'FCMQ:Mobilité restreinte': '!'
    'FCMQ:Relais Détente': "Spa / Santé"
    'FCMQ:Restaurant': '!'
    'FCMQ:Réparation mécanique': '!'
    'FCMQ:Site web': '!'
    'FCMQ:Sites et attraits': "Site et attrait"
    'FCMQ:Stationnement': 'Stationnement'
    'FCMQ:Incitatif':     'Stationnement incitatif'
    'FCMQ:Téléphone': '!'
    'FCMQ:type attraits': "Types d’attraits"

    'FCMQ:info-Hôtel sécuritaire':"Un hôtel sécuritaire est muni d’un système de sécurité pour motoneiges. L’hôtelier doit avoir, par exemple, un garage ou un enclos fermé ou encore fournir gracieusement une chaîne. Il peut aussi être doté d’un système de caméra ou avoir un service de gardien de sécurité."
    'FCMQ:info-Incitatif':"Endroit où stationner son véhicule avant d’aller en sentier. Des frais pourraient s’appliquer."
    'FCMQ:info-Repos': "<b>Refuge</b>: Abri avec possibilité de chauffage, aucun service, géré par le club de motoneigiste.<br/><b>Relais</b>: Abri chauffé, offre un ou plusieurs services, gérés par le club de motoneigiste.<br/><b>IMPORTANT</b>: Avant de partir, vérifiez si ouvert auprès du club."
    'FCMQ:info-Relais': "Relais motoneige chauffé, offre un ou plusieurs services. Vérifiez si ouvert auprès du club avant de partir."
    'FCMQ:info-Refuge': "Abri avec possibilité de chauffage d'appoint, aucun service. Vérifiez si ouvert auprès du club avant de partir."
    'FCMQ:info-Bornes évacuation': 'Fournie par la sécurité publique du Québec (911)'

    'GMapWidgets:Recherche Google': '!'


    "Itinéraire": "!"

    'ItinWidgets:Titre Itinéraire': "Itinéraire : "

    'ItinWidgets:itinButton':(say,{distUnit,distScale})->
            #log " ItinWidgets:itinButton distUnit=#{distUnit} distScale=#{distScale}"
            switch @wpcnt
                when 0
                    [
                        span '.vx-label__responsive .vx-show-w-wide',"Itinéraire:"
                        span '.vx-show-w-narrow',"cliquer carte"
                        span '.vx-hide-w-narrow',"cliquer carte pour débuter"
                        ]
                when 1
                    [
                        span '.vx-label__responsive .vx-show-w-wide',"Itinéraire:"
                        span '.vx-label__responsive',"ajouter une destination"
                        ]
                when 2
                    [
                        span '.vx-label__responsive .vx-show-w-wide',"Itinéraire:"
                        span '.vx-label__responsive',"#{(@dist*distScale).toFixed 0} #{distUnit}"
                        ]
                else
                    [
                        span '.vx-label__responsive .vx-show-w-wide'," Itinéraire:"
                        span '.vx-label__responsive .vx-hide-w-compact',"#{@wpcnt-1} étapes, #{(@dist*distScale).toFixed 0} #{distUnit}"
                        span '.vx-label__responsive .vx-show-w-compact',"#{(@dist*distScale).toFixed 0} #{distUnit}"
                        ]

    'ItinWidgets:mess no wp':->
        div '.mdc-typography--body1',
            p '',
                em '',"Aucun itinéraire n’est défini."
                br()
                "Pour créer un itinéraire, affichez la carte puis ajoutez les marqueurs de l’itinéraire en cliquant sur la carte."
                br()
                "Pour plus de détails, consultez le ",(a "target=aidefcmq href=#{vx.urls.AideFR}","guide d’utilisation")," (Internet requis)."

    'ItinWidgets:bouton enlever itin': "Enlever étape"
    'ItinWidgets:titre bouton enlever itin': "Enlever cet attrait de l’itinéraire"

    'ItinWidgets:Début': "!"
    'ItinWidgets:Fin': "!"

    'ItinWidgets:Insérer ce point au début de l’itinéraire': "!"
    'ItinWidgets:Insérer ce point entre 2 marqueurs pour que la distance soit la plus courte': "!"
    'ItinWidgets:Insérer ce point à la fin de l’itinéraire': "!"
    'ItinWidgets:Insérer dans l’itinéraire:': "!"
    'ItinWidgets:Étape': "!"


    'ItinWidgets:Arrivée': "!"
    'ItinWidgets:Arrivé':  "Arrivée"
    'ItinWidgets:Départ': "!"
    'ItinWidgets:Étape': "!"

    'ItinWidgets:Cumul parcouru':  (say,{distUnit,distScale})->" Cumul parcouru : #{(@dist*distScale).toFixed 1} #{distUnit}"
    'ItinWidgets:Prochaine étape': (say,{distUnit,distScale})-> "Prochaine étape : #{(@dist*distScale).toFixed 1} #{distUnit}"
    'ItinWidgets:Distance arrivée':(say,{distUnit,distScale})-> "Arrivée :  #{(@dist*distScale).toFixed 1} #{distUnit}"

    'ItinWidgets:cumul (km)':(say,{distUnit})-> "cumul (#{distUnit})"
    'ItinWidgets:pour (km)': (say,{distUnit})-> "pour (#{distUnit})"
    'ItinWidgets:prendre':   "!"
    'ItinWidgets:à (km)':    (say,{distUnit})-> "à (#{distUnit})"

    'itin titre arrivee': "Arrivée"
    'itin titre depart':  "Départ"
    'itin titre etape':   "Étape"

    'ItinWidgets:nomPiste':->@nom

    'ItinWidgets:clear itin': "Effacer l'itinéraire"
    'ItinWidgets:export as GPX': "Exporter itinéraire en GPX"

    'ItinWidgets:kilometres': "Kilomètres"
    'ItinWidgets:miles':      "Miles"

    'ItinWidgets:reverse itin': "Inverser direction"

# Circuit UI - Circuit Info Template

    "GPX downloaded":  ->"GPX du circuit téléchargé dans #{@fname}"
    "download GPX":  "Télécharger GPX du circuit"
    "navaigation google": "Navigation Google"
# fin


# MessageUI
    'MessageUI:text accept conditions d\'utilisation debut': "J'accepte les"
    'MessageUI:lien conditions d\'utilisation': "conditions d'utilisation de la FCMQ."
    'MessageUI:text accept conditions d\'utilisation fin': "de la FCMQ."
# fin


# Couche Selector template

    'titre legend conditions':"Conditions"
    'legend cond bonne':      "Bonne"
    'legend cond acceptable': "Acceptable"
    'legend cond ferme':      "Fermé"
    'legend cond non dispo':  "Non disponible"




    'titre legend sentiers': "Types de sentiers"
    'type sentier T': "Trans-Québec"
    'type sentier R': "Régional"
    'type sentier L': "Local"
    'type sentier N': ['Local ns ',span '$fontSize:66%',"(non subventionné)"]
    'type sentier N2': 'Local ns <span style="font-size:66%">(non subventionné)</span>'
    'type sentier P': "Privé"
    'type sentier V': "Voies fluviales et autres"
    'type sentier fermer': "Fermé"
    'type sentier eco': "ÉcoSentier"


    'titre legend surf': "Légende du surfaçage"
    'legend surf 1':     "Nouvelles couleurs"
    'legend surf 2':     "Légende en bas à gauche"

    'mess cond': "Les conditions des sentiers sont émises par les Associations touristiques régionales (ATR) en collaboration avec les clubs membres de la FCMQ. Il est possible de connaître la date et l'heure des dernières mises à jour en cliquant sur le sentier."
    'mess surf': "Les données sur les délais de surfaçage proviennent des GPS des surfaceuses des clubs membres de la FCMQ. Ces données sont transmises deux fois par jour."

    'Internet requis': "!"

# fin



    'GraphWidgets:position clicker': ->"Position: #{n6 @pt.y},#{n6 @pt.x}"
    'GraphWidgets:sentier info':-> """Sentier: #{@cat}#{@no_sen or ''} #{ if @dist and @dist>0.05 then " à #{@dist.toFixed 1}km" else ''} (#{@id})"""
    'GraphWidgets:club info':->
        if @numClub
            if @clubs and c=@clubs[@numClub]
                if c[..3].toLowerCase() is 'club'
                     "#{c} (#{@numClub})"
                else "Club: #{c} (#{@numClub})"
            else "Club numero #{@numClub}"
    'GraphWidgets:region info':->
        switch @numClub
                when 201, 202, 204, 206, 207, 215, 229, 233, 236, 246, 251
                    "Région: Saguenay-Lac-Saint-Jean"
                when 300, 322, 338, 342, 351, 319, 342, 356, 371
                    "Région: Québec/Charlevoix/Portneuf"
                when 601, 608, 627, 628, 629, 642, 655, 656, 657, 659, 662, 663, 666, 669, 677, 684, 696, 698
                    "Région: Lanaudière"
                when 400, 404, 408, 409, 410, 412, 854
                    "Région: Mauricie/Centre-du-Québec"
                when 760, 766, 771, 780, 786, 791, 794
                    "Région: Outaouais"
                when 603, 610, 611, 615, 617, 632, 633, 637, 645, 648, 649, 650, 672, 680, 683, 690, 693, 618
                    "Région: Montérégie"
                when 500, 501, 507, 508, 514, 518, 522, 524, 532, 535, 537, 538, 686, 692
                    "Région: Cantons-de-l'Est"
                when 612, 614, 619, 622, 624, 639, 646, 654, 658, 668, 681, 687, 699, 750, 751, 768, 772, 776, 792, 620, 601
                    "Région: Laurentides"
                when 101, 102, 103, 104, 105, 107, 108, 109, 110, 111, 116, 118, 120, 124, 131, 133, 150
                    "Région: Gaspésie/Îles-de-la-Madeleine"  
                when 950, 951, 952, 953, 955, 956, 957, 958, 959, 960, 961, 962, 967, 970
                    "Région: Côte-Nord"
                when 313, 359
                    "Région: Québec/Charlevoix/Portneuf"
                when 310, 328, 353, 403, 406, 407, 413, 417, 422
                    "Région: Mauricie/Centre-du-Québec"  
                when 301, 303, 306, 309, 312, 315, 317, 325, 327, 334, 337, 341, 344, 354, 355, 362, 366, 367, 379, 381, 385, 388, 112
                    "Région: Chaudière-Appalaches"  
                when 106, 302, 305, 307, 308, 314, 320, 336, 340, 346, 352, 333, 391
                    "Région: Bas-Saint-Laurent"
                when 209, 210
                    "Région: Saguenay-Lac-Saint-Jean"
                when 850, 851, 852, 855, 856, 859, 860, 862, 873, 890, 897, 880
                    "Région: Abitibi-Témiscamigue"
                else ""

    'App:menu item fermer app': "Quitter l'application"

    'App:menu item message': "Afficher message"

    'App:menu item aide': "Aide"
    'App:titre aide': "Aide"
    'App:aide-html':"""
    <P >Les sections spécifiques à l'application mobile ou au site Internet sont regroupées à la fin.</P>

    <H1 >Tracer un itinéraire</H1>
    <UL>
        <LI>Chaque fois que la carte est cliquée, un nouveau marqueur d'itinéraire peut être ajouté. Il suffit de choisir le type d'insertion ("Au début", "Étape", "À la fin");</LI>
        <LI>Chaque marqueur sera relié aux autres par une ligne représentant l'itinéraire le plus court entre ces marqueurs.       </LI>
        <LI>IMPORTANT! </LI>
            <UL>
            <LI>L'insertion d'un marqueur "Au début" et "À la fin" implique que vous voulez contrôler l'ordre dans lequel les marqueurs sont ajoutés. </LI>
            <LI>L'insertion d'un marqueur "Étape" implique que vous désirez que l'itinéraire passe par cet endroit mais cela vous importe peu le trajet emprunté pourvu que l'itinéraire soit le plus court possible. </LI>
            <LI>Sélectionner "Étape" peut changer la forme de l'itinéraire. Pour éviter de le changer, sélectionnez "À la fin". </LI>
            </UL>
        <LI>La distance de l'itinéraire ainsi que le nombre d'étapes apparaît en haut à gauche de la carte.       </LI>
        <LI> Si un pictogramme affiché sur la carte doit être intégré à l'itinéraire, il suffit de le pointer puis choisir le type d'insertion. Le nom du pictogramme appraîtra dans les étapes de l'itinéraire (voir ci-dessous).    </LI>
    </UL>
    <P >Pour modifier l'itinéraire, il y a 3 possibilités:</P>
    <UL>
        <LI>pour ajouter une étape, pointez la carte à l'endroit où le marqueur doit être inséré puis choisir le type d'insertion.</LI>
        <LI>pour déplacer un marqueur, cliquez-le et déplacez la souris en maintenant le bouton enfoncé jusqu'à l'endroit désiré.</LI>
        <LI>pour enlever un marqueur: </LI>
            <UL>
            <LI>cliquez-le avec le bouton droit de la souris;  </LI>
            <LI>ou cliquez "Enlever cette étape" sur la fiche apparaissant lorsqu'on clique le marqueur avec le bouton gauche de la souris; </LI>
            <LI>ou cliquez "Enlever cette étape" dans l'interface de l'itinéraire. </LI>
            </UL>
    </UL>

    <H1 >Afficher les instructions de parcours</H1>
    <UL>
        <LI>Lorsque l'itinéraire est tracé, consultez les instructions de parcours en cliquant sur le bouton "Itinéraire" sur la carte (en haut à gauche).</LI>
        <LI>Pour chaque marqueur, les distances sont précisées pour la "prochaine étape", le "cumul parcouru" et atteindre l'"arrivée".</LI>
        <LI>Pour obtenir les instructions de parcours d'une étape, il suffit de cliquer la flèche à gauche du nom de l'étape. Les sentiers à emprunter ainsi que les distances associées sont identifiés.</LI>
    </UL>

    <H1 >Afficher les délais de surfaçage (Internet requis)</H1>
    <UL>
        <LI>Cliquez sur le bouton "Services" de la carte (en haut à droite) puis cochez "Délais de surfaçage".</LI>
        <LI>Les sentiers seront colorés en utilisant les couleurs de délai de surfaçage de la légende (en bas à gauche). </LI>
        <LI>Le délai est calculé entre le moment du surfaçage et le moment de la mise à jour identifié dans dans la légende de surfaçage.</LI>
        <LI>Pour connaître le moment du surfaçage d'un tronçon précis, cliquez-le sur la carte.</LI>
    </UL>

    <H1 >Afficher la météo (Internet requis)</H1>

    Des données météo fournies par Environnement Canada sont disponibles:
    <OL>
        <LI>Prévision 6 heures : neige (moyenne) - Système Régional de Prévision Déterministe (SRPD) à 10 km de résolution mis-à-jour à 00UTC, 06UTC, 12UTC et 18UTC.</LI>
        <LI>Prévision 12 heures : neige (moyenne) - Système Régional de Prévision Ensembliste (SRPE) à 10 km de résolution mis-à-jour à 00UTC et 12UTC (intervalle de 12 heures). Neige (QPF : Quantité de neige accumulée) : moyenne </LI>
        <LI>Prévision 24 heures : neige (moyenne) - Système Régional de Prévision Ensembliste (SRPE) à 10 km de résolution mis à jour à intervalle de 24 heures. Neige (QPF : Quantité de neige accumulée) : moyenne</LI>
        <LI>Masse de la neige au sol - Système Global de Prévision Déterministe (SGPD) à 25 km de résolution mis-à-jour à 00UTC et 12UTC</LI>
    </OL>

    <H1 >Imprimer (spécifique au site Internet)</H1>
    <UL>
        <LI>L'impression de la carte et des instructions de parcours se fait avec le menu standard de votre navigateur.</LI>
        <LI>Lorsque la fen&ecirc;tre de prévisualisation s'affiche, si la carte est mal positionnée, annulez la commande puis déplacez la carte et imprimez.</LI>
        <LI>Pour imprimer le dessin des marqueurs et pictogrammes, il se peut que vous ayez à sélectionner une option telle  "Couleurs et images d'arrière-plan".</LI>
    </UL>


    <H1>Marqueur de position GPS (spécifique à l'application mobile) </H1>
    <UL>
        <LI>La position est mise à jour en continu.</LI>
        <LI>Précision de la position:</LI>
        <UL>
            <LI>La position sera plus précise si le GPS de l'appareil est ouvert puisque la position est obtenue par les satellites.</LI>
            <LI>Si le GPS est fermé ou inexistant sur l'appareil, la position sera estimée par triangulation avec les antennes cellulaires les plus proches ou ne sera pas disponible.</LI>
        </UL>
    </UL>


    <H1>Centrage de la carte (spécifique à l'application mobile) </H1>
    <UL>
        <LI>Pour régler le centrage de la carte, cliquer l'icône de position apparaissant au-dessus des boutons de zoom (en bas à droite).</LI>
        <LI>Lorsque l'icône de position est bleue, la carte est automatiquement centrée sur votre position. </LI>
        <LI>Lorsqu'elle est noire, la carte n'est pas automatiquement centrée sur votre position. Pour centrer automatiquement la carte sur votre position, cliquez à nouveau sur l'icône et elle devient bleue. Dès que vous déplacez manuellement la carte, le centrage automatique est déactivé et l'icône devient noire. </LI>
    </UL>


      <H1>Carte (spécifique à l'application mobile)</H1>
      <UL>
        <LI>L'installation de l'application inclut la carte globale. Ainsi, m&ecirc;me sans accès Internet (via un plan data ou Wifi), vous pouvez localiser les villes, villages et rues principales.  </LI>
        <LI>Des cartes détaillées sont accessibles si l'application mobile a accès à Internet. Un dessin de nuage ("cloud") sera affiché au-dessus de l'icône de centrage (en bas à droite) lorsque Internet est utilisé. Si la carte affichée correspond à celle copiée sur votre mobile lors de l'installation, le dessin d'un cercle barré apparaît.</LI>
        <LI>Pour empêcher iMotoneige d'utiliser Internet, il suffit de fermer l'accès Internet. </LI>
      </UL>


    <H1>Problème, question, commentaire </H1>
    <UL>
      <LI>N'hésitez pas à nous contacter.

         <!-- <A TARGET="_blank" HREF="mail:info@fcmq.qc.ca" >info@fcmq.qc.ca</A>. -->
        Merci !</LI>
        </UL>

    <H1 >Pour plus de détails</H1>
      <P ><A TARGET="_blank"  HREF="http://fcmq.fcmqapi.ca/docs/AIDE_carte_interactive.pdf">http://fcmq.fcmqapi.ca/docs/AIDE_carte_interactive.pdf</A>

      </P>
"""


#         'Map Menu': "Menu"
#         'menu item mapgoogle': "Carte Google"
#         'menu item mapleaflet':"Carte Leaflet"
#         'menu item traces':"Liste des sorties"
#         'menu item edit trace':"Modifier sortie"
#         'menu item options':"Options"
#         'titre parcours':"Parcours"
#         'titre traces':"Sorties"
#         'titre page traces':"Liste des sorties"
#         'titre edit trace':"Modifier sortie"
#         'edit title':"Titre"
#         'edit desc':"Description"
#         'label id':"Identifiant"
#         'label dist':"Distance"
#         'label deniveler':"Dénivelé"

