{ meta, log, oDiffs, time:getTime, Global }= vx= require 'vx/globals/Boot'

# require 'GPS.css'

# TODO: remove background geoloc not used anymore... ( unless tracker enabled, TODO: verify )

{ UIComponent, icon, fab }= require 'vx/UI'

{ say }= require 'vx/i18n/I18n'

{ latM }= require 'vx/math/MapGeometry'


class GPSStateManager


    # *********** GPS *******************

    getGPSPos: -> @state.gpsPos

    setGPSPos: (gpsPos)->

        @setState {gpsPos}
        if @state.trackMode is 'track' and gpsPos
            @setMapCenter gpsPos,null,null,'dontCheckTrack'
        if @state.bkgMenuIsGPS and @state.bkgMenuVisible
            @setState bkgMenuPosition: [gpsPos.lat,gpsPos.lng]

    GPSManager: null # gps component
    setGPSManager: (comp)-> @GPSManager= comp #Only used as a call destination
                # so doent require a rerendre thus not in state

    doRandomPt: ->
        if @GPSManager
            @GPSManager.randomPt()
        else null # log " doRandomPt no component "


    setTrackMode: (trackMode)->
        #log " >>>> setting trackMode to #{trackMode}"
        @setState {trackMode}
        if @state.gpsPos and trackMode is 'track'
            @setMapCenter @state.gpsPos

    getTrackMode: -> @state.trackMode


    doGPSClick: (e)->
        return if !pos=@getGPSPos()
        #log "doBkgClick click at event e=",e
        #log "doBkgClick click on #{e.latLng or e.latlng}"
        @closeAllMenus()
        @setState
            bkgMenuPosition: [pos.lat,pos.lng]
            bkgMenuVisible: true
            bkgMenuIsGPS: true







gpsManager= meta class GPSManager extends UIComponent

    @displayName: 'gpsManager'

    @propTypes =
        enabled:    @type.bool
        deviceReady:@type.bool #cordova dev ready flag
        onNewValue: @type.func
        onError:    @type.func
        updateInterval: @type.number  #secs
        timeoutInterval: @type.number #secs


    gps: null # gps object
    gpsStartTime: null # Date()
    api: null # name of pi being used for gps

    render: ->
        # update the the layer
        null

    componentDidMount:->
        @_didmount= true
        @_updateProps oDiffs @props,{}


    #WillReceiveProps better than DidUpdate cause we can change state without generting a render loop
    #componentDidUpdate: ( prevProps,prevState) ->
    componentWillReceiveProps: ( newProps) ->
        @_updateProps (oDiffs newProps,@props),newProps

    _updateProps: (diffs,newProps=@props)->
        return if not diffs
        #log "GPS udate diffs=",{diffs,newProps,@props}
        for name,{a,b} of diffs
            switch name
                when 'onNewValue','onError'
                    continue
                when 'enabled','updateInterval','timeoutInterval','deviceReady'
                    @["_updateProp_#{name}"] a,b,diffs,newProps
                else log " GGGGGGGGGGGGGGGGGGG #{@constructor?.displayName} componentDidUpdate ignore change for #{name} ",{a,b} if @props.debug
        null

    _updateProp_updateInterval: ->
        return if !@gps
        # TODO

    _updateProp_timeoutInterval: ->
        return if !@gps
        # TODO

    _updateProp_enabled: (enabled,oldVal,diffs,newProps)->
        if enabled and newProps.deviceReady
            @initGPS() if !@gps
        else
            @gps.pause() if @gps

    _updateProp_deviceReady: (ready,oldVal,diffs,newProps)->
        if ready and newProps.enabled and !@gps
            @initGPS() if ! @gps


    initGPS: ->
        @gpsStartTime= getTime()
        log "init GPS"
        switch
            when Global.navigator.geolocation
                geolocation= Global.navigator.geolocation
                log " GGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGps using geolocation"
                @api= 'geolocation'
                
                gpsOps=
                    maximumAge: 1000 * ( @props.updateInterval ? 5 ) # 5 sec
                    timeout:    1000 * ( @props.timeoutInterval ? 10*60 ) # 10 min
                    enableHighAccuracy: true
                
                # get last known postion then update
                geolocation.getCurrentPosition @doGeoSucces, @doGeoError,{maximumAge:Infinity,enableHighAccuracy:false}
                @gps= geolocation.watchPosition @doGeoSucces, @doGeoError,gpsOps
                    
                log "GPS enabled"
            else
                @api="NO GPS API AVAIALBE"
                @doGeoError()

    doGeoSucces: (geoPos)->

        #log "!!!!!!!!!!! doGeoSucces GPS hit coords=",geoPos
        switch @api
            when 'geolocation'
                { timestamp:time, coords:{ latitude:lat, longitude:lng, accuracy:acc, altitude:alt, altitudeAccuracy:altAcc, heading, speed }} =  geoPos
                pos= { lat, lng, acc, time, alt, } # provider:'gps'
            else
                log "!!!!!!!!!!! doGeoSucces api unkowen #{@api}"
                pos= geoPos

        log "gps got pos=",{pos,@lastPos} if vx.isDEV 
        if @lastPos and @props.updateInterval and (pos?.time-@lastPos?.time)<1000*@props.updateInterval
            #log "skip gps update"
            return
        #log "do gps update delta=#{pos?.time-@lastPos?.time} interval=#{1000*@props.updateInterval}"
        @lastPos= pos
        
        @props.onNewValue pos  if @props.onNewValue

        null


    doGeoError: (err)->

        @lastPosErrr= err

        if @props.onError
            @props.onError err
        else # no error than set pos to null
            @props.onNewValue null  if @props.onNewValue

        log "GPS error=",err
        log "GPS err:#{err.code} #{err.message} #{((getTime()-@gpsStartTime)/1000).toFixed 0}"

        null


    r= (a)-> a+(Math.random()-0.5)/10

    randomPt: ->

        if @lastPos
            {lat, lng}= @lastPos
        else
            lat=47; lng=-71
        log "random pt is #{lat},#{lng}"
        @doGeoSucces { timestamp:getTime(), coords:{  latitude:r(lat), longitude:r(lng),altitude:r(0.06)*300 }}


gpsMarker= (props)->
    ret=if gpsPos=props.pos
         icon
            __proto__: props
            pos: undefined
            className: 'gps-marker'
            key:'gpsicon'
            id:'gpsmarker'
            position: {top:gpsPos.lat,left:gpsPos.lng}
            color: props.color
            $cursor:if props.onClick then 'pointer'
            #$marginLeft:'-12px'
            #$marginTop: '-12px'
            props.icon or 'gps_fixed'
    else icon
            __proto__: props
            pos: undefined
            className: 'gps-marker'
            key:'gpsicon'
            id:'gpsmarker'
            position:{top:47,left:-71}
            color: props.color
            #$marginLeft:'-12px'
            #$marginTop: '-12px'
            props.icon or 'gps_not_fixed'
    log "created gpsmarker=",ret if props.debug
    ret


gpsTracker= meta class GPSTracker extends UIComponent

    # button show gps state and tracking mode

    @propTypes=
        gpsPos:   @type.object
        onNewValue: @type.func
        value:    @type.string
        name:     @type.string
        trackColor: @type.string
        dontTrackColor: @type.string
        className: @type.string

    constructor:(props)->
        super props
        @state=
            mode:'idle'


    render: (props=@props)->

        if props.gpsPos
             iconName= 'gps_fixed'
        else iconName= 'gps_not_fixed'

        if props.value is 'track'
             color= props.trackColor ? 'blue'
        else color= props.dontTrackColor ? 'grey'

        fab '#gpstracker ',
            className: (props.className or '')
            onClick: @doClick
            children:[icon {color},iconName]


    doClick:->
        val= if @props.value is 'track' then 'idle' else 'track'
        @props.onNewValue  val,@props.name or 'gpsTracker' if @props.onNewValue


module.exports= { gpsManager, gpsMarker, gpsTracker, GPSStateManager }


