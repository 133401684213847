{ log, meta, oIsEmpty, oA }= vx= require 'vx/globals/Boot'  # to get a global ref to HelloWorld that survies webpack
ModuleName= 'AppAdminPage'
require './AdminPage.css'


wait= require 'vx/tools/Wait'

vx.fileurls= require 'vx/globals/FileURLS'

# I18n
{ sayModule }= require 'vx/i18n/I18n'
say= sayModule ModuleName

UI= require 'vx/UI'

# UI layout
{ page, sidePanel, header2, tbTitle, section, content, group, ul, li, form, fileChooser }= UI

# html blocks
{ div, h1, h2, h3, h4, h5, h6 }= UI # pass through require 'DOMTags'

# html inline
{ p, br, h5, span, img }= UI

#htmlctrls
{ button, input, details, summary, icon }= UI

{ list, listItem }= UI

{ updateForm, updateFormJ }= require 'vx/tools/Form'

{ reg2nomFr, reg2nomEn }= require 'vx/globals/Conversions'

sul= (props,...items)->
    itemProps=props.itemProps or ''
    ul {props...,itemProps:undefined},...( li itemProps,item for item in items.flat 2 when item )

getName= (membre='',lang='fr')->
    lookup= if lang is 'en' then reg2nomEn else reg2nomFr
    lookup[ membre.toUpperCase() ] or membre

doNavigate= (e)->
    { href,id}= e.target
    log "doNavigate for #{id} to #{href}",e.target
    window.open href,id


fileUpload= ({app,id,action,updateText,iconName='|attach_file',btnText,mess})->
    listItem group {id:"admin-#{id}"},form 'method=post encType=multipart/form-data',action:"save/#{action}",
        input 'type=hidden name=user',value:app.getUser()
        fileChooser '$width:0 $margin:0 $padding:0 $visibility:hidden  type=file',
            id:"edit-opt-#{id}"
            name:id
            onChange:(e)->
                target= e.target
                log "onchange target=",{target:e.target} if app.state.debug
                if target.files?.length
                    log "Doing update" if app.state.debug
                    app.setAdminState 'updating'," Maj #{updateText} #{target.value} en cours"
                    updateForm target.form
                        .then (response)->
                            log "fileUpload reponse.status=#{response.status}",response
                            switch response.status
                                when 200
                                    app.setAdminState 'succes'," Maj #{updateText} #{target.value} réussie..."
                                    #response.text().then (txt)-> app.setAdminState '',...[                                                       " Maj ShapeFile #{target.value} reussie", br(), txt ]
                                when 403
                                    app.setAdminState 'error403',"Authentifiez-vous à nouveau. Contactez la FCMQ si vous éprouvez des problèmes persistants."
                                    log "fileUpload got 403 error "
                                when 302
                                    app.setAdminState 'redirect302',"Redirect OK?"
                                    log "fileUpload got 302 redirect "
                                when 304
                                    app.setAdminState 'error304',"Aucun changement détecté. Modifez le fichier avant de faire la mise à jour."
                                    log "fileUpload got 304 error "
                                else
                                    app.setAdminState 'error'," Maj #{updateText} #{target.value} erreur..."
                                    response.text().then (txt)-> app.setAdminState '',...[" Maj #{updateText} #{target.value} erreur:",br(),txt]

                        .catch (err)->
                            log " updateform error=",err
                            app.setAdminState 'error',[" Maj #{updateText} #{target.value} erreur",br(),"#{err}"]

            button
                onClick:(e)->
                    #log "filechoser button click e.target.parent=",e.target.parentElement
                    e.target.parentElement.click()
                    e.preventDefault()
                (icon iconName), say btnText
        if mess
            span '$paddingLeft=10px',mess
                
addTest= (str)-> if str then str+'Test' else str
addtest= (str)-> if str then str+'test' else str
addSTest= (str)-> if str then str+' Test' else str

actionFileBtnTest= (props={})->
    #log "actionFileBtnTest id=#{props.id}",props
    if !vx.q.TEST
        return actionBtn2 props

    if props.url or !vx.fileurls
        return actionBtn2 props
    
    { id='', urlId=id.toLowerCase(), url, urlTest,id2='',membre='' }= props
    mid2= (membre+id2).toLowerCase()
    url?= vx.q.urls?[id]  or vx.fileurls[urlId] or vx.fileurls[mid2]
    urlTest?= vx.q.urls?[addTest id]  or vx.fileurls[addtest urlId] or vx.fileurls[addtest mid2]
    
    log "actionFileBtnTest membre=#{membre} id2=#{id2} mid2=#{mid2} url=#{url} urlTest=#{urlTest} "
   
    
    if !url
        return actionBtn2 props  
    
    if !urlTest
        return actionBtn2 oA {},props,{url}
    
    {action,action2,updateText,btnText,testBtnText,title,testTitle}=props
    
    [
        actionBtn2 oA {},props,{url}
        actionBtn2 oA {},props,{url:urlTest,id:(addTest id),updateText:(addSTest updateText),btnText:(testBtnText or addSTest btnText),title:testTitle or addTest title}
    ]
   

actionBtn2= ({app,id='',id2='',membre='',urlId=id.toLowerCase(),action,action2,updateText,iconName='|menu',btnText,url,goto=true,sendData,title,noMAJ})->
    mid2= (membre+id2).toLowerCase()
    url2= url or vx.q.urls?[id]  or vx.fileurls[urlId] or vx.fileurls[mid2]
    #log "actionBtn2 membre=#{membre} id2=#{id2} mid2=#{mid2} url2=#{url2}"
    #is url2 a google sheet url?
    
    
    if url2
        parts= url2.split '/export?'
        if parts[1] is 'format=csv'
            url2= parts[0]
            
    sheetId=''
    if url2 #is url2 a google sheet url?
        parts2= url2.split 'docs.google.com/spreadsheets/d/'
        if parts2.length is 2
            sheetId= parts2[1]
            
    actionName= action2 or "save/#{action}"
    #log "actionBtn #{id} sheetId=#{sheetId} url2=#{url2} actionName=#{actionName}"
    
    sendData?= {url,membre}
    listItem span {id:"admin-#{id}"},...[
        if sheetId
            button '',
                id:"edit-#{id}-btn"
                target: "#{membre}-#{btnText}"
                href: "/admin-edit-sheet?sheetId=#{sheetId}&action=#{actionName}&update=#{updateText}"
                #onClick: doNavigate
                icon '|attach_file'
                say btnText #if goto and url        
        if url2
            button '$marginLeft:16px',
                id:"goto-#{id}-btn"
                href: url2
                target:id or '_blank'
                #onClick: doNavigate
                icon '|attach_file'
                say "Chiffrier" #if goto and url
        if !noMAJ
            form 'method=post encType=application/x-www-form-urlencoded $display=inline-block $visibility=hidden',
                id:     "maj-#{id}"
                title: title
                action: actionName
                onSubmit: (e)->
                    target=e.target
                    e.preventDefault()
                    app.setAdminState 'updating'," Maj #{updateText} automatique en cours"
                    log "action button doing updateForm with",{target,sendData}
                    updateFormJ target,sendData
                        .then (response)->
                            log "updateFormJ reponse.status=#{response.status}"
                            switch response.status
                                when 200
                                    app.setAdminState 'succes',"Maj #{updateText}  automatique réussie."
                                    #response.text().then (txt)-> app.setAdminState '',...[                                                       " Maj ShapeFile #{target.value} reussie", br(), txt ]
                                when 403
                                    app.setAdminState 'error403',"Authentifiez-vous à nouveau. Contactez la FCMQ si vous éprouvez des problèmes persistants."
                                    log "actionBtn2 got 403 error "
                                when 304
                                    app.setAdminState 'error304',"Aucun changement détecté. Modifez le chiffrier avant de faire la mise à jour."
                                    log "actionBtn2 got 304 error "
                                else
                                    app.setAdminState 'error'," Maj #{updateText}  automatique erreur..."
                                    response.text().then (txt)-> app.setAdminState '',...[" Maj #{updateText} automatique erreur:",br(),txt]

                        .catch (err)->
                            log " updateform error=",err
                            app.setAdminState 'error',[" Maj #{updateText} #{target.value} erreur",br(),"#{err}"]

                input 'type=hidden name=user',value:app.getUser()
                input 'type=hidden name=url2',value:url2 #CAREFULL was url, not sure why ????
                button 'type=submit',
                    id:"maj-#{id}-btn"
                    'data-url':url2
                    'data-id': id
                    onClick:(e)-> e.target.parentElement.click()
                    (icon iconName), say btnText
        ]


actionBtn= ({app,id='',urlId=id.toLowerCase(),action,action2,updateText,iconName='|menu',btnText,url,goto=true,sendData,title,membre})->
    url2= url or vx.q.urls?[id]  or vx.fileurls[urlId]
    
    #is url2 a google sheet url?
    
    
    if url2
        parts= url2.split '/export?'
        if parts[1] is 'format=csv'
            url2= parts[0]
            
    sheetId=''
    if url2 #is url2 a google sheet url?
        parts2= url2.split 'docs.google.com/spreadsheets/d/'
        if parts2.length is 2
            sheetId= parts2[1]
            
    actionName= action2 or "save/#{action}"
    #log "actionBtn #{id} sheetId=#{sheetId} url2=#{url2} actionName=#{actionName}"
    
    sendData?= {url,membre}
    listItem group {id:"admin-#{id}"},form 'method=post encType=application/x-www-form-urlencoded',
        id:     "maj-#{id}"
        title: title
        action: actionName
        onSubmit: (e)->
            target=e.target
            e.preventDefault()
            app.setAdminState 'updating'," Maj #{updateText} automatique en cours"
            log "action button doing updateForm with",{target,sendData}
            updateFormJ target,sendData
                .then (response)->
                    log "updateFormJ reponse.status=#{response.status}",response
                    switch response.status
                        when 200
                            app.setAdminState 'succes',"Maj #{updateText}  automatique réussie."
                            #response.text().then (txt)-> app.setAdminState '',...[                                                       " Maj ShapeFile #{target.value} reussie", br(), txt ]
                        when 403
                            app.setAdminState 'error403',"Authentifiez-vous à nouveau. Contactez la FCMQ si vous éprouvez des problèmes persistants."
                            log "actionBtn got 403 error ", url
                        when 304
                            app.setAdminState 'error304',"Aucun changement détecté. Modifez le chiffrier avant de faire la mise à jour."
                            log "actionBtn got 304 error ", url  
                        else
                            app.setAdminState 'error'," Maj #{updateText}  automatique erreur..."
                            response.text().then (txt)-> app.setAdminState '',...[" Maj #{updateText} automatique erreur:",br(),txt]

                .catch (err)->
                    log " updateform error=",err
                    app.setAdminState 'error',[" Maj #{updateText} #{target.value} erreur",br(),"#{err}"]

        input 'type=hidden name=user',value:app.getUser()
        input 'type=hidden name=url2',value:url2 #CAREFULL was url, not sure why ????
        button 'type=submit',
            id:"maj-#{id}-btn"
            'data-url':url2
            'data-id': id
            onClick:(e)-> e.target.parentElement.click()
            (icon iconName), say btnText
        if sheetId
            button '$marginLeft:16px',
                id:"edit-#{id}-btn"
                href: "/admin-edit-sheet?sheetId=#{sheetId}&action=#{actionName}&update=#{updateText}"
                #onClick: doNavigate
                icon '|attach_file'
                say "Edit" #if goto and url        
        if url2
            button '$marginLeft:16px',
                id:"goto-#{id}-btn"
                href: url2
                target:id or '_blank'
                #onClick: doNavigate
                icon '|attach_file'
                say "Ouvrir Chiffrier" #if goto and url


adminPage= (props={})->
            {app,key,active,id='admin',menuBtn}= props
            membre= vx.q?.srvr
            sName= getName membre,app.state.lang
            
            readyState=app.getAdminState()  
            adminMess= app.getAdminMessage()
            
            page {id,key,active},

                header2 '.appadminpage__header',
                    left: [ (menuBtn ''), tbTitle say "Administration #{sName}"]
                    right: img '.vx-user-pict',src:vx.q.pict if vx.q.pict
                content '.page__content-margin',
                  switch readyState
                    when 'ready' then sul '',...[
                        if vx.uiMode.adminMn  then details 'open',
                            summary '',h5 '',say 'Motoneige'
                            list '',{},...[
                                #Motoneige

                                if vx.uiMode.adminGraph
                                    fileUpload {app,id:'fileshape',action:'mnshapefile',updateText:"ShapeFile",iconName:'|attach_file',btnText:"bouton import shapefile",mess:"ATTENTION!: Vérifier surfaçage 10 minutes après mise à jour du shapefile."}

                                if vx.uiMode.adminAttraits
                                    actionFileBtnTest 
                                        app:app
                                        id:'FCMQAttraits'
                                        action:'updatefcmqattraits'
                                        updateText: "des services"
                                        btnText: "MAJ services"


                                if vx.uiMode.adminParcours
                                    actionFileBtnTest {app,id:'FCMQParcours',action:'parcoursinfoupdate',updateText:"des informations de circuits",btnText:"MAJ circuits"}
#
                                if vx.uiMode.adminMessage
                                    actionFileBtnTest {app,id:'FCMQMess',action:'majMessageFCMQ',updateText:"du message FCMQ",btnText:"MAJ Message FCMQ"}

                                if vx.uiMode.adminCondATR and !vx.uiMode.adminCondMaster
                                    actionFileBtnTest {app,id:'ATRCond',id2:'cond',membre,action2:vx.q.condATRAction or 'save2/majCondMNurl',updateText:"des condtions "+membre,btnText:"MAJ Conditions "+membre}

                                ...(if !vx.uiMode.adminCondMaster then [] else
                                    [
                                        actionFileBtnTest {app,membre:'FCMQ',id:'FCMQCond',action2:vx.q.condATRAction or 'save2/majCondMNurl',updateText:"du des condtions ATR FCMQ",btnText:"MAJ Conditions FCMQ"}
                                        
                                        actionBtn {app,id:'majConditionsATR',action:'majConditionsATR',updateText:" des conditions ATR",btnText:"majConditionsATR  tout reffiare",title:"relire tous les chiffriers, tout recalculer"}

                                        actionBtn {app,id:'regenConditionsATR',action:'regenConditionsATR',updateText:" des conditions ATR",btnText:"regenConditionsATR  tout regénérer",title:"tout regénérer, tout recacalculer, ce qui est fait après maj shape, NE PAS RELIRE CHIFFRIER"}

                                        actionBtn {app,id:'recalcConditionsATR',action:'recalcConditionsATR',updateText:" des conditions ATR",btnText:"recalcConditionsATR  tout recalculuer",title:"Tout recalculer, ce qui est fait après une maj de chiffrier"}
                                    ])


                                ...(if !(vx.uiMode.adminSurfFCMQ or vx.uiMode.adminCondMaster) then [] else
                                    [
                                        listItem group '#adminSurf',form 'method=post encType=multipart/form-data action=save/majSurfFCMQ',
                                            input 'type=hidden name=user',value:app.getUser()
                                            button 'type=submit',
                                                title: " redownloader dernier NTS et refaire maj"
                                                onClick:(e)-> e.target.parentElement.click()
                                                (icon '|menu'), say "Mettre a jour tout le surfaçage"
                                                
                                        listItem group '#adminSurf2',form 'method=post encType=multipart/form-data action=save/recalcSurfFCMQ',
                                            input 'type=hidden name=user',value:app.getUser()
                                            button 'type=submit',
                                                title: "Recalculer surfacage pour X derniers mois"
                                                onClick:(e)-> e.target.parentElement.click()
                                                (icon '|menu'), say "Recalculer tout le surfaçage"
                                                
                                    ])

                                ]


                        if vx.q.validEmails then details '#adminauto closed',{},...[
                            summary '',h5 '',say 'Autorisations'
                            list '',{},...( listItem '',{},"#{nom}:\t#{email}" for email,nom of vx.q.validEmails )
                            ]

                        ]
                    when 'updating' then [
                        h5 '',say "Mise à jour encours ..."
                        p '',adminMess
                        ]
                    when 'succes' then [
                        h5 '',
                            button
                                onClick: wait ->app.setAdminState 'ready',''
                                say "OK"
                            say "Mise à jour réussie!"
                        p '',adminMess
                        ]
                    when 'error304' then [
                        h5 '',
                            button
                                onClick: wait ->window.location.reload true
                                say "OK"
                            say " ATTENTION! Pas de changement"
                        p '',adminMess
                        ]
                    when 'error403' then [
                        h5 '',
                            button
                                onClick: wait ->window.location.reload true
                                say "OK"
                            say " ATTENTION! Erreur d'authetification"
                        p '',adminMess
                        ]
                    when 'error' then [
                        h5 '',
                            button
                                onClick: wait ->app.setAdminState 'ready',''
                                say "OK"
                            say "ATTENTION! Erreur de mise à jour:"
                        p '',adminMess
                        ]
                    else
                        log "render AppAdminPage unkown state #{readyState}"
                        [
                            h5 '',
                                button
                                    onClick: wait ->app.setAdminState 'ready',''
                                    say "OK"
                                say "État incounnu #{readyState}"
                            p '',adminMess
                            ]


module.exports= { adminPage,AdminPage: adminPage }

