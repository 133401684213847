{ log, timeStart, timeLog, timeEnd, meta, fromJS, setInterval2, setTimeout2, oDiffs, oAssign, oA, Global, sList }= vx= require 'vx/globals/Boot'
ModuleName= 'Ajax'

{ PureComponent }= require 'react'
PropTypes= require 'prop-types'
{ ElementFactory:EF }= require 'vx/ui/ReactElemSS'

{ sayModule, getLang }= require 'vx/i18n/I18n'
say= sayModule 'Ajax' # cheat use app for index and appIndex ModuleName


{ readFileP, writeFileP }= require 'vx/app/FilePromise2' # v8 was require 'FilePromise2' used for file cache (not used)
LZString=     require 'lz-string-gd'

if vx.isAPP
     isApp= true
else isApp= false

isDEV= vx.isDEV

# iconito client in http patch 
localStorage= undefined
try
    localStorage= Global.localStorage
    lslen= localStorage.length
catch err
    localStorage= undefined
    


dateAsVersionNumber= (time)->
    d= new Date time
    return '?' if isNaN d
    an=    d.getFullYear()-2007 #so toString is a for 2017
    mois=  d.getMonth()+1
    heure= d.getHours()
    mins=  d.getMinutes()
    jour=  d.getDate()

    if mois < 8
         mois+= 5
         an+=  -1
    else mois+= -7

    "#{an.toString 36}#{((mois*100)+jour+(0.01*heure)+(0.0001*mins)).toFixed 4}"



NoChangeError= new Error 'No Change'

ajax= EF class Ajax extends PureComponent

    @propTypes =
        URL:        PropTypes.string
        onNewValue: PropTypes.func
        onNewHeader:PropTypes.func
        name:       PropTypes.string # passed as second arg to onNewValue
        fetchOps:   PropTypes.object
        noData:     PropTypes.object # optionnal
        revive:     PropTypes.func
        debug:      PropTypes.bool
        wait:       PropTypes.number # seconds
        online:     PropTypes.boolean

    constructor:(props)->
        super props
        @state= props.initData or {}
        return @


    bind: ->
        args= Array::splice.call arguments,0
        fnc= args.pop()
        args.unshift @
        fnc.bind.apply fnc,args


    render: ->
        #log "#{MN} render "
        null


    componentDidMount: ->
        #log "#{MN} componentDidMount "
        if @props.wait
            self=@
            @waiting= setTimeout2 @props.wait*1000,->
                self.waiting=null
                self.loadFirstData()
            return

        @loadFirstData()


    componentDidUpdate:(prevProps)->

        log " Ajax.componentDidUpdate(#{@props.name}) " if @props.debug
        for name,{a:oldVal,b:newVal} of diffs= oDiffs prevProps,@props
            switch name
                when 'URL'  then @loadUpdate()
                when 'online' then @_didUpdate_online newVal,oldVal,diffs,prevProps
                else
                    log " changed #{name} from '#{oldVal}' to '#{newVal}' ignored" if @props.debug


    _didUpdate_online: (newVal,oldVal,diffs)->
        log "Ajax. _didUpdate_online(#{@props.name}) from #{oldVal} to #{newVal}" if @props.debug
        if newVal and @loadDataWaitingForOnline and 'URL' not of diffs
            mode= @loadDataWaitingForOnline
            log "Ajax. _didUpdate_online(#{@props.name}) doing loadData mode=#{mode}" if @props.debug
            @loadDataWaitingForOnline= null
            @loadData mode
        #else if newVal and @poolWaitingForOnline and 'URL' not of diffs

    fetch: (URL,ops)->
        log "Fetch  #{@props.name}  #{URL}" if @props.debug
        protocole= (URL.split ':',1)[0]
        #log " ajax.fetch isAPP=#{vx.isAPP} is App=#{isApp} protocole=#{protocole} URL=#{URL}"

        #if (URL.indexOf('?') is -1) and not ( protocole is 'file' or protocole is 'cdvfile')
        #    URL+= "?_var_path=#{window.location.pathname}"

        if fetch and not ( protocole is 'file' or protocole is 'cdvfile')
            log " ajax fetch  #{@props.name} vx.isAPP=#{vx.isAPP}  URL=#{URL} ops=",ops if @props.debug
            fetch URL,ops
        else
            log " ajax XHRfetch  #{@props.name}  isAPP=#{vx.isAPP}  URL=#{URL} forcing xhr ops=",ops if @props.debug
            @fetchXHR URL,ops



    loadFirstData: -> @loadData 'first'

    loadUpdate: ->    @loadData 'update'


    loadData: (mode,props=@props)->

        { URL, online, fetchOps, onNewValue, noValue, name, debug, progress, app}= props

        log " AJAX loadData(#{@props.name}) mode=#{mode} URL=#{URL} " if debug
        if URL
            if online
                if progress
                    app.toast (say "Un moment téléchargement ")+say name
                @loadDataWaitingForOnline= null
                return @processFetchPromise mode,(@fetch URL,fetchOps),URL
                #log " AAAAAA #{ModuleName}.#{@name} loadData no URL self=",@
            else
                @loadDataWaitingForOnline= mode
                #onNewValue (noValue or []),name,{offline:true} if onNewValue #NO cause we could already have data
        else
            @loadDataWaitingForOnline= null #cause no url
            onNewValue (noValue or []),name,{nourl:true} if onNewValue
            
        Promise.resolve null


    getDoCheckResponse: (mode)->
        self= @
        (response)->
            debug= self.props.debug
            log "ajax.DoCheckResponse(#{self.props.name}) #{mode}  URL=#{response.url} response=",response  if debug and debug isnt 2
            log "  AJAX Cache info etag=#{response.headers.get? 'etag'} modif=#{response.headers.get? 'Last-Modified'} ctrl=#{response.headers.get? 'Cache-Control'} " if debug
            if !response.ok and !( response.status==0 and self.props.URL[0 .. 4] is 'file:')
                # bad response thow error
                log " AJAX Error  #{self.props.name}  #{ModuleName}.#{self.displayName} url=#{response.url} Fetch got response=",response
                error= new Error "Fetch  #{self.props.name}  error status=#{response.status}"
                error.response= response
                throw error

            response


    getDoResponse: (mode)->
        self= @
        (response)->
            log "ajax.DoResponse(#{self.props.name}) #{mode} " if self.props.debug
            lastResponseInfo=
                #ETag:         response.headers.get? 'ETag'
                etag:         response.headers.get? 'etag'
                LastModified: if lm=(response.headers.get? 'Last-Modified') then new Date(lm).getTime()
                CacheControl: response.headers.get? 'Cache-Control'
                url:          response.url
                responseTime: new Date().getTime()
            log "  last response info is ",lastResponseInfo if self.props.debug
            log "  debug ",{Promise,all:Promise.all,resolve:Promise.resolve,lastResponseInfo} if self.props.debug
            Promise.all [response.text(),Promise.resolve  lastResponseInfo] # could return a promise


    getDoData: (mode)->
        self= @
        ([data,responseInfo])->
            log "ajax.DoData(#{self.props.name}) #{mode} Fetch got data self.props.onNewValue= #{typeof self.props.onNewValue } self.props.revive=#{typeof self.props.revive} =#{typeof data}" if self.props.debug

            try
                jsonret= JSON.parse data,self.props.revive
            catch err
                if self.props.errData?
                    jsonret=self.props.errData?
                else
                    throw err

            log "  AJAX OK did json parse on text got",{jsonret} if self.props.debug is 2

            self.lastResponseInfo= responseInfo
            log "  set last response to ",self.lastResponseInfo if self.props.debug

            try

                self.props.onNewValue jsonret,self.props.name,responseInfo if self.props.onNewValue
            catch err
                log "onNewValue error #{err}"
                if self.props.errData
                    log "onNewValue error trying errdata"
                    self.props.onNewValue self.props.errData,self.props.name,responseInfo if self.props.onNewValue
                    log "no error setting result to errdata"
                    jsonret= self.props.errData


            self.props.onNewHeader self.lastResponseInfo,self.props.name,jsonret if self.props.onNewHeader

            [jsonret,responseInfo]


    getDoError: (mode,type=0)->
        self= @
        (err)->
            log "ajax.DoError(#{self.props.name}) mode=#{mode} type=#{type} err=#{err}",err

            if self.props.noData? and self.props.onNewValue?
                log "  AJAX #{ModuleName}.#{self.props.name} Fetch got IGNORED error #{err} noData=",self.props.noData
                #console.trace(err)
                #log err.stack
                #self.props.onNewValue self.props.noData,self.props.name,err
                log "return nodata"
                return self.props.noData
            else
                log "  AJAX #{ModuleName}.#{self.props.name} Fetch got error #{err} mode=#{mode} throw error"
                throw err



    processFetchPromise: (mode,fetchPromise,URL)->
        timeStart "fetch #{URL}"
        fetchPromise
            .then  @getDoCheckResponse mode
            #.catch @getDoError    mode,1
            .then  @getDoResponse mode
            #.catch @getDoError    mode,2
            .then  @getDoData     mode
            .catch @getDoError    mode,3
            .then ->timeEnd "fetch #{URL}"



    fetchXHR: (URL,options={})->
        # from: https://github.com/developit/unfetch
        log "XHR fetch  #{@props.name}  #{URL}" if @props.debug
        self= @ # just to test debug
        new Promise (resolve, reject)->
            request = new XMLHttpRequest()
            request.open ( options.method or 'get'),URL

            for name,val of options.headers
                request.setRequestHeader name,val

            response= ->
                keys=   []
                all=    []
                headers={}
                log "XHR  #{self.props.name} #{URL} headers=\n#{request.getAllResponseHeaders()}" if self.props.debug
                request.getAllResponseHeaders().replace /^(.*?):\s*([\s\S]*?)$/gm, (m, key, value)->
                    keys.push key= key.toLowerCase()
                    all.push [key, value]
                    header=  headers[key]
                    headers[key]= if header then "#{header},#{value}" else value
                log "XHR  #{self.props.name} response= ",{req:request,respHeaders:headers,status:request.status}  if self.props.debug

                ok:         (request.status/200|0) == 1        # 200-299
                status:     request.status
                statusText: request.statusText
                url:        request.responseURL
                clone:     response
                text: -> Promise.resolve request.responseText
                json: -> (Promise.resolve request.responseText).then JSON.parse
                blob: -> Promise.resolve new Blob [request.response]
                headers:
                    keys:    -> keys
                    entries: -> all
                    get: (n)-> headers[n.toLowerCase()]
                    has: (n)-> n.toLowerCase() of headers


            request.withCredentials = options.credentials is 'include'

            request.onload= -> resolve response()

            request.onerror= reject

            request.send() # options.body



ajaxCached= EF class AjaxCached extends Ajax

    @propTypes =
        cacheName:   PropTypes.string
        cacheMode:   PropTypes.string
        poolMessage: PropTypes.string # or string
        poolTime:    PropTypes.number
        progress:    PropTypes.number
        app:         PropTypes.object


    getDoData: (mode)->
        doData= super mode
        switch mode
            when 'firstcache'
                self= @
                (info)->
                    self.startPooling()


                    PdoData= Promise.resolve info
                        .then doData
                    if vx.isAPP
                        PdoData.then self.getDoPostMessage mode

                    Promise.resolve info
                        .then (info)->
                            log "AjaxCached.saving(#{self.props.name}) to cache" if self.props.debug
                            info
                        .then  ->self.doSaveToCache info
                        .catch self.getDoError mode

                    PdoData

            when 'reload'
                self= @
                (info)->
                    Promise.resolve info
                        .then  ->self.doSaveToCache info
                        .catch self.getDoError
                    Promise.resolve info
                        .then doData
            else doData


    doSaveToCache: (info)->
        dbg= @props.debug
        [text,responseInfo]= info
        log "AjaxCached.doSaveToCache(#{@props.name}) !!!!! got #{text.length} chars" if dbg

        ls= localStorage
#         try
#             ls=Global.localStorage
#             ls.length # in some cases this througs an error
#         catch err
#             ls= undefined

        if ls and cacheName=(@props.cacheName)
        #    ls.setItem cacheName,text
        #    ls.setItem "#{cacheName}--Info",JSON.stringify oAssign {ver:1,len:text.length},responseInfo
            ls.removeItem "#{cacheName}--Info"


            savePromise= switch cmode= @props.cacheMode or 'file'
                when 'ls-head'
                    Promise.resolve '[]'
                when 'file'
                    log "AjaxCached.doSaveToCache before writeFileP " if dbg
                    writeFileP "#{cacheName}.json",text+'\n\n'
                when 'ls'
                    try
                        lzText= LZString.compressToUTF16 text
                        log "AjaxCached.doSaveToCache #{cacheName} before ls write text text.len=#{text.length} lz.len=#{lzText.length}." if dbg
                        ls.setItem cacheName,lzText
                        ret= Promise.resolve text
                    catch err
                        log "AjaxCached.doSaveToCache  ls write text error",err if dbg
                        ret= Promise.reject err
                    log "AjaxCached.doSaveToCache after ls write text" if dbg
                    ret
                else
                    log "AjaxCached.doSaveToCache(#{@props.name}) unknown mode #{cmode} NOT SAVED"
                    Promise.reject new Error  "AjaxCached.doSaveToCache unknown mode #{cmode}"

            savePromise.then ->
                log "AjaxCached.doSaveToCache(#{cacheName}) finished write of #{cacheName}--Info" if dbg
                ls.setItem "#{cacheName}--Info",JSON.stringify oAssign {ver:2,len:text.length},responseInfo
                info

        else
            log "AjaxCached.doSaveToCache(#{@props.name}) skip no LS or no cacheName #{cacheName}.json" if dbg
            Promise.resolve info


    getCacheInfo: ->
        dbg= @props.debug

        if (!ls=localStorage) or !cacheName=(@props.cacheName)
            log "AjaxCached.getCacheInfo (#{@props.name}) miss no localstorage or no cacheName" if dbg
            return Promise.resolve null # cant cache
        try
            pname= @props.name
            log "AjaxCached.getCacheInfo ls.getItem #{cacheName}--Info" if dbg
            timeStart 'cacheLoad' if dbg
            responseInfoTxt= ls.getItem "#{cacheName}--Info"
            log "AjaxCached.responseInfoTxt(#{pname})  = #{responseInfoTxt}" if dbg
            return Promise.resolve null if !responseInfoTxt
            responseInfo= JSON.parse responseInfoTxt
            log "AjaxCached.responseInfoTxt(#{pname}) = ",responseInfo if dbg
            return Promise.resolve null if @props.cacheMode!='ls-head' and !responseInfo?.len

            self= @

            readPromise= switch cmode= @props.cacheMode or 'file'
                when 'ls-head'
                    Promise.resolve '[]'
                when 'ls'
                    try
                        lzText=  ls.getItem cacheName
                        if  responseInfo.ver >=2
                             text= LZString.decompressFromUTF16 lzText
                        else text= lzText
                        if text.length isnt responseInfo?.len
                            log " ls cache #{cacheName} length miss macth got #{text.length} expected #{responseInfo?.len}"
                            throw new Error " ls cache #{cacheName} length miss macth got #{text.length} expected #{responseInfo?.len}"
                        Promise.resolve text
                    catch err
                        Promise.resolve null

                when 'file'
                    readFileP "#{cacheName}.json"
                        .catch -> null

                else
                    log "AjaxCached.getCacheInfo(#{@props.name}) unknown cacheMode #{cmode}"
                    Promise.resolve null



            readPromise.then (cacheData)->
                log "AjaxCached.getCacheInfo(#{pname}) got cacheData len=#{cacheData.length} name=#{cacheName}  " if dbg
                return null if self.props.cacheMode!='ls-head' and ( !cacheData or  cacheData.length isnt responseInfo.len )
                if !self.props.URL or self.props.URL isnt responseInfo.url?[ -self.props.URL.length .. ]
                    log "AjaxCached.cache(#{pname}) url mismatch "
                    log " cacheurl= #{responseInfo.url} sub= #{self.props.URL and responseInfo.url?[ -self.props.URL.length .. ]}"
                    log " props.URL=#{self.props.URL}"
                    return null
                # ok cache hit succes!
                timeEnd 'cacheLoad' if dbg
                [cacheData,responseInfo]


        catch err
            log "AjaxCached.Cache(#{@props.name}) miss error #{err} ",err
            Promise.resolve null #no cache info


    loadFirstData: ->
        {progress,app,name,online,app}= @props
        toast= app.toast
        
        log "AjaxCached loadFirstData for #{name}:#{@props.cacheName or ''} = #{@props.URL} progress=#{progress}"  if @props.debug
        self= @
        
        if  progress
            app.toast (say "Un moment lecture des")+' '+say name
        if url=@props.URL
            timeStart "getFirst #{url}"
            @getCacheInfo()
                .then (cacheInfo)->
                    timeLog "getFirst cache #{url}"
                    if cacheInfo
                        #simulate a first load
                        log "AjaxCached.Cache(#{self.props.name}) hit with ",cacheInfo if self.props.debug
                        mode= 'fromcache'
                        #dont know why but faster to wait a bit
                        setTimeout2 10,->
                            Promise.resolve cacheInfo
                                .then  self.getDoData  mode
                                .catch self.getDoError mode
                                .then  self.getCheckCache 'pooling'
                                .then  ->self.startPooling()
                                .catch (e)->
                                    log "AjaxCached.loadFirstData(#{self.props.name}) ->getCacheInfo getCacheData catch #{e} "
                                    log "AjaxCached.loadFirstData(#{self.props.name}) ->getCacheInfo getCacheData catch do start pooling anyways ",cacheInfo if self.props.debug
                                    self.startPooling()
                                .then ->timeEnd "getFirst cache #{url}"
                    else 
                        # TODO make this DRY
                        log "AjaxCached.Cache(#{self.props.name}) miss" if self.props.debug
                        if online or !self.props.backupURL
                            if progress
                                app.toast (say "Un moment telechargement")+' '+say name
                            self.loadData 'firstcache'
                        else
                            #OK we have a backup solution try that 'while waiting for first cache
                            self.loadDataFromBackup()
                            
                        timeEnd "getFirst cache #{url}"
                .then -> if progress
                    app.toast ((say "Lecture des")+' '+(say name)+' '+say 'terminée'),"OK"
        else
            log "AjaxCached.(#{self.props.name})  no URL" if @props.debug
            if @props.backupURL
                #OK we have a backup solution try that 'while waiting for first cache
                return @loadDataFromBackup().then -> if progress
                    app.toast ((say "Lecture des")+' '+(say name)+' '+say 'terminée'),"OK"
            if progress
                app.toast (say "Un moment telechargement")+' '+say name
            @loadData 'firstcache'
            .then -> if progress
                app.toast ((say "Lecture des")+' '+(say name)+' '+say 'terminée'),"OK"


    loadDataFromBackup: (props=@props)->

        { backupURL, online, fetchOps, onNewValue, noValue, name, debug}= props

        mode= 'fromBackup'
        log "AjaxCached. (#{@props.name}) AJAX loadDataFromBackup mode=#{mode} URL=#{backupURL} " if debug

        # we know that backURL works even if not online
        timeStart "fectch backup #{backupURL}"
        if backupURL
            self=@
            @fetch backupURL,oA( xhr:1,fetchOps)
                .then  @getDoCheckResponse mode
                .then  @getDoResponse mode
                .then  @getDoData     mode
                .catch @getDoError    mode
                #.then  -> self.loadData 'firstcache'
                .then  -> timeEnd "fectch backup #{backupURL}"
                .then ->self.startPooling()
        else
            @loadDataWaitingForOnline= null #cause no url
            onNewValue (noValue or []),name,{nourl:true} if onNewValue
            timeEnd "fectch backup #{backupURL}"
            Promise.resolve null

    getCheckCache: (mode)->
        #check if we need to reload cache
        self=@
        (-> self.doPoolData mode)


    stopPooling:->
        log "AjaxCached.stopPooling(#{@props.name} pooler=#{@pooler}" if @props.debug
        return if !@pooler
        clearInterval @pooler
        @pooler= null


    startPooling:->
        log "AjaxCached.startPooling(#{@props.name}) poolTime=#{@props.poolTime} online=#{@props.online} pooler=#{@pooler}" if @props.debug
        if !@props.online
            #we are offline ... just set Waitingto pool flag
            log "AjaxCached.startPooling(#{@props.name}) offline should never happen or very very rare poolDataWaitingForOnline=#{@poolDataWaitingForOnline}"
            @poolDataWaitingForOnline= true
            return
        return if !@props.poolTime or @pooler # dont reset if already pooling
        @pooler= setInterval (@doPoolData.bind @),(1000*@props.poolTime)



    doPoolData: (mode)-> # check to see if data was changed, return a promise that will resolve to text if update required or null if not
        log "AjaxCached.doPoolData(#{@props.name}) pooling #{!!@pooling} lri=#{JSON.stringify @lastResponseInfo} " if @props.debug
        log "  self=",self:@ if @props.debug and @props.debug isnt 2
        if @pooling # already waiting for answer
            log "  AjaxCached.ajax already pooling"
            return @pooling

        if !@props.online
            log "  AjaxCached.doPoolDataOffLine ... wait" if @props.debug
            @poolDataWaitingForOnline= true
            @stopPooling()
            return

        if !@lastResponseInfo
            # ok noting to comapre to force update or do nothing, or error
            # self.pooling is already false
            log "  AjaxCached.ajax no lastResponseInfo check anyways" if @props.debug
            #return new Promise (resolve,error)-> resolve null



        URL= @props.URL # this is async in theorie props.URL could change
        self= @
        mode= 'pool'
        @pooling= @fetch URL,
                __proto__: @props.fetchOps or {}
                method:'HEAD' # actually with fetch get/no-cache pool and update
                cache:'no-cache'
            .then @getDoCheckResponse mode
            .then @getDoCheckHeaderForUpdate mode,URL
            .then @getDoClearPooling mode
            .catch (err)->
                self.pooling= null # in case not already done ...
                if err is NoChangeError
                    log "AjaxCached.DoPoolData(#{self.props.name}) no change error" if self.props.debug
                    return null # ignore
                # ahh error we dont care just forget about it next pool we hope will work
                log "AjaxCached.DoPoolData(#{self.props.name}) error #{URL} failed got error #{err}"


    _didUpdate_online: (newVal,oldVal,diffs,prevProps)->
        log "AjaxCached._didUpdate_online(#{@props.name}) from #{oldVal} to #{newVal}" if @props.debug
        super newVal,oldVal,diffs,prevProps
        if newVal
            @startPooling()
        if newVal and @poolDataWaitingForOnline
            mode= @poolDataWaitingForOnline
            log "  AjaxCached._didUpdate_online doing doPoolData mode=#{mode}" if @props.debug
            @poolDataWaitingForOnline= null
            @doPoolData mode
        #else if newVal and @poolWaitingForOnline and 'URL' not of diffs



    getDoClearPooling: (mode) ->
        self= @
        (response)->
            log "AjaxCached.doClearPooling(#{self.props.name}) #{mode}" if self.props.debug
            self.pooling= null

    getDoCheckHeaderForUpdate:(mode,URL)->
        self= @

        (response)->
            debug= self.props.debug
            log "AjaxCached.CheckHeaderForUpdate(#{self.props.name}) #{mode} " if debug
            if self.lastResponseInfo
                # check if we need to load
                { etag:lastETag, LastModified:lastLastModified}= self.lastResponseInfo
                etag= response.headers.get? 'etag'
                LastModified= if lm=(response.headers.get? 'Last-Modified') then new Date(lm).getTime() else  null

                if etag? and lastETag? and etag == lastETag
                    log "AjaxCached.CheckHeaderForUpdate(#{self.props.name}) #{mode} ignore update same etag #{etag} " if debug
                    #self.pooling= false # ????
                    #update lastResponseInfo for current time
                    self.lastResponseInfo= oAssign {},self.lastResponseInfo,{responseTime:new Date().getTime()}
                    self.props.onNewHeader self.lastResponseInfo,self.props.name,undefined if self.props.onNewHeader
                    return response

                if LastModified? and lastLastModified? and LastModified == lastLastModified
                    log "AjaxCached.CheckHeaderForUpdate(#{self.props.name}) #{mode} ignore update same lastModified #{LastModified} " if debug
                    #self.pooling= false # ????
                    self.lastResponseInfo= oAssign {},self.lastResponseInfo,{responseTime:new Date().getTime()}
                    self.props.onNewHeader self.lastResponseInfo,self.props.name,undefined if self.props.onNewHeader
                    return response

                log "AjaxCached.CheckHeaderForUpdate(#{self.props.name}) lastResponse check failed, reloading" if debug
                log "  etag=#{etag} lastETag=#{lastETag}" if debug
                log "  lastModified=#{LastModified} lastLastModified=#{lastLastModified}" if debug


            else
                log "AjaxCached.CheckHeaderForUpdate(#{self.props.name}) check skipped no lastResponse reloading " if debug

            dataResponse= null
            self.pooling= self.fetch URL,oAssign {method:'GET',cache:'no-cache'},self.props.fetchOps
                .then self.getDoCheckResponse 'reload'
                .then (response)->
                    dataResponse= response
                .then self.getDoResponse      'reload'
                .then self.getDoDataPool      'reload'
                .then self.getDoPostMessage   'reload'
                .then self.getDoClearPooling  'reload'


    getDoDataPool: (mode)-> @getDoData mode


    getDoPostMessage: (mode)->
        self= @
        (info)->
            log "AjaxCached.doPostMessage(#{self.props.name}) #{mode} self=",{self:@} if self.props.debug
            if (mess=self.props.poolMessage) and self.props.app?.snackbarMessage
                    log "AjaxCached.doPostMessage(#{self.props.name}) mess=#{mess}",mess if self.props.debug
                    self.props.app?.snackbarMessage
                        message: mess
                        info:    oAssign {},self.lastResponseInfo
            else
                log "AjaxCached.doPostMessage(#{self.props.name}) no message ",mess if self.props.debug
            info



module.exports= { Ajax, ajax, AjaxCached, ajaxCached, dateAsVersionNumber }


