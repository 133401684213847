{ log, isArray, lang, before }= vx= require 'vx/globals/Boot'

{ Fragment }= require 'react'

before './ReactElemSS'
{ ElementFactory:EF  }= require './ReactElemSS'

before 'react'
{ createElement, useCallback, Children, cloneElement }= require 'react'
cE= createElement
childrenToArray= Children.toArray

require './RMWCWidgets.css' # auto gen see # # # CSS: below

# *********************** Elevation  ***************************
before '@rmwc/elevation'
{ Elevation }= require '@rmwc/elevation'
before 'after @rmwc/elevation'
require '@rmwc/elevation/styles'
before 'after @rmwc/elevation styles'
elevation= EF Elevation
before 'after @rmwc/elevation loaded'


# ************************ ICON *********************************

icon= EF Icon=({color,...props})->
        props.className= "material-icons #{props.className or ''}"
        props.style= {color,...props.style} if color
        props['data-icon']= props.children  if typeof props.children is 'string'
        createElement 'i',props

### CSS:

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;

    -moz-osx-font-smoothing: grayscale;

    font-feature-settings: 'liga';
    }

###




# ****************************** Button *******************************

before '@rmwc/button'
{Button}= require '@rmwc/button'
require           '@rmwc/button/styles'


rmwcButton= EF RMWCButton= ({flat,href,target,...props})->
    props.raised= !flat
    if href
         createElement 'a',{href,target,className:'vx-link'},createElement Button,props
    else createElement Button,props
    
### CSS:
    .mdc-button__label > *  {
        vertical-align:middle;
        }

    .mdc-button + .mdc-button {
        margin-left: 8px;
        }
###

valueButton= EF RMWCButton= ({flat,onNewValue,href,target,...props})->
    props.raised= !flat
    
    onClick= useCallback ((e)-> 
        ev= e.target.closest '[value]'
        #log "valueButton onClick->onNewValue value=#{ev?.value} name=#{ev?.name}",{e,target:e.target,vtarget:ev}
        onNewValue ev?.value,ev?.name
        ),[onNewValue]
    
    if onNewValue
        if props.onClick
            log "Clobbered onClick #{props.id}",props
        props.onClick= onClick
        
    if href
         createElement 'a',{href,target,className:'vx-link'},createElement Button,props
    else createElement Button,props


        
before '@rmwc/fab'
{Fab}= require '@rmwc/fab'
require        '@rmwc/fab/styles'

fab= EF Fab,theme:['primary','surface']

### CSS:

    .mdc-fab {
        align-items: center;
        }

    button.mdc-fab--plain {
        background-color: white;
        background-color: var( --mdc-theme-background,white);
        }
###



        
link= EF Link=({...props})->
    props.className= "vx-link #{props.className or ''}"
    createElement 'a',props
    
### CSS:
	a.vx-link {
	    text-decoration-line:none;
	}
###


# ********************** Inputs ********************************
before '@rmwc/formfield'
{ FormField }= require '@rmwc/formfield'
require     '@rmwc/formfield/styles'


{ Ripple }= require '@rmwc/ripple'
require     '@rmwc/ripple/styles'



#TODO: megre this code nearly same thing

fileChooser= EF FileChooser= ({id,onNewValue,left,right=!left,children,type='file',formFieldProps,labelProps,value='*.*',...props})->

    doOnChange= useCallback ((e)-> onNewValue e.target.file,e.target.name if onNewValue), [onNewValue]
    
    onChange= if onNewValue then doOnChange else undefined
    
    inputProps= {id,type,onChange,filename:value,props...}
    
    
    #cE Fragment,null,
        #cE 'input',inputProps if id
    cE FormField,{className:"vx-input vx-input-#{type}",id:(if id then id+'-formfield'),formFieldProps...},
        cE Ripple,null,
            cE 'label',{id:(if id then id+'-label'),labelProps...}, # htmlFor:id,
                children if left
                cE 'input',inputProps #if !id
                children if right
                

checkbox= EF Checkbox= ({id,onNewValue,left,right=!left,children,type='checkbox',formFieldProps,labelProps,value=false,offValue=false,onValue=true,...props})->

    doOnChange= useCallback ((e)-> onNewValue (if e.target.checked then onValue else offValue),e.target.name if onNewValue), [onNewValue]
    
    onChange= if onNewValue then doOnChange else undefined
    
    inputProps= {id,type,onChange,checked:(!!value==!!onValue),props...}
    
    cE FormField,{className:"vx-input vx-input-#{type}",id:(if id then id+'-formfield'),formFieldProps...},
        cE Ripple,null,
            cE 'label',{id:(if id then id+'-label'),labelProps...},
                children if left
                cE 'input',inputProps
                children if right


radio= EF Radio= ({id,onNewValue,left,right=!left,children,type='radio',formFieldProps,labelProps,...props})->

    doOnChange= useCallback ((e)-> onNewValue (if e.target.checked then onValue else offValue),e.target.name if onNewValue), [onNewValue]
    
    onChange= if onNewValue then doOnChange else undefined
    
    inputProps= {id,type,onChange,props...}
    
    cE FormField,{className:"vx-input vx-input-#{type}",id:(if id then id+'-formfield'),formFieldProps...},
        cE Ripple,null,
            cE 'label',{id:(if id then id+'-label'),labelProps...},
                children if left
                cE 'input',inputProps
                children if right


radioGroup= EF RadioGroup= ({id,className,onNewValue,children,name:groupName='radioGroup',value:groupValue,...props})->

    doOnChange= useCallback (
        (e)->
            if onNewValue and e.target.checked
                onNewValue e.target.value,name
            )
        ,[onNewValue]
    
    onChange= if onNewValue then doOnChange else undefined
    
    changed= false
    childs=for child in Children.toArray children
        {name,value,checked}= child?.props or {}
        if (!value?) or (name and name isnt groupName)
            # not of concerne
            child
        else if name and  checked? and ( checked is ( value == groupValue) )
            # already ok
            child
        else
            changed= true
            cloneElement child,{name:groupName,checked:value==groupValue}

    children=childs if changed
    if className
         className= "vx-radiogroup #{className}"
    else className= 'vx-radiogroup'
    
    group {props...,onChange,className,children}




# **********************  Hearer / App Bar  ********************
before '@rmwc/top-app-bar'
{ TopAppBar, TopAppBarRow, TopAppBarSection, TopAppBarTitle, TopAppBarActionItem }= require '@rmwc/top-app-bar'
require '@rmwc/top-app-bar/styles'

topBar= EF TopBar= ({left,right,leftShrink,rightShrink,style,...props})->
    style?={}
    style.top= '0 !important'
    createElement TopAppBar,{dense:true,theme:['onDark'],style,...props},
        createElement TopAppBarRow,null,
            createElement TopAppBarSection,{alignStart:true},(left or leftShrink)  if left  or leftShrink
            createElement TopAppBarSection,{alignEnd:true},  (right or rightShrink )     if right or rightShrink
            
tbTitle= EF TopAppBarTitle
tbIcon=  EF TopAppBarActionItem

### CSS:
// top-app-bar adjust because fixe by default {}
article.page      > header.mdc-top-app-bar { position: relative; }
aside.side-panel  > header.mdc-top-app-bar { position: relative; }
.map-popup__inner > header.mdc-top-app-bar { position: relative; }
header.mdc-top-app-bar { top: 0 !important;}
//Menu patch for header {}
.mdc-top-app-bar__title.was-menu {
    overflow:visible;
}
###



# ************** Dialog   *************************************
before '@rmwc/dialog'
{ Dialog, DialogTitle, DialogContent, DialogActions, DialogButton }= require '@rmwc/dialog'
require '@rmwc/dialog/styles'

mDialog= EF MDialog= (inProps)->
    {visible,cancel,ok,onOk,onValue,children,title,disabled,debug,...props}= inProps
    cE Dialog,{onClick:onValue,props...,open:visible},
        cE DialogTitle,null,title   if title
        cE DialogContent,{children} if children
        if ok or cancel
            cE DialogActions,null,
                #cE DialogButton,{action:'accept',disabled,children:ok}     if ok
                cE DialogButton,{action:'cancel',disabled,children:cancel} if cancel
                #{visible,cancel,ok,onOk,onCancel,children,title,disabled,debug,...props}= inProps
                #cE Dialog,{props...,open:visible},
                cE DialogButton,{action:'accept',onClick:onOk,disabled,children:ok}     if ok
                #cE DialogButton,{action:'cancel',onClick:(()=>console.log('hi')),disabled,children:cancel} if cancel


# ************** Snackbar **********************

before '@rmwc/snackbar'
{ createSnackbarQueue, SnackbarQueue }= require '@rmwc/snackbar'
require '@rmwc/snackbar/styles'

MainSnackbarQueue= createSnackbarQueue()
mainSnackbar= (props={})->
    createElement SnackbarQueue,{ ...props, theme:[ 'onDark'], messages:MainSnackbarQueue.messages}

### CSS:

 .mdc-snackbar__surface, .mdc-snackbar__label, .mdc-snackbar__surface mdc-button__label {
    color: white !important;
    }
###


# ***************** List **************************************
before '@rmwc/list'
{ List, SimpleListItem, ListItem, ListDivider }= require '@rmwc/list'

require '@rmwc/list/styles'

list= EF rmwcList= (props)->
    if childs=props.children
        if !isArray childs
            childs=[childs]
        changed=0
        newChilds=for child in childs when child
            switch
                when (child.type is SimpleListItem) or (child.type is ListDivider) or (child.type is ListItem )
                    child
                else
                    changed++
                    createElement SimpleListItem,null,child
        if changed
            log "RMWCList changed #{changed}"
            props={...props,children:newChilds}
        createElement List,props
        
listItem= EF SimpleListItem
listDivider= EF ListDivider


# *********************** MENU ******************************
before '@rmwc/menu'
{ Menu, MenuSurfaceAnchor }= require '@rmwc/menu'
require '@rmwc/menu/styles'

menu=EF Menu
menuSurfaceAnchor= EF MenuSurfaceAnchor


# *********************** Grid/layout ***********************
before '@rmwc/grid'
{ Grid, GridCell }= require '@rmwc/grid'
require '@rmwc/grid/styles'

layout= EF Grid
cell=   EF GridCell


# ********************** NON RMWC widgets ***************************

# ********************** group **************************************

group= EF Group= ({veritcal,horizontal,alignEnd,...props})->
    if horizontal
         props.className="group group--horiz#{alignEnd and ' group--alignend' or ''} #{props.className or ''}"
    else props.className="group group--vert #{props.className or ''}"
    
    createElement 'div',props
    
### CSS:

.group--horiz {
    display:flex;
    flex-direction: row;
    }

.group--horiz > * {
    flex: 1 1 auto ;
    display: inline-block;
    }

.group--horiz.group--alignend > *, .group--horiz > *.group--alignend {
     flex: 0 0 auto ;
    }

.group--horiz.group--alignend {
    justify-content: flex-end;
    }

.group--vert {
    display:flex;
    flex-direction: column;
    }

.group--vert > * {
    flex: 1 1 auto ;
    }

###

# *********************** Page ********************************************

page= EF Page= ({active,...props})->
    props.className= "page page--#{if active then 'active' else 'not-active'} #{props.className}"
    cE 'article',props
    

require './RMWCWidgets-page.css'


# ************************ Side Panel ***********************************


sidePanel= EF SidePanel= ({active,right,...props})->
    props.className= "side-panel side-panel--#{if right then 'right' else 'left'} #{if lang is 'en' then 'side-en' else 'side-fr'} side-panel--#{if active then 'active' else 'not-active'} #{props.className}"
    cE 'aside',props
    
require './RMWCWidgets-sidepanel.css'


# ********************* AppWidget a Panel Swicth ************************

appWidget= EF AppWidget= ({ pageId, defaultPageId, ...props })->
    pid= pageId or defaultPageId
    #log "app-widget render pid=#{pid}, pageId=#{pageId} ,defaultPageId=#{defaultPageId} props=",props
    props.className= "app-widget rmwc-app-widget #{props.className or ''}"
    props.children=
        for child in childrenToArray props.children
            #log "PanelSwicth test #{pid} #{child.props.id} is #{child.props.id is pid}
            cloneElement child,active:child.props.id is pid
    cE 'main',props

### CSS:

.app-widget {
    overflow:hidden;

    position: absolute;
    width:  100%;
    height: 100%; 

    margin:  0;
    padding: 0;

    top:0;
    left:0;
    z-index:1;
    background-color: white;

    }

.app-widget .page > section .map-widget {
    overflow:hidden;
}

.app-widget .page > section .map-container {
    overflow: hidden ;
}

###

# AppWidget for react-router, No Swicth -> all pages are active

appWidgetNS= EF AppWidgetNS= ({ pageId, defaultPageId, ...props })->
    pid= pageId or defaultPageId
    #log "app-widget render pid=#{pid}, pageId=#{pageId} ,defaultPageId=#{defaultPageId} props=",props
    props.className= "app-widget rmwc-app-widget #{props.className or ''}"
#     props.children=
#         for child in childrenToArray props.children
#             log "PanelSwicth test #{pid} #{child.props.id} type is  #{child.type?.name}",child
#             cloneElement child,active:true #child.props.id is pid
    cE 'main',props

# **************** THEME stuff ******************************************

require '@rmwc/theme'
require '@rmwc/theme/styles'




module.exports= { 
    topBar, tbTitle, tbIcon,
    fileChooser, checkbox, radio, radioGroup,
    fab, rmwcButton, valueButton, icon, link, 
    mainSnackbar, MainSnackbarQueue, 
    list, listItem, listDivider, menu, menuSurfaceAnchor,
    mDialog, 
    layout, cell, group, page, sidePanel, appWidget, appWidgetNS
    }


