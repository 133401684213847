
{ ElementFactory }= require './ReactElemSS'
{ Fragment }=       require 'react'

DOMElements= require 'vx/json/DOMElementNames.json' # see DOMElements.ck !!

uncap= (s)-> s[0].toLowerCase()+s[1..]

OfE= Object.fromEntries ? (arr,ret={})->
    ret[n]=v for [n,v] in arr
    ret

DomFactories= OfE ([uncap(elemName),ElementFactory elemName] for elemName in DOMElements)

DomFactories.use= ElementFactory 'use'
DomFactories.frag= ElementFactory Fragment

module.exports= DomFactories

