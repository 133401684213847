{ log, aPairs }= vx= require 'vx/globals/Boot'

{ min, max, sqrt, sin, cos, acos, atan, PI:pi, log:Log, exp} = Math

earthRadious= 6378.137 #6378.137 was 6366.707

meter2deg=360/(2 * pi * 1000 * earthRadious)

mCalcDist = (p1lat,p1lng,p2lat,p2lng)-> # Calculate distance between point p1 and p2

        p1RLat=  pi*(90-p1lat)/180
        p2RLat=  pi*(90-p2lat)/180
        p1RLng=  pi*((360+p1lng) % 360)/180
        p2RLng=  pi*((360+p2lng) % 360)/180


        x1 = cos(p1RLng)*(sp1RLat=sin(p1RLat))
        y1 = sin(p1RLng)* sp1RLat
        z1 = cos(p1RLat)

        x2 = cos(p2RLng)*(sp2RLat=sin(p2RLat))
        y2 = sin(p2RLng)* sp2RLat
        z2 = cos(p2RLat) # could cor

        dx= x1-x2
        dy= y1-y2
        dz= z1-z2

        earthRadious*acos((2-(dx*dx+dy*dy+dz*dz))/2)

mCalcDistPt= (pta,ptb)-> mCalcDist pta.lat,pta.lng, ptb.lat,ptb.lng

d2r=  2*pi/360
d2r2= d2r*2

latM= (lat)->
    #Mercatile projection adjustment latitude is 'strecheted' to preserve angles
    lats= sin(lat*d2r) #Convert to radians and sin ..
    Log( (1+lats)/((1-lats) || 1.0e-20))/d2r2

sinh= (x)-> ((ex=exp x) - 1/ex) / 2

#Inverse of latM
latMInv= (latm)-> atan(sinh(latm*d2r))/d2r


pxscales=( 360/(2**(z+8)) for z in [ 0 .. 22 ] )

pixel2deg= (px,z=18)-> px*pxscales[z]

tile2latMswne= (x,y,zoom)->
    zscale= 2**zoom
    x%= zscale
    y%= zscale

    scale=360/zscale

    x= (x*scale)-180
    Y= 180-(y*scale)

    [(Y-scale),x,Y,x+scale,scale] #[s,w,n,e,scale]


###
from: https://github.com/Yhozen/rn-maps-polyline#readme
###

decodeGEPolyline= (str)->

    values= getGEIntegers(str)

    ret=for i in [0 ... values.length] by 2
        lat: (values[ i + 0 ] += (values[ i - 2 ] || 0)) / 1e5
        lng: (values[ i + 1 ] += (values[ i - 1 ] || 0)) / 1e5

    #log "decodeGEPolyline did ret=",ret
    ret


geSign= (value)-> if value & 1 then  ~(value >>> 1) else (value >>> 1)


getGEIntegers= (str)->
  values = []
  byte = 0
  current = 0
  bits = 0

  for i in [0 ... str.length] #(var i = 0; i < str.length; i++) {
    byte = str.charCodeAt(i) - 63
    current = current | ((byte & 0x1F) << bits)
    bits = bits + 5

    if (byte < 0x20)
      values.push(geSign(current))
      current = 0
      bits = 0

  values



module.exports= { latM, latMInv, mCalcDist,  mCalcDistPt, earthRadious, meter2deg,  pixel2deg, tile2latMswne, decodeGEPolyline  }
