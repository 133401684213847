{ meta, oAssign, log }= vx= require 'vx/globals/Boot'

{ BaseClass }= require 'vx/tools/BaseClass'

{ min, max, sqrt, pow, abs } = Math

{ Bounds }= require 'vx/math/Bounds'

idc=0

getId= (prefix='id_')-> prefix+(++idc).toString 36

meta class QuadNode extends BaseClass

    @FROM:(json,ops)->
        root= @_()
        oAssign root,json
        if ops then oAssign root,ops
        root.bounds= Bounds.BNDS root.bounds
        root.splits= ( @FROM subNode,ops for subNode in root.splits )
        root

    level: 0 #max depth allocated at init
    maxLevel:16 #dont add levels past this depth
    maxSize: 32 #size at witch to split ...
    splits: false #array when splited ...

    constructor: (bnds,ops={})->
        super ops
        @contents= []
        @bounds= bnds
        if @level>0 then @_split()
        return @

    getObjBounds: (obj)->obj.getBounds()

    add: (obj)->

        if not(@splits) and @maxSize<@contents.length
            @_split()
        if @splits
            bnds= @getObjBounds obj
            for s in @splits when s.bounds.contains(bnds) #we know s is same class as @
                return s.add(obj) #return to stop iteration
        @addHere obj

    addHere: (obj)-> @contents.push(obj)

    addAll: (arr)->
        @add o for o in arr
        @

    remove: (obj)->

        return @removeHere(idx) if (idx=@contents.indexOf(obj))>=0
        return false if !@splits
        bnds= @getObjBounds obj
        return s.remove(obj) for s in @splits when s.bounds.contains(bnds)
        false

    removeHere: (idx)-> @contents.splice(idx,1)

    getIntersects: (bnds,ret=[])->

        return ret if !@bounds.intersects(bnds) #return if no intersection
        for obj in @contents when obj and bnds.intersects(@getObjBounds obj)
            ret.push obj
        if @splits then for s in @splits
            s.getIntersects(bnds,ret)
        ret


    _split: ->

        if @maxLevel<=0
            @splits=[]
            return

        cntr= @bounds.center()
        @splits=  for id in 'tl tr bl br'.split(' ')
            @constructor._(
                Bounds.PTS( @bounds[id](), cntr )
                { level:@level-1, maxLevel:@maxLevel-1, getObjBounds:@getObjBounds }
                )

        #readd contents, will try to distribute in splits
        contents= @contents
        @contents= []
        for o in contents then @add(o)
        return


    getBounds: -> @bounds


    dump: (lvl=4,id='?',indent='',step='  ')->

        log "#{indent} #{@id}:#{@contents.length}  #{@level}"
        if lvl>0 and @splits then for s,i in @splits
            s.dump(lvl-1,i,indent+step)

    findClosest2Pt: (pt,distFn=(o,pt,better)->o.distance2Pt(pt,better))->

        bnds=Bounds.PTS(pt)
        r= 0 #start with direct hits
        minD= Infinity
        seen= {}
        obj= null
        loop
            hits=@getIntersects(bnds.grow(r*2))
            for o in hits when (!(id=o.id)?) or id not of seen
                if id? then seen[id]= 1
                d= distFn(o,pt,minD)
                if d<minD then obj= o; minD= d
            break if minD<= r
            r= if minD != Infinity then minD
            else if r>0 then r*2
            else max(@bounds.width(),@bounds.height(),0.00001)/(@maxSize*pow(2,@level))
        {obj,dist:minD}

    cloneCnt=0

    clone: ()->

        #Create a clone of the node
        # has extOwn
        oAssign (new @constructor),@,
            id: getId('c_')+'_'+@id
            add:(obj)->
                #this is the first add clone splits if they exist
                if @splits
                    @splits=( s.clone() for s in @splits) #clone next level
                delete @add
                delete @remove
                @add(obj)
            addHere: (obj)->
                #first add to this node
                @contents= @contents[..]
                delete @addHere
                delete @removeHere
                @addHere(obj)
            remove: (obj)->
                if @splits
                    @splits=( s.clone() for s in @splits)
                delete @add
                delete @remove
                return @remove(obj)
            removeHere: (idx)->
                @contents= @contents[..]
                delete @addHere
                delete @removeHere
                @removeHere(idx)


    iter:(fn)->
        fn o for o in @contents
        if @splits
            leaf.iter fn for leaf in @splits
        @

    replace:(fn)->
        for o,i in @contents
            @contents[i]= fn o
        if @splits
            leaf.replace fn for leaf in @splits
        @

    list:(ret=[])->
        @iter (x)->ret.push x
        ret


module.exports= { QuadNode, Bounds }



