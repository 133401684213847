{ meta, log, snList, sSet, nr, time:getTime, lang }= Mod= vx= require 'vx/globals/Boot'
{ oDiffs, oAssign, oA, time:getTime, isPrefix, aSum, aPairs, aLast, isArray, sSplit, sSplit1, fromJS }= Mod

{ shortDate2 }= require 'vx/tools/DateUtils'

{Promise}= require 'vx/tools/NamedPromises'

{ say }= require 'vx/i18n/I18n'

{ mCalcDist }= require 'vx/math/MapGeometry'

{ Bounds }= require 'vx/math/Bounds'

{ topEval, sendHash }= require 'vx/globals/TopControlClient'

{ Circuit }= require './CircuitModel'

PR= require 'vx/tools/Persistance'

{Regroupements}= require 'vx/globals/Conversions'

PR.addQSCompressors CircuitsPatterns=
    C:
        prefix:'parcours.CA.2014-10-30.'
        sufix: ''
        compFn: (str)-> PR.intToB64u parseInt str
        expdFn: (str)-> (PR.b64uToInt str).toString()




class CircuitsStateManager

    noCircuits=[]
    #noCircuitsProposed=[]

    defaultState:
        circuits: noCircuits
        circuitsFileInfo:{}
        #circuitsProposedIds: noCircuitsProposed
        circuitsDecorations: {}
        circuitSelectedId: ''
        circuitHoverId: ''
        circuitSelectedZoomMode: null
        circuitsFilter: {}
        circuitsFiltreVisible: false
        _circuitsLookup:{}
        #_circuitsProposedIds:[]
        #_circuitsProposed:[]


    urlAPIVars:
#         circuit: (circuitIdInfo,{urlQS={},refQS={}}={})->
#             circuitIdInfo= snList circuitIdInfo
#             return if not isArray circuitIdInfo
#             [ circuitId=null, autoZoom=null ]= circuitIdInfo
#             return if typeof circuitId isnt 'string'
#
#             if isPrefix circuitId,'_ref_'
#                 refName= (circuitId.substr 5) or 'circuit'
#                 circuitId= refQS[refName]
#                 log "REFERRERCIRCUITID IS #{circuitId} from #{refName} got",{urlQS,refQS,refName,circuitId}
#                 return if typeof circuitId isnt 'string'
#
#             if not isPrefix circuitId,'parcours.'
#                 circuitId="parcours.#{circuitId}"
#
#             if !autoZoom?
#                 autoZoom=urlQS.circuitZoom
#
#             @setCircuitSelectedId circuitId,autoZoom


        circuitUpdateHash:(val)->
            vx.use.circuitUpdateHash= val

        circuitSelectedId:(val)->@setCircuitSelectedId val

        circuitHoverId:(val)->@setCircuitHoverId val,2

        region: (regionId,{urlQS={},refQS={}}={})->
            log "API got region=#{regionId}"
            return if typeof regionId isnt 'string'
            if isPrefix regionId,'_ref_'
                log "prefix fix"
                refName= (regionId.substr 5) or 'region'
                regionId= refQS[refName]
                log "REFERRERCREGIONID IS #{regionId} from #{refName} got",{urlQS,refQS,refName,regionId}
                return if typeof regionId isnt 'string'

            regionId= Regroupements[regionId] or regionId

            log "API region=#{regionId} Regroupements",Regroupements



            app= @ # state manager code runs as app
            @setCircuitsFiltreValue 'membre',regionId,->
                log "CircuitsStateManager.filter set"


        zoomMode: (zoomMode)->

            app= @
            (Promise.all [(Promise.vxGet 'circuits-data'),(Promise.vxGet 'main-gmaps-map')]).then ->
                log "zoom mode eval zoomMode=#{zoomMode}"
                switch  zoomMode
                    when 'circuits'
                        # we have an autozoom
                        # We know the problem, wait for the map
                        log "zooomMode circuits ok map and circuits-data "
                        lookups= app.state._circuitsLookup
                        selected= ( lookups[id] for id,deco of app.state.circuitsDecorations when deco.filtered )
                        log "selected circuits=",selected
                        app.zoomCircuits selected if selected.length
                    when 'selected'
                        log "zoommode selected"
                        if (circuitId= app.getCircuitSelectedId()) and (circuit=app.getCircuitId circuitId)
                            app.zoomCircuits [circuit]
                    when 'hover'
                        log "zoommode selected"
                        if (circuitId= app.getCircuitHoverId()) and (circuit=app.getCircuitId circuitId)
                            app.zoomCircuits [circuit]
                    when 'selected-circuits'
                        selected=null
                        if (circuitId= app.getCircuitSelectedId()) and (circuit=app.getCircuitId circuitId)
                            selected= [circuit]
                        else
                            lookups= app.state._circuitsLookup
                            selected= ( lookups[id] for id,deco of app.state.circuitsDecorations when deco.filtered )
                        log "zoommode slected-cricuits got #{selected.length}"
                        if selected.length
                            app.zoomCircuits selected

        mapBounds:(s,w,n,e)->
            mb= Bounds._ w,s,e,n
            args= arguments
            log "fitbounds= bnds=",{mb,args}
            @fitMapBounds mb

        persiste:(obj)->
            if typeof obj is 'string'
                obj= sSet obj,','
            log "set persistant state to ",obj
            @persistantState= obj

    #TODO adjust inforce circuits propose subset of circuits

    setCircuitsInfo: (info)-> @setState circuitsFileInfo:info

    setCircuits: (circuitsData)->
        oldCircuits= null
        if !circuitsData?.length?
            log "setCircuits no circuirs using noCircuits=#{noCircuits}",noCircuits
            circuits= noCircuits
        else
            circuits= Circuit.fromJSONArr circuitsData
        @setState ((state)->
            oldCircuits= state.circuits
            #log " Setting circuits *****************************"

            circuits: circuits
            _circuitsLookup: _circuitsLookup= new -> (@[circuit.id]=circuit for circuit in circuits; @ )
            circuitsDecorations: new -> (@[circuitId]=deco for circuitId,deco of state.circuitsDecorations when circuitId of _circuitsLookup ; @ )
            #_circuitsProposedIds: _circuitsProposedIds= ( id for id in state.circuitsProposedIds when id of _circuitsLookup  )
            #_circuitsProposed: ( _circuitsLookup[id] for id in _circuitsProposedIds )
            circuitSelectedId: if state.circuitSelectedId of _circuitsLookup then state.circuitSelectedId else ''
            ),->
                #log "setCircuits.doUpdate !!!!!!!!!!!!!  old= #{oldCircuits?.length} new= #{circuits?.length}",{oldCircuits,circuits}
                if !(oldCircuits?.length) and (circuits?.length)
                    #log "Cicuits went from no data to data=#{circuits.length}"
                    (Promise.vxGet 'circuits-data').vxResolve circuits.length
                #log "Did circuit update *********************************8 "

    getCircuits:  (state=@state)-> state.circuits or []
    getCircuitId: (id,state=@state)-> state._circuitsLookup[id]

#     setCircuitsProposedIds: (circuitsIds=noCircuitsProposed)->
#         @setState (state)->
#             _circuitsLookup= state._circuitsLookup
#
#             circuitsProposedIds: circuitsIds= if circuitsIds.length? then circuitsIds else noCircuitsProposed
#             _circuitsProposedIds: _circuitsProposedIds= ( circuit.id for circuit in circuitsIds when circuit.id of _circuitsLookup  )
#             _circuitsProposed: ( _circuitsLookup[id] for id in _circuitsProposedIds )
#
#     hasCircuitsProposed: -> !!@state._circuitsProposedIds.length
#     getCircuitsProposed:    ->@state._circuitsProposed
#
#     getCircuitsProposedIds: ->@state.circuitsProposedIds

    setCircuitDecoration: (decoName,circuitId,value)-> @setCircuitDecorations circuitId,"#{decoName}":value

    setCircuitDecorations:(circuitId,decos)->@setState (state)->
        newDecos= oA {},state.circuitsDecorations, {"#{circuitId}":oA {},state.circuitsDecorations[circuitId],decos}
        #log "seting circuit decos to",newDecos
        circuitsDecorations: newDecos

    setCircuitsDecorations:(decos,state=@state,trans)->
        newDecos=oA {},state.circuitsDecorations,trans?.circuitsDecorations
        for circuitId,cDecos of decos
            newDecos[circuitId]= oA {},newDecos[circuitId],cDecos
        if trans
            #log "setCircuitsDecorations delayed setStae trans=",trans
            oA {},trans,circuitsDecorations: newDecos
        else
            @setState circuitsDecorations: newDecos

    getCircuitDecorations: (circuitId,state=@state)-> state.circuitsDecorations[circuitId]

    getCircuitDecoration: (decoName,circuitId,state=@state)-> @getCircuitDecorations(circuitId,state)?[decoName]

    getCircuitSelectedId:(state=@state)->   state.circuitSelectedId

    setCircuitSelectedId: (newIdIn,zoomMode,state=@state,cb)->
        oldId=@getCircuitSelectedId state
        if !newIdIn
             newId= ''
        else newId= newIdIn

        return if ( !oldId and !newId ) or ( newId is oldId ) # no change, zoomMode only good on change
        #log "seting selected cicuit for #{oldId} to #{newId} zoomMode=#{zoomMode} "

        if newId and @getSelectedAttraitId state
            @setSelectedAttraitId null,state

        change= circuitSelectedId: newId

        if zoomMode? and ( zoomMode isnt state.circuitSelectedZoomMode)
            change.circuitSelectedZoomMode= zoomMode

        decoChanges= {}
        decoChanges[oldId]= { selected:undefined } if oldId
        decoChanges[newId]= { selected:1         } if newId

        changes= @setCircuitsDecorations decoChanges,state,change

        self= @
        @setState changes,->
            #log "Finised slectId newId=#{newId} oldId=#{oldId}"
            state= self.state
            if !newId and oldId
                self.setCircuitHoverId oldId,2,state #(if self.state.vxScreenWidth is 'wide' then 2 else 1)
            if hashPrefix=vx.use.circuitUpdateHash
                if typeof  hashPrefix is 'string'
                     newHash= if newId then "#{hashPrefix}_#{newId}" else hashPrefix
                else newHash= if newId then newId else ''
                topEval "location.hash='#{newHash}'"
            cb() if cb


    getCircuitHoverId:(state=@state)->   state.circuitHoverId

    setCircuitHoverId: (newId,mode,state)->
        oldId= @getCircuitHoverId state
        #log "Circuits setCircuitHoverId mode=#{!!mode} newId=#{newId} oldId=#{oldId}"

        if !mode
            mode= undefined
        if oldId is newId
            oldId=null

        #log "seting hover cicuit for #{oldId} to #{newId}"

        change= circuitHoverId: (if mode then newId else '')

        decoChanges= {}

        (decoChanges[oldId]= { hover:undefined }) if oldId and oldId isnt newId
        (decoChanges[newId]= { hover:mode      }) if newId

        changes= @setCircuitsDecorations decoChanges,state,change

        #log "doing changes=",changes
        @setState changes


    toggleCircuitsList: (state=@state)->
        #log "setting circuitsListVisible to #{!@state.circuitsListVisible}"
        @setState circuitsListVisible:!state.circuitsListVisible

    toggleCircuitDecoration: (decoName,circuitId,state=@state)-> @setCircuitDecoration decoName,circuitId,( if @getCircuitDecoration decoName,circuitId then undefined else 1)

    toggleCircuitVisibility: (circuitId)-> @toggleCircuitDecoration 'visible',circuitId

    toggleCircuitSelectedId: (newId,state=@state)->
        if newId is @getCircuitSelectedId state
             @setCircuitSelectedId '',null,state
        else @setCircuitSelectedId newId,null,state


    toggleCircuitsFiltreVisibility: (state=@state)->
        @setState circuitsFiltreVisible: !state.circuitsFiltreVisible



    doSetCircuitsFiltreValue: (key,valJS,cb)->
        self=@
        log "async SetCircuitsFiltreValue"
        @setState ((state,props)->self._setCircuitsFiltreValue key,valJS,state),cb


    setCircuitsFiltreValue: (key,valJS,state=@state,cb)->
        change= @_setCircuitsFiltreValue key,valJS,state
        @setState change,cb if change

    _setCircuitsFiltreValue: (key,valJS,state,cb)->
        return if !key or typeof key isnt 'string'

        val= fromJS valJS
        log "Circuit filter setting #{key} to #{val}"
        circuitsFilter: oA {},state.circuitsFilter,"#{key}":val


    zoomCircuits:(circuits,state=@state)->
        zoomMode= state.zooMode
        bnds= Bounds.aBNDS (circuit.bounds for circuit in circuits)
        #log "CircuitPathSVG.zoomCircuit got #{zoomMode}",{zoomMode,@props,bnds}
        @fitMapBounds? bnds


#     doCircuitZoomToSelection:->
#         log "IN doZoomToSelection"
#         id=  @getCircuitSelectedId()
#         return if !id
#         circuit= @getCircuitId id
#         return if !circuit
#         @zoomCircuits [circuit]



    onMountDo: ['watchHash']


    watchHash:->
        #log "watchHash Circuits mounted circuit statemanager vx.use.circuitUpdateHash=#{vx.use.circuitUpdateHash} self=",self:@
        return if !vx.use.circuitUpdateHash

        self= @
        window.addEventListener 'top_message_sethash',(e)->
            #log "watchHash Circuits got a top_message_sethash tophash='#{hash=e.detail?.hash}' hashPrefix=#{hashPrefix=vx.use.circuitUpdateHash} isPrefix=#{isPrefix hash,hashPrefix} e=",e
            state=self.state
            if (hash=e.detail?.hash)?
                #ok we have a hash do we need to parse it?
                if (typeof (hashPrefix=vx.use.circuitUpdateHash) is 'string')
                    if isPrefix hash,hashPrefix
                         hashId= hash[hashPrefix.length+1...]
                    else hashId=''
                else hashId= hash
                self.setCircuitSelectedId hashId,null,state,->
                    #log "watchHash init callback is boot=#{e.detail?.boot}",e
#                     if e.detail?.boot
#                         #log "watchHash got hash=#{e.detail.hash}"
#                         if  (vx.q?.zoomMode=='selected' or vx.q?zoomMode=='selected-circuit')
#                             log "watchHash do zoom"
#                         else log "watchHash dont zoom"

        topEval "send(wins,{verb:'setHash',hash:lastHash,boot:1})"


    zoomCircuit: (zoomMode)->
        bnds= Bounds.BNDS @props.circuit.bounds
        @props.app?.fitMapBounds bnds



module.exports= { CircuitsStateManager, CircuitsPatterns }
