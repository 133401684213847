{ log, arr, Global, isPrefix, getPropertyText }= Mod= vx= require 'vx/globals/Boot'
{ oA, oAssign, meta }= Mod

ModuleName= 'TemplateWidgets'


{ getTemplateFn }= require 'vx/templates/Template'
{ UIComponent, div }= require 'vx/UI'
{ sayModule, getLang }= require 'vx/i18n/I18n'

{topEval}= require 'vx/globals/TopControlClient'


Say= sayModule ModuleName

Lang= getLang()

styleWatcher= meta class StyleWatcher extends UIComponent
    # only one child

    defaultProps:
        styleList:[]


TemplateWidgetIdCnt=0


templateRender= meta class TemplateRender extends UIComponent
    @displayName: 'TemplateRender'

    #props:
    # obj
    # templateFn
    # className
    # ...ops

    render: (props=@props)->
        { obj, templateFn, deco={}, ops={}, tref, debug, tag=div, say=Say,lang=getLang() }= props

        if obj and templateFn
             html= templateFn obj,deco,ops,say,lang
        else html= ''

        if debug
            log "templateRender didhtml='#{html}'", { obj, templateFn, deco, ops, tref, debug, tag, say,lang,props }

        tag oA {},props,
            ref: tref
            obj:  undefined
            templateFn: undefined
            deco: undefined
            ops:  undefined
            tref: undefined
            tag:  undefined
            say:  undefined
            lang: undefined
            debug:undefined
            dangerouslySetInnerHTML: __html: html



meta class TemplateWidgetBase extends UIComponent
    @displayName: 'TemplateWidgetBase'

    doRedirectEvent: (e)->
        #log "Template widget Redirect event type=#{e.type} nodeName=#{e.target.nodeName}",e
        target= e.target
        return if target.nodeName is 'LABEL' # labels are just proxies ....

        type=   e.type

        if actionTarget= target.closest("[data-#{type}-action],[data-action]")
            actionTargetDS= actionTarget.dataset
            actionNameAll= actionTargetDS["#{type}Action"] or actionTargetDS.action
            if isPrefix actionNameAll,'--'
                # use var Name lets use set action through css rules
                actionNameAll= getPropertyText actionTarget,actionNameAll

        [actionName,actionArg]= (actionNameAll or '').split ','

        [obj,objid]= @getObjInfo target


        { checked, name } = target # , value }

        #log "TemplateWidgetBase.doRedirectEvent got name=#{name} checked=#{!!checked}"

        switch
            when target.dataset.jsonValue
                if target.dataset.jsonValue is 'undefined'
                    value= undefined
                else
                    try value= JSON.parse target.dataset.jsonValue
            when target.dataset.listValue
                value= target.dataset.listValue.split ','
            else value= target.value


        if not name
            # EDGE patch for options name hunt
            nameTarget= target.closest('[name],[data-name]')
            name= nameTarget?.name or nameTarget?.attributes.name?.value or nameTarget?.dataset.name
            #log "name hunt got name=#{name} ",{target,nameTarget,name:nameTraget?.name?.value,aname:nameTraget?.attributes.name,dname:nameTarget?.dataset.name}


        if !value? and nameTarget and target isnt nameTarget
            # EDGE patch for options name hunt
            value= nameTarget?.value or nameTarget?.attributes.value?.value or nameTarget?.dataset.value
            #log "value hunt got value=#{value} "


        if  action=@props.container?[actionName]
            actionTaget= @props.container
        else
            action= @props[actionName] or @props.actions?[actionName] or @[actionName]
            actionTarget= @

        #log "TemplateListWidget.doRedirectEvent got event type=#{type} target is #{target?.nodeName}  actionName=#{actionName} objid=#{objid} found obj #{!!obj} checked=#{checked} name=#{name} value=#{value} ",{ e, n:e.nativeEvent, obj, objid, checked, type, actionName, name, value, list:@props.list,action,actionName ,self:@, @props,target}

        if typeof action is 'function'
            #log "doin action function from #{actionName}" ,[obj,{objid,checked,type,actionName,name,value,list:@props.list,target}]
            #log "doing  e.preventDefault()"
            #e.preventDefault()
            action.call actionTarget,obj,{objid,checked,type,actionName,name,value,list:@props.list,target,actionArg,e}
        else
            @eventAction action,obj,{actionType:(typeof action),objid,checked,type,actionName,actionArg,name,value,list:@props.list,target,actionTarget,e,@props,container:@props.container,caction:@props.container?[actionName],action}


    eventAction: (action,obj,ops)->
        log "TemplateWidget got unhandled event actionName=#{ops.actionName} action=#{action} on #{ops.objid}",{action,obj,ops} if vx.isDEV


    navigateTo: (obj,{target,e})->
        # This is a generique action
        e?.preventDefault()
        closestHref= target.closest('[data-href],[href]' )
        href= closestHref?.dataset?.href or closestHref?.href
        if isPrefix href,'--'
            href= getPropertyText closestHref,href


        hTarget=  closestHref?.dataset?.target or closestHref?.target

        if isPrefix hTarget,'--'
            hTarget= getPropertyText closestHref,hTarget

        #log "navigateTo got href=#{href} hTarget=#{hTarget} ",closestHref if isDEV

        if href is 'back'
            if hTarget is '_top'
                try
                    window.parent.location.back()
                catch
                    # ahh not same domaine try TopControl
                    topEval "window.location.back()"

            else window.location.back()
            return

        if hTarget
            window.open href,hTarget
        else
            window.location= href

    ignore: (e)->
        log "Ignore event ",e if vx.isDEV
        null

    toggleClass: (obj,info)->
        log "got toggleClass =,",{obj,self:@,info,e:info.e}
        info.e.preventDefault()
        info.e.stopPropagation()




templateWidget= meta class TemplateWidget extends TemplateWidgetBase
    @displayName: 'TemplateWidget'

    constructor: (props)->
        super props
        @state=
            template:props.template
            toggleClasses:[]


    render: (props=@props,state=@state)->
        { tag, componentClassName, className, obj, doHover, onChange, onClick, say=Say, lang=getLang() }= props

        componentClassName= vx.templates[componentClassName] or componentClassName

        if state.stateClassNames
            iCls+=' '+( k for k,v of state.stateClassNames when v ).join ' '
        else iCls=''

        tag?= div
        template= state.template
        templateRender oA {
                tag: tag
                templateFn: ( getTemplateFn template if template )
                tref: (@doGetTemplate if not template)
                'data-objid':obj?.id #TODO generalize this, add itemProps
                'data-nom':(obj?.Titre or ( lang=='en' and obj?.titleEn or obj?.title))
                'data-region':obj?.membre if obj?.membre
                onClick:  onClick or @doRedirectEvent
                onChange: onChange or @doRedirectEvent
                onMouseEnter: @doStartHover if doHover
                onMouseLeave: @doEndHover   if doHover
                say: say
                lang: lang
            },props,{
                className: componentClassName+' '+className+iCls
                componentClassName: undefined
                container: undefined
                actions: undefined
            }


    doGetTemplate: (node)->
        if node
            templateTxt= getPropertyText node,'--template'
            (@setState template:templateTxt) if templateTxt and templateTxt isnt @state.template


    getObjInfo: -> [obj=@props.obj,obj?.id]


    doStartHover: (e)->
        return if !@props.doHover or !@props.obj

        @props.doHover @props.obj,1


    doEndHover: (e)->
        return if !@props.doHover or !@props.obj

        @props.doHover @props.obj,1



    toggleClass: (obj,info)->
        toggleClass= info.actionArg
        if !toggleClass
            log "Ignored toggle class no class",{obj,self:@,info,e:info.e,toggleClass:info.actionArg}
            return
        #log "got toggleClass = #{toggleClass}",{obj,self:@,info,e:info.e,toggleClass:info.actionArg}
        info.e.preventDefault()
        info.e.stopPropagation()
        classNames= oA {},@state.stateClassNames
        classNames[toggleClass]= !classNames[toggleClass]
        @setState stateClassNames:classNames






templateListWidget= meta class TemplateListWidget extends TemplateWidgetBase
    @displayName: 'TemplateListWidget'

    constructor: (props)->
        super props
        {template_header,template_item,template_footer}= props
        @state=
             {template_header,template_item,template_footer,stateClassNames:null}

        #log "create TemplateListWidget widget props=",props


    # TODO: will receive props detect template change and re-render

    render: (props=@props,state=@state)->
        { tag, id, componentClassName, className, list, ops, decos, itemClassName, doHover, say=Say, lang=getLang() }= props

        componentClassName= vx.templates[componentClassName] or componentClassName
        #log " TemplateListWidget.render for #{componentClassName}"

        tag?= div
        {template_header,template_item,template_footer,stateClassNames}= state
        #log "TemplateListWidget render=", {template_header,template_item,template_footer}
        #log "TemplateListWidget render=", {ops, decos}
        tag {
            ref: @doGetTemplate if not ( template_item or template_header or template_footer )
            id: id
            className: componentClassName+' '+className
            onClick:  @doRedirectEvent
            onChange: @doRedirectEvent
            onInput:  @doRedirectEvent
            },
            if template_header
                #log "TemplateListWidget.render header vars=",{ops,list,decos,say}
                templateRender
                    key: 'header'
                    className: "#{componentClassName}__header template__header"
                    ops: ops
                    obj: list
                    deco: decos
                    say: say
                    templateFn: getTemplateFn template_header
            if template_item and list? and list.length
                div className: "#{componentClassName}__list",(
                        templateFn= getTemplateFn template_item

                        for obj,i in list when obj
                            deco=decos?[obj.id]
                            switch typeof itemClassName
                                when 'function'
                                    iCls= "#{componentClassName}__item #{if cls=itemClassName obj,deco,ops,say,state.item then cls else ''}"
                                when 'string'
                                    iCls="#{componentClassName}__item #{itemClassName}"
                                else iCls="#{componentClassName}__item"
                            if stateClassNames and sCls=stateClassNames[obj.id]
                                iCls+=' '+( k for k,v of sCls when v ).join ' '
                            templateRender
                                key: obj.id or i
                                obj: obj
                                'data-objid':obj.id # to do generalize this add itemProp
                                'data-nom':(obj?.Titre or ( lang=='en' and obj?.titreEn or obj?.titreFr)) # alors super patch
                                'data-region':obj?.membre if obj?.membre
                                deco: deco
                                className: iCls or ''
                                ops: ops
                                say: say
                                lang: lang
                                templateFn: templateFn
                                onMouseEnter: @doStartHover if doHover
                                onMouseLeave: @doEndHover   if doHover
                        )
            if template_footer
                templateRender
                    key: 'footer'
                    className: "#{componentClassName}__footer"
                    ops: ops
                    obj: {}
                    say: say
                    templateFn: getTemplateFn template_footer


    doGetTemplate: (node)->
        if node
            tState= null
            for t in [ 'header','item','footer']
                templateTxt= getPropertyText node, "--template-#{t}"
                if templateTxt and templateTxt isnt @state["template_#{t}"]
                    (tState?={})["template_#{t}"]= templateTxt
            if tState
                @setState tState


    doStartHover: (e)->
        #log "template start hover"
        @hover e,1

    doEndHover: (e)->
        #log "template end hover"
        @hover e,undefined

    hover: (e,mode)->
        objid=  e.target.closest('[data-objid]' )?.dataset.objid

        return if !objid or !@props.doHover

        for o in @props.list #when objid is o.id
            if o.id is objid
                obj= o
                break

        return if !objid
        #log "template call doHover objid=#{obj.id} mode=#{mode}"
        @props.doHover obj,mode


    getObjInfo: (target)->
        objid=  target.closest('[data-objid]' )?.dataset.objid

        if objid #TODO make a lookup
            for o in @props.list #when objid is o.id
                if o.id is objid
                    obj= o
                    break

        [obj,objid]


    toggleClass: (obj,info)->
        toggleClass= info.actionArg
        if !toggleClass
            log "Ignored toggle class no class",{obj,self:@,info,e:info.e,toggleClass:info.actionArg}
            return
        #log "got toggleClass = #{toggleClass}",{obj,self:@,info,e:info.e,toggleClass:info.actionArg}
        info.e.preventDefault()
        info.e.stopPropagation()
        classNames= oA {},@state.stateClassNames
        classNames[obj.id]= objClassNames= oA {},classNames[obj.id]
        objClassNames[toggleClass]= !objClassNames[toggleClass]
        @setState stateClassNames:classNames




module.exports= { StyleWatcher, templateRender, templateWidget, templateListWidget }
