#!/usr/bin/coffee

Meter2Degre= 0.00000899822 # TODO calc

MapDigits= 6
MapScale=  10**MapDigits
iMapScale= 1/MapScale
sMapScale= iMapScale*100

SIScale=1000000

verySmall= iMapScale/1000

NodeScale= MapScale/3 # error allowed for node connections
INodeScale= 1/NodeScale

module.exports= { Meter2Degre, MapDigits, MapScale, iMapScale, sMapScale, SIScale, verySmall, NodeScale, INodeScale }
