{ meta, log, oCreate, oAssign, aLast }= vx= require 'vx/globals/Boot'

{ BaseClass }= require 'vx/tools/BaseClass'

{ min, max, sqrt, floor } = Math

{ distancePt, distInfo2Pt, ptAtUPt }= require 'vx/math/Geometry'

{ mCalcDist, latM }= require 'vx/math/MapGeometry'

{ Bounds }= require 'vx/math/Bounds'

{ QuadNode } = require 'vx/math/QuadNode'

Persistance= require 'vx/tools/Persistance'
#log "LineStr persistance=",Persistance
ptEncoder= Persistance.SEncodeObjArr x:10000000,y:10000000
ptDecoder= Persistance.SDecodeObjArr x:10000000,y:10000000

meta class LineStr extends BaseClass

    pts:[]#of {x,y}
    bounds:false #Bounds of @pts

    lsCnt=0

    constructor:(pts,ops)->
        super ops
        @pts= pts
        @id= 'ls:'+(++lsCnt).toString(36)
        if !@bounds and Bounds? then @bounds= Bounds.aPTS(@pts)
        return @

    toString:-> "LineStr#{if @data and @data.id then ':'+@data.id else ''}([#{@pts.length}],#{@bounds.toString()})"

    start:-> @pts[0]
    end:  -> aLast @pts
    lastIdx: -> @pts.length-1 #A multi string would calc this an other way ...

    ptAt: (idx)->
        if idx<0 then idx=@pts.length+idx
        #log "pt at idx=#{idx} l=#{@pts.length}"
        return @pts[idx] if (u= idx % 1) == 0
        if idx>@pts.length-2
            log "OUPS got idx=#{idx} anf l=@{pts.length}"
        p0= @pts[idx]
        p1= @pts[idx+1]
        ptAtUPt(p0,p1,u)

    reverse:(ops)->@constructor._ @pts[..].reverse(),ops


    getBounds:->@bounds

    intersectLS:(ls)-># intersect with other linestring
        return false if !@bounds.intersects(ls.bounds)

    _cache: false
    _useCache: false
    _blockSize:bSz=64
    _minCacheSz:bSz*4

    distance2Pt: (pt,better)->
        if better?
            return dist if (dist=@bounds.distInfo2Pt(pt).dist) > better
        @distInfo2Pt(pt).dist

    distInfo2Pt: (pt,start=0,stop=0)->
        if start != 0 or stop != 0
            #partial scan cant use cache
            return @distInfo2Pt_scan(pt,start,stop)

        if @_useCache and @_cache
            return @distInfo2Pt_cache(pt) #start and stop always =0

        return @distInfo2Pt_scan(pt)

        #First time decide if we want a cache?
        if @pts.length>=@_minCacheSz
            @_makeCache()
            return @distanceInfoToPtCache(pt)
        else
            @_cache= false
            return @distanceInfoToPtScan(pt,start,stop)


    distInfo2Pt_scan: (pt,start=0,stop=@pts.length)->
        len= @pts.length
        stop= if stop <= 0 then len-stop else min(len,stop)
        if stop ==  len
            if pt.x == (endpt=@pts[len-1]).x and pt.y==endpt.y
                #log "end point guest hit"
                return {x:pt.x,y:pt.y,u:0,uo:0,uf:0,end:true,dist:0,idx:len-1}
            if len == 1
                #single point
                dist= distancePt pt,pt0=@pts[0]
                return {x:pt0.x,y:pt0.y,u:0,uo:0,uf:0,end:false,dist:dist,idx:0}
        dist= Infinity
        pts= @pts
        a= pts[start]
        for i in [start+1 ... stop] by 1\
            when (next= distInfo2Pt(a,(a=pts[i]),pt)).dist < dist
                if next.u < 1
                     next.idx= i-1; next.end= false; next.uf= next.u
                else next.idx= i  ; next.end=true  ; next.uf= 0
                dist= next.dist
                info= next
                break if dist == 0 #cant do better than that
        info

    _makeCache:(blockSize=@_blockSize)->
        cache= @_cache= QuadNode._(@bounds)
        getBounds=->@bounds

        for i in [0 ... @pts.length-1] by blockSize #carfull last point cant be start of segment
            cache.add({ start:i, end:i+blockSize, getBounds, bounds:Bounds.aPTS(@pts[i..i+blockSize]) })

    distInfo2Pt_cache: (pt)->
        self= @
        {obj,dst}= @_cache.findClosest2Pt(pt,(o,pt,better)->
                if better?
                    return dist if (dist=o.bounds.distInfo2Pt(pt).dist) > better
                self.distInfo2Pt(pt,o.start,o.end).dist)
        @distInfo2Pt_scan(pt,obj.start,obj.end)

    _dist: null
    getDist:->if @_dist isnt null then @_dist else @evalDist()

    evalDist:->
        dst= 0; lastPt= @pts[0]
        for pt in @pts
            dst+= mCalcDist lastPt.y, lastPt.x, pt.y, pt.x
            lastPt= pt

        #console.log "eval dist for #{@pts.length} is #{dst}",@pts
        @_dist= dst


    getDistToIdx: (idx,u=0)->
        #distance up to (including idx)

        return @getDist if idx >= @pts.length-1

        start= 0
        dst=0

        if idx isnt 0

            if @_dist2idx? and  @_dist2idx < idx
                start= @_dist2idx
                dst= @_dist2idxDst
            lastPt= @pts[start++]

            for p in [start..idx] by 1
                pt= @pts[p]
                dst+= mCalcDist lastPt.y, lastPt.x, pt.y, pt.x
                lastPt= pt

            @_dist2idx= idx
            @_dist2idxDst= dst

        else if u > 0
            lastPt=@pts[0]
            p=0

        if u>0
            pt= @pts[++p]
            dst+= u*mCalcDist lastPt.y, lastPt.x, pt.y, pt.x

        dst



    toJSON:->
        __cls:  'LineStr'
        _dist:  @dist
        bounds: @bounds.asArray()
        pts: ptEncoder @pts

    @fromJSON= (data)->
        ls= oCreate @::
        ls.id= 'ls:'+(++lsCnt).toString(36)
        ls._dist= data._dist
        ls.bounds= Bounds._.apply Bounds,data.bounds
        ls.pts= ptDecoder data.pts
        ls

    add2map: (map,ops)->
        map.createPolyline ( [ y,x ] for {x,y} in @pts ),ops

    asSVGPts:     (scale=1,digits=7)-> @_svgPts?= ("#{scale*x.toFixed digits},#{scale*y.toFixed digits}" for {x,y} in @pts ).join ' '

    asSVGLine:    (scale=1,digits=7)-> @_svgLine?= ("#{scale*x.toFixed digits} #{scale*y.toFixed digits}" for {x,y} in [@pts[0],aLast @pts] ).join ' '

    asSVGLatMPts: (scale=1,digits=7)-> @_svgLatMPts?= ("#{scale*x.toFixed digits},#{scale*(ym ? latM(y)).toFixed digits}" for {x,y,ym} in @pts ).join ' '

    asSVGLatMLine:(scale=1,digits=7)-> @_svgLatMLine?= ("#{scale*x.toFixed digits},#{scale*latM(y).toFixed digits}" for {x,y} in [@pts[0],aLast @pts] ).join ' '

#COnevrsion utilities
# from dirent formats to LineStr array

readVxlJson= (data)->
    for line in data # when line.linestr and line.linestr.length > 1
        cx=0
        cy=0
        l=line.linestr
        pts= ( for dy,i in l by 2
            dx= l[i+1]
            cx+= dx
            cy+= dy
            { x:cx/1000000.0 , y:cy/1000000.0 }
            )
        bounds= Bounds.aPTS(pts)
        if not line.data then log "????? #{line} #{pts.length} #{pts}"
        info= oAssign {},line.data, id:line.data?.GID ? line.data?.fid ? '?'
        LineStr._ pts,{bounds, data:info}




readAJson= (data)->
    offset= data.pop()
    r=1000000
    ox= offset[1]+(45*r)
    oy= offset[0]+(-70*r)
    cats= offset[2]
    cat=[''].concat (c for c in cats)
    for line,j in data
        d= line.pop()
        ldata= { cat:cat[d % 10], num: floor(d/10)}
        cx=ox; cy=oy
        pts= ( for dy,i in line by 2
            dx= line[i+1]
            cx+= dx
            cy+= dy
            { x:cx/r , y:cy/r }
            )
        #if j < 10 then log "pts=#{pts[..20]} c=#{data.cat} n=#{data.num}",pts[..20]
        bounds= Bounds.aPTS(pts)
        LineStr._(pts,{bounds, data:ldata} )


# Not used left for doc 
readGeoJson= (data)->
    if data.type isnt "FeatureCollection"
        log "No feature colection"
        return []
    ret= []
    for feature in data.features
        if feature.type isnt "Feature"
            log "Unknown feature type #{feature.type} geometry #{ feature.geometry?.type ? 'does not exist' }"
        else
            info= feature.properties
            info= oAssign {},info, id: info.FQCQ_G_ID or (if info.OBJECTID then 'O'+info.OBJECTID) or '?'
            switch feature.geometry.type
                when 'LineString'
                    pts= ( {x,y} for [x,y] in feature.geometry.coordinates )
                    bounds= Bounds.aPTS(pts)
                    ret.push LineStr._(pts,{bounds,data:info})
                when 'MultiLineString' #TODO GENERATE IDs
                    for line,i in feature.geometry.coordinates
                        pts= ( {x,y} for [x,y] in line )
                        bounds= Bounds.aPTS(pts)
                        ret.push(LineStr._(pts,{ bounds, data:oAssign {},info,id:info.id+".#{i}}" }))
                else log "Unknowen geometry #{feature.type} geometry #{feature.geometry.type}"
    ret



linesFromGraph5= (graph5)->

    console.time 'linesFromGraph5'
    ret=for seg in graph5.segments
        if seg.data?.no_sen
            seg.data.num= parseInt seg.data?.no_sen
        LineStr._ seg.pts,data:seg.data,bounds:seg.bounds
    console.timeEnd 'linesFromGraph5'
    ret

linesFromGraph5Async= (graph5,cb)->
    console.time 'linesFromGraph5Async'
    asyncMap graph5.segments,
        end: (lines)->
            console.timeEnd 'linesFromGraph5Async',lines
            cb lines
        fnc: (seg)->
            if seg
                if seg.data?.no_sen
                    seg.data.num= parseInt seg.data?.no_sen
                LineStr._ seg.pts,data:seg.data,bounds:seg.bounds


asyncMapDefaultMaxTime= 1000/90 # half of a scan
asyncMap= (arr,{end,cb,fnc=(->),step=10,maxTime=asyncMapDefaultMaxTime})->
    return cb? null if not arr
    len= arr.length or 0
    idx=-1
    ret= []
    loopcnt=0
    loopFnc= ->
        loopcnt++
        start= (new Date).getTime()
        #log " assync idx=#{idx} start=#{start}"
        while idx <= len and (new Date).getTime()-start < maxTime
            i= step
            while --i and ++idx < len
                if (res= fnc arr[idx]) isnt undefined
                    ret.push res
        if idx >= len
            log "asyncMap loopcnt=#{loopcnt} len/step=#{len/step}"
            end? ret
        else requestAnimationFrame loopFnc
    loopFnc()
    ret




module.exports= { LineStr, readGeoJson, readVxlJson, readAJson,  linesFromGraph5, linesFromGraph5Async }

