{ log, meta }= vx= require 'vx/globals/Boot'

{ ElementFactory:EF  }= require './ReactElemSS'
{ createElement }= require 'react'


{Typography}= require '@rmwc/typography'
require       '@rmwc/typography/styles'
require './RMWCTypo.css'




Typo= ({className,adjust,noTopMagrin,...props}={})->
    className=[
        'typo'
        className,
        'mdc-typography--adjust-margin' if adjust
        'typo--no-top-margin'           if noTopMagrin
        ]
    props.className= ( c for c in className when c).join ' '
    createElement Typography,props


typos=
    typo: typo= EF Typo

    h1: EF typo,
        tag:'h1'
        use:'display4'

    h2: EF typo,
        use: 'display3'
        tag: 'h2'


    h3: EF typo,
        use: 'display2'
        tag: 'h3'

    h4: EF typo,
        use: 'display1'
        tag: 'h4'

    h5: EF typo,
        use: 'subheading2'
        tag: 'h5'

    h6: EF typo,
        use: 'subheading1'
        tag: 'h6'

    title: EF typo,
        use: 'title'
        tag: 'h4'

    headline: EF typo,
        use: 'headline'
        tag: 'h5'

    caption: EF typo,
        use: 'caption'
        tag: 'h6'


    p: EF typo,
        use: 'body1'
        tag: 'p'

    em: EF typo,
        use: 'body2'
        tag: 'em'


module.exports= typos

